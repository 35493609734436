<div class="main">
  <div class="container-fluid container-1 mt-24" *ngIf="layer == 'language'">
    <div class="row">
      <div class="col-12 text-center">
        <img src="../../../assets/images/jaanoLogo.svg" alt="jaano-logo" class="jaano-logo-1">
      </div>
    </div>
    <div class="row mt-24">
      <div class="col-12 text-center">
        <span class="text text-1 w-700 color-1">
          {{'please_choose_language'|translate}}
        </span>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-sm-8">
        <div class="container-fluid sm-w-75 mt-24" style="max-width: 600px;">
          <div class="row">
            <div class="col-6 col-sm-4 content-center  w-700">
              <div class="card lang-card" (click)="onClick('en')">
                <div class="row">
                  <div class="col-12 text text-2 color-2">
                    A
                  </div>
                  <div class="col-12 text text-1 color-1 mt-8">
                    English
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 content-center  w-700">
              <div class="card lang-card" (click)="onClick('hi')">
                <div class="row">
                  <div class="col-12 text text-2 color-2">
                    अ
                  </div>
                  <div class="col-12 text text-1 color-1 mt-8">
                    हिंदी
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 content-center sm-mt-24  w-700">
              <div class="card lang-card" (click)="onClick('te')">
                <div class="row">
                  <div class="col-12 text text-2 color-2">
                    ఆ
                  </div>
                  <div class="col-12 text text-1 color-1 mt-8">
                    తెలుగు
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 content-center mt-40-24  w-700">
              <div class="card lang-card" (click)="onClick('pn')">
                <div class="row">
                  <div class="col-12 text text-2 color-2">
                    ੳ
                  </div>
                  <div class="col-12 text text-1 color-1 mt-8">
                    ਪੰਜਾਬੀ
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 content-center mt-40-24  w-700">
              <div class="card lang-card" (click)="onClick('mr')">
                <div class="row">
                  <div class="col-12 text text-2 color-2">
                    अ
                  </div>
                  <div class="col-12 text text-1 color-1 mt-8">
                    मराठी
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 content-center mt-40-24  w-700">
              <div class="card lang-card" (click)="onClick('bn')">
                <div class="row">
                  <div class="col-12 text text-2 color-2">
                    অ
                  </div>
                  <div class="col-12 text text-1 color-1 mt-8">
                    বাংলা
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 content-center mt-40-24  w-700">
              <div class="card lang-card" (click)="onClick('as')">
                <div class="row">
                  <div class="col-12 text text-2 color-2">
                    অ
                  </div>
                  <div class="col-12 text text-1 color-1 mt-8">
                    অসমীয়া
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-4 content-center mt-40-24  w-700" >
              <div class="card lang-card" (click)="onClick('ne')">
                <div class="row">
                  <div class="col-12 text text-2 color-2">
                    अ
                  </div>
                  <div class="col-12 text text-1 color-1 mt-8">
                    नेपाली
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="layer == 'cbp'" style="height:100vh; position:relative">
    <div class="row p-24" 
    style="background: linear-gradient(180deg, #FFFFFF 68.23%, rgba(255, 255, 255, 0) 100%);">
      <div class="col-auto w-1 p-0">
        <div class="row">
          <div class="col-12 text text-11 w-700 color-3">
            {{'jaano'|translate}}
          </div>
          <div class="col-12 text text-4 color-4 w-500">
            {{'india_civic_platform'|translate}}
          </div>
        </div>
      </div>
      <div class="col-auto ml-auto p-0">
        <div class="content-center">
          <img src="../../../assets/images/jaanoLogo.svg" alt="jaano-logo" class="jaano-logo-sm">
        </div>
      </div>
    </div>
    <div class="know-more-box">
      <div class="left-side-card lg-show">
        <div class="slider-card left-card-1">
          <div class="row">
            <div class="col-12 text-center">
              <div class="img-circle">
                <img src="../../../assets/images/solution3-icon.svg" alt="solution">
              </div>
            </div>
            <div class="col-12 text-center field-1">
              {{'quick_response'|translate}}
            </div>
            <div class="col-12 text-center field-2">
              {{'assured_solution'|translate}}
            </div>
          </div>
        </div>
        <div class="slider-card left-card-2">
          <div class="row">
            <div class="col-12 text-center">
              <div class="img-circle">
                <img src="../../../assets/images/certified2-icon.svg" alt="solution">
              </div>
            </div>
            <div class="col-12 text-center field-1">
              {{'government_certified'|translate}}
            </div>
            <div class="col-12 text-center field-2">
              {{'certified_emitra_for_help'|translate}}
            </div>
          </div>
        </div>
        <div class="slider-card left-card-3">
          <div class="row">
            <div class="col-12 text-center">
              <div class="img-circle">
                <img src="../../../assets/images/refund-icon.svg" alt="solution">
              </div>
            </div>
            <div class="col-12 text-center field-1">
              {{'new_survey_refund'|translate}}
            </div>
            <div class="col-12 text-center field-2">
              {{'if_fails_refund'|translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="right-side-card lg-show">
        <div class="slider-card right-card-1">
          <div class="row">
            <div class="col-12 text-center">
              <div class="img-circle">
                <img src="../../../assets/images/customer-icon.svg" alt="solution">
              </div>
            </div>
            <div class="col-12 text-center field-1">
              10000+
            </div>
            <div class="col-12 text-center field-2">
              {{'happy_customers'|translate}}
            </div>
          </div>
        </div>
        <div class="slider-card right-card-2">
          <div class="row">
            <div class="col-12 text-center">
              <div class="img-circle">
                <img src="../../../assets/images/solved-icon.svg" alt="solution">
              </div>
            </div>
            <div class="col-12 text-center field-1">
              500+
            </div>
            <div class="col-12 text-center field-2">
              {{'solution_everyday'|translate}}
            </div>
          </div>
        </div>
        <div class="slider-card right-card-3">
          <div class="row">
            <div class="col-12 text-center">
              <div class="img-circle">
                <img src="../../../assets/images/support-icon.svg" alt="solution">
              </div>
            </div>
            <div class="col-12 text-center field-1">
              {{'on_time'|translate}}
            </div>
            <div class="col-12 text-center field-2">
              {{'support_from_jaano_helper'|translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center plr-24" id="knowMoreBox">
          <div class="col-12 text-center text text-2 color-1 w-700 sm-show">
            {{'new_survey_jaano_paid_hr'|translate}}
          </div>
        </div>
        <div class="row justify-content-center plr-24">
          <div class="col-12 text-center mt-24 sm-show">
            <img src="../../../assets/images/landing-page-img.svg" alt="landing-page-img"
            width="200px" height="210px">
          </div>
        </div>
        <div class="row justify-content-center plr-24">
          <div class="col-12 text-center lg-show">
            <img src="../../../assets/images/landing-page-img.svg" alt="landing-page-img"
            width="360px" height="376px">
          </div>
        </div>
        <div class="row justify-content-center plr-24">
          <div class="col-12 mt-24 mb-24 sm-show">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active slider-card">
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="img-circle">
                        <img src="../../../assets/images/solution3-icon.svg" alt="solution">
                      </div>
                    </div>
                    <div class="col-12 text-center field-1">
                      {{'quick_response'|translate}}
                    </div>
                    <div class="col-12 text-center field-2">
                      {{'assured_solution'|translate}}
                    </div>
                  </div>
                </div>
                <div class="carousel-item slider-card">
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="img-circle">
                        <img src="../../../assets/images/certified2-icon.svg" alt="solution">
                      </div>
                    </div>
                    <div class="col-12 text-center field-1">
                      {{'government_certified'|translate}}
                    </div>
                    <div class="col-12 text-center field-2">
                      {{'certified_emitra_for_help'|translate}}
                    </div>
                  </div>
                </div>
                <div class="carousel-item slider-card">
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="img-circle">
                        <img src="../../../assets/images/refund-icon.svg" alt="solution">
                      </div>
                    </div>
                    <div class="col-12 text-center field-1">
                      {{'new_survey_refund'|translate}}
                    </div>
                    <div class="col-12 text-center field-2">
                      {{'if_fails_refund'|translate}}
                    </div>
                  </div>
                </div>
                <div class="carousel-item slider-card">
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="img-circle">
                        <img src="../../../assets/images/customer-icon.svg" alt="solution">
                      </div>
                    </div>
                    <div class="col-12 text-center field-1">
                      10000+
                    </div>
                    <div class="col-12 text-center field-2">
                      {{'happy_customers'|translate}}
                    </div>
                  </div>
                </div>
                <div class="carousel-item slider-card">
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="img-circle">
                        <img src="../../../assets/images/solved-icon.svg" alt="solution">
                      </div>
                    </div>
                    <div class="col-12 text-center field-1">
                      500+
                    </div>
                    <div class="col-12 text-center field-2">
                      {{'solution_everyday'|translate}}
                    </div>
                  </div>
                </div>
                <div class="carousel-item slider-card">
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="img-circle">
                        <img src="../../../assets/images/support-icon.svg" alt="solution">
                      </div>
                    </div>
                    <div class="col-12 text-center field-1">
                      {{'on_time'|translate}}
                    </div>
                    <div class="col-12 text-center field-2">
                      {{'support_from_jaano_helper'|translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center plr-24">
          <div class="col-12 text-center text text-4 color-3 mt-8-40 w-500 cursor-pointer">
            <span (click)="onKnowMore()" class="cursor-pointer">{{'know_more'|translate}}</span>
          </div>
        </div>
        <div id="confidence_building_scroll">
          <div  [hidden]="!knowMore">
            <div class="row justify-content-center plr-24 mt-171-24" >
              <div class="col-12 text-center text text-1 color-1 mt-24 w-700">
                {{'how_paid_help_work'|translate}}
              </div>
            </div>
            <div class="row justify-content-center plr-24">
              <div class="col-12 mt-24 p-0 mb-24">
                <div id="paidHelpFlow" class="carousel slide" data-ride="carousel">
                  <ol class="carousel-indicators">
                    <li data-target="#paidHelpFlow" data-slide-to="0" class="active"></li>
                    <li data-target="#paidHelpFlow" data-slide-to="1"></li>
                    <li data-target="#paidHelpFlow" data-slide-to="2"></li>
                    <li data-target="#paidHelpFlow" data-slide-to="3"></li>
                    <li data-target="#paidHelpFlow" data-slide-to="4"></li>
                    <li data-target="#paidHelpFlow" data-slide-to="5"></li>
                  </ol>
                  <div class="carousel-inner carousel-custom">
                    <div class="carousel-item slider-card"
                    *ngFor="let slider of sliderList; let i = index" [ngClass]="{'active':i ==0}">
                      <div class="row">
                        <div class="col-12 text-center text text-1 color-2 w-600">
                          {{slider.label|translate}}
                        </div>
                        <div class="col-12 text-center content-center" 
                        style="width:200px; height:307px;">
                          <img [src]="slider.img" alt="slider-1" [style.width]="slider.width"
                          [style.height]="slider.height">
                        </div>
                        <div class="col-12 text-center field-2 mt-4">
                          {{slider.description|translate}} 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center plr-24">
              <div class="col-12 text-center text text-1 color-1 mt-40 w-700">
                {{'feedback_list'|translate}}
              </div>
            </div>
            <div class="row justify-content-center plr-24" *ngFor="let review of customerReviews">
              <div class="col-12 mt-24 mainContainers p-0">
                <div class="card card-body customer-review-card">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="user-img-circle">
                          <img [src]="review.userProfilePicture" alt="profile-picture"
                          *ngIf="review.userProfilePicture && review.userProfilePicture != '' &&
                          review.userProfilePicture != 'undefined' && review.userProfilePicture != 'null'
                          && review.userProfilePicture != undefined"
                          height="40px" width="40px" class="border-radius-50">
                          <img src="../../../../../assets/images/new_help_icons/initials-img.svg"
                           alt="profile-picture"
                          *ngIf="!review.userProfilePicture || review.userProfilePicture == '' ||
                          review.userProfilePicture == 'undefined' || review.userProfilePicture == 'null'
                          || review.userProfilePicture == undefined"
                          height="40px" width="40px" class="border-radius-50">
                          <span class="initials-1"
                          *ngIf="!review.userProfilePicture || review.userProfilePicture == '' ||
                          review.userProfilePicture == 'undefined' || review.userProfilePicture == 'null'
                          || review.userProfilePicture == undefined">
                            {{getInitial(review.userid)}}
                          </span>
                        </div>
                        <div class="col" style="position:relative;margin-left: 4px;">
                          <div class="row">
                            <div class="col-12 username"
                            (click)="$event.stopPropagation();navigateToProfile(review.userid)">
                              @{{review.userid}}
                            </div>
                            <div class="col-12 light-text-1" style="position:absolute; bottom:0px;">
                              {{review.location}}
                            </div>
                          </div>
                        </div>
                        <div class="col-auto ml-auto">
                          <div class="row justify-content-end">
                            <div class="col-auto rating">
                              <img src="../../../../../assets/images/feedback2.svg" alt="rating"
                                width="16px" height="16px"
                                style="margin-bottom: 5px;
                                margin-right: 3px;">
                              <span>{{review.rating |number: '1.1-1'}}</span>
                            </div>
                          </div>
                          <div class="row justify-content-end">
                            <div class="col-auto light-text-1">
                              {{review.timestamp}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-8 comment" 
                    (click)="review.expand = !review.expand"
                    [ngClass]="{'expand-comment':review.expand}">
                      {{review.comment}}
                    </div>
                    <div class="col-12 mt-8">
                      <div class="row">
                        <div class="col" style="position:relative">
                          <button class="sub-cat-btn light-text-1">{{review.subCat}}</button>
                        </div>
                        <div class="col-auto ml-auto">
                          <div class="row justify-content-end cursor-pointer"
                          (click)="$event.stopPropagation();navigateToProfile(review.solvedById)">
                            <div class="col-auto light-text-1" style="position:relative;">
                              {{'solved_by'|translate}}
                              <img [src]="review.solvedByProfilePicture" alt="profile-picture"
                              *ngIf="review.solvedByProfilePicture && review.solvedByProfilePicture != '' &&
                              review.solvedByProfilePicture != 'undefined' && review.solvedByProfilePicture != 'null'
                              && review.solvedByProfilePicture != undefined"
                              height="19px" width="19px" class="border-radius-50">
                              <img src="../../../../../assets/images/new_help_icons/initials-img.svg"
                               alt="solvedByProfilePicture-picture"
                              *ngIf="!review.solvedByProfilePicture || review.solvedByProfilePicture == '' ||
                              review.solvedByProfilePicture == 'undefined' || review.solvedByProfilePicture == 'null'
                              || review.solvedByProfilePicture == undefined"
                              height="19px" width="19px" class="border-radius-50">
                              <span class="initials-2"
                             *ngIf="!review.solvedByProfilePicture || review.solvedByProfilePicture == '' ||
                             review.solvedByProfilePicture == 'undefined' || review.solvedByProfilePicture == 'null'
                             || review.solvedByProfilePicture == undefined">
                                {{getInitial(review.solvedById)}}
                              </span>
                            </div>
                          </div>
                          <div class="row justify-content-end">
                            <div class="col-auto solved-by-name"
                            (click)="$event.stopPropagation();navigateToProfile(review.solvedById)">
                              @{{review.solvedById}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center plr-24">
              <div class="col-12 mt-24 p-0">
                <div class="card card-body video-card" (click)="playKnowMoreVideo()"
                style="padding:0px;">
      
                  <iframe class="match_height_width" title="" [src]="knowMoreVideo" allow='autoplay'
                  webkitallowfullscreen mozallowfullscreen allowfullscreen
                  style="width:100%; height: 100%" ></iframe>
                  <!-- <img src="../../../../../assets/images/play-btn.svg" alt="play-btn"
                  class="play-btn"> -->
                </div>
              </div>
            </div>
            <div class="row justify-content-center plr-24">
              <div class="col-12 text-center text text-1 color-1 mt-40 w-700">
                {{'faqs_'|translate}}
              </div>
            </div>
            <div class="row justify-content-center plr-24">
              <div class="col-12 p-0">
                <div class="accordion" id="faqSeries">
                  <div class="card accrodian-card mt-24" 
                  (click)="showHideFaq(faq)"
                  *ngFor="let faq of faqList | slice:0:showFaq;let i=index">
                    <div class="card-header" id="headingOne"  data-toggle="collapse" 
                    [attr.data-target]="'#' + faq.id" aria-expanded="true" aria-controls="collapseOne">
                     <div class="row">
                      <div class="col-auto sm-mw-255  content-center">
                        {{faq.label|translate}}
                      </div>
                      <div class="col-auto ml-auto" 
                      [ngClass]="{'hidden':faq.hidden,'show-img':!faq.hidden}">
                        <img src="../../../assets/images/arrow-right-icon.svg" alt="right-arrow">
                      </div>
                     </div>
                    </div>
                
                    <div id={{faq.id}} class="collapse" aria-labelledby="headingOne" 
                    data-parent="#faqSeries" (click)="$event.stopPropagation()">
                      <div class="card-body">
                        <p *ngFor="let desc of faq.desc">{{desc|translate}} </p>
                        <span *ngIf="i ==1" (click)="goToConfidenceBuilding()" class="link">
                          {{'click_here'|translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center plr-24">
              <div class="col-12 text-center mt-24">
                <button class="transparent-btn" (click)="viewMoreFaq()" *ngIf="showFaq < faqList.length">
                  {{'view_more'|translate}}
                </button>
              </div>
            </div>
            <div class="row justify-content-center plr-24">
              <div class="col-12 text-center mt-40 mb-24 mb-24 p-0">
                <div class="card hr-solved">
                  <div class="row">
                    <div class="col-12 text-center count">
                      {{hrSolved}}
                    </div>
                    <div class="col-12 text-center desc">
                      {{'request_solved_in_last_7_days'|translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center plr-24" 
      style="background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 17.19%);">
        <div class="col-12 content-center p-0">
          <button class="paid-help-btn" (click)="getPaidHelp()">
            {{'request_paid_help'|translate}}
          </button>
        </div>
        <div class="col-12 mt-8 text-center">
          <!-- <span class="text text-3 color-2 w-400">Paid help usually costs between </span> -->
          <span class="text text-3 color-2 w-400"> {{'paid_help_usually_cost'|translate}}</span>
        </div>
      </div>
    </div>
    <div class="row lg-show" *ngIf="knowMore" style="position: absolute; bottom: 32px; right: 32px;">
      <div class="col-auto">
        <div class="img-circle-2" (click)="goToKnowMore()">
          <img src="../../../assets/images/arrow-right-icon.svg" alt="arrow-right-icon">
        </div>
      </div>
    </div>
  </div>  
</div>



