<div class="mainbox">
  <div class="generatingtext">{{'generating'}}...</div>
  <div class="mainheadingtext">{{'Civic Participation Card'}}</div>
  <div class="text-center loadermain" style="margin-bottom: 22px;">
    <div class="loader"></div>
    <img class="loaderimage" src="../../../assets/images/loader_timer.svg"  alt="loader-timer"/>
  </div>
  <div class="subheadingText">
    {{'Download the card'}}
  </div>
</div>