<!-- <header #topNavbar id="topNavbar" class="box-shadow-topbar">
    <div class="container-fluid m-0 p-0">
        <div class="row header-row align-items-center m-0 h-100">
            <div class="logo-box h-100">
                <mat-toolbar color="primary" class="top-bar MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">
                    <div class="d-flex w-100 justify-content-between" style="padding: 11px 0;">
                    <div class="d-flex" style="z-index: 100;">
                        <div class="flex-center">
                            <img class="mr-40 jaano_image" style="margin-left: 15px;" [height]="40" [width]="40"
                                src="../../../assets/images/jaanoLogo.svg" />
                            <div class="nav-location">
                                <div class="d-md-inline-block district" (click)="openChangeLocationDialog()">
                                    <img height="20" class="mr-2" src="../../../assets/images/location_pin.svg" />
                                    <span matTooltip="{{'location'| translate}}">{{userCurrentPlace}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex m-auto sm-hide" style="position: absolute; width: 100vw; justify-content: center; top: 0;">
                        <div class="mode" (click)="modeChangeHandler('home'); goToHome()"
                            [ngClass]="mode == 'home' ? 'selected_mode' : ''" mat-raised-button>
                            <ul class="img-wrapper">
                                <img matTooltip="{{'home'| translate}}" [src]="mode == 'home' ? '../../../assets/images/home-page-active.svg' :'../../../assets/images/home-page.svg'"
                                    alt="home" />
                            </ul>
                        </div>
                        <div class="mode" (click)="modeChangeHandler('feeds'); navigateToFeed(0)"
                            [ngClass]="mode == 'feeds' ? 'selected_mode' : ''">
                            <ul class="img-wrapper">
                                <img matTooltip="{{'Help_requests'| translate}}" [src]="mode == 'feeds' ? '../../../assets/images/offer-help-active.svg' :'../../../assets/images/offer-help.svg'"
                                    alt="help" />
                            </ul>
                        </div>
                        <div class="mode" (click)="modeChangeHandler('official'); navigateToFeed(2)"
                            [ngClass]="mode == 'official' ? 'selected_mode' : ''">
                            <ul class="img-wrapper">
                                <img matTooltip="{{'official_feed'| translate}}" [src]="mode == 'official' ? '../../../assets/images/official-feed-active.svg' :'../../../assets/images/official-feed.svg'"
                                    alt="Official" />
                            </ul>
                        </div>
                    </div>
                    <div class="d-inline-flex" style="align-items: center;">
                        <div class="d-md-inline-block d-none d-flex profile_icon p-relative mr-40" *ngIf="isUserLoggedIn"
                            [ngClass]="mode == 'user-profile' || isUserLoggedIn ? '' : ''"
                            (click)="isUserLoggedIn ? '': openLoginDialog()">
                            <span id="game_jp" class="game_jp" (click)="goToGamification()" matTooltip="{{'Jaano Points'| translate}}">
                                <img style="width: 20px; height: 20px;" alt="Coins"
                                    src="../../../assets/images/jaano_points_header.svg">
                                <ng2-odometer [number]="jaano_points" [theme]="'default'" animation="count">
                                </ng2-odometer>
                                <span>
                                </span>
                            </span>
                        </div>
                        <div class="d-md-inline-block d-none d-flex profile_icon mr-40 p-relative" *ngIf="!isUserLoggedIn" style="color: #454648;" matTooltip="{{'login'| translate}}"
                        (click)="openLoginDialog()" style="display: flex !important;
                        justify-content: center;">
                            <span class="img-icon">
                                <img src="../../../assets/images/menu/login-icon.svg" style="width:20px;
                                height:20px; "
                                 alt="login-icon" />
                            </span> 
                            <span class="login-text">{{'login'| translate| uppercase}}</span>
                        </div>
                        <div class="d-md-inline-block d-none d-flex mr-40 profile_icon mr-40 p-relative" 
                         href="javascript:void(0)" *ngIf="isUserLoggedIn"
                         (click)="navigateToNotifications()" matTooltip="{{'notification'| translate}}">
                            <img height="20" src="../../../assets/images/menu/menu_notification.svg" />
                        </div>
                        <img class="mr-40 p-relative" matTooltip="{{'help'| translate}}" (click)="modeChangeHandler('aboutus')" height="20" src="../../../assets/images/help_question.svg" />
                        <span>
                        <div class="dropdown" style="margin-right: 15px;">
                            <button class="btn dropdown-toggle p-0 align-text-bottom" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" matTooltip="{{'menu'| translate}}">
                                <img height="20" src="../../../assets/images/dropdown_header.svg" />
                            </button>
                            <div class="dropdown-menu no-padding" aria-labelledby="dropdownMenuButton"
                                style="right: 0; left: unset;">
                                <a class="dropdown-item border-radius-0 auto-width"
                                 *ngIf="isUserLoggedIn" href="javascript:void(0)"
                                    (click)="modeChangeHandler('user-profile')">
                                    <img src="../../../assets/images/menu/menu_profile1.svg" 
                                     class="mr-16 icon1" width="32px" height="32px" alt="menu-icon1" />
                                     <img src="../../../assets/images/menu/menu_profile2.svg" 
                                      class="mr-16 icon2" width="32px" height="32px" alt="menu-icon1" />
                                    {{'my_profile'| translate}}</a>
                                <a class="dropdown-item border-radius-0 auto-width" href="javascript:void(0)" 
                                (click)="openChangeLanguageDialog()">
                                    <img src="../../../assets/images/menu/menu_language1.svg"
                                    class="mr-16 icon1" width="32px" height="32px" alt="menu-icon2" />
                                    <img src="../../../assets/images/menu/menu_language2.svg"
                                    class="mr-16 icon2" width="32px" height="32px" alt="menu-icon2" />
                                    {{'change_language'| translate}}</a>
                                <a class="dropdown-item border-radius-0 auto-width" href="javascript:void(0)"
                                    (click)="modeChangeHandler('aboutus')">
                                    <img src="../../../assets/images/menu/menu_about_us1.svg"
                                    class="mr-16 icon1" width="32px" height="32px" alt="menu-icon3" />
                                    <img src="../../../assets/images/menu/menu_about_us2.svg"
                                    class="mr-16 icon2" width="32px" height="32px" alt="menu-icon3" />
                                    {{'about_us'| translate}}</a>
                                <a class="dropdown-item border-radius-0 auto-width" href="javascript:void(0)"
                                    (click)="modeChangeHandler('privacy_policy')">
                                    <img src="../../../assets/images/menu/menu_privacy_policy1.svg"
                                    class="mr-16 icon1" width="32px" height="32px" alt="menu-icon4" />
                                    <img src="../../../assets/images/menu/menu_privacy_policy2.svg"
                                    class="mr-16 icon2" width="32px" height="32px" alt="menu-icon4" />
                                    {{'privacy_policy'| translate}}</a>
                                <a class="dropdown-item border-radius-0 auto-width" href="javascript:void(0)"
                                    (click)="modeChangeHandler('terms_of_use')">
                                    <img src="../../../assets/images/menu/menu_terms_of_use1.svg"
                                    class="mr-16 icon1" width="32px" height="32px" alt="menu-icon5" />
                                    <img src="../../../assets/images/menu/menu_terms_of_use2.svg"
                                    class="mr-16 icon2" width="32px" height="32px" alt="menu-icon5" />
                                    {{'terms_of_use'| translate}}</a>
                                <a class="dropdown-item border-radius-0 auto-width" href="javascript:void(0)"
                                    (click)="modeChangeHandler('refund_policy')">
                                    <img src="../../../assets/images/menu/menu_refund_policy1.svg"
                                    class="mr-16 icon1" width="32px" height="32px" alt="menu-icon6" />
                                    <img src="../../../assets/images/menu/menu_refund_policy2.svg"
                                    class="mr-16 icon2" width="32px" height="32px" alt="menu-icon6" />
                                    {{'refund_policy'| translate}}</a>
                                <a class="dropdown-item border-radius-0 auto-width" href="javascript:void(0)" 
                                 *ngIf="isUserLoggedIn"
                                    (click)="goToNotificationsSettings()">
                                    <img src="../../../assets/images/menu/menu_settings1.svg"
                                    class="mr-16 icon1" width="32px" height="32px" alt="menu-icon7" />
                                    <img src="../../../assets/images/menu/menu_settings2.svg"
                                    class="mr-16 icon2" width="32px" height="32px" alt="menu-icon7" />
                                    {{'action_settings'| translate}}</a>
                                <a class="dropdown-item border-radius-0 auto-width" 
                                *ngIf="isUserLoggedIn" href="javascript:void(0)"
                                    (click)="logOut()">
                                    <img src="../../../assets/images/menu/menu_logout1.svg"
                                    class="mr-16 icon1" width="32px" height="32px" alt="menu-icon8" />
                                    <img src="../../../assets/images/menu/menu_logout2.svg"
                                    class="mr-16 icon2" width="32px" height="32px" alt="menu-icon8" />
                                    {{'LogOut'| translate}}</a>
                            </div>
                        </div>
                        </span>
                    </div>
                    </div>
                    <div class="d-flex h-100 m-auto md-hide justify-content-between align-items-center">
                        <div class="mode tabmode" (click)="modeChangeHandler('home'); goToHome()"
                            [ngClass]="mode == 'home' ? 'selected_mode' : ''" mat-raised-button>
                            <ul class="img-wrapper">
                                <img matTooltip="{{'home'| translate}}"  height="16" [src]="mode == 'home' ? '../../../assets/images/home-page_white.svg' :'../../../assets/images/home-page.svg'"
                                    alt="home" />
                            </ul>
                            <span class="d-md-inline-block ml-2 p-0">{{'home' | translate|uppercase}}</span>
                        </div>
                        <div class="mode tabmode" (click)="modeChangeHandler('feeds'); navigateToFeed(0)"
                            [ngClass]="mode == 'feeds' ? 'selected_mode' : ''">
                            <ul class="img-wrapper">
                                <img matTooltip="{{'Help_requests'| translate}}"  height="16" [src]="mode == 'feeds' ? '../../../assets/images/offer-help_white.svg' :'../../../assets/images/offer-help.svg'"
                                    alt="help" />
                            </ul>
                            <span class="d-md-inline-block ml-2 p-0">{{'offer_help' | translate|uppercase}}</span>
                        </div>
                        <div class="mode tabmode" (click)="modeChangeHandler('official'); navigateToFeed(2)"
                            [ngClass]="mode == 'official' ? 'selected_mode' : ''">
                            <ul class="img-wrapper">
                                <img matTooltip="{{'official_feed'| translate}}" height="16" [src]="mode == 'official' ? '../../../assets/images/official-feed_white.svg' :'../../../assets/images/official-feed.svg'"
                                    alt="Official" />
                            </ul>
                            <span class="d-md-inline-block ml-2 p-0">{{'official_feed' | translate|uppercase}}</span>
                        </div>
                    </div>
                </mat-toolbar>
                <mat-menu #appMenu="matMenu">
                    <div id="myNav" class="overlay" (click)="closeNav()">
                        <div class="overlay-content">
                            <div class="login-box" *ngIf="!isUserLoggedIn">
                                <div class="d-flex" style="padding: 0 1.7rem;" (click)="openLoginDialog()">
                                    <ul class="sideBar-img" style="margin-right: 1rem;">
                                        <li id="logout-icon"></li>
                                    </ul>
                                    {{'log_in' | translate}}
                                </div>
                                <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">
                                    <div class="sideBar-cross"></div>
                                </a>
                            </div>
                            <div class="login-box mb-3 pl-24" *ngIf="isUserLoggedIn">
                                <div class="d-flex">
                                    <div class="profile-img mr-2"
                                        *ngIf="profile_picture!= 'undefined' && profile_picture != null && profile_picture != '' && profile_picture != 'null'">
                                        <img src="{{profile_picture}}" alt="Image"></div>
                                    <div class="user-profile"
                                        *ngIf="profile_picture== 'undefined' || profile_picture == null || profile_picture == '' || profile_picture == 'null'">
                                        <div class="initial">{{initial}}</div>
                                    </div>
                                    <div>
                                        <div class="user-name">{{username}}</div>
                                        <div class="fs-13" [ngClass]="{'select-menu': mode === 'user-profile'}"
                                            (click)="modeChangeHandler('user-profile')" *ngIf="isUserLoggedIn">
                                            {{'my_account' | translate}}</div>
                                    </div>
                                </div>
                                <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">
                                    <div class="sideBar-cross"></div>
                                </a>
                            </div>
                            <div class="blue-hr">
                                <hr>
                            </div>
                            <div class="wrapper-side-menu">
                                <a href="javascript:void(0)" [ngClass]="{'select-menu': mode === 'home'}"
                                    (click)="modeChangeHandler('home')">
                                    <span>
                                        <ul class="sideBar-img">
                                            <li id="home"></li>
                                        </ul>
                                    </span>
                                    {{'menu_home' | translate}} </a>

                                <a [ngClass]="{'select-menu': mode === 'feeds'}">
                                    <span>
                                        <ul class="sideBar-img">
                                            <li id="feed-icon"></li>
                                        </ul>
                                    </span>
                                    {{'feeds' | translate}}
                                </a>

                                <div class="move_right">
                                    <a href="javascript:void(0)" (click)="navigateToFeed(0)">
                                        <span>
                                            <ul class="sideBar-img">
                                                <li id="help-icon"></li>
                                            </ul>
                                        </span>
                                        {{'help_requests' | translate}}</a>

                                    <a href="javascript:void(0)" (click)="navigateToFeed(2)">
                                        <span>
                                            <ul class="sideBar-img">
                                                <li id="official-icon"></li>
                                            </ul>
                                        </span>
                                        {{'official_feed' | translate}}</a>
                                </div>

                                <a href="javascript:void(0)" (click)="openChangeLanguageDialog()">
                                    <span>
                                        <ul class="sideBar-img">
                                            <li id="lang-icon"></li>
                                        </ul>
                                    </span>
                                    {{'select_preferred_language' | translate}}
                                </a>
                                <a href="javascript:void(0)" (click)="openChangeLocationDialog()">
                                    <span>
                                        <ul class="sideBar-img">
                                            <li id="location-icon"></li>
                                        </ul>
                                    </span>
                                    {{'change_location' | translate}}
                                </a>
                                <a href="javascript:void(0)" [ngClass]="{'select-menu': mode === 'information_center'}"
                                    *ngIf="false" (click)="modeChangeHandler('information_center')">
                                    <span>
                                        <ul class="sideBar-img">
                                            <li id="info-icon"></li>
                                        </ul>
                                    </span>
                                    {{'COVID-19_info_center' | translate}}</a>
                                <a href="javascript:void(0)" [ngClass]="{'select-menu': mode === 'aboutus'}"
                                    (click)="modeChangeHandler('aboutus')">
                                    <span>
                                        <ul class="sideBar-img">
                                            <li id="about-icon"></li>
                                        </ul>
                                    </span>
                                    {{'about_us' | translate}}</a>

                                <a (click)="loadTourService()">
                                    <span>
                                        <ul class="sideBar-img">
                                            <li id="guide-icon"></li>
                                        </ul>
                                    </span>
                                    {{'guideTour' | translate}}
                                </a>

                                <a (click)="goToNotificationsSettings()">
                                    <span>
                                        <ul class="sideBar-img">
                                            <li id="settings-icon"></li>
                                        </ul>
                                    </span>
                                    {{'notification_settings' | translate}}
                                </a>

                                <div class="login-box" *ngIf="!isUserLoggedIn">
                                    <div *ngIf="isUserLoggedIn" (click)="logOut()" class="text-white logInOutButton">
                                        {{'LogOut' | translate}}</div>
                                    <a href="javascript:void(0)" (click)="openLoginDialog()">
                                        <span>
                                            <ul class="sideBar-img">
                                                <li id="logout-icon"></li>
                                            </ul>
                                        </span> {{'log_in' | translate}} </a>
                                </div>
                                <div class="login-box" *ngIf="isUserLoggedIn">
                                    <a href="javascript:void(0)" (click)="logOut()">
                                        <span>
                                            <ul class="sideBar-img">
                                                <li id="logout-icon"></li>
                                            </ul>
                                        </span> {{'LogOut' | translate}} </a>
                                </div>
                            </div>
                            <div class="blue-hr">
                                <hr>
                            </div>
                            <div class="version-block">
                                <div class="versionDiv">
                                    <span>v2.17(15th Nov 2021)</span>
                                </div>
                                <div class="ind-flag"></div>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
</header>
<button class="info-btn" *ngIf="showInfoIcon && (remainingTime > 0 || hasOffer) && username" (click)="goToOffer()">
    <div style="display: flex;
          height: 80px;
          width: 80px;
          justify-content: center;
          align-items: center;"  *ngIf="remainingTime > 0 "
          (click)="goToOffer(); $event.stopPropagation()">
        <span class="text-1">
            {{timerText}}
        </span>
    </div>
    <div style="display:block" *ngIf="hasOffer" class="text-1">
        <div style="display:block; justify-content: center;align-items: center; margin-bottom: 4px;">
            {{hasOffer}}
        </div>
        <div style="display:block; justify-content: center;align-items: center;" class="text-2">
            {{'offer_recived'|translate}}
        </div>
    </div>
</button> -->

<header #topNavbar  id="topNavbar11" (click)="closeNavbar()" style="position: sticky; z-index: 1000; width: 100%;">
    <div class="container-fluid m-0 p-0">
        <div class="row m-0 h-48 justify-content-between top-bar-menu">
            <div class="col-auto content-center cursor-pointer" style="width:32px"
            (click)="openNavbar(); $event.stopPropagation();" *ngIf="showMenu()">
                <img src="../../../assets/images/burger-menu-icon.svg" alt="burger-menu-icon"
                width="32px" height="32px">
            </div>
            <div class="col-auto content-center cursor-pointer" style="width:32px"
            (click)="onBack();" *ngIf="!showMenu()">
                <img src="../../../assets/images/back-icon-1.svg" alt="back-icon-1"
                width="32px" height="32px">
            </div>
            <div class="col-auto content-center cursor-pointer" style="width:116px"
            (click)="modeChangeHandler('home'); goToHome(); jaanoAnalyticLogo()">
                <img src="../../../assets/images/jaano-icon-3.svg" alt="jaano-icon-3"
                width="116px" height="28px">
            </div>
            <div class="col-auto content-center cursor-pointer" style="width: 32px;" *ngIf="(fromExpandedView() || isUserLoggedIn)"
            (click)="$event.stopPropagation();openLanguageMenu()">
                <img src="../../../assets/images/new-images/language-icon.svg" alt="language-icon"
                width="32px" height="32px" >
            </div>
            <!-- <div class="col-auto content-center cursor-pointer" style="width:32px" *ngIf="!fromExpandedView() && isUserLoggedIn
            && abTestingType != ABTestingType.New"
            (click)="navigateToNotifications()">
                <img src="../../../assets/images/notification-icon-3.svg" alt="notification"
                width="32px" height="32px" *ngIf="mode != 'notifications'">
                <img src="../../../assets/images/notification-icon-4.svg" alt="notification"
                width="32px" height="32px"*ngIf="mode == 'notifications'">
            </div> -->
            <div class="col-auto content-center cursor-pointer" style="width:32px" *ngIf="!fromExpandedView() && !isUserLoggedIn"
            (click)="openLoginDialog()">
                <img src="../../../assets/images/login-icon-1.svg" alt="login-icon"
                width="32px" height="32px" >
            </div>
        </div>
    </div>
</header>
<footer id="bottomNavbar"  *ngIf="showFooter() && !showFooterException" 
style="bottom: 0px; width: 100%;  position: fixed; z-index: 999;" 
(click)="closeNavbar();$event.stopPropagation();">
    <div class="container p-20-0">
        <div class="footer-shadow">
            <div class="row m-0 h-48 justify-content-between bootom-navbar"
            *ngIf="!isClosedExpandedView()">
                <div class="col-auto content-center" 
                style="display:inline-block !important; cursor: pointer;"
                (click)="modeChangeHandler('home'); goToHome(); googleAnalayticHome();">
                    <div class="content-center">
                        <img src="../../../assets/images/home-active-icon.svg" alt="home-inactive-icon"
                        width="24px" height="24px" *ngIf="mode == 'home'">
                        <img src="../../../assets/images/home-inactive-icon.svg" alt="home-inactive-icon"
                        width="24px" height="24px" *ngIf="mode != 'home'">
                    </div>
                    <div  class="content-center"
                    [ngClass]="mode == 'home' ? 'menu-label-active' : 'menu-label'">
                        {{'home'|translate}}
                    </div>
                </div>
                <div class="col-auto content-center cursor-pointer" style="width:40px;" 
                (click)="addRequest()"
                *ngIf="(remainingTime <= 0 && !hasOffer) || !username">
                    <img src="../../../assets/images/add-hr-icon.svg" alt="add-hr-icon"
                    width="40px" height="40px;">
                </div>
                <div class="addHrBanner" *ngIf="(hasOffer || remainingTime > 0) && username">
                  <div class="add-btn">
                    <ng-container *ngIf="!hasOffer && remainingTime > 0 && username">
                      <div style="display: flex;
                      height: 40px;
                      width: 40px;
                      justify-content: center;
                      align-items: center;" (click)="goToOffer(); $event.stopPropagation()">
                        <span class="text-11">{{timerText}}</span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!hasOffer && remainingTime == 0">
                      <img src="../../../../assets/images/add-hr.svg" alt="Add"
                      width="44.5px" height="44.5px"
                      class="rotation-icon2"  (click)="addRequest()">
                    </ng-container>
                    <ng-container *ngIf="hasOffer && username">
                      <div style="display: block;
                        height: 40px;
                        width: 40px;
                        justify-content: center;
                        align-items: center; " class="animation-1" (click)="goToOffer(); $event.stopPropagation()">
                        <div class="text-1 animation-1" style="display: flex;
                        justify-content: center;
                        align-items: center;margin-bottom: 2px;">
                          {{hasOffer}}
                        </div>
                        <div class="text-2 animation-1" style="display: flex;
                        text-align:center;justify-content: center;">
                          {{'offer'|translate}}
                        </div>
                      </div>
                      <img src=".../../../../../../assets/images/rotation-logo1.svg" alt="Add"
                       class="rotation-icon animation-2" (click)="goToOffer(); $event.stopPropagation()">
                      <img src=".../../../../../../assets/images/rotation-logo2.svg" alt="Add"
                      class="name-icon animation-3" (click)="goToOffer(); $event.stopPropagation()">
                      <img src="../../../../assets/images/add-hr-icon.svg" alt="Add"
                      width="40px" height="40px"
                      class="rotation-icon2 animation-4"(click)="addRequest()">
                      <img src=".../../../../../../assets/images/rotation-logo1.svg" alt="Add"
                       class="rotation-icon animation-5"(click)="addRequest(); $event.stopPropagation()">
                       <img src=".../../../../../../assets/images/rotation-logo2.svg" alt="Add"
                       class="name-icon animation-6"(click)="addRequest(); $event.stopPropagation()">
                    </ng-container>
                  </div>
                </div>
                <div class="col-auto content-center" 
                style="display:inline-block !important; cursor: pointer;"
                (click)="modeChangeHandler('user-profile');jaanoAnalyticProfile()">
                    <div  class="content-center">
                        <img src="../../../assets/images/profile-active-icon.svg" 
                        alt="profile-inactive-icon" width="24px" height="24px" 
                        *ngIf="mode == 'user-profile' && notLeaderboard()">
                        <img src="../../../assets/images/profile-inactive-icon.svg" 
                        alt="profile-inactive-icon" width="24px" height="24px"
                        *ngIf="mode != 'user-profile' || !notLeaderboard()">
                    </div>
                    <div  class="content-center"
                    [ngClass]="mode == 'user-profile' && notLeaderboard() ? 'menu-label-active' : 'menu-label'">
                        {{'Profile'|translate}}
                    </div>
                </div>
            </div>
            <div class="row m-0 h-48 justify-content-between bootom-navbar create-help-footer"
            *ngIf="isClosedExpandedView()" (click)="addRequest()">
                <div class="col-12 text-center">
                    <div>
                        {{'need_help'|translate}}
                    </div>
                    <div>
                        <span>
                            <img [src]="gifFile"
                             alt="create-help"  width="20px" height="20px">
                        </span>
                        {{'create_request_now'|translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>


<div id="mySidenav" class="sidenav">
    <div class="header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-auto login-block pl-0 content-center" *ngIf="!isUserLoggedIn">
                    <button (click)="closeNavbar();openLoginDialog()" 
                    class="transparent-btn mr--4">
                        <img src="../../../assets/images/login-icon-2.svg" alt="login-icon"
                        width="24px" height="24px">
                    </button>
                    <div class="text">
                        {{'login'|translate}}
                    </div>
                </div>
                <div class="col-auto login-block pl-0 content-center" *ngIf="isUserLoggedIn">
                    <button class="transparent-btn mr--4" 
                    (click)="closeNavbar();modeChangeHandler('user-profile')">
                        <span>{{getInitials(username)}}</span>
                    </button>
                    <div style="display:inline-block;" class="cursor-pointer"
                    (click)="closeNavbar();modeChangeHandler('user-profile')">
                        <div class="text">
                            {{username}}    
                        </div>
                        <!-- <div class="text content-center" style="justify-content:flex-start;">
                            <img src="../../../assets/images/jaano-points-icon.svg" alt="jaano-points"
                            width="18px" height="18px">
                            <span>{{jaano_points}}</span>   
                        </div> -->
                    </div>
                </div>
                <div class="col-auto ml-auto content-center pr-0">
                    <button (click)="closeNavbar()"  class="transparent-btn">
                        <img src="../../../assets/images/back-icon-1.svg" alt="back-icon-1"
                        width="32px" height="32px">
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="body">
        <div class="items mt-8" (click)="closeNavbar();openChangeLocationDialog()">
            <div class="mr--4">
                <img src="../../../assets/images/location-icon-4.svg" alt="gps-icon"
                width="18px" height="18px" class="img-1">
                <img src="../../../assets/images/location-icon-5.svg" alt="gps-icon"
                width="18px" height="18px" class="img-2">
            </div>
            <div class="">
                {{userCurrentPlace || 'select location'|translate}}
            </div>
        </div>
        <div class="items mt-16" (click)="closeNavbar();openChangeLanguageDialog()">
            <div class="mr--4">
                <img src="../../../assets/images/language-icon.svg" alt="gps-icon"
                width="18px" height="18px" class="img-1">
                <img src="../../../assets/images/language-icon-2.svg" alt="gps-icon"
                width="18px" height="18px" class="img-2">
            </div>
            <div class="">
                {{'selectedLanguage'|translate}}
            </div>
        </div>
        <!-- ==> (changes) uncomment the following first commented block.. -->
        <!-- <div class="items mt-16" (click)="closeNavbar();goToGamification('refund_policy')" *ngIf="isUserLoggedIn">
            <div class="mr--4">
                <img src="../../../assets/images/refer-friend-2.svg" alt="refer-icon"
                width="18px" height="18px" class="img-1">
                <img src="../../../assets/images/refer-friend.svg" alt="refer-icon"
                width="18px" height="18px" class="img-2">
            </div>
            <div class="">
                {{'refer_a_friend'|translate}}
            </div>
        </div> -->
        <!-- <div class="items mt-16" (click)="closeNavbar();modeChangeHandler('refund_policy')">
            <div class="mr--4">
                <img src="../../../assets/images/refund-policy-icon.svg" alt="refund-icon"
                width="18px" height="18px" class="img-1">
                <img src="../../../assets/images/refund-policy-icon-2.svg" alt="refund-icon"
                width="18px" height="18px" class="img-2">
            </div>
            <div class="">
                {{'refund_policy'|translate}}
            </div>
        </div> -->
        <div class="items mt-16" (click)="closeNavbar();modeChangeHandler('aboutus')">
            <div class="mr--4">
                <img src="../../../assets/images/about-us-icon-3.svg" alt="gps-icon"
                width="18px" height="18px"  class="img-1">
                <img src="../../../assets/images/about-us-icon-4.svg" alt="gps-icon"
                width="18px" height="18px"  class="img-2">
            </div>
            <div class="">
                {{'about_us'|translate}}
            </div>
        </div>
        <div class="items mt-16" (click)="closeNavbar();modeChangeHandler('terms_of_use')">
            <div class="mr--4">
                <img src="../../../assets/images/doc-icon-3.svg" alt="gps-icon"
                width="18px" height="18px" class="img-1">
                <img src="../../../assets/images/doc-icon-4.svg" alt="gps-icon"
                width="18px" height="18px" class="img-2">
            </div>
            <div class="">
                {{'terms_of_use'|translate}}
            </div>
        </div>
        <div class="items mt-16" (click)="closeNavbar();modeChangeHandler('privacy_policy')">
            <div class="mr--4">
                <img src="../../../assets/images/privacy-policy-icon.svg" alt="gps-icon"
                width="18px" height="18px" class="img-1">
                <img src="../../../assets/images/privacy-policy-icon-2.svg" alt="gps-icon"
                width="18px" height="18px" class="img-2">
            </div>
            <div class="">
                {{'privacy_policy'|translate}}
            </div>
        </div>
        <div class="items mt-16" *ngIf="isUserLoggedIn" (click)="closeNavbar();logOut()">
            <div class="mr--4">
                <img src="../../../assets/images/logout-icon-3.svg" alt="gps-icon"
                width="18px" height="18px" class="img-1">
                <img src="../../../assets/images/logout-icon-4.svg" alt="gps-icon"
                width="18px" height="18px" class="img-2">
            </div>
            <div class="">
                {{'LogOut'|translate}}
            </div>
        </div>
    </div>
</div>
<div class="language-wrapper" (click)="closeNavbar()" id="languageWrapper">
    <div id="languageNav" class="right-side-nav">
        <div class="wrapper">
            <div class="header">
                Change language
            </div>
            <div class="body">
                <div class="element" *ngFor="let lang of languages" 
                (click)="onChangeLanguage(lang.lang)" [ngClass]="{'active':lang.lang == language}">
                    <span>{{lang.language}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid hide-card" id="overlayBox11" (click)="closeNavbar()">
    <div class="row">
        <div class="col-12 overlay-box p-0" >
        </div>
    </div>
</div>