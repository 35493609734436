<!-- <div *ngIf="dialogOne">
  <mat-dialog-content class="mat-dialog-content-2" *ngIf="dialogOne">

    <p>
      {{'Did our suggestions solve the
      problem you had ?'|translate}} 
    </p>
  </mat-dialog-content>
</div>

<div *ngIf="dialogTwo">
  <button mat-button class="close-icon float-right" [mat-dialog-close]="true" (click)="close()" *ngIf="dialogTwo">
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.285101 0.273312C0.4604 0.0983329 0.698109 0 0.945964 0C1.19382 0 1.43152 0.0983329 1.60682 0.273312L6.01257 4.67298L10.4183 0.273312C10.5948 0.10435 10.8306 0.011146 11.0751 0.013728C11.3196 0.01631 11.5533 0.114474 11.7262 0.287126C11.8991 0.459778 11.9974 0.693181 11.9999 0.937334C12.0025 1.18149 11.9092 1.41691 11.74 1.59318L7.33429 5.99289L11.74 10.3926C11.9092 10.5688 12.0025 10.8043 11.9999 11.0484C11.9974 11.2926 11.8991 11.526 11.7262 11.6987C11.5533 11.8713 11.3196 11.9694 11.0751 11.972C10.8306 11.9746 10.5948 11.8814 10.4183 11.7125L6.01257 7.31276L1.60965 11.7125C1.52355 11.8022 1.42038 11.8738 1.3062 11.9232C1.19201 11.9725 1.06911 11.9986 0.944692 11.9999C0.820275 12.0013 0.696847 11.9777 0.581643 11.9308C0.46644 11.8838 0.36178 11.8144 0.273799 11.7266C0.185817 11.6387 0.116285 11.5342 0.0692784 11.4192C0.0222717 11.3041 -0.00126347 11.1808 5.22725e-05 11.0566C0.00136801 10.9323 0.0275085 10.8096 0.076941 10.6956C0.126374 10.5815 0.198103 10.4785 0.287925 10.3926L4.69367 5.99289L0.287925 1.59318C0.11233 1.4185 0.0133902 1.18136 0.0128606 0.933848C0.0123309 0.686335 0.110255 0.448743 0.285101 0.273312Z"
        fill="#AFA3A3" />
    </svg>
  </button>
  <mat-dialog-content class="mat-dialog-content-1" *ngIf="dialogTwo">
    <div class="borderbox">
      <svg width="41" height="40" viewBox="0 0 41 40">
        <g id="noun-customer-support-4433879" transform="translate(-78.489 -15.445)">
          <path id="Path_8728" data-name="Path 8728"
            d="M116.28,35.716c0-.253,0-.5,0-.772,0-10.752-7.758-19.5-17.3-19.5S81.7,24.192,81.7,34.954c0,.268,0,.513,0,.762-4.7.707-4.253,9.421,1.427,8.89a9.293,9.293,0,0,0,1.707-.265l-.2-.822a30.918,30.918,0,0,1-.9-8.575c0-9.627,6.844-17.459,15.253-17.459s15.255,7.832,15.255,17.459a68.863,68.863,0,0,1-.373,7.94A22.271,22.271,0,0,1,102.4,47.789a2.262,2.262,0,0,0-1.939-1.11H97.52a2.271,2.271,0,0,0,0,4.543h2.939a2.262,2.262,0,0,0,2.25-2.095,23.666,23.666,0,0,0,11.374-4.651c6.4,1.394,7.1-8.025,2.2-8.761Z"
            fill="#323233" />
          <path id="Path_8729" data-name="Path 8729"
            d="M189.347,83.5a4.911,4.911,0,0,0-.37-1.717h.007s1.967-19.054-13.874-19.054-13.6,18.392-13.6,18.392v.008a4.539,4.539,0,0,0-.723,2.371A4.649,4.649,0,0,0,164,88.346c.564,2.718,3.189,10.812,11.068,10.812,5.738,0,8.69-4.3,10.088-7.677a19.776,19.776,0,0,1-2.633.954c-1.43,2.468-3.776,4.916-7.455,4.916-7.365,0-9.39-9.816-9.407-9.937l-.118-.7-.706-.052c-1.536-.123-2.308-1.682-2.253-3.092a2.474,2.474,0,0,1,.871-1.9c13.931-.413,16.838-5.4,16.838-5.4a7.822,7.822,0,0,0,6.429,5.44,2.506,2.506,0,0,1,.829,1.861c.056,1.411-.718,2.969-2.256,3.092l-.706.053-.11.7a17.047,17.047,0,0,1-.7,2.343,14.6,14.6,0,0,0,2.242-.907c.045-.183.082-.353.115-.5a4.648,4.648,0,0,0,3.216-4.842Z"
            transform="translate(-76.076 -43.713)" fill="#989696" />
        </g>
      </svg>
      <p style="font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      font-family: Roboto;">
        {{'We have E-mitras who can help you
        personally'|translate}}</p>

      <div class="row">
        <div class="col-12 text-center">
          <p style="font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          font-family: Roboto;">
          {{'our_emitras'|translate}}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-4 p-0">
          <div class="img-icon-1">
            <img src="../../../../../assets/images/certificate-icon.svg" 
            alt="certificate-icon">
          </div>
          <div class="text-fields">
            <div class="text-1">
              {{'certified'|translate}}
            </div>
            <div class="text-1 light-text">
              {{'government'|translate}}
            </div>
          </div>
        </div>
        <div class="col-4 p-0">
          <div class="img-icon-1">
            <img src="../../../../../assets/images/experienced-icon.svg" 
            alt="experienced-iconn">
          </div>
          <div class="text-fields">
            <div class="text-1">
              {{'experienced'|translate}}
            </div>
            <div class="text-1 light-text">
              {{'solving_problems'|translate}}
            </div>
          </div>
        </div>
        <div class="col-4 p-0">
          <div class="img-icon-1">
            <img src="../../../../../assets/images/high-rated-icon.svg" 
            alt="high-rated-ico">
          </div>
          <div class="text-fields">
            <div class="text-1">
              {{'highly_rated'|translate}}
            </div>
            <div class="text-1 light-text">
              {{'by_their_customers'|translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <span class="blue-txt cursor-pointer"
          (click)="openKnowMoreLink()">
            {{'know_more'|translate}}
          </span>
        </div>
      </div>
      <div class="new-box mt-4">
        <button class="request-paid-help" (click)="requestPaidHelp()">
          {{'request_paid_help'|translate}}
          <img src="../../../../../assets/images/post-btn.svg"
            class="ml-3" width="20px" height="20px" alt="send" />
        </button>
      </div>
      <div class="text-center text-grey">
        {{'paid_help_usually_cost'|translate}}
      </div>
    </div>
    <p class="secondpop">{{'or you can'|translate}}</p>
  </mat-dialog-content>
</div>


<div *ngIf="dialogThree">
  <mat-dialog-content class="mat-dialog-content-3">
    <p class="popThree1">
      Thanks a lot for opting for
        paid help!!
      </p>

  </mat-dialog-content>
  <mat-dialog-actions class="popThree2">
    <p>
      We'll reach out to you
        very soon.
    </p>
  </mat-dialog-actions>
</div>

<mat-dialog-actions>

  <button id="yesbutton" mat-button class="no-hover" *ngIf="dialogOne" [mat-dialog-close]="true"
    (click)="numberOfClicks('yes')">
    <img src="../../../../../assets/images/check-icon.svg" width="24px" height="24px"
    alt="check-icon">
    <p style="font-weight: 500;
    font-size: 12px;
    line-height: 14px;">
      {{'Yes'|translate}}
    </p>
  </button>
  <button mat-button class="no-hover1" *ngIf="dialogOne" (click)="numberOfClicks('no')">
    <img src="../../../../../assets/images/cross-icon.svg" width="24px" height="24px"
    alt="cross-icon">
    <p style="font-weight: 500;
    font-size: 12px;
    line-height: 14px;">
      {{'no_'|translate}}
    </p>

  </button>

  <span class="skip-btn" *ngIf="dialogTwo"(click)="close()" >{{'skip'|translate}}</span>
  
  <button mat-button class="mat-button-2" (click)="addRequest()" [mat-dialog-close]="true" *ngIf="dialogTwo">
    {{'Create New Help Request'|translate}}
    <img src="../../../../../assets/images/right_arrow_help2.svg" alt="send-button"
    height="12px" width="12px" style="margin-left:12px" />
  </button>

</mat-dialog-actions> -->

<!-- <div class="container container-1">
  <button class="close-icon float-right"
   (click)="closeOption()" *ngIf="showExtendedCard">
   <img alt="close-icon" height="12" 
   src="../../../../../assets/images/cross-close-icon.svg" width="12">
  </button>
  <div class="row">
    <div class="col-12 text-center w-500 font-18 line-height-21 black">
      {{'new_survey_ques'|translate}}
    </div>
  </div>
  <div class="row justify-content-around mt-40 mb-40" *ngIf="!showExtendedCard">
    <div class="col-auto">
      <button class="survey-btn" [mat-dialog-close]="true"
      (click)="optedYes()">
        {{'Yes'|translate}}
      </button>
    </div>
    <div class="col-auto">
      <button class="survey-btn" (click)="showExtendedCard = true"
      (click)="optedNo()">
        {{'no_'|translate}}
      </button>
    </div>
  </div>
  <div class="row justify-content-center mt-16" *ngIf="showExtendedCard">
    <div class="col-auto">
      <button class="disabled-btn">
        {{'no_'|translate}}
      </button>
    </div>
  </div>
  <div [ngClass]="{'default':!showExtendedCard, 'rollup':showExtendedCard}">
    <div class="row mt-40">
      <div class="col-12 text-center w-600 font-18 line-height-21 black">
        {{'new_survey_recommended'|translate}}
      </div>
    </div>
    <div class="row mt-12">
      <div class="col-12">
        <div class="card card-body request-card">
          <div class="container">
            <div class="row">
              <div class="col-12 w-600 font-16 line-height-19 black text-center">
                {{'new_survey_jaano_paid_hr'|translate}}
              </div>
            </div>
            <div class="row mt-12">
              <div class="col p-0">
                <div class="content-center img-block">
                  <img src="../../../../../assets/images/solution-icon.svg" 
                  alt="icon" width="24px" height="24px">
                </div>
                <div class="content-center w-600 font-14 line-height-16 black mt-4 text-center">
                  {{'new_survey_solution'|translate}}
                </div>
                <div class="content-center w-400 font-12 line-height-14 black text-center">
                  {{'new_survey_within24'|translate}}
                </div>
              </div>
              <div class="col p-0">
                <div class="content-center img-block">
                  <img src="../../../../../assets/images/refund-icon.svg" 
                  alt="icon" width="24px" height="24px">
                </div>
                <div class="content-center w-600 font-14 line-height-16 black mt-4 text-center">
                  {{'new_survey_refund'|translate}}
                </div>
                <div class="content-center w-400 font-12 line-height-14 black text-center">
                  {{'new_survey_not_satisfied'|translate}}
                </div>
              </div>
              <div class="col p-0">
                <div class="content-center img-block">
                  <img src="../../../../../assets/images/best-price-icon.svg" 
                  alt="icon" width="24px" height="24px">
                </div>
                <div class="content-center w-600 font-14 line-height-16 black mt-4 text-center">
                  {{'new_survey_best_price'|translate}}
                </div>
                <div class="content-center w-400 font-12 line-height-14 black text-center">
                  {{'new_survey_guaranteed'|translate}}
                </div>
              </div>
            </div>
            <div class="row mt-12">
              <div class="col-12 text-center">
                <button class="request-paid-btn"
                (click)="requestPaidHelpOption()">
                  {{'request_paid_help'|translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-12 mb-16">
      <div class="col-12 text-center">
        <span class="font-14 w-400 line-height-16 black">
          {{'new_survey_free_help'|translate}}
        </span>
        <span class="font-14 w-600 line-height-16 blue cursor-pointer" 
        (click)="requestFreeHelpOption()">
          {{'click_here'|translate}}
        </span>
      </div>
    </div>
  </div>
</div> -->
<!-- <div class="container container-1"  
*ngIf="!showExtendedCard && abTestingType == ABTestingType.Old">
  <div class="row">
    <div class="col-12 text-center w-500 font-18 line-height-21 black">
      {{'new_survey_ques'|translate}}
    </div>
  </div>
  <div class="row justify-content-around mt-40 mb-40" *ngIf="!showExtendedCard">
    <div class="col-auto">
      <button class="survey-btn" [mat-dialog-close]="true"
      (click)="optedYes()">
        {{'Yes'|translate}}
      </button>
    </div>
    <div class="col-auto">
      <button class="survey-btn" (click)="showExtendedCard = true"
      (click)="optedNo()">
        {{'no_'|translate}}
      </button>
    </div>
  </div>
</div>
<div class="container custom-card"  *ngIf="showExtendedCard && abTestingType == ABTestingType.Old">
  <button class="close-icon float-right" 
   (click)="closeOption()">
   <img alt="close-icon" height="12" 
   src="../../../../../assets/images/close-icon-1.svg" width="24px" height="24px;">
  </button>
  <div class="row">
    <div class="col-12 text-center header">
      {{'request_help_from_helpers'|translate}}
    </div>
  </div>
  <div class="row mt-16">
    <div class="col-12">
      <div class="custom-card-1">
        <div class="container p-0">
          <div class="row">
            <div class="col-4 p-0">
              <div class="card-1">
                <div class="img-circle">
                  <img src="../../../../../assets/images/timer-icon-2.svg" alt="timer-icon">
                </div>
                <div class="text-1">
                  <span> < </span> {{'paid_help_12'|translate}}
                </div>
                <div class="text-2">
                  {{'for a response'|translate}}
                </div>
              </div>
            </div>
            <div class="col-4 p-0">
              <div class="card-1">
                <div class="img-circle">
                  <img src="../../../../../assets/images/certificate-icon-2.svg" alt="certificate-icon">
                </div>
                <div class="text-1">
                  {{'govt_certified'|translate}}
                </div>
                <div class="text-2">
                  {{'paid_help_22'|translate}}
                </div>
              </div>
            </div>
            <div class="col-4 p-0">
              <div class="card-1">
                <div class="img-circle">
                  <img src="../../../../../assets/images/solution-icon-2.svg" alt="solution-icon">
                </div>
                <div class="text-1">
                  10,000+
                </div>
                <div class="text-2">
                  {{'req_solved'|translate}}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 p-0" style="padding:0px 16px !important;">
              <button class="request-btn"  (click)="requestPaidHelpOption()">
                {{'request help'|translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->



<div class="floating-card">
  <div class="container p-40">
    <div class="failed-card popup-card" style="background:#FFFFFF;" 
    *ngIf="!exitConfirm && !assignedEmitra">
      <header>
        {{'Please wait for a few more mins!'|translate}}
      </header>
      <div class="highlight-info">
        {{'you are'|translate}} <span class="timer-text">{{remainingTime}}</span>
         {{'away from finding a helper!'|translate}}
      </div>
      <footer>
        <button class="action-btn" style="width: 160px;display: block;margin: auto;"
        [mat-dialog-close]="false">
          {{'Wait'|translate}}
        </button>
        <button class="trans-btn" style="width: 160px;display: block;
        margin-left: auto;
        margin-right: auto;" (click)="setExitConfirm1()">
          {{'exit'|translate}}
        </button>
      </footer>
    </div>
    <!-- <div class="failed-card popup-card" style="background:#FFFFFF;"
    *ngIf="exitConfirm && !assignedEmitra">
      <header>
        {{'notify_via_sms'|translate}}
      </header>
      <div class="info-4">
        {{'You can find your request in your'|translate}}
        <span style="color:#304FFE;text-decoration-line: underline;cursor:pointer" (click)="profileRoute()">{{'Profile'|translate}}</span>
      </div>
      <footer>
        <button class="action-btn" style="width: 160px;" [mat-dialog-close]="false" (click)="setExitConfirm1()">
          {{'Okay'|translate}}
        </button>
      </footer>
    </div> -->
    <div class="popup-card-2" *ngIf="assignedEmitra && !exitConfirm ">
      <header>
        <div>{{assignedEmitra}}</div>
        <div>{{'will solve your request within'|translate}} <span style="color: #00AF31;">{{'24 Hrs'|translate}}*.</span></div>
      </header>
      <section>
        <div class="img-circle">
          <img src="../../../../assets/images/refund-icon-3.svg" alt="refund-icon" width="30px" height="30px" >
        </div>
        <div class="info-1">{{'We offer 100% refund*'|translate}}</div>
        <div class="info-2">{{'If we fail to serve your request.'|translate}}</div>
      </section>
      <footer>
        <button class="solid-btn block-btn" (click)="acceptOffer()">{{'connectAt'|translate}} ₹ {{assignedAmount}}</button>
        <button class="trans-btn text-center" (click)="setExitConfirm1()">{{'exit'|translate}}</button>
      </footer>
    </div>
    <!-- <div class="failed-card popup-card" style="background:#FFFFFF;"
    *ngIf="exitConfirm && assignedEmitra">
      <header>
        {{'Your offer will be available for next 48 Hrs.'|translate}}
      </header>
      <div class="info-4">
        {{'You can find your request in your'|translate}}
        <span style="color:#304FFE;text-decoration-line: underline;cursor:pointer" (click)="profileRoute()" [mat-dialog-close]="true">{{'Profile'|translate}}</span>
      </div>
      <footer>
        <button class="action-btn" style="width: 160px;" [mat-dialog-close]="true">
          {{'Okay'|translate}}
        </button>
      </footer>
    </div> -->
  </div>
</div>