<div class="opacity_overlay" *ngIf="isLoading">
    <mat-progress-spinner *ngIf="isLoading" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
    </mat-progress-spinner>
  </div>
<div class="container-fluid wrapper-1">
    <div class="row">
        <!-- <div class="col-auto left-card-wrapper"> -->
        <div class="col-auto left-card-wrapper" id="hrlistWrapper">
            <div class="wrapper-2">
                <ng-container *ngIf="hrList && hrList.length>0">

                    <div class="row sm-hide">
                        <div class="col-12">
                            <h1 class="h1">{{hrList[0].hrDesc}}</h1>
                        </div>
                    </div>
                    <div style="position: sticky; top: -24px; z-index: 999; background: #fff;">
                        <div class="row sm-show">
                            <div class="col-12">
                                <h1 class="h1">{{hrList[0].hrDesc}}</h1>
                            </div>
                        </div>
                        <div class="row mt-24 sm-view mt-mb-sm-8">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-12  content-center">
                                                <span class="title">{{'Paid Solutions in 24 Hrs'|translate}}*</span>
                                            </div>
                                            <div class="col-12  content-center">
                                                <span class="desc">{{'Refund guaranteed by Swaniti'|translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row" style="width:100%">
                                            <div class="col-12 text-center content-center">
                                                <button class="paid-help-btn gradiant-btn" (click)="onInitiateRequest()">
                                                    {{'Get professional’s paid help'|translate}}
                                                </button>
                                            </div>
                                            <div class="col-12 content-center mt--4">
                                                <span class="amount-txt">{{'@ just ₹70 - 200'|translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="row mt-12">
                    <div class="col-12 p-wrapper">
                        <p class="p" [innerHTML]="hrList[0].suggestion"></p>
                    </div>
                </div>
                </ng-container>
                <div class="row">
                    <div class="col-12 mt-40-30">
                        <h1 class="h1">{{'Similar questions'|translate}}</h1>
                    </div>
                    <div class="col-12 mt-18 mb-sm-50 p-sm-0-20">
                        <ul>
                            <ng-container *ngFor="let hr of hrList;let i=index;">
                                <li *ngIf="i>0" (click)="getHelpRequestDetailsById(hr.helpId,i)">
                                    <div class="col-auto text-wrapper pl-0">{{hr.hrDesc}}</div>
                                    <button class="col-auto ml-auto no-border-btn btn-wrapper">
                                        <img src="../../../assets/images/arrow-right-icon.svg" alt="arrow-right-icon" width="24px" height="24px">
                                    </button>
                                </li>
                            </ng-container>
                            <li class="text-center" style="border: none !important;box-shadow: none;">
                                <button class="no-border-btn btn-blue btn-block" (click)="onLoadMore()">{{'Load more'|translate}}</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-auto ml-auto ">
            <div class="card right-card-wrapper">
                <div class="card-header">
                    <div class="row">
                        <div class="col-12  content-center">
                            <span class="title">{{'Paid Solutions in 24 Hrs'|translate}}*</span>
                        </div>
                        <div class="col-12  content-center">
                            <span class="desc">{{'Refund guaranteed by Swaniti'|translate}}</span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row" style="width:100%">
                        <div class="col-12 text-center content-center">
                            <button class="paid-help-btn gradiant-btn" (click)="onInitiateRequest()">
                                {{'Get professional’s paid help'|translate}}
                            </button>
                        </div>
                        <div class="col-12 content-center mt--4">
                            <span class="amount-txt">{{"@ just ₹70 - 200"|translate}}</span>
                        </div>
                        <div class="col-12 mt-20-16">
                            <img [src]="hrImage" alt="paid-help-img" style="width:100%; height:100%">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-40-30" id="popularServices112" #popularServices112>
        <div class="col-12 header-2" style="font-weight:700;">
            {{'Our popular services'|translate}}
        </div>
    </div>
    <div class="row mt-18-20">
        <div class="sub-cat-slider">
          <div class="col-auto" *ngFor="let tag of tagSubCats | slice: startIndex:endIndex;let idx = index">
              <div class="cat-wrapper" [ngClass]="{'active':selectedSubCatValue == tag.subCat}"
              (click)="onSelectSubCat(tag.subCatId)">
                  <span class="body-r">{{tag.subCat}}</span>
              </div>
          </div>
          <div class="col-auto ml-auto content-center sm-hide" *ngIf="endIndex == 999">
              <span class="body-r txt-blue cursor-pointer text-underline" (click)="toggleAllCategories()">
                <img src="../../../assets/images/arrow-right-icon.svg" alt="arrow-right" width="24px" height="24px"
                style="transform: rotate(90deg);">
              </span>
          </div>
        </div>
        <div class="col-auto ml-auto content-center sm-hide pl-0" *ngIf="endIndex == range">
            <div class="body-r txt-blue cursor-pointer mw-105px text-underline" (click)="toggleAllCategories()">
                <img src="../../../assets/images/arrow-right-icon.svg" alt="arrow-right" width="24px" height="24px">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 mt-18-20" *ngFor="let item of popularServices">
          <div class="popular-card" (click)="initiateHelpRequestFromExistingOne(item)">
              <div class="row">
                  <div class="col-12 mh-24 line-clamp">
                      <p class="body-r txt-black">
                        {{item.subject}}
                      </p>
                  </div>
                  <div class="col-12 footer">
                      <img src="../../../../assets/images/new-images/rupee-icon-2.svg" alt="rupee-icon" width="16px" height="16px" >
                      <span class="caption-r txt-green">{{item.pricing}}</span>
                  </div>
              </div>
              <div class="side-elipse">
                  <img src="../../../../assets/images/new-images/elipse-icon.svg" alt="elipse-icon" >
              </div>
              <div class="add-request">
                  <img src="../../../../assets/images/new-images/add-icon.svg" alt="add-icon" width="24px" height="24px" >
              </div>
          </div>
        </div>
    </div>
    <div class="row mt-18-20 mb-64-40">
        <div class="col-12 text-center">
            <button class="no-border-btn btn-blue btn-block" (click)="onLoadMorePopularService()"
            *ngIf="selectedSubCat == -1">{{'Load more'|translate}}</button>
        </div>
    </div>
</div>

<div class="floater-card">
    <div class="card-wrapper" (click)="scrollToPopularServices()" style="cursor:pointer;" *ngIf="explorePopularService">
        <img src="../../../assets/images/down-arrow.svg" alt="down-arrow" class="wh-20-16">
        {{'Get solution in just 24 Hrs'|translate}}
        <img src="../../../assets/images/down-arrow.svg" alt="down-arrow" class="wh-20-16">
    </div>
</div>

