import { Injectable, Inject, NgZone } from "@angular/core";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { AuthService } from "./auth.service";
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { AuthenticationComponent } from "../shared-components/authentication/authentication.component";
import { CommonService } from "./common.service";
import { GoogleAnalyticsService } from './google-analytics.service';
import { Router } from "@angular/router";

declare var hj:any;
@Injectable()
export class LoginDialogService {
    mobileScreen: boolean = false;
    dialogConfig: any;
    windowWidth: number;
    callback: any;

    private subject: Subject<any> = new Subject<any>();
    private showGratificationPopup: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private dialog: MatDialog, private _auth: AuthService, 
        private googleAnalyticsService: GoogleAnalyticsService, 
        private _zone: NgZone, private commonService : CommonService,
        private route:Router) {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth < 768)
            this.mobileScreen = true;
    }

    openModal(data:any, callback:any) {
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if(isSafari){
            window.scroll(0,0);
        }

        this._auth.getAuthStateSync((authenticated:any) => {
            if (authenticated) {
                this.showGratificationPopup.next(false);
                this.subject.next(true);
                callback(true);
            }
            else {
                this.dialogConfig = new MatDialogConfig();
                this.dialogConfig.disableClose = true;
                this.dialogConfig.hasBackdrop = true;
                this.dialogConfig.data = data;
                this.dialogConfig.height = "100%";
                this.dialogConfig.width = "100%";
                this.dialogConfig.maxWidth = "100%";
                if(data.width && data.height){
                    this.dialogConfig.height = data.height;
                    this.dialogConfig.width = data.width;
                    this.dialogConfig.panelClass = data.panelClass;
                }
                if(data.minWidth){
                    this.dialogConfig.minWidth = data.minWidth;
                    this.dialogConfig.maxWidth = '80vw';
                }
                else{
                    this.dialogConfig.maxWidth = data.width;
                }
                if(data.fromAds){
                    this.dialogConfig.fromAds = data.fromAds
                }
                if(data.fromGoogleAds){
                    this.dialogConfig.fromGoogleAds = data.fromGoogleAds
                }
                if(data.fromSolution){
                    this.dialogConfig.fromSolution = data.fromSolution;
                }
                const dialogRef = this.dialog.open(AuthenticationComponent, this.dialogConfig);
                dialogRef.afterClosed().subscribe(
                    data => {
                        if (data) {
                            if(data.allClose){
                                callback(data);
                                return;
                            }
                            this.showGratificationPopup.next(true);
                            this.subject.next(true);
                            callback(true);
                        }
                        else {
                            hj('event', 'quitting_from_authentication');
                            this.googleAnalyticsService.eventEmitter("user_cancels_auth_dialog", "", "");
                            this.showGratificationPopup.next(false);
                            this.subject.next(false);
                            callback(false);
                        }
                    }
                );
            }
        });
    }

    userLoggedInStatus(): Observable<any> {
        return this.subject.asObservable();
    }

    showGratificationPopupStatus(): boolean {
        return this.showGratificationPopup.getValue();
    }

    close() {
        // this.subject.next(false);
        this.callback(false);
        this.dialogConfig.close();
    }

    logOut() {
        localStorage.removeItem("username");
        localStorage.removeItem('public_username');
        localStorage.removeItem('notificationArr');
        localStorage.removeItem('profile_picture');
        localStorage.removeItem('phone_no');
        localStorage.removeItem('jaano_points');
        this.commonService.setUsername(null);
        this._auth.signOut();
        this.route.navigateByUrl("home");
        // window.location.reload();
    }

}