<div class="col-12 pd0 no-gutter trendingContainer" style="padding:2px;">

  <div class="card mb-12 box-shadow-4px w-100" *ngFor="let request of trendingPosts;let i = index">
    <div [id]="'m'+i" class="card-body review-label cursor-pointer" (click)="navigateNewHelpRequestById(request)">
      <!-- [ngClass]="request?.req_status == '0' ? 'label-card-closed':'label-card'" -->
      <!-- <div class="side-label-close" *ngIf="request?.req_status == '0'" style="cursor: pointer;"
        (click)="navigateToHelpRequestById(request);">{{'Closed' | translate}}</div>
      <div class="side-label" *ngIf="request?.req_status == '1'" style="cursor: pointer;"
        (click)="navigateToHelpRequestById(request);">
        {{'open' | translate}}</div> -->
      <div class="container-fluid pd0">
        <div class="no-gutter">
          <div class="col-12 pd0">
            <div class="">
              <!-- <div class="col-12 col-sm-12 pr-0 pl-0" *ngIf="request && request.urlArray && request.urlArray.length > 0">
                <ngu-carousel class="carousel" #myCarousel [inputs]="carouselTile" [dataSource]="request?.urlArray">
                  <ngu-tile class="carousel-inner" *nguCarouselDef="let banner; let i = index">
                    <div class="carousel-item">
                      <img (click)="navigateToHelpRequestById(request)" class="d-block w-100 ht-100 obj-cover"
                        *ngIf="banner.type =='picture'" [src]="banner.link" alt="...">
                      <video class="video d-block w-100" *ngIf="banner.type =='video'" width="245" height="190"
                        controls>
                        <source [src]="banner.link" type="video/mp4"></video>
                      <!- - <audio controls class="d-block w-100" *ngIf="banner.type =='audio'" [src]="banner.link">
                      </audio> - ->
                      <ngx-circular-player *ngIf="banner.type =='audio'"  [source]="banner.link"
                      radius="120"
                      stroke="20"
                      innerStroke="2"
                      strokeColor="#003DB3"
                      progressStrokeColor="#C4C4C4"
                      innerStrokeColor="#14377b"
                      ></ngx-circular-player>
                    </div>
                  </ngu-tile>
                  <button NguCarouselPrev class="carousel-control-prev" [style.opacity]="myCarousel.isFirst ? 0:1">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  </button>
                  <button NguCarouselNext class="carousel-control-next" [style.opacity]="myCarousel.isLast ? 0:1">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  </button>
                  <ul class="myPoint" NguCarouselPoint *ngIf="request.urlArray.length > 1">
                    <li *ngFor="let i of myCarousel.pointNumbers; let i = index"
                      [class.active]="i==myCarousel.activePoint" (click)="myCarousel.moveTo(i)"
                      [style.background]="'url(' + request?.urlArray[i] + ')'"></li>
                  </ul>
                </ngu-carousel>
              </div> -->
              <div class="col-12 col-sm-12 p-relative pd0"
                [ngClass]="{'width-full': request?.urlArray?.length == 0 && request?.help_video == null && request?.help_voice_link == null }">
                <div class="user-detail-box">
                  <div class="text-left">
                    <span  class="user-name"
                      (click)="navigateToProfile(request.username, $event)">@{{request.username}}</span>
                    <span class="post-date">{{request.timestamp | date:
                      'short'}}</span>
                    <span *ngIf="request.mandalcode > -1 ">, {{request.mandalname}}</span>
                  </div>
                  <div class="col-4 col-md-2 pd0 text-right pointer dropdown">
                    <span class="ellipsis_container" (click)="$event.stopPropagation();">
                      <span class="sm-wid-20 ellipsis_options"></span>
                    </span>
                    <div class="dropdown-content wid-125px">
                      <!-- <div class="text-left text-dropdown cursor-p"
                        (click)="markSaveUnSaveRequest(request,request.is_saved,i,$event)">
                        <span *ngIf="!request?.is_saved">
                          <img src="../../../../assets/images/save.svg" alt="Save" class="sm-ht-16">
                          <span class="ml-2">
                            {{'save' | translate}}
                          </span>

                        </span>
                        <span *ngIf="request?.is_saved">
                          <img src="../../../../assets/images/home-images/Saved-icon.svg" alt="Save" class="sm-ht-16">
                          <span class="ml-2">
                            {{'Saved' | translate}}
                          </span>
                        </span>
                      </div> -->
                      <div class="text-left text-dropdown cursor-p">
                        <div (click)="reportPost(request?.helpid, $event)">
                          <img src="../../../../assets/images/report-icon.svg" alt="report" class="sm-ht-16">
                          <span class="ml-2"> {{'report' | translate}}</span>
                        </div>
                      </div>
                      <div class="text-left text-dropdown cursor-p" *ngIf="request?.username == username">
                        <div (click)="navigateToEditHelpRequestById(request?.helpid, $event)">
                          <img src="../../../../assets/images/profile-editPen.svg" alt="report" class="sm-ht-16">
                          <span class="ml-2"> {{'Edit' | translate}}</span>
                        </div>
                      </div>
                      <div class="text-left text-dropdown cursor-p" *ngIf="request?.username == username">
                        <div (click)="deleteHelpRequest(request?.helpid,i, $event,request?.username)">
                          <img src="../../../../assets/images/delete-icon.svg" alt="report" class="sm-ht-16">
                          <span class="ml-2"> {{'delete' | translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="main-text mb-2" style="cursor: pointer;" 
                  *ngIf="request.help_title == '{}' || request.help_title == '' || request.help_title == null ">
                  {{'Help Request for' | translate}}&nbsp;{{request?.requestTitle}}
                </div>
                <div class="main-text mb-2" style="cursor: pointer;" 
                  *ngIf="request.help_title != '{}' && request.help_title != '' && request.help_title != null ">
                  {{request.help_title}}
                </div>
                <div class="main-text-caption mb-4" style="cursor: pointer;"
                  [style.-webkit-line-clamp]="windowWidth < 767 ? 2 : 4" 
                  *ngIf="request.help_description != '{}' && request.help_description != '' && request.help_description != null ">
                  {{request.help_description}}
                </div>
                <div class="col-12 share-box border-n">
                  <div class="closed_tag d-inline-block align-top mr-2" *ngIf="request?.req_status == '0'">{{'Closed' |
                    translate}}
                    <span *ngIf="request?.closed_by == 'admin'">&nbsp;{{'by_moderator' | translate}}</span>
                  </div>

                  <div class="open_tag d-inline-block align-top mr-2" *ngIf="request?.req_status == '1'">
                    <img src="../../../../assets/images/new_help_icons/help_open.svg" alt="open-help" />
                    {{'open' | translate}}
                  </div>

                  <div class="d-inline-block">
                    <div class="pd0 d-inline-block mr-2"
                      *ngFor="let req of getCategoryArrayByCategoryId(request.category,request?.sub_category)"
                      style="cursor: pointer;">
                      <div
                        *ngIf="!(request?.sub_category != null && request?.sub_category != undefined && request?.sub_category !='0')">
                        <span class="icon_size align-top cat-box flex-center">
                          <img src="{{req.cat_icon}}" alt="Image">
                          <span class="text-blue">{{req.CatlangKey}}</span>
                        </span>
                      </div>
                    </div>
                    <div class="cat-box align-top mr-2 d-inline-block subcat"
                      *ngIf="request?.sub_category != null && request?.sub_category != undefined && request?.sub_category !='0'">
                      <div class="icon-background-page2-selected subheading3-selected subheading3">
                        {{subcategory}}
                      </div>
                    </div>
                  </div>
                  <div class="float-right p-0 d-flex mb-2">
                    <div class="pd0 d-flex justify-center mr-md-4" style="cursor: pointer;">
                      <img [id]="'post_animator' + i" style="display: none;"
                        src="../../../../assets/images/jp2_animation.gif" alt="Image">
                      <span class="icon_size" (click)="markUserSupport(request,i, $event)">
                        <span class="action_buttons_container2">
                          <img
                            [src]="request.is_support == 0 ?'../../../../assets/images/home-images/supporter-icon.svg':'../../../../assets/images/home-images/selected-suppoter-icon.svg'"
                            alt="Support">
                        </span>
                        <span [ngClass]="{'support-font': (request?.tot_support > 0)}" style="cursor: pointer;">
                          <span>{{request?.tot_support == -1? 0: request?.tot_support}}</span>
                          <!-- <span *ngIf="! (windowWidth > 768 && windowWidth < 1105)">{{'Supporters' | translate}}</span> -->
                        </span>
                      </span>
                    </div>
                    <div class="pd0 d-flex justify-center mr-md-4">
                      <span class="icon_size" style="cursor: pointer;">
                        <span class="action_buttons_container2">
                          <img
                            [src]="request?.comments_count > 0 ?'../../../../assets/images/home-images/selected-suggest-icon.svg':'../../../../assets/images/home-images/suggestion-icon.svg'"
                            alt="suggestion-icon">
                        </span>
                        <span
                          [ngClass]="{'support-font': (request?.comments_count > 0)}"><span>{{request?.comments_count ==
                            -1 ? 0: request?.comments_count}}</span>
                          <!-- <span *ngIf="! (windowWidth > 768 && windowWidth < 1105)">{{'Suggestion' | translate}}</span> -->
                        </span></span>
                    </div>
                    <div class="p-0" style="text-align: right;">
                      <!-- <div class="sm-hide d-flex justify-center dropdown">
                        <span class="icon_size"><img src="../../../../assets/images/home-images/light-share-icon.svg"
                            alt="share" class="ht-20"><span
                            *ngIf="! (windowWidth > 768 && windowWidth < 1105)">{{'share'| translate}}</span></span>
                        <div class="dropdown-content p-3">
                          <div> {{'share' | translate}} : </div>
                          <div class="shareoption" (click)="share('facebook', request)"><img alt="Image"
                              src="../../../../assets/images/facebook_share.svg"> {{'facebook'| translate}}</div>
                          <div class="shareoption" (click)="share('twitter', request)"><img alt="Image"
                              src="../../../../assets/images/twitter_share.svg">{{'twitter'| translate}}</div>
                          <div class="shareoption" (click)="share('whatsapp', request)"><img alt="Image"
                              src="../../assets/images/whatsapp.svg">{{'whatsApp'| translate}}</div>
                        </div>
                      </div> -->
                      <div class="d-flex justify-center" (click)="share('whatsapp', request, $event)">
                        <span class="icon_size action_buttons_container2">
                          <img src="../../../../assets/images/whatsapp.svg" alt="share" class="ht-20">
                          <!-- <span *ngIf="! (windowWidth > 768 && windowWidth < 1105)">{{'share'| translate}}</span> -->
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 share-box mt-4 d-none pt-2">
              <div class="col-5 col-sm-4 pd0" style="cursor: pointer;" (click)="markUserSupport(request,i, $event)">
                <img [id]="'post_animator' + i + '_s'" style="display: none;"
                  src="../../../../assets/images/jp2_animation.gif" alt="Image">
                <span class="icon_size d-flex ">
                  <span class="action_buttons_container2">
                    <img
                      [src]="request?.is_support == 0 ?'../../../../assets/images/home-images/supporter-icon.svg':'../../../../assets/images/home-images/selected-suppoter-icon.svg'"
                      alt="Support">
                  </span>
                  <span [ngClass]="{'support-font': (request?.tot_support > 0)}"><span>{{request?.tot_support == -1? 0:
                      request?.tot_support}}</span>
                    <!-- <span *ngIf="! (windowWidth > 768 && windowWidth < 1105)">{{'Supporters' | translate}}</span> -->
                  </span>
                </span>
              </div>
              <div class="col-5 col-sm-4 pd0">
                <span class="icon_size d-flex">
                  <span class="action_buttons_container2"><img
                      [src]="request?.comments_count > 0 ?'../../../../assets/images/home-images/selected-suggest-icon.svg':'../../../../assets/images/home-images/suggestion-icon.svg'"
                      alt="suggestion-icon">
                  </span>
                  <span [ngClass]="{'support-font': (request?.comments_count > 0)}"><span>{{request?.comments_count ==
                      -1 ? 0: request?.comments_count}}</span>
                    <!-- <span *ngIf="! (windowWidth > 768 && windowWidth < 1105)">{{'Suggestion' | translate}}</span> -->
                  </span></span>
              </div>
              <!-- <div class="col-2 col-sm-4 pd0 dropdown">
                <span class="icon_size d-flex"><img src="../../../../assets/images/home-images/light-share-icon.svg"
                    alt="share" class="ht-20">
                  <span *ngIf="! (windowWidth > 768 && windowWidth < 1105)">{{'share' | translate}}</span></span>
                <div class="dropdown-content p-3">
                  <div> {{'share' | translate}} : </div>
                  <div class="shareoption" (click)="share('facebook', request)"><img alt="Image"
                      src="../../../../assets/images/facebook_share.svg"> {{'facebook'| translate}}</div>
                  <div class="shareoption" (click)="share('twitter', request)"><img alt="Image"
                      src="../../../../assets/images/twitter_share.svg">{{'twitter'| translate}}</div>
                  <div class="shareoption" (click)="share('whatsapp', request)"><img alt="Image"
                      src="../../assets/images/whatsapp.svg">{{'whatsApp'| translate}}</div>
                </div>
              </div> -->
              <div class="col-2 col-sm-4 pd0" (click)="share('whatsapp', request, $event)">
                <span class="icon_size d-flex action_buttons_container2"><img
                    src="../../../../assets/images/whatsapp.svg" alt="share" class="ht-20">
                  <!-- <span *ngIf="! (windowWidth > 768 && windowWidth < 1105)">{{'share' | translate}}</span> -->
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="noData mt-3" *ngIf="trendingPosts && trendingPosts?.length == 0 && !IsLoader">
    {{'No Requests Found' | translate}}
  </div>

</div>