import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { env } from 'process';
import { GoogleAnalyticsService } from '../../../../services/google-analytics.service';
import { environment } from '../../../../../environments/environment';
import { DataService } from '../../../../services/data.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-paynow-dialog',
  templateUrl: './paynow-dialog.component.html',
  styleUrls: ['./paynow-dialog.component.scss']
})
export class PaynowDialogComponent implements OnInit {

  paymentStatus :AngularFireList<any>;
  @ViewChild('paymentLink')paymentLink : ElementRef= new ElementRef(null);

  paytmUrl = environment.paytmUrl;
  title;

  helprequestId:string="";
  phoneNo:string="";
  emitraId:string="";
  redirect:boolean = false;
  bid:any=null;
  fromReferral= false;

  constructor(
    private db: AngularFireDatabase,
    public dialogRef: MatDialogRef<PaynowDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private dataService: DataService,
    @Inject(DOCUMENT) private document: Document,
    private googleAnalyticsService:GoogleAnalyticsService,
    private sanitizer:DomSanitizer
  ) { 
    this.showingPrice = data.showingPrice;
    this.helprequestId= data.helpid;
    this.emitraId= data.emitraId;
    this.phoneNo = data.phoneNo;
    this.emitraName = data.emitraName;
    this.price = data.price,
    this.tokenId = data.tokenId,
    this.orderId = data.orderId,
    this.userName = data.userName;
    this.subcategory = data.subCategory;
    this.profilePicture = data.picture && data.picture != 'null' && data.picture != 'undefined'
                          ?data.picture:null;
    this.redirect = data.redirect?data.redirect:false;
    this.bid = data.offer;
    this.fromReferral = data.fromReferral;
    if(this.redirect){
      this.onClickPayNow()
    }


    this.paymentStatus = this.db.list('/orders2', ref=> ref.orderByKey()
    .equalTo(this.orderId));
    this.paymentStatus.valueChanges().subscribe( res=>{
      if(res){
        if(res.length != 0){
          const paymentStatus = res[0].txn_status;
          if(paymentStatus == "success"){
            this.isLoader = false;
            this.dialogRef.close("success");
            //  reloading page because of paytm popup issue..... 
            if(!this.paymentTypePhonepe){
              this.googleAnalyticsService.eventEmitter2('Payment_completed_paytm',{});
            }
            else{
              this.googleAnalyticsService.eventEmitter2('Payment_completed_phonepe',{});
            }
            (window as any).hj('event', 'Payment_completed');
            location.reload();
          }
        }
      }
    });
    this.title = localStorage.getItem('hr_title');
  }

  ngOnInit(){
    // this.invokePaytm();
  }

  dialogOne = true;
  dialogTwo = false;
  emitraName: string;
  price: number;
  tokenId: string;
  orderId: string;
  userName: string;
  urlCode: Location|any;
  acceptTermsCondition= false;
  termAndConditionDialog= false;
  subcategory: string;
  profilePicture: string;
  color = "primary";
  mode = "indeterminate";
  value = 50;
  isLoader = false;
  showingPrice:number=0;

  showIframe=false;
  paymentTypePhonepe= false;
  onClickPayNow(){
    // setting local storage for payment failed survey
    const paymentExitSurvey={
      helpid:this.bid.helpid,
      bid:this.bid
    }
    const strigyfyData= JSON.stringify(paymentExitSurvey);
    localStorage.setItem('paymentExitForm',strigyfyData);


    // storing initiate payment helpid
    localStorage.setItem('initiatePayment',this.helprequestId);
    this.googleAnalyticsService.eventEmitter2('payment_init',{});
    (window as any).hj('event', 'payment_init');
    this.dataService.growthBookPayment.subscribe(response=>{
      // if old
      if(response){
        this.googleAnalyticsService.eventEmitter2('payment_init_paytm',{});
        this.paymentTypePhonepe = false;
        this.invokePaytm();
        return;
      }
      else{
        this.googleAnalyticsService.eventEmitter2('payment_init_phonepe',{});
        this.paymentTypePhonepe = true;
        this.onClickPayNow2();
        return;
      }
    })
    // initiate paid help transaction..
    // const request = {
	  //   transaction_token :  this.tokenId,
	  //   orderId : this.orderId
    // }
    // this.dataService.processPaidHelpTransaction(request)
    // .subscribe((res) => {
    //   if(res.success){
    //     this.urlCode = res.deeplink;
    //   }
    //   // get device: either mobile or desktop..
    //   const userDevice = navigator.userAgent
    //   console.log(userDevice)
    //   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userDevice)){
    //     // this.dialogOne = false;
    //     // window.open(this.urlCode,"_blank");
    //     // this.dialogRef.close(true);
    //     this.paymentLink.nativeElement.click();
    //   }
    //   else{
    //     this.dialogOne = false;
    //     this.termAndConditionDialog = false;
    //     this.dialogTwo = true;
    //   }
    // },error=>{
    //   this.dialogRef.close(true);
    //   this.dialogOne = false;
    //   this.dialogTwo = false;
    //   this.termAndConditionDialog = false;
    // });    
  }

  pay(){
    window.location= this.urlCode;
  }

  onClicDone(){
    this.dialogRef.close(true);
    this.dialogTwo = false;
  }

  switchDialogTermAndCondition(){
    this.dialogOne = false;
    this.termAndConditionDialog = true;
  }

  chatAt0(username:any){
    if(username){
      return username.charAt(0).toUpperCase();
    }
    return 'E';
  }

  invokePaytm(){
    this.isLoader = true;
    // if(!window.document.getElementById('paytm-script')){
      let script = window.document.createElement('script');
      script.id='paytm-script';
      script.type = 'text/javascript';
      script.src= this.paytmUrl;
      script.async = true;
      script.crossOrigin = "anonymous"
      script.onload = () => {
        // script has been loaded
      }
      document.getElementsByTagName('head')[0].appendChild(script);
      script.onload = () => {
        this.makePayment(this.tokenId, this.orderId);
        setTimeout(() => {
          this.isLoader = false;
        }, 2000);
        // script has been loaded
      }
    // }
  }

  makePayment(token:any, orderId:any){
    this.isLoader = true;
      let config = {
        "root": "",
        "flow": "DEFAULT",
        "data": {
            "orderId": orderId, /* update order id */
            "token": token, /* update token value */
            "tokenType": "TXN_TOKEN",
            "amount": this.price /* update amount */
        },
        "merchant":{
          "redirect": false
        },
        payMode:{
          "filter": {
              "exclude": ['NB','BALANCE','PPBL','PDC']
          }
        },
        "handler": {
            "notifyMerchant": function(eventName:any,data:any){
            },
            "transactionStatus":function(data:any){
              // document.getElementById("app-close-btn").click();
              // this.isLoader = true;
            }
        }
    };

    if((<any>window).Paytm && (<any>window).Paytm.CheckoutJS){
      (<any>window).Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
            // initialze configuration using init method
            (<any>window).Paytm.CheckoutJS.init(config).then(function onSuccess() {
                // after successfully updating configuration, invoke JS Checkout
                (<any>window).Paytm.CheckoutJS.invoke();
                // this.isLoader = false;
            }).catch(function onError(error:any){
              // this.isLoader = false;
            });
        });
        // this.isLoader = false;
    }
  }

  closeOption(){
    this.dialogRef.close(false);
  }

  phonePayLink:any ="";
  onClickPayNow2(){
    const request2={
      username: this.userName,
      phone_no: this.phoneNo,
      redirectUrl:`${environment.webUrl}/help-request/getHelp-RequestbyId?Id=${this.helprequestId}`,
      txn_amt:this.price,
      helpid:this.helprequestId,
      emitra_id:this.emitraId,

    }
    console.log('request url',request2.redirectUrl);
    this.dataService.callv3API("phonepepayments/initiatePaidHelpTransaction", request2)
    .subscribe((res) => {
      console.log('response from phonepay',res);
      if(res && res.success && res.url){
        this.phonePayLink = this.sanitizer.bypassSecurityTrustResourceUrl(res.url);
        // this.showIframe=true;
        const paymentUrl = res.url;
        function callback (response:any) {
            if (response === 'USER_CANCEL') {
              console.log('payment pending');
              return;
            } else if (response === 'CONCLUDED') {
              console.log('payment completed');
              location.reload();
              return;
            }
          }
        if((<any>window).PhonePeCheckout && (<any>window).PhonePeCheckout.transact){
          (<any>window).PhonePeCheckout.transact({ tokenUrl:paymentUrl });;
          // (<any>window).PhonePeCheckout.transact({ tokenUrl:paymentUrl, callback, type: "IFRAME" });
        }
      }
    },
    error=>{
      console.log('error',error);
    });
  }
}
