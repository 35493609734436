<div class="opacity_overlay" *ngIf="IsLoader">
    <mat-progress-spinner *ngIf="IsLoader" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
    </mat-progress-spinner>
</div>
<!-- <div class="main-dialog col-sm-12 col-xs-12">

    <div *ngIf="!OnBoardUser" class="text-right">
        <img (click)="closeDialog()" src="../../../assets/images/close.svg" alt="close-icon">
    </div>

    <div class="text-center">
        <img height="200" src="../../../assets/images/new_add_lang.svg" alt="add-lang">
    </div>

    <div class="select_heading">{{'Please Select Your Language'| translate}}</div>
    <div class="col-12 locationselector">
        <div class="stname" [ngClass]="language == 'en'? 'selectedClass' : 'unselectedClass'" (click)="onClick('en')">
            {{'English' | translate}} <span class="lang-tick"><img src="../../../assets/images/lang-tick.svg"
                    alt="tick"></span></div>
        <div class="stname" [ngClass]="language == 'hi'? 'selectedClass' : 'unselectedClass'" (click)="onClick('hi')">
            {{'Hindi' | translate}}<span class="lang-tick"><img src="../../../assets/images/lang-tick.svg"
                    alt="tick"></span></div>
        <div class="stname" [ngClass]="language == 'te'? 'selectedClass' : 'unselectedClass'" (click)="onClick('te')">
            {{'Telugu' | translate}} <span class="lang-tick"><img src="../../../assets/images/lang-tick.svg"
                    alt="tick"></span></div>
        <div class="stname" [ngClass]="language == 'pa'? 'selectedClass' : 'unselectedClass'" (click)="onClick('pa')">
            {{'Punjabi' | translate}} <span class="lang-tick"><img src="../../../assets/images/lang-tick.svg"
                    alt="tick"></span></div>
        <div class="stname" [ngClass]="language == 'mr'? 'selectedClass' : 'unselectedClass'" (click)="onClick('mr')">
            {{'Marathi' | translate}} <span class="lang-tick"><img src="../../../assets/images/lang-tick.svg"
                    alt="tick"></span></div>
        <div class="stname" [ngClass]="language == 'bn'? 'selectedClass' : 'unselectedClass'" (click)="onClick('bn')">
            {{'Bangla' | translate}} <span class="lang-tick"><img src="../../../assets/images/lang-tick.svg"
                    alt="tick"></span></div>
        <div class="stname" [ngClass]="language == 'as'? 'selectedClass' : 'unselectedClass'" (click)="onClick('as')">
            {{'Assamese' | translate}} <span class="lang-tick"><img src="../../../assets/images/lang-tick.svg"
                    alt="tick"></span></div>
        <div class="stname" [ngClass]="language == 'ne'? 'selectedClass' : 'unselectedClass'" (click)="onClick('ne')">
            {{'Nepali' | translate}} <span class="lang-tick"><img src="../../../assets/images/lang-tick.svg"
                    alt="tick"></span></div>
    </div>

</div> -->

<!-- <div class="container-fluid">
    <div class="row select-lang-top">
        <div class="col-12 text-center">
            <img src="../../../assets/images/jaanoLogo.svg" alt="jaanoLogo" width="120px"
            height="120px;">
        </div>
        <div class="col-12 mt-16 text-center">
            <span class="text-1 sh1 w-500">
                {{'select_language'|translate}}
            </span>
        </div>
    </div>
    <div class="row select-lang-bottom">
        <div class="col-12 mt-24 content-center">
            <button class="select-lang-btn" (click)="onClick('en')">
                {{'English' | translate}}
            </button>
        </div>
        <div class="col-12 mt-16 content-center">
            <button class="select-lang-btn" (click)="onClick('te')">
                {{'Telugu' | translate}}
            </button>
        </div>
        <div class="col-12 mt-16 content-center">
            <button class="select-lang-btn" (click)="onClick('hi')">
                {{'Hindi' | translate}}
            </button>
        </div>
        <div class="col-12 mt-16 content-center">
            <button class="select-lang-btn" (click)="onClick('bn')">
                {{'Bangla' | translate}}
            </button>
        </div>
        <div class="col-12 mt-16 content-center">
            <button class="select-lang-btn" (click)="onClick('mr')">
                {{'Marathi' | translate}}
            </button>
        </div>
        <div class="col-12 mt-16 content-center">
            <button class="select-lang-btn" (click)="onClick('pa')">
                {{'Punjabi' | translate}}
            </button>
        </div>
        <div class="col-12 mt-16 content-center">
            <button class="select-lang-btn" (click)="onClick('as')">
                {{'Assamese' | translate}}
            </button>
        </div>
        <div class="col-12 mt-16 content-center">
            <button class="select-lang-btn" (click)="onClick('ne')">
                {{'Nepali' | translate}}
            </button>
        </div>
    </div>
</div> -->


<div class="card">
    <div class="card-header text-center">
        <span>{{'Please Select Your Language'| translate}}</span>
        <div *ngIf="!OnBoardUser" class="close-btn">
            <img (click)="closeDialog()" src="../../../assets/images/cross-close-icon-2.svg" alt="close-icon" width="24px" height="24px" >
        </div>
    </div>
    <div class="card-body" style="height: 352px;padding-top: 0px;" id="bgImgCard">
        <div class="container">
            <div class="row">
                <div class="col-4 col-sm-3 custom-block" *ngFor="let lang of languages" >
                    <button class="block" (click)="onClick(lang.lang)" [ngClass]="{'active':language === lang.lang}">
                        <div class="letter">
                            {{lang.key}}
                        </div>
                        <div class="lang mt-lg-8">
                            {{lang.language}}
                        </div>
                    </button>
                </div>
                <div class="col-4 col-sm-3 custom-block">
                </div>
            </div>
        </div>
    </div>
</div>