<div class="opacity_overlay" *ngIf="IsLoader">
  <mat-progress-spinner *ngIf="IsLoader" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
  </mat-progress-spinner>
</div>
<div class="container" style="padding-top: 32px;
  position: relative;" 
  [ngClass]="{'height-515':fromAds}">
  <button class="transparent-btn"
  [mat-dialog-close]="false" style="position: absolute;
  right: 10px;
  z-index: 1;
  top: 10px;" (click)="closeIfFromAds()">
    <img src="../../../../../assets/images/cross-close-icon.svg" alt="close-icon"
    width="12" height="12">
  </button>
  <!-- <div class="row mt-12">
    <div class="col">
      <button class="transparent-btn color-blue" style="text-transform:uppercase"
      [mat-dialog-close]="false" >
      <img src="../../../../../assets/images/blue-back-btn.svg" alt="back-btn"
      height="11px" width="15px" style="margin-right:5px;">
        {{'back'|translate}}
      </button>
    </div>
    <div class="col-auto ml-auto">
    </div>
  </div> -->
  <div class="row">
    <div class="col-12">
      <div class="card card-body preferrence-card" *ngIf="!fromAds">
        <div class="row">
          <div class="col-12 text-center text text-bold color-black"
          style="font-size:18px; line-height: 21px;">
            {{'prefilled_request'|translate}}
          </div>
          <div class="col-12 text-center text text-1">
            {{'Please_make_changes_if_need'|translate}} 
          </div>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="helpRequestForm">
    <div class="row mt-12">
      <div class="col-12 text-center text text-bold color-black">
        {{'topic'|translate}}
      </div>
      <!-- <div class="col-12 text-center" style="margin-top: 4px;">
        <select class="custom-select-1" formControlName = "subCategory"
        (change)="onChangeSubCategory()">
          <option *ngFor="let subCat of subCategories" [value]="subCat.index">
            {{subCat.value}}
          </option>
        </select>
      </div> -->
      <div class="col-12 text-center" style="margin-top: 4px;">
        <div class="dropdown">
          <button class="dropdown-toggle sub-cat-btn"
          type="button" id="subCatId" data-toggle="dropdown" 
          aria-haspopup="true" aria-expanded="false">
            {{subCategory}}
          </button>
          <div class="dropdown-menu" aria-labelledby="subCatId"
          style="overflow-y: auto;
          max-height: 250px;">
            <div class="dropdown-item" *ngFor="let subCat of subCategories"
            (click)="onChangeSubCategory(subCat.index)"
            [ngClass]="{'active-item':subCat.value == subCategory}">
              {{subCat.value}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-12">
      <div class="col-12">
        <div class="card card-body create-help-card"
        style="max-height: 335px;
        overflow-y: auto;">
          <div class="container">

            <div class="form-group row">
              <label for="title" class="text text-2 weight-500 color-black">
                {{'title'|translate}} : 
              </label>
              <input type="text" class="form-input-field"  id="title" 
              formControlName = "title" placeholder="{{'title_placeholder'|translate}}">
            </div>
            <div class="form-group row mt-12">
              <label for="description" class="text text-2 weight-500 color-black">
                {{'description'|translate}} :
              </label>
              <input type="text" class="form-input-field"  id="description" 
              formControlName = "description" 
              placeholder="{{'description_placeholder'|translate}}">
            </div>
            <div class="row mt-12">
              <div class="col-12">
                <div class="card card-body preferrence-card">
                  <div class="row">
                    <div class="col-12 text-center text-bold text color-black"
                    style="font-size:16px; line-height:19px;">
                      {{'dont_know_what_to_type'|translate}}
                    </div>
                    <div class="col-12 text-center text text-1">
                      {{'please_use_audio'|translate}} 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col-12 large-device p-0 mt-16" style="
              justify-content: space-between;">
                <button class="btn-normal" data-toggle="modal" 
                data-target="#imageModal1" style="margin-right: 5px;"
                (click)="selectAttachOption(1)" >
                <img src="../../../../../assets/images/camera-icon.svg" 
                alt="camera-icon" width="16px" height="16px" style="margin-right:4px;">
                  {{'add_photos'|translate}}
                </button>
                <button class="btn-normal btn-active" (click)="selectAttachOption(3)"
                 data-toggle="modal" style="margin-right: 5px;
                 font-weight: 600;"
                data-target="#audioInputModal1">
                <img src="../../../../../assets/images/mic-icon.svg" 
                alt="mic-icon" width="16px" height="16px" style="margin-right:4px;">
                  {{'add_voice_note'|translate}}
                </button>
                <button class="btn-normal" data-toggle="modal" data-target="#exampleModal1"
                (click)="selectAttachOption(2)">
                <img src="../../../../../assets/images/video-recorder-icon.svg" 
                alt="video-recorder-icon" width="16px" height="16px" 
                style="margin-right:4px;">
                  {{'add_video'|translate}}
                </button>
              </div> -->
              <div class="col-12 mt-12" style="justify-content: center;
              margin-bottom:8px;display: flex;">
                <button class="btn-normal btn-active" (click)="selectAttachOption(3)" data-toggle="modal"
                data-target="#audioInputModal1" style="
                font-weight: 600;">
                <img src="../../../../../assets/images/mic-icon.svg" 
                alt="mic-icon" width="16px" height="16px" style="margin-right:4px;">
                  {{'add_voice_note'|translate}}
                </button>
              </div>
              <div class="col-12 p-0" style="
              justify-content: space-between;display: flex;">
                <button class="btn-normal" data-toggle="modal" data-target="#imageModal1"
                (click)="selectAttachOption(1)" >
                <img src="../../../../../assets/images/camera-icon.svg" 
                alt="camera-icon" width="16px" height="16px" style="margin-right:4px;">
                  {{'add_photos'|translate}}
                </button>
                <button class="btn-normal" data-toggle="modal" data-target="#exampleModal1"
                (click)="selectAttachOption(2)">
                <img src="../../../../../assets/images/video-recorder-icon.svg" 
                alt="video-recorder-icon" width="16px" height="16px" 
                style="margin-right:4px;">
                  {{'add_video'|translate}}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex mt-12"
               *ngIf="selectedImageThumbnail.length > 0"
                style="overflow-x: auto;">
                <div *ngFor="let image of selectedImageThumbnail; let idx= index"
                  class="imagesContainer addImageListItems mr-2" style="display: flex;">
                  <div><img style="height: 80px;max-width: 80px;
                  object-fit:cover;" src="{{image}}" alt="Image"></div>
                  <span class="remove-icon" (click)="remove_picture(idx)">
                    <img src="../../../../../assets/images/cross-close-icon.svg" 
                    alt="remove-icon" height="12" width="12">
                  </span>
                  <!-- <i class="fa fa-trash trash_icon" (click)="remove_picture(idx)"></i> -->
                </div>
              </div>
              <!-- audio record -->
              <div *ngIf="voiceOptionSelected" class="d-flex sm-flex-column mt-12">
                <div class="ml-2">
                  <audio *ngIf="!isRecording && audioBlobURL" controls>
                    <source [src]="audioBlobURL" type="audio/webm">
                  </audio>
                </div>
              </div>
        
              <div *ngIf="showVideo" class="col-12 p-3 d-flex mt-12">
                <div class="videoContainer">
                  <!-- <video id=ideoCustom src="{{videosThumbnile}}" style="height: 100px;"></video> -->
                  <img id=ideoCustom src="../../../../assets/images/Play 1.svg" style="height: 100px;" alt="Image">
                </div>
                <span class="remove-icon" (click)="removeVideoSelect()">
                  <img src="../../../../../assets/images/cross-close-icon.svg" 
                  alt="remove-icon" height="12" width="12">
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-12 mb-12">
      <div class="col-12 text-center">
        <button class="btn-normal btn-active active" 
          (click)="createHelpRequest()" [disabled]="helpRequestForm.invalid">
          {{'Create Request'|translate}}
        </button>
      </div>
    </div>
  </form>
  <!-- <div class="row complete-refund" *ngIf="isPaid">
    <div class="img-icon">
      <img src="../../../../../assets/images/refund-icon.svg" alt="refund-icon"
      height="24px" width="24px">
    </div>
    <div class="col">
      <span class="text">
        {{'complete_refund'|translate}}
      </span>
    </div>
  </div> -->
  <div class="row footer-card justify-content-between" *ngIf="isPaid"
  [ngClass]="{'bottom-0':fromAds}">
    <div class="col-auto p-0">
      <div class="img-circle">
        <img src="../../../assets/images/refund-icon.svg" alt="refund-icon">
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div class="col-12 text-center field-1">
          {{'we_offer_complete_refund'|translate}}
        </div>
        <div class="col-12 text-center field-2 p-0">
          {{'if_not_satisfied'|translate}}
        </div>
      </div>
    </div>
    <div class="col-auto p-0">
      <div class="img-circle">
        <img src="../../../assets/images/refund-icon.svg" alt="refund-icon">
      </div>
    </div>
  </div>
  <div class="row" style="position: absolute; bottom: 4px; right: 20px;" *ngIf="isPaid">
    <div class="col-12 field-3">
      {{'term_condition_apply'|translate}}
    </div>
  </div>
</div>



<!-- add media -->

<div class="modal fade" id="exampleModal1" *ngIf="videoOptionSelected && !videoOption && !videoSelected" tabindex="-1"
  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content jaano-modal">
      <div class="modal-header text-center">
        <span class="modal-title-1 heading text-center" id="exampleModalLabel">
          {{'upload_video' | translate}}
        </span>
        <!-- <h5 class="modal-title heading d-inline-block" id="exampleModalLabel">{{'upload_a_video' | translate}}</h5>
        <button type="button" #closeVideobutton class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>

      <div class="modal-body">
        <div class="row justify-content-center" style="margin-bottom: 10px;">
          <div class="col-12">
            <div class="modal-box justify-content-center" data-dismiss="modal" 
            (click)="videoUploadOption(2)" data-toggle="modal"
              data-target="#videoRecordModal1">
              <img src="../../../../assets/images/new_help_icons/capture-video-icon.svg" 
              alt="Take video" width="16px" height="16px"
              class="mr-2 choose-picture-active">
              <img src="../../../../assets/images/new_help_icons/capture-video-icon.svg" 
              alt="Take video" width="16px" height="16px" class="mr-2 choose-picture">
              <span class="upload-image-text">{{'capture_video' | translate}}</span>
              <!-- <div>{{'Record Video' | translate}}</div>
              <div class="camera-wrapper"><img src="../../../../assets/images/home-images/record-inactive.svg"
                  alt="Take Picture" class="choose-picture">
                <img src="../../../../assets/images/home-images/record-active.svg" alt="Take Picture"
                  class="choose-picture-active">
              </div> -->
            </div>
          </div>
        </div>
        <div class="row justify-content-center" (click)="videoUploadOption(1)">
          <div class="col-12">
            <label for="file-input-video2" class="modal-box file-upload-media
            justify-content-center">
              <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
              class="mr-2 choose-picture-active" width="20px" height="18px">
              <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
              class="mr-2 choose-picture" width="20px" height="18px">
              <span class="upload-image-text">
                {{'choose_video' | translate}}
              </span>
            </label>
          </div>
          <input id="file-input-video2" (change)="handleVideoSelection($event)" type="file"
            accept="{{selectedFileType}}" />
        </div>
        
      </div>

      <div class="modal-footer">
        <button class="float-right modal-close-btn"
        #closeVideobutton1 data-dismiss="modal" aria-label="Close">
          {{'cancel'|translate}}
        </button>
      </div>
    </div>
  </div>
</div>
<button id="exampleModalButton" [hidden]="true" data-dismiss="modal" data-target="#exampleModal1"></button>
<div class="modal fade" id="videoRecordModal1" *ngIf="openRecordVideo == true" tabindex="-1" role="dialog"
  aria-labelledby="videoRecordModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div>
          <div class="col-12 col-sm-12 col-xs-12" style="text-align: right;">
            <i class="fa fa-close cross_icon" data-dismiss="modal" data-target="#videoRecordModal"
              (click)="remove_video()"></i>
          </div>
          <div class="col-12 col-xs-12 col-sm-12">
            <video #video1 class="video mb-3 mr-2 mt-2"></video>
          </div>
          <div class="col-12 mb-3 sm-pd0">
            <div class="text-center">
              <button *ngIf="!isRecordingVideo && !showVideo" md-raised-button color="primary"
                (click)="startVideoRecording()" class="file-upload">
                <img src="../../../../assets/images/start_recording_video.svg" alt="Image" />
              </button>
              <button *ngIf="isRecordingVideo && !showVideo" md-raised-button color="primary" data-dismiss="modal"
                data-target="#videoRecordModal" (click)="stopVideoRecording()" class="file-upload">
                <img src="../../../../assets/images/stop_recording_video.svg" alt="Image" />
              </button>
              <button *ngIf="showVideo" md-raised-button color="primary" data-dismiss="modal"
                data-target="#videoRecordModal" (click)="remove_video()" class="file-upload">
                <img src="../../../../assets/images/remove_video.svg" alt="Image" />
              </button>
              <button *ngIf="showVideo" md-raised-button color="primary" data-dismiss="modal"
                data-target="#videoRecordModal" class="file-upload">
                <img src="../../../../assets/images/accept_video.svg" alt="Image" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button id="videoRecordModalButton" [hidden]="true" data-dismiss="modal" data-target="#videoRecordModal"></button>
<div class="modal fade" id="imageCaptureModal1" *ngIf="imageClickOption" tabindex="-1" role="dialog"
  aria-labelledby="imageCaptureModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <webcam class="webcamClass" style="display: block; width: 100%;" [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)" *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch"
            [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" [height]="280" [width]="280"
            [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
          </webcam>
          <button class="file-upload actionBtn">
          </button>
          <button (click)="triggerSnapshot();" data-dismiss="modal" data-target="#imageCaptureModal1"
            class="file-upload actionBtn">
            <img src="../../../../assets/images/capture_photo.svg" alt="Image" />
          </button>
          <button (click)="toggleWebcam();" data-dismiss="modal" data-target="#imageCaptureModal1"
            class="file-upload actionBtn">
            <img src="../../../../assets/images/camera_off.svg" alt="Image" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<button id="imageCaptureModalButton1" [hidden]="true" data-dismiss="modal" data-target="#imageCaptureModal1"></button>
<div class="modal fade" id="imageModal1" *ngIf="imageOptionSelected" tabindex="-1" role="dialog"
  aria-labelledby="imageModal1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content jaano-modal">
      <div class="modal-header text-center">

        <span class="modal-title-1 heading text-center" id="exampleModalLabel">
          {{'upload_image' | translate}}
        </span>
        <!-- <button type="button" class="close" #closeImagebutton data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>

      <div class="modal-body">
        <div class="row justify-content-center" style="margin-bottom: 10px;">
          <div class="col-12">
            <div class="modal-box justify-content-center" data-dismiss="modal" data-target="#imageModal1" (click)="imageUploadOption(1)"
              data-toggle="modal" data-target="#imageCaptureModal1">
              <img src="../../../../assets/images/new_help_icons/capcture-img-icon.svg" 
              alt="Take Picture" width="16px" height="16px"
              class="mr-2 choose-picture-active">
              <img src="../../../../assets/images/new_help_icons/capcture-img-icon.svg" 
              alt="Take Picture" width="16px" height="16px" class="mr-2 choose-picture">
              <span class="upload-image-text">{{'capture_photo' | translate}}</span>
              <!-- <div class="camera-wrapper">
                <img src="../../../../assets/images/home-images/camera-inactive.svg"
                  alt="Take Picture" class="choose-picture">
                <img src="../../../../assets/images/home-images/camera-active.svg" alt="Take Picture"
                  class="choose-picture-active">
              </div> -->
            </div>
          </div>
        </div>
        <div class="row justify-content-center" (click)="imageUploadOption(2)">
          <div class="col-12">
            <label for="file-input2" class="modal-box file-upload-media
            justify-content-center">
                <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
                class="mr-2 choose-picture-active" width="20px" height="18px">
                <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
                class="mr-2 choose-picture" width="20px" height="18px">
                <span class="upload-image-text">
                  {{'choose_photo' | translate}}
                </span>
                <!-- <img src="../../../../assets/images/home-images/picture-inactive.svg" alt="Picture"
                  class="choose-picture">
                <img src="../../../../assets/images/home-images/picture-active.svg" alt="Picture"
                  class="choose-picture-active"> -->
            </label>
          </div>
          <input id="file-input2" (change)="handleFileSelect($event)" type="file" accept='image/*' multiple />

        </div>
      </div>
      <div class="modal-footer">
        <button class="float-right modal-close-btn"
        #closeImagebutton1 data-dismiss="modal" aria-label="Close">
          {{'cancel'|translate}}
        </button>
      </div>
    </div>
  </div>

</div>
<button id="imageModalButton" [hidden]="true" data-dismiss="modal" data-target="#imageModal1"></button>
<!-- Audio Input Dialog -->
<div class="modal fade" id="audioInputModal1" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="text-align: center;">
      <div class="modal-body" style="padding-bottom:0px;margin: auto;">
        <div class="audio-text text-center" >{{'record_audio'|translate}}</div>
        <div class="col-12" style="position:relative; top:130px;left: -6px;"
        *ngIf="!tempAudioBlob" >
          <img *ngIf="!isRecording && !tempAudioBlob" (click)="startAudioRecording()"
            src="../../../../assets/images/audioButtons/record_audio_start.svg" alt="Image">
          <img *ngIf="isRecording && !tempAudioBlob" (click)="stopAudioRecording()"
            src="../../../../assets/images/audioButtons/record_audio_stop.svg" alt="Image">
        </div>
        <!-- *ngIf="voiceOptionSelected" -->
        <audio *ngIf="!isRecording && tempAudioBlob" controls
        style="margin-top:72px;">
          <source [src]="tempAudioBlob" type="audio/webm">
        </audio>
        <div id="progress" class="dyanmicProgress" *ngIf="!tempAudioBlob">
          <span id="progress-value"></span>
        </div>
        <div *ngIf="!tempAudioBlob" style="position: absolute; top: 100%; left: 40%;">
          {{timer}} Sec
        </div>
        <!-- <circle-progress *ngIf="!tempAudioBlob" [percent]="audioRecorderProgress" [outerStrokeWidth]="10"
          [innerStrokeWidth]="10" [titleFontSize]="24" [title]="setTitleOfProgressBar()" [unitsFontSize]="24"
          [animation]="true" [subtitle] [animationDuration]="300" [startFromZero]="false" [maxPercent]="100">
        </circle-progress>
        <div class="col-12 text-center" *ngIf="!tempAudioBlob">
          <span class="audio-text grey-color">
            {{transformIntoSec(audioRecorderProgress)}}
          </span>
        </div> -->
      </div>
      <div class="modal-footer col-sm-12 col-xs-12" style="padding-top:0px;">

        <div class="col-12 text-center" style="margin-top:24px; margin-bottom:52px;">
          <img *ngIf="!isRecording && tempAudioBlob" style="cursor:pointer"
          (click)="tempAudioBlob= null;startAudioRecording()"
          src="../../../../../assets/images/restart-recording.svg" alt="Image" />
        </div>

        <div class="col-6">
          <button class="submit-btn" (click)="clearRecordedData()"  data-dismiss="modal"
          [disabled]="isRecording && !tempAudioBlob">
            {{'cancel'|translate|titlecase}}
          </button>
          <!-- <img *ngIf="isRecording || !tempAudioBlob"
            src="../../../../assets/images/audioButtons/record_audio_save_disable.svg" alt="Image">
          <img *ngIf="!isRecording && tempAudioBlob && !audioBlobURL" (click)="saveAudio()"
            src="../../../../assets/images/audioButtons/record_audio_save_enable.svg" alt="Image">
          <img *ngIf="!isRecording && audioBlobURL" data-dismiss="modal"
            src="../../../../assets/images/audioButtons/record_audio_save_active.svg" alt="Image"> -->
        </div>

        <div class="col-6 p-0">
          <button class="submit-btn" [disabled]="isRecording || !tempAudioBlob"
          *ngIf="isRecording || !tempAudioBlob">
            {{'submit'|translate|titlecase}}
          </button>
          <button class="submit-btn" *ngIf="!isRecording && tempAudioBlob && !audioBlobURL"
          (click)="saveAudio()">
            {{'submit'|translate|titlecase}}
          </button>
          <button style="display:none" #closeAudioModal1 data-dismiss="modal"></button>
          <!-- <img *ngIf="isRecording || !tempAudioBlob"
            src="../../../../assets/images/audioButtons/record_audio_delete_disable.svg" alt="Image">
          <img *ngIf="!isRecording && tempAudioBlob && !audioBlobURL" (click)="clearRecordedData()"
            src="../../../../assets/images/audioButtons/record_audio_delete_enable.svg" alt="Image">
          <img *ngIf="!isRecording && audioBlobURL" data-dismiss="modal" (click)="clearRecordedData()"
            src="../../../../assets/images/audioButtons/record_audio_delete_active.svg" alt="Image"> -->
        </div>

      </div>
    </div>
  </div>
</div>