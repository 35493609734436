import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as converter from 'number-to-words';


@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.scss']
})
export class PaymentReceiptComponent implements OnInit {

  paymentObj:{
    emitra_id: string,
    emitra_name: string,
    full_name: string,
    helpid: string,
    payment_mode: string,
    phone_no: string,
    receipt_no: string,
    txn_amount: number,
    txn_time: number,
    username: string
  };
  amountInWords: string="";
  constructor(
    public dialogRef: MatDialogRef<PaymentReceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      this.paymentObj = data;
      this.paymentObj.txn_time = this.paymentObj.txn_time*1000;
      if(this.paymentObj.txn_amount){
        this.amountInWords = converter.toWords(this.paymentObj.txn_amount);
      }
     }

  ngOnInit() {
  }

  downloadReceipt(){
    let original:any = document.getElementById("viewportMeta")
    original?original=original.getAttribute("content"):'';
    let element = document.getElementById("viewportMeta");
    element?element.setAttribute("content","width=900"):"";
    let element2:any= document.querySelector("#paymentReceiptDownload");
    html2canvas(element2).then(canvas =>{
      // let pdf= new jspdf('p','pt',[canvas.width,canvas.height]);
      // let imgData= canvas.toDataURL("image/jpeg",1.0);
      // pdf.addImage(imgData,0,0,canvas.width,canvas.height);
      // const timestamp= new Date().getTime();
      // pdf.save('payment-receipt_'+timestamp+'.pdf');
      let fileName = "Payment_receipt"+new Date().getTime();
      let dataUrl = canvas.toDataURL('image/jpeg', 1.0);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = fileName;
      link.click();
      link.remove();
      let element3 = document.getElementById("viewportMeta");
      element3?element3.setAttribute("content",original):"";
    })
  }

}
