import { catchError, map, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { DataService } from './data.service';

@Injectable()
export class HrGuard implements CanActivate, CanActivateChild {
  constructor(private _auth: AuthService,private dataService:DataService,private router:Router) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> |boolean{
    return this.canActivate(route, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> |boolean{
    let hr = route.queryParams['hr'];
    if(hr){
      this.router.navigate(["expanded-view"], {
          queryParams: { hr:hr },
      });
      console.debug('returning from 2')
      return false;
    }
    else{
        console.debug('returning from 4')
        return true;
    }
  }
}