import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDetailService {
  selectedLanguage:any;
  categoryList:any;
  public loginUserDetail = new Subject<any>();
  public userDt = this.loginUserDetail.asObservable();
  constructor() { }

  setUserDetails(username:any,profile_picture:any){
    let loginUserDt =[username,profile_picture];
    this.loginUserDetail.next(loginUserDt);
  }
}
