import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  ViewChild,
  HostListener,
  ViewChildren,
  AfterViewInit,
  Testability,
  Output,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { NguCarouselConfig } from "@ngu/carousel";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";

import {
  Subject,
  Observable,
  Observer,
  Subscription,
  of as observableOf,
  interval
} from "rxjs";

import { DataService } from "../../../services/data.service";
import { ShowToastyService } from "../../../services/showToastyService";
import { LanguageTranslationService } from "../../../services/language-translation-service";
import { CommonService } from "../../../services/common.service";
import { LoginDialogService } from "../../../services/loginService";
import { ReportPostDialogComponent } from "../../../shared-components/report-post-dialog/report-post-dialog.component";
import { GoogleAnalyticsService } from "../../../services/google-analytics.service";
import { HelpRequestCategoryServiceService } from "../../../services/help-request-category-service.service";
import { PreviousRouteService } from "../../../services/previous-route.service";
import { MoengageEventsService } from "../../../services/moengage_events_service";
import { SeoService } from "../../../seo.service";
import { Gratification } from "../../../shared-components/gratification-dialog/gratification.component";
import { UploadService } from "../../../services/uploadService";
import { StringcheckerService } from "../../../services/StringcheckerService";
import { LoclangDialogsService } from "../../../services/loclang-dialogs.service";
import { ImgTextButtonCustomDialogComponent } from "../../../shared-components/img_text_button_customdiaog/img_text_button_customdiaog";
import { VideoPlayer } from "../../../shared-components/video_player_dialog/video_player_dialog";
import { SplashScreenComponent } from "../../../shared-components/splash-screen/splash-screen.component";
import { FormControl, FormGroup } from "@angular/forms";
import { GetSpecificHelpRequestService } from "../../../services/get-specific-help-request.service";

import { TranslateModule } from "@ngx-translate/core";
import { PaynowDialogComponent } from "./paynow-dialog/paynow-dialog.component";
import { PaymentReceiptComponent } from "../payment-receipt/payment-receipt.component";
import { ConfirmDialogPaidHelpComponent } from "./confirm-dialog-paid-help/confirm-dialog-paid-help.component";
import { map, mapTo, repeat, switchMap, tap } from "rxjs/operators";
import { CookieService } from "ngx-cookie";
import { AuthService } from "../../../services/auth.service";
import RecordRTC from "recordrtc";
import { PaymentExitForm, RazorPayTxn } from "src/app/models/models";
import { WindowRefService } from "../window-ref.service";
import { environment } from "src/environments/environment";
import { PaymentMethod } from "src/app/enums/enum.type";

declare var FB: any;
declare var require: any;
declare var hj:any;
// const MicRecorder = require('mic-recorder-to-mp3');

@Component({
  selector: "app-get-specific-help-request",
  templateUrl: "./get-specific-help-request.component.html",
  styleUrls: ["./get-specific-help-request.component.scss"],
})
export class GetSpecificHelpRequestComponent implements OnInit, AfterViewInit {
  @ViewChild('expandedView') expandedView: ElementRef= new ElementRef(null);
  @ViewChild("video1") video: any;
  @ViewChild("closeVideobutton") closeVideobutton:any;
  @ViewChild("closeImagebutton") closeImagebutton:any;
  @ViewChild("closeAudioModal") closeAudioModal:any;
  @ViewChild("closeSupportDialog") closeSupportDialog:any;

  color = "primary";
  mode = "indeterminate";
  value = 50;
  IsLoader: boolean = false;
  mobileScreen: boolean = false;
  helpRequestId: any;
  isAssignedEmitra: boolean = false;
  assignedEmitraId: string= "";
  assignedEmitraName: string="";
  assignedEmitraProfilePicture: string="";
  assignedEmitraPhoneNumber: string="";
  hrEmitraStatus: string="";
  hrUserStatus: string="";
  userProfilePicture: any="";
  paymentPending: boolean = false;
  paymentSuccess: boolean = false;
  paymentAmount = 0;
  username:any;
  helpRequestUserName: string="";
  requestDetails:any;
  latestHelpid:any="";
  likeRequest:any;
  request:any;
  lat:any;
  lng:any;
  myComment = "";
  stcode11:any;
  dtcode11:any;
  stname:any;
  dtname:any;
  commentToShow:any;
  commentDeatils:any;
  thankYouMessage = "";
  firstpage = false;
  editpage = false;
  replyBox = false;
  myReplyOnComment = "";
  requestDescription = "";
  select_message_type = 0;
  profile_picture:any;
  selectedComment:any;
  mediaArray:any[] = [];
  url: string="";
  twitterShareLink: string = "";
  whatsappShareLink: string = "";
  requestTitle = "";
  IsMarkedSolution = false;
  selectedSubCategory:any;
  selectedLanguage:any;
  phone_No:any;
  userdata:any;
  selectCommentIndex:any;
  usernameIntial:any;
  subscriptions: Subscription[] = [];
  categoryList = [];
  subcategory:any;
  replyType = 0;
  previousUrl: string="";
  oneQuarterTimer: any;
  halfQuarterTimer: any;
  queryParams:any;
  desp_video:any;
  trendingCount:any;
  disablePostButtton = false;
  dataReady = false;
  passCatID:any = "";
  passSubCatID = "";
  showThankYouNote: boolean = false;
  paymentFailed: boolean = false;
  isPaidHelp = false;
  isConvertedToFree = false;
  fromReferral=false;

  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 500,
    point: {
      visible: true,
    },
    load: 2,
    velocity: 0,
    touch: true,
    loop: true,
    easing: "cubic-bezier(0, 0, 0.2, 1)",
  };

  //Audio in suggestion
  audioRecorderProgress = 0;
  audioRecorderTimer = 0;
  isRecording = false;
  selectAudioFile: any;
  intervalId:any;
  // recorder = new MicRecorder({
  //   bitRate: 128,
  // });
  AUDIODURATION = 60; //in seconds
  audioBlobURL:any;
  tempAudioBlob:any;
  tempSelectedAudio:any;
  isDisable: boolean=false;
  audioTimeIssue: boolean=false;
  voiceOption = false;
  voiceOptionSelected = false;
  selectHelpVoice: any;
  //...Audio in suggestion

  // Video in suggestions
  private VIDEODURATION = "30"; // in seconds
  showVideo: boolean = false;
  isRecordingVideo: boolean = false;
  private recordRTC: any;
  openRecordVideo = false;
  public showWebcam = true;
  videoOption:any = false;
  videoUpload = false;
  videoRecord = false;
  selectedFileType = "video/*";
  selectedVideoFiles: any;
  videoTimeIssue: boolean=false;
  videosThumbnile: any;
  videoOptionSelected = false;

  // Image in suggestion
  imageClickOption = false;
  ImageFiles:any[] = [];
  private stream: MediaStream|any;
  imageSelect = false;
  imageOption = false;
  selectedImageThumbnail:any[] = [];
  imageOptionSelected = false;
  public deviceId: string="";
  public webcamImage: WebcamImage|any = null; // latest snapshot
  private trigger: Subject<void> = new Subject<void>(); // webcam snapshot trigger
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >(); // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  base64TrimmedURL: string="";
  base64DefaultURL: string="";
  moreContentForYou = [];
  requestSubscribe: any;

  hasBids:boolean = false;
  hasListing: boolean = false;
  showListing: boolean = false;
  minPrice = 0;
  maxPrice = 0;
  helpCostString:any= "";
  bidList:{rating:number, bid_id:number, bid_title: string,dtname:string,rating_count:number,
    emitra_id: string, helpid: string, msg: string,name:string, stname:string,
    phone_no: string, price: number, profile_picture: string, seeMore: boolean,num_hr_solved:number}[] = [];

  listings: {rating:number, ctg:string,emitra_id: string, helpid:string,
     help_title: string, listing_id: number ,msg: string,name:string,
    phone_no: string, price: number, profile_picture: string, status: string,
    sub_ctg:number, seeMore:boolean}[] = [];

  showReviewCard: boolean = false;
  ratingStarCount=0;
  review:string="";

  userReview:{
    hasReview:boolean,
    message:string,
    rating:number,
    hasMessage:boolean
  } = {hasReview:false,message:'',rating:0,hasMessage:false}


  sliderSubscription: Subscription = new Subscription;
  countDownTimer : Subscription = new Subscription;
  oneMinuteCountDownTimer: Subscription = new Subscription;
  timeDifference : number=0;
  timeDifference2 : number=0;
  secondCountDown : number=0;
  secondCountDown2 : number=0;
  minuteCountDown : number=0;
  hourCountDown : number=0;
  showSecondsTimer: boolean = false;
  showPaymentProcessingTimer: boolean = false;
  floatingState = true;
  showEmitra = false;
  showOffers = false;
  showOffers2 = false;
  docEl:HTMLElement|any;
  hasLoginModal=false;
  loginRequired = false;
  hrRefreshed=false;


  customerReviews2 = [
    {
      userProfilePicture:'https://firebasestorage.googleapis.com/v0/b/jaano2.appspot.com/o/rating-img-1.png?alt=media&token=3fbcd649-f48c-4179-b1db-50a6cff274b6',
      userid:'Kalyan Ranjeet',
      location:'Andhra Pradesh',
      rating:5,
      comment:"Jaano made getting government assistance a breeze! Quick responses, helpful guidance, and reliable service. Highly recommend!"
    },
    {
      userProfilePicture:'https://firebasestorage.googleapis.com/v0/b/jaano2.appspot.com/o/rating-img-2.png?alt=media&token=c815d283-fe46-45dd-af30-79c5f40d198f',
      userid:'Samira Nirupama',
      location:'Uttar Pradesh',
      rating:5,
      comment:"Impressed with Jaano's efficiency! Within 24 hours, my request was resolved. Great platform for navigating government services."
    },
    {
      userProfilePicture:'https://firebasestorage.googleapis.com/v0/b/jaano2.appspot.com/o/rating-img-3.png?alt=media&token=b7567830-b75e-499a-a237-78faa21d5925',
      userid:'Gurmeet Nand',
      location:'Delhi',
      rating:5,
      comment:"Jaano is a game-changer! Simple interface, timely help, and a money-back guarantee. Couldn't ask for more in government assistance."
    },
  ];
  hideMainPopup = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private languageService: LanguageTranslationService,
    private helpRequestService: HelpRequestCategoryServiceService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private loginService: LoginDialogService,
    public sanitizer: DomSanitizer,
    private toastyService: ShowToastyService,
    private dialog: MatDialog,
    private cdf: ChangeDetectorRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private previousRouteService: PreviousRouteService,
    private seoService: SeoService,
    private uploadService: UploadService,
    private checkString: StringcheckerService,
    private loclatdialog: LoclangDialogsService,
    private moeService: MoengageEventsService,
    private getSpecificHelpRequestService: GetSpecificHelpRequestService,
    private el: ElementRef,
    private cookieService: CookieService,
    private authService: AuthService,
    private winRef:WindowRefService
  ) {
    this.moeService.eventEmitter("Web_Help_Expanded_Visit", {
      platform: "web",
    });
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.helpRequestId = params["Id"];
        const paidType = Boolean(params["isPaidType"]);
        const unpaidType = Boolean(params["unpaid"]);
        let helpID = localStorage.getItem("helpID");
        //  checking the requiredLogin param to constraint login if not logged in
        this.loginRequired = Boolean(params["requiredLogin"]);
        if (this.helpRequestId == undefined && helpID != undefined) {
          this.helpRequestId = helpID;
          localStorage.removeItem("helpID");
          let state:any = { Id: helpID };
          let source=localStorage.getItem('source');
          let status = localStorage.getItem('status');
          if(status=='0' && source == 'home'){
            state = {Id: helpID, status : '0', source: 'home'};
            localStorage.removeItem('source');
            localStorage.removeItem('status');
          }
          let url = this.router
            .createUrlTree([], {
              relativeTo: this.activatedRoute,
              queryParams: state,
            })
            .toString();
          this.router.navigateByUrl(url);
        }
        if(paidType){
          this.showThankYouNote = true;
          this.router.navigate([], {
            queryParams: {
              'isPaidType': null,
            },
            queryParamsHandling: 'merge'
          });
          this.reloadTimerOfferSubject = null;
          this.hasBids = false;
          this.reloadTimerType = 'thankyouCard';
        }
        if(unpaidType){
          this.router.navigate([], {
            queryParams: {
              'unpaid': null,
            },
            queryParamsHandling: 'merge'
          })
        }
        this.loadData();
        this.docEl = document.getElementById("topNavbar");
        if(this.docEl){
          this.docEl.scrollIntoView();
        }
        const acceptOffer = Boolean(params["acceptOffer"]);
        if(acceptOffer){
          console.log('inside accept offer')
          const bid = this.getEmitraBid();
          this.acceptOffer(bid);
          this.router.navigate([], {
            queryParams: {
              'acceptOffer': null,
              'paymentStatus':null,
              'assignedEmitra':null,
              'assignedAmount':null,
              'userStatus':null
            },
            queryParamsHandling: 'merge'
          })
        }
      })
    );

    this.twitterShareLink = this.commonService.getTwitterLink();
    this.whatsappShareLink = this.commonService.getWhatsappLink();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.setPageView({
          page_title: "Help-request expanded-view Web",
          page_path: event.urlAfterRedirects,
        });
      }
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = params;
    });

    // if user refreshed show be calm
    const hrRefreshed = localStorage.getItem('hr_refreshed');
    if(hrRefreshed && hrRefreshed == this.helpRequestId){
      this.hrRefreshed = true;
      setTimeout(() => {
        this.hrRefreshed = false;
      }, 5000);
    }
    else{
      localStorage.setItem('hr_refreshed',this.helpRequestId);
    }
  }

  WhatsApp=()=>{
    this.dataService.WhatsApp().subscribe(response=>{
      console.log('response from whatsapp',response);
    })
  }
  
  getEmitraBid(){
    let assignedEmitra = this.assignedEmitraId;
    if(assignedEmitra =="" && this.bidList && this.bidList.length>0){
      assignedEmitra = this.bidList[0].emitra_id;
      this.paymentAmount = this.bidList[0].price;
      this.assignedEmitraName = this.bidList[0].name;
    }
    let bid = {
      price: this.paymentAmount,
      emitra_id: assignedEmitra,
      name: this.assignedEmitraName,
      picture: null
    }
    console.log('bid',bid);
    return bid;
  }

  randomRequests=()=>{
    return Math.floor(Math.random() * (11) + 5);
  }

  disableFloatState2(){
    if(!this.hasBids){
      if(this.thankyouCardTimer >0){
        return false;
      }
    }
    this.floatingState = false;
    this.showEmitra = false;
    this.showOffers = false;
    this.showOffers2 = false;
  }

  disableFloatState(){
    if(this.bidList.length==0 && this.hasOffer ==0){
      return;
    }
    if(!this.hasBids){
      if(this.thankyouCardTimer >0){
        return false;
      }
    }
    this.floatingState = false;
    this.showEmitra = false;
    this.showOffers = false;
    this.showOffers2 = false;
  }
  setHeight() {
    setTimeout((res:any) => {
      let ele = document.getElementById("firstmain");
      if (ele) {
        let eleheight = ele.clientHeight;
        const element = document.getElementById("recommendedPostsCont");
        element?element.style.height = "calc( " + eleheight.toString() + "px - 3rem)":'';
      }
    }, 1000);
  }

  ngOnInit() {
    let style:string  = `.dyanmicProgress{background: conic-gradient(#304FFE ${this.progress}%, #f2f2f4 ${this.progress}%)}`;
    this.progressBar(style);
    if (isPlatformBrowser(this.platformId)) {
      this.oneQuarterTimer = setTimeout(() => {
        this.moeService.eventEmitter("web_OfferHelp_ExpandedView_15s", {
          platform: "web",
        });
      }, 15001);
      this.halfQuarterTimer = setTimeout(() => {
        this.moeService.eventEmitter("web_OfferHelp_ExpandedView_30s", {
          platform: "web",
        });
      }, 30001);
    }

    // this.subscriptions.push(
    //   this.commonService.selectedLocation.subscribe((value) => {
    //     debugger
    //     if (value && Object.keys(value).length > 0) {
    //       this.lat = value.lat();
    //       this.lng = value.lng();
    //     }
    //   })
    // );
    this.languageService.startLanguageTranslator();

    this.username = this.commonService.getUsername();
    this.userProfilePicture = localStorage.getItem('profile_picture');
    this.commonService.subscribeToUsername().subscribe((res) => {
      this.username = res;
      if (this.username != "" && this.username != null) {
        this.usernameIntial = this.username.charAt(0).toUpperCase();
      }
    });
    this.stcode11 = localStorage.getItem("stcode11");
    this.dtcode11 = localStorage.getItem("dtcode11");
    this.stname = localStorage.getItem("stname");
    this.dtname = localStorage.getItem("dtname");
    this.phone_No = localStorage.getItem("phone_no");
    this.profile_picture = localStorage.getItem("profile_picture");
    if (this.username != "" && this.username != null) {
      this.usernameIntial = this.username.charAt(0).toUpperCase();
    }

    if (isPlatformBrowser(this.platformId)) {
      if (
        !(localStorage.getItem("lang") && localStorage.getItem("user_location"))
      ) {
        // let dialogRef = this.dialog.open(SplashScreenComponent, {
        //   width: "100%",
        //   height: "100%",
        //   maxWidth: "100%",
        //   panelClass: "no-padding-dialog",
        // });
        // dialogRef.afterClosed().subscribe((result) => {
        // });
        this.loadData();
      } else {
        this.loadData();
      }
    } else {
      this.loadData();
    }
    setTimeout(() => {
      this.loadDataForPopUpComponent();
    }, 1500);

    this.getTopEmitras();
    this.sliderSubscribe();
    // this.reloadApi();
  }

  onCloseSupportDialog=()=>{
    this.closeSupportDialog.nativeElement.click();
    this.googleAnalyticsService.eventEmitter('report_pp_c1_call',"",
    "");
    (window as any).hj('event', 'report_pp_c1_call');
  }

  slideScreen :number = 1;
  sliderSubscribe(){
    this.sliderSubscription =  interval(5000).subscribe(x => {
      if(this.slideScreen == 4){
        this.slideScreen = 1;
      }
      else{
        this.slideScreen = this.slideScreen +1;
      }
    });
  }
// let reqStatusfetchsubscribe = this.commonService.reqStatusfetch.subscribe((res: any) => {console.log("--result----",res)
//     if(res == 0 || res == 1){
//         reqStatusfetchsubscribe.unsubscribe()
//       }
//     })


askedForSupport =false;
onSumitAskForSupport=()=>{
  this.IsLoader = true;
  const helpid = this.helpRequestId;
  this.dataService.askForSupport(helpid).subscribe(response=>{
    if(response){
      this.askedForSupport = true;
    }
    this.IsLoader = false;
    this.googleAnalyticsService.eventEmitter('report_pp_c2_ok',"",
    "");
    (window as any).hj('event', 'report_pp_c2_ok');
  },error=>{
    this.IsLoader = false;
  })
}

  isDataLoaded=false;
  loadData() {

    this.subscriptions.push(
      this.languageService.getSelectedLanguage().subscribe((value) => {
        console.log('get value from selectedlanguage',value)
        if (value) {
          this.selectedLanguage = value;
          // if (isPlatformBrowser(this.platformId)) {
          this.helpRequestService.getHelpRequestCategoriesList(
            this.selectedLanguage
          );
          this.getHelpFilters();

          let subscriptions = this.helpRequestService.listObservable.subscribe(
            (data) => {
              this.categoryList = data;
              this.commentToShow = 20;
              this.getRequestDetailbyId(this.helpRequestId, this.commentToShow);
              subscriptions.unsubscribe();
            }
          );
          // } else {
          //   this.categoryList = [];
          //   this.commentToShow = 20;
          //   this.getRequestDetailbyId(this.helpRequestId, this.commentToShow);
          // }
          this.commonService.selectedLocation.subscribe((value) => {
            if (
              value &&
              Object.keys(value).length > 0 &&
              (Number(localStorage.getItem("admin_defined_location")) > 0 ||
                Number(localStorage.getItem("admin_defined_location")) < 0)
            ) {
              this.stcode11 = localStorage.getItem("stcode11");
              this.dtcode11 = localStorage.getItem("dtcode11");
              this.stname = localStorage.getItem("stname");
              this.dtname = localStorage.getItem("dtname");
              if (Number(localStorage.getItem("admin_defined_location")) == 1)
                localStorage.setItem("admin_defined_location", "0");
              // login constraint
              this.loginConstraint();
            } else {
              if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem("admin_defined_location", "-1");
                if(!this.hasLocationDialog){
                  this.hasLocationDialog = true;
                  this.loclatdialog.openChangeLocationDialog((res:any) => {
                  });
                }
              }
            }
          })
        } else {
          if (isPlatformBrowser(this.platformId) && !this.changeLanguageDialog){
            this.changeLanguageDialog = true;
            this.loclatdialog.openChangeLanguageDialog(() => {
            });
          }
        }
      })
    );
  }

  hasLocationDialog=false;
  loginConstraint(){
    if(this.loginRequired && !this.hasLoginModal){
      this.hasLoginModal = true;
      this.loginService.openModal('', (status: string) => {
        this.hasLoginModal = false;
        if (!status) {
          this.moeService.eventEmitter('web_Login/Signup_Left', {platform : "web"}); 
        }
      });
    }
  }

  contactViaSms(){
    this.googleAnalyticsService.eventEmitter('conct_via_SMS',"contact via sms",
    "contact via sms");
    (window as any).hj('event', 'conct_via_SMS');
  }
  contactViaPhone(){
    this.googleAnalyticsService.eventEmitter('conct_via_phone',"contact via sms",
    "contact via phone");
    (window as any).hj('event', 'conct_via_phone');
  }
  contactViaWhatsapp(){
    this.googleAnalyticsService.eventEmitter('conct_via_whatsapp',"contact via sms",
    "contact via whatsapp");
    (window as any).hj('event', 'conct_via_whatsapp');
  }
  onReport(){
    this.googleAnalyticsService.eventEmitter('report_init_pp',"report on no respond from helper",
    "report on no respond from helper");
    (window as any).hj('event', 'report_init_pp');
  }
  onReportSubmitc1(){
    if(!this.noResponseFromEmitra.viaPhone){
      this.googleAnalyticsService.eventEmitter('report_pp_c1',"",
      "");
      (window as any).hj('event', 'report_pp_c1');
    }
    else{
      this.googleAnalyticsService.eventEmitter('report_pp_c2',"",
      "");
      (window as any).hj('event', 'report_pp_c2');
    }
  }
  onReportSubmitc1Closed(){
    this.googleAnalyticsService.eventEmitter('report_pp_c1_clsd',"",
    "");
    (window as any).hj('event', 'report_pp_c1_clsd');
  }

  viewMoreOfferEvent(){
    this.googleAnalyticsService.eventEmitter('vm_offers',"",
    "");
    (window as any).hj('event', 'vm_offers');
  }


  changeLanguageDialog = false;

  ngOnDestroy(): void {
    // clear sliding interval
    if(this.sliderInterval){
      clearInterval(this.sliderInterval)
    }
    this.abortRecording();
    clearTimeout(this.oneQuarterTimer);
    clearTimeout(this.halfQuarterTimer);
    clearInterval(this.intervalId);
    // prevent memory leak when component destroyed
    this.subscriptions.forEach((s) => s.unsubscribe());
    if(this.countDownTimer){
      this.countDownTimer.unsubscribe();
    }
    if(this.oneMinuteCountDownTimer){
      this.oneMinuteCountDownTimer.unsubscribe();
    }
    if(this.sliderSubscription){
      this.sliderSubscription.unsubscribe();
    }
    // if(this.reloadApiSubject){
    //   this.reloadApiSubject.unsubscribe();
    // }
    this.commonService.dontShowFooter.next(false);
    if(this.offerListner){
      this.offerListner.unsubscribe();
    }
    if(this.fbListener){
      this.fbListener.unsubscribe();
    }
  }

  ngAfterContentChecked(): void {
    this.cdf.detectChanges();
  }

  getHelpFilters() {
    let params = {
      language: this.selectedLanguage,
    };
    this.subscriptions.push(
      this.dataService
        .callv3API("homePage/getHomePageFilters", params)
        .subscribe((res) => {
          if (res.success) {
            this.moreContentForYou = res.data.slice(0, 8);
          }
        }
        ,error=>{
          this.IsLoader = false;
        })
    );
  }

  itemselected(catDetails:any) {
    this.router.navigate(["./feeds/home"], {
      queryParams: {
        category: catDetails.cat_id,
        subcategory: catDetails.subcat_id,
      },
    });
  }

  ifAudioInSuggestion(data:any) {
    return this.checkString.isAudioURL(data);
  }

  setMetaTags(request:any) {
    //  this.meta.addTag({name: 'twitter:card', content: 'summary'});
    //  this.meta.addTag({name: 'twitter:site', content: '@AngularUniv'});
    //  this.meta.addTag({name: 'twitter:title', content: this.course.description});
    //  this.meta.addTag({name: 'twitter:description', content: this.course.description});
    //  this.meta.addTag({name: 'twitter:text:description', content: this.course.description});
    //  this.meta.addTag({name: 'twitter:image', content: 'https://avatars3.githubusercontent.com/u/16628445?v=3&s=200'});

    if (
      request.help_title != "NaN" &&
      request.help_title &&
      request.help_title.length > 0
    ) {
      this.seoService.updateMetaTag({
        property: "og:title",
        content: request.help_title,
      });
    } else {
      let title_custom = "Help request for " + this.requestTitle;
      this.seoService.updateMetaTag({
        property: "og:title",
        content: title_custom,
      });
    }

    if (
      request.help_description != "NaN" &&
      request.help_description != null &&
      request.help_description != undefined &&
      request.help_description != ""
    ) {
      this.seoService.updateMetaTag({
        property: "description",
        content: request.help_description,
      });
      this.seoService.updateMetaTag({
        property: "og:description",
        content: request.help_description,
      });
    }

    if (request.help_pictures != null && request.help_pictures.length > 0) {
      this.seoService.updateMetaTag({
        name: "og:image",
        content: request.help_pictures[0],
      });
    }
  }

  navigateToProfile(username_public_profile:any) {
    this.loginService.openModal("", (status: string) => {
      if (status) {
        if (this.username == username_public_profile) {
          localStorage.setItem("profile_type", "pvt");
          this.router.navigate(["user-profile"], {
            queryParams: { profile_type: "pvt" },
          });
          this.googleAnalyticsService.eventEmitter(
            "web_navigate_to_private-profile",
            "",
            "User navigate to private-profile"
          );
        }
        // else {
        //   this.moeService.eventEmitter("web_Login/Signup_Left", {
        //     platform: "web",
        //   });
        //   localStorage.setItem("profile_type", "pub");
        //   localStorage.setItem("public_username", username_public_profile);
        //   this.router.navigate(["user-profile"], {
        //     queryParams: { profile_type: "pub" },
        //   });
        //   this.googleAnalyticsService.eventEmitter(
        //     "web_navigate_to_public-profile",
        //     "",
        //     "User navigate to public-profile"
        //   );
        // }
      }
    });
  }
  share(platform:any, request:any) {
    this.url = window.location.href;
    switch (platform) {
      case "facebook":
        this.facebookShare(request);
        break;
      case "twitter":
        this.twitterShare(request);
        break;
      case "whatsapp":
        this.whatsappShare(request);
        this.googleAnalyticsService.eventEmitter('whatsapp_share_cev',"","share whats app");
        (window as any).hj('event', 'whatsapp_share_cev');
        break;
    }
  }

  facebookShare(request:any) {
    // Dynamically gather and set the FB share data.
    let FBDesc = request.help_description;
    let FBTitle = request.help_title;
    let FBLink =
      window.location.origin +
      "/#/help-request/getHelp-RequestbyId?Id=" +
      request.helpid;
    FBLink = encodeURI(FBLink);
    FB.ui(
      {
        method: "share_open_graph",
        action_type: "og.shares",
        action_properties: JSON.stringify({
          object: {
            "og:url": FBLink,
            "og:title": FBTitle,
            "og:description": FBDesc,
          },
        }),
      },
      function (response:any) {
        if (response != undefined && response.length == 0) {
        }
      }
    );
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_share_fb",
      "",
      "User shares help-request on fb"
    );
  }

  twitterShare(request:any) {
    let requestURL =
      window.location.origin +
      "/#/help-request/getHelp-RequestbyId?Id=" +
      request.helpid;
    let text =
      (request.help_title && request.help_title != "NaN"
        ? request.help_title + " \n"
        : "") +
      request.help_description +
      " \n" +
      (request.help_video &&
      request.help_video != "NaN" &&
      request.help_video != "null"
        ? "\n" + request.help_video
        : "") +
      "\n" +
      ("Please click for more Info -" + requestURL + "\n");
    // Opens a pop-up with twitter sharing dialog
    var shareURL = this.twitterShareLink; //url base
    //params
    var params:any = {
      url: this.url,
      text: text,
      via: "jaano",
      //hashtags: ""
    };
    for (var prop in params)
      shareURL += "&" + prop + "=" + encodeURIComponent(params[prop]);
    window.open(
      shareURL,
      "",
      "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    );
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_share_twitter",
      "",
      "User shares help-request on twitter"
    );
  }

  whatsappShare(request:any) {
    let requestURL =
      window.location.origin +
      "/help-request/getHelp-RequestbyId?Id=" +
      request.helpid;
    let text =
      (request.help_title && request.help_title != "NaN"
        ? request.help_title + " \n"
        : "") +
      request.help_description +
      " \n" +
      (request.help_video &&
      request.help_video != "NaN" &&
      request.help_video != "null"
        ? "\n" + request.help_video
        : "") +
      "\n" +
      ("Please click for more Info -" + requestURL + "\n");
    if (this.mobileScreen) {
      window.open("whatsapp://send?text=" + encodeURIComponent(text));
    } else {
      let url = this.whatsappShareLink + encodeURIComponent(text);
      window.open(url, "_blank");
    }
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_share_whatsApp",
      "",
      "User shares help-request on whatsApp"
    );
  }

  reportPost(helpid:any) {
    this.loginService.openModal(
      { quote: "offer_help_report_1", text: "offer_help_report_2" },
      (status: string) => {
        if (status) {
          this.dialog.open(ReportPostDialogComponent, {
            data: {
              helpid: helpid,
              type: "help",
            },
            disableClose: true,
            width:'320px'
          });
        } else {
          this.moeService.eventEmitter("web_Login/Signup_Left", {
            platform: "web",
          });
        }
      }
    );
  }
  getRequestDetailbyId(helprequestId:any, commentToShow:any) {
    this.requestDetails = {
      username: this.username,
      helpRequestId: helprequestId,
      commentsToShow: commentToShow,
      repliesToShow: 5,
      lat: this.lat,
      lng: this.lng,
      metaData: { requestID: this.helpRequestId },
    };
    if(!this.requestDetails.helpRequestId ||
      !this.requestDetails.commentsToShow || !this.requestDetails.repliesToShow){
        this.IsLoader = false;
        return;
      }

      if(this.selectedLanguage && !this.isDataLoaded){
        this.isDataLoaded =true;
        this.IsLoader = true;
        this.dataService
        .callv3API("expand/getHelpRequestDetailsByID", this.requestDetails)
        .subscribe((res) => {
          if (res) {
            if(res.data){
              // setting subcategory
              this.getCategoryArrayByCategoryId(res.data.category,res.data.sub_category);
              if(res.data.hr_user_status == 'cancel'){
                this.floatingState = false;
              }
              // if user asked for support or not..
              if(res.data.ask_for_support){
                this.askedForSupport = true;
              }
              if(res.review){
                this.processUserReview(res.review);
              }
              this.processData(res.data);
              if(this.isPaidHelp && !this.reviewLoaded){
                this.getCustomerReviews();
              }
            }
            if(this.helpRequestUserName == this.username){
              if(res.data&& res.payment_initiate_emitra){
                this.paymentDetails(res.data, res.payment_initiate_emitra);
              }
              if(res.bids){
                this.processBids(res.bids);
              }
              if(this.hasBids){
                this.showListing = false;
                // this.end = 0;
              }
              else{
                this.showListing = true;
                // this.end = 4;
              }
              if(res.listings){
                this.processListings(res.listings);
              }
              if(res.assigned_emitra&& res.data){
                this.processAssignedEmitra(res.assigned_emitra, res.data);
              }

              // show or not the footer menu
              if((this.isPaidHelp || (this.isConvertedToFree && this.request && this.request.comments && this.request.comments.length>0 &&
                 this.request.comments[0].is_admin)) && (!this.isAssignedEmitra) || this.paymentSuccess){
                this.commonService.dontShowFooter.next(true);
              }
            }
            if(res.data && res.data.is_paid_help){
              let assignedEmitra:any = this.assignedEmitraId !=""?this.assignedEmitraName:null;
              let amountPaid:any= this.paymentAmount;
              if(this.bidList && this.bidList.length>0){
                assignedEmitra= !assignedEmitra?this.bidList[0].name:assignedEmitra;
                amountPaid = !amountPaid ?this.bidList[0].price:amountPaid;
              }
              this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: {
                  isPaid: true,
                  paymentStatus:this.request.payment_status,
                  helpid:this.helpRequestId,
                  userStatus:this.request?.hr_user_status
                },
                queryParamsHandling: 'merge',
                // preserve the existing query params in the route
                skipLocationChange: true
                // do not trigger navigation
              });
            }
            if (res.data && res.data.req_status == 0) {
             this.requestSubscribe = this.commonService.reqStatusfetch.subscribe((resStatus: any) => {
               if (resStatus.length == 0) {
                 console.log("log console", res.data.req_status)
                 let reqfetchdata = res.data.req_status == 0 ? 1 : 2
                 console.log ("console log",reqfetchdata)
                 this.commonService.setReqstatus(reqfetchdata )
               }
              })
              // this.commonService.setReqstatus(res.data.req_status)
            } else {
               this.commonService.setReqstatus(2)
            }

            this.dataReady = true;
          }

          const username = this.commonService.getUsername();
          if(username){
            if(this.fbListener){
              this.fbListener.unsubscribe();
              this.hasBids = false;
            }
            if(this.reloadTimerOfferSubject){
              this.reloadTimerOfferSubject.unsubscribe();
              this.reloadTimerOfferSubject = null;
            }
            this.fbListener = this.dataService.getLatestHrAndTimestamp(username)
            .snapshotChanges()
                  .pipe(
                  map(changes =>
                    changes.map((c:any) =>
                      ({ key: c.payload.key, ...c.payload.val() })
                    )
                  )
                  ).subscribe( response=>{
                    if(response && response.length>0){
                      let helpid = response[0].help_id;
                      let timestamp = response[0].timestamp;
                      this.latestHelpid = helpid;
                      if(helpid == res.data.helpid){
                        // latest helpid is the same where we are exactly..
                        // in such check available offer for that helpid and show timestamp for that helpid.
                        if(!this.reloadTimerOfferSubject){
                          this.reloadTimerForOffer(timestamp);
                        }
                        this.checkAvailableOffers(res.data.helpid);
                      }
                      else{
                        // if both are different which means they are at different page.
                        if(res.data.username == this.commonService.getUsername()){
                          this.checkAvailableOffers(res.data.helpid);
                          if(!this.reloadTimerOfferSubject){
                            this.reloadTimerForOffer(timestamp);
                          }
                        }
                        else{
                          this.checkAvailableOffers(res.data.helpid);
                          if(!this.reloadTimerOfferSubject){
                            this.reloadTimerForOffer(timestamp);
                          }
                        }
                      }
                    }
                    else{
                      this.latestHelpid = localStorage.getItem('create_paid_help_id')?localStorage.getItem('create_paid_help_id'):"";
                      if(!this.checkAvailableOffers(res.data.helpid)){
                        this.reloadTimerType = 'thankyouExtendedCard';
                      }
                    }
                  });
          }
          this.IsLoader = false;
        }
        ,error=>{
          this.IsLoader = false;
        })    
      }
      else{
        this.IsLoader = false;
      }
  }

  fbListener:any;

  goToFeedPageWithCatSubCat(cat:any, subcat:any) {
    this.router.navigate(["./home"], {
      queryParams: {
        pageNo: this.queryParams.pageNo,
        category: cat,
        subcategory: subcat ? subcat : undefined,
      },
    });
    this.googleAnalyticsService.eventEmitter('sc_hr_card_cev',"","view sub category");
    (window as any).hj('event', 'sc_hr_card_cev');
  }

  gotoPrivousPage() {
    let source = this.queryParams.source;
    this.previousUrl = this.previousRouteService.getPreviousUrl() || "";
    if (
      this.previousUrl.indexOf("home?") > -1 ||
      (typeof source != "undefined" && source == "home") ||
      this.request.req_status == "0"
    ) {
      this.previousUrl = "/home";
    } else if (
      this.previousUrl.indexOf("helprequest?") > -1 ||
      (typeof source != "undefined" && source == "help") ||
      this.request.req_status == "1"
    ) {
      this.previousUrl = "/feeds/help";
    }
    if (
      this.previousUrl &&
      this.previousUrl != "/" &&
      this.previousUrl.indexOf("?") > -1 &&
      this.previousUrl.indexOf("/view-all-comments") == -1
    ) {
      let search = this.previousUrl.substr(
        this.previousUrl.indexOf("?") + 1,
        this.previousUrl.length
      );
      let newUrl = this.previousUrl.substr(0, this.previousUrl.indexOf("?"));
      let paramsJSON = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      if (paramsJSON.pageNo && this.queryParams.pageNo) {
        paramsJSON.pageNo = this.queryParams.pageNo;
        this.router.navigate([newUrl], { queryParams: paramsJSON });
      } else {
        this.router.navigate([newUrl], { queryParams: paramsJSON });
      }
    } else if (this.previousUrl == "/home") {
      this.router.navigate(["./home"], {
        queryParams: { pageNo: this.queryParams.pageNo },
      });
    } else {
      this.router.navigate(["./feeds/help"], {
        queryParams: { pageNo: this.queryParams.pageNo },
      });
    }
    // if (this.previousUrl == '/user-profile?profile_type=pvt') {
    //   this.router.navigate(['user-profile'], { queryParams: { profile_type: 'pvt' } });
    // } else if (this.previousUrl == '/notifications') {
    //   this.router.navigate(['./notifications']);
    // } else {
    //   this.router.navigate(['./feeds/help']);
    // }
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_feed_page",
      "",
      "User navigate back to help-request main page"
    );
  }

  remove_audio() {
    this.selectHelpVoice = null;
    this.voiceOption = false;
    this.voiceOptionSelected = false;
    this.audioBlobURL = null;
    this.tempAudioBlob = null;
    this.tempSelectedAudio = null;
  }

  customerReviews : {userProfilePicture: string, userid: string, location:string, rating:number,
                    timestamp:string, comment:string, subCat:string, solvedById:string,
                    solvedByProfilePicture:string ,expand:boolean}[] =[];
  getCustomerReviews(){
    const req= {
      subctg: this.request.sub_category
    }
    this.subscriptions.push(this.dataService.callv3API("expand/getHRReviews",req).subscribe(res => {
      if(res && res.success && res.rows){
        this.customerReviews = [];
        let response = res.rows;
        // filtering out timestamp -1 and timestamp in microseconds..
        response = response.filter( (data:any)=> data.timestamp>-1 && data.timestamp< 10000000000)
        response.forEach((data:any) => {
          let timestamp = this.commonService.timeStampToTime(data.timestamp);
          this.customerReviews.push({userProfilePicture:data.profile_picture, userid: data.userid,
             location: data.stname, rating: data.rating, timestamp: timestamp,
             comment: data.msg , subCat: this.subcategory,
          solvedById: data.emitra_username, solvedByProfilePicture:data.emitra_profile_picture
           , expand: false});
        })
        this.reviewLoaded = true;
      }}
    ,error=>{
      this.IsLoader = false;
    }))
  }

  processUserReview(review:any){
    if(review && review.rating){
      const hasMessage:boolean = review.msg && review.msg.length>0?true:false;
      this.userReview={
        hasReview: true,
        message: review.msg,
        rating: review.rating,
        hasMessage: hasMessage
      }
    }
  }

  processData(result:any) {
    this.mediaArray = [];
    this.fromReferral = result.referred_by?true:false;
    this.isConvertedToFree = result.is_converted_to_free?true:false;
    if(result && result.is_paid_help && !result.is_converted_to_free){
      this.isPaidHelp = result.is_paid_help;
      localStorage.setItem('helpType','paid');
    }
    else{
      this.isPaidHelp = false;
      localStorage.setItem('helpType','unpaid');
      if(result.req_status == '0'){
        localStorage.setItem('helpStatus','close');
      }
      else{
        localStorage.setItem('helpStatus','open');
      }
    }
    if(!this.isPaidHelp && this.sliderSubscription){
      this.sliderSubscription.unsubscribe();
    }
    // if(!this.isPaidHelp && this.reloadTimerOfferSubject){
    //   this.reloadTimerOfferSubject.unsubscribe();
    // }
    if(result.is_paid_help)
    this.helpRequestUserName = result.username;
    if(!this.isPaidHelp || this.username == null || this.helpRequestUserName != this.username){
      this.floatingState = false;
    }
    if (result.help_pictures == null) {
      result.help_pictures = [];
    }
    if (result.help_video != "" && result.help_video != null) {
      let linkJson = {
        type: "video",
        link: this.sanitizer.bypassSecurityTrustResourceUrl(result.help_video),
      };
      this.mediaArray.push(linkJson);
    }
    if (result.help_voice_link != "" && result.help_voice_link != null) {
      let linkJson = {
        type: "audio",
        link: result.help_voice_link,
      };
      this.mediaArray.push(linkJson);
    }
    if (result.help_pictures.length > 0) {
      result.help_pictures.forEach((element:any) => {
        if (element != null) {
          let linkJson = {
            type: "picture",
            link: element,
          };
          this.mediaArray.push(linkJson);
        }
      });
    }
    this.cdf.detectChanges();
    result.help_video = result.help_video == "" ? null : result.help_video;
    result.help_pictures =
      result.help_pictures && result.help_pictures.length ==0 ? null : result.help_pictures;
    result.help_voice_link =
      result.help_voice_link == "" ? null : result.help_voice_link;

    this.request = [];
    this.cdf.detectChanges();
    result.comments = result.comments ? result.comments : [];
    this.request = result;
    // adding category, sub category, title and description of current hr
    localStorage.setItem("hr_title",this.request.help_title)
    localStorage.setItem("hr_description",this.request.help_description)
    localStorage.setItem("hr_cat",this.request.category)
    localStorage.setItem("hr_sub_cat",this.request.sub_category)
    this.cdf.detectChanges();

    // adding title in local storage..
    this.languageService
    .getTranslationOf('need_help_with')
    .subscribe((value) => {
      if (value) {
        value = value.replace('$$',this.subcategory);
        localStorage.setItem("hr_title",value);
      }
    });



    if (result.help_description != null && result.help_description !== "null") {
      var urlRegex =
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
      let matches = result.help_description.match(urlRegex);
      if (matches && matches.length > 0) {
        let checkForDespVideo = matches[0];
        console.log("checkfordespvideo",checkForDespVideo)
        if (checkForDespVideo.indexOf("https://youtu.be/") !== -1) {
          this.desp_video = checkForDespVideo.replace(
            "https://youtu.be/",
            "https://youtube.com/embed/"
          );
        } else if (
          checkForDespVideo.indexOf("https://www.youtube.com/watch?v=") !== -1
        ) {
          checkForDespVideo = checkForDespVideo.replace(
            "&feature=youtu.be",
            ""
          );
          this.desp_video = checkForDespVideo.replace(
            "https://www.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          );
        } else if (!checkForDespVideo.startsWith("http")) {
          this.desp_video = "https://".concat(checkForDespVideo);
        }else if (
          checkForDespVideo.indexOf("https://m.youtube.com/watch?v=") !== -1
        ) {
          checkForDespVideo = checkForDespVideo.replace(
            "&feature=youtu.be",
            ""
          );
          this.desp_video = checkForDespVideo.replace(
            "https://m.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          );
        }
        console.log("desp_video",this.desp_video);
        this.desp_video = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.desp_video
        );
      }
    }

    let catgoryDetails:any = this.categoryList.filter(
      (x:any) => x.cat_id == result.category
    );
    this.passCatID = catgoryDetails[0].cat_id;
    this.passSubCatID = catgoryDetails[0].subcat_id;
    this.requestTitle =
      catgoryDetails && catgoryDetails.length > 0
        ? catgoryDetails[0].CatlangKey
        : "";
    result.help_description = this.urlify(result.help_description);
    result.help_title = this.urlify(result.help_title);
    // this.requestDescription = result.help_title == "{}" || result.help_description == "" ? "Help Request for" + catgoryDetails[0].category_name : result.help_description;
    // this.requestTitle = result.help_title == "{}" || result.help_title == "" ? "Help Request for" + catgoryDetails[0].category_name : result.help_title;
    this.setMetaTags(this.request);
    if (result.comments == null) {
      result.comments = [];
    }
    if (result.comments.length > 0) {
      result.comments.forEach((element:any) => {
        element["IsCommentReply"] = false;
        this.findUrlAndgetPreview(element);
        if (!this.ifAudioInSuggestion(element.comment))
          element.comment = this.urlify(element.comment);
        if (element.replies != null) {
          if (element.replies.length > 0) {
            element.replies.forEach((element1:any) => {
              this.findUrlAndgetPreview(element1);
              element1.reply = this.urlify(element1.reply);
              if (element1.isThankyouReply) {
                this.IsMarkedSolution = true;
              }
            });
          }
        }
      });
    }
    if (result.sub_category != null) {
      this.selectedSubCategory = result.sub_category;
    } else {
      this.selectedSubCategory = "10";
    }
    // this.setHeight();
  }


  processBids(bids:any){
    if(bids && bids.length>0){
      // number of help request solved if not present then assigned random request count.
      bids.forEach((item:any)=>{
        item.num_hr_solved = item.num_hr_solved && item.num_hr_solved != -1? 
        item.num_hr_solved: this.randomRequests();
      })
      this.slidingInterval();
      // get details of payment exit form
      let paymentExitForm:any = localStorage.getItem('paymentExitForm');
      paymentExitForm?paymentExitForm = JSON.parse(paymentExitForm):paymentExitForm = null;
      if(paymentExitForm && paymentExitForm.helpid == this.helpRequestId){
        this.floatingState = true;
          const data = {username:this.username,helpid: this.helpRequestId,amount:paymentExitForm.bid.price};
          this.paymentFailedPopup(data,paymentExitForm.bid);
      }
      (window as any).hj('event', 'offers_available');
      this.googleAnalyticsService.eventEmitter(
        "offers_available",
        "",
        "Offer available for the paid help request"
      );
      this.hasBids = true;
      if(this.reloadTimerOfferSubject){
        this.reloadTimerOfferSubject.unsubscribe();
      }
      this.thankyouCardTimer = 0;
      this.bidList = [];
      // show bid list for four bids each 10 seconds, in auto offer case so that user doesn't feel like
      // getting offer.
      // if(bids && bids.length>0){
      //   bids[0].seeMore = false;
      //   this.bidList.push(bids[0]);
      // }
      // if(bids && bids.length>1){
      //   setTimeout(() => {
      //     bids[1].seeMore = false;
      //     this.bidList.push(bids[1]);
      //   }, 5000);
      // }
      // if(bids && bids.length>2){
      //   setTimeout(() => {
      //     bids[2].seeMore = false;
      //     this.bidList.push(bids[2]);
      //   }, 10000);
      // }
      // if(bids && bids.length>3){
      //   setTimeout(() => {
      //     for(let index_=3;index_<bids.length;index_++){
      //       bids[index_].seeMore = false;
      //       this.bidList.push(bids[index_]);
      //     }
      //   }, 15000);
      // }
      for(let index_=0;index_<bids.length;index_++){
        bids[index_].seeMore = false;
        this.bidList.push(bids[index_]);
      }
      if(this.showOrHideMessage){
        this.showOrHideMessage =null;
      }
      this.showOrHideMessage = interval(15000).pipe(
        map(i => i%2 === 0),
      );
      this.showOrHideMessage.subscribe((value:any)=>{
        this.message1 = value;
      });
    }
  }

  paymentDetails(data:any, payment_initiate_emitra:any){
    if(data.payment_initiate_emitra_id){
      this.assignedEmitraId = data.payment_initiate_emitra_id;
    }
    if(data.payment_status == "initiate" || data.payment_status == "pending"){
      this.paymentPending = true;
      this.paymentAmount  = data.payment_initiate_amt;
      this.assignedEmitraName = payment_initiate_emitra.name;
      this.assignedEmitraProfilePicture= payment_initiate_emitra.profile_picture;
      const initiateTimeStamp = data.payment_initiate_timestamp;
      const twentyFourHours = 1000*60*60*24;
      const totalTime = +initiateTimeStamp + +twentyFourHours;
      const currentTime = new Date().getTime();
      const remainingTime = totalTime - currentTime;
      // if it is less than equals to 24 hours.
      if(remainingTime>0){
        this.timeDifference = remainingTime;
        this.showPaymentProcessingTimer = true;
        this.startTimer24Hour();
      }
      else{
        this.paymentFailed = true;
        this.paymentPending = true;
      }
    }
    else if(data.payment_status == "success"){
      // check if initiatePayment available
      const initiatePayment = localStorage.getItem('initiatePayment');
      const currentHelpid= this.helpRequestId;
      if(initiatePayment && currentHelpid && currentHelpid == initiatePayment){
        //  payment initiated for the created help request and payment is also done.
        this.googleAnalyticsService.eventEmitter2('payment_completed_4',{});
        localStorage.removeItem('initiatePayment');
      }
      // hitting google event for payment completed
      const createdhelpid= localStorage.getItem('create_paid_help_id');
      const assignedEmitraForHelpid = localStorage.getItem('assigned_emitra_helpid');
      if(createdhelpid && createdhelpid == this.helpRequestId && assignedEmitraForHelpid != createdhelpid){
        localStorage.setItem('assigned_emitra_helpid',createdhelpid);
        this.googleAnalyticsService.eventEmitter2('payment_completed_3',{});
      }
      this.paymentAmount = data.payment_initiate_amt;
      this.assignedEmitraProfilePicture= payment_initiate_emitra.profile_picture;
      this.paymentSuccess = true;
      this.paymentPending = false;
      this.paymentFailed = false;
      if(this.countDownTimer){
        this.countDownTimer.unsubscribe();
      }
      if(this.oneMinuteCountDownTimer){
        this.oneMinuteCountDownTimer.unsubscribe();
      }
    }
    else if(data.payment_status == "failed"){
      this.paymentAmount = data.payment_initiate_amt;
      this.assignedEmitraName = payment_initiate_emitra.name;
      this.assignedEmitraProfilePicture= payment_initiate_emitra.profile_picture;
      this.paymentFailed = true;
    }

  }

  acceptOfferPast5Minutes = false;
  acceptOffertimer="05:00";
  supportResolve= false;
  textMessage="";
  processAssignedEmitra(emitra:any,res:any){
    if(Object.keys(emitra).length != 0 && Object.keys(res).length != 0){
      this.isAssignedEmitra = true;
      localStorage.setItem('helpStatus','open');
      if(this.isAssignedEmitra){
        this.floatingState = false;

        // removing the paymentfailed survey if the current helpid is same as payment failed form survey helpid
        let paymentFailedForm:any= localStorage.getItem('paymentExitForm');
        paymentFailedForm?paymentFailedForm = JSON.parse(paymentFailedForm):paymentFailedForm = null;
        if(paymentFailedForm && paymentFailedForm.helpid == this.helpRequestId){
          localStorage.removeItem('paymentExitForm');
        }
      }
      this.assignedEmitraName= emitra.name;
      this.assignedEmitraProfilePicture= emitra.profile_picture;
      this.assignedEmitraPhoneNumber = emitra.phone_no;
      this.hrEmitraStatus = res.hr_emitra_status;
      this.hrUserStatus = res.hr_user_status;
      const subject = res.help_description;
      const title = res.help_title;
      const messageDate = new Date(+res.timestamp).toUTCString();
      //  check if support resolved or not
      this.supportResolve = res.support_resolve_at && (res.support_resolve_at != null && res.support_resolve_at != '-1')?true:false;
      // if emitra is assigned then for new a/b case in b: if help request is created within 5 minutes and not completed then show a timer.
      // if time is more than 5 minute and status is not completed then show the option to perform action for communication with emitra.
      
      this.textMessage = `Hi ${this.assignedEmitraName}, %0a
      This is ${title}, I created a request in Jaano to ${subject} on ${messageDate}.%0D%0A 
      What are the documents required?`;
      if(this.hrEmitraStatus != 'closed'
      && !this.supportResolve){
        // const hrCreationTime = this.request.timestamp;
        const hrCreationTime = res.payment_initiate_timestamp;
        const limitTime =  5*60000;
        const timerPlusLimit = new Date(+hrCreationTime + limitTime).getTime();
        const currentTime = new Date().getTime();
        // check for timer
        let remainingMinute = 5;
        let remainingSeconds = 0;
        console.log('timer',timerPlusLimit,currentTime,timerPlusLimit> currentTime)
        if(timerPlusLimit> currentTime ){
          remainingMinute = Math.floor((timerPlusLimit - currentTime)/60000);
          remainingSeconds =  Math.round((timerPlusLimit - currentTime)/1000) - 60*remainingMinute;
          console.log('remaining time',remainingMinute,remainingSeconds);
          let countDown = setInterval(()=>{
            if(remainingMinute == 0 && remainingSeconds == 0){
              clearInterval(countDown);
              this.acceptOfferPast5Minutes = true;
              return;
            }
            if(remainingSeconds == 0){
              remainingMinute = remainingMinute - 1;
              remainingSeconds = 59;
            }
            else{
              remainingSeconds = remainingSeconds - 1;
            }
            const minutes = '0'+remainingMinute
            const seconds = (remainingSeconds.toString())
                              .length==1?'0'+remainingSeconds:remainingSeconds;
            this.acceptOffertimer= minutes+':'+seconds;
          },1000)
        }
        else{
          this.acceptOfferPast5Minutes = true;
          this.acceptOffertimer= '00:00'
        } 
      }
    }
    else{
      localStorage.setItem('helpStatus','open');
    }
  }

  noResponseFromEmitra:{viaPhone:boolean,viaSms:boolean,viaWhatsapp:boolean,submit:boolean}={viaPhone:false,viaSms:false,viaWhatsapp:false,submit:false}
  onSubmitNoResponse=()=>{

  }
  processListings(listings:any){
    if(listings && listings.length>0){
      this.hasListing = true;
      this.listings = [] ;
      let priceArray:any = [];
      listings.forEach((listing:any) =>{
        listing.seeMore = false;
        this.listings.push(listing);
        priceArray.push(listing.price);
      })
      // sort by ratings.
      this.listings.sort((a,b)=>b.rating - a.rating);
      // sort by status
      this.listings.sort((a,b)=>b.status.localeCompare(a.status));
      this.maxPrice = Math.max(...priceArray);
      this.minPrice = Math.min(...priceArray);
      const helpCostString = 'help_cost_between';
      this.languageService
                    .getTranslationOf(helpCostString)
                    .subscribe((value) => {
                      if (value) {
                        const replaceAmountString = `₹ ${this.minPrice} - ₹ ${this.maxPrice}`;
                        let message= value;
                        message = message.replace('₹0 - ₹1',replaceAmountString);
                        this.helpCostString = message;
                      }
                    });
    }
  }

  start = 0;
  end = 4;
  viewAllOffer = false;
  viewMoreListings(){
    if(!this.showListing){
      this.showListing = true;
    }
    let listCount = this.listings?this.listings.length:0;
    if(listCount >=this.end){
      this.end = this.end + 4;
    }
  }

  onClickPayAgain(){
    let bid = {
      price: this.paymentAmount,
      emitra_id: this.assignedEmitraId,
      name: this.assignedEmitraName,
      picture: null
    }
    this.acceptOffer(bid);
  }

  checkoutPage=false;
  offerOnCheckout = false;
  bid:any;
  onChangeHelperHalder(event:any){
    this.offerOnCheckout = true;
    this.viewAllOffer = true;
  }
  onPaymentCompleteHandler(event:any){
    this.checkoutPage = false;
    window.location.reload();
  }

  onExitCheckoutHandler(event:any){
    this.checkoutPage = false;
    const paymentExitSurvey={
      helpid:this.bid.helpid,
      bid:this.bid
    }
    const strigyfyData= JSON.stringify(paymentExitSurvey);
    localStorage.setItem('paymentExitForm',strigyfyData);
    const data = {username:this.username,helpid: this.helpRequestId,amount:this.bid.price};
    this.paymentFailedPopup(data,this.bid);
  }
  acceptOffer(bid:any,redirect?:boolean){

    // new process on accept offer
    this.bid = bid;

    this.dataService.growthBookPayment.subscribe(response=>{
      if(!response){
        // initiate paid help transaction..
        this.IsLoader = true;
        const request = {
          txn_amt: bid.price,
          username : this.username,
          helpid :  this.helpRequestId,
          emitra_id : bid.emitra_id
        }
        this.googleAnalyticsService.eventEmitter2('offer_accepted',{});
        (window as any).hj('event', 'offer_accepted');
        this.dataService.growthBookPayment.subscribe(response=>{
          // old scenario
          if(response){
            this.subscriptions.push(this.dataService.callv3API("payments/initiatePaidHelpTransaction", request)
            .subscribe((res) => {
              if(res.success){
                // show card
                // showing price is the price for paytm/phonepee price while will be same 
                // but bid price is different.
                const dialogRef = this.dialog.open(PaynowDialogComponent, {
                  width: "364px",
                  disableClose: true,
                  panelClass: 'paynowDialog',
                  data: {
                    emitraName: bid.name,
                    price: bid.price,
                    showingPrice:bid.price,
                    tokenId: res.token,
                    orderId: res.orderId,
                    subCategory: this.subcategory,
                    picture: bid.profile_picture,
                    fromReferral:this.fromReferral,
                    redirect:redirect,
                    offer:bid,
                    phoneNo:localStorage.getItem('phone_no'),
                    helpid:this.helpRequestId,
                    emitraId:bid.emitra_id,
                    userName:localStorage.getItem('username')
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  console.log('after close',response);
                  if(result){
                    this.showListing = false;
                    this.hasBids = false;
                    this.hasListing = false;
                    this.dataService
                      .callv3API("expand/getHelpRequestDetailsByID", this.requestDetails)
                      .subscribe((res) => {
                        if (res) {
                          this.paymentDetails(res.data,res.payment_initiate_emitra);
                          this.processAssignedEmitra(res.assigned_emitra, res.data);
                          // show or not show footer menu
                          if((this.isPaidHelp || this.isConvertedToFree) && !this.isAssignedEmitra ){
                            this.commonService.dontShowFooter.next(true);
                          }
                          if(!this.paymentSuccess){
                            // this.googleAnalyticsService.eventEmitter2('ja_ptm_f',{});
                            if(this.countDownTimer){
                              this.countDownTimer.unsubscribe();
                            }
                            if(this.oneMinuteCountDownTimer){
                              this.oneMinuteCountDownTimer.unsubscribe();
                            }
                            this.showPaymentProcessingTimer = false;
                            this.startTimer1Minute();
                          }
                          else{
                            // google event on payment done.
                            this.googleAnalyticsService.eventEmitter2('ja_ptm_t',{});
                            localStorage.setItem("alloted_paid_help_id",this.helpRequestId);
                            this.removeHrAndTimestamp();
                          }
                        }
                      }
                      ,error=>{
                        this.IsLoader = false;
                      });
                  }
                  else{
                    const data = {username:this.username,helpid: this.helpRequestId,amount:bid.price};
                    this.paymentFailedPopup(data,bid);
                    this.IsLoader = false;
                  }
                }
                ,error=>{
                  this.IsLoader = false;
                });
                this.IsLoader = false;
              }
              this.IsLoader = false;
        
            }));
          }
          else{
            // alternative of payment using phonepay
            // !!!!!!important===> multiplying the bid price by 100 in case of phonepe, since it is accepting price in paisa.
            // showing price is the price for paytm/phonepee price while will be same but
            //  bid price is different.
            const dialogRef = this.dialog.open(PaynowDialogComponent, {
              width: "364px",
              disableClose: true,
              panelClass: 'paynowDialog',
              data: {
                emitraName: bid.name,
                price: bid.price*100,
                showingPrice:bid.price,
                tokenId: '',
                orderId: '',
                subCategory: this.subcategory,
                picture: bid.profile_picture,
                phoneNo:localStorage.getItem('phone_no'),
                helpid:this.helpRequestId,
                emitraId:bid.emitra_id,
                userName:localStorage.getItem('username'),
                redirect:redirect,
                offer:bid,
                fromReferral:this.fromReferral
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if(result){
                console.log('result after closing of payment popup',result);
              }
              this.IsLoader = false;
            }); 
          }
        })        
      }
      else{
        const checkoutObj = {
          amount:bid.price,
          service:this.request?.help_description,
          category:this.subcategory,
          emitraName:bid.name,
          emitraHrSolved:bid.num_hr_solved,
          emitraRating:bid.rating?bid.rating:0,
          emitraReviews:bid.rating_count?bid.rating_count:0,
          emitraPicture:bid.profile_picture,
          selectedPaymentMethod:PaymentMethod.Paytm,
          fromReferral:this.fromReferral,
          grandTotalAmount:bid.price,
          helpid:this.helpRequestId,
          phoneNo:this.phone_No,
          username:this.username,
          emitraId:bid.emitra_id,offerlist:this.bidList,offer:bid}
        this.dataService.checkoutSubject.next(checkoutObj);
        localStorage.setItem('checkout_obj',JSON.stringify(checkoutObj));
    
        
        // this.checkoutPage = true;
        // this.offerOnCheckout = false;
        this.router.navigate(["./help-request/checkout"]);
    
        console.log('inside accept offer')
        return;
      }
    })


  }

  scrolltoNeedHelp(){
    this.docEl = document.getElementById("needHelpBlock");
    if(this.docEl){
      this.docEl.scrollIntoView();
    }
    this.googleAnalyticsService.eventEmitter2('need_help',{});
    (window as any).hj('event', 'need_help');
  }


  requestHelp(listing:any){
    const request = {
      emitra_id: listing.emitra_id,
      helpid:this.helpRequestId,
      username:this.username,
    }
    this.dataService
        .callv3API("expand/setRequestReview", request)
        .subscribe((res) => {
          if(res){
          let index=
            this.listings.findIndex(list => list.emitra_id == listing.emitra_id);
            this.listings[index].status = "sent";
            const selectedList = this.listings.splice(index,1);
            this.listings.unshift(selectedList[0]);
          }
    }
    ,error=>{
      this.IsLoader = false;
    });
  }


  onSuggestFocus() {
    this.moeService.eventEmitter("Web_Help_Click_on_Suggestion_Box", {
      platform: "web",
    });
  }

  link_clicked(comment:any) {
    if (comment.username == "Jaano Helper") {
    }
  }

  urlify(text:any) {
    if (text != "" && text != null && text != undefined) {
      var urlRegex =
        /[http(s)?.:\/(www)?a-zA-Z0-9@:%_\+~#=]{2,256}[^(..)]\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
      var urlRegexWithoutWWW = /^(http|https):/gi;
      return text.replace(urlRegex, function (url:any) {
        if (url.search(urlRegexWithoutWWW) > -1) {
          return '<a class="font-16" target="_blank" href="' + url + '">' + url + "</a>";
        } else {
          return (
            '<a class="font-16" target="_blank" href="https://' + url + '">' + url + "</a>"
          );
        }
      });
    } else {
      return text;
    }
  }

  findUrlAndgetPreview(element:any) {
    let text = element.comment || element.reply;
    if (
      text != "" &&
      text != null &&
      text != undefined &&
      !this.ifAudioInSuggestion(text)
    ) {
      var urlRegex =
        /[http(s)?.:\/(www)?a-zA-Z0-9@:%_\+~#=]{2,256}[^(..)]\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
      // var urlRegex =/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
      let urls = text.match(urlRegex);
      element.urls = urls;
      if (urls && urls.length > 0) {
        let url = urls[0];
        this.dataService
          .getPreviewMetaData("getpreview", { url: url })
          .subscribe((res) => {
            element.snippetData = {};
            if (
              res &&
              res.success &&
              Object.keys(res.result || {}).length > 0
            ) {
              element.snippetData["link"] = url;
              element.snippetData["news_image"] = res.result.imageURL;
              element.snippetData["news_title"] = res.result.title;
              element.snippetData["news_description"] = res.result.description;
              element.snippetData["news_source"] = res.result.source;
            }
          });
      }
    }
  }

  openLink(link:any) {
    window.open(link);
  }

  navigateToHelpRequestById(requestId:any) {
    this.router.navigate(["./help-request/view-all-comments"], {
      queryParams: { Id: requestId ,
      suggestions: 'all'},
    });
    this.googleAnalyticsService.eventEmitter2('viewmore_sugg',{});
    (window as any).hj('event', 'viewmore_sugg');
  }
  markCommentAsThankyou(request:any, comment:any, i:any) {
    this.selectCommentIndex = i;
    this.loginService.openModal("", (status: string) => {
      if (status) {
        this.select_message_type = 0;
        this.firstpage = true;
        this.editpage = false;
        this.commentDeatils = {};
        this.commentDeatils = {
          username: comment.username,
          helpRequestId: request.helpid,
          commentId: comment.comment_id,
          thankYouMessage: this.thankYouMessage,
          ownerusername: request.username,
          lat: request.lat,
          lng: request.lng,
        };
      } else {
        this.moeService.eventEmitter("web_Login/Signup_Left", {
          platform: "web",
        });
      }
    });
  }

  reviewSlider=1;
  offerSlider=1;
  sliderInterval:any;
  slidingTimer=0;
  slidingInterval=()=>{
    this.sliderInterval = setInterval(()=>{
      this.slidingTimer = this.slidingTimer+1;
      if(this.slidingTimer%10 == 0){
        if(this.reviewSlider == 3){
          this.reviewSlider=1
        }
        else{
          this.reviewSlider = this.reviewSlider+1;
        }
        if(this.offerSlider == 2){
          this.offerSlider=1
        }
        else{
          this.offerSlider = this.offerSlider+1;
        }
      }

    },1000)
  }

  element:any = document.getElementById("drag1");
  ngAfterViewInit() {
    setTimeout(() => {
      this.element = document.getElementById("drag1");
      if(this.element){
        this.element.addEventListener("touchstart", (event:any)=>{
          this.clientYStart = event.changedTouches[0].clientY;
          this.element.style.color = "red";
        }, true);
        this.element.addEventListener("touchend", (event:any)=>{
          if(this.thankyouCardTimer <= 0 || this.hasBids){
            this.clientYEnd = event.changedTouches[0].clientY;
            if(this.clientYEnd > this.clientYStart){
              this.floatingState = false;
              this.showEmitra = false;
              this.showOffers = false;
              this.showOffers2 = false;
            }
            else{
              this.floatingState = true;
            }
            this.element.style.color = "red";
          }
        }, true);
      }
    }, 1000);
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      this.mobileScreen = true;
    } else {
      this.mobileScreen = false;
    }
    var snapshot:any = this.activatedRoute.snapshot;
    if (snapshot && snapshot.queryParams.upload) {
      if (snapshot.queryParams.upload == "help") {
        this.dialog.open(ImgTextButtonCustomDialogComponent, {
          data: Object.assign(
            {
              title: this.languageService.getTranslated(
                "Your help request has been posted successfully"
              ),
              action: this.languageService.getTranslated("View Help Request"),
              image: "hr_added.svg",
            },
            {
              click_action:
                "/help-request/getHelp-RequestbyId?Id=" +
                snapshot.queryParams.newHRID,
            }
          ),
          maxWidth: "100%",
          width: "100%",
          height: "100%",
        });
        // this.toastyService.success('', this.languageService.getTranslated('help_request_posted_text'), 6000);
      } else if (snapshot.queryParams.upload == "post") {
        this.toastyService.success(
          "",
          this.languageService.getTranslated("post_upload_text")
        );
      }
      const params = { ...snapshot.queryParams };
      delete params.upload;
      delete params.newHRID;
      this.router.navigate([], { queryParams: params });
    }
  }

  // ngAfterViewChecked() {
  //   window.scrollTo(0, 0);
  //   console.log("inside nafterviwe checked");
  // }

  postComment(request:any) {
    if (!this.disablePostButtton) {
      if (this.voiceOption) {
        this.saveCommentWithAudio();
      } else if (this.videoOption) {
        this.saveCommentWithVideo();
      } else if (this.imageOption) {
        this.saveCommentWithImages();
      } else this.saveComment(request);
    }
  }

  selectMessage(message:any, e:any) {
    this.thankYouMessage = message;
    this.select_message_type = e;
  }
  editMesge(message:any) {
    this.thankYouMessage = "";
    this.thankYouMessage = message;
    this.firstpage = false;
    this.editpage = true;
  }

  hasClickedEmoji = false;
  sentFeedback = false;
  markCommentHelpful(requestList:any, comment:any, i:any, reaction:any) {
    this.selectedComment = comment.comment_id;
    this.hasClickedEmoji  = true;
    if (typeof reaction == "boolean") reaction = Number(reaction);
    if (comment.reaction != reaction) {
      this.selectCommentIndex = i;
      this.loginService.openModal(
        { quote: "offer_help_like_1", text: "offer_help_like_2" },
        (status: string) => {
          if (status) {
            this.likeRequest = {
              helpRequestId: requestList.helpid,
              commentId: comment.comment_id,
              username: this.username,
              lat: requestList.lat,
              lng: requestList.lng,
              commentOwner: comment.username,
              userReaction: reaction,
              dtcode11: requestList.dtcode11,
              stcode11: requestList.stcode11,
              stname: requestList.stname,
              dtname: requestList.dtname,
              mandalcode: requestList.mandalcode,
              mandalname: requestList.mandalname,
              clustercode: requestList.clustercode,
              clustername: requestList.clustername,
              metaData: { requestID: this.helpRequestId },
              user_lat: this.lat,
              user_lng: this.lng,
            };
            if (reaction == 1) {
              let ele: HTMLImageElement = document.getElementById(
                "post_animator_2" + i
              ) as HTMLImageElement;
              ele.classList.add("animationClassOnSubmit3");
              ele.src = "";
              ele.src = "../../../../assets/images/jp1_animation.gif";
              setTimeout(() => {
                ele.classList.remove("animationClassOnSubmit3");
                ele.src = "";
              }, 600);
            }
            this.subscriptions.push(
              this.dataService
                .getDataByEntity(
                  "helpRequest/setReactionOnHelpRequestComment",
                  this.likeRequest
                )
                .subscribe((res) => {
                  if (res.success) {
                    if (res.reactionApplied == 1) {
                      this.request.comments[this.selectCommentIndex].tot_likes =
                        this.request.comments[this.selectCommentIndex]
                          .tot_likes + 1;
                      this.commonService.incrementJaanoPoints(1);
                      if (comment.reaction == 2) {
                        this.request.comments[
                          this.selectCommentIndex
                        ].tot_dislike =
                          this.request.comments[this.selectCommentIndex]
                            .tot_dislike - 1;
                      }
                    } else if (res.reactionApplied == 0) {
                      this.request.comments[this.selectCommentIndex].tot_likes =
                        this.request.comments[this.selectCommentIndex]
                          .tot_likes - 1;
                      this.commonService.decrementJaanoPoints(1);
                    } else if (res.reactionApplied == 2) {
                      this.request.comments[
                        this.selectCommentIndex
                      ].tot_dislike =
                        this.request.comments[this.selectCommentIndex]
                          .tot_dislike + 1;
                      if (
                        this.request.comments[this.selectCommentIndex]
                          .reaction == 1
                      ) {
                        this.request.comments[
                          this.selectCommentIndex
                        ].tot_likes =
                          this.request.comments[this.selectCommentIndex]
                            .tot_likes - 1;
                      }
                    }
                    this.request.comments[this.selectCommentIndex].reaction =
                      res.reactionApplied;
                    //this.getRequestDetailbyId(this.helpRequestId, 5);
                  }
                })
            );
            this.googleAnalyticsService.eventEmitter(
              "web_liked_reply_help-request",
              "",
              "User liked reply on help-request"
            );
          } else {
            this.moeService.eventEmitter("web_Login/Signup_Left", {
              platform: "web",
            });
          }
        }
      );
    }
  }
  submitThankYou() {
    if (this.thankYouMessage.length == 0 || !this.thankYouMessage) {
      this.toastyService.failure(
        "",
        this.languageService.getTranslated(
          "Please select Atleast one Thankyou message from given options."
        )
      );
      this.IsLoader = false;
      return;
    }
    let ele: HTMLImageElement = document.getElementById(
      "post_animator_2"
    ) as HTMLImageElement;
    ele.classList.add("animationClassOnSubmit");
    ele.src = "";
    ele.src = "../../../../assets/images/jp10_animation.gif";
    setTimeout(() => {
      ele.classList.remove("animationClassOnSubmit");
      ele.src = "";
    }, 600);
    this.IsLoader = true;

    this.commentDeatils.thankYouMessage = this.thankYouMessage;
    this.commentDeatils.metaData = { requestID: this.helpRequestId };
    this.subscriptions.push(
      this.dataService
        .getDataByEntity(
          "helpRequest/markCommentAsThankYou",
          this.commentDeatils
        )
        .subscribe((res) => {
          this.myComment = "";
          this.IsLoader = false;
          if (res.data.isStatusUpdated) {
            this.commonService.incrementJaanoPoints(10);
            this.request.comments[this.selectCommentIndex] = Object.assign(
              this.request.comments[this.selectCommentIndex],
              res.data.updatedComment
            );
            this.moeService.eventEmitter("web_marked_as_Thank_you_help", {
              platform: "web",
            });
            this.googleAnalyticsService.eventEmitter(
              "web_marked_as_Thank_you_help-request",
              "",
              "User marked comment as Thank-you help-request"
            );
          }
          //this.getRequestDetailbyId(this.helpRequestId, 5);
        })
    );
  }

  findContentType() {
    let type = 0;
    // video - 4 ,audio - 3,picture - 2,text - 1, video_txt - 5, audio_txt - 6, picture_txt - 7
    if (this.myComment && this.myComment.length > 0) type = 1;
    if (this.request.pictures) {
      this.myComment && this.myComment.length > 0 ? (type = 7) : (type = 2);
    }
    if (this.request.video) {
      this.myComment && this.myComment.length > 0 ? (type = 5) : (type = 4);
    }
    if (this.request.audio) {
      this.myComment && this.myComment.length > 0 ? (type = 6) : (type = 3);
    }
    return type;
  }

  saveComment(request:any) {
    this.disablePostButtton = true;
    this.loginService.openModal(
      { quote: "offer_help_suggestion_1", text: "offer_help_suggestion_2" },
      (status: string) => {
        if (status && !this.IsLoader) {
          if (this.username != null || this.username != "") {
            if (
              (!this.myComment || this.myComment.length == 0) &&
              !(
                this.videoOptionSelected ||
                this.imageOptionSelected ||
                this.voiceOptionSelected
              )
            ) {
              if (this.myComment.trim() == "") {
                this.languageService
                  .getTranslationOf("Please write something in comment Box.")
                  .subscribe((value) => {
                    if (value) {
                      this.IsLoader = false;
                      this.disablePostButtton = false;
                      this.myComment = "";
                      this.toastyService.failure("", value);
                      return;
                    }
                  });
              }
            } else {
              this.IsLoader = true;
              this.requestDetails = {
                username: this.username,
                comment: this.myComment,
                helpRequestId: request.helpid,
                requestOwner: request.username,
                lat: request.lat,
                lng: request.lng,
                stcode11: request.stcode11,
                dtcode11: request.dtcode11,
                stname: request.stname,
                dtname: request.dtname,
                metaData: { requestID: this.helpRequestId },
                comment_type: this.findContentType(),
                // video - 4 ,audio - 3,picture - 2,text - 1, video_txt - 5, audio_txt - 6, picture_txt - 7
                pictures: request.pictures,
                audio: request.audio,
                video: request.video,
                user_lat: this.lat,
                user_lng: this.lng,
              };
              this.subscriptions.push(
                this.dataService
                  .getDataByEntity(
                    "helpRequest/addNewCommentToHelpRequest/v2",
                    this.requestDetails
                  )
                  .subscribe((res) => {
                    this.myComment = "";
                    this.IsLoader = false;
                    this.disablePostButtton = false;
                    if (res.success) {
                      if (this.voiceOption) {
                        this.request.audio = undefined;
                        this.clearRecordedData();
                        this.toastyService.success(
                          "",
                          "Your audio comment was added"
                        );
                      }
                      if (this.videoOptionSelected) {
                        this.request.video = undefined;
                        this.removeVideoSelect();
                        this.toastyService.success(
                          "",
                          "Your video comment was added"
                        );
                      }
                      if (this.imageOptionSelected) {
                        this.request.pictures = undefined;
                        this.remove_all_pictures();
                        this.toastyService.success(
                          "",
                          "Your images/s comment was added"
                        );
                      }
                      this.moeService.eventEmitter(
                        "added_suggestion_on_help_req",
                        { platform: "web" }
                      );
                      let ele: HTMLImageElement = document.getElementById(
                        "post_animator"
                      ) as HTMLImageElement;
                      ele.classList.add("animationClassOnSubmit");
                      ele.src = "";
                      ele.src = "../../../../assets/images/jp10_animation.gif";
                      setTimeout(() => {
                        ele.classList.remove("animationClassOnSubmit");
                        ele.src = "";
                      }, 600);
                      this.commonService.incrementJaanoPoints(10);
                      if (this.loginService.showGratificationPopupStatus()) {
                        this.dialog.open(Gratification, {
                          width: "30rem",
                          panelClass: "dialogWithNoPadding",
                          data: {
                            jp_points: 10,
                            text_jp: "for_adding_suggestion_help",
                          },
                        });
                      }
                      if (!this.ifAudioInSuggestion(res.result.comment))
                        res.result.comment = this.urlify(res.result.comment);
                      else res.result.comment = res.result.comment;
                      res.result.approval_status = true; //DO not show "Disapproved" as the post may be in review process
                      this.request.comments = [res.result].concat(
                        this.request.comments
                      );
                      this.googleAnalyticsService.eventEmitter(
                        "web_comment_added_on_help-request",
                        "",
                        "User added comment on help-request"
                      );
                    } else {
                      if (res.msg) this.toastyService.failure("", res.msg);
                    }
                    // this.getRequestDetailbyId(this.helpRequestId, 5);
                  })
              );
            }
          }
        } else {
          this.moeService.eventEmitter("web_Login/Signup_Left", {
            platform: "web",
          });
        }
      }
    );
  }

  addReplyOnHelpRequest(request:any, comment:any, i:any, e:any) {
    this.request.comments.forEach((data:any)=>{
      data.IsCommentReply = false;
    })
    this.replyType = e;
    this.request.comments[i]['IsCommentReply'] = true;
    this.selectedComment = comment.comment_id;
    this.myReplyOnComment = '';
    this.selectCommentIndex = i;
    // this.selectedComment = comment.comment_id;
    // if (this.selectCommentIndex == i) {
    //   if (this.replyType == 0 || this.replyType == e) {
    //     this.request.comments[i]["IsCommentReply"] =
    //       !this.request.comments[i]["IsCommentReply"];
    //     this.selectedComment = comment.comment_id;
    //     this.selectCommentIndex = i;
    //     this.replyType = e;
    //   } else {
    //     this.selectedComment = comment.comment_id;
    //     this.selectCommentIndex = i;
    //     this.replyType = e;
    //   }
    // } else {
    //   this.replyType = e;
    //   if (this.selectCommentIndex >= 0) {
    //     this.request.comments[this.selectCommentIndex]["IsCommentReply"] =
    //       !this.request.comments[this.selectCommentIndex]["IsCommentReply"];
    //   }
    //   this.request.comments[i]["IsCommentReply"] =
    //     !this.request.comments[i]["IsCommentReply"];
    //   this.selectedComment = comment.comment_id;
    //   this.myReplyOnComment = "";
    //   this.selectCommentIndex = i;
    // }
  }
  savereply(request:any) {
    this.loginService.openModal(
      { quote: "offer_help_reply_1", text: "offer_help_reply_2" },
      (status: string) => {
        if (status) {
          this.IsLoader = true;
          if (this.username != null || this.username != "") {
            if (
              this.myReplyOnComment != null ||
              this.myReplyOnComment != undefined
            ) {
              if (this.myReplyOnComment.trim() == "") {
                this.languageService
                  .getTranslationOf("Please write something in Reply Box.")
                  .subscribe((value) => {
                    if (value) {
                      this.IsLoader = false;
                      this.myReplyOnComment = "";
                      return;
                    }
                  });
              }
            }
            let replyDetail = {
              username: this.username,
              commentId: this.selectedComment,
              reply: this.myReplyOnComment,
              lat: request.lat,
              lng: request.lng,
              user_lat: this.lat,
              user_lng: this.lng,
              metaData: { requestID: this.helpRequestId },
            };
            this.subscriptions.push(
              this.dataService
                .getDataByEntity(
                  "helpRequest/addReplyToHelpRequestComment",
                  replyDetail
                )
                .subscribe((res) => {
                  this.myReplyOnComment = "";
                  this.IsLoader = false;
                  this.replyType = 0;
                  this.request.comments[this.selectCommentIndex][
                    "IsCommentReply"
                  ] =
                    !this.request.comments[this.selectCommentIndex][
                      "IsCommentReply"
                    ];
                  if (res.success) {
                    res.data.reply = this.urlify(res.data.reply);
                    if (
                      this.request.comments[this.selectCommentIndex].replies !=
                      null
                    ) {
                      this.request.comments[this.selectCommentIndex].replies = [
                        res.data,
                      ].concat(
                        this.request.comments[this.selectCommentIndex].replies
                      );
                    } else {
                      this.request.comments[this.selectCommentIndex].replies =
                        res.data;
                    }
                    this.googleAnalyticsService.eventEmitter(
                      "web_reply_added_on_help-request",
                      "",
                      "User added reply on a help-request comment"
                    );
                  }
                })
            );
          }
        } else {
          this.moeService.eventEmitter("web_Login/Signup_Left", {
            platform: "web",
          });
        }
      }
    );
    this.hasClickedEmoji = false;
    this.sentFeedback = true;
  }

  markUserSupport(request:any) {
    if (!request.sentReqForSupport) {
      request.sentReqForSupport = true;
      this.loginService.openModal(
        { quote: "offer_help_support_1", text: "offer_help_support_2" },
        (status: string) => {
          if (status) {
            this.requestDetails = {
              username: this.username,
              helpRequestId: request.helpid,
              isSupport: request.is_support == 0 ? 1 : 0,
              lat: request.lat,
              lng: request.lng,
              stcode11: request.stcode11,
              dtcode11: request.dtcode11,
              stname: request.stname,
              dtname: request.dtname,
              metaData: { requestID: this.helpRequestId },
              clustername: request.clustername || "",
              clustercode: request.clustercode || -1,
              mandalname: request.mandalname || "",
              mandalcode: request.mandalcode || -1,
              user_lat: this.lat,
              user_lng: this.lng,
            };
            this.subscriptions.push(
              this.dataService
                .getDataByEntity(
                  "helpRequest/markUserSupportForHelpRequest",
                  this.requestDetails
                )
                .subscribe((res) => {
                  request.sentReqForSupport = false;
                  if (res.success == true) {
                    if (res.data.is_support == 0) {
                      this.request.tot_support = this.request.tot_support - 1;
                      this.moeService.eventEmitter("unsupported_help_req", {
                        platform: "web",
                      });
                    } else {
                      if (this.loginService.showGratificationPopupStatus()) {
                        this.dialog.open(Gratification, {
                          width: "30rem",
                          panelClass: "dialogWithNoPadding",
                          data: {
                            jp_points: 2,
                            text_jp: "for_supporting_help",
                          },
                        });
                      }
                      this.request.tot_support = this.request.tot_support + 1;
                      this.moeService.eventEmitter("supported_help_req", {
                        platform: "web",
                      });
                    }
                    this.request.is_support = res.data.is_support;
                    this.googleAnalyticsService.eventEmitter(
                      "web_mark_support_on_help-request",
                      "",
                      "User marks support to help-request."
                    );
                  }
                })
            );
          } else {
            this.moeService.eventEmitter("web_Login/Signup_Left", {
              platform: "web",
            });
          }
        }
      );
    }
  }
  getCategoryArrayByCategoryId(id:any, sub_category:any) {
    let subcategory: any;
    subcategory = this.categoryList.filter((x:any) => x.cat_id == id);
    if (subcategory && subcategory.length > 0) {
      let value = subcategory[0].subcat_id.indexOf(sub_category);
      this.subcategory = subcategory[0].SubCatlangKey[value];
      if (!this.subcategory) {
        this.subcategory = "";
      }
    } else {
      this.subcategory = "";
    }
    return subcategory;
  }
  markSaveUnSaveRequest(request:any, isSaved:any) {
    this.loginService.openModal(
      { quote: "offer_help_save_1", text: "offer_help_save_2" },
      (status: string) => {
        if (status) {
          this.requestDetails = {
            username: this.username,
            helpRequestId: request.helpid,
            isSave: !isSaved,
            lat: request.lat,
            lng: request.lng,
            stname: request.stname,
            dtname: request.dtname,
            stcode11: request.stcode11,
            dtcode11: request.dtcode11,
            metaData: { requestID: this.helpRequestId },
          };
          this.subscriptions.push(
            this.dataService
              .getDataByEntity(
                "helpRequest/saveUnSaveAHelpRequest",
                this.requestDetails
              )
              .subscribe((res) => {
                if (res.success == true) {
                  if (res.data.is_saved) {
                    this.toastyService.success("",
                    this.languageService.getTranslated(
                      "post_saved"
                    ));
                    this.request.is_saved = true;
                  } else {
                    this.toastyService.success("",
                    this.languageService.getTranslated(
                      "post_unsaved"
                    ));
                    this.request.is_saved = false;
                  }
                  this.googleAnalyticsService.eventEmitter(
                    "web_save_help-request",
                    "",
                    "User saves help-request."
                  );
                }
              })
          );
        } else {
          this.moeService.eventEmitter("web_Login/Signup_Left", {
            platform: "web",
          });
        }
      }
    );
  }

  progress = 0;
  timer = 0;
  interval:any;
  progressBar(style:any) {
    // dyanmic progress bar
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(style));
    this.el.nativeElement.appendChild(styleElement);
  }

  startAudioRecording() {
    if (!this.isRecording) {
      // creating progress bar
      this.timer = 0;
      this.interval = setInterval(() => {
        if(this.progress <= 100) {
          if(this.timer < 60){
            this.timer = this.timer+1;
          }
          this.progress = (this.timer/60)*100;
          let style:string  = `.dyanmicProgress{background: conic-gradient(#304FFE ${this.progress}%, #f2f2f4 ${this.progress}%)}`;
          this.progressBar(style);
        } else {
          clearInterval(this.interval);
        }
      },1000);
      this.isRecording = true;
      // recording using recordrtc

      let mediaConstraints = {
        video: false,
        audio: true
      };
      navigator.mediaDevices.getUserMedia(mediaConstraints).
      then(this.successCallback2.bind(this), this.errorCallback2.bind(this));
      // this.recorder
      //   .start()
      //   .then(() => {
      //     const getDownloadProgress = () => {
      //       if (this.audioRecorderProgress < 100) {
      //         this.audioRecorderTimer += 1;
      //         this.audioRecorderProgress =
      //           this.audioRecorderProgress + (1 / this.AUDIODURATION) * 100;
      //       } else {
      //         this.stopAudioRecording();
      //       }
      //     };
      //     this.intervalId = setInterval(getDownloadProgress, 1000);
      //   })
      //   .catch((e:any) => {
      //     this.isRecording = false;
      //   });
    }
  }
  audioRecord:any;
  successCallback2(stream:any) {
    let options:RecordRTC.Options = {
      type: 'audio',
      mimeType: "audio/wav",
      // numberOfAudioChannels: 1,
      // sampleRate: 16000,
      desiredSampRate: 16000,
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.audioRecord = new StereoAudioRecorder(stream, options);
    this.audioRecord.record();
    const getDownloadProgress = () => {
      if (this.audioRecorderProgress < 100) {
        this.audioRecorderTimer += 1;
        this.audioRecorderProgress =
          this.audioRecorderProgress + (1 / this.AUDIODURATION) * 100;
      } else {
        this.stopAudioRecording();
      }
    };
    this.intervalId = setInterval(getDownloadProgress, 1000);
  }
  errorCallback2() {
    this.isRecording = false;
  }

  processRecording(blob:any) {
    const url = URL.createObjectURL(blob);
    console.log("blob", blob);
    console.log("url", url);
    this.tempAudioBlob = this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(blob)
    );
    this.tempSelectedAudio = blob;
    this.handleAudioSelect(blob);
  }

  stopAudioRecording() {
    if (this.isRecording) {
      clearInterval(this.intervalId);
      clearInterval(this.interval);
      this.audioRecorderProgress = 0;
      this.audioRecorderTimer = 0;
      this.isRecording = false;
      this.audioRecord.stop(this.processRecording.bind(this));
      // this.recorder
      //   .stop()
      //   .getMp3()
      //   .then(([buffer, blob]:any) => {
      //     this.tempAudioBlob = this.sanitizer.bypassSecurityTrustUrl(
      //       URL.createObjectURL(blob)
      //     );
      //     this.tempSelectedAudio = blob;
      //     this.handleAudioSelect(blob);
      //   })
      //   .catch((e:any) => {
      //     this.isRecording = false;
      //   });
    }
  }

  handleAudioSelect(audioFile:any) {
    this.IsLoader = true;
    this.selectAudioFile = audioFile;
    var reader = new FileReader();
    if (audioFile != null) {
      this.isDisable = false;
      this.setFileInfo(audioFile);
    } else {
      //document.getElementById("errorInVideo").innerHTML = "Size should be less than 2MB";
      this.isDisable = true;
    }
    if (this.isDisable) {
      if (this.audioTimeIssue) {
        this.toastyService.failure(
          "",
          "Duration of Audio should be less than " +
            this.AUDIODURATION +
            " seconds."
        );
        this.IsLoader = false;
        return;
      } else {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "Not a valid Video, Please try again"
          )
        );
        this.IsLoader = false;
        return;
      }
    } else {
      this.IsLoader = false;
    }
  }

  setFileInfo(file:any) {
    var video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;
      file.duration = duration;
      if (file.duration < this.AUDIODURATION) {
        this.isDisable = false;
        this.audioTimeIssue = false;
      } else {
        this.audioTimeIssue = true;
        this.isDisable = true;
      }
    };
  }

  setTitleOfProgressBar() {
    return this.audioRecorderTimer + " Sec";
  }

  clearRecordedData() {
    this.voiceOption = false;
    this.voiceOptionSelected = false;
    this.audioBlobURL = null;
    this.tempAudioBlob = null;
    this.tempSelectedAudio = null;
  }

  saveAudio() {
    this.closeAudioModal.nativeElement.click();
    this.audioBlobURL = this.tempAudioBlob;
    this.selectHelpVoice = this.tempSelectedAudio;
    this.voiceOption = true;
    this.voiceOptionSelected = true;
  }

  saveCommentWithAudio() {
    this.disablePostButtton = true;
    if (this.disablePostButtton) {
      this.uploadService.uploadAudio(this.selectHelpVoice).then((value:any) => {
        if (value != null || value != "") {
          this.request.audio = value;
          this.saveComment(this.request);
        } else {
          this.toastyService.failure("", "Error occurred");
        }
      });
    }
  }

  saveCommentWithVideo() {
    this.disablePostButtton = true;
    if (this.disablePostButtton) {
      this.uploadService.uploadVideo(this.videosThumbnile).then((value:any) => {
        if (value != null || value != "") {
          this.request.video = value;
          this.saveComment(this.request);
        } else {
          this.toastyService.failure("", "Error occurred");
        }
      });
    }
  }

  saveCommentWithImages() {
    this.disablePostButtton = true;
    if (this.disablePostButtton) {
      let imageUploaded:any = [];
      this.uploadService
        .uploadImageOnFirebase(this.ImageFiles)
        .then((imageurl:any) => {
          if (imageurl.length > 0) {
            imageurl.forEach((element:any) => {
              imageUploaded.push(element);
            });
            this.request.pictures = imageUploaded;
            // let abc = JSON.stringify(imageUploaded);
            // this.request.pictures = abc.replace('[', '{').replace(']', '}');
            this.saveComment(this.request);
          } else {
            this.toastyService.failure("", "Error occurred");
          }
        });
    }
  }

  checkLoginAndOpenDialog(ele:any) {
    if (this.videoOptionSelected) {
      this.toastyService.failure("", "Please remove video to add other media");
      return;
    }
    if (this.imageOptionSelected && ele != "openAddImageButton") {
      this.toastyService.failure(
        "",
        "Please remove image/s to add other media"
      );
      return;
    }
    if (this.voiceOptionSelected) {
      this.toastyService.failure("", "Please remove audio to add other media");
      return;
    }
    this.loginService.openModal(
      { quote: "offer_help_suggestion_1", text: "offer_help_suggestion_2" },
      (status: string) => {
        if (status) {
          const element = document.getElementById(ele);
          element?element.click():'';
        } else {
          this.moeService.eventEmitter("web_Login/Signup_Left", {
            platform: "web",
          });
        }
      }
    );
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
    }
  }

  navigateToEditHelpRequestById(requestId:number) {
    this.loginService.openModal("", (status: string) => {
      if (status) {
        this.router.navigate(["help-request/edit-request"], {
          queryParams: { Id: requestId },
        });
      } else {
        this.moeService.eventEmitter("web_Login/Signup_Left", {
          platform: "web",
        });
      }
    });
  }

  deleteHelpRequest(requestId:any, username_:any) {
    this.IsLoader = true;
    const detail = {
      helpId: requestId,
      username: this.username,
    };
    this.subscriptions.push(
      this.dataService.deleteHelpRequest(detail).subscribe((result) => {
        this.IsLoader = false;
        if (result.success) {
          if (result.isdeleted) {
            this.toastyService.success(
              "",
              this.languageService.getTranslated(
                "Help Request Deleted Successfully."
              )
            );
            this.router.navigate(["./feeds/help"], {
              queryParams: { pageNo: this.queryParams.pageNo },
            });
            // on successfull deletion also delete from firebase if exist.
            if(this.authService.authenticated){
              this.dataService.removeHrAndTimestampIfPaid(username_,requestId);
            }
          } else {
            this.toastyService.success("", result.msg);
          }
        }
      })
    );
  }

  imageUploadOption(e:any) {
    this.showWebcam = false;
    if (e == 1) {
      this.imageClickOption = true;
      this.showWebcam = true;
      this.closeImagebutton.nativeElement.click();
    } else {
      this.imageSelect = true;
    }
  }

  public toggleWebcam(): void {
    const element = document.getElementById("imageCaptureModalButton");
    element?element.click():'';
    this.showWebcam = !this.showWebcam;
    this.imageClickOption = !this.imageClickOption;
  }

  public takeNewSnapShot(): void {
    this.webcamImage = null;
  }

  videoUploadOption(e:any) {
    this.openRecordVideo = false;
    if (e == 1) {
      this.videoUpload = true;
    } else {
      this.videoRecord = true;
      this.openRecordVideo = true;
      this.closeVideobutton.nativeElement.click();
    }
  }

  startVideoRecording() {
    let mediaConstraints = {
      allowCameraSwitch: true,
      video: {
        mandatory: {
          minWidth: 1280,
          minHeight: 720,
        },
      },
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(<any>mediaConstraints)
      .then(this.successCallback.bind(this), (() => {}).bind(this));
    this.isRecordingVideo = true;
  }

  stopVideoRecording() {
    this.isRecordingVideo = false;
    const element = document.getElementById("videoRecordModalButton");
    element?element.click():'';
    let recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processVideo.bind(this));
    let stream = this.stream;
    stream.getAudioTracks().forEach((track:any) => track.stop());
    stream.getVideoTracks().forEach((track:any) => track.stop());
  }

  processVideo(audioVideoWebMURL:any) {
    let video: HTMLVideoElement|any = this.video.nativeElement;
    let recordRTC = this.recordRTC;
    video.src = video.srcObject = null;
    video.src = URL.createObjectURL(recordRTC.getBlob());
    this.toggleControls();
    var recordedBlob = recordRTC.getBlob();
    this.handleVideoSelect(recordedBlob);
    recordRTC.getDataURL(function () {});
  }

  toggleControls() {
    let video: HTMLVideoElement = this.video.nativeElement;
    video.muted = !video.muted;
    video.controls = !video.controls;
    video.autoplay = !video.autoplay;
  }

  remove_video(video?:any) {
    this.showVideo = false;
    const element = document.getElementById("videoRecordModalButton");
    element?element.click():'';
    let stream:any = this.stream;
    stream.getAudioTracks().forEach((track:any) => track.stop());
    stream.getVideoTracks().forEach((track:any) => track.stop());
    this.video = [];
    this.videoOption = false;
    this.openRecordVideo = false;
    this.videoOptionSelected = false;
    this.videosThumbnile = "";
  }

  successCallback(stream: MediaStream) {
    var options = {
      mimeType: "video/webm", // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 128000,
      bitsPerSecond: 128000, // if this line is provided, skip above two
    };
    this.stream = stream;
    this.recordRTC = new RecordRTC(stream);
    this.recordRTC.startRecording();
    let video: HTMLVideoElement = this.video.nativeElement;
    video.srcObject = stream;
    this.toggleControls();
  }

  handleFileSelect(evt:any) {
    this.closeImagebutton.nativeElement.click();
    var files = evt.target.files; // FileList object

    // Loop through the FileList and render image files as thumbnails.
    for (var i = 0, f; (f = files[i]); i++) {
      if (!f.type.match("image.*")) {
        continue;
      }
      this.ImageFiles.push(files[i]);

      var reader = new FileReader();

      // Closure to capture the file information.
      reader.onload = ((theFile) => {
        return (e:any) => {
          // Render thumbnail.
          // var strImage = e.target.result.replace(/^data:image\/[a-z]+;base64,/, "" );
          var strImage:any = e.target.result;
          this.selectedImageThumbnail.push(strImage);
          // this.selectedImages.push(strImage);
        };
      })(f);
      reader.readAsDataURL(f);
      // this.imageSelect = !this.imageSelect;
      this.imageOption = true;
      this.imageOptionSelected = true;
    }
  }

  handleVideoSelect(videoFile:any) {
    this.closeVideobutton.nativeElement.click();
    this.IsLoader = true;
    this.selectedVideoFiles = videoFile;
    var reader = new FileReader();
    if (videoFile != null) {
      this.isDisable = false;
      this.setFileInfo(videoFile);
    } else {
      //document.getElementById("errorInVideo").innerHTML = "Size should be less than 2MB";
      this.isDisable = true;
    }
    if (this.isDisable) {
      if (this.videoTimeIssue) {
        this.toastyService.failure(
          "",
          "Duration of video should be less than " +
            this.VIDEODURATION +
            " seconds."
        );
        this.IsLoader = false;
        return;
      } else {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "Not a valid Video, Please try again"
          )
        );
        this.IsLoader = false;
        return;
      }
      // this.IsLoader = false;
    } else {
      this.videosThumbnile = videoFile;
      this.videoOption = true;
      this.IsLoader = false;
      this.showVideo = true;
      this.videoOptionSelected = true;
      this.cdf.detectChanges();
      // this.uploadService.uploadVideo(videoFile).then((value) => {
      //   if (value != null || value != "") {
      //     this.IsLoader = false;
      //     this.showVideo = true;
      //     this.videoOptionSelected = true;
      //     this.videoOption = true;
      //     this.helpVideo = value;

      //   }
      //   //this.IsLoader = false;
      // });
      // this.IsLoader = false;
    }
  }

  removeVideoSelect() {
    this.showVideo = false;
    this.video = [];
    this.videoOption = false;
    this.openRecordVideo = false;
    this.videosThumbnile = "";
    this.videoUpload = false;
    this.videoOptionSelected = false;
  }

  handleVideoSelection(evt:any) {
    var files = evt.target.files; // FileList object
    // Loop through the FileList and render image files as thumbnails.
    for (var i = 0, f; (f = files[i]); i++) {
      if (f.type.match("video.*")) {
        this.handleVideoSelect(files[i]);
        continue;
      }
      this.selectedVideoFiles = files[i];
      var reader = new FileReader();
      // Closure to capture the file information.
      reader.onload = ((theFile) => {
        return (e:any) => {
          var strImage = e.target.result;
          // this.selectedVideoFiles.push(strImage)
        };
      })(f);
      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    const element = document.getElementById("imageCaptureModalButton");
    element?element.click():"";
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.showWebcam = !this.showWebcam;
    this.getImage(this.webcamImage.imageAsDataUrl);
  }

  getImage(imageUrl: string) {
    this.imageClickOption = false;
    this.imageSelect = false;
    this.IsLoader = true;
    this.subscriptions.push(
      this.getBase64ImageFromURL(imageUrl).subscribe((base64Data: string) => {
        this.base64TrimmedURL = base64Data;
        this.imageOption = true;
        this.dataURItoBlob(this.base64TrimmedURL).subscribe((blob: Blob) => {
          const imageBlob: Blob = blob;
          const imageName: string = this.generateName();
          const imageFile: File = new File([imageBlob], imageName, {
            type: "image/jpeg",
          });
          this.ImageFiles.push(imageFile);
          this.selectedImageThumbnail.push(this.base64DefaultURL);
          this.imageOption = true;
          this.imageOptionSelected = true;
          this.IsLoader = false;
        });
        this.IsLoader = false;
      })
    );
  }

  getBase64ImageFromURL(url: string): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    var canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx: CanvasRenderingContext2D|any = canvas.getContext("2d");
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    let dataURL: string = canvas.toDataURL("image/png");
    this.base64DefaultURL = dataURL;
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  /* Method to convert Base64Data Url as Image Blob */
  dataURItoBlob(dataURI: string): Observable<Blob> {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "image/jpeg" });
      observer.next(blob);
      observer.complete();
    });
  }

  /**Method to Generate a Name for the Image */
  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = "";
    const possibleText: string =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + "." + text + ".jpeg";
  }

  remove_picture(image:any) {
    this.selectedImageThumbnail.splice(image, 1);
    if (this.selectedImageThumbnail.length == 0) {
      this.imageOption = false;
      this.imageOptionSelected = false;
    }
    this.ImageFiles = [];
  }

  remove_all_pictures() {
    this.imageClickOption = false;
    this.ImageFiles = [];
    this.imageSelect = false;
    this.imageOption = false;
    this.selectedImageThumbnail = [];
    this.imageOptionSelected = false;
  }

  markAsNotHelpful(comment_id:any, comment:any) {
    this.IsLoader = true;
    this.requestDetails = {
      helpid: this.helpRequestId,
      commentid: comment_id,
      username: this.username,
    };
    this.subscriptions.push(
      this.dataService
        .getDataByEntity("hr/markSuggestionAsNotHelpful", this.requestDetails)
        .subscribe((res) => {
          if (res.success) {
            this.toastyService.success("", "Suggestion marked as not Helpful");
            comment.is_helpful = false;
          }
          this.IsLoader = false;
        })
    );
  }

  markhelpful(request:any, comment:any, i:any) {
    this.selectMessage("Thanks a lot Dear friend", 2);
    this.markCommentAsThankyou(request, comment, i);
    setTimeout(() => {
      this.submitThankYou();
    }, 2000);
  }

  openDialog(video_link:any) {
    let dialogRefer;
    let timerinSec = 0;
    let timer = setInterval(() => {
      timerinSec += 0.5;
    }, 500);
    if (this.mobileScreen) {
      dialogRefer = this.dialog.open(VideoPlayer, {
        width: "100%",
        minWidth: "100%",
        height: "100%",
        data: {
          videoLink: video_link,
        },
        panelClass: "transparent-dialog_for_videPlayer",
      });
    } else {
      dialogRefer = this.dialog.open(VideoPlayer, {
        width: "36rem",
        height: "28rem",
        data: {
          videoLink: video_link,
        },
        panelClass: "transparent-dialog_for_videPlayer",
      });
    }
    dialogRefer.afterClosed().subscribe(() => {
      this.googleAnalyticsService.eventEmitter2("time_spent_on_video", {
        platform: "web",
        timeSpent: timerinSec,
        video_link: video_link,
        help_id: this.request.helpid,
      });
      clearInterval(timer);
    });
  }

  // togglePictureInPicture(index) {
  //   let video = document.getElementById('videoElement'+ index);
  //   if (document['pictureInPictureElement']) {
  //     (document as any).exitPictureInPicture();
  //   } else {
  //     if (document['pictureInPictureEnabled']) {
  //         // You should be able to request the picture in picture API from here
  //         // Request on my dom element
  //         setTimeout(()=>{
  //           (video as any).requestPictureInPicture();
  //         }, 0)
  //     }
  //   }
  // }

  addRequest() {
    this.router.navigate(["./help-request/create-request"]);
    this.googleAnalyticsService.eventEmitter(
      "web_add_help-request_page",
      "",
      "User navigate to add help-request page"
    );
  }

  viewRequest(catid:any) {``
    console.log("++++++++++++++++++++++++++++++++", catid);
      // this.requestSubscribe = this.commonService.reqStatusfetch.subscribe((resStatus: any) => {
      //          console.log("testing 1", resStatus,resStatus.length)
      //          if (resStatus.length == 0) { console.log("testing 2")
      //            this.requestSubscribe.unsubscribe()
      //            this.commonService.setReqstatus(1)
      //            this.router.navigate(["./help-request/create-request"], {
      //            queryParams: { cat_id: catid },
      //          });
      //          }
      //         })
    // this.commonService.setReqstatus(1)
    this.router.navigate(["./help-request/create-request"], {
      queryParams: { cat_id: catid },
    });
  }

  // @HostListener("window:beforeunload", ["$event"])
  // onBeforeReload(e: BeforeUnloadEvent) {
  //   e.stopPropagation();
  // }
  private routeNext = false;
  isPopUpOneOpened(): boolean {
    // console.log("++++++++++++++++++++++++++++++++++++++");
    // const dialog = this.dialog.open(PopUpOne);
    // console.log("asdf", dialog.afterOpened());
    // // document.getElementById("openPopUpButton").click();
    // let status = false;
    // // dialog.afterClosed().subscribe((res) => {
    // //   return res;
    // // });
    // console.log("asdf", dialog.afterClosed());
    return false;
  }
  loadDataForPopUpComponent() {
    if(!this.requestDetails || !this.requestDetails.helpRequestId || !this.requestDetails.commentsToShow || !this.requestDetails.repliesToShow){
        this.IsLoader = false;
        return;
      }
    let user:any = new FormGroup({
      username: new FormControl(this.commonService.getUsername()),
      helpid: new FormControl(this.request.helpid),
      ctg: new FormControl(this.passCatID),
      sub_ctg: new FormControl(this.passSubCatID[0]),
      response: new FormControl("Yes"),
    });
    this.getSpecificHelpRequestService.setRequestData(user.value);
    // this.getSpecificHelpRequestService.getRequestData().subscribe((res) => {
    //   console.log("result", res);
    //   console.log("gettting done");
    // });
    // console.log("gettting done");
  }

  numberOfClicks(opt: string) {
    let user = new FormGroup({
      username: new FormControl(this.commonService.getUsername()),
      helpid: new FormControl(this.request.helpid),
      ctg: new FormControl(this.passCatID),
      sub_ctg: new FormControl(this.passSubCatID[0]),
      response: new FormControl(opt),
    });

    if (opt == "yes") {
      this.dataService.recordUsersAction(user.value).subscribe((res) => {
        // console.log("sadlkfjalsdkjfalskdjfalskdjf");
      });
    } else if (opt == "no") {
      this.dataService.recordUsersAction(user.value).subscribe((res) => {
        // this.gotoUserChoice();
      });
    }
  }
  testing() {
    return true;
  }

  startTimer1Minute(){
    this.showSecondsTimer = true;
    this.timeDifference2 = 1000*60; // 1 minute timer
    this.oneMinuteCountDownTimer =  interval(1000).subscribe(x => {
      this.secondCountDown2 = Math.floor((this.timeDifference2) / 1000 % 60);
      this.timeDifference2 = this.timeDifference2 - 1000;
      if((this.secondCountDown2 < 0)){
        this.showSecondsTimer = false;
        this.oneMinuteCountDownTimer.unsubscribe();
        this.getRequestDetailbyId(this.helpRequestId,this.commentToShow);
      }
    })
  }

  startTimer24Hour(){
    this.startCountDownTimer();
  }

  startCountDownTimer(){
    if(this.countDownTimer){
      this.countDownTimer.unsubscribe();
    }
    this.countDownTimer  = interval(1000).subscribe(x => {
      this.getTimeDifference();
      if(this.hourCountDown == 0 && this.minuteCountDown == 0 && this.secondCountDown == 0){
        this.showPaymentProcessingTimer = false;
        this.countDownTimer.unsubscribe();
        this.getRequestDetailbyId(this.helpRequestId,this.commentToShow);
      }
    })
  }

  getTimeDifference(){
    this.allocateTimeUnits(this.timeDifference);
  }

  allocateTimeUnits(timeDifference:any){
    this.secondCountDown = Math.floor((timeDifference) / 1000 % 60);
    this.minuteCountDown = Math.floor((timeDifference) / (1000*60) % 60);
    this.hourCountDown = Math.floor((timeDifference) / (1000*60*60) % 60);
    this.timeDifference = this.timeDifference -1000;
  }


  ratingStars(count:any){
    this.ratingStarCount = count;
    this.userReview.rating = count;
    (window as any).hj('event', 'rating_init');
    this.googleAnalyticsService.eventEmitter2('rating_init',{});
  }

  onClickReviewEmitra(){
    if(this.userReview && this.userReview.rating && this.userReview.rating >0){
      const req= {
        emitra_id : this.assignedEmitraId,
        helpid: this.helpRequestId,
        rating: this.ratingStarCount,
        userid: this.username,
        msg: this.userReview.message,
        timestamp: Math.floor(new Date().getTime()/1000),
        subctg: this.request.sub_category
      }

      this.dataService
      .callv3API("expand/setRatingForEmitra", req)
      .subscribe((res) => {
        if (res.success) {
          this.userReview.hasReview= true;
          this.userReview.message && this.userReview.message.length>0?this.userReview.hasMessage=true:
                                      this.userReview.hasMessage = false;
          this.showReviewCard = false;
          this.review = "";
          this.ratingStarCount = 0;


          // if user added rating with review
            if(this.userReview && this.userReview.rating && this.userReview.rating>0 && this.userReview.message.length>0){
              this.googleAnalyticsService.eventEmitter2('rating_fb_submit',{});
              (window as any).hj('event', 'rating_fb_submit');
            }
            else{
              this.googleAnalyticsService.eventEmitter2('rating_submit',{});
              (window as any).hj('event', 'rating_submit');
            }
        }
      }
      ,error=>{
        this.IsLoader = false;
      })
    }
  }

  closeRequestNew(){
    const req= {
      helpid : this.helpRequestId,
      username : this.username,
      emitra_id: this.assignedEmitraId
    }
    this.subscriptions.push(this.dataService.callv3API("expand/closePaidHelpRequest",req).subscribe(res =>{
      if(res && res.success){
        this.hrEmitraStatus = "closed";
        this.hrUserStatus = "completed";
        (window as any).hj('event', 'hr_clsd');
        this.googleAnalyticsService.eventEmitter2('hr_clsd',{});
      }
      this.getRequestDetailbyId(this.helpRequestId,this.commentToShow);
      this.showReviewCard = true;
    }))
  }

  closeRequestHandler(){
    this.getRequestDetailbyId(this.helpRequestId,this.commentToShow);
    this.showReviewCard = true;
    this.docEl = document.getElementById("reviewCard1");
    if(this.docEl){
      this.docEl.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }

  openPaymentReceipt(){
    const req={
      helpid:this.helpRequestId,
      username:this.username
    }
    let paymentObj:{
      emitra_id: string,
      emitra_name: string,
      full_name: string,
      helpid: string,
      payment_mode: string,
      phone_no: string,
      receipt_no: string,
      txn_amount: number,
      txn_time: number,
      username: string
    };
    this.subscriptions.push(this.dataService.callv3API("payments/getPaymentReceiptDetails",req)
      .subscribe(res =>{
        if(res.success){
          paymentObj = res.data;
          let dialogRef = this.dialog.open(PaymentReceiptComponent, {
            width: "700px",
            height: "722px",
            panelClass: "no-padding-dialog",
            data:paymentObj
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.googleAnalyticsService.eventEmitter2('dwnd_recpt',{});
            (window as any).hj('event', 'dwnd_recpt');
          });
        }
      }
      ,error=>{
        this.IsLoader = false;
      }))
  }

  transformIntoSec(progress:any){
    if(progress!=0){
      return Math.round((progress*60)/100);
    }
    return 0;
  }

  pageNo = 1;
  viewMoreTrending(){
    this.pageNo = this.pageNo +1;
    this.googleAnalyticsService.eventEmitter('view_more_rec_cont',"","view more recommended content");
    (window as any).hj('event', 'view_more_rec_cont');
  }

  hasMoreTrendingHr = true;
  hasMoreTrendingHrHandler(event:any){
    this.hasMoreTrendingHr = false;
  }

  topEmitras: {name:string,profile_picture:string,rating:number,phone_no:string}[]=[];
  getTopEmitras(){
    const req={};
    this.subscriptions.push(this.dataService.callv3API("emitra/getTopEmitra",req)
      .subscribe(response =>{
        if(response && response.success && response.rows){
          this.topEmitras = [];
          this.topEmitras = response.rows;
          // round off the emitra ratings..
          if(this.topEmitras){
            this.topEmitras.forEach(data=>{
              data.rating= Math.round(data.rating*10)/10;
            })
          }
        }
      }
      ,error=>{
        this.IsLoader = false;
      }))
  }

  getInitial(string:any){
    if(string){
      return string.charAt(0).toUpperCase();
    }
    return ('E');
  }


  requestFreeHelp(){
    const isPaid = false;
    this.createHelpRequest(isPaid);
    this.googleAnalyticsService.eventEmitter2('fr_init_cev',{});
    (window as any).hj('event', 'fr_init_cev');
  }

  requestPaidHelp(){
    this.router.navigate(["./help/survey"]);
    this.googleAnalyticsService.eventEmitter2('pr_init_cev',{});
    (window as any).hj('event', 'pr_init_cev');
    // this.router.navigateByUrl("help/survey");
  }

  helpid: string="";
  phoneNo:string="";
  createHelpRequest(isPaid:any){
    this.loginService.openModal(
      { autoChangeScreen: true },
      (status: string) => {
        if (status) {
          let helpid = localStorage.getItem("")
          const paidHelpDialog = this.dialog.open(ConfirmDialogPaidHelpComponent, {
            disableClose: true,
            panelClass: 'confirmPaidHelpDialog',
            width:'435px',
            maxHeight:'620px',
            data: {
              isPaid: isPaid
            }
          });
          this.getSpecificHelpRequestService.getRequestData().subscribe((res) => {
            if (res) {
              this.helpid = res.helpid;
              this.phoneNo = res.username;

              let user = {
                username: res.username,
                helpid: res.helpid,
                ctg: res.ctg,
                sub_ctg: res.sub_ctg,
                response: "Yes",

              };
              this.dataService.recordUsersAction(user).subscribe((res) => {
              });
              // let jsonRequest = {
              //   "helpid": this.helpid,
              //   "phone_no": this.phoneNo,
              //   "opt": "y"
              // }
              // this.dataService
              //   .callv3API("setToOptForPaidHR", jsonRequest)
              //   .subscribe((res) => {
              //     console.log("res", res, res.success)
              // })
            }
          });
        }
      });
  }


  // reloadApiSubject;
  // // 5 minute interval limit 5*60*1000
  // intervalLimit = 0;
  // // interval time is 30 second = 30*1000
  // interval =  30000;
  reviewLoaded = false;
  // offerListner(){
  //   // if emitra has been asigned then no need to reload
  //   if(this.isAssignedEmitra){
  //     if(this.reloadApiSubject){
  //       this.reloadApiSubject.unsubscribe();
  //     }
  //   }
  //   // itreate the interval after every thirty seconds.
  //   this.reloadApiSubject = Observable.interval(this.interval).subscribe((val)=>{
  //     // if emitra has been asigned then no need to reload
  //     if(this.isAssignedEmitra){
  //       if(this.reloadApiSubject){
  //         this.reloadApiSubject.unsubscribe();
  //       }
  //     }
  //     this.intervalLimit = this.intervalLimit + this.interval;
  //     // unsubscribe if interval is greater than 5 minutes.
  //     if(this.intervalLimit>300000){
  //       this.reloadApiSubject.unsubscribe();
  //     }
  //     if(this.intervalLimit>=30000 && this.intervalLimit <= 300000 && !this.isAssignedEmitra){
  //       this.getRequestDetailbyId(this.helpRequestId, this.commentToShow);
  //     }
  //   })
  // }


  thankyouCardTimer = 0;   // 5 minutes set on start
  thankyouCardTimerText = "05:00";   // 5 minutes
  thankyouCardTimerText2 = "05:00";   // 5 minutes
  reloadTimerOfferSubject:any;
  reloadTimerOfferInterval = 1000; // 1 second.
  reloadTimerType: "thankyouCard"|"thankyouExtendedCard" = "thankyouCard";
  messageTimer = 0;
  messageType : '1'|'2'|'3' = '1';
  timerMessage:any;
  timerMessage2:any='94% of the time our helpers respond within 3 mins.';
  reloadTimerForOffer(timestamp:any){
    this.thankyouCardTimer = 300000;
    const creationTime = timestamp;
    const currentTime = Date.now();
    if(currentTime - +creationTime <= 300000){
      this.thankyouCardTimer = 300000 - (currentTime - +creationTime);
    }
    else{
      this.thankyouCardTimer = 0;
      this.reloadTimerType = 'thankyouExtendedCard';
      if(this.reloadTimerOfferSubject){
        this.reloadTimerOfferSubject.unsubscribe();
      }
      return;
    }
    // if emitra has been asigned then no need to reload
    if(this.isAssignedEmitra || this.hasBids){
      this.thankyouCardTimer = 0;
      if(this.reloadTimerOfferSubject){
        this.reloadTimerOfferSubject.unsubscribe();
      }
      return;
    }
    // for random helper less than 3
    let helpers = Math.floor(Math.random() * 3)+1;
    // for random request solved
    let solutions = Math.floor(Math.random() * 25)+1;
    const thankyou_note_1 = 'thankyou_note_1'
    this.languageService
                  .getTranslationOf(thankyou_note_1)
                  .subscribe((value) => {
                    if (value) {
                      this.timerMessage = value;
                    }
                  });
    // setting default timer message
    this.timerMessage2 = '94% of the time our helpers respond within 3 mins.';
    // itreate the interval after every thirty seconds.
    this.reloadTimerOfferSubject = interval(this.reloadTimerOfferInterval).subscribe((val:any)=>{
      // if emitra has been asigned then no need to reload
      if(this.isAssignedEmitra || this.hasBids){
        this.thankyouCardTimer = 0;
        if(this.reloadTimerOfferSubject){
          this.reloadTimerOfferSubject.unsubscribe();
        }
      }
      if(this.reloadTimerType == "thankyouCard"){
        this.thankyouCardTimer = this.thankyouCardTimer - this.reloadTimerOfferInterval;
        this.thankyouCardTimerText = this.dataService.getTime(this.thankyouCardTimer);
        this.messageTimer = this.messageTimer +1;
        if(this.messageTimer == 15){
          if(this.messageType == '1'){
            this.messageType = '2';
            //  new helpers become 20 percent of helpers
            helpers = helpers + Math.ceil(0.20*helpers)
            const thankyou_note_2 = 'thankyou_note_2';
            this.languageService
                          .getTranslationOf(thankyou_note_2)
                          .subscribe((value) => {
                            if (value) {
                              const replaceValueString = helpers;
                              let message= value;
                              message = message.replace('--',replaceValueString);
                              this.timerMessage = message;
                            }
                          });
            this.timerMessage2 = `${this.randomSolvedRequest2} requests were solved in last 24 Hrs.`;
          }
          else if(this.messageType == '2'){
            this.messageType = '3';
            //  new helpers become 20 percent of helpers
            solutions = solutions + Math.ceil(0.20*solutions)
            const thankyou_note_3 = 'thankyou_note_3'
            this.languageService
                          .getTranslationOf(thankyou_note_3)
                          .subscribe((value) => {
                            if (value) {
                              const replaceValueString = solutions;
                              let message= value;
                              message = message.replace('--',replaceValueString);
                              this.timerMessage = message;
                            }
                          });
            this.timerMessage2 = 'Accept offer when available to connect with helper.';
          }
          else{
            this.messageType = '1';
            const thankyou_note_1 = 'thankyou_note_1'
            this.languageService
                          .getTranslationOf(thankyou_note_1)
                          .subscribe((value) => {
                            if (value) {
                              this.timerMessage = value;
                            }
                          });
            this.timerMessage2 = '94% of the time our helpers respond within 3 mins.';
          }
          this.messageTimer = 0;
          this.randomEmitraCount = this.increaseByRate(this.randomEmitraCount);
          this.randomSolvedRequest2 = this.increaseBy10Rate(this.randomSolvedRequest2);
        }
      }
      if(this.thankyouCardTimer <=0){
        this.reloadTimerType = "thankyouExtendedCard";
        this.reloadTimerOfferSubject.unsubscribe();
        this.disableFloatState();
      }

    })
  }

  // random emitra count between 0 to 10 and atleast greater than 2
  randomEmitraCount = Math.round(Math.random()*8 +2);
  // random number less than 25  .. and atleast greater than 2.
  randomSolvedRequest = Math.round(Math.random()*25 + 30);
  randomSolvedRequest2 = Math.round(Math.random()*800 + 200);
  // increase by 20%
  increaseByRate(number:any): number{
    const finalValue = Math.round(number * 20/100 + number);
    return finalValue;
  }
  increaseBy10Rate(number:any): number{
    const finalValue = Math.round(number * 10/100 + number);
    return finalValue;
  }

  onDragStart(event:any){
    this.clientYStart = event.clientY;
  }

  clientYStart:any = 0;
  clientYEnd: any = 0;
  onDragEnd(event:any){
    if(this.thankyouCardTimer>0 && !this.hasBids){
      return;
    }
    this.clientYEnd = event.clientY;
    if(this.clientYEnd > this.clientYStart){
      this.floatingState = false;
      this.showEmitra = false;
      this.showOffers = false;
      this.showOffers2 = false;
    }
    else{
      this.floatingState = true;
    }
  }

  hasOffer = 0;

  goToOffers(){
    this.router.navigate(["help-request/getHelp-RequestbyId"], {
      queryParams: {
        Id: this.offerHelpid,
        source: "home",
        goToOffers: "true"
      },
    });
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_expanded-view_page",
      "",
      "User navigate to help-request expanded-view"
    );
  }


  offerHelpid:string="";
  hitApiOnOfferReceived = false;
  offerListner:any;
  checkAvailableOffers(helpid:any){
    // check for offers
    this.username = this.commonService.getUsername();
    if(this.username && this.authService.authenticated){
      this.offerHelpid = helpid;
      if(this.offerHelpid){
        if(this.offerListner){
          this.offerListner.unsubscribe();
          this.offerListner = null;
        }
        this.offerListner = this.dataService.getOffer(this.username,this.offerHelpid).snapshotChanges()
          .pipe(
          map(changes =>
            changes.map((c:any) =>
              ({ key: c.payload.key, ...c.payload.val() })
            )
          )
          ).subscribe( res=>{
          if(res){
            if(res.length != 0){
              this.hasOffer = res[0].count;
              if(this.hasOffer > 0){
                this.slidingInterval();
                if((this.paymentFailed || this.paymentPending)){
                  this.floatingState = true;
                }
                this.googleAnalyticsService.eventEmitter2('offer_received',{});
                (window as any).hj('event', 'offer_received');
                this.thankyouCardTimer = 0;
                // if(!this.hitApiOnOfferReceived){
                //   this.getRequestDetailbyId(this.helpRequestId, this.commentToShow);
                //   this.hitApiOnOfferReceived = true;
                // }

                this.hasBids = true;
                this.thankyouCardTimer = 0;
                // console.log('thec 5')
                // this.reloadTimerType = 'thankyouExtendedCard';
                this.bidList = [];
                const bids = res[0].data;
                // number of help request solved if not present then assigned random request count.
                bids.forEach((item:any)=>{
                  item.num_hr_solved = item.num_hr_solved && item.num_hr_solved != -1? 
                  item.num_hr_solved: this.randomRequests();
                })
                // show bid list for four bids each 10 seconds, in auto offer case so that user doesn't feel like
                // getting offer.
                // if(bids && bids.length>0){
                //   bids[0].seeMore = false;
                //   this.bidList.push(bids[0]);
                // }
                // if(bids && bids.length>1){
                //   setTimeout(() => {
                //     bids[1].seeMore = false;
                //     this.bidList.push(bids[1]);
                //   }, 5000);
                // }
                // if(bids && bids.length>2){
                //   setTimeout(() => {
                //     bids[2].seeMore = false;
                //     this.bidList.push(bids[2]);
                //   }, 10000);
                // }
                // if(bids && bids.length>3){
                //   setTimeout(() => {
                //     for(let index_=3;index_<bids.length;index_++){
                //       bids[index_].seeMore = false;
                //       this.bidList.push(bids[index_]);
                //     }
                //   }, 15000);
                // }
                for(let index_=0;index_<bids.length;index_++){
                  bids[index_].seeMore = false;
                  this.bidList.push(bids[index_]);
                }
                this.router.navigate([], {
                  relativeTo: this.activatedRoute,
                  queryParams: {
                    assignedEmitra: bids[0].name,
                    assignedAmount:bids[0].price
                  },
                  queryParamsHandling: 'merge',
                  // preserve the existing query params in the route
                  skipLocationChange: true
                  // do not trigger navigation
                });
                // if(this.reloadApiSubject){
                //   this.reloadApiSubject.unsubscribe();
                // }
                if(this.reloadTimerOfferSubject){
                  this.reloadTimerOfferSubject.unsubscribe();
                }
                if(this.showOrHideMessage){
                  this.showOrHideMessage =null;
                }
                this.showOrHideMessage = interval(15000).pipe(
                  map(i => i%2 === 0),
                );
                this.showOrHideMessage.subscribe((value:any)=>{
                  this.message1 = value;
                });
                return true;
              }
            }
            else{
              if(!this.bidList || this.bidList.length == 0){
                this.hasBids = false;
              }
            }
          }
        });
      }
    }
    return false;
  }

  showOrHideMessage:Observable<boolean>|any;
  message1:any;

  removeHrAndTimestamp(){
    if(this.helpRequestId == this.offerHelpid && this.authService.authenticated){
      this.dataService.removeHridAndTimestamp(this.commonService.getUsername());
      this.dataService.setOfferHelpid(null);
    }
  }

  paymentFailedPopup(data:any,bid:any){
    let width = '400px';
    let height= '412px';
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      width= '328px';
      height= '384px';
    }
    let dialogRef = this.dialog.open(PaymentFailedPopupComponent, {
      width: width,
      height: height,
      disableClose: true,
      panelClass:'custom-panel-class5',
      data:data
    });
    dialogRef.afterClosed().subscribe(response=>{
      if(response){
        this.acceptOffer(bid,true);
      }
      if(response==null){
        this.paymentFailedPopup(data,bid);
      }
    });
  }


  hasThankyouComment=(comments:any)=>{
    if(comments && comments.length>0){
      for (let index = 0; index < comments.length; index++) {
        if(comments[index].is_admin ==  true){
          return true;
        }
      }
    }
    return false;
  }

  createHelpRequestFromExistingOne(){
    let v1 = this.cookieService.get("localstars") == "true" ? 1 : 0;
    let v2 =
      this.cookieService.get("Help_Video_seen") == "true" ? 1 : 0;
    let v3 =
      this.cookieService.get("publicfacilities") == "true" ? 1 : 0;
    let v4 = this.cookieService.get("local") == "true" ? 1 : 0;
    let v5 = this.cookieService.get("official") == "true" ? 1 : 0;
    let v6 = JSON.stringify(Object.values({ v1, v2, v3, v4, v5 }));

    let metaData = {
      virtual_id: localStorage.getItem("virtualId"),
      browser_userAgent: this.cookieService.get("browser_userAgent"),
      LanguageSelected: this.cookieService.get("LanguageSelected"),
      LocationSelected: this.cookieService.get("LocationSelected"),
      Help_videos_seen: v2,
      Home_videos_seen: v6,
      platform: this.cookieService.get("platform"),
      is_logged__in: this.cookieService.get("is_logged__in"),
      Webtoken: localStorage.getItem("Webtoken"),
      username: this.cookieService.get("username"),
      mobile_number: this.cookieService.get("mobile_number"),
    };

    this.IsLoader = true;
    const body = {
      username: this.username,
      category: this.request.category,
      subCategory: this.request.sub_category,
      lat: this.request.lat,
      lng: this.request.lng,
      metadata: JSON.stringify(metaData),
      stcode11: localStorage.getItem("stcode11"),
      dtcode11: localStorage.getItem("dtcode11"),
      helpType: this.request.help_type,
      helpTitle: this.request.help_title,
      stname: localStorage.getItem("stname"),
      dtname: localStorage.getItem("dtname"),
      helpVideo: this.request.help_video && this.request.help_video  != ''?
                                                this.request.help_video:"",
      helpVoice:this.request.help_voice_link && this.request.help_voice_link  != ''?
                                                this.request.help_voice_link :"",
      helpPictures:this.request.help_pictures && this.request.help_pictures.length != 0?
                                                this.request.help_pictures :"{}",
      helpDescription:this.request.help_description,
      standard_hr_lang:this.selectedLanguage,
      is_paid_help: true,
      is_standard: false
    };
    this.dataService.createHelpRequestv2(body).subscribe((req) => {
      if(req && req.success){
        const time = Date.now();
        localStorage.setItem("paid_help_creation_time",time.toString());
        this.dataService.setOfferHelpid(req.result.helpid);
        localStorage.setItem("create_paid_help_id",req.result.helpid);
        const statusValue  ="0";
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            Id: req.result.helpid,
            pageNo: "1",
            status: statusValue,
            source: "help",
            isPaidType: "true",
            'acceptOffer': null,
            'paymentStatus':null,
            'assignedEmitra':null,
            'assignedAmount':null,
            'userStatus':null
          },
          queryParamsHandling: 'merge'
        });
        this.googleAnalyticsService.eventEmitter2('ja_create_hr',{});
        (window as any).hj('event', 'PaidHR_Created_Home');
        if(this.authService.authenticated){
          this.dataService.updateNewHridAndTimestamp(this.commonService.getUsername(),
          req.result.helpid);
        }
        this.floatingState = true;
        this.showEmitra = false;
        this.showOffers = false;
        this.showOffers2 = false;
        this.isAssignedEmitra= false;
        this.isDataLoaded = false;
        this.bidList=[];
        this.hasOffer = 0;
        this.assignedEmitraId ='';
        this.assignedEmitraName='';
      }
      this.IsLoader = false;
    }, error=>{
      this.IsLoader = false;
    });

  }


  public allowCameraSwitch = true;
  public errors: WebcamInitError[] = [];
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }


  goToHome(){
    this.router.navigateByUrl("");
  }

  async createRzpayOrder(request:RazorPayTxn) {
    try {
      const txnObj:any = await this.initiateRazorPayTransaction(request);
      const orderId = txnObj.orderId;
      console.log('orderId',txnObj);
      this.IsLoader=false;
      this.payWithRazor(orderId,request.txn_amt);

    } catch (error) {
      console.log('error',error);
      this.toastyService.failure('Something went wrong, please try again later!!','');
      this.IsLoader=false;
    }
  }

  async initiateRazorPayTransaction(request:RazorPayTxn){
    return new Promise((resolve,reject)=>{
      this.dataService.initiatePaidHelpTransaction(request).subscribe(response=>{
        console.log('response',response);
        resolve(response);
      },error=>{
        reject(error);
      })
    })
  }


  payWithRazor(val:string,amount:number) {
    const options: any = {
      key: environment.razorKeyId,
      amount: amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'jaano.swaniti.org', // company name or product name
      description: '',  // product description
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      },
      prefill: {
        contact: this.phone_No
      },
      config: {
        display: {
          hide: [
            {
              method: 'wallet'
            },
            {
              method: 'paylater'
            }
          ],
        },
      },
    };
    options.handler = ((response:any, error:any) => {
      options.response = response;
      console.log(response);
      console.log(options);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }
}




@Component({
  selector: 'payment-failed-popup',
  templateUrl: 'payment-failed-popup.component.html',
  styleUrls: ['get-specific-help-request.component.scss']
})
export class PaymentFailedPopupComponent {
  isLoader = false;
  color = "primary";
  mode = "indeterminate";
  value = 50;
  username ="";
  helpid="";
  amount = 0;
  paymentFailedReason:{id:number,name:string,flag:boolean,value:string}[] = [
    {id:1,name:'reason1',flag:false,value:'Technical issues'},
    {id:2,name:'reason2',flag:false,value:'Payment method not available'},
    {id:3,name:'reason3',flag:false,value:'Security concerns'},
    {id:4,name:'reason4',flag:false,value:'Changed my mind'},
    {id:5,name:'reason5',flag:false,value:'Process too complicated'},
    {id:6,name:'reason6',flag:false,value:'Chose wrong helper'},
    {id:7,name:'other',flag:false,value:''},
  ];

  constructor( public dialogRef: MatDialogRef<PaymentFailedPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private dataService: DataService,
    private googleAnalyticsService:GoogleAnalyticsService,private languageService:LanguageTranslationService) {
    if(data){
      this.username = data.username;
      this.helpid = data.helpid;
      this.amount = data.amount;
    }
    this.paymentFailedReason.forEach(item=>{
      item.name = this.languageService.getTranslated(item.name);
    })

    // google and hotjar events
    this.googleAnalyticsService.eventEmitter('payment_exit_srvy_open',"", "");
    (window as any).hj('event', 'payment_exit_srvy_open');
  }

  onSubmit=()=>{
    let reasons:string[] = [];
    for(let item of this.paymentFailedReason){
      if(item.flag){
        reasons.push(item.value);

        // adding google events correspondingly
        const event = item.id == 6? `payment_exit_srvy_op_otr`:`payment_exit_srvy_op${item.id}`;
        this.googleAnalyticsService.eventEmitter(event,"", "");
      }
    }
    const req:PaymentExitForm={
      username:this.username,
      helpid:this.helpid,
      reason:reasons
    }
    this.isLoader = true;
    this.dataService.setPaymentExitReason(req).subscribe(response=>{
      // remove local value for payment failed
      localStorage.removeItem('paymentExitForm');
      (window as any).hj('event', 'payment_exit_srvy_op');
      this.isLoader = false;
      this.dialogRef.close(false);
    },error=>{
      this.isLoader = false;
      this.dialogRef.close(false);
    })
  }

  onDescribe(event:any){
    const value = event.target.value;
    this.paymentFailedReason[6].value= value;
  }

  anyReasonSelected=()=>{
    for(let item of this.paymentFailedReason){
      if(item.flag){
        return true;
      }
    }
    return false;
  }
  retryPayment=()=>{
    // google and hotjar events
    if(this.anyReasonSelected()){
      this.googleAnalyticsService.eventEmitter('payment_retry_2',"", "");
      (window as any).hj('event', 'payment_retry_2');
    }
    else{
      this.googleAnalyticsService.eventEmitter('payment_retry',"", "");
      (window as any).hj('event', 'payment_retry');
    }
    this.dialogRef.close(true);
  }
  
  onToggle(index:number){
    if(index == 6){
      if(this.paymentFailedReason[6].flag){
        const flag = this.paymentFailedReason[6].flag;
        this.paymentFailedReason.forEach(item=>item.flag=false);
        this.paymentFailedReason[6].flag =flag;
      }
    }
    else{
      if(this.paymentFailedReason[6].flag){
        this.paymentFailedReason[6].flag = false;
      }
    }
    return;
  }
  // onClose(){
  //   this.dialogRef.close(false);
  // }
}
