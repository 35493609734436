<div class="opacity_overlay" *ngIf="IsLoader">
  <mat-progress-spinner *ngIf="IsLoader" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
  </mat-progress-spinner>
</div>


<!-- <div> -->
<!-- Button trigger modal
  <button type="button" hidden class="btn btn-primary" data-toggle="modal" data-target="#staticBackdrop"
    id="openPopUpButton">
    Launch static backdrop modal
  </button> -->

<!-- Modal -->
<!-- <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header1">
          <h5 class="modal-title" id="staticBackdropLabel"><b>Did our suggestions solve the problem you had ?</b></h5>
        </div>
        <div class="modal-footer1">
          <button type="button" (click)="numberOfClicks('yes')" class="btn btn-secondary1" data-dismiss="modal">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 0C9.62663 0 7.30656 0.703789 5.33317 2.02237C3.35978 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9971 8.8183 22.7319 5.76774 20.4821 3.51793C18.2323 1.26813 15.1817 0.00291256 12 0ZM18.063 9.322L11.646 16.622C11.5392 16.7455 11.4074 16.845 11.2593 16.914C11.1113 16.9829 10.9503 17.0197 10.787 17.022H10.736C10.4222 17.0187 10.1224 16.8914 9.90201 16.668L5.98601 12.727C5.77246 12.5044 5.65465 12.207 5.65784 11.8986C5.66104 11.5901 5.78498 11.2952 6.0031 11.0771C6.22121 10.859 6.51612 10.735 6.82456 10.7318C7.13301 10.7286 7.43042 10.8464 7.65301 11.06L10.71 14.117L16.293 7.751C16.395 7.63359 16.5194 7.53772 16.6589 7.469C16.7984 7.40027 16.9503 7.36007 17.1055 7.35076C17.2607 7.34145 17.4163 7.36321 17.563 7.41476C17.7097 7.46632 17.8447 7.54663 17.96 7.651C18.0802 7.75114 18.1786 7.87484 18.2492 8.01447C18.3198 8.1541 18.3611 8.3067 18.3704 8.46288C18.3798 8.61905 18.3571 8.7755 18.3037 8.92256C18.2503 9.06963 18.1674 9.20421 18.06 9.318L18.063 9.322Z"
                fill="#003DB3"></path>
            </svg>
            <b>YES</b></button>
          <button type="button" (click)="numberOfClicks('no')" class="btn btn-primary1" data-dismiss="modal">
            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.0676 1.70632C14.6662 0.839096 12.0509 0.759613 9.60121 1.47941C7.15155 2.1992 4.99497 3.68082 3.4444 5.70928C1.89382 7.73773 1.02993 10.2075 0.978066 12.7602C0.926202 15.3129 1.68907 17.8157 3.15598 19.9055C4.62288 21.9952 6.7175 23.5632 9.13589 24.3819C11.5543 25.2006 14.1707 25.2274 16.6053 24.4584C19.04 23.6895 21.1663 22.1647 22.6757 20.1055C24.1851 18.0462 24.9991 15.5595 24.9996 13.0063C24.9874 10.5387 24.2211 8.13377 22.8034 6.11403C21.3856 4.09429 19.3842 2.55631 17.0676 1.70632ZM17.9156 19.0673L13.0006 14.1513L8.08559 19.0673C7.9309 19.2087 7.72766 19.2849 7.51818 19.2802C7.3087 19.2755 7.10911 19.1901 6.96095 19.042C6.81278 18.8938 6.72746 18.6942 6.72273 18.4847C6.71801 18.2752 6.79425 18.072 6.93559 17.9173L11.8506 13.0013L6.93559 8.08532C6.79425 7.93063 6.71801 7.7274 6.72273 7.51792C6.72746 7.30844 6.81278 7.10885 6.96095 6.96068C7.10911 6.81252 7.3087 6.7272 7.51818 6.72247C7.72766 6.71775 7.9309 6.79399 8.08559 6.93532L13.0006 11.8513L17.9156 6.93532C18.0703 6.79399 18.2735 6.71775 18.483 6.72247C18.6925 6.7272 18.8921 6.81252 19.0402 6.96068C19.1884 7.10885 19.2737 7.30844 19.2784 7.51792C19.2832 7.7274 19.2069 7.93063 19.0656 8.08532L14.1506 13.0013L19.0656 17.9173C19.2069 18.072 19.2832 18.2752 19.2784 18.4847C19.2737 18.6942 19.1884 18.8938 19.0402 19.042C18.8921 19.1901 18.6925 19.2755 18.483 19.2802C18.2735 19.2849 18.0703 19.2087 17.9156 19.0673Z"
                fill="#003DB3"></path>
            </svg>
            <b>NO</b>
          </button>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div [ngClass]="{'overlay-card':!checkoutPage && floatingState  && (!paymentPending && !paymentFailed)}"></div>
<div style="
height: calc(100vh - 48px);
overflow: overlay;
overflow-x: hidden;">
<div [ngClass]="{'overlay-card':checkoutPage && offerOnCheckout}"></div>
<div class="container p-40" style="position:relative" [hidden]="checkoutPage">
  <!-- <div class="lg-show paid-left-side-card"
  *ngIf="isPaidHelp && helpRequestUserName == username && abTestingType == ABTestingType.Old">
    <div class="left-side-card">
      <div class="row">
        <div class="col-12">
          <div class="card side-cards">
            <div class="card-body" style="padding: 2.5px 10px;">
              <div class="row">
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/customer-icon.svg" 
                    alt="customer-icon">
                  </div>
                  <div class="text-fields">
                    <div class="text-1 content-center">
                      10000+
                    </div>
                    <div class="text-1 light-text content-center">
                      {{'satisfied customers'|translate}}
                    </div>
                  </div>
                </div>
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/solved-icon.svg" 
                    alt="solved-icon">
                  </div>
                  <div class="text-fields">
                    <div class="text-1 content-center">
                      500+
                    </div>
                    <div class="text-1 light-text content-center">
                      {{'solved_everyday'|translate}}
                    </div>
                  </div>
                </div>
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/support-icon.svg" 
                    alt="support-icon">
                  </div>
                  <div class="text-fields">
                    <div class="text-1 content-center">
                      {{'on_time'|translate}}
                    </div>
                    <div class="text-1 light-text content-center">
                      {{'support_from_jaano'|translate}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top:32px;">
                <div class="col-12 text-center text-1" style="line-height:22.4px">
                  {{'our_emitras'|translate}}
                </div>
              </div>
              <div class="row">
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/certificate-icon.svg" 
                    alt="customer-icon">
                  </div>
                  <div class="text-fields">
                    <div class="text-1 content-center">
                      {{'certified'|translate}}
                    </div>
                    <div class="text-1 light-text content-center">
                      {{'government'|translate}}
                    </div>
                  </div>
                </div>
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/experienced-icon.svg" 
                    alt="solved-icon">
                  </div>
                  <div class="text-fields">
                    <div class="text-1 content-center">
                      {{'experienced'|translate}}
                    </div>
                    <div class="text-1 light-text content-center">
                      {{'solving_problems'|translate}}
                    </div>
                  </div>
                </div>
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/high-rated-icon.svg" 
                    alt="highly-rated-icon">
                  </div>
                  <div class="text-fields">
                    <div class="text-1 content-center">
                      {{'highly_rated'|translate}}
                    </div>
                    <div class="text-1 light-text content-center">
                      {{'by_their_customers'|translate}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top:32px;">
                <div class="col-3" *ngFor="let emitra of topEmitras">
                  <div class="card rating-card" (click)="showEmitraDetails2(emitra)">
                    <div class="card-body" style="padding:0px;">
                      <div class="floating-certificate">
                        <img src="../../../../assets/images/certificate-icon2.svg" 
                        alt="certificate-icon">
                      </div>
                      <div class="rating-user-img">
                        <img alt="Image" width="38px" height="38px" class="img-circle"
                          [src]="emitra.profile_picture?emitra.profile_picture:
                          '../../../assets/images/new_help_icons/initials-img.svg'"
                          style="border-radius:50%">
                          <span class="initials"
                          *ngIf="!emitra.profile_picture">
                          {{getInitial(emitra.name)}}
                          </span>
                      </div>
                      <div class="ratings">
                        <img src="../../../../assets/images/feedback2.svg" 
                        alt="customer-icon" class="rating-stars-active"
                        style="margin-right:3px"  >
                        <span style="margin-top:2px;">
                          {{emitra.rating}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-16">
                <div class="col-12 text-center">
                  <span class="review-text">
                    {{'review_example'|translate}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lg-show paid-right-side-card"
  *ngIf="isPaidHelp && helpRequestUserName == username && abTestingType == ABTestingType.Old">
    <div class="right-side-card">
      <div class="row">
        <div class="col-12">
          <div class="card side-cards">
            <div class="card-body" style="padding: 2.5px 10px;">
              <div class="row term-and-condition2">
                <div class="col-12">
                  <span class="title">
                    {{'complete_refund'|translate}}
                  </span>
                </div>
                <div class="col-12">
                  <div class="img-icon-1" 
                  style="width:60px; height: 60px;">
                    <img src="../../../../assets/images/refund-icon.svg" 
                    alt="refund-icon">
                  </div>
                </div>
                <div class="condition">
                  {{'term_condition_apply'|translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <app-leftsectionlocalstars class="custom-section-sm-block-hide"
  *ngIf="(!isPaidHelp || helpRequestUserName != username) && abTestingType == ABTestingType.Old ">
  </app-leftsectionlocalstars> -->
  <!-- <app-morecontentforyousection class="custom-section-sm-block-hide" [moreContentForYou]="moreContentForYou"
    (itemselected)="itemselected($event)"
    *ngIf="(!isPaidHelp || helpRequestUserName != username) && abTestingType == ABTestingType.Old">
  </app-morecontentforyousection> -->
    <div class="col-md-12 col-sm-12 mainContainers" 
    *ngIf="isPaidHelp && helpRequestUserName == username">
      <div class="help-request-card" style="padding:initial;">
        <div style="padding:10px;">
          <header>
            <div class="row">
              <div class="col label">
                {{phone_No}}
              </div>
              <div class="col-auto ml-auto label">
                {{request?.timestamp | date: 'short'}}
              </div>
            </div>
          </header>
          <section class="mt-20-16">
            <div class="hr-title">
              {{request?.help_description}}
            </div>
            <div class="hr-username">
              {{request?.help_title}}
            </div>
          </section>
          <footer class="mt-20-16">
            <div class="row">
              <div class="col hr-sub-cat">
                {{subcategory}}
              </div>
              <div class="col-auto ml-auto hr-status">
                <span *ngIf="(hasOffer == 0 && (bidList && bidList.length==0)) && 
                request?.hr_user_status != 'cancel' && !isAssignedEmitra" style="color: #00AF31;">
                  {{'Waiting for helper'|translate}}
                </span>
                <span *ngIf="(hasOffer > 0 || (bidList && bidList.length > 0)) && 
                  request?.hr_user_status != 'cancel' && !isAssignedEmitra" style="color: #00AF31;">
                  {{'Helper available'|translate}}
                </span>
                <span *ngIf="request?.hr_user_status == 'cancel'" style="color: #323233;">
                  {{'Closed_'|translate}}
                </span>
                <span *ngIf="request?.hr_user_status == 'completed'" style="color: #323233;">
                  {{'Closed_'|translate}}
                </span>
                <span *ngIf="isAssignedEmitra && request?.hr_emitra_status != 'closed'" 
                style="color: #FFC135;">
                  {{'In process'|translate}}
                </span>
                <span *ngIf="isAssignedEmitra && request?.hr_emitra_status == 'closed'
                && request?.hr_user_status != 'completed'" 
                 style="color: #00AF31;">
                  {{'Closed'|translate}}
                </span>
              </div>
            </div>
          </footer>
        </div>
        <div class="download-footer" *ngIf="isAssignedEmitra">
          <button class="no-border-btn download-btn" (click)="openPaymentReceipt()">
            <img alt="Image" class="img-18-20"
            src="../../../../assets/images/download-icon.svg">
            {{'download recipt'|translate}}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="isAssignedEmitra && isPaidHelp && helpRequestUserName == username && hrEmitraStatus != 'closed'&& hrUserStatus != 'completed'" 
      class="emitra-detail-card mt-40">
      <div class="row">
        <div class="col-auto" style="display:flex;">
          <div class="user-img-1 content-center" style="margin-right:4px;">
            <img alt="Image"
            [src]="assignedEmitraProfilePicture?assignedEmitraProfilePicture:'../../../assets/images/profile-icon-2.svg'">
          </div>
          <div class="user-details-1">
            <div class="name">{{assignedEmitraName}}</div>
            <div class="location">
              <a [href]="'tel:'+assignedEmitraPhoneNumber">
                {{assignedEmitraPhoneNumber}}
              </a>
            </div>
          </div>
        </div>
        <div class="col-auto ml-auto" style="display:flex;">
          <div class="img-circle-1" style="display:inline-flex; box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);">
            <a [href]="'sms:'+assignedEmitraPhoneNumber+'?body='+textMessage" (click)="contactViaSms()">
              <img src="../../../../assets/images/message-icon-1.svg" alt="message-box" width="24px" height="24px">
            </a>
          </div>
          <div class="img-circle-1 ml-40-8" style="display:inline-flex;background:#304FFE;box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);">
            <a [href]="'tel:'+assignedEmitraPhoneNumber"  (click)="contactViaPhone()">
              <img src="../../../../assets/images/phone-icon.svg" alt="message-box" width="24px" height="24px">
            </a>
          </div>
          <div class="img-circle-1 ml-40-8" style="display:inline-flex;box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);">
            <a aria-label="Chat on WhatsApp"  (click)="contactViaWhatsapp()"
            [href]="'https://wa.me/'+assignedEmitraPhoneNumber+'?text='+textMessage"> 
              <img src="../../../../assets/images/whatsapp-icon-1.svg" alt="message-box" width="24px" height="24px">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-sm-12 mainContainers mt-20-16"
    *ngIf="isAssignedEmitra && isPaidHelp && helpRequestUserName == username && hrEmitraStatus != 'closed'&& hrUserStatus != 'completed'" >
      <div>
        <div class="green-card  ">
          <header>
            {{'Congratulations'|translate}}!
          </header>
          <div class="info">
            {{'You are now connected with'|translate}} {{assignedEmitraName}}, {{'connection_emitra_info'|translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="pull-down" *ngIf="isAssignedEmitra && isPaidHelp && 
    helpRequestUserName == username && hrEmitraStatus != 'closed'&& hrUserStatus != 'completed' &&
    !supportResolve">
      <div class="content-center text-center" *ngIf="!acceptOfferPast5Minutes && !askedForSupport">
        <span class="paid-txt-14-12 text-grey2 w-700">{{acceptOffertimer}}</span>
      </div>
      <div  class="content-center text-center" *ngIf="!acceptOfferPast5Minutes && !askedForSupport">
        <span class="paid-txt-14-12 text-grey2">
          {{'helpers_fails_to_connect1'|translate}} 
          {{'report_here'|translate}}
        </span>
      </div>
      <div class="content-center text-center" *ngIf="acceptOfferPast5Minutes && !askedForSupport">
        <span class="paid-txt-14-12 txt-underline" data-toggle="modal" data-target="#contactEmitra"
        style="margin-right:2px;" (click)="onReport()">
          {{'click_here'|translate}}
        </span>
        <span class="paid-txt-14-12 text-grey2">
          {{'helpers_fails_to_connect2'|translate}}
        </span>
      </div>
      <div class="content-center text-center" *ngIf="askedForSupport">
        <span class="paid-txt-14-12 text-grey2">
          {{'team_working_on'|translate}}
        </span>
      </div>
    </div>
    <div class="cong-card mt-40" *ngIf="isAssignedEmitra && isPaidHelp && helpRequestUserName == username && hrEmitraStatus == 'closed'&& hrUserStatus != 'completed'">
      <div class="header">
        <div class="content-center text-center mt-8">
          <span class="paid-txt-18-16 text-white">{{'Congratulations'|translate}}!</span>
        </div>
        <div class="content-center text-center mt-8">
          <span class="paid-txt-14-12 text-white">{{assignedEmitraName}} {{'solved_request'|translate}}</span>
        </div>
      </div>
      <div class="body">
        <div class="content-center">
          <div class="img-circle-1">
            <img alt="user-img" width="40px" height="40px" style="width:40px !important; height: 40px !important;"
            [src]="assignedEmitraProfilePicture?assignedEmitraProfilePicture:'../../../assets/images/user_icon.svg'">
          </div>
        </div>
        <div class="content-center text-center mt-8">
          <span class="paid-txt-18-16 w-700">{{assignedEmitraName}}</span>
        </div>
        <div class="content-center text-center mt-8">
          <span class="paid-txt-16-14 text-grey-8">{{assignedEmitraPhoneNumber}}</span>
        </div>
        <div class="content-center text-center mt-20-12">
          <span class="paid-txt-16-14 text-grey2">{{'kindly_close_request'|translate}}</span>
        </div>
        <div class="content-center text-center mt-20-12">
          <button class="block-btn blue-btn text-white paid-txt-16-14" (click)="closeRequestNew()">{{'close request'|translate}}</button>
        </div>
      </div>
    </div>
    <div class="card-new mt-40" *ngIf="isAssignedEmitra && isPaidHelp && helpRequestUserName == username && hrEmitraStatus == 'closed'&& hrUserStatus == 'completed'">
      <div class="header">
        <div class="content-center text-center" *ngIf="!userReview.hasReview">
          <span class="paid-txt-18-16 w-500">{{'how_do_you_rate_service'|translate}}?</span>
        </div>
        <div class="content-center text-center" *ngIf="userReview.hasReview && !userReview.hasMessage">
          <span class="paid-txt-18-16 w-500">{{'thanks_for_rating'|translate}}</span>
        </div>
        <div class="content-center text-center" *ngIf="userReview.hasReview && userReview.hasMessage">
          <span class="paid-txt-18-16 w-500">{{'thanks_for_review'|translate}}</span>
        </div>
      </div>
      <div class="body">
        <div class="content-center text-center" *ngIf="!userReview.hasReview">
          <span class="paid-txt-16-14 text-grey2">{{'love_feedback'|translate}}</span>
        </div>
        <div class="content-center text-center mb-20-12" *ngIf="!userReview.hasReview">
          <span class="paid-txt-16-14 text-grey2">{{'input_helps_improve'|translate}}</span>
        </div>
        <div class="content-center ">
          <div class="img-circle-1">
            <img alt="user-img" width="40px" height="40px" style="width:40px !important; height: 40px !important;"
            [src]="assignedEmitraProfilePicture?assignedEmitraProfilePicture:'../../../assets/images/user_icon.svg'">
          </div>
        </div>
        <div class="content-center text-center mt-8">
          <span class="paid-txt-18-16 w-700" style="text-transform: capitalize;">{{assignedEmitraName}}</span>
        </div>
        <div class="content-justify text-center mt-20-12" *ngIf="!userReview.hasReview">

          <img src="../../../../assets/images/inactive-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          (click)="ratingStars(1)"  *ngIf="userReview.rating < 1">
          <img src="../../../../assets/images/active-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          (click)="ratingStars(1)"  *ngIf="userReview.rating >= 1">
          <img src="../../../../assets/images/inactive-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          (click)="ratingStars(2)"  *ngIf="userReview.rating < 2">
          <img src="../../../../assets/images/active-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          (click)="ratingStars(2)"  *ngIf="userReview.rating >= 2">
          <img src="../../../../assets/images/inactive-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          (click)="ratingStars(3)"  *ngIf="userReview.rating < 3">
          <img src="../../../../assets/images/active-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          (click)="ratingStars(3)"  *ngIf="userReview.rating >= 3">
          <img src="../../../../assets/images/inactive-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          (click)="ratingStars(4)"  *ngIf="ratingStarCount < 4">
          <img src="../../../../assets/images/active-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          (click)="ratingStars(4)"  *ngIf="userReview.rating >= 4">
          <img src="../../../../assets/images/inactive-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          (click)="ratingStars(5)"  *ngIf="userReview.rating < 5">
          <img src="../../../../assets/images/active-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          (click)="ratingStars(5)"  *ngIf="userReview.rating >= 5">
        </div>
        <div class="content-justify text-center mt-20-12" *ngIf="userReview.hasReview">
          <img src="../../../../assets/images/inactive-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          *ngIf="userReview.rating < 1">
          <img src="../../../../assets/images/active-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          *ngIf="userReview.rating >= 1">
          <img src="../../../../assets/images/inactive-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          *ngIf="userReview.rating < 2">
          <img src="../../../../assets/images/active-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          *ngIf="userReview.rating >= 2">
          <img src="../../../../assets/images/inactive-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          *ngIf="userReview.rating < 3">
          <img src="../../../../assets/images/active-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          *ngIf="userReview.rating >= 3">
          <img src="../../../../assets/images/inactive-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          *ngIf="userReview.rating < 4">
          <img src="../../../../assets/images/active-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          *ngIf="userReview.rating >= 4">
          <img src="../../../../assets/images/inactive-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          *ngIf="userReview.rating < 5">
          <img src="../../../../assets/images/active-rating-icon.svg" alt="rating-active-star" width="40px" height="40px" style="cursor:pointer" 
          *ngIf="userReview.rating >= 5">
        </div>
        <div class="content-center text-center mt-20-12" *ngIf="!userReview.hasReview">
          <textarea placeholder="{{'Write your feedback here . . .'|translate}}"
          [(ngModel)] = "userReview.message" class="paid-text-area paid-txt-16-14">
          </textarea>
        </div>
        <div class="content-center text-center mt-20-12" *ngIf="userReview.hasMessage">
          <span class="paid-txt-16-14">" {{userReview.message}}"</span>
        </div>
        <div class="content-center text-center mt-20-12" *ngIf="!userReview.hasReview">
          <button class="block-btn blue-btn text-white paid-txt-16-14" 
          (click)="onClickReviewEmitra()" [disabled]="userReview.rating == 0">{{'Submit'|translate}}</button>
        </div>
      </div>
    </div>
  <div class="popup-card green-card top-popup" 
  *ngIf="!isAssignedEmitra && isPaidHelp && hrRefreshed && hasOffer ==0
  && (bidList && bidList.length == 0) && request?.hr_user_status != 'cancel' && thankyouCardTimer >0">
    <div class="title">
      {{'Be calm!'|translate}}
    </div>
    <div class="message">
      {{'offer_will_be_visible'|translate}}
    </div>
  </div>
  <div class="center-card" *ngIf="!isAssignedEmitra && isPaidHelp && hasOffer == 0 && (bidList && bidList.length == 0)
  && request?.hr_user_status != 'cancel'">
    <div class="container p-40">
      <div class="help-request-card" *ngIf="latestHelpid == helpRequestId && thankyouCardTimer >0">
        <header>
          <div class="row">
            <div class="col-12 text-center label-2">
              {{'Your paid request is under process'|translate}}
            </div>
          </div>
        </header>
        <section class="mt-20-16">
          <div class="hr-timer">
            {{thankyouCardTimerText}}
          </div>
          <div class="hr-timer-text mt--4">
            {{'Finding available helper'|translate}}
          </div>
        </section>
        <footer class="mt-20-16 container">
          <div class="row">
            <div class="col-12 emitra-info">
             {{timerMessage2}}
            </div>
          </div>
        </footer>
      </div>
      <div class="failed-card popup-card" style="background:#FFFFFF;" *ngIf="(latestHelpid != helpRequestId || thankyouCardTimer <=0)
      && !hideMainPopup">
        <header>
          {{'We are sorry'|translate}}!
        </header>
        <section style="background: rgba(255, 192, 0, 0.10);">
          <div class="info-1">
            {{'helpers_busy'|translate}}
          </div>
          <div class="info-2">
            {{'notify_via_sms'|translate}}
          </div>
        </section>
        <div class="info-4">
          {{'You can find your request in your'|translate}}
           <span style="color:#304FFE;text-decoration-line: underline;cursor:pointer"
           (click)="navigateToProfile(request.username);disableFloatState()">Profile</span>
        </div>
        <footer>
          <button class="action-btn" style="width: 160px;" (click)="goToHome()">
            {{'Okay'|translate}}
          </button>
        </footer>
      </div>
      <div class="popup-card left-right mt-10-12" *ngIf="latestHelpid == helpRequestId && reloadTimerType == 'thankyouCard'">
        <div class="sliding slider">
          <div class="circle">
            1
          </div>
          <div class="message">
            {{'Wait for helper'|translate}}
          </div>
          <div class="dash-line">
            <img src="../../../../assets/images/dash-line-icon.svg" alt="dash-line">
          </div>
          <div class="circle">
            2
          </div>
          <div class="message">
            {{'Accept offer and pay'|translate}}
          </div>
          <div class="dash-line">
            <img src="../../../../assets/images/dash-line-icon.svg" alt="dash-line">
          </div>
          <div class="circle">
            3
          </div>
          <div class="message">
            {{'Connect with helper'|translate}}
          </div>
          <div class="dash-line">
            <img src="../../../../assets/images/dash-line-icon.svg" alt="dash-line">
          </div>
          <div class="circle">
            1
          </div>
          <div class="message">
            {{'Wait for helper'|translate}}
          </div>
          <div class="dash-line">
            <img src="../../../../assets/images/dash-line-icon.svg" alt="dash-line">
          </div>
          <div class="circle">
            2
          </div>
          <div class="message">
            {{'Accept offer and pay'|translate}}
          </div>
          <div class="dash-line">
            <img src="../../../../assets/images/dash-line-icon.svg" alt="dash-line">
          </div>
          <div class="circle">
            3
          </div>
          <div class="message">
            {{'Connect with helper'|translate}}
          </div>
          <div class="dash-line">
            <img src="../../../../assets/images/dash-line-icon.svg" alt="dash-line">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="center-card scroll-center" *ngIf="!isAssignedEmitra && isPaidHelp && (hasOffer > 0 || (bidList && bidList.length > 0))
    && request?.hr_user_status != 'cancel'">
    <div class="container p-40" style="margin: auto;">
      <div class="offer-wrapper">
        <div class="jaano-recommended-layer">
          <div class="col-auto p-0">
            <span class="rank">#1</span>
            <span class="label">{{'Jaano recommended'|translate}}</span>
          </div>
          <div class="col-auto ml-auto p-0">
            <div class="star-img">
              <img src="../../../../assets/images/new-images/rating-icon.svg" alt="rating-icon" >
              <div class="label-2">{{'Best from the list'|translate}}</div>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let bid of bidList;let index = index">
          <div [ngClass]="{'p-16-16-16-0':index != 0,'margin-top-card':index == 0}" *ngIf="viewAllOffer || index<1">
            <div class="offer-card" [ngClass]="{'top-card':index == 0,'border-8-0':viewAllOffer && index ==0}">
              <div class="row">
                <div class="col-auto pr-0" style="display:flex;">
                  <div class="user-img">
                    <img alt="Image"
                    [src]="bid.profile_picture?bid.profile_picture:'../../../assets/images/profile-icon-2.svg'">
                  </div>
                  <div class="user-details">
                    <div class="name">{{bid.name}}</div>
                    <div class="location">{{bid.num_hr_solved}} {{'requests solved'|translate}} </div>
                  </div>
                </div>
                <div class="col-auto ml-auto" *ngIf="bidList.length > 1 && index != 0">
                  <button class="solid-btn width-200-140 p-0-10" (click)="acceptOffer(bid)" style="margin-top: initial;">
                    {{'connectAt'|translate}} ₹{{bid.price}}
                  </button>
                </div>
                <div class="col-auto ml-auto" *ngIf="bidList.length == 1 || index == 0">
                  <div class="rating">
                    <img src="../../../../assets/images/new-images/rating-icon.svg" alt="rating-icon"
                    width="20px" height="20px"  >
                    <span *ngIf="bid.rating_count && bid.rating_count > 20">{{bid.rating|number:'0.1-1'}}</span>
                    <span *ngIf="!bid.rating_count || (bid.rating_count && bid.rating_count <= 20)">5.0</span>
                  </div>
                  <div class="reviews">
                    <span *ngIf="bid.rating_count && bid.rating_count > 20">{{bid.rating_count}} Reviews</span>
                  </div>
                </div>
              </div>
              <!-- <div class="row" *ngIf="bidList.length == 1 || index == 0">
                <div class="col-12 offer-msg" 
                [ngClass]="{'single-line':!bid.seeMore, 'multi-line':bid.seeMore}">
                  {{bid.msg}}
                  <div *ngIf="!bid.seeMore" (click)="bid.seeMore= !bid.seeMore"
                  class="read-more">
                    {{'read_more'|translate}}
                  </div>
                  <span *ngIf="bid.seeMore" (click)="bid.seeMore= !bid.seeMore"
                  style="color: #304FFE;cursor:pointer">
                    {{'read_less'|translate}}
                  </span>
                </div>
              </div> -->
              <div class="row" *ngIf="bidList.length == 1 || index == 0">
                <div class="col-12">
                  <button class="solid-btn" (click)="acceptOffer(bid)">
                    {{'connectAt'|translate}} ₹{{bid.price}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf="bidList && bidList.length>1" class="view-more-offers" 
          [ngClass]="{'offer-border-top':viewAllOffer}">
          <div class="col-auto label p-0"  *ngIf="!viewAllOffer">
            {{'Other helpers starting from'|translate}}{{bidList[1].price}}
          </div>
          <div class="col-auto ml-auto p-0" *ngIf="!viewAllOffer">
            <button class="btn-1" (click)="viewAllOffer = !viewAllOffer;">
              {{'View'|translate}}
            </button>
          </div>
          <div class="col-auto p-0 text-center" *ngIf="viewAllOffer">
            <button class="btn-1" (click)="viewAllOffer = !viewAllOffer;viewMoreOfferEvent()" >
              {{'Hide'|translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isAssignedEmitra && isPaidHelp">
    <div class="failed-card mt-20-24" *ngIf="hasOffer == 0 && (bidList && bidList.length == 0) && request?.hr_user_status == 'cancel'">
      <header>
        {{'We are sorry'|translate}}!
      </header>
      <section>
        <div class="info-1">
          {{'Our helpers failed to connect with you.'|translate}}
        </div>
        <div class="info-2">
          {{'You can try creating the request again.'|translate}}
        </div>
      </section>
      <footer>
        <button class="action-btn" (click)="createHelpRequestFromExistingOne()">
          {{'Create same request again'|translate}}
        </button>
      </footer>
    </div>
    <div class="failed-card mt-20-24" *ngIf="(hasOffer > 0 || (bidList && bidList.length > 0)) && request?.hr_user_status == 'cancel'">
      <header>
        {{'Sorry'|translate}}!
      </header>
      <section>
        <div class="info-1">
          {{'The offer sent by our helper has been expired.'|translate}}
        </div>
        <div class="info-2">
          {{'You can try creating the request again.'|translate}}
        </div>
      </section>
      <footer>
        <button class="action-btn" (click)="createHelpRequestFromExistingOne()">
          {{'Create same request again'|translate}}
        </button>
      </footer>
    </div>
  </ng-container>
  <div class="bottom-popup"  [hidden]="!(!isAssignedEmitra && isPaidHelp && (hasOffer >0 || (bidList && bidList.length > 0)))
    || request?.hr_user_status == 'cancel'">
    <div class="container p-40">
      <div class="popup-card-2 pd-10-8">
        <div id="emitraCarousel" class="carousel slide" data-interval="10000">
          <ol class="carousel-indicators">
            <li data-target="#emitraCarousel" data-slide-to="0" [ngClass]="{'active':offerSlider == 1}"></li>
            <li data-target="#emitraCarousel" data-slide-to="1" [ngClass]="{'active':offerSlider == 2}"></li>
          </ol>
          <div class="carousel-inner h-92-80" >
            <div class="carousel-item" [ngClass]="{'active':offerSlider == 1}">
              <div class="carosuel-card">
                <section style="background: transparent; margin-top: initial; padding: 0px;">
                  <div class="img-circle" style="background: rgba(0, 175, 49, 0.10);">
                    <img src="../../../../assets/images/refund-icon-3.svg" alt="refund-icon" width="30px" height="30px" >
                  </div>
                  <div class="info-1 mt--4">{{'We offer 100% refund*'|translate}}</div>
                  <div class="info-2">{{'If we fail to serve your request.'|translate}}</div>
                </section>
              </div>
            </div>
            <div class="carousel-item "  [ngClass]="{'active':offerSlider == 2}">
              <div class="carosuel-card">
                <section style="margin-top:0px; background:transparent;padding:0px;">
                  <div class="info-1">{{'Our helpers are'|translate}}</div>
                  <div class="row mt-8-4">
                    <div class="col p-0">
                      <div class="img-circle" style="background:rgba(0, 175, 49, 0.10);">
                        <img src="../../../../assets/images/certificate-icon-2.svg" alt="certificate" width="30px" height="30px" >
                      </div>
                      <div class="info-3">
                        {{'govt_certified'|translate}}
                      </div>
                    </div>
                    <div class="col p-0">
                      <div class="img-circle" style="background:rgba(0, 175, 49, 0.10);">
                        <img src="../../../../assets/images/experience-icon.svg" alt="certificate" width="30px" height="30px" >
                      </div>
                      <div class="info-3">
                        {{'experienced'|translate}}
                      </div>
                    </div>
                    <div class="col p-0">
                      <div class="img-circle" style="background:rgba(0, 175, 49, 0.10);">
                        <img src="../../../../assets/images/highgly-rated-icon.svg" alt="certificate" width="30px" height="30px" >
                      </div>
                      <div class="info-3">
                        {{'highly_rated'|translate}}
                      </div>
                    </div>
                  </div>   
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-popup"  [hidden]="!(!isAssignedEmitra && isPaidHelp && hasOffer ==0 && (bidList && bidList.length == 0)
  && request?.hr_user_status != 'cancel')">
    <div class="container p-40">
      <div class="offer-card">
        <div id="slidingCardReview" class="carousel slide" data-interval="10000">
            <ol class="carousel-indicators">
              <li data-target="#slidingCardReview" data-slide-to="0"[ngClass]="{'active':reviewSlider == 1}"></li>
              <li data-target="#slidingCardReview" data-slide-to="1" [ngClass]="{'active':reviewSlider == 2}"></li>
              <li data-target="#slidingCardReview" data-slide-to="2" [ngClass]="{'active':reviewSlider == 3}"></li>
            </ol>
            <div class="carousel-inner" *ngIf="customerReviews2 && customerReviews2.length>0">
              <ng-container *ngFor="let item of customerReviews2;let i = index">
                <div class="carousel-item" [ngClass]="{'active':reviewSlider == i+1}">
                  <div class="carosuel-card">
                      <div class="row">
                        <div class="col-auto" style="display:flex;">
                          <div class="user-img">
                            <img alt="user-img" [src]="item.userProfilePicture" *ngIf="item.userProfilePicture" >
                          </div>
                          <div class="user-details">
                            <div class="name">{{item.userid}}</div>
                            <div class="location">{{item.location}}</div>
                          </div>
                        </div>
                        <div class="col-auto ml-auto">
                          <div class="rating">
                            <img src="../../../../assets/images/new-images/rating-icon.svg" alt="rating" *ngIf="item.rating>0" >
                            <img src="../../../../assets/images/new-images/rating-icon.svg" alt="rating" *ngIf="item.rating>1" >
                            <img src="../../../../assets/images/new-images/rating-icon.svg" alt="rating" *ngIf="item.rating>2" >
                            <img src="../../../../assets/images/new-images/rating-icon.svg" alt="rating" *ngIf="item.rating>3" >
                            <img src="../../../../assets/images/new-images/rating-icon.svg" alt="rating" *ngIf="item.rating>4" >
                          </div>
                        </div>
                      </div>
                      <div class="comment">
                          <span>
                            " {{item.comment}} "
                          </span>
                      </div>
                  </div>
                </div>
              </ng-container>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 mainContainers" id="firstmain">
    <!-- <div class="row" style="background-color: #fff; margin: 10px 0; padding: 12px; border-radius: 4px;
    margin-top:24px;box-shadow: 0px 0px 4px rgb(0,0,0,0.25); margin-bottom: 0px;"
    *ngIf="abTestingType == ABTestingType.Old">
      <div class="col-12 p-0">
        <div class="col-12 p-0 sm-show" 
        style="margin-bottom: 10px; margin-top:10px;"
        *ngIf="isPaidHelp && helpRequestUserName == username && !floatingState
        && abTestingType == ABTestingType.Old">
          <div class="card d-block w-100 sliding-card"
            *ngIf="slideScreen == 1">
            <div class="card-body" style="padding: 2.5px 10px;">
              <div class="row">
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/customer-icon.svg" 
                    alt="customer-icon">
                  </div>
                  <div class="text-fields">
                    <div class="text-1 mt-4-">
                      10000+
                    </div>
                    <div class="text-1 light-text">
                      {{'satisfied customers'|translate}}
                    </div>
                  </div>
                </div>
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/solved-icon.svg" 
                    alt="solved-icon">
                  </div>
                  <div class="text-fields mt-4-">
                    <div class="text-1">
                      500+
                    </div>
                    <div class="text-1 light-text">
                      {{'solved_everyday'|translate}}
                    </div>
                  </div>
                </div>
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/support-icon.svg" 
                    alt="support-icon">
                  </div>
                  <div class="text-fields mt-4-">
                    <div class="text-1">
                      {{'on_time'|translate}}
                    </div>
                    <div class="text-1 light-text">
                      {{'support_from_jaano'|translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card d-block w-100 sliding-card"
          *ngIf="slideScreen == 2">
            <div class="card-body" style="padding: 2.5px 10px;">
              <div class="row">
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/certificate-icon.svg" 
                    alt="customer-icon">
                  </div>
                  <div class="text-fields mt-4-">
                    <div class="text-1">
                      {{'certified'|translate}}
                    </div>
                    <div class="text-1 light-text">
                      {{'government'|translate}}
                    </div>
                  </div>
                </div>
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/experienced-icon.svg" 
                    alt="solved-icon">
                  </div>
                  <div class="text-fields mt-4-">
                    <div class="text-1">
                      {{'experienced'|translate}}
                    </div>
                    <div class="text-1 light-text">
                      {{'solving_problems'|translate}}
                    </div>
                  </div>
                </div>
                <div class="col-4 p-0">
                  <div class="img-icon-1">
                    <img src="../../../../assets/images/high-rated-icon.svg" 
                    alt="support-icon">
                  </div>
                  <div class="text-fields mt-4-">
                    <div class="text-1">
                      {{'highly_rated'|translate}}
                    </div>
                    <div class="text-1 light-text">
                      {{'by_their_customers'|translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card d-block w-100 sliding-card"
          *ngIf="slideScreen == 3">
            <div class="card-body" style="padding:0px;">
              <div class="row">
                <ng-container  *ngFor="let emitra of topEmitras; let i = index">
                  <div class="col-3" *ngIf="i==0">
                    <div class="card rating-card" (click)="showEmitraDetails2(emitra)">
                      <div class="card-body" style="padding:0px;">
                        <div class="floating-certificate">
                          <img src="../../../../assets/images/certificate-icon2.svg" 
                          alt="certificate-icon">
                        </div>
                        <div class="rating-user-img">
                          <img alt="Image" width="38px" height="38px" class="img-circle"
                          [src]="emitra.profile_picture?emitra.profile_picture:
                          '../../../assets/images/new_help_icons/initials-img.svg'"
                          style="border-radius:50%">
                          <span class="initials"
                          *ngIf="!emitra.profile_picture">
                          {{getInitial(emitra.name)}}
                          </span>
                        </div>
                        <div class="ratings">
                          <img src="../../../../assets/images/feedback2.svg" 
                          alt="customer-icon" class="rating-stars-active"
                          style="margin-right:3px"  >
                          4.5
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="col-9 text-center pl-0">
                  <span class="review-text" style="color: #323233;
                  font-size: 12px;">
                  {{'review_example'|translate}}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card d-block w-100 refund-card sliding-card"
          *ngIf="slideScreen == 4">
            <div class="card-body term-and-condition2"
             style="padding: 0px;">
              <div class="row">
                <div class="col-12">
                  <span class="title">
                    {{'complete_refund'|translate}}
                  </span>
                </div>
                <div class="col-12">
                  <div class="img-icon-1" >
                    <img src="../../../../assets/images/refund-icon.svg" 
                    alt="refund-icon" width="14px;" height="17px;">
                  </div>
                </div>
                <div class="condition">
                  {{'term_condition_apply'|translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <div class="card" style="padding: 6px 12px;
          border: 1px solid rgba(50, 50, 51, 0.6);" *ngIf="abTestingType == ABTestingType.Old">
            <div class="col-12 p-0">
              <div class="row profile_header_conatiner" style="margin: 0;">
                <div class="pd0" style="flex-grow: 1; max-width: calc( 100% - 36px)">
                  <span class="user-name"  (click)="navigateToProfile(request.username)">@{{request?.username | translate}}</span>
                  <span class="user-detail">{{request?.timestamp | date: 'short'}}.
                    <span *ngIf="request?.mandalcode > -1 ">{{request?.mandalname}},</span>
                    {{request?.dtname | translate}}
                  </span>
                </div>
                <div class="pd0 sm-align-start" style="position: absolute;
                right: 0px;top:1px;">
                  <div class="dropdown pl-2">
                    <span class="ellipsis_container">
                      <span class="sm-wid-20 ellipsis_options"></span>
                    </span>
                    <div class="dropdown-content wid-125px right-0">
                      <div class="text-left text-dropdown cursor-p"
                      *ngIf="helpRequestUserName != username">
                        <div (click)="reportPost(request?.helpid)">
                          <img src="../../../../assets/images/report-icon.svg" alt="report" class="sm-ht-16">
                          <span class="ml-2"> {{'report' | translate}}</span>
                        </div>
                      </div>
                      <div class="text-left text-dropdown cursor-p" *ngIf="request?.username == username">
                        <div (click)="$event.stopPropagation();navigateToEditHelpRequestById(request?.helpid)">
                          <img src="../../../../assets/images/profile-editPen.svg" alt="report" class="sm-ht-16">
                          <span class="ml-2"> {{'Edit' | translate}}</span>
                        </div>
                      </div>
                      <div class="text-left text-dropdown cursor-p"
                        *ngIf="request?.username == username && request?.comments_count >= 0">
                        <div (click)="$event.stopPropagation();deleteHelpRequest(request?.helpid,request?.username)">
                          <img src="../../../../assets/images/delete-icon.svg" alt="report" class="sm-ht-16">
                          <span class="ml-2"> {{'delete' | translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 pt-2 pb-2">
              <div class="col-12">
                <div class="row">
                  <div class="card-text post-para"
                    *ngIf="request?.help_title == '{}' || request?.help_title == '' || request?.help_title == null ">
                    {{'Help Request for' | translate}}&nbsp;{{requestTitle}}</div>
                  <div class="card-text post-para" style="white-space: pre-line !important;"
                    *ngIf="request?.help_title != '{}' && request?.help_title != '' && request?.help_title != null "
                    [innerHTML]="request?.help_title"></div>
                </div>
                <div class="row" style="white-space: pre-wrap;">
                  <div class="card-para"
                    *ngIf="request && request?.help_description != '{}' && request?.help_description != '' &&  request?.help_description != null ">
                    <p class="m-0" [innerHTML]="request?.help_description"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <iframe title="" class="video d-block w-100" *ngIf="desp_video" [src]="desp_video" width="400"
                height="250" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"
                allowFullScreen="true"></iframe>
            </div>
            <div class="col-12 mb-2 flex-center padlr-8" *ngIf="mediaArray.length > 0">
              <ngu-carousel class="carousel" #myCarousel [inputs]="carouselTile" [dataSource]="mediaArray">
                <ngu-tile class="carousel-inner" *nguCarouselDef="let banner; let i = index">
                  <div class="carousel-item">
                    <div appImageFullScreen class="d-block  w-100 imageContainer"
                      *ngIf="banner.type =='picture'" [style.backgroundImage]="'url(' + banner.link + ')'"></div>
                    <video class="video d-block w-100" *ngIf="banner.type =='video'" width="245" height="250" controls>
                      <source [src]="banner.link" type="video/mp4">
                    </video>
                    <ngx-circular-player class="d-block audio-div" *ngIf="banner.type =='audio'" [source]="banner.link"
                      [radius]="120" [stroke]="20" [innerStroke]="2" strokeColor="#003DB3" progressStrokeColor="#C4C4C4"
                      innerStrokeColor="#14377b"></ngx-circular-player>
                  </div>
                </ngu-tile>
                <button NguCarouselPrev class="carousel-control-prev" [style.opacity]="myCarousel.isFirst ? 0:1">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                </button>
                <button NguCarouselNext class="carousel-control-next" [style.opacity]="myCarousel.isLast ? 0:1">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                </button>
                <ul class="myPoint" NguCarouselPoint *ngIf="mediaArray.length > 1">
                  <li *ngFor="let i of myCarousel.pointNumbers; let j = index"
                    [class.active]="j==myCarousel.activePoint" (click)="myCarousel.moveTo(j)"
                    [style.background]="'url(' + mediaArray[j] + ')'"></li>
                </ul>
              </ngu-carousel>
            </div>

            <div class="col-12 p-0">
              <div class="pd0 float-left">
                <div class="closed_tag d-inline-block align-top mr-2"
                  *ngIf="request?.req_status == '0'  && !request?.is_standard_hr
                  && !(isAssignedEmitra)">{{'Closed' | translate}}
                  <span *ngIf="request?.closed_by == 'admin'">&nbsp;{{'by_moderator' | translate}}</span>
                </div>
                <div class="open_tag d-inline-block align-top mr-2"
                  *ngIf="request?.req_status == '1' && !request?.is_standard_hr
                  && !(isAssignedEmitra)">
                  <img src="../../../../assets/images/new_help_icons/help_open.svg" alt="open-help" />
                  {{'open' | translate}}
                </div>
                <div class="pending_tag d-inline-block align-top mr-2"
                  *ngIf="isAssignedEmitra && hrUserStatus != 'completed'">
                  <img src="../../../../assets/images/new_help_icons/pending-icon.svg" alt="pending-help"  />
                  {{'processing' | translate|titlecase}}
                </div>
                <div class="open_tag d-inline-block align-top mr-2"
                  *ngIf="hrEmitraStatus == 'closed' && hrUserStatus == 'completed'">
                  <img src="../../../../assets/images/tick_circle.svg"  alt="completed-help"/>
                  {{'completed' | translate|titlecase}}
                </div>

                <div class="category-box d-inline-block">
                  <div class="cat-box mr-2"
                    *ngIf="!(request?.sub_category != null && request?.sub_category != undefined && request?.sub_category !='0' && subcategory)">
                    <div (click)="goToFeedPageWithCatSubCat(request?.category, undefined)"
                      *ngFor="let req of getCategoryArrayByCategoryId(request?.category,request?.sub_category)">
                      <img height="17" src="{{req.cat_icon}}" alt="Image">
                      <div class="category-name ml-1"> {{req.CatlangKey | translate}}</div>
                    </div>
                  </div>
                  <div class="cat-box mr-2"
                    (click)="goToFeedPageWithCatSubCat(request?.category, request?.sub_category)"
                    *ngIf="request?.sub_category != null && request?.sub_category != undefined && request?.sub_category !='0' && subcategory">
                    <div class="icon-background-page2-selected subheading3-selected subheading3">
                      {{subcategory}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="actions-btn float-right">
                <div class="d-inline post-comment-wrapper" style="cursor: pointer;" (click)="markUserSupport(request)">
                  <span class="action_buttons_container2">
                    <img height="20"
                      [src]="request?.is_support == 0 ?'../../../../assets/images/home-images/supporter-icon.svg':'../../../../assets/images/home-images/selected-suppoter-icon.svg'"
                      alt="Support">
                  </span>
                  <span [ngClass]="{'support-font': (request?.tot_support > 0)}">{{request?.tot_support == -1? 0:
                    request?.tot_support}}</span>
                </div>
                <div class="d-inline post-comment-wrapper">
                  <span class="action_buttons_container2">
                    <img height="16"
                      [src]="request?.comments_count > 0 ?'../../../../assets/images/home-images/selected-suggest-icon.svg':'../../../../assets/images/home-images/suggestion-icon.svg'"
                      alt="suggestion-icon">
                  </span>
                  <span [ngClass]="{'support-font': (request?.comments_count > 0)}">
                    {{request?.comments_count == -1 ? 0: request?.comments_count}}&nbsp;</span>
                </div>
                <div class="d-inline pd0 post-comment-wrapper">
                  <span class="icon_size justify-center action_buttons_container2" (click)="share('whatsapp', request)">
                    <img class="ht-20" src="../../../../assets/images/whatsapp.svg" alt="share">
                  </span>
                </div>
              </div>
            </div>

          </div>

          <ng-container *ngIf="helpRequestUserName == username && isPaidHelp && !isConvertedToFree">
            <div class="floating-card"
            (click)="disableFloatState()"
            [ngClass]="{'normal-card':!floatingState, 
            'bottom-0':(showEmitra || showOffers || showOffers2) }" 
            *ngIf="!isAssignedEmitra && (!paymentPending && !paymentFailed)  && abTestingType == ABTestingType.Old" >
              <div class="container p-40">
                <div class="card-1" [ngClass]="{'border-8':(!showEmitra && !showOffers && !showOffers2
                && floatingState)}"
                (click)="$event.stopPropagation(); floatingState = true">
                <div class="row">
                  <div class="col-12 content-center mobile-show">
                    <button (dragstart)="onDragStart($event)" draggable="true"
                    id="drag1"
                    (drop)="onDragEnd($event)"
                    (dragend)="onDragEnd($event)" class="transparent-btn">
                      <img src="../../../../assets/images/drag-icon.svg" alt="drag-icon"
                      width="80px" height="4px" />
                    </button>
                  </div>
                </div>
                  <div class="card thank-you-card-2"
                  *ngIf = "!isAssignedEmitra && isPaidHelp">
                    <div class="col-12" style="position:relative">
                      <div class="move-text-top" style="width: 100%;"
                      [ngClass]="{'move-text-top-animation':showThankYouNote}">
                        <div class="text-1 content-center">
                          {{'thanks_for_request_paid_help'| translate}} 
                        </div>
                      </div>
                    </div>
                    <div class="col-12" style="margin-top:8px;">
                      <div class="row justify-content-center padding-15-55"
                      [ngClass]="{'animation-2':showThankYouNote}">
                        <div class="col-auto" style="width:40px; height:40px;justify-content: center;
                        align-items: center;
                        display: flex;
                        padding: 0px;">
                          <div class="circular-icon-2 content-center" style="margin-left:auto;">
                            <img src="../../../../assets/images/complete-icon.svg" alt="complete"
                             width="17.31px" height="11.31px"
                             *ngIf="!showThankYouNote" />
                            <svg class="animated-check" viewBox="0 0 24 24"
                            *ngIf="showThankYouNote">
                              <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
                          </div>
                        </div>
                        <div class="col" style="justify-content: center;
                        align-items: center;
                        display: flex;
                        padding: 0px 4px;">
                          <img src="../../../../assets/images/dash-line.svg" alt="complete"
                          style="width: 100%;" class="mobile-show" />
                          <img src="../../../../assets/images/dash-line-2.svg" alt="complete"
                           class="non-mobile-show" style="width: 100%;" />
                        </div>
                        <div class="col-auto" style="width:40px; height:40px;justify-content: center;
                        align-items: center;
                        display: flex;
                        padding: 0px;">
                          <div class="circular-icon-2 content-center timer" [ngClass]="{'offers-card':hasBids}">
                            <ng-container *ngIf="hasBids">
                              {{bidList.length}}
                            </ng-container>
                            <ng-container *ngIf="!hasBids && reloadTimerType == 'thankyouCard'">
                              {{thankyouCardTimerText}}
                            </ng-container>
                            <ng-container *ngIf="!hasBids && reloadTimerType != 'thankyouCard'">
                              <img src="../../../../assets/images/timer-icon.gif" alt="complete"
                               width="16px" height="16px" />
                            </ng-container>
                          </div>
                        </div>
                        <div class="col" style="justify-content: center;
                        align-items: center;
                        display: flex;
                        padding: 0px 4px;"  [ngClass]="{'light-fields':!hasBids}">
                          <img src="../../../../assets/images/dash-line.svg" alt="complete"
                          style="width: 100%;" class="mobile-show" />
                          <img src="../../../../assets/images/dash-line-2.svg" alt="complete"
                           class="non-mobile-show" style="width: 100%;" />
                        </div>
                        <div class="col-auto" style="width:40px; height:40px;justify-content: center;
                        align-items: center;
                        display: flex;
                        padding: 0px;"
                        [ngClass]="{'light-fields':!hasBids}">
                          <div class="circular-icon-2 content-center">
                            <img src="../../../../assets/images/help-type-icon.svg" alt="complete"
                            width="20px" height="20px" />
                          </div>
                        </div>
                      </div>
                      <div class="row" 
                      [ngClass]="{'animation-2':showThankYouNote}"
                      style="justify-content:space-between;">
                        <div class="col-auto width-sm-55 text-2 p-0">
                          {{'request_created'|translate}}
                        </div>
                        <div class="col">
                        </div>
                        <div class="col-auto width-sm-62 text-2 p-0 pl-sm-8px">
                          <ng-container  *ngIf="hasBids">
                            {{'offer_received'|translate}}
                          </ng-container>
                          <ng-container  *ngIf="!hasBids">
                            {{'waiting_for_emitra'|translate}}
                          </ng-container>
                        </div>
                        <div class="col">
                        </div>
                        <div class="col-auto width-sm-70 text-2 p-0" 
                        [ngClass]="{'light-fields':!hasBids}">
                              {{'waiting_for_payment'|translate}}
                        </div>
                      </div>
                      <div class="sliding-left" *ngIf="showThankYouNote">
                        <div class="row padding-15-55">
                          <div class="col-auto" style="width:40px; height:40px;justify-content: center;
                          align-items: center;
                          display: flex;
                          padding: 0px;">
                            <div class="circular-icon-2 content-center" style="margin-left:auto;">
                              <svg class="animated-check" viewBox="0 0 24 24">
                               <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
                            </div>
                          </div>
                        </div>
                        <div class="row" 
                        style="justify-content:space-between;height: 19px;">
                          <div class="col-auto width-sm-55 text-2 p-0">
                            {{'request_created'|translate}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 p-0">
                      <div class="footer-text mt-8" *ngIf="!hasBids"
                      [ngClass]="{'animation-2':showThankYouNote}">
                        <ng-container  *ngIf="reloadTimerType == 'thankyouCard'">
                          {{timerMessage}}
                        </ng-container>
                        <ng-container  *ngIf="reloadTimerType != 'thankyouCard'">
                          {{'thankyou_note_4'|translate}}
                        </ng-container>
                      </div>
                      <div class="footer-text mt-8" *ngIf="hasBids"
                      [ngClass]="{'animation-2':showThankYouNote}">
                        <span *ngIf="message1">
                          {{'thankyou_note_6'|translate}}
                        </span>
                        <span *ngIf="!message1">
                          {{'thankyou_note_5'|translate}}
                        </span>
                      </div>
                      <div class="progress-bar" *ngIf="!hasBids"
                      [ngClass]="{'animation-2':showThankYouNote}">
                        <img src="../../../../assets/images/progress-bar1.svg" class="move-block-right"
                        alt="progress-bar">
                        <img src="../../../../assets/images/progress-bar2.svg" class="move-block-right-2"
                        alt="progress-bar">
                      </div>
                      <div class="progress-bar" *ngIf="hasBids"
                      [ngClass]="{'animation-2':showThankYouNote}">
                        <img src="../../../../assets/images/green-progress-bar1.svg" class="move-block-right"
                        alt="progress-bar">
                        <img src="../../../../assets/images/green-progress-bar2.svg" class="move-block-right-2"
                        alt="progress-bar">
                      </div>
                    </div>
                  </div>
        
                    <ng-container *ngIf="hasBids">
                      <ng-container *ngFor="let bid of bidList;let index = index">
                        <div class="card bid-card mt-12"
                        [ngClass]="{'lg-show':(bid && bidList.length >= 1 && !floatingState)}"
                         *ngIf="(index == 0 || showOffers)">
                          <div class="card-body" style="padding:8px;">
                            <div class="row">
                              <div class="col-8 admin-detail">
                                <div class="container">
                                  <div class="row">
                                    <div class="img-circle">
                                      <img alt="Image" class="img-circle" 
                                      [src]="bid.profile_picture?bid.profile_picture:'../../../assets/images/user_icon.svg'">
                        
                                    </div> 
                                    <div class="col">
                                      <div class="row">
                                        <div class="col-12 admin-name">
                                          {{bid.name}} 
                                        </div>
                                        <div class="col-12">
                                          <img alt="Image" class="feedback" *ngIf="bid.rating < 0.5"
                                          src="../../../assets/images/feedback.svg">
                                          <img alt="Image" class="feedback-active" *ngIf="bid.rating >= 0.5"
                                            src="../../../assets/images/rating-active-star.svg">
                                          <img alt="Image" class="feedback" *ngIf="bid.rating < 1.5"
                                          src="../../../assets/images/feedback.svg">
                                          <img alt="Image" class="feedback-active" *ngIf="bid.rating >= 1.5"
                                            src="../../../assets/images/rating-active-star.svg">
                                          <img alt="Image" class="feedback" *ngIf="bid.rating < 2.5"
                                          src="../../../assets/images/feedback.svg">
                                          <img alt="Image" class="feedback-active" *ngIf="bid.rating >= 2.5"
                                            src="../../../assets/images/rating-active-star.svg">
                                          <img alt="Image" class="feedback" *ngIf="bid.rating < 3.5"
                                          src="../../../assets/images/feedback.svg">
                                          <img alt="Image" class="feedback-active" *ngIf="bid.rating >= 3.5"
                                            src="../../../assets/images/rating-active-star.svg">
                                          <img alt="Image" class="feedback" *ngIf="bid.rating < 4.5"
                                          src="../../../assets/images/feedback.svg">
                                          <img alt="Image" class="feedback-active" *ngIf="bid.rating >= 4.5"
                                            src="../../../assets/images/rating-active-star.svg">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-4">
                                <div class="bid-amount float-right">
                                  {{bid.price| currency:'INR'}}
                                </div>
                              </div>
                            </div>
                            <div class="row  bid-title">
                              <div class="col  mt-8" style="text-overflow: ellipsis;
                              overflow: hidden;
                              white-space: nowrap;">
                                {{bid.bid_title}}
                              </div>
                              <div class="col-auto see-more" *ngIf="!bid.seeMore">
                                <span  (click)="bid.seeMore = true; $event.stopPropagation()">
                                {{'read_more'|translate}}
                                </span>
                              </div>
                            </div>
                            <div class="row" *ngIf="bid.seeMore">
                              <div class="col-12 bid-mssg">
                                {{bid.msg}}
                              </div>
                            </div>
                          </div>
                          <div class="card-footer">
                            <div class="row">
                              <div class="col-6" style="padding-right:8px;">
                                <button class="btn-11 btn-1-lg-outline" 
                                (click)="ignoreOffer(bid);$event.stopPropagation();">
                                  <div>
                                    {{'ignore'|translate}}
                                  </div>
                                  <li class="sub">
                                    <span>
                                      {{'suggestion_in_7_days'|translate}}
                                    </span>
                                  </li>
                                </button>
                              </div>
                              <div class="col-6" style="padding-left:8px;">
                                <button class="btn-11 btn-1-lg" (click)="acceptOffer(bid);
                                $event.stopPropagation();">
                                  <div>
                                    {{'pay'|translate}} {{bid.price| currency:'INR'}}
                                  </div>
                                  <li class="sub">
                                    <span>
                                      {{'solution_in_24_hours'|translate}}
                                    </span>
                                  </li>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <div class="content-center view-blue-btn" *ngIf="!hasBids && !showEmitra 
                    && thankyouCardTimer <= 0 && hasListing">
                      <button class="transparent-btn" (click)="showEmitra = true">
                        {{'view_available_emitra'|translate}}
                      </button>
                    </div>
        
                    <div class="content-center view-blue-btn sm-show"
                    *ngIf="hasBids && bidList && bidList.length == 1 && !floatingState && !showOffers2">
                     <button class="transparent-btn" (click)="showOffers2 = true; floatingState = true">
                       {{'view_offer'|translate}}
                     </button>
                   </div>
                  <div class="content-center view-blue-btn"
                  *ngIf="hasBids && bidList && bidList.length > 1 && !showOffers && floatingState">
                    <button class="transparent-btn"  (click)="showOffers = true">
                      {{'view_more_offers'|translate}}
                    </button>
                  </div>
                  <div class="content-center view-blue-btn"
                  *ngIf="hasBids && bidList && bidList.length > 1 && !showOffers && !floatingState">
                    <button class="transparent-btn"  (click)="showOffers = true">
                      {{'view_offers'|translate}}
                    </button>
                  </div>
                  <div class="content-center view-blue-btn sm-show" 
                  *ngIf="hasBids && bidList && bidList.length == 1
                    && !showEmitra && floatingState && hasListing">
                    <button  class="transparent-btn" (click)="showEmitra = true">
                      {{'view_available_emitra'|translate}}
                    </button>
                  </div>
                  <div class="content-center view-blue-btn lg-show" 
                  *ngIf="hasBids && bidList && bidList.length == 1
                    && !showEmitra && !floatingState && hasListing" style="text-align:center;">
                    <button  class="transparent-btn" (click)="showEmitra = true">
                      {{'view_available_emitra'|translate}}
                    </button>
                  </div>
                  <div class="card offer-card-title mt-12 text-center" *ngIf="hasListing && 
                  (showEmitra || showOffers)">
                    <div class="title">
                      {{'choose_emitra_yourself'| translate}}
                    </div>
                  </div>
                
                  <ng-container *ngIf="hasListing &&  (showEmitra || showOffers)">
                    <div class="row justify-content-center mt-12">
                      <ng-container *ngFor="let list of listings|slice:start:end; let index = index">
                        <div class="col-6 col-sm-4 col-md-3 mt-2">
                          <div class="card list-card" (click)="showEmitraDetails(list)">
                            <div class="card-body">
                              <div class="content-center mt-2">
                                <img alt="Image" class="img-circle" 
                                [src]="list.profile_picture?list.profile_picture:'../../../assets/images/user_icon.svg'">
                              </div>
                              <div class="content-center list-name mt-1">
                                {{list.name}}
                              </div>
                              <div class="content-center rating mt-1 mb-1">
                                <img alt="Image" class="feedback" *ngIf="list.rating < 0.5"
                                      src="../../../assets/images/feedback3.svg">
                                      <img alt="Image" class="feedback-active-green" *ngIf="list.rating >= 0.5"
                                        src="../../../assets/images/feedback2.svg">
                                      <img alt="Image" class="feedback" *ngIf="list.rating < 1.5"
                                      src="../../../assets/images/feedback3.svg">
                                      <img alt="Image" class="feedback-active-green" *ngIf="list.rating >= 1.5"
                                        src="../../../assets/images/feedback2.svg">
                                      <img alt="Image" class="feedback" *ngIf="list.rating < 2.5"
                                      src="../../../assets/images/feedback3.svg">
                                      <img alt="Image" class="feedback-active-green" *ngIf="list.rating >= 2.5"
                                        src="../../../assets/images/feedback2.svg">
                                      <img alt="Image" class="feedback" *ngIf="list.rating < 3.5"
                                      src="../../../assets/images/feedback3.svg">
                                      <img alt="Image" class="feedback-active-green" *ngIf="list.rating >= 3.5"
                                        src="../../../assets/images/feedback2.svg">
                                      <img alt="Image" class="feedback" *ngIf="list.rating < 4.5"
                                      src="../../../assets/images/feedback3.svg">
                                      <img alt="Image" class="feedback-active-green" *ngIf="list.rating >= 4.5"
                                        src="../../../assets/images/feedback2.svg">
                              </div>
                            </div>
                            <div class="card-footer content-center">
                              <button class="request-btn " (click)="$event.stopPropagation(); requestHelp(list)"
                              [disabled]="list.status != 'new'" 
                              [ngClass]="{'rq-block-btn': list.status != 'new'}">
                                {{'request help'|translate}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    
                  </ng-container>
      
      
                  <div class="col-12 more-btn-card mt-12" 
                  *ngIf="hasListing && end < listings.length && (showEmitra || showOffers)">
                    <button class="more-listing-btn" (click)="viewMoreListings()">
                      {{'view_more_listings' | translate}} 
                      <img alt="Image" class="ml-1" src="../../../assets/images/post-btn.svg">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          <ng-container *ngIf="!isAssignedEmitra">
            <div class="col-12 payment-varification-card mt-3" 
            *ngIf="showSecondsTimer && secondCountDown2 > -1">
              <div class="title content-center pt-3">
                {{'your process will start shortly'|translate}}
              </div>
              <div class="wait-time content-center mt-3">
                <img src="../../../../assets/images/timer.svg" 
                alt="timer" class="mr-2" height="28px" width="28px">
                <ng-container *ngIf="secondCountDown2">
                  0.{{secondCountDown2}} s
                </ng-container>
              </div>
              <div class="description content-center mt-3 pb-3">
                {{'please give few minute to verify'|translate}}
              </div>
            </div>
  
            <div class="col-12 payment-varification-card mt-3" 
            *ngIf="showPaymentProcessingTimer">
              <div class="title content-center pt-3">
                {{'trouble_verifying_your_payment'|translate}}
              </div>
              <div class="wait-time content-center mt-3">
                <img src="../../../../assets/images/timer2.svg"
                 alt="timer" class="mr-2" height="28px" width="28px">
                <ng-container *ngIf="timeDifference">
                  {{hourCountDown}} : {{minuteCountDown}} : {{secondCountDown}}
                </ng-container>
              </div>
              <div class="description content-center mt-3 pb-3">
                {{"verification_until_24_hours" | translate}}
              </div>
            </div>
  
            <div class="col-12 payment-failed-card mt-24" *ngIf="paymentFailed">
              <div class="content-center pb-2 pt-2">
                <img alt="Image" class="mr-2"  width="24px" height="24px"
                src="../../../../assets/images/sad_user2.svg">
                <span class="description">
                  {{'unable_to_verify_payment'|translate}}
                </span>
              </div>
            </div>
  
            <div class="col-12 pay-again-card mt-12" *ngIf="paymentPending || paymentFailed">
              <div class="title content-center" 
              style="padding-top:10px; padding-bottom:10px;">
                {{'pay_again_text'|translate}}
              </div>
            </div>
            <div class="col-12 content-center mt-12"
            *ngIf="paymentPending || paymentFailed">
              <button class="pay-again " (click)="onClickPayAgain()">
                {{'pay_again_btn'|translate}} 
                <img src="../../../../assets/images/post-btn.svg" alt="Send" class="pl-2">
              </button>
            </div>

          </ng-container>
          <div class="col-12 payment-varification-card mt-3"
           *ngIf="isAssignedEmitra
          && hrUserStatus != 'completed'" style="background:transparent;">
            <div class="content-center pt-3">
              <img alt="Image" class="img-circle mr-2"  width="20px" height="20px"
              [src]="assignedEmitraProfilePicture?assignedEmitraProfilePicture:'../../../assets/images/user_icon.svg'">
              <span class="name mr-2 text-blue w-500">{{assignedEmitraName}}</span>
              <button class="call-btn pr-2 text-blue w-500">
                <a [href]="'tel:'+assignedEmitraPhoneNumber">
                  <img alt="phone" width="10px" class="ml-2"
                  src="../../../assets/images/phone_icon3.svg">
                  {{assignedEmitraPhoneNumber}}
                </a>
              </button>
            </div>
            <div class="description content-center mt-3 pb-3 w-500">
              {{'working_on_help_request'|translate}}
            </div>
          </div>


          <div  #reviewCard id="reviewCard1">
            <div class="col-12 payment-varification-card mt-3 pb-3" 
            style="background:transparent;"
            *ngIf="hrEmitraStatus == 'closed' && hrUserStatus == 'completed'">
              <div class="description content-center pt-3 w-500">
                {{'Congratulations'|translate}}!!
                <img alt="Image" class="img-circle mr-2 ml-2"  width="20px" height="20px"
                [src]="assignedEmitraProfilePicture?assignedEmitraProfilePicture:'../../../assets/images/user_icon.svg'">
                <span class="description mr-2 text-blue w-500">{{assignedEmitraName}}</span>
              </div>
              <div class="description content-center mt-3 pb-3 w-500">
                {{'help_request_solved_by'|translate}}
              </div>
              <ng-container *ngIf="showReviewCard && abTestingType == ABTestingType.Old">
                <div class="card">
                  <div class="card-body">
                    <div class="title2 content-center">
                      {{'post_review'|translate}}
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-7">
                        <div class="rating-stars mt-3 content-center">
                          <div class=" row justify-content-center">
                            <div class="col pr-0 pl-0">
                              <span class="star" (click)="ratingStars(1)">
                                <img alt="Image" class="img-fluid" 
                                *ngIf="ratingStarCount < 1"
                                src="../../../assets/images/feedback3.svg">
                                <img alt="Image" class="img-fluid" 
                                *ngIf="ratingStarCount >=1"
                                src="../../../assets/images/feedback2.svg">
                              </span>
                            </div>
                            <div class="col pr-0 pl-2">
                              <span class="star" (click)="ratingStars(2)">
                                <img alt="Image" class="img-fluid" 
                                *ngIf="ratingStarCount < 2"
                                src="../../../assets/images/feedback3.svg">
                                <img alt="Image" class="img-fluid" 
                                *ngIf="ratingStarCount >=2"
                                src="../../../assets/images/feedback2.svg">
                              </span>
                            </div>
                            <div class="col pr-0 pl-2">
                              <span class="star" (click)="ratingStars(3)">
                                <img alt="Image" class="img-fluid" 
                                *ngIf="ratingStarCount < 3"
                                src="../../../assets/images/feedback3.svg">
                                <img alt="Image" class="img-fluid" 
                                *ngIf="ratingStarCount >=3"
                                src="../../../assets/images/feedback2.svg">
                              </span>
                            </div>
                            <div class="col pr-0 pl-2">
                              <span class="star" (click)="ratingStars(4)">
                                <img alt="Image" class="img-fluid" 
                                *ngIf="ratingStarCount < 4"
                                src="../../../assets/images/feedback3.svg">
                                <img alt="Image" class="img-fluid" 
                                *ngIf="ratingStarCount >=4"
                                src="../../../assets/images/feedback2.svg">
                              </span>
                            </div>
                            <div class="col pr-0 pl-2">
                              <span class="star" (click)="ratingStars(5)">
                                <img alt="Image" class="img-fluid" 
                                *ngIf="ratingStarCount < 5"
                                src="../../../assets/images/feedback3.svg">
                                <img alt="Image" class="img-fluid" 
                                *ngIf="ratingStarCount >=5"
                                src="../../../assets/images/feedback2.svg">
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-9">
                        <div class="input-box content-center mt-3">
                          <textarea placeholder="{{'write_your_review'|translate}}"
                          [(ngModel)] = "review">
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3 content-center">
                      <button class="send-btn " (click)="onClickReviewEmitra()">
                        {{'menu_send'|translate}}
                        <img src="../../../../assets/images/post-btn.svg" alt="Send" 
                        class="pl-2">
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="content-center pt-3 pb-3">
                <button class="download-btn" (click)="openPaymentReceipt()">
                  {{'download recipt'|translate}}
                  <img alt="Image" class="mr-2 icon"
                  src="../../../../assets/images/download_icon2.svg">
                </button>
              </div>
            </div>
          </div>

          <div class="chat" *ngIf="isAssignedEmitra">
            <div class="row mt-3">
              <div class="col-auto mr-auto">
                <span class="heading">
                  {{'chat'|translate}}
                </span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <app-chat-box [userName]="username" [emitraId]="assignedEmitraId"
                [helpRequestId]="helpRequestId" 
                [emitraProfilePicture]="assignedEmitraProfilePicture"
                [userProfilePicture]="userProfilePicture"
                [hrEmitraStatus]="hrEmitraStatus"
                [hrUserStatus]="hrUserStatus"
                [paidAmount]="paymentAmount" 
                (closeRequestEvent)="closeRequestHandler()"></app-chat-box>
              </div>
            </div>
          </div>


          </ng-container>

          
          <hr style="border-top: 1px solid #D6CACA; margin-top: 21px;" 
          *ngIf=" (username != helpRequestUserName && (!paymentPending && !paymentFailed) && 
          ((isPaidHelp && helpRequestUserName == username && isAssignedEmitra) || 
          (isPaidHelp && helpRequestUserName != username) || !isPaidHelp) )
          && request?.req_status == '1'"/>

          <div class="col-12 p-0 card mt-3" *ngIf="username != helpRequestUserName &&  
          request?.req_status == '1'  
          && (!paymentPending && !paymentFailed) && 
          ((isPaidHelp && helpRequestUserName == username && isAssignedEmitra) || 
          (isPaidHelp && helpRequestUserName != username) || !isPaidHelp)"
          style="border: 1px solid rgba(50, 50, 51, 0.6);">
            <div class="post_a_comment_section">
              <div class="col-12 pd0">
                <div class="d-flex comment-box w-100">
                  <textarea class="input make-comment" [cdkTextareaAutosize]="true" [cdkAutosizeMinRows]="1"
                    [cdkAutosizeMaxRows]="10" cols="20" rows="1" role="textbox" [(ngModel)]="myComment"
                    (focus)="onSuggestFocus();" placeholder="{{'Write your answer' | translate}} "></textarea>
                </div>
                <div class="actions_buttons_for_post_comment">
                  <div class="d-inline">
                    <img (click)="checkLoginAndOpenDialog('openAddImageButton')" alt="Image" height="16" class="mr-4"
                      [src]="videoOptionSelected || voiceOptionSelected? '../../../assets/images/new_help_icons/image.svg' :'../../../assets/images/new_help_icons/img-active.svg'" />
                    <img [hidden]="true" id="openAddImageButton" data-toggle="modal" data-target="#imageModal"
                      alt="Image" />
                    <img (click)="checkLoginAndOpenDialog('openAddVideoButton')" alt="Image" height="16" class="mr-4"
                      [src]="videoOptionSelected || imageOptionSelected|| voiceOptionSelected? '../../../assets/images/new_help_icons/video.svg' :'../../../assets/images/new_help_icons/video-active.svg'" />
                    <img [hidden]="true" id="openAddVideoButton" data-toggle="modal" data-target="#exampleModal"
                      alt="Image" />
                    <img (click)="checkLoginAndOpenDialog('openModalButton')" height="40" alt="Image" height="16"
                      class="mr-4"
                      [src]="videoOptionSelected || imageOptionSelected|| voiceOptionSelected ? '../../../assets/images/new_help_icons/VoiceRecImg.svg' :'../../../assets/images/new_help_icons/record_audio_suggestion.svg'" />
                    <img [hidden]="true" id="openModalButton" data-toggle="modal" data-target="#audioInputModal"
                      alt="Image" />
                  </div>
                  <div class="d-inline-block float-right" style="cursor: pointer;" (click)="postComment(request)">
                    <img id="post_animator" style="display: none;" src="../../../../assets/images/jp10_animation.gif"
                      alt="animation">
                    <span class="icon-send" [ngClass]="{'disabled_class': disablePostButtton}">
                      <img src="../../../../assets/images/right_arrow_help2.svg" alt="Send">
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div *ngIf="showVideo" class="col-12 d-flex">
                <div class="videoContainer">
                  <img id=ideoCustom src="../../../../assets/images/Play 1.svg" style="height: 100px;" alt="Image">
                </div>
                <i class="fa fa-trash trash_icon" (click)="removeVideoSelect()"></i>
              </div>
              <div class="col-12 post-comment-info audio-comment-box" *ngIf="voiceOptionSelected">
                <audio *ngIf="!isRecording && audioBlobURL" controls>
                  <source [src]="audioBlobURL" type="audio/webm">
                </audio>
                <i class="fa fa-trash trash_icon" (click)="remove_audio()"></i>
              </div>
              <div class="col-12 d-flex overflow-auto" *ngIf="selectedImageThumbnail.length > 0">
                <div *ngFor="let image of selectedImageThumbnail; let idx= index"
                  class="imagesContainer addImageListItems mt-3 mr-2" style="display: flex;">
                  <div><img style="height:100px;max-width:150px;object-fit:cover;" src="{{image}}" alt="Image"></div>
                  <i class="fa fa-trash trash_icon" (click)="remove_picture(idx)"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 comment-section p-0" *ngIf="(!paymentPending && !paymentFailed) || isConvertedToFree">
            <div class="col-12 pd0 mt-12" *ngFor="let comment of request?.comments|slice:0:3;let i = index">
              <div *ngIf="$any(comment)?.is_thankyou == true || ($any(comment)?.is_helpful == true && i == 0)">
                <div class="solution-card">
                  <div class="row">
                    <div class="col-4">
                      <div class="content-center" style="width:fit-content">
                        <img src="../../../../assets/images/solutions.svg" alt="solutions"
                        height="17.03px" width="17.03px">
                        <span class="title" style="margin-left:4px;" *ngIf="$any(comment)?.is_admin">
                          {{'Suggestion'|translate}}
                        </span>
                        <span class="title" style="margin-left:4px;" *ngIf="!$any(comment)?.is_admin">
                          {{'new_survey_solution'|translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="post-comment-info" style="margin-bottom:12px;"
                  [ngClass]="($any(comment)?.username == 'Jaano Helper' || $any(comment)?.is_thankyou == true
                  || $any(comment)?.is_admin)
                  ? 'helpermainanswer': null">

                  <div class="col-12 fs-16 pd0">@{{$any(comment)?.username | translate}}
                    <span class="fs-12 text-grey"
                    style="color: rgba(50, 50, 51, 0.6);">{{$any(comment)?.timestamp | date: 'short'}}</span>
                    <span class="content-disapproved float-right" *ngIf="!$any(comment)?.approval_status">{{'Disapproved' |
                      translate}}</span>
                  </div>
                  <div class="col-12 pd0">
                    <div class="col-12 pd0">
                      <div class="conatiner_news_details"
                        *ngIf="$any(comment) && $any(comment).snippetData && $any(comment).snippetData.news_source"
                        (click)="openDialog($any(comment).snippetData.link)">
                        <img style="object-fit: contain; width: 100%; border-radius: 4px;" alt="Image"
                          *ngIf="$any(comment).snippetData.news_image" src="{{$any(comment).snippetData.news_image}}">
                        <div class="layer"></div>
                        <div class="overlay_layer">
                          <img class="h-100" src="../../../../assets/images/Play 2.svg" alt="play-icon" />
                        </div>
                      </div>
                      <div class="post-comment"
                        *ngIf="$any(comment) && $any(comment).comment.length > 0 && !ifAudioInSuggestion($any(comment).comment)">
                        <p class="mb-0 fs-16" (click)="link_clicked(comment)" [innerHTML]="$any(comment)?.comment"> </p>
                      </div>
                      <div class="post-comment flex-start"
                        *ngIf="$any(comment) && $any(comment).comment.length > 0 && ifAudioInSuggestion($any(comment).comment)">
                        <img src="../../../../assets/images/show_audio_in_reply_icon.svg" alt="Image" />
                        <audio controls>
                          <source [src]="$any(comment).comment" type="audio/webm">
                        </audio>
                      </div>

                      <div class="mt-2 mb-2" *ngIf="$any(comment) && $any(comment).comment_audio">
                        <audio controls class="w-100">
                          <source [src]="$any(comment).comment_audio" type="audio/webm">
                        </audio>
                      </div>
                      <div *ngIf="$any(comment) && $any(comment).comment_image" class="overflow-auto w-100"
                        style="white-space: nowrap;">
                        <img *ngFor="let image of $any(comment).comment_image" src={{image}} alt="Image" class="bigImg">
                      </div>
                      <div *ngIf="$any(comment) && $any(comment).comment_video">
                        <video class="video_comment d-block w-100" width="245" height="250" controls>
                          <source [src]="$any(comment).comment_video" type="video/mp4">
                        </video>
                      </div>
                    </div>
                    <div class="post-comment-reply">
                      <div class="container p-0">
                        <div class="row md-hide">
                          <div class="col-6 col-sm">
                            <span class="helpfulButton" style="display:flex; margin:inherit">
                              <span class="">
                                <img src="../../../../assets/images/happy-active-icon.svg" alt="happy-active"
                                *ngIf="request?.req_status != '1'">
                                <img *ngIf="request?.req_status == '1'" alt="helpful-button"
                                  [src]="($any(comment)?.reaction == 1) ? '../../../../assets/images/new_help_icons/helpful_button blue.svg' : '../../../../assets/images/new_help_icons/helpful_button.svg'" />
                                </span>
                              <span class="" style="margin-left:6px;" [ngClass]="{'color-707070':request?.req_status == '1'}">
                                {{$any(comment)?.tot_likes == -1 ? '': $any(comment)?.tot_likes}} 
                                {{'helpful' | translate}}</span>
                            </span>
                          </div>
                          <div class="col-auto ml-auto">
                            <span class="helpfulButton" style="display:flex; margin:inherit">
                              <span class="">
                                <img src="../../../../assets/images/sad-active-icon.svg" alt="sad-active"
                                *ngIf="request?.req_status != '1'">
                                <img *ngIf="request?.req_status == '1'" alt="not-helpful-button"
                                  [src]="($any(comment)?.reaction == 2) ? '../../../../assets/images/new_help_icons/nothelpful_button blue.svg' : '../../../../assets/images/new_help_icons/nothelpful_button.svg'" />
                                </span>
                              <span class="" style="margin-left:6px;" [ngClass]="{'color-707070':request?.req_status == '1'}">
                                {{$any(comment)?.tot_dislike == -1 ? '': $any(comment)?.tot_dislike}} 
                                {{'not_helpful'| translate}}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="d-inline-block pd0 helpful_text sm-inline-block-hide">
                        <span class="helpfulButton">
                          <span class="">
                            <img src="../../../../assets/images/happy-active-icon.svg" alt="happy-active"
                            *ngIf="request?.req_status != '1' || $any(comment)?.is_admin">
                            <img *ngIf="request?.req_status == '1' && !$any(comment)?.is_admin" alt="helpful-button"
                              [src]="($any(comment)?.reaction == 1) ? '../../../../assets/images/new_help_icons/helpful_button blue.svg' : '../../../../assets/images/new_help_icons/helpful_button.svg'" />
                            </span>
                          <span class="sm-inline-block-hide" style="margin-left:6px;">
                            {{$any(comment)?.tot_likes == -1 ? '': $any(comment)?.tot_likes}} 
                            {{'helpful' | translate}}</span>
                        </span>
                        <span class="helpfulButton">
                          <span class="">
                            <img src="../../../../assets/images/sad-active-icon.svg" alt="sad-active"
                            *ngIf="request?.req_status != '1' || $any(comment)?.is_admin">
                            <img *ngIf="request?.req_status == '1' && !$any(comment)?.is_admin" alt="not-helpful-button"
                              [src]="($any(comment)?.reaction == 2) ? '../../../../assets/images/new_help_icons/nothelpful_button blue.svg' : '../../../../assets/images/new_help_icons/nothelpful_button.svg'" />
                            </span>
                          <span class="sm-inline-block-hide" style="margin-left:6px;">
                            {{$any(comment)?.tot_dislike == -1 ? '': $any(comment)?.tot_dislike}} 
                            {{'not_helpful'| translate}}</span>
                        </span>
                      </div>
                      <span class="fs-14" style="cursor: pointer;" (click)="addReplyOnHelpRequest(request,comment,i,1)">
                        <img [id]="'post_animator_2' + i" style="display: none;"
                          src="../../../../assets/images/jp10_animation.gif" alt="Image">
                      </span>
                      <span [ngClass]="$any(comment)?.totalReplies > 0?'text-blue fs-14':'fs-14'" style="cursor: pointer;"
                        (click)="addReplyOnHelpRequest(request,comment,i,1)"
                        class="reply-btn" 
                        *ngIf="!$any(comment).IsCommentReply && 
                        (request.req_status != '0' && !$any(comment)?.is_admin)">
                        <img src="../../../../assets/images/reply-icon.svg" alt="reply-icon"
                        height="10px" width="12px" style="margin-right:3px;">
                        {{'txt_reply'| translate}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="reply-comment-wrapper"
                *ngIf="request?.req_status == '1'">
                <div class="col-12 p-0" *ngFor="let reply of $any(comment)?.replies;let j = index">
                  <div class="col-12 mb-3 d-flex pr-0">
                    <div class="post-comment-info">
                      <div class="col-12 fs-16 pd0">{{reply?.username | translate}}
                        <span class="fs-12 text-grey">{{reply?.timestamp | date: 'short'}}</span>
                      </div>
                      <div class="col-12 pd0">
                        <div class="post-comment">
                          <span *ngIf="reply?.isThankyouReply">
                            <span class="ml-2 fs-14 thankYouNote">
                              <img src="../../../../assets/images/new_help_icons/noun_thank you.svg" alt="thankyou" />
                              {{reply?.reply}}
                            </span>
                          </span>
                          <span *ngIf="!reply?.isThankyouReply">
                            <p class="mb-0 fs-16" [innerHTML]="reply?.reply"></p>
                          </span>

                          <div class="conatiner_news_details" *ngIf="reply && reply.snippetData"
                            (click)="openDialog(reply.snippetData.link)">
                            <img style="height: 100px;object-fit: contain; border-radius: 4px;" alt="Image"
                              src="{{reply.snippetData.news_image}}">
                            <div style="padding: 4px;width: 100%;  overflow: hidden;">
                              <div class="source">{{reply.snippetData.news_source}}</div>
                              <div class="title">{{reply.snippetData.news_title}}</div>
                              <div class="description" *ngIf="reply.snippetData.news_description">
                                {{reply.snippetData.news_description.length < 90 ? reply.snippetData.news_description :
                                  reply.snippetData.news_description.slice(0, 90) + "..." }} </div>
                              </div>
                            </div>

                          </div>
                          <div class="post-comment-reply" *ngIf="!$any(comment)?.IsCommentReply">
                            <span class="text-blue fs-14" (click)="addReplyOnHelpRequest(request,comment,i,2)">
                              {{'txt_reply'| translate}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 p-0" *ngIf="$any(comment)?.IsCommentReply">
                  <div class="reply-comment-wrapper d-flex col-12 mb-12">
                    <div class="post-comment-info comment-box reply_area">
                      <textarea class="col-10 p-0 input make-comment d-inline-block" [cdkTextareaAutosize]="true"
                        [cdkAutosizeMinRows]="1" [cdkAutosizeMaxRows]="10" cols="20" rows="1" role="textbox"
                        [(ngModel)]="myReplyOnComment" placeholder="{{'Write Your Reply Here' | translate}} ">
                          </textarea>
                      <button class="icon-send col-2 d-inline-block p-0 align-top text-right bg-white"
                        [disabled]="IsLoader" (click)="savereply(request)" style="cursor: pointer;"
                        [ngClass]="{'disabled_class': disablePostButtton}">
                        <img src="../../../../assets/images/post-icon.svg" alt="Send" height="16">
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-12 pd0" *ngIf="request && request.comments && request.comments.length>0
              && request?.req_status == '0'
               && (!request.comments[0].reaction || hasClickedEmoji || sentFeedback) && !isPaidHelp">
              <div class="card card-body solution2-card">
                <ng-container *ngIf="!sentFeedback">
                  <div class="d-flex justify-content-center text">
                    {{'was_suggestion_helpful'|translate}}
                  </div>
                  <div class="d-flex justify-content-center mt-12">
                    <div (click)="markCommentHelpful(request,request.comments[0],0, 1)">
                      <img 
                      [src]="(request.comments[0]?.reaction == 1) ? '../../../../assets/images/happy-active-icon.svg' : '../../../../assets/images/happy-icon.svg'"
                      alt="happy-icon" height="33.3px" width="33.3px"
                      style="cursor: pointer;">
                    </div>
                    <div (click)="markCommentHelpful(request,request.comments[0],0, 2)">
                      <img 
                      [src]="(request.comments[0]?.reaction == 2) ? '../../../../assets/images/sad-active-icon.svg' : '../../../../assets/images/sad-icon.svg'"
                      alt="sad-icon" height="33.3px" width="33.3px"
                      style="margin-left: 40px; cursor: pointer;">
                    </div>
                  </div>
                  <div class="d-flex justify-content-center mt-12" *ngIf="request.comments[0]?.reaction == 2">
                    <span (click)="scrolltoNeedHelp()" class="get-help-text">
                      {{'get_help_from_jaano'|translate}}
                    </span>
                  </div>
                  <div class="d-flex justify-content-center mt-16" *ngIf="request.comments[0].reaction">
                    <textarea class="custom-text-area" [cdkTextareaAutosize]="true"
                      [cdkAutosizeMinRows]="1" [cdkAutosizeMaxRows]="10" cols="20" rows="1" role="textbox"
                      [(ngModel)]="myReplyOnComment" placeholder="{{'feedback_placeholder' | translate}} ">
                    </textarea>
                  </div>
                  <div class="d-flex justify-content-center mt-12" *ngIf="request.comments[0].reaction">
                    <button class="grey-btn"
                      [disabled]="!myReplyOnComment || myReplyOnComment.length == 0" 
                      (click)="savereply(request)" style="cursor: pointer;"
                      [ngClass]="{'disabled_class': disablePostButtton}">
                      {{'submit'|translate| titlecase}}
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="sentFeedback">
                  <div class="thankyou-feedback">{{'thanks_for_feedback'|translate}}</div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-12 mainContainers mt-24 need-help-card" 
    *ngIf="!isPaidHelp && helpRequestUserName != username" 
    #needHelpBlock id="needHelpBlock">
      <div class="need-help-card card p-0 box-shadow-4px">
        <div class="card-header p-0">
          <div class="row">
            <div class="col-6 text-center header border-right">
              <span>
                {{'new_survey_jaano_unpaid_hr'|translate}}
              </span>
              <div (click)="showUnpaidHelpInfo()" class="cursor-pointer content-center" 
              style="width:24px;height: 24px;">
                <img src="../../../../assets/images/about-us-icon-4.svg" alt="info-icon"
                width="16px" height="16px">
              </div>
            </div>
            <div class="col-6 text-center header">
              <span>
                {{'new_survey_jaano_paid_hr'|translate}}
              </span>
              <div (click)="showPaidHelpInfo()" class="cursor-pointer content-center"
              style="width:24px;height: 24px;">
                <img src="../../../../assets/images/about-us-icon-4.svg" alt="info-icon"
                width="16px" height="16px">
              </div>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-6 pt-24 border-right">
                <div class="icon-img">
                  <img src="../../../../assets/images/calendar-icon-2.svg" alt="calendar-icon">
                </div>
                <div class="label mt--4">
                  <span>
                    {{'free_help_11'|translate}}
                  </span>
                </div>
              </div>
              <div class="col-6 pt-24">
                <div class="icon-img">
                  <img src="../../../../assets/images/timer-icon-2.svg" alt="timer-icon" >
                </div>
                <div class="label mt--4">
                  <span>
                    {{'paid_help_11'|translate}} 
                    <span style="color:#00AF31">
                      {{'paid_help_12'|translate}}
                    </span>
                  </span>
                </div>
              </div>
              <div class="col-6 pt-24 border-right">
                <div class="icon-img">
                  <img src="../../../../assets/images/phone-icon-2.svg" alt="phone-icon" >
                </div>
                <div class="label mt--4">
                  <span>
                    {{'free_help_12'|translate}}
                  </span>
                </div>
              </div>
              <div class="col-6 pt-24">
                <div class="icon-img">
                  <img src="../../../../assets/images/certificate-icon-2.svg" alt="certificate-icon">
                </div>
                <div class="label mt--4">
                  <span style="color:#00AF31">
                    {{'paid_help_21'|translate}} 
                  </span>
                  <span >
                    {{'paid_help_22'|translate}}
                  </span>
                </div>
              </div>
              <div class="col-6 pt-24 border-right">
              </div>
              <div class="col-6 pt-24">
                <div class="icon-img">
                  <img src="../../../../assets/images/refund-icon-3.svg" alt="refund-icon">
                </div>
                <div class="label mt--4">
                  <span style="color:#00AF31">
                    {{'paid_help_31'|translate}}  
                  </span>
                  <span>
                    {{'paid_help_32'|translate}}
                  </span>
                </div>
              </div>
              <div class="col-6 pt-16-8" 
              style="border-right:1px solid rgba(50, 50, 51, 0.3)">
                <button class="btn btn-1" (click)="requestFreeHelp()">
                  {{'get_free_help'|translate}}
                </button>
              </div>
              <div class="col-6 pt-16-8">
                <button class="btn btn-2" (click)="requestPaidHelp()">
                  {{'get_paid_help'|translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-12 mb-20 mainContainers box-shadow-4px card-need-help" *ngIf="!isPaidHelp" 
    #needHelpBlock id="needHelpBlock">
        <div class="container p-0">
          <div class="row">
            <div class="col-12 text-center pb-0 pt-0 need-help-text">
              {{'need_help_from_us'|translate}}
            </div>
            <div class="col-12 mt-12">
              <div class="card card-body new-card-1">
                <div class="d-flex justify-content-center text-center">
                  <span class="title">{{'new_survey_jaano_paid_hr'|translate}}</span>
                </div>
                <div class="d-flex justify-content-center mt-24">
                  <div class="circular-icon content-center">
                    <img src="../../../../assets/images/solution3-icon.svg" alt="solution"
                    width="20px" height="21px">
                  </div>
                </div>
                <div class="d-flex justify-content-center text-11 w-600 mt-6 text-center">
                  {{'quick_response'|translate}}
                </div>
                <div class="d-flex justify-content-center text-11 w-400 light-color-text text-center">
                  {{'quick_response2'|translate}}
                </div>
                <div class="d-flex justify-content-center mt-24">
                  <div class="circular-icon content-center">
                    <img src="../../../../assets/images/certified2-icon.svg" alt="solution"
                    width="20px" height="21px">
                  </div>
                </div>
                <div class="d-flex justify-content-center text-11 w-600 mt-6 text-center">
                  {{'government_certified'|translate}}
                </div>
                <div class="d-flex justify-content-center text-11 w-400 light-color-text text-center">
                  {{'government_certified2'|translate}}
                </div>
                <div class="d-flex justify-content-center mt-24">
                  <div class="circular-icon content-center">
                    <img src="../../../../assets/images/refund2-icon.svg" alt="solution"
                    width="20px" height="21px">
                  </div>
                </div>
                <div class="d-flex justify-content-center text-11 w-600 mt-6 text-center">
                  {{'new_survey_refund'|translate}}
                </div>
                <div class="d-flex justify-content-center text-11 w-400 light-color-text text-center">
                  {{'if_fails_refund'|translate}}
                </div>
                <div class="d-flex justify-content-center mt-24">
                  <button class="help-btn paid-help-btn" (click)="requestPaidHelp()">
                    {{'get_paid_help'|translate}}
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 mt-12">
              <div class="card card-body new-card-1">
                <div class="d-flex justify-content-center text-center">
                  <span class="title">{{'jaano_free_comunity_help'|translate}}</span>
                </div>
                <div class="d-flex justify-content-center mt-24">
                  <div class="circular-icon content-center">
                    <img src="../../../../assets/images/solution2-icon.svg" alt="solution"
                    width="20px" height="21px">
                  </div>
                </div>
                <div class="d-flex justify-content-center text-11 w-600 mt-6 text-center">
                  {{'new_survey_solution'|translate}}
                </div>
                <div class="d-flex justify-content-center text-11 w-400 light-color-text text-center">
                  {{'solution_string'|translate}}
                </div>
                <div class="d-flex justify-content-center mt-24">
                  <div class="circular-icon content-center">
                    <img src="../../../../assets/images/how-icon.svg" alt="solution"
                    width="20px" height="21px">
                  </div>
                </div>
                <div class="d-flex justify-content-center text-11 w-600 mt-6 text-center">
                  {{'response'|translate}}
                </div>
                <div class="d-flex justify-content-center text-11 w-400 light-color-text text-center">
                  {{'response2'|translate}}
                </div>
                <div class="d-flex justify-content-center mt-24">
                  <button class="help-btn paid-help-btn" (click)="requestFreeHelp()">
                    {{'get_free_help'|translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div> -->
    <div class="col-12 mainContainers" style="margin-top:24px;"
    *ngIf="isConvertedToFree && !isPaidHelp && helpRequestUserName == username && request && request.comments && !hasThankyouComment(request?.comments)">
      <div class="card card-body box-shadow-4px p-24-8">
        <div class="row">
          <div class="col-12 text-center text-ignore1">
            {{'ignore_offer_string2'|translate}}
          </div>
          <div class="col-12 text-center text-ignore2 mt-16">
            {{'ignore_offer_string3'|translate}}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-12 mb-20 mainContainers" 
    *ngIf="isPaidHelp && (!paymentPending && !paymentFailed) && abTestingType == ABTestingType.Old"
    style="margin-top:24px;">
      <div class="card card-body customer-review-title text-center box-shadow-4px"
      *ngIf="customerReviews && customerReviews.length>0">
        <span class="text">{{'customer_reviews'|translate}}</span>
      </div>
    </div> -->

    <!-- <div class="col-12" *ngIf="username && !isPaidHelp && helpRequestUserName == username && 
     abTestingType == ABTestingType.Old &&
    request && request.comments && request.comments.length>0 && hasThankyouComment(request?.comments)">
      <div class="floating-card floatin-card-1">
        <div class="container p-40">
          <div class="card-1">
            <div class="card thank-you-card-2">
              <div class="col-12 text-center text-111 w-500">
                <div class="suggestion-icon">
                  <img src="../../../../assets/images/complete-icon.svg" alt="complete-icon" >
                </div>
                {{'unpaid_suggestion_delivered'|translate}}
              </div>
              <hr class="mt-8" style="border: 1px solid rgba(50, 50, 51, 0.3);">
              <div class="col-12 text-center mt-8 text-111 w-500">
                {{'unpaid_not_satisfied'|translate}}
              </div>
              <div class="col-12 text-center text-12" style="font-weight:400">
                {{'unpaid_create_request_str'|translate}}
              </div>
              <div class="col-12 mt-16 p-0">
                <button class="btn-full" (click)="createHelpRequestFromExistingOne()">
                  {{'Create Request'|translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <ng-container  *ngIf="isPaidHelp && (!paymentPending && !paymentFailed)
    && abTestingType == ABTestingType.Old ">

      <div class="col-12 mb-12 mainContainers" [ngClass]="{'p-0':helpRequestUserName != username || !isPaidHelp}"
       *ngFor="let review of customerReviews">
        <div class="card card-body customer-review-card box-shadow-4px">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="user-img-circle">
                  <img [src]="review.userProfilePicture" alt="profile-picture"
                  *ngIf="review.userProfilePicture && review.userProfilePicture != '' &&
                  review.userProfilePicture != 'undefined' && review.userProfilePicture != 'null'
                  && review.userProfilePicture != undefined"
                  height="40px" width="40px" class="border-radius-50">
                  <img src="../../../../assets/images/new_help_icons/initials-img.svg"
                   alt="profile-picture"
                  *ngIf="!review.userProfilePicture || review.userProfilePicture == '' ||
                  review.userProfilePicture == 'undefined' || review.userProfilePicture == 'null'
                  || review.userProfilePicture == undefined"
                  height="40px" width="40px" class="border-radius-50">
                  <span class="initials-1"
                  *ngIf="!review.userProfilePicture || review.userProfilePicture == '' ||
                  review.userProfilePicture == 'undefined' || review.userProfilePicture == 'null'
                  || review.userProfilePicture == undefined">
                    {{getInitial(review.userid)}}
                  </span>
                </div>
                <div class="col" style="position:relative">
                  <div class="row">
                    <div class="col-12 username"
                    (click)="navigateToProfile(review.userid)">
                      @{{review.userid}}
                    </div>
                    <div class="col-12 light-text-1" style="position:absolute; bottom:0px;">
                      {{review.location}}
                    </div>
                  </div>
                </div>
                <div class="col-auto ml-auto">
                  <div class="row justify-content-end">
                    <div class="col-auto rating">
                      <img src="../../../../assets/images/feedback2.svg" alt="rating"
                        width="16px" height="16px"
                        style="margin-bottom: 5px;
                        margin-right: 3px;">
                      <span>{{review.rating |number: '1.1-1'}}</span>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-auto light-text-1">
                      {{review.timestamp}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-8 comment" (click)="review.expand = !review.expand"
            [ngClass]="{'expand-comment':review.expand}">
              {{review.comment}}
            </div>
            <div class="col-12 mt-8">
              <div class="row">
                <div class="col" style="position:relative">
                  <button class="sub-cat-btn light-text-1">{{review.subCat}}</button>
                </div>
                <div class="col-auto ml-auto">
                  <div class="row justify-content-end cursor-pointer"
                  (click)="navigateToProfile(review.solvedById)">
                    <div class="col-auto light-text-1" style="position:relative;">
                      {{'solved_by'|translate}}
                      <img [src]="review.solvedByProfilePicture" alt="profile-picture"
                      *ngIf="review.solvedByProfilePicture && review.solvedByProfilePicture != '' &&
                      review.solvedByProfilePicture != 'undefined' && review.solvedByProfilePicture != 'null'
                      && review.solvedByProfilePicture != undefined"
                      height="19px" width="19px" class="border-radius-50">
                      <img src="../../../../assets/images/new_help_icons/initials-img.svg"
                       alt="solvedByProfilePicture-picture"
                      *ngIf="!review.solvedByProfilePicture || review.solvedByProfilePicture == '' ||
                      review.solvedByProfilePicture == 'undefined' || review.solvedByProfilePicture == 'null'
                      || review.solvedByProfilePicture == undefined"
                      height="19px" width="19px" class="border-radius-50">
                      <span class="initials-2"
                     *ngIf="!review.solvedByProfilePicture || review.solvedByProfilePicture == '' ||
                     review.solvedByProfilePicture == 'undefined' || review.solvedByProfilePicture == 'null'
                     || review.solvedByProfilePicture == undefined">
                        {{getInitial(review.solvedById)}}
                      </span>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-auto solved-by-name"
                    (click)="navigateToProfile(review.solvedById)">
                      @{{review.solvedById}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height:213px;" *ngIf="!floatingState && helpRequestUserName == username 
      && !isAssignedEmitra" ></div>
    </ng-container> -->
    <div class="col-md-12 col-sm-12 mainContainers" style="padding: 0px;">
      <div class="recommended_header box-shadow-4px mt-24" 
      *ngIf="!isPaidHelp && (!paymentPending && !paymentFailed)">
        <img src="../../../../assets/images/new_help_icons/noun_recommend.svg" alt="recomment-icon" />
        {{'recommended_post' | translate}} ({{trendingCount || 0}})
      </div>
      <div class="col-sm-12 pd0" style="height: calc(100% - 3rem); overflow: auto;margin-bottom: 84px;" 
      id="recommendedPostsCont" *ngIf="!isPaidHelp && (!paymentPending && !paymentFailed)">
        <app-trending (getTrendingPostCount)="trendingCount = $event" [categoryList]="categoryList"
          [current_helpReq_id]="request?.helpid" [cat_id]="request?.category" 
          [subcat_id]="request?.sub_category" [pageNo]="pageNo"
          (hasMoreTrendingHrEmitter)="hasMoreTrendingHrHandler($event)">
        </app-trending>
        <div class="text-center" *ngIf="hasMoreTrendingHr"
        [ngClass]="{'mb-3':(hasOffer || thankyouCardTimer > 0) && !isPaidHelp}">
          <button class="view_more_button" (click)="viewMoreTrending()">{{'view_more' |
            translate}}</button></div>
      </div>
      <!-- <div class="addHRBanner" (click)="viewRequest(request?.category)" 
      *ngIf="dataReady && !isPaidHelp && (!hasOffer &&  thankyouCardTimer <= 0)">
        <span>{{ 'not_found_a_solution' | translate}}</span>
        <img src="../../../../assets/images/home-images/plus_icon_round.svg" />
      </div>
      <div class="addHrBanner" *ngIf="(hasOffer || thankyouCardTimer > 0) && !isPaidHelp">
        <div class="add-btn">
          <ng-container *ngIf="!hasOffer && thankyouCardTimer > 0 && username">
            <div style="display: flex;
            height: 80px;
            width: 80px;
            justify-content: center;
            align-items: center;" (click)="goToOffers(); $event.stopPropagation()">
              <span class="text-1">{{thankyouCardTimerText}}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="!hasOffer && thankyouCardTimer == 0">
            <img src="../../../../assets/images/add-hr.svg" alt="Add"
            width="44.5px" height="44.5px"
            class="rotation-icon2"  (click)="addRequest()">
          </ng-container>
          <ng-container *ngIf="hasOffer && username">
            <div style="display: block;
              height: 80px;
              width: 80px;
              justify-content: center;
              align-items: center;
              margin-top: 30px;" class="animation-1" (click)="goToOffers(); $event.stopPropagation()">
              <div class="text-1 animation-1" style="display: flex;
              justify-content: center;
              align-items: center;margin-bottom: 4px;">
                {{hasOffer}}
              </div>
              <div class="text-2 animation-1" style="display: flex;
              text-align:center;justify-content: center;">
                {{'offer_recived'|translate}}
              </div>
            </div>
            <img src=".../../../../../../assets/images/rotation-logo1.svg" alt="Add"
             class="rotation-icon animation-2" (click)="goToOffers(); $event.stopPropagation()">
            <img src=".../../../../../../assets/images/rotation-logo2.svg" alt="Add"
            class="name-icon animation-3" (click)="goToOffers(); $event.stopPropagation()">
            <img src="../../../../assets/images/add-hr.svg" alt="Add"
            width="44.5px" height="44.5px"
            class="rotation-icon2 animation-4"(click)="addRequest()">
            <img src=".../../../../../../assets/images/rotation-logo1.svg" alt="Add"
             class="rotation-icon animation-5"(click)="addRequest(); $event.stopPropagation()">
             <img src=".../../../../../../assets/images/rotation-logo2.svg" alt="Add"
             class="name-icon animation-6"(click)="addRequest(); $event.stopPropagation()">
          </ng-container>
        </div>
      </div> -->
    </div>
</div>
</div>
  <div class="modal fade" id="thankYouModal" tabindex="-1" role="dialog" aria-labelledby="thankYouModal"
    aria-hidden="true">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content modal-content2">
        <div class="modal-header">

          <h5 class="modal-title heading" id="exampleModalLabel">{{'You are closing this Help Request!' | translate}}
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>


        <div class="modal-body ml-3">

          <div class="row">{{'Send a Thank You note to username for their help!' | translate}}

          </div>
          <div *ngIf="firstpage">
            <div class="row mt-4" style="cursor: pointer;">
              <div
                [ngClass]="{'markThankyou-selected': select_message_type == 1,'markThankyou':select_message_type !=1}">
                <span (click)="selectMessage('Dhanyavaad Mitra',1)">
                  {{'Dhanyavaad Mitra' | translate}}
                </span>
                <span style="cursor: pointer;float: right;padding-right: 10px;" (click)="editMesge('Dhanyavaad Mitra')">
                  <img
                    [src]="select_message_type == 1 ? '../../../../assets/images/EditSign.svg':'../../../../assets/images/edit-sign2.svg'"
                    alt="Image">
                </span>

              </div>
            </div>
            <div class="row mt-3" style="cursor: pointer;">
              <div
                [ngClass]="{'markThankyou-selected': select_message_type == 2,'markThankyou':select_message_type !=2}">
                <span (click)="selectMessage('Thanks a lot Dear friend',2)">
                  {{'Thanks a lot Dear friend' | translate}}
                </span>
                <span style="cursor: pointer;float: right;padding-right: 10px;"
                  (click)="editMesge('Thanks a lot Dear friend')">
                  <img
                    [src]="select_message_type == 2 ? '../../../../assets/images/EditSign.svg':'../../../../assets/images/edit-sign2.svg'"
                    alt="Image">
                </span>
              </div>
            </div>
            <div class="row mt-3" style="cursor: pointer;">
              <div
                [ngClass]="{'markThankyou-selected': select_message_type == 3,'markThankyou':select_message_type !=3}">
                <span (click)="selectMessage('Madad kay liye shukriya',3)">
                  {{'Madad kay liye shukriya' | translate}}
                </span>

                <span style="cursor: pointer;float: right;padding-right: 10px;"
                  (click)="editMesge('Madad kay liye shukriya')">
                  <img
                    [src]="select_message_type == 3 ? '../../../../assets/images/EditSign.svg':'../../../../assets/images/edit-sign2.svg'"
                    alt="Image"></span>
              </div>
            </div>
          </div>
          <div *ngIf="editpage">
            <div class="row mt-4">
              <span class="editheading">
                {{'Write your own thank you note' | translate}}
              </span>
            </div>
            <div class="row mt-3">
              <textarea class="editArea" [(ngModel)]="thankYouMessage"
                placeholder="{{thankYouMessage | translate}}"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <img id="post_animator_2" style="display: none;" src="../../../../assets/images/jp10_animation.gif"
              alt="Image">
            <button class="nextButton" data-dismiss="modal" (click)="submitThankYou()">{{'Close Request & Send Thank
              you' |translate}}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- <ng2-toasty [position]="'top-center'"></ng2-toasty> -->


  <div class="modal fade" id="audioInputModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="text-align: center;">
        <div class="modal-body" style="padding-bottom:0px;margin: auto;">
          <div class="audio-text text-center">{{'record_audio'|translate}}</div>
          <div class="col-12" style="position:relative; top:130px;left: -6px;"
          *ngIf="!tempAudioBlob" >
            <img *ngIf="!isRecording && !tempAudioBlob" (click)="startAudioRecording()"
              src="../../../../assets/images/audioButtons/record_audio_start.svg" alt="Image">
            <img *ngIf="isRecording && !tempAudioBlob" (click)="stopAudioRecording()"
              src="../../../../assets/images/audioButtons/record_audio_stop.svg" alt="Image">
          </div>
          <!-- *ngIf="voiceOptionSelected" -->
          <audio *ngIf="!isRecording && tempAudioBlob" controls
          style="margin-top:72px;">
            <source [src]="tempAudioBlob" type="audio/webm">
          </audio>
          <div id="progress" class="dyanmicProgress" *ngIf="!tempAudioBlob">
            <span id="progress-value"></span>
          </div>
          <div *ngIf="!tempAudioBlob" style="position: absolute; top: 100%; left: 40%;">
            {{timer}} Sec
          </div>
          <!-- <circle-progress *ngIf="!tempAudioBlob" [percent]="audioRecorderProgress" [outerStrokeWidth]="10"
            [innerStrokeWidth]="10" [titleFontSize]="24" [title]="setTitleOfProgressBar()" [unitsFontSize]="24"
            [animation]="true" [subtitle] [animationDuration]="300" [startFromZero]="false" [maxPercent]="100">
          </circle-progress>
          <div class="col-12 text-center" *ngIf="!tempAudioBlob">
            <span class="audio-text grey-color">
              {{transformIntoSec(audioRecorderProgress)}}
            </span>
          </div> -->
          <!-- <div class="max_recording_time_text">{{'max_recording_time' | translate}} {{AUDIODURATION}}
            {{'seconds' | translate}}</div> -->
        </div>
        <div class="modal-footer col-sm-12 col-xs-12" style="padding-top:0px;">

          <div class="col-12 text-center" style="margin-top:24px; margin-bottom:52px;">
            <img *ngIf="!isRecording && tempAudioBlob" style="cursor:pointer"
            (click)="tempAudioBlob= null;startAudioRecording()"
            src="../../../../assets/images/restart-recording.svg" alt="Image"  />
          </div>

          <div class="col-6">
            <button class="submit-btn" (click)="clearRecordedData()"  data-dismiss="modal"
            [disabled]="isRecording && !tempAudioBlob">
              {{'cancel'|translate|titlecase}}
            </button>
            <!-- <img *ngIf="isRecording || !tempAudioBlob"
              src="../../../../assets/images/audioButtons/record_audio_save_disable.svg" alt="Image">
            <img *ngIf="!isRecording && tempAudioBlob && !audioBlobURL" (click)="saveAudio()"
              src="../../../../assets/images/audioButtons/record_audio_save_enable.svg" alt="Image">
            <img *ngIf="!isRecording && audioBlobURL" data-dismiss="modal"
              src="../../../../assets/images/audioButtons/record_audio_save_active.svg" alt="Image"> -->
          </div>

          <div class="col-6 p-0">
            <button class="submit-btn" [disabled]="isRecording || !tempAudioBlob"
            *ngIf="isRecording || !tempAudioBlob">
              {{'submit'|translate|titlecase}}
            </button>
            <button class="submit-btn" *ngIf="!isRecording && tempAudioBlob && !audioBlobURL"
            (click)="saveAudio()">
              {{'submit'|translate|titlecase}}
            </button>
            <button style="display:none" #closeAudioModal data-dismiss="modal"></button>
            <!-- <button class="submit-btn"*ngIf="!isRecording && audioBlobURL" data-dismiss="modal">
              {{'submit'|titlecase}}
            </button> -->
            <!-- <img *ngIf="isRecording || !tempAudioBlob"
              src="../../../../assets/images/audioButtons/record_audio_delete_disable.svg" alt="Image">
            <img *ngIf="!isRecording && tempAudioBlob && !audioBlobURL" (click)="clearRecordedData()"
              src="../../../../assets/images/audioButtons/record_audio_delete_enable.svg" alt="Image">
            <img *ngIf="!isRecording && audioBlobURL" data-dismiss="modal" (click)="clearRecordedData()"
              src="../../../../assets/images/audioButtons/record_audio_delete_active.svg" alt="Image"> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="imageModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content jaano-modal">
        <div class="modal-header text-center">
          <span class="modal-title-1 heading text-center" id="exampleModalLabel">
            {{'upload_image' | translate}}
          </span>
        </div>

        <div class="modal-body">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="modal-box justify-content-center" 
              data-dismiss="modal" data-target="#imageModal" (click)="imageUploadOption(1)"
                data-toggle="modal" data-target="#imageCaptureModal">
                <img src="../../../../assets/images/new_help_icons/capcture-img-icon.svg" 
                alt="Take Picture" width="16px" height="16px"
                class="mr-2 choose-picture-active">
                <img src="../../../../assets/images/new_help_icons/capcture-img-icon.svg" 
                alt="Take Picture" width="16px" height="16px" class="mr-2 choose-picture">
                <span class="upload-image-text">{{'capture_photo' | translate}}</span>
              </div>
            </div>
          </div>
          <div class="row justify-content-center" (click)="imageUploadOption(2)">
            <div class="col-12">
              <label for="file-input1" class="modal-box file-upload-media
              justify-content-center">
                  <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
                  class="mr-2 choose-picture-active" width="20px" height="18px">
                  <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
                  class="mr-2 choose-picture" width="20px" height="18px">
                  <span class="upload-image-text">
                    {{'choose_photo' | translate}}
                  </span>
                  <!-- <img src="../../../../assets/images/home-images/picture-inactive.svg" alt="Picture"
                    class="choose-picture">
                  <img src="../../../../assets/images/home-images/picture-active.svg" alt="Picture"
                    class="choose-picture-active"> -->
              </label>
            </div>
            <input id="file-input1" (change)="handleFileSelect($event)" type="file" accept='image/*' multiple />
  
          </div>
        </div>
        <div class="modal-footer">
          <button class="float-right modal-close-btn"
          #closeImagebutton data-dismiss="modal" aria-label="Close">
            {{'cancel'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content jaano-modal">
        <div class="modal-header text-center">
          <span class="modal-title-1 heading text-center" id="exampleModalLabel">
            {{'upload_video' | translate}}
          </span>
          <!-- <h5 class="modal-title heading d-inline-block" id="exampleModalLabel">{{'upload_a_video' | translate}}</h5>
          <button type="button" #closeVideobutton class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>

        <div class="modal-body">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="modal-box justify-content-center" data-dismiss="modal" 
              (click)="videoUploadOption(2)" data-toggle="modal"
                data-target="#videoRecordModal">
                <img src="../../../../assets/images/new_help_icons/capture-video-icon.svg" 
                alt="Take video" width="16px" height="16px"
                class="mr-2 choose-picture-active">
                <img src="../../../../assets/images/new_help_icons/capture-video-icon.svg" 
                alt="Take video" width="16px" height="16px" class="mr-2 choose-picture">
                <span class="upload-image-text">{{'capture_video' | translate}}</span>
              </div>
            </div>
          </div>
          <div class="row justify-content-center" (click)="videoUploadOption(1)">
            <div class="col-12">
              <label for="file-input-video" class="modal-box file-upload-media 
              justify-content-center">
                <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
                class="mr-2 choose-picture-active" width="20px" height="18px">
                <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
                class="mr-2 choose-picture" width="20px" height="18px">
                <span class="upload-image-text">
                  {{'choose_video' | translate}}
                </span>
              </label>
              <input style="display: none;" id="file-input-video" (change)="handleVideoSelection($event)" type="file"
                accept="video/*" />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="float-right modal-close-btn"
          #closeVideobutton data-dismiss="modal" aria-label="Close">
            {{'cancel'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <button id="videoRecordModalButton" [hidden]="true" data-dismiss="modal" data-target="#videoRecordModal"></button>
  <div class="modal fade" id="videoRecordModal" tabindex="-1" role="dialog" aria-labelledby="videoRecordModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div>
            <div class="col-12 col-sm-12 col-xs-12" style="text-align: right;">
              <i class="fa fa-close cross_icon" data-dismiss="modal" data-target="#videoRecordModal"
                (click)="remove_video(video)"></i>
            </div>
            <div class="col-12 col-xs-12 col-sm-12">
              <video #video1 class="video mb-3 mr-2 mt-2"></video>
            </div>
            <div class="col-12 mb-3 sm-pd0">
              <div class="text-center">
                <button *ngIf="!isRecordingVideo && !showVideo" md-raised-button color="primary"
                  (click)="startVideoRecording()" class="file-upload">
                  <img src="../../../../assets/images/start_recording_video.svg" alt="Image" />
                </button>
                <button *ngIf="isRecordingVideo && !showVideo" md-raised-button color="primary" data-dismiss="modal"
                  data-target="#videoRecordModal" (click)="stopVideoRecording()" class="file-upload">
                  <img src="../../../../assets/images/stop_recording_video.svg" alt="Image" />
                </button>
                <button *ngIf="showVideo" md-raised-button color="primary" data-dismiss="modal"
                  data-target="#videoRecordModal" (click)="remove_video()" class="file-upload">
                  <img src="../../../../assets/images/remove_video.svg" alt="Image" />
                </button>
                <button *ngIf="showVideo" md-raised-button color="primary" data-dismiss="modal"
                  data-target="#videoRecordModal" class="file-upload">
                  <img src="../../../../assets/images/accept_video.svg" alt="Image" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="nextButton">{{'Close' | translate}}</button>
      </div>
    </div>
  </div>


  <!-- Image capture start -->
  <div class="modal fade" id="imageCaptureModal" *ngIf="imageClickOption" tabindex="-1" role="dialog"
    aria-labelledby="imageCaptureModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <webcam class="webcamClass" style="display: block; width: 100%;" [trigger]="triggerObservable"
              (imageCapture)="handleImage($event)" *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch"
              [switchCamera]="nextWebcamObservable" [videoOptions]="videoOption" [height]="280" [width]="280"
              [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
            </webcam>
            <button class="file-upload actionBtn">
            </button>
            <button (click)="triggerSnapshot();" data-dismiss="modal" data-target="#imageCaptureModal"
              class="file-upload actionBtn">
              <img src="../../../../assets/images/capture_photo.svg" alt="Image" />
            </button>
            <button (click)="toggleWebcam();" data-dismiss="modal" data-target="#imageCaptureModal"
              class="file-upload actionBtn">
              <img src="../../../../assets/images/camera_off.svg" alt="Image" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button id="imageCaptureModalButton" [hidden]="true" data-dismiss="modal" data-target="#imageCaptureModal"></button>
  <!-- Image capture end -->

<!-- Modal for contact-helper -->
<div class="modal fade" id="contactEmitra" data-backdrop="static" data-keyboard="false"
 tabindex="-1" aria-labelledby="contactEmitra" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content custom-modal">
      <div class="modal-header text-center" *ngIf="!noResponseFromEmitra.submit">
        <span class="paid-txt-18-16 w-500">{{'how_try_to_contact'|translate}}</span>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="!noResponseFromEmitra.submit">
          <div class="content-start">
            <img src="../../../../assets/images/check-box-active.svg" alt="check-box-active" style="cursor: pointer;"
            width="24px" height="24px" style="margin-right:4px;" *ngIf="noResponseFromEmitra.viaPhone" (click)="noResponseFromEmitra.viaPhone = false;">
            <img src="../../../../assets/images/check-box-inactive.svg" alt="check-box-inactive" style="cursor: pointer;"
            width="24px" height="24px" style="margin-right:4px;" *ngIf="!noResponseFromEmitra.viaPhone" (click)="noResponseFromEmitra.viaPhone = true;">
            <span class="paid-txt-16-14 text-grey-8">{{'Via Phone'|translate}}</span>
          </div>
          <div class="content-start mt-16">
            <img src="../../../../assets/images/check-box-active.svg" alt="check-box-active" style="cursor: pointer;"
            width="24px" height="24px" style="margin-right:4px;"  *ngIf="noResponseFromEmitra.viaSms" (click)="noResponseFromEmitra.viaSms = false;">
            <img src="../../../../assets/images/check-box-inactive.svg" alt="check-box-inactive" style="cursor: pointer;"
            width="24px" height="24px" style="margin-right:4px;"  *ngIf="!noResponseFromEmitra.viaSms" (click)="noResponseFromEmitra.viaSms = true;">
            <span class="paid-txt-16-14 text-grey-8">{{'Via SMS'|translate}}</span>
          </div>
          <div class="content-start mt-16">
            <img src="../../../../assets/images/check-box-active.svg" alt="check-box-active" style="cursor: pointer;"
            width="24px" height="24px" style="margin-right:4px;"  *ngIf="noResponseFromEmitra.viaWhatsapp" (click)="noResponseFromEmitra.viaWhatsapp = false;">
            <img src="../../../../assets/images/check-box-inactive.svg" alt="check-box-inactive" style="cursor: pointer;"
            width="24px" height="24px" style="margin-right:4px;"  *ngIf="!noResponseFromEmitra.viaWhatsapp" (click)="noResponseFromEmitra.viaWhatsapp = true;">
            <span class="paid-txt-16-14 text-grey-8">{{'Via WhatsApp'|translate}}</span>
          </div>
        </ng-container>
        <div class="content-center" style="height:100px;" *ngIf="noResponseFromEmitra.submit && !noResponseFromEmitra.viaPhone">
          <div style="text-align: center;display: block;margin: auto;">
            <span class="paid-txt-16-14 text-grey-8">{{'Please try contacting via phone'|translate}}</span>
            <div style="text-align: center;margin-top:8px;">
              <span class="paid-txt-16-14">{{assignedEmitraPhoneNumber}}</span>
            </div>
          </div>
        </div>
        <div class="content-center" style="height:154px;" *ngIf="noResponseFromEmitra.submit && noResponseFromEmitra.viaPhone">
          <div style="text-align: center;display: block;margin: auto;">
            <span class="paid-txt-16-14 w-700">{{'sorry_for_inconvenience'|translate}}</span>
            <div style="text-align: center;margin-top:16px;">
              <span class="paid-txt-16-14">{{'support_as_soon'|translate}}</span>
            </div>
          </div>
        </div>
        <div class="content-around mt-16">
          <button type="button" class="no-border-btn" data-dismiss="modal" style="padding: 4px 16px;"
          (click)="noResponseFromEmitra.submit = false;onReportSubmitc1Closed()"
          *ngIf="!noResponseFromEmitra.submit || !noResponseFromEmitra.viaPhone">{{'Cancel'|translate}}</button>
          <button type="button" class="solid-btn paid-txt-16-14 text-white" *ngIf="!noResponseFromEmitra.submit" style="width:120px;"
          (click)="noResponseFromEmitra.submit = true;onReportSubmitc1()">{{'submit'|translate}}</button>
          <button style="display:none;" data-dismiss="modal" #closeSupportDialog>
          </button>
          <a [href]="'tel:'+assignedEmitraPhoneNumber"  class="solid-btn paid-txt-16-14 text-white"
          *ngIf="noResponseFromEmitra.submit && !noResponseFromEmitra.viaPhone"   
          style="width:120px;text-decoration: none;" (click)="onCloseSupportDialog()">
            {{'call'|translate}}
          </a> 
          <!-- <button type="button" class="solid-btn paid-txt-16-14 text-white" *ngIf="noResponseFromEmitra.submit && !noResponseFromEmitra.viaPhone"
            data-dismiss="modal"  style="width:120px;">{{'call'|translate}}</button> -->
          <button type="button" class="solid-btn paid-txt-16-14 text-white" *ngIf="noResponseFromEmitra.submit && noResponseFromEmitra.viaPhone"
           data-dismiss="modal" style="width: 160px;margin: auto;" (click)="onSumitAskForSupport()">{{'okay'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>