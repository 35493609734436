<div class="card mb-12 box-shadow-1">
  <div class="card-body hr-card" (click)="navigateToHelpRequestById(hr?.helpid)">
    <div class="row">
      <div class="col-10">
        <span class="user-id">@{{hr.username}}</span>
        <span class="ml-2 date-time">{{hr.timestamp|date:'short'}}</span>
      </div>
      <div class="col-2 col-sm-auto ml-auto" (click)="$event.stopPropagation()">
        <div class="dropdown pl-2" (click)="$event.stopPropagation()">
          <span class="ellipsis_container" (click)="$event.stopPropagation()">
            <span class="sm-wid-20 ellipsis_options"></span>
          </span>
          <div class="dropdown-content wid-125px right-0">
            <div class="text-left text-dropdown cursor-p" (click)="$event.stopPropagation();deletePaidHelpRequest(hr?.helpid,hr?.username)">
              <img src="../../../../assets/images/delete-icon.svg" alt="delete" class="sm-ht-16">
              <span class="ml-2"> {{'delete'|translate}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <span class="title">
          {{hr?.help_title}}
        </span>
      </div>
      <div class="col-12">
        <span class="description">
          {{hr?.help_description}}
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <button class="btn-status mr-2">
          <img alt="tick" width="15px" height="15px" 
          class="img-circle mr-1" *ngIf="hr.hr_user_status == 'completed'"
          src="../../../../assets/images/tick_circle.svg" />
          <img alt="tick" width="15px" height="15px" 
          class="img-circle mr-1" *ngIf="hr.hr_user_status == 'open'"
          src="../../../../assets/images/new_help_icons/help_open.svg" />
          <img alt="tick" width="15px" height="15px" 
          class="img-circle mr-1" *ngIf="hr.hr_user_status == 'processing'"
          src="../../../../assets/images/new_help_icons/pending-icon.svg" />
          {{hr.hr_user_status|translate|titlecase}}
        </button>
        <button class="category mr-2">
          {{hr.categoryText}}
        </button>
        <button class="sub-category mr-2">
          {{hr.subCategoryText}}
        </button>
      </div>
    </div>
  </div>
</div>
      