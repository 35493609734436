import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help-request-card',
  templateUrl: './help-request-card.component.html',
  styleUrls: ['./help-request-card.component.scss']
})
export class HelpRequestCardComponent implements OnInit {

  @Input() hr : {
    username:string,
    timestamp:string,
    hr_user_status: 'completed'|'open'|'processing',
    categoryText:string,
    subCategoryText:string,
    help_title:any,
    help_description:any,
    helpid:any
  }|any;

  @Output() deletePaidHelpRequestEvent = new EventEmitter<any>();
  constructor(private router: Router) { }

  ngOnInit() {
    // console.log('hr',this.hr)
  }


  navigateToHelpRequestById = (helpid:any) =>{
    this.router.navigate(['./help-request/getHelp-RequestbyId'], { queryParams: { Id: helpid } });
  }


  deletePaidHelpRequest = (helpid:any,username:any) =>{
    this.deletePaidHelpRequestEvent.emit({helpid:helpid,username:username});
  }
}
