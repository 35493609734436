import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from 'ngx-cookie';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { map } from "rxjs/operators"; 

@Injectable({
  providedIn: 'root'
})
export class LanguageTranslationService {
  language: any=null;
  private subject: Subject<any> = new Subject<any>();
  selectedLanguageValue: BehaviorSubject<any>;

  constructor(private translateService: TranslateService,
    private cookieService: CookieService) {
    this.selectedLanguageValue = new BehaviorSubject(this.getLanguage());
  }

  setSelectedLanguage(selectedLang:any) {
    localStorage.setItem('lang', selectedLang);
    this.selectedLanguageValue.next(selectedLang);
  }

  getSelectedLanguage() {
    return this.selectedLanguageValue;
  }

  startLanguageTranslator() {
    this.language = localStorage.getItem("lang");
    if (this.language) {
      this.translateService.use(this.language);
    }
    else {
      this.translateService.use("en");
      this.translateService.setDefaultLang("en");
    }
  }

  getLanguage() {
    let selectedLanguage:any = '';
    if (localStorage.getItem("lang")) {
      selectedLanguage = localStorage.getItem("lang");
    }
    this.cookieService.put('LanguageSelected',selectedLanguage || 'en');
    return selectedLanguage;

  }

  getTranslationOf(key:any): Observable<any> {
    return this.translateService.get(key).pipe(map((translatedText: any) => { 
      return translatedText;
    }));

    // this.translateService.get(key).subscribe(translatedText => {
    //   this.subject.next(translatedText);
    // });
    // return this.subject.asObservable();
  }
  getTranslated(key:any){
    let transaltedKey='';
    this.translateService.get(key).subscribe(translatedText => {
      this.subject.next(translatedText);
      transaltedKey= translatedText;
    });
    return transaltedKey;
  }
}
