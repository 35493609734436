import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { UserSurvey } from "../modules/help-request/get-specific-help-request/user-survey";

@Injectable({
  providedIn: "root",
})
export class GetSpecificHelpRequestService {
  // subscribe(arg0: (res: any) => void) {
  //   throw new Error("Method not implemented.");
  // }
  public requestData = new BehaviorSubject<UserSurvey>({
    username: '',
    helpid: '',
    ctg: '',
    sub_ctg: '',
  });

  setRequestData(req: UserSurvey) {
    this.requestData.next(req);
  }

  getRequestData(): Observable<UserSurvey> {
    return this.requestData.asObservable();
  }
}
