import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatSliderModule } from "@angular/material/slider";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatDividerModule } from "@angular/material/divider";
import { HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ArchwizardModule } from "angular-archwizard";

import { ChangeLocationDialog } from "../shared-components/new-change-location/new-change-location.component";
import { VideoPlayer } from "../shared-components/video_player_dialog/video_player_dialog";
import { LanguageSelectionDialog } from "../shared-components/LanguageDialog/selectlanguage.dialog";
import { AuthenticationComponent, ReferralDialogComponent } from "../shared-components/authentication/authentication.component";
// import { InternationalPhoneNumberModule } from "ngx-international-phone-number";
import { JoyrideModule } from "ngx-joyride";
import { SafePipe } from "../shared-components/safe.pipe";
import { NumberFormatDecimal } from "../shared-components/numberformateer";

import { ReportPostDialogComponent } from "../shared-components/report-post-dialog/report-post-dialog.component";
//Language Commponents
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { ToastyModule } from "ng2-toasty";
import { StringcheckerService } from "../services/StringcheckerService";
import { UserLevelUpDialogComponent } from "../shared-components/user-level-up-dialog/user-level-up-dialog.component";
// import { LeftsectionlocalstarsComponent } from "../shared-components/leftsectionlocalstars/leftsectionlocalstars.component";
// import { RightsharecardsectionComponent } from "../shared-components/rightsharecardsection/rightsharecardsection.component";

import { ConfirmDialogOneComponent } from "../modules/help-request/get-specific-help-request/confirm-dialog-one/confirm-dialog-one.component";
import { ConfirmDialogPaidHelpComponent } from '../modules/help-request/get-specific-help-request/confirm-dialog-paid-help/confirm-dialog-paid-help.component';
import { WebcamModule } from "ngx-webcam";
import { HelpRequestCardComponent } from "../shared-components/help-request-card/help-request-card.component";
// import { MoreContentForYouSectionComponent } from "../shared-components/morecontentforyousection/morecontentforyousection.component";
// import { HelpFeedLocalStarsComponent } from "../modules/help-request/help-feed-local-stars/help-feed-local-stars.component";
// import { HelpFeedGenericCardsComponent } from "../modules/help-request/help-feed-generic-cards/help-feed-generic-cards.component";
import { DashboardTopBarComponent } from "../shared-components/dashboard-top-bar/dashboard-top-bar.component";
import { CategorieslistComponent } from "../shared-components/categorieslist/categorieslist.component";
import { RedeemComponent } from "../modules/user-profile/gamification/gamification.component";
import { AboutFaqComponent } from "../modules/user-profile/about-faq/about-faq.component";
import { TermsOfUseComponent } from "../modules/terms_and_conditions/terms_of_use/terms-of-use.component";
import { RefundPolicyComponent } from "../modules/terms_and_conditions/refund_policy/refund-policy.component";
import { PrivacyPolicyComponent } from "../modules/terms_and_conditions/privacy_policy/privacy-policy.component";
import { LandingPageComponent, ViewSolutionDialogComponent } from "../modules/landing-page/landing-page.component";
import { HelpreqoptComponent } from "../modules/helpreqopt/helpreqopt.component";
import { NguCarouselModule } from "@ngu/carousel";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { PaymentFailedPopupComponent } from "../modules/help-request/get-specific-help-request/get-specific-help-request.component";
import { CreateHelpRequestDialog, OtpDialog, SelectCategoryDialog } from "../modules/expanded-view/expanded-view.component";
import { InfoDialog, MediaDialog } from "../modules/help-request/create-help-request/create-help-request.component";
// import { NgCircleProgressModule } from "ng-circle-progress";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "../assets/i18n/", ".json");
}
@NgModule({
  declarations: [

    ConfirmDialogOneComponent,
    AuthenticationComponent,
    ChangeLocationDialog,
    LanguageSelectionDialog,
    VideoPlayer,
    ReportPostDialogComponent, 
    SafePipe,  // ask for remove if not using trending component.
    UserLevelUpDialogComponent,
    NumberFormatDecimal,
    // LeftsectionlocalstarsComponent, // ask if we are removing local stars component
    // RightsharecardsectionComponent, // ask if we are removing local stars component
    ConfirmDialogPaidHelpComponent,
    HelpRequestCardComponent,
    // MoreContentForYouSectionComponent,// ask if we are removing more content component.
    // HelpFeedLocalStarsComponent, // ask we are removing feed local star from home
    // HelpFeedGenericCardsComponent, // ask we are removing feed generic card from home
    DashboardTopBarComponent,
    CategorieslistComponent,
    ReferralDialogComponent,
    RedeemComponent,  // are we using this redeem component
    AboutFaqComponent,
    TermsOfUseComponent,
    RefundPolicyComponent,
    PrivacyPolicyComponent,
    LandingPageComponent,
    ViewSolutionDialogComponent, // are we using landing page component
    HelpreqoptComponent,
    CreateHelpRequestDialog,
    SelectCategoryDialog,
    OtpDialog,
    MediaDialog,
    InfoDialog,
    PaymentFailedPopupComponent
  ],
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatSliderModule,
    MatToolbarModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    MatExpansionModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // InternationalPhoneNumberModule,
    // ToastyModule.forRoot(),
    MatAutocompleteModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,// ask if we are removing notification module.
    MatDividerModule,
    WebcamModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
    }),
    JoyrideModule.forChild(),
    ArchwizardModule,
    NguCarouselModule,
    InfiniteScrollModule
  ],
  exports: [
    MatButtonModule,
    MatMenuModule,
    MatSliderModule,
    MatToolbarModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatExpansionModule,
    MatSelectModule,
    MatCheckboxModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AuthenticationComponent,
    // InternationalPhoneNumberModule,
    MatAutocompleteModule,
    MatDividerModule,
    // ToastyModule,
    ReportPostDialogComponent,
    ChangeLocationDialog,
    LanguageSelectionDialog,
    VideoPlayer,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    SafePipe,
    UserLevelUpDialogComponent,
    NumberFormatDecimal,
    // LeftsectionlocalstarsComponent,  
    // RightsharecardsectionComponent,   
    MatSlideToggleModule,WebcamModule, 
    HelpRequestCardComponent,
    // MoreContentForYouSectionComponent,  
    // HelpFeedLocalStarsComponent,
    // HelpFeedGenericCardsComponent,
    DashboardTopBarComponent,
    CategorieslistComponent,
    ReferralDialogComponent,
    RedeemComponent,
    AboutFaqComponent,
    TermsOfUseComponent,
    RefundPolicyComponent,
    PrivacyPolicyComponent,
    LandingPageComponent,
    ViewSolutionDialogComponent,
    HelpreqoptComponent,
    CreateHelpRequestDialog,
    SelectCategoryDialog,
    OtpDialog,
    MediaDialog,
    InfoDialog,
    PaymentFailedPopupComponent
    // NgCircleProgressModule
  ],
  entryComponents: [
    ConfirmDialogOneComponent,
    ConfirmDialogPaidHelpComponent,
  ],
  providers: [ StringcheckerService],
})
export class SharedModuleModule {}
