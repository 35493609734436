import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class MoengageEventsService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object, ) { }

  public eventEmitter(
    eventName: string, eventJSON:any) {
    if (isPlatformBrowser(this.platformId) && (window as any).webengage) {
      (window as any).webengage.track(eventName, eventJSON);
      // (window as any).Moengage.track_event.eventEmitter(eventName, eventJSON);
    }
  }
}
