import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, PLATFORM_ID } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import * as firebase from 'firebase/app';
import { CookieService } from 'ngx-cookie';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from "@angular/common";

import { CommonService } from '../../services/common.service';
import { DataService } from "../../services/data.service";
import { environment } from "../../../environments/environment";
import { LanguageTranslationService } from "../../services/language-translation-service";
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { UserDetailService } from '../../services/user-detail.service';
// import { UpdateUsernameDialogComponent } from "../../shared-components/update-username-dialog/update-username-dialog.component";
import { ImgTextButtonCustomDialogComponent } from "../../shared-components/img_text_button_customdiaog/img_text_button_customdiaog";
import { AuthService } from '../../services/auth.service';
import { PhoneNo, SessionLogin, VerifyOtp } from 'src/app/models/models';

declare var grecaptcha: any;
declare let gtag: Function, gtag_report_conversion:any;

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  @ViewChild('loginCheckbox')
  private loginCheckbox!: MatCheckbox;
  @ViewChild('signupCheckbox')
  private signupCheckbox!: MatCheckbox;
  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  //signuplogin, ifsignup , iflogin, otpScreen
  referralCode;
  selectedscreen = 'iflogin';
  importData;
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required,
      Validators.pattern("[0-9 ]{10}")])
  });
  isloading: boolean = false;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  username:any;
  phoneno:any;
  httpPostParameters: any = {};
  captchaloaded: boolean = false;
  reCAPTCHASolved: boolean = true; //false
  reCAPTCHAExpired: boolean = false;
  timer: string="";
  timerId: any;
  otpResendTimeout: string = '01'; //in minutes
  otpNotReceived: string = "";
  ngIfAfterOtpSent: boolean = false;
  ngIfOtpTimeout: boolean = false;
  widgetId: any;
  windowRef: any;
  last4digits: any;
  loginDetails: any = {};
  verificationCode = '';
  webNotificationToken:any;
  swanitiUrl: string = `${this.commonService.getSwanitiLink()}/ugc-policy/`;
  selected_username_pattern:any;
  nameString: string="";
  wasUserSignedUp = false;

  prevScreen:any = null;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '45px',
      'height': '45px',
      'font-size': '15px'
    }
  };
  details = {
    lat: '',
    lng: '',
    language: ''
  };

  onLoginRequestInProcess: boolean = false;
  onSignupRequestInProcess: boolean = false;
  optionsJSON:any;
  shakeIt = false;
  userActivity:any;
  username_suggestions = [];
  sendOTPfnCalled = false;
  autoChangeScreen = false;
  showModal = false;
  fromAds= false;
  fromSolution = false;

  // ==> (changes)setting it false for removing referral feature...   don't set if want to add referral feature.
  showReferralCode = false;
  disableEditReferral= false;
  // ==> (changes)setting it true for removing referral feature...   set to false when have to add referral feature and replace !fromAds from html and 
  // replace with fromGoogleAds in html file
  fromGoogleAds = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<AuthenticationComponent>,
    private userService: UserDetailService,
    private dataService: DataService,
    private commonService: CommonService,
    private languageService: LanguageTranslationService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private cookieService: CookieService,
    private router: Router,
    private authSerivce: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.googleAnalyticsService.eventEmitter('login_open', "login pop-up","open login pop-up");
    (window as any).hj('event', 'login_open');
    if (typeof (data) == 'object' && Object.keys(data).length > 0) {
      this.importData = data;
      this.showModal = data.showModal?true:false;
      if(this.importData.autoChangeScreen){
        this.autoChangeScreen = true;
      }
      if(data.fromAds){
        this.fromAds = true;
      }
      if(data.fromGoogleAds){
        this.fromGoogleAds = true;
      }
      if(data.fromSolution){
        this.fromSolution = true;
      }
    }
    if (Boolean(firebase)) {
      if (!firebase.apps)
        firebase.initializeApp(environment.firebase);
      else if (!firebase.apps.length)
        firebase.initializeApp(environment.firebase);
    }
    this.windowRef = window;
    this.languageService.startLanguageTranslator();

    // getting referral code
    this.referralCode = localStorage.getItem('referralCode')?localStorage.getItem('referralCode'):null;
    if(this.referralCode){
      this.disableEditReferral = true;
    }

    // ==> (changes) uncomment the following..
    // if(this.cookieService.get('fromGoogleAds')){
    //   this.showReferralCode = false;
    // }
    // else{
    //   this.showReferralCode = true;
    // }
  }

  ngOnInit() {
    if(this.selectedscreen == 'iflogin'){
      this.renderReCAPTCHA('recaptcha-container');
    }
    this.commonService.getWebNotificationToken().subscribe((res) => {
      this.webNotificationToken = res;
    });
    firebase.auth().languageCode = this.languageService.getLanguage() || 'en';
    if(!this.fromAds){
      this.changeURLForDialog(true);
    }
  }

  ngOnDestroy() {
    if(!this.fromAds){
      this.changeURLForDialog(false);
    }
    this.windowRef.recaptchaVerifier = null;
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  closeModal() {
    this.googleAnalyticsService.eventEmitter('exit_login',"login pop-up","exit login");
    (window as any).hj('event', 'exit_login');
    this.dialogRef.close();
  }
  closeModal2() {
    this.googleAnalyticsService.eventEmitter('exit_login',"login pop-up","exit login");
    (window as any).hj('event', 'exit_login');
    this.dialogRef.close(false);
  }
  closeModal3() {
    this.googleAnalyticsService.eventEmitter('exit_login',"login pop-up","exit login");
    (window as any).hj('event', 'exit_login');
    const obj= {allClose:true}
    this.dialogRef.close(obj);
  }

  changeURLForDialog(isVisible:any) {
    if (isVisible) {
      this.router.navigate([], {
        queryParams: {
          showLoginDialog: isVisible
        },
        queryParamsHandling: 'merge',// preserve the existing query params in the route
      });
    } else {
      var snapshot = this.route.snapshot;
      const params = { ...snapshot.queryParams };
      delete params['showLoginDialog']
      if (this.userActivity == 'login') {
        params['userLoggedIn'] = true;
      } else if (this.userActivity == 'signup') {
        params['userSignedUp'] = true;
      }
      const requestCreated= localStorage.getItem('requestCreated');
      if(!requestCreated){
        setTimeout(()=>{
          this.router.navigate([], { queryParams: params});
        }, 1000)
      }
      else{
        localStorage.removeItem('requestCreated');
      }
    }
  }

  pagechange(selectedscreen:any) {
    const element:any = document.getElementById("errorMsg");
    element.innerHTML = "";
    // if (this.windowRef && this.windowRef.recaptchaVerifier) {
    //   this.windowRef.recaptchaVerifier.clear();
    // }
    if (selectedscreen == 'iflogin') {
      setTimeout(()=>{
        this.resetReCAPTCHA();
      }, 0);
      this.googleAnalyticsService.eventEmitter("web_login", "", "User trying to Login.");
    }
    else if (selectedscreen == 'ifsignup') {
      this.renderReCAPTCHA('recaptcha_container_signup');
      this.googleAnalyticsService.eventEmitter("web_signup", "", "User trying to sign-up.");
      this.setUsernameOption();
    }

    if (selectedscreen == 'otpScreen') {
      this.prevScreen = this.selectedscreen;
      this.googleAnalyticsService.eventEmitter("web_otp_screen", "", "User navigated to otp-screen.");
    }
    this.selectedscreen = selectedscreen;
    // this.dialogRef.updateSize('30rem', '35rem');
  }

  // deprecated
  validateUserName = (event: any) => {
    const pattern = /[a-z0-9_.]+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  shakeDialog() {
    // this.shakeIt = true;
    // setTimeout((arg) => {
    //   this.shakeIt = false;
    // },
    // 300);
  }

  selectPattern(pattern:any){
    this.selected_username_pattern = pattern;
  }

  getUserNameoption(pattern:any) {
    return this.optionsJSON[pattern];
  }

  //Deprecated
  setUsernameOption() {
    // this.optionsJSON = {};
    // if (this.nameString) {
      // let name_with_pattern = this.nameString.toLowerCase().replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').trim();
      // this.optionsJSON['name.lastname'] = name_with_pattern.replace(/\s/g, '.');
      // if (this.nameString.split(/\s/g).length > 1) {
      //   this.optionsJSON['name_lastname'] = name_with_pattern.replace('.', '').replace(/\s/g, '_');
      // } else {
      //   this.optionsJSON['name_lastname'] = name_with_pattern.replace('.', '').replace(/\s/g, '_') + Math.floor((Math.random() * 998) + 1);
      // }
      // this.optionsJSON['name_lastname_yyyy'] = name_with_pattern.replace('.', '').replace(/\s/g, '_') + "_" + Math.floor((Math.random() * 998) + 1);

      // if (this.nameString.length > 1) {
        this.dataService.getDataByEntity('getUserNameSuggestions', {
          "inserted_Username": this.nameString || " "
        }).subscribe((result:any) => {
          if (result.success) {
            this.username_suggestions = result.data;
            if(this.username_suggestions.length > 0){
              this.selected_username_pattern = this.username_suggestions[0];
            }
          }
        });
      // }
    // }
  }

  checkActivityForContinueButtonOfLogin(){
    let phoneControl:any = this.phoneForm.controls.phone;
    let validator = (phoneControl.valid  || (window.location.hostname == "localhost" && phoneControl.value == '+911111122222')) && this.loginCheckbox.checked ? false : true;
    if(!validator && this.selectedscreen == 'iflogin' && !this.sendOTPfnCalled){
      this.sendOTPfnCalled = true;
      this.cd.detectChanges();
      // this.sendOtpOnLogin();
    }
    return validator;
  }

  changeValue($event:any){
    this.verificationCode = $event.target.value;
    if(this.verificationCode && this.verificationCode.length == 6){
      this.verifyCodeNew();
    }
  }

  checkActivityForContinueButtonOfSignup(){
    let phoneControl = this.phoneForm.controls.phone;
    let validator = phoneControl.valid && this.signupCheckbox.checked ? false : true;
    if(!validator && this.selectedscreen == 'ifsignup' && this.selected_username_pattern){
      this.sendOtpOnSignup();
    }
    return validator;
  }

  sendOtpOnSignup() {
    this.googleAnalyticsService.eventEmitter2("web_authentication_continue_to_get_otp", { platform: "web" });
    if (this.onSignupRequestInProcess) {
      return;
    }
    let phoneControl = this.phoneForm.controls.phone;
    if (phoneControl.valid) {
      if (!this.signupCheckbox.checked) {
        this.shakeDialog();
        this.showMessage("agree_to_ugc_policy");
        return;
      }
      if (this.reCAPTCHASolved && !this.reCAPTCHAExpired) {
        this.username = this.selected_username_pattern;
        if(!this.username) {
          this.shakeDialog();
          this.showMessage("login_activity_no_username_toast");
          return;
        }
        this.captchaloaded = true;
        const appVerifier = this.windowRef.recaptchaVerifier;
        this.phoneno = phoneControl.value;
        this.phoneno = this.phoneno.replace(/ /g, '');
        // this.reCAPTCHASolved = false;
        this.reCAPTCHAExpired = false;
        this.httpPostParameters = {
          "phone_no": this.phoneno,
          "username": this.username,
          "full_name": this.nameString,
          webNotificationToken: this.webNotificationToken,
          "language":(this.languageService.getLanguage() || 'en') == "te"  ? "tg" : (this.languageService.getLanguage() || 'en')
        }

        this.onSignupRequestInProcess = true;
        this.dataService.getDataByEntity('authenticateUser', this.httpPostParameters)
          .subscribe(
            res => {
              if (res.success == 'created') {
                firebase.auth().signInWithPhoneNumber(this.phoneno, appVerifier)
                  .then((result:any) => {
                  this.userActivity = 'signup';
                    this.googleAnalyticsService.eventEmitter2('web_Signup_Success', {platform : "web"});
                    this.onSignupRequestInProcess = false;
                    this.pagechange('otpScreen');
                    this.cd.detectChanges();
                    this.captchaloaded = false;
                    this.windowRef.confirmationResult = result;
                    this.last4digits = this.phoneno.substr(this.phoneno.length - 4);
                    this.timer = parseInt(this.otpResendTimeout, 8) + ":" + parseInt('00', 8);
                    this.otpNotReceived = "Didn't receive OTP? Retry in ";
                    this.cookieService.put('is_logged__in', 'true');
                    this.cookieService.put('username', this.username);
                    this.cookieService.put('mobile_number', this.phoneno);
                    this.startTimer(this.timer);
                    if (this.ngIfOtpTimeout)
                      this.ngIfOtpTimeout = false;
                    this.ngIfAfterOtpSent = true;
                    this.cd.detectChanges();
                  })
                  .catch((error:any) => {
                    this.googleAnalyticsService.eventEmitter2('web_Signup_Failed', {platform : "web"});
                    this.onSignupRequestInProcess = false;
                    this.cd.detectChanges();
                    this.resetReCAPTCHA();
                    this.captchaloaded = false;
                    this.showMessage(error && error.message ? error.message : "server_error");
                    return false;
                  });
              }
              else {
                if(res.success == 'username_exist'){
                  if (this.selected_username_pattern == 'name.lastname') {
                    this.selected_username_pattern  = 'name_lastname';
                  } else if (this.selected_username_pattern == 'name_lastname') {
                    this.selected_username_pattern  = 'name_lastname_yyyy';
                  } else if (this.selected_username_pattern == 'name_lastname_yyyy') {
                    this.selected_username_pattern  = 'name.lastname';
                  }
                }
                // if(res.success == 'account_exist'){
                //     this.sendOtpOnLogin();
                //   }
                this.googleAnalyticsService.eventEmitter2('web_Signup_Failed', {platform : "web"});
                this.onSignupRequestInProcess = false;
                this.cd.detectChanges();
                this.showMessage(res.success);
                this.resetReCAPTCHA();
                this.captchaloaded = false;
                return false;
              }
            },
            err => {
              this.googleAnalyticsService.eventEmitter2('web_Signup_Failed', {platform : "web"});
              this.onSignupRequestInProcess = false;
              this.cd.detectChanges();
              this.resetReCAPTCHA();
              this.captchaloaded = false;
              this.showMessage("server_error");
              return false;
            }
          )
      }
      else {
        this.showMessage("recaptcha_not_solved");
        return false;
      }
    }
    else {
      this.shakeDialog();
      this.showMessage("invalid_mobile");
      return false;
    }
  }

  sendOtpOnLoginNew(){
    // in new login approach we removed the send otp approach of old firebase and used custom api for send otp
    // we also called userauthentication api for getting username and other details which we will user later as it was also perviously used.
    // console.log('inside new send otp login new');
    // this.googleAnalyticsService.eventEmitter2("Web_Signup/Login_Continue", {platform: "web"});
    const reqOtpEvent = this.cookieService.get('reqOtpEvent');
    if(!reqOtpEvent){
      this.cookieService.put('reqOtpEvent','true');
      this.googleAnalyticsService.eventEmitter('req_otp',"login pop-up"," request OTP");
      (window as any).hj('event', 'req_otp');
    }
    if (this.onLoginRequestInProcess) {
      return;
    }
    let phoneControl:any = this.phoneForm.controls.phone;
    if (phoneControl.valid || (window.location.hostname == "localhost" && phoneControl.value == '+911111122222')) {
      // if (this.reCAPTCHASolved && !this.reCAPTCHAExpired) {
        if (!this.loginCheckbox.checked) {
          this.showMessage("agree_to_ugc_policy");
          return;
        }
        this.captchaloaded = true;
        const appVerifier = this.windowRef.recaptchaVerifier;
        this.phoneno = phoneControl.value;
        // this.phoneno = this.phoneno.replace(/ /g, '');
        this.phoneno = "+91"+ this.phoneno;
        const userLocation:any = localStorage.getItem('user_location');
        const storelocation = JSON.parse(userLocation);
        const referralCode = this.referralCode && this.referralCode.trim().length==6?this.referralCode.trim():null;
        this.httpPostParameters = {
          "phone_no": this.phoneno,
          webNotificationToken: this.webNotificationToken,
          "language":(this.languageService.getLanguage() || 'en') == "te"  ? "tg" : (this.languageService.getLanguage() || 'en'),
          "lat": storelocation.lat,
          "lng": storelocation.lng,
          "stcode11": localStorage.getItem('stcode11'),
          "dtcode11": localStorage.getItem('dtcode11'),
          "stname": localStorage.getItem('stname'),
          "dtname": localStorage.getItem('dtname'),
          "referred_by_code":referralCode    
        }
        this.onLoginRequestInProcess = true;
        this.dataService.getDataByEntity('userAuthentication', this.httpPostParameters)
          .subscribe(
            (res:any) => {
              if(res && res.success && res.userExists && this.referralCode && this.referralCode.length == 6 && this.showReferralCode){
                this.showReferral();
              }
              // if provided username is already exists.
              if(res.success== true && res.data && res.data == 'username_exist'){
                this.onLoginRequestInProcess = false;
                this.cd.detectChanges();
                this.captchaloaded = false;
                this.showMessage("Please try again.");
                return false;
              }
              else if (res.success == true) {
                const req:PhoneNo={
                  phone_no:this.phoneno
                }
                this.dataService.sendOTP(req).subscribe(response=>{
                  this.userActivity = 'login';
                  if(res && res.userCreated == true){
                    this.wasUserSignedUp = true;
                  }
                  this.pagechange('otpScreen');
                  this.googleAnalyticsService.eventEmitter2("Web_Signup/Login_OTP", {platform: "web"});
                  this.loginDetails = res;
                  localStorage.removeItem('notificationArr');
                  this.commonService.setUsername(this.loginDetails.username);
                  localStorage.setItem('phone_no', this.phoneno);
                  this.cookieService.put('username', this.loginDetails.username);
                  this.cookieService.put('mobile_number', this.phoneno);
                  this.last4digits = this.phoneno.substr(this.phoneno.length - 4);
                  this.timer = parseInt(this.otpResendTimeout, 8) + ":" + parseInt('00', 8);
                  this.otpNotReceived = "Didn't receive OTP? Retry in ";
                  this.changeNumber = false;
                  this.startTimer(this.timer);
                  if (this.ngIfOtpTimeout)
                    this.ngIfOtpTimeout = false;
                  this.ngIfAfterOtpSent = true;
                  this.onLoginRequestInProcess = false;
                  this.cd.detectChanges();
                },error=>{
                    this.sendOTPfnCalled = false;
                    this.onLoginRequestInProcess = false;
                    this.cd.detectChanges();
                    this.showMessage(error && error.message && !error.message.error?
                      error.message : "server_error");
                    return false;
                })
              }
              else {
                this.onLoginRequestInProcess = false;
                this.cd.detectChanges();
                if (res.msg != undefined) {
                  this.showMessage("server_error");
                }
                else {
                  this.showMessage("Number not registered.");
                }

                if(res.msg == "Number not registered"){
                  this.phoneForm.patchValue({
                    phone: this.phoneno 
                  });
                  this.pagechange('ifsignup');
                }
                return false;
              }
            },
            err => {
              this.onLoginRequestInProcess = false;
              this.showMessage("server_error");
              return false;
            }
          )
    }
    else {
      this.showMessage("invalid_mobile");
      return false;
    }   
  }

  sendOtpOnLogin() {
    // this.googleAnalyticsService.eventEmitter2("Web_Signup/Login_Continue", {platform: "web"});
    const reqOtpEvent = this.cookieService.get('reqOtpEvent');
    if(!reqOtpEvent){
      this.cookieService.put('reqOtpEvent','true');
      this.googleAnalyticsService.eventEmitter('req_otp',"login pop-up"," request OTP");
      (window as any).hj('event', 'req_otp');
    }
    if (this.onLoginRequestInProcess) {
      return;
    }
    let phoneControl:any = this.phoneForm.controls.phone;
    if (phoneControl.valid || (window.location.hostname == "localhost" && phoneControl.value == '+911111122222')) {
      if (this.reCAPTCHASolved && !this.reCAPTCHAExpired) {
        if (!this.loginCheckbox.checked) {
          this.showMessage("agree_to_ugc_policy");
          return;
        }
        this.captchaloaded = true;
        const appVerifier = this.windowRef.recaptchaVerifier;
        this.phoneno = phoneControl.value;
        // this.phoneno = this.phoneno.replace(/ /g, '');
        this.phoneno = "+91"+ this.phoneno;
        // this.reCAPTCHASolved = false;
        this.reCAPTCHAExpired = false;
        const userLocation:any = localStorage.getItem('user_location');
        const storelocation = JSON.parse(userLocation);
        const referralCode = this.referralCode && this.referralCode.trim().length==6?this.referralCode.trim():null;
        this.httpPostParameters = {
          "phone_no": this.phoneno,
          webNotificationToken: this.webNotificationToken,
          "language":(this.languageService.getLanguage() || 'en') == "te"  ? "tg" : (this.languageService.getLanguage() || 'en'),
          "lat": storelocation.lat,
          "lng": storelocation.lng,
          "stcode11": localStorage.getItem('stcode11'),
          "dtcode11": localStorage.getItem('dtcode11'),
          "stname": localStorage.getItem('stname'),
          "dtname": localStorage.getItem('dtname'),
          "referred_by_code":referralCode    
        }
        this.onLoginRequestInProcess = true;
        this.dataService.getDataByEntity('userAuthentication', this.httpPostParameters)
          .subscribe(
            (res:any) => {
              if(res && res.success && res.userExists && this.referralCode && this.referralCode.length == 6 && this.showReferralCode){
                this.showReferral();
              }
              // if provided username is already exists.
              if(res.success== true && res.data && res.data == 'username_exist'){
                this.onLoginRequestInProcess = false;
                this.cd.detectChanges();
                this.resetReCAPTCHA();
                this.captchaloaded = false;
                this.showMessage("Please try again.");
                return false;
              }
              else if (res.success == true) {
                firebase.auth().signInWithPhoneNumber(this.phoneno, appVerifier)
                  .then((result:any) => {
                    this.userActivity = 'login';
                    if(res && res.userCreated == true){
                      this.wasUserSignedUp = true;
                    }
                    this.pagechange('otpScreen');
                    this.googleAnalyticsService.eventEmitter2("Web_Signup/Login_OTP", {platform: "web"});
                    this.loginDetails = res;
                    localStorage.removeItem('notificationArr');
                    this.commonService.setUsername(this.loginDetails.username);
                    localStorage.setItem('phone_no', this.phoneno);
                    this.cookieService.put('username', this.loginDetails.username);
                    this.cookieService.put('mobile_number', this.phoneno);
                    this.captchaloaded = false;
                    this.windowRef.confirmationResult = result;
                    this.last4digits = this.phoneno.substr(this.phoneno.length - 4);
                    this.timer = parseInt(this.otpResendTimeout, 8) + ":" + parseInt('00', 8);
                    this.otpNotReceived = "Didn't receive OTP? Retry in ";
                    this.changeNumber = false;
                    this.startTimer(this.timer);
                    if (this.ngIfOtpTimeout)
                      this.ngIfOtpTimeout = false;
                    this.ngIfAfterOtpSent = true;
                    this.onLoginRequestInProcess = false;
                    this.cd.detectChanges();
                  })
                  .catch((error:any) => {
                    this.sendOTPfnCalled = false;
                    this.onLoginRequestInProcess = false;
                    this.cd.detectChanges();
                    this.resetReCAPTCHA();
                    this.captchaloaded = false;
                    this.showMessage(error && error.message && !error.message.error?
                       error.message : "server_error");
                    return false;
                  });
              }
              else {
                this.onLoginRequestInProcess = false;
                this.cd.detectChanges();
                this.resetReCAPTCHA();
                this.captchaloaded = false;
                if (res.msg != undefined) {
                  this.showMessage("server_error");
                }
                else {
                  this.showMessage("Number not registered.");
                }

                if(res.msg == "Number not registered"){
                  this.phoneForm.patchValue({
                    phone: this.phoneno 
                  });
                  this.pagechange('ifsignup');
                }
                return false;
              }
            },
            err => {
              this.onLoginRequestInProcess = false;
              this.cd.detectChanges();
              this.resetReCAPTCHA();
              this.captchaloaded = false;
              this.showMessage("server_error");
              return false;
            }
          )
      }
      else {
        this.showMessage("recaptcha_not_solved");
        return false;
      }
    }
    else {
      this.showMessage("invalid_mobile");
      return false;
    }
  }

  confirmStatusOfRegisteredUser() {
    this.httpPostParameters = {
      "username": this.loginDetails['username'],
      "phone_no": this.phoneno
    };
    this.dataService.getDataByEntity('confirmStatusOfRegisteredUser', this.httpPostParameters).subscribe((res) => {
    })
  }
  getUserLoginDetail() {
    this.commonService.selectedLocation.subscribe((value) => {
      if (value && Object.keys(value).length > 0) {
        this.details.lat = value.lat();
        this.details.lng = value.lng();
        this.details.language = this.languageService.getLanguage() || 'en';
      }
    });
    this.dataService.getUserLocationDetails(this.details).subscribe((res) => {
      localStorage.setItem('stcode11', res.data.stcode11);
      localStorage.setItem('dtcode11', res.data.dtcode11);
      localStorage.setItem('stname', res.data.state_ln || res.data.stname);
      localStorage.setItem('dtname', res.data.dtname);
      localStorage.setItem('district_ln', res.data.district_ln);
      localStorage.setItem('mandalname', res.data.mandalname_ln || res.data.mandalname);
      localStorage.setItem('mandalcode', res.data.mandalcode);
      localStorage.setItem('clustername', res.data.clustername_ln || res.data.clustername);
      localStorage.setItem('clustercode', res.data.clustercode);

      if ((window as any).webengage) {
        (window as any).webengage.user.setAttribute('region', res.data.stname);
        (window as any).webengage.user.setAttribute('locality', res.data.dtname);
      }
    })
  }

  showMessage(msgCode:any) {
    let message = msgCode;
    this.languageService.getTranslationOf(message).subscribe((res:any) => {
      const element:any = document.getElementById("errorMsg");
      element.innerHTML = res;
    })
  }

  startTimer(timer: string) {
    let presentTime = timer;
    var timeArray: any = presentTime.split(/[:]+/);
    var m = timeArray[0];
    var s = this.checkSecond((timeArray[1] - 1));
    if (s == 59) { m = m - 1 }
    if (m < 0 || this.changeNumber) {
      this.endTimer();
      return false;
    }
    this.timer = m + ":" + s;
    this.cd.detectChanges();
    timer = m + ":" + s;
    if (typeof this.timerId != 'undefined')
      setTimeout(this.startTimer.bind(this, timer), 1000);
    else
      this.timerId = setTimeout(this.startTimer.bind(this, timer), 1000);;
  }

  endTimer() {
    this.timer = "";
    this.otpNotReceived = "";
    clearTimeout(this.timerId);
    this.timerId = undefined;
    this.ngIfOtpTimeout = true;
    this.cd.detectChanges();
  }

  clearTimer(timerId:any) {
    this.timer = "";
    this.otpNotReceived = "";
    clearTimeout(timerId);
  }

  checkSecond(sec:any) {
    if (sec < 10 && sec >= 0) { sec = "0" + sec }; // add zero in front of numbers < 10
    if (sec < 0) { sec = "59" };
    return sec;
  }

  resetReCAPTCHA() {
    // this.reCAPTCHASolved = false;
    this.reCAPTCHAExpired = false;
    grecaptcha.reset(this.widgetId);
  }

  renderReCAPTCHA(container:any) {
    // this.reCAPTCHASolved = false;
    this.windowRef = window;
    this.captchaloaded = true;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(container, {
      'size': 'invisible',
      'callback': (response:any) => {
        this.reCAPTCHASolved = true;
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // this.reCAPTCHASolved = false;
        this.reCAPTCHAExpired = true;
        this.showMessage("recaptcha_expired");
        this.resetReCAPTCHA();
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      }
    });
    this.windowRef.recaptchaVerifier.render().then((widgetId:any) => {
      this.captchaloaded = false;
      // this.cdr.detectChanges();
      this.windowRef.recaptchaWidgetId = widgetId;
      this.widgetId = widgetId;
    });
  }

  verifyCodeNew(){
    // here we removed old approach of firebase login with otp instead use siginInwithCustomToken
    this.onLoginRequestInProcess = true;
    // google event for verify otp
    const verifyOtpEvent = this.cookieService.get('verifyOtpEvent');
    if(!verifyOtpEvent){
      this.googleAnalyticsService.eventEmitter('verify_otp', "login pop-up","verify OTP");
      (window as any).hj('event', 'verify_otp');
      this.cookieService.put('verifyOtpEvent','true');
    }
    // check for validation of verification code.
    if (Boolean(this.verificationCode) && this.verificationCode.length == 6) {
        const username = this.cookieService.get('username');
        const req:VerifyOtp={
          phone_no:this.phoneno,
          code:this.verificationCode,
          username:username
        }
        // verify otp
        this.dataService.verifyOTP(req).subscribe(otpResponse=>{
          if(otpResponse && otpResponse.success){
            this.cookieService.put('is_logged__in', 'true');
            if ((window as any).webengage) {
              (window as any).webengage.user.login(localStorage.getItem('virtualId'));
              (window as any).webengage.user.setAttribute('username', this.username ? this.username : localStorage.getItem('username'));
              (window as any).webengage.user.setAttribute('mobile_num', this.phoneno);
            }
            // got login details from the previous api while sending otp.
            this.commonService.setUsername(this.loginDetails['username']);
            if (this.userActivity == 'signup' || this.wasUserSignedUp) {
              this.confirmStatusOfRegisteredUser();
              this.googleAnalyticsService.eventEmitter2('web_Signup_Success', {platform : "web"});
              // adding event ja_signup
              this.googleAnalyticsService.eventEmitter(
                "ja_signup",
                "",
                "Signing up in jaano"
              );
            } else{
              this.googleAnalyticsService.eventEmitter2('web_Login_Success', {platform : "web"});
              // adding event ja_signup
              this.googleAnalyticsService.eventEmitter(
                "ja_signin",
                "",
                "Login into jaano"
              );
            }
            const _token = otpResponse.customToken;
            // perform sign in with custom token.
            firebase.auth().signInWithCustomToken(_token)
              .then((userCredential) => {
                // getting token from firebase which need to passed for session login.
                userCredential.user?.getIdToken().then(newToken=>{
                  // setting session
                  const csrftoken = this.cookieService.get('csrfToken');
                  const _csrf = this.cookieService.get('_csrf');
                  console.log('csrf token from cookie',csrftoken);
                  console.log('csrf token from cookie',_csrf);
                  const newReq:SessionLogin={
                    idToken:newToken,
                    csrfToken:csrftoken
                  }
                  this.dataService.sessionLogin(newReq).subscribe(response=>{
                    console.log('response from session login',response);
                    if(response && response.status){
                      // ...
                      this.getUserLoginDetail();
                      this.getUserDetails();
                      gtag_report_conversion(this.username);
                      this.authSerivce.userAuthToken.subscribe((response1) => {
                        if (response1) {
                          localStorage.setItem('authToken',response1);
                          this.dialogRef.close("success");
                        } else {
                          this.authSerivce.currentUserIdToken().subscribe((response2:any) => {
                            if (response2) {
                              localStorage.setItem('authToken',response2);
                            }
                            if(response2){
                              this.dialogRef.close("success");
                            }
                          },
                          (error:any)=>{
                            this.onLoginRequestInProcess = false;
                            this.dialogRef.close("success");
                          });
                        }
        
                      },error=>{
                        this.onLoginRequestInProcess = false;
                        this.dialogRef.close("success");
                        console.log("token",error);
                      });
                    }
                    else{
                      this.onLoginRequestInProcess = false;
                    }
                  },error=>{
                    this.onLoginRequestInProcess = false;
                    console.error(error);
                  })
                },error=>{
                  this.onLoginRequestInProcess = false;
                  console.error(error);
                });
                // Signed in
                const user = userCredential.user;
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                this.onLoginRequestInProcess = false;
                console.error(errorMessage);
                // ...
              });  
          }
          else{
            this.googleAnalyticsService.eventEmitter2('web_Login_Failed', {platform : "web"});
            this.showMessage("wrong_code");
            this.onLoginRequestInProcess = false;
            return false;
          }
        },error=>{
          this.googleAnalyticsService.eventEmitter2('web_Login_Failed', {platform : "web"});
          this.showMessage("wrong_code");
          this.onLoginRequestInProcess = false;
          return false;
        })
    }
    else {
      this.showMessage("no_vcode");
      this.onLoginRequestInProcess = false;
      return false;
    }
  }

  verifyCode() {
    this.onLoginRequestInProcess = true;
    const verifyOtpEvent = this.cookieService.get('verifyOtpEvent');
    if(!verifyOtpEvent){
      this.googleAnalyticsService.eventEmitter('verify_otp', "login pop-up","verify OTP");
      (window as any).hj('event', 'verify_otp');
      this.cookieService.put('verifyOtpEvent','true');
    }
    if (Boolean(this.verificationCode) && this.verificationCode.length == 6) {
      this.captchaloaded = true;
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then((res:any) =>{
        this.windowRef.confirmationResult
        .confirm(this.verificationCode)
        .then((result:any) => {
          this.cookieService.put('is_logged__in', 'true');
          if ((window as any).webengage) {
            (window as any).webengage.user.login(localStorage.getItem('virtualId'));
            (window as any).webengage.user.setAttribute('username', this.username ? this.username : localStorage.getItem('username'));
            (window as any).webengage.user.setAttribute('mobile_num', this.phoneno);
          }
          this.commonService.setUsername(this.loginDetails['username']);
          if (this.userActivity == 'signup' || this.wasUserSignedUp) {
            this.confirmStatusOfRegisteredUser();
            // if (!this.data.autoChangeScreen) {
            //   setTimeout(() => {
            //     let dialogRef = this.dialog.open(UpdateUsernameDialogComponent, {
            //       width: '100%',
            //       height: '100%',
            //       minWidth: '100%',
            //       panelClass: 'dialogWithNoPadding',
            //       data: {
            //         "phone_no": this.phoneno,
            //         "username": this.username,
            //         "autoChangeScreen": this.autoChangeScreen
            //       }
            //     });
            //     dialogRef.afterClosed().subscribe(res=>{
            //       this.dialog.open(ImgTextButtonCustomDialogComponent, {
            //         data: Object.assign({
            //           title: 100 + this.languageService.getTranslated('JP earned for signup success') + '!',
            //           action: this.languageService.getTranslated('Create My Profile'), image: 'gamification/level-coins.svg'
            //         }, {'click_action' : '/user-profile/addupdate-user'}),
            //         maxWidth: '100%',
            //         width: '100%',
            //         height: '100%'
            //       });
            //     })
            //   }, 1000);
            // } else {
            //   let dialogref = this.dialog.open(ImgTextButtonCustomDialogComponent, {
            //     data:{
            //       title: 100 + this.languageService.getTranslated('JP earned for signup success') + '!',
            //       image: 'gamification/level-coins.svg'
            //     },
            //     maxWidth: '100%',
            //     width: '100%',
            //     height: '100%'
            //   });
            //   setTimeout(() => {
            //     dialogref.close();
            //   }, 5000);
            // }
            this.googleAnalyticsService.eventEmitter2('web_Signup_Success', {platform : "web"});
            // adding event ja_signup
            this.googleAnalyticsService.eventEmitter(
              "ja_signup",
              "",
              "Signing up in jaano"
            );
          } else{
            this.googleAnalyticsService.eventEmitter2('web_Login_Success', {platform : "web"});
            // adding event ja_signup
            this.googleAnalyticsService.eventEmitter(
              "ja_signin",
              "",
              "Login into jaano"
            );
          }

          this.getUserLoginDetail();
          this.getUserDetails();
          gtag_report_conversion(this.username);
          this.authSerivce.userAuthToken.subscribe((response1) => {
            if (response1) {
              localStorage.setItem('authToken',response1);
              this.dialogRef.close("success");
            } else {
              this.authSerivce.currentUserIdToken().subscribe((response2:any) => {
                if (response2) {
                  localStorage.setItem('authToken',response2);
                }
                if(response2){
                  this.dialogRef.close("success");
                }
              },
              (error:any)=>{
                this.onLoginRequestInProcess = false;
                this.dialogRef.close("success");
              });
            }
          },error=>{
            this.onLoginRequestInProcess = false;
            this.dialogRef.close("success");
            console.log("token",error);
          });
        })
        .catch((error:any) => {
          this.googleAnalyticsService.eventEmitter2('web_Login_Failed', {platform : "web"});
          // document.getElementById("otpContainer").classList.add("otpError");
          this.captchaloaded = false;
          this.showMessage("wrong_code");
          this.onLoginRequestInProcess = false;
          return false;
        });
        // this.onLoginRequestInProcess = false;
      }).catch((err:any) => {
        this.showMessage("server_error");
        this.onLoginRequestInProcess = false;
        return false;
      });
    }
    else {
      this.showMessage("no_vcode");
      this.onLoginRequestInProcess = false;
      return false;
    }
  }

  resendNewOtp(){
    this.googleAnalyticsService.eventEmitter('resend_otp', "login pop-up","resend OTP");
    (window as any).hj('event', 'resend_otp');
    this.ngIfOtpTimeout = false;
    const req:PhoneNo={
      phone_no:this.phoneno
    }
    this.dataService.resendOTP(req).subscribe(response=>{
      this.timer = parseInt(this.otpResendTimeout, 8) + ":" + parseInt('00', 8);
      this.startTimer(this.timer);
    })
  }

  resendOtp() {
    this.googleAnalyticsService.eventEmitter('resend_otp', "login pop-up","resend OTP");
    (window as any).hj('event', 'resend_otp');
    this.captchaloaded = true;
    this.reCAPTCHASolved = true;
    this.ngIfOtpTimeout = false;
    const appVerifier = this.windowRef.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber(this.phoneno, appVerifier)
      .then((result:any) => {
        this.windowRef.confirmationResult = result;
      }).catch((error:any) => {
      });
    this.captchaloaded = false;
    this.timer = parseInt(this.otpResendTimeout, 8) + ":" + parseInt('00', 8);
    this.startTimer(this.timer);
    // this.selectedscreen = this.prevScreen;
    // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    // this.pagechange(this.prevScreen);
  }

  getUserDetails() {
    // location.reload();
    const data2 = { phone_no: this.phoneno };
    this.dataService.getDataByEntity('getUserProfile/getUserDetails', data2).subscribe(result => {

      if ((window as any).webengage) {
        (window as any).webengage.user.setAttribute('we_first_name', result.result[0].username);
        (window as any).webengage.user.setAttribute('we_phone', result.result[0].phone_no);


        if (Boolean(result.result[0].email) && result.result[0].email != "null")
          (window as any).webengage.user.setAttribute('we_email', result.result[0].email);

        if (Boolean(result.result[0].dob) && result.result[0].dob != "null")
          (window as any).webengage.user.setAttribute('birth_date', result.result[0].dob);

        if (Boolean(result.result[0].gender) && result.result[0].gender != "null")
          (window as any).webengage.user.setAttribute('we_gender', result.result[0].gender == "f" || result.result[0].gender.toLowerCase() == 'female' ? 'female' : result.result[0].gender == "m" || result.result[0].gender.toLowerCase() == 'male' ? 'male' : 'other');

      }

      localStorage.setItem('profile_picture', result.result[0].profile_picture);
      localStorage.setItem('phone_no', result.result[0].phone_no);
      this.commonService.setUsername(result.result[0].username);
      localStorage.setItem('jaano_points', result.result[0].total_jaano_points);
      localStorage.setItem('my_referral_code',result.result[0].referral_code);
      this.commonService.setJaanoPoints(result.result[0].total_jaano_points);
      this.userService.setUserDetails(result.result[0].username, result.result[0].profile_picture);
      this.router.events.subscribe(event => {
      if(isPlatformBrowser(this.platformId)) {
        if (event instanceof NavigationEnd) {
          gtag('set', 'user_properties',
            {
              username: localStorage.getItem('username'),
              phone_no: localStorage.getItem('phone_no'),
              profile_picture: localStorage.getItem('profile_picture'),
              webToken: this.webNotificationToken,
              platform: this.cookieService.get('platform'),
              virtualId: localStorage.getItem('virtualId'),
              created_at: this.cookieService.get('created_at'),
              updated_at: this.cookieService.get('updated_at'),
              location : localStorage.getItem('user_location'),
              language: localStorage.getItem('lang')
            }
          );
        }
        }
      });
      // let isMigratedUser = result.result[0].old_user;
      // if (isMigratedUser) {
      //   this.dialog.open(MigrantUserDialog, { data: 'Migrant User Text' });
      // }
      // location.reload();
    });
  }

  changeNumber = false;
  editPhoneNo(){
    this.googleAnalyticsService.eventEmitter('edit_ph', "login pop-up","edit phone number");
    (window as any).hj('event', 'edit_ph');
    this.selectedscreen = "iflogin";
    this.changeNumber = true;
    this.endTimer();
    this.verificationCode= "";
  }

  invalidOtp(){
    if(this.verificationCode.length == 6){
      return false;
    }
    return true;
  }

  showReferral(){
    let dialogRef = this.dialog.open(ReferralDialogComponent, {
      width: '328px',
      height: '200px',
      panelClass:'custom-panel-class'
    });
  }
}


@Component({
  selector: 'referral-dialog',
  templateUrl: 'referral-dialog.component.html',
  styleUrls: ['authentication.component.scss']
})
export class ReferralDialogComponent {
  constructor( public dialogRef: MatDialogRef<ReferralDialogComponent>) {
  }

  onClose(){
    this.dialogRef.close(false);
  }
}