
<div class="opacity_overlay" *ngIf="isLoading">
    <mat-progress-spinner *ngIf="isLoading" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
    </mat-progress-spinner>
  </div>
<div class="container redeem" *ngIf="viewScreen == 'UPI'" style=" position: relative; height: 288px; ">
    <div class="row">
        <div class="col-12 header p-0">
            {{'redeem_details_title'|translate}}
        </div>
    </div>
    <form  [formGroup]="redeemForm" (ngSubmit)="onUpiSubmit()">
        <div class="row" style="position: absolute; width: 100%; top: 36%;">
            <div class="col-12 lable p-0" [ngClass]="{'error':redeemForm.invalid && redeemForm.touched}">
                {{'UPI ID'|translate}}:
            </div>
            <div class="col-12 p-0">
                <input type="text" class="input" placeholder="{{'upi_placeholder'|translate}}" formControlName="upi"
                [ngClass]="{'error':redeemForm.invalid && redeemForm.touched}">
            </div>
            <div class="col-12 p-0 error-lable" *ngIf="redeemForm.invalid && redeemForm.touched" >
                {{'Enter valid UPI id'|translate}}
            </div>
        </div>
        <div class="row" style="position: absolute; width: 100%; bottom: 0px;">
            <div class="col-auto p-0">
                <button class="btn cancel-btn" (click)="onClose()">{{'cancel'|translate}}</button>
            </div>
            <div class="col-auto ml-auto p-0">
                <button class="btn submit-btn" type="submit">{{'submit'|translate}}</button>
            </div>
        </div>
    </form>
</div>
<div class="container redeem" *ngIf="viewScreen == 'SUBMITTED'" style=" position: relative; height: 288px; ">
    <div class="row">
        <div class="col-12 header p-0">
            {{'request_submitted'|translate}}
        </div>
    </div>
    <div class="row" style="position: absolute; width: 100%; top: 34%;">
        <div class="col-12 text p-0">
            {{'redeem_money_wait'|translate}}
        </div>
        <div class="col-12 text p-0" style="margin-top:21px;">
            {{'thankyou_for_patience'|translate}}
        </div>
    </div>
    <div class="row"  style="position: absolute; width: 100%; bottom: 0px;">
        <div class="col-auto ml-auto p-0">
            <button class="btn okay-btn" (click)="onClose()">{{'okay'|translate}}</button>
        </div>
    </div>
</div>