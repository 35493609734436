import { Component } from "@angular/core";

@Component({
    selector: 'app-refund-policy',
    templateUrl: './refund-policy.component.html',
    styleUrls: ['./refund-policy.component.scss']
})

export class RefundPolicyComponent {
    
}
