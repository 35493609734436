<div class="media-card">
    <div class="card-header">
        <div class="container">
            <div class="row justify-content-between" style="margin-bottom: 0px;">
                <header>{{'Add attachments'|translate}} </header>
                <div class="optional">({{'Optional'|translate}})</div>
            </div>
        </div>
        <div class="title">{{'attachmentType'|translate}}</div>
    </div>
    <div class="card-body" style="height: 260px; position: relative;">
        <div class="media-wrapper">
            <div class="img-circle" (click)="onClickPicture()">
                <img src="../../../../assets/images/camera-icon-3.svg" alt="camera-icon" width="24px" height="24px" >
            </div>
            <div class="img-circle" (click)="onClickAudio()">
                <img src="../../../../assets/images/audio-icon-3.svg" alt="mic-icon" width="24px" height="24px">
            </div>
            <div class="img-circle" (click)="onClickVieo()">
                <img src="../../../../assets/images/video-icon-3.svg" alt="video-icon" width="24px" height="24px">
            </div>
        </div>
        <div class="row" style="position: absolute; bottom: 0px; width: 100%;">
            <div class="col-12 text-center">
                <button class="cancel-btn" (click)="onClose()">
                    {{'cancel'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>