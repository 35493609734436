import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'gratification-dialog',
  templateUrl: './gratification.component.html',
  styleUrls: ['./gratification.component.scss']
})
export class Gratification implements OnInit {

  jp_points = 0;
  text_jp = '';

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<Gratification>
  ) {
    this.jp_points = data.jp_points;
    this.text_jp = data.text_jp;
  }

  ngOnInit() {
  }


  dismiss() {
    this.dialogRef.close();
  }

}
