<div class="opacity_overlay" *ngIf="isLoader">
    <mat-progress-spinner *ngIf="isLoader" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
    </mat-progress-spinner>
</div>
<div class="payment-failed-wrapper">
    <div class="card-header">
        Help us know why you didn’t pay?
    </div>
    <div class="card-body">
        <div class="container h-356-328px">
            <ng-container  *ngFor="let item of paymentFailedReason;let i=index">
                <div class="row cursor-pointer" (click)="item.flag = !item.flag;onToggle(i)">
                    <div class="col-auto p-0">
                        <img src="../../../../assets/images/check-box-active.svg" alt="check-box" width="24px" height="24px" *ngIf="item.flag">
                        <img src="../../../../assets/images/check-box-inactive-2.svg" alt="check-box" width="24px" height="24px"  *ngIf="!item.flag">
                    </div>
                    <div class="col label p-0">
                        {{item.name}}
                    </div>
                </div>
            </ng-container>
            <!-- in case of other selection in payment failed reason -->
            <ng-container *ngIf="anyReasonSelected()">
                <div class="row" *ngIf="paymentFailedReason && paymentFailedReason.length>6 && paymentFailedReason[6].flag">
                    <div class="col-12 p-0">
                        <label for="describe">Describe</label>
                        <textarea name="describe" id="describe" cols="30" rows="10" formControlName="describe" id="describe"
                        (input)="onDescribe($event)"
                            placeholder="Describe your problem here. . . " [value]="paymentFailedReason[6].value"></textarea>
                    </div>
                </div>
                <div class="row pull-bottom-submit">
                    <div class="col-12 p-0">
                        <button class="solid-btn" (click)="onSubmit()"
                        [disabled]="paymentFailedReason && paymentFailedReason.length>6 && paymentFailedReason[6].flag && 
                        paymentFailedReason[6].value == ''">{{'submit'|translate}}</button>
                    </div>
                </div>
            </ng-container>
            <div class="row pull-bottom" style="margin-bottom:0px;">
                <div class="col-auto light-text p-0 mb-12-16">
                    {{'want_retry'|translate}}
                </div>
                <div class="col p-0 text-center content-center mb-12-16">
                    <hr>
                </div>
                <div class="col-12 p-0">
                    <button class="solid-btn" (click)="retryPayment()"
                    [ngClass]="{'no-border-btn':anyReasonSelected()}">{{'retry_payment'|translate}} ₹{{amount}}</button>
                </div>
            </div>
        </div>
    </div>
</div>