import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PopupserviceService {
  public yesButton = new BehaviorSubject<boolean>(false);
  public skipButton = new BehaviorSubject<boolean>(false);
  public createHelpButton = new BehaviorSubject<boolean>(false);
  public pageReloaded = new BehaviorSubject<boolean>(false);

  setPageReloaded(req: boolean) {
    this.pageReloaded.next(req);
  }

  getPageReloaded(): Observable<boolean> {
    return this.pageReloaded.asObservable();
  }

  setSkipButton(req: boolean) {
    this.skipButton.next(req);
  }

  getSkipButton(): Observable<boolean> {
    return this.skipButton.asObservable();
  }

  setCreateHelpButton(req: boolean) {
    this.createHelpButton.next(req);
  }

  getCreateHelpButton(): Observable<boolean> {
    return this.createHelpButton.asObservable();
  }
}
