// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  pwa: false,
  websiteUrl: 'https://dev-jaano.swaniti.org/#/',
  baseUrl: 'https://devnearbyentityservice.swaniti.org',
  baseUrl2: 'https://devapiv4.swaniti.org',
  baseUrl3: 'https://devapiv4.swaniti.org',
  baseUrl4: 'https://devapiv4.swaniti.org',
  surveymanBaseUrl: 'https://devapisurveymanager.ankaha.com',
  adminUrl: 'https://devapiadminjaanolocalv2.swaniti.org',
  firebase: {
    apiKey: "AIzaSyCMUsTVvCDLJehDFaqSbvRYOnLAPeafKQU",
    authDomain: "jaano-dev.firebaseapp.com",
    databaseURL: "https://jaano-dev-default-rtdb.firebaseio.com",
    projectId: "jaano-dev",
    storageBucket: "jaano-dev.appspot.com",
    messagingSenderId: "849077873762",
    appId: "1:849077873762:web:76f55ca7c8b61527e7f53b",
    measurementId: "G-KZYCXP2T2S"
  },
  paytmUrl: 'https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/tlRKkd73702448359208.js',
  webUrl:'https://devjaano.swaniti.org',
  appVersion: require('../../package.json').version,
  growthBookToken:"sdk-yMhfSonPlXBl2KE",
  razorKey:"TCAvoiwzjFokgKb7vLkd7JK5",
  razorKeyId:"rzp_test_oaGYYuDp6fiSTg"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
