import { Injectable } from '@angular/core';

@Injectable()
export class StringcheckerService {


    constructor() {

    }

    isAudioURL(audioUrl:any){
        if(audioUrl.split(" ").length == 1 && audioUrl.startsWith('https://firebasestorage.googleapis.com/') && (audioUrl.includes('_webaudio') || audioUrl.includes('_audio'))){
            return true;
        }
        return false;
    }

    isYoutubeURL(videoURL:any) {
        let pattern = '^(http(s)?:\\/\\/)?((w){3}.)?youtu(be|.be)?(\\.com)?\\/.+';
        return videoURL && videoURL.match(pattern) && videoURL.match(pattern).length > 0;
    }


    isFacebookURL(videoURL:any) {
        let pattern = "((http|https)://)?(www[.])?facebook.com/.+";
        return videoURL && videoURL.match(pattern) && videoURL.match(pattern).length > 0;
    }

    isFacebookVideoURL(videoURL:any) {
        let pattern = "http(?:s)?://(?:www\\.)?facebook.(?:[a-z]+)/(?!(?:video\\.php\\?v=\\d+|usernameFB/videos/\\d+)).*";
        return videoURL && videoURL.match(pattern) && videoURL.match(pattern).length > 0;
    }

    isTwitterVideoURL(videoURL:any) {
        return videoURL.startsWith('https://video.twimg.com');
    }
}
