import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { LanguageTranslationService } from '../../services/language-translation-service';
import { DataService } from "../../services/data.service";
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { CommonService } from '../../services/common.service';
import { CookieService } from 'ngx-cookie';

interface WizardStepDescriptor {
  header: string;
  content: string;
}

const STATES = require('../../../assets/jsons/StatesLang.json');
const STATEDISTRICTS = require('../../../assets/jsons/DistrictsLang.json');
const STATEDISTRICTSMAP = require('../../../assets/jsons/StateDistrictLang.json');
var MANDAL = require('../../../assets/jsons/MandalMapping.json');

@Component({
  selector: 'app-new-change-location',
  templateUrl: './new-change-location.component.html',
  styleUrls: ['./new-change-location.component.scss']
})
export class ChangeLocationDialog implements OnInit {
  public wizardSteps: WizardStepDescriptor[] = [];
  myControlStates = new FormControl();
  myControlDistricts = new FormControl();
  myControlClusters = new FormControl();
  myControlMandals = new FormControl();
  filteredStates!: Observable<any[]>;
  filteredDistricts!: Observable<any[]>;
  filteredClusters!: Observable<any[]>;
  filteredMandals!: Observable<any[]>;

  selectedLanguage:any;
  username:any;
  err: String = '';

  statesdata = STATES;
  districtsdata = STATEDISTRICTS;
  clustersList:any[] = [];
  mandalList = [];
  districtsDD:any;
  stateDD = this.statesdata;

  selectedClusterDetails = { "clusterName": '', 'clusterID': -1 };
  selectedMandalDetails = { "mandalName": '', 'mandalID': -1 };
  selectedStateName = "";
  SelectedDistrictName = "";
  selectedDistrictID:any;
  selectedStateID:any;
  entermanualLocation = true;
  timer_gps = 0;
  onBoardUser =false;

  fromAds = false;
  logoSpinner = true;

  popularDistricts = [ 
    {
      id:1,
      key:'MUM',
      district:'Mumbai',
      district_as:'মুম্বাই',
      district_bn:'মুম্বাই',
      district_en:'Mumbai',
      district_hi:'मुंबई',
      district_mr:'मुंबई',
      district_ne:'मुम्बई',
      district_pa:'ਮੁੰਬਈ',
      district_te:'ముంబై',
      dtcode:519,
      stcode:27
    },
    {
      id:2,
      key:'BAN',
      district:'Bengaluru urban',
      district_as:'বেংগালুৰু নগৰীয়া',
      district_bn:'বেঙ্গালুরু শহুরে',
      district_en:'Bengaluru urban',
      district_hi:'बेंगलुरु अर्बन',
      district_mr:'बेंगळुरू अर्बन',
      district_ne:'बेंगलुरु अर्बन',
      district_pa:'ਬੈਂਗਲੁਰੂ ਸ਼ਹਿਰੀ',
      district_te:'Bengaluru urban',
      dtcode:572,
      stcode:29
    },
    {
      id:3,
      key:'CHE',
      district:'Chennai',
      district_as:'চেন্নাই',
      district_bn:'চেন্নাই',
      district_en:'Chennai',
      district_hi:'चेन्नई',
      district_mr:'चेन्नई',
      district_ne:'चेन्नई',
      district_pa:'ਚੇਨਈ',
      district_te:'చెన్నై',
      dtcode:603,
      stcode:33
    },
    {
      id:4,
      key:'HYD',
      district:'Hyderabad',
      district_as:'হায়দৰাবাদ',
      district_bn:'হায়দ্রাবাদ',
      district_en:'Hyderabad',
      district_hi:'हैदराबाद',
      district_mr:'हैदराबाद',
      district_ne:'हैदरावाद',
      district_pa:'ਹੈਦਰਾਬਾਦ',
      district_te:'హైదరాబాద్',
      dtcode:536,
      stcode:36
    },
    {
      id:5,
      key:'CD',
      district:'Central Delhi',
      district_as:'মধ্য দিল্লী',
      district_bn:'মধ্য দিল্লি',
      district_en:'Central Delhi',
      district_hi:'मध्य दिल्ली',
      district_mr:'मध्य दिल्ली',
      district_ne:'मध्य दिल्ली',
      district_pa:'ਕੇਂਦਰੀ ਦਿੱਲੀ',
      district_te:'సెంట్రల్ ఢిల్లీ',
      dtcode:95,
      stcode:7
    },
    {
      id:6,
      key:'AHM',
      district:'Ahmadabad',
      district_as:'আহমদাবাদ',
      district_bn:'আহমেদাবাদ',
      district_en:'Ahmadabad',
      district_hi:'अहमदाबाद',
      district_mr:'अहमदाबाद',
      district_ne:'अहमदाबाद',
      district_pa:'ਅਹਿਮਦਾਬਾਦ',
      district_te:'అహ్మదాబాద్',
      dtcode:474,
      stcode:24
    },
    {
      id:7,
      key:'NAM',
      district:'Namsai',
      district_as:'নামছাই',
      district_bn:'নামসাই',
      district_en:'Namsai',
      district_hi:'नामसाई',
      district_mr:'नामसाई',
      district_ne:'नमसाई',
      district_pa:'ਨਮਸਾਇ',
      district_te:'నమ్సాయి',
      dtcode:697,
      stcode:12
    },
    {
      id:8,
      key:'PUN',
      district:'Pune',
      district_as:'পুনে',
      district_bn:'পুনে',
      district_en:'Pune',
      district_hi:'पुणे',
      district_mr:'पुणे',
      district_ne:'पुणे',
      district_pa:'ਪੁਣੇ',
      district_te:'పూణే',
      dtcode:521,
      stcode:27
    },
    {
      id:9,
      key:'VIZ',
      district:'Visakhapatnam',
      district_as:'বিশাখাপট্টনম',
      district_bn:'বিশাখাপত্তনম',
      district_en:'Visakhapatnam',
      district_hi:'विशाखापत्तनम',
      district_mr:'विशाखापट्टणम',
      district_ne:'विशाखापट्टनम',
      district_pa:'ਵਿਸ਼ਾਖਾਪਟਨਮ',
      district_te:'విశాఖపట్నం',
      dtcode:544,
      stcode:37
    },
    // {
    //   id:10,
    //   key:'NE',
    //   district:'S.P.S.NELLORE',
    //   dtcode:550,
    //   stcode:37
    // }
  ]

  // code -- 00001111
  showFilter = false;

  filterDistricts : {districtName:string,dtcode:number,stcode:number, stname:string}[] = [];
  filterDistrictsCopy : {districtName:string,dtcode:number,stcode:number, stname:string}[] = [];
  stateDistrictMap = STATEDISTRICTSMAP;
  getFilteredDistricts = () =>{
    const stateDistricts = this.stateDistrictMap.map((item:any)=> {
      const districtKey = `district_${this.selectedLanguage}`;
      const stateKey = `state_${this.selectedLanguage}`;
      const district = item[districtKey];
      const state = item[stateKey];
      return {
        districtName: district,
        stname: state,
        dtcode:+item['Dtcode11'],
        stcode: +item['Stcode11']
      }
    });
    this.filterDistricts = stateDistricts;
    this.filterDistrictsCopy = stateDistricts;
  }

  searchValue="";
  onFilterDistricts = (event:any) =>{
    let value = event.target.value;
    this.searchValue = value;
    if(value && value.length > 3){
      value = value.toLocaleLowerCase();
      this.viewType = 'search';
      this.filterDistricts = [...this.filterDistrictsCopy];
      this.filterDistricts =  this.filterDistricts.filter( data=> 
        (data.districtName.toLocaleLowerCase().includes(value)) || 
        (data.stname.toLocaleLowerCase().includes(value)));
    }
    else{
      this.viewType = this.oldViewType;
    }
  }

  onSelectFilteredDistrict = (district:any) =>{ 
    (window as any).hj('event', 'Loc_D_s');
    this.selectedStateID = district.stcode;
    this.selectedDistrictID = district.dtcode;
    this.SelectedDistrictName = district.districtName;
    this.selectedStateName = district.stname;
    this.callLocationAPI();
    const locSelEvent = this.cookieService.get('locSelEvent');
    if(!locSelEvent){
      this.cookieService.put('locSelEvent','true');
      (window as any).hj('event', 'loc_sel');
      this.googleAnalyticsService.eventEmitter('loc_sel', "location pop-up",this.SelectedDistrictName);
    }
  }

  viewType: 'popularDistrict'|'search'|'viewMore' = 'popularDistrict';
  oldViewType:'popularDistrict'|'search'|'viewMore' = 'popularDistrict';
  locationAvailable=false;
  
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private languageService: LanguageTranslationService, private dataService: DataService,
    public dialogref: MatDialogRef<ChangeLocationDialog>, 
    public googleAnalyticsService:GoogleAnalyticsService, private commonService:CommonService,
    private cookieService:CookieService) { 
      this.googleAnalyticsService.eventEmitter('loc_open', "location pop-up","selected district");
      (window as any).hj('event', 'loc_open');
      this.commonService.selectedLocation.subscribe((value) => {
        if(!value || Object.keys(value).length == 0 ){
          this.logoSpinner = true;
          this.entermanualLocation = false;
          this.getLocationData();
        }
        else{
          this.logoSpinner = false;
          this.locationAvailable = true;
        }
      });
      if(this.data == null){
        this.onBoardUser = true;
      }
      if(this.data && this.data.fromAds){
        this.fromAds = true;
      }
    }

  ngOnInit() {
    this.wizardSteps = [
      {
        header: 'Steptitle 1',
        content: 'state'
      },
      {
        header: 'Steptitle 2',
        content: 'district'
      }
    ];

    this.filteredStates = this.myControlStates.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.state_en),
        map(name => name ? this._filter_states(name) : this.statesdata.slice())
      );

    this.selectedLanguage = this.languageService.getLanguage() || 'en';
    this.username = localStorage.getItem('username');
    this.getFilteredDistricts();
    this.popularDistricts.forEach((data:any)=> {
      if(this.selectedLanguage != 'en'){
        const district:any = `district_${this.selectedLanguage}`;
        const dataDistrict:any = data[district];
        data.district = dataDistrict;
        data.key = data.district.substring(0,3);
      }
    })
  }

  private _filter_states(name: string) {
    const filterValue = name.toLowerCase();
    return this.statesdata.filter((option:any) => option.state_en.toLowerCase().includes(filterValue));
  }

  private _filter_districts(name: string) {
    const filterValue = name.toLowerCase();
    return this.districtsDD.filter((option:any) => option.district_en.toLowerCase().includes(filterValue));
  }

  private _filter_clusters(name: string) {
    const filterValue = name.toLowerCase();
    return this.clustersList.filter((option:any) => option.cluster_en.toLowerCase().includes(filterValue));
  }

  private _filter_mandals(name: string) {
    const filterValue = name.toLowerCase();
    return this.mandalList.filter((option:any) => option.mandal_en.toLowerCase().includes(filterValue));
  }

  selectedState(state:any) {
    if(state.value == this.selectedStateID && !this.fromAds){
      this.selectedStateName = "";
      this.selectedStateID = null;
      return;
    }
    // this.googleAnalyticsService.eventEmitter2('admin_help_dashboard_filter_location', { platform: "admin_web" });
    let selectedState = this.statesdata.filter((stateInfo:any) => stateInfo.stcode11 == state.value);
    this.selectedStateName = selectedState[0]['state_' + this.selectedLanguage] || selectedState[0]['stname'];
    this.selectedStateID = state.value;
    const googleAnalyticKey = `loc_S`;
    this.googleAnalyticsService.eventEmitter2(googleAnalyticKey,{});
    (window as any).hj('event', googleAnalyticKey);

    this.districtsDD = this.districtsdata[this.selectedStateID];
    this.filteredDistricts = this.myControlDistricts.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.district_en),
      map(name => name ? this._filter_districts(name) : this.districtsDD.slice())
    );

    this.SelectedDistrictName = "";
    this.selectedDistrictID = -1;
    this.selectedClusterDetails = { "clusterName": '', 'clusterID': -1 };
    this.selectedMandalDetails = { "mandalName": '', 'mandalID': -1 };
      if (this.selectedStateID == 37 ) {
        if(this.wizardSteps.length === 2){
          this.wizardSteps.push({
            header: 'Steptitle 3',
            content: 'cluster'
          })
          this.wizardSteps.push({
            header: 'Steptitle 4',
            content: 'mandal'
          })
        }
      } 
      else if(this.selectedStateID == 27){
        if(this.wizardSteps.length === 2){
          this.wizardSteps.push({
            header: 'Steptitle 3',
            content: 'cluster'
          })
        }
      }
      else {
        this.wizardSteps.splice(2,2)
      }
  }

  onSelectDistrict = (id:any) =>{
    (window as any).hj('event', 'Loc_D_q');
    this.selectedStateID = this.popularDistricts[id-1].stcode;
    const districtId = this.popularDistricts[id-1].dtcode;
    this.selectedDistrictID = this.popularDistricts[id-1].stcode;
    this.SelectedDistrictName = this.popularDistricts[id-1].district;
    const district = {value: districtId};
    this.selectedDistrict(district);
    const locSelEvent = this.cookieService.get('locSelEvent');
    if(!locSelEvent){
      this.cookieService.put('locSelEvent','true');
      this.googleAnalyticsService.eventEmitter('loc_sel', "location pop-up",this.SelectedDistrictName);
      (window as any).hj('event', 'loc_sel');
    }
  }

  onViewMore = () =>{
    this.viewType = 'viewMore';
    this.oldViewType = this.viewType;
  }

  selectedDistrict(district:any) {
    // this.googleAnalyticsService.eventEmitter2('admin_help_dashboard_filter_location', { platform: "admin_web" });
    let selectedDistrict = this.districtsdata[this.selectedStateID].filter((districtInfo:any) => districtInfo.dtcode11 == district.value);
    this.SelectedDistrictName = selectedDistrict[0]['district_' + this.selectedLanguage] || selectedDistrict[0]['dtname'];
    this.selectedDistrictID = district.value;
    const googleAnalyticKey = `loc_D`;
    this.googleAnalyticsService.eventEmitter2(googleAnalyticKey,{});
    (window as any).hj('event', googleAnalyticKey);
    (window as any).hj('event', 'Loc_D_vm');
    this.clustersList = MANDAL[this.selectedStateID][this.selectedDistrictID];
    let keys = Object.keys(this.clustersList);
    this.clustersList = keys.map((value:any) => this.clustersList[value][0]);
    this.filteredClusters = this.myControlClusters.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.cluster_en),
        map(name => name ? this._filter_clusters(name) : this.clustersList.slice())
      );
    this.selectedClusterDetails = { "clusterName": '', 'clusterID': -1 };
    this.selectedMandalDetails = { "mandalName": '', 'mandalID': -1 };

    // if((this.selectedStateID != 37 && this.selectedStateID != 27) || 
    //   (this.selectedStateID == 27 && this.selectedDistrictID != 497)){
    //   this.callLocationAPI();
    // }
    this.callLocationAPI();
    const locSelEvent = this.cookieService.get('locSelEvent');
    if(!locSelEvent){
      this.cookieService.put('locSelEvent','true');
      (window as any).hj('event', 'loc_sel');
      this.googleAnalyticsService.eventEmitter('loc_sel', "location pop-up",this.SelectedDistrictName);
    }
  }

  selectedDistrict2(district:any) {
    // this.googleAnalyticsService.eventEmitter2('admin_help_dashboard_filter_location', { platform: "admin_web" });
    let selectedDistrict = this.districtsdata[this.selectedStateID].filter((districtInfo:any) => districtInfo.dtcode11 == district.value);
    this.SelectedDistrictName = selectedDistrict[0]['district_' + this.selectedLanguage] || selectedDistrict[0]['dtname'];
    this.selectedDistrictID = district.value;
    const googleAnalyticKey = `loc_D_${this.SelectedDistrictName}`;
    this.googleAnalyticsService.eventEmitter2(googleAnalyticKey,{});
    (window as any).hj('event', googleAnalyticKey);

    // not getting cluster or mandal for case: location asking from landing page from google ads redirecting... (NEW CHANGES)
    this.callLocationAPI();
  }



  selectCluster(cluster:any) {
    // this.googleAnalyticsService.eventEmitter2('admin_help_dashboard_filter_location', { platform: "admin_web" });
    this.selectedClusterDetails.clusterID = cluster.value;
    let selectedCluster = this.clustersList.filter(clusterinfo => clusterinfo.clustercode == this.selectedClusterDetails.clusterID);
    this.selectedClusterDetails.clusterName = selectedCluster[0]['cluster_' + this.selectedLanguage] || selectedCluster[0]['clustername'];
    this.mandalList = MANDAL[this.selectedStateID][this.selectedDistrictID][this.selectedClusterDetails.clusterID];
    const googleAnalyticKey = `loc_C`;
    this.googleAnalyticsService.eventEmitter2(googleAnalyticKey,{});
    (window as any).hj('event', googleAnalyticKey);

    this.filteredMandals = this.myControlMandals.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.mandal_en),
      map(name => name ? this._filter_mandals(name) : this.mandalList.slice())
    );
    this.selectedMandalDetails = { "mandalName": '', 'mandalID': -1 };
    if(this.selectedStateID != 37){
      this.callLocationAPI();
    }
  }

  selectMandal(selectedmandal:any) {
    // this.googleAnalyticsService.eventEmitter2('admin_help_dashboard_filter_location', { platform: "admin_web" });
    let mandaldata:any = this.mandalList.filter((mandalrow:any) => {
      return mandalrow.mandalcode == selectedmandal.value;
    });
    this.selectedMandalDetails.mandalName = mandaldata[0]['mandal_' + this.selectedLanguage] || mandaldata[0].mandalname;
    this.selectedMandalDetails.mandalID = mandaldata[0].mandalcode;
    const googleAnalyticKey = `loc_M`;
    this.googleAnalyticsService.eventEmitter2(googleAnalyticKey,{});
    (window as any).hj('event', googleAnalyticKey);
    this.callLocationAPI();
  }

  callLocationAPI(){
   this.dataService.getUserLocationDetailsv2({
      language: this.languageService.getLanguage() || 'en',
     "stcode11": this.selectedStateID,
     "dtcode11": this.selectedDistrictID,
     "clustercode": this.selectedClusterDetails.clusterID > -1 ? this.selectedClusterDetails.clusterID : undefined,
     "mandalcode": this.selectedMandalDetails.mandalID > -1 ? this.selectedMandalDetails.mandalID : undefined,
     "username": this.username
    }).subscribe((res) => {
      if (res.success) {
        localStorage.setItem('stcode11', this.selectedStateID);
        localStorage.setItem('dtcode11', this.selectedDistrictID);
        localStorage.setItem('stname', this.selectedStateName);
        localStorage.setItem('dtname', this.SelectedDistrictName);
        localStorage.setItem('district_ln', this.SelectedDistrictName);
        localStorage.setItem('mandalname', this.selectedMandalDetails.mandalName);
        localStorage.setItem('mandalcode', this.selectedMandalDetails.mandalID.toString());
        localStorage.setItem('clustername', this.selectedClusterDetails.clusterName);
        localStorage.setItem('clustercode', this.selectedClusterDetails.clusterID.toString());
        this.dialogref.close({
          latitude : res.data.lat,
          longitude : res.data.lng
        });
      }
    },
    error=>{
      this.dialogref.close({
        latitude : -1,
        longitude : -1
      });
    });
  }

  getLocationData() {
    let timer = setInterval(()=>{
      this.timer_gps+= 100;
    }, 100);
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((location) => {
        clearInterval(timer);
        let mapLocationValue:any = {};
        mapLocationValue['latitude'] = (location.coords.latitude);
        mapLocationValue['longitude'] = (location.coords.longitude);
        let details = {
          'lat': location.coords.latitude,
          'lng': location.coords.longitude,
          'language': this.selectedLanguage,
          'username': localStorage.getItem('username')
        }
        const logGpsEvent = this.cookieService.get('logGpsEvent');
        if(!logGpsEvent){
          this.cookieService.put('logGpsEvent','true');
          this.googleAnalyticsService.eventEmitter('loc_gps',"location pop-up","allow GPS,","");
          (window as any).hj('event', 'loc_gps');
        }
        this.dataService.getUserLocationDetails(details).subscribe((res) => {
          if (res.success) {
            localStorage.setItem('stcode11', res.data.stcode11);
            localStorage.setItem('dtcode11', res.data.dtcode11);
            localStorage.setItem('stname', res.data.state_ln || res.data.stname);
            localStorage.setItem('dtname', res.data.dtname);
            localStorage.setItem('district_ln', res.data.district_ln);
            localStorage.setItem('mandalname', res.data.mandalname_ln || res.data.mandalname);
            localStorage.setItem('mandalcode', res.data.mandalcode);
            localStorage.setItem('clustername', res.data.clustername_ln || res.data.clustername);
            localStorage.setItem('clustercode', res.data.clustercode);
            this.dialogref.close(mapLocationValue);
          }
          this.logoSpinner = false;
        },error=>{
          this.logoSpinner = false;
        });
      },
        error => {
        const logGpsEvent = this.cookieService.get('logGpsEvent');
        if(!logGpsEvent){
          this.cookieService.put('logGpsEvent','true');
          this.googleAnalyticsService.eventEmitter('Loc_GPS_blk', "location pop-up","block GPS");
          (window as any).hj('event', 'Loc_GPS_blk');
        }
        clearInterval(timer);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              this.err = 'Seems like you have blocked the location. Please unblock it from browser\'s settings.';
              break;
            case error.POSITION_UNAVAILABLE:
              this.err = 'Location information is unavailable.';
              break;
            case error.TIMEOUT:
              this.err = 'Location detection timed out.';
              break;
            case 2:
              this.err = 'An unknown error occurred.';
              break;
          }
          // console.log(this.err);
          let timer2 =  setTimeout(() => {
            this.entermanualLocation = true;
            clearTimeout(timer2);
          }, 2000);
          this.logoSpinner = false;
        },
        { timeout: 9000, enableHighAccuracy: true });
    }
    else{
      this.logoSpinner = false;
    }
  }


  close() {
    if (localStorage.getItem('user_location')) {
      const userLocation:any=localStorage.getItem('user_location')
      const storelocation = JSON.parse(userLocation);
      this.dialogref.close({
        latitude: storelocation.lat,
        longitude: storelocation.lng
      });
    } else {
      this.dialogref.close();
    }
  }

  onBack= () =>{
    if(this.viewType != 'popularDistrict'){
      this.viewType = 'popularDistrict';
      this.oldViewType = 'popularDistrict';
      this.searchValue = "";
    }
    else{
      this.dialogref.close();
    }
  }
}
