<div class="opacity_overlay" *ngIf="IsLoader">
  <mat-progress-spinner *ngIf="IsLoader" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
  </mat-progress-spinner>
</div>

<!-- <div class="maincontainer" (click)="allowHelpReq ? null : navigateToPage('/feeds/help');">

  <div class="text-center p-3">
    <img height="120px" width="120px" alt="logo"
      src="../../../assets/images/jaanoLogo.svg">
  </div>

  <div class="subcontainer">
    <div class="heading">{{'please_select_help_req'| translate}}</div>

    <div style="max-height: 52vh; overflow: auto; margin: 1rem 0rem">
      <div class="questioncontainter" *ngFor="let question of questions" (click)="allowHelpReq ? onAQuesClick(question): null">
        <span class="setPadding quescontainer">{{question['hr_' + selectedLang]}}</span>
          <span><img class="questionImg" src="../../../assets/images/post-icon.svg" height="20" alt="post-icon" /></span>
      </div>
    </div>

    <div style="display:flex; justify-content:center; align-item:center">
      <button class="view-more-btn"
      (click)="navigateToPage('/home');">
        {{'view_more'|translate}}
      </button>
    </div>
    
  </div>
</div> -->

<div class="card hr-list-card" [hidden]="!stcode11">
  <div class="card-header">
    {{'please_select_help_req'| translate}}
  </div>
  <div class="card-body">
    <div class="container p-0">
      <div class="row">
        <div class="col-12" *ngFor="let question of questions">
          <button (click)="allowHelpReq ? onAQuesClick(question): null" class="list-button">
            <div class="text">
              {{question['hr_' + selectedLang]}}
            </div>
            <div class="img">
              <img class="questionImg" src="../../../assets/images/arrow-right-icon.svg" width="20px" height="20px" alt="arrow-right" />
            </div>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button class="view-more"  (click)="navigateToPage('/home');">{{'view_more'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="maincontainer">
  <div class="container-fluid">
    <div class="row select-lang-top">
        <div class="col-12 text-center">
            <img src="../../../assets/images/jaanoLogo.svg" alt="jaanoLogo" width="120px"
            height="120px;">
        </div>
        <div class="col-12 mt-16 text-center">
            <span class="text-1 sh1 w-500">
                {{'select_your_problem'|translate}}
            </span>
        </div>
    </div>
  </div>
  <div class="container-fluid select-lang-bottom">
    <div class="row">
      <ng-container *ngFor="let question of questions">
        <div class="col-12 mt-16 content-center">
          <div class="title-box"  (click)="allowHelpReq ? onAQuesClick(question): null">
            <div class="row" style="width:100%;margin:auto;">
              <div class="col-10 p-0" style="text-align: left;">
                {{question['hr_' + selectedLang]}}
              </div>
              <div class="col-auto ml-auto p-0 content-center" style="width:30px">
                <span><img class="questionImg" src="../../../assets/images/arrow-right-icon-2.svg" height="20"/></span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-12 mt-16 content-center">
        <div class="title-box" (click)="navigateToPage('/home');" style="justify-content:center;">
          {{'view_more'|translate}}
        </div>
      </div>
    </div>
  </div>
</div> -->