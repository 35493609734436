<div class="category-card">
    <div class="card-header">
        <header>{{'choose_category'|translate}}</header>
        <div class="title">{{'select_category'|translate}}</div>
    </div>
    <div class="card-body max-height-custom">
        <div class="container" style="position:initial;">
            <div class="row" style="position: sticky; top: 1px;z-index: 999;">
                <div class="col-12 p-0">
                    <input type="text" placeholder="{{'search_category'|translate}}"
                    [(ngModel)]="searchCategory" (input)="onSearchCategory()">
                    <img src="../../../assets/images/search.svg" alt="search-icon">
                </div>
            </div>
            <div class="row mt-16-8" *ngIf="!searchCategory ||
             (searchCategory && searchCategory.length == 0)">
                <div class="col-auto pl-0 title">
                    {{'popular_categories'|translate}}
                </div>
                <div class="col ml-auto content-center p-0">
                    <hr style="width: 100%;border-top: 1px solid rgba(50, 50, 51, 0.30);">
                </div>
            </div>
            <div class="row mt-16-8"   *ngIf="searchCategory && searchCategory.length>0">
                <div class="col-auto pl-0 title">
                    <span>
                        {{'Search results'|translate}}
                    </span>
                </div>
                <div class="col ml-auto content-center p-0">
                    <hr style="width: 100%;border-top: 1px solid rgba(50, 50, 51, 0.30);">
                </div>
            </div>
            <div class="row mt-16-8" *ngIf="referralService.popularService && referralService.popularService.length>0">
                <ng-container  *ngFor="let item of referralService.popularService;let i=index">
                    <div class="col-12 tiles space-around mb-12-8"  *ngIf="item.flag"
                     (click)="getPopularServiceData(item.subCatId,item.subCat,false)">
                        <div class="label">{{item.subCat}}</div>
                        <div class="value">{{item.count}} {{'menu_services'|translate}}</div>
                    </div>
                </ng-container>
            </div>
            <div class="row mt-16-8" *ngIf="!searchCategory ||
            (searchCategory && searchCategory.length == 0)">
                <div class="col-auto pl-0 title">
                    <span >
                        {{'other_categories'|translate}}
                    </span>
                </div>
                <div class="col ml-auto content-center p-0">
                    <hr style="width: 100%;border-top: 1px solid rgba(50, 50, 51, 0.30);">
                </div>
            </div>
            <div class="row" [ngClass]="{'mt-16-8':!searchCategory ||
            (searchCategory && searchCategory.length == 0)}">
                <ng-container  *ngFor="let item of referralService.otherService;let i=index">
                    <div class="col-6 pl-0 mb-12-8" *ngIf="item.flag"
                    (click)="getPopularServiceData(item.subCatId,item.subCat,true,item.catId)">
                        <div class="tiles">
                            <div class="label-2">{{item.subCat}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>