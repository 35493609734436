<div class="opacity_overlay" *ngIf="IsLoader">
    <mat-progress-spinner *ngIf="IsLoader" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
    </mat-progress-spinner>
</div>
<div class="category-card" *ngIf="!userDetails.username || userNameDialog">
    <div class="card-header">
        <header>{{'Enter name and mobile number '|translate}}</header>
        <div class="title">{{'Enter your name & mobile number to login'|translate}}</div>
    </div>
    <div class="card-body">
        <div class="container" style="height: 458px;position:relative;" [formGroup]="userDetailForm" >
            <div class="row" style="position: absolute;
            width: 100%;
            top:30%;">
                <div class="col-12 content-center p-0">
                    <div class="tile" [ngClass]="{'error-border':userDetailForm.controls['userName'].errors && (userName?.dirty || userName?.touched)}">
                      <div class="label">
                        {{'name'|translate}}
                      </div>
                      <div class="error-label" *ngIf="userDetailForm.controls['userName'].errors && (userName?.dirty || userName?.touched) && 
                      userDetailForm.controls['userName'].errors['required']">
                        {{'Name is required*'|translate}}
                      </div>
                      <div class="error-label" *ngIf="userDetailForm.controls['userName'].errors && (userName?.dirty || userName?.touched) && 
                      !userDetailForm.controls['userName'].errors['required']">
                        {{'invalidName'|translate}}*
                      </div>
                      <input type="text" placeholder="{{'Enter your name here'|translate}}" formControlName="userName" style="width: 100%;" >
                    </div>
                </div>
                <div class="col-12 content-center p-0" style="margin-top:40px;">
                    <div class="tile mt-24" [ngClass]="{'error-border':userDetailForm.controls['mobileNo'].errors && (mobileNo?.touched)}">
                      <div class="label" >
                        {{'mobileNo'|translate}}
                      </div>
                      <div class="error-label" *ngIf="userDetailForm.controls['mobileNo'].errors && (mobileNo?.touched) && 
                      userDetailForm.controls['mobileNo'].errors['required']">
                        {{'Mobile no. is required*'|translate}}
                      </div>
                      <div class="error-label" *ngIf="userDetailForm.controls['mobileNo'].errors && (mobileNo?.touched) && 
                      !userDetailForm.controls['mobileNo'].errors['required']">
                        {{'invalidMobileNo'|translate}}*
                      </div>
                      <input type="number" placeholder="{{'mobileNoPlaceholder'|translate}}"  formControlName="mobileNo" style="width: 100%;" 
                      (keyup)="onChangeMobileNo()">
                    </div>
                </div>
            </div>
            <div class="row" style="position: absolute;
            width: 100%;
            bottom: 4px;">
                <div class="col-12 p-0">
                    <button class="btn-block verify-btn" (click)="getOtp()" [disabled]="userDetailForm.invalid">
                        {{'Get OTP'|translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="category-card" *ngIf="userDetails.username && !userNameDialog">
    <div class="card-header">
        <header>{{'enter_otp'|translate}}</header>
        <div class="title">{{'otpDesc'|translate}}</div>
    </div>
    <div class="card-body">
        <div class="container" style="height: 458px;position:relative;">
            <div class="row">
                <div class="col-12 content-between p-0">
                    <div class="light-text-112">{{phoneNo}}</div>
                    <div class="light-text-112 color-8 underline"
                    (click)="clearInterval_()">{{'Edit'|translate}}</div>
                </div>
            </div>
            <div class="row invalid-otp-wrapper"
            *ngIf="invalidOtp">
                <div class="col-12 mb-16 text-center danger">
                    {{'invalidOtp'|translate}}
                </div>
            </div>
            <div class="row" style="position: absolute;
            width: 100%;
            top: 38%;">
                <div class="col-12 content-center">
                    <div class="otp-box">
                        <input type="number" id="otp1" [(ngModel)]="otp1" (keyup)="onOtp1($event)">
                    </div>
                    <div class="otp-box">
                        <input type="number" id="otp2" [(ngModel)]="otp2" (keyup)="onOtp2($event)">
                    </div>
                    <div class="otp-box">
                        <input type="number" id="otp3" [(ngModel)]="otp3" (keyup)="onOtp3($event)">
                    </div>
                    <div class="otp-box">
                        <input type="number" id="otp4" [(ngModel)]="otp4" (keyup)="onOtp4($event)">
                    </div>
                    <div class="otp-box">
                        <input type="number" id="otp5" [(ngModel)]="otp5" (keyup)="onOtp5($event)">
                    </div>
                    <div class="otp-box">
                        <input type="number" id="otp6" [(ngModel)]="otp6" (keyup)="onOtp6($event)">
                    </div>
                </div>
                <div class="col-12 text-center light-text-112" *ngIf="remainingTime != '00:00'">
                    {{'requestOtpIn'|translate}} {{remainingTime}}
                </div>
                <div class="col-12 text-center otp-text" *ngIf="remainingTime == '00:00'"
                (click)="resendNewOtp()" style="cursor:pointer">
                {{'resendotp'|translate}}
                </div>
            </div>
            <div class="row" style="position: absolute;
            width: 100%;
            bottom: 4px;">
                <div class="col-12 p-0">
                    <button class="btn-block verify-btn" (click)="onVerify()" [disabled]="verifingOtp || IsLoader">
                        {{'verify'|translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>