<div class="media-card">
    <div class="card-header">
        <div class="container">
            <div class="row justify-content-between" style="margin-bottom: 0px;">
                <header>{{header}}</header>
            </div>
        </div>
    </div>
    <div class="card-body" style="height: 260px; position: relative;">
        <div class="">
            {{infoMessage}}
        </div>
        <div class="row" style="position: absolute; bottom: 0px; width: 100%;">
            <div class="col-12 text-center">
                <button class="cancel-btn" (click)="onClose()" style="color: #304FFE;" >
                    {{'okay'|translate}}!
                </button>
            </div>
        </div>
    </div>
</div>