<div class="container-fluid">
    <div class="scroll-content">
        <div class="pageheading">{{'refund_policy' | translate}}</div>
        <p class="pb-20">
            Thanks for purchasing our products (Paid Service) at [jaano.swaniti.org] operated by [Swaniti Initiative].<br><br>
            We offer a full money-back guarantee for all purchases made on our website. If you are not satisfied with the product that you have purchased from us, you can get your money back no questions asked. You are eligible for a full reimbursement within 7 calendar days of your purchase.<br><br>
            After the 7-day period you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the product (or service) in the first two weeks after their purchase to ensure it fits your needs.<br><br>       
            If you have any additional questions or would like to request a refund, feel free to contact us.<br>
            <a href="mailto:help@swaniti.in">help@swaniti.in</a>
        </p>
    </div>
</div>