import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';

import * as firebase from 'firebase/app';
import 'firebase/storage';

import { environment } from "../../environments/environment";
import { DataService } from "../services/data.service";
import { Feeds } from '../feeds';
import { CommonService } from './common.service';
import { ShowToastyService } from './showToastyService';

@Injectable({
    providedIn: 'root'
})

export class UploadService {
    storage:any;
    dialogRef:any;
    
    constructor(@Inject(PLATFORM_ID) private platformId: Object,
        private dataService: DataService,
        private commonService: CommonService,
        private toastyService: ShowToastyService,
        private router: Router,
    ) {
        if (Boolean(firebase) && isPlatformBrowser(this.platformId)) {
            if (!firebase.apps)
                firebase.initializeApp(environment.firebase);
            else if (!firebase.apps.length)
                firebase.initializeApp(environment.firebase);
        }
        if(isPlatformBrowser(this.platformId)){
        // Get a reference to the storage service, which is used to create references in your storage bucket
        this.storage = firebase.storage();
        }
    }

    uploadFileOnFirebase(files:any, bucket?:any): any {
        let storageRef = this.storage.ref();
        let promises:any = [];

        files.forEach((element:any, index:any) => {
            promises.push(
                new Promise((resolve, reject) => {
                    let fileRef = storageRef.child('files/' + new Date().getTime() + index.toString() + 'fileName_'+element.name);
                    if(bucket!=null){
                        fileRef = storageRef.child('files/'+bucket+'/' + new Date().getTime() + index.toString() + 'fileName_'+element.name);
                    }
                    let uploadTask = fileRef.put(element);
                    uploadTask.on('state_changed', function (snapshot:any) {
                        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED:
                                break;
                            case firebase.storage.TaskState.RUNNING:
                                break;
                        }
                    }, function (error:any) {
                        reject(error);
                    }, function () {
                        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL:any) {
                            
                            resolve(downloadURL);
                        });
                    });
                })
            );
        });
        return Promise.all(promises);
    }
    
    uploadImageOnFirebase(files:any, bucket?:any): any {
        // var storageRef = firebase.app().storage('gs://jaano2.appspot.com').ref();
        var storageRef = this.storage.ref();
        var promises:any = [];

        files.forEach((element:any, index:any) => {
            promises.push(
                new Promise((resolve, reject) => {
                    var imagesRef = storageRef.child('files/' + new Date().getTime() + index.toString() + "_webimage");
                    if(bucket!=null){
                        imagesRef = storageRef.child('files/'+bucket+'/' + new Date().getTime() + index.toString() + "_webimage");
                    }
                    let uploadTask = imagesRef.put(element);
                    uploadTask.on('state_changed', function (snapshot:any) {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        // console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED:
                                // console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING:
                                // console.log('Upload is running');
                                break;
                        }
                    }, function (error:any) {
                        reject(error);
                        // Handle unsuccessful uploads
                    }, function () {
                        // Handle successful uploads on complete
                        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL:any) {
                            
                            resolve(downloadURL);
                        });
                    });
                })
            );
        });
        return Promise.all(promises);
    }

    // Deprecated
    uploadImage(files:any, feed: Feeds) {
        // var storageRef = firebase.app().storage('gs://jaano2.appspot.com').ref();
        var storageRef = this.storage.ref();
        var promises:any = [];

        files.forEach((element:any, index:any) => {
            promises.push(
                new Promise((resolve, reject) => {
                    var imagesRef = storageRef.child('files/' + new Date().getTime() + index.toString() + "_webimage");
                    let uploadTask = imagesRef.put(element);
                    uploadTask.on('state_changed', function (snapshot:any) {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        // console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED:
                                // console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING:
                                // console.log('Upload is running');
                                break;
                        }
                    }, function (error:any) {
                        reject(error);
                        // Handle unsuccessful uploads
                    }, function () {
                        // Handle successful uploads on complete
                        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL:any) {
                            // console.log('File available at', downloadURL);
                            resolve(downloadURL);
                        });
                    });
                })
            )
        });
        Promise.all(promises).then((values) => {
            if (values.length > 0) {
                feed.feed_pictures = JSON.stringify(values);
            }
            this.sendToServer(feed);
        }, reason => {
            // console.log("rejected" + reason);
        });
    }
    uploadAudio(audioFile:any): any {
        var storageRef = this.storage.ref();
        var metadata = {
            contentType: 'mp3',
          };
        var videoRef = storageRef.child('files/' + new Date().getTime() + "_webaudio");
        let uploadTask = videoRef.put(audioFile,metadata);
        let promise = new Promise((resolve, reject) => {
            uploadTask.on('state_changed', function (snapshot:any) {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {   
                    case firebase.storage.TaskState.PAUSED:
                        // console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING:
                        // console.log('Upload is running');
                        break;
                }
            }, function (error:any) {
                reject(error);
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL:any) {
                    // console.log('File available at', downloadURL);
                    resolve(downloadURL);
                });
            });
        });
        return promise;
      }

    // var storageRef = firebase.app().storage('gs://jaano2.appspot.com').ref();
    uploadVideo(videoFile:any): any {
        var storageRef = this.storage.ref();

        var videoRef = storageRef.child('files/' + new Date().getTime() + "_webvideo");
        let uploadTask = videoRef.put(videoFile);
        let promise = new Promise((resolve, reject) => {
            uploadTask.on('state_changed', function (snapshot:any) {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED:
                        // console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING:
                        // console.log('Upload is running');
                        break;
                }
            }, function (error:any) {
                reject(error);
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL:any) {
                    // console.log('File available at', downloadURL);
                    resolve(downloadURL);
                });
            });
        });
        return promise;
    }

    // Deprecated
    sendToServer(feed: Feeds) {

        let uploadData:any = {};
        if (feed.feed_pictures != null) {
            uploadData["feed_pictures"] = feed.feed_pictures;
        }
        if (feed.feed_video != null) {
            uploadData["feed_video"] = feed.feed_video;
        }
        if (feed.feed_news != null) {
            uploadData["news_link"] = feed.feed_news;
        }
        if (feed.entity_id != null) {
            uploadData["entity_id"] = feed.entity_id;
            uploadData["entity_name"] = feed.entity_name;
        }
        // if (feed.entity_type != 0) {
        //     uploadData["entityType"] = feed.entity_type.toString();
        // }
        uploadData["experience_rating"] = feed.experience_rating.toString();

        uploadData["lat"] = feed.lat.toString();
        uploadData["lng"] = feed.lng.toString();
        if (feed.post_description != null)
            uploadData["post_description"] = feed.post_description;
        uploadData["postid"] = (new Date).getTime().toString();
        feed.postid = uploadData["postid"];
        uploadData["timestamp"] = (new Date).getTime().toString();
        feed.timestamp = uploadData["timestamp"];
        // TODO :  add username
        uploadData["username"] = this.commonService.getUsername();
        feed.username = uploadData["username"];
        uploadData["metadata"] = "{}";
        uploadData['push'] = false;
        this.dataService.getDataByEntity('setCommunityNewsFeed', uploadData).subscribe(result => {
            if (result && result.success == true) {
                // this.router.navigate(['feeds/local-feed'], {
                //     queryParams : {"upload" : "post"}
                //   });
                // this.dialogRef.close(feed);
            } else {
                this.toastyService.failure('', 'falied to add post');
                // show toasty that failed to upload
            }
        });
    }
}
