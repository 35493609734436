import { Injectable, Inject, NgZone } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';

declare var require: any;

@Injectable()
export class AuthService {
    authResult: any;
    authState: any = null;
    httpPostParameters: any;
    user: any;
    redirectUrl: string|null="";
    username: string="";
    userAuthToken;

    constructor(
        public afAuth: AngularFireAuth,
        private _router: Router,
        private _zone: NgZone,
    ) {
        this.userAuthToken = new BehaviorSubject('');
    }

    /**
     * this method is use to initiate user auth service
     */
    initUserAuthenticationService() {
        this.afAuth.authState.subscribe((auth) => {
            this.authState = auth;
        });
        this.afAuth.auth.onAuthStateChanged((user:any) => {
            if (user) {
                this.user = user;
            } else {
                this.user = null;
            }
        });
    }

    //Returns true if user is logged in
    get authenticated(): boolean {
        return this.authState !== null;
    }

    //Returns current user
    get currentUser(): any {
        return this.authenticated ? this.afAuth.auth.currentUser : null;
    }

    //Retuns Current user UID
    get currentUserId(): string {
        return this.authenticated ? this.authState.uid : '';
    }



    //Return Current User Token
    currentUserIdToken(): any {
        if (this.user) {
            this.user.getIdToken(/* forceRefresh */ true).then((idToken:any) => {
                this.userAuthToken.next(idToken);
                localStorage.setItem('authToken',idToken);
                return this.userAuthToken;
                // Send token to your backend via HTTPS
                // ...
            }).catch(function (error:any) {
            });
        } else {
            this.afAuth.auth.onAuthStateChanged((user:any) => {
                if (user) {
                    this.user = user;
                    this.user.getIdToken(/* forceRefresh */ true).then((idToken:any) => {
                        this.userAuthToken.next(idToken);
                        localStorage.setItem('authToken',idToken);
                        return this.userAuthToken;
                        // Send token to your backend via HTTPS
                        // ...
                    }).catch(function (error:any) {
                        // Handle error
                        // console.log(error);
                    });
                } else {
                    this.user = null;
                }
            });
        }
        return this.userAuthToken;
    }

    //Return Encrypted current user token
    currentUserStatus(callback:any) {
        let afSubs = this.afAuth.authState.subscribe((auth) => {
            if (auth) {
                auth.getIdToken(/* forceRefresh */ true).then((idToken:any) => {
                    // let encryptedIdToken = encryption.encrypt(idToken);
                    // localStorage.setItem("tkc", encryptedIdToken);

                    callback(true);
                    afSubs.unsubscribe();
                    // Send token to your backend via HTTPS
                    // ...
                }).catch(function (error:any) {
                    // Handle error
                    // console.log(error);
                    callback(false);
                    afSubs.unsubscribe();
                });
            }
            else {
                callback(false);
                afSubs.unsubscribe();
            }
        });
    }


    // updateProfile(username) {
    //     this.username = username;
    //     firebase.auth().currentUser.updateProfile({
    //         displayName: username,
    //         photoURL: null
    //     }).then(function () {
    //     }, function (error) {
    //         console.log(error + ' error');
    //     });
    // }

    updateUserProfile(profile_url:any){
        firebase.auth().currentUser?.updateProfile({
            displayName: null,
            photoURL: profile_url
        }).then(function () {
        }, function (error:any) {
            // console.log(error + ' error in update user file');
        });
    }

    getPhoneNumber() {
        return this.currentUser.phoneNumber;
    }

    /**
     * this method is use to redirect if user is already logged in
     */
    checkAndRedirectIfLoggedIn() {
        this.afAuth.authState.subscribe((auth) => {
            if (auth != null) {
                if (typeof this.redirectUrl != 'undefined' && this.redirectUrl != null) {
                    this._router.navigateByUrl(this.redirectUrl);
                    this.redirectUrl = null;
                }
                else {
                    this._router.navigateByUrl('home', { replaceUrl: true });
                }
            }
        });
    }

    signOut() {
        this.afAuth.auth.signOut();
        // this._router.navigateByUrl('login',{skipLocationChange: true});
    }


    getAuthStateSync(callback:any) {
        let afSubs = this.afAuth.authState.subscribe((auth) => {
            if (auth) {
                callback(true);
                afSubs.unsubscribe();
            }
            else {
                callback(false);
                afSubs.unsubscribe();
            }
        });
    }

    signInWithCustomToken(customToken: any, callback:any) {
        this.afAuth.auth.signInWithCustomToken(customToken).then((result:any) => {
            callback(true);
        }).catch((err:any) => {
            callback(false);
        })
    }

    signoutSilently() {
        this._zone.run(() => {
            this.afAuth.auth.signOut();
        });
    }
}
