<div class="main-wrapper">
  <div class="page-wrapper full-page">
    <div class="page-content d-flex align-items-center justify-content-center">

      <div class="row w-100 mx-0 auth-page">
        <div class="col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center">
          <h1 class="font-weight-bold mb-22 mt-2 tx-80 text-muted">404</h1>
          <h4 class="mb-2">Page Not Found</h4>
          <h6 class="text-muted mb-3 text-center" innerHTML="Oopps!! The page you were looking for doesn\'t exist."></h6>
          <a routerLink="" class="btn btn-primary">Back to home</a>
        </div>
      </div>

    </div>
  </div>
</div>