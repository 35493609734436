import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutFaqComponent } from './modules/user-profile/about-faq/about-faq.component';
import { AuthGuard } from "./services/authguard-service";
import { HelpreqoptComponent } from "./modules/helpreqopt/helpreqopt.component";
import { PageNotFoundComponent } from './shared-components/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './modules/terms_and_conditions/privacy_policy/privacy-policy.component';
import { RefundPolicyComponent } from './modules/terms_and_conditions/refund_policy/refund-policy.component';
import { TermsOfUseComponent } from './modules/terms_and_conditions/terms_of_use/terms-of-use.component';
import { LandingPageComponent } from './modules/landing-page/landing-page.component';
import { HrGuard } from './services/hrguard-service';
import { ExpandedViewGuard } from './services/expanded-view-guard.service';

// code 000013
const routes: Routes = [
  {
    path: '', 
    redirectTo: 'home', pathMatch: 'full'
  },
  {
    path:'create-help-request',
    redirectTo:'home',pathMatch:'full'
  },
  {
    path: 'home',
    canActivate:[HrGuard],
    // loadChildren:'./modules/help-request/help-request.module#HelpRequestModule',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    // loadChildren:'./modules/home/home.module#HomeModule',
    data: {
      seo: {
        title: 'Jaano - Community Help',
        metaTags: [
          { name: 'title', content: 'Jaano - Community Help' },
          { property: 'description', content: 'India’s Civic Participation Platform, helps people by solving problem. Official News about various government schemes.' },
          { property: 'og:title', content: 'Jaano - Community Help' },
          { property: 'og:description', content: 'India’s Civic Participation Platform, helps people by solving problem. Official News about various government schemes.' },
          { property: 'keywords', content: 'community help, public services, district administration, local news, city news, district news, official news, government updates, नागरिक भागीदारी प्लेटफार्म, लोकल न्यूज, civic engagement' }
        ]
      }
    }
  },
  // {
  //   path: 'home/hr',
  //   loadChildren:'./modules/home/home.module#HomeModule',
  // },
  {
    path: 'feeds/help',
    redirectTo: 'home'
  },
  {
    path: 'help/help',
    redirectTo: 'home'
  },
  // {
  //   path: 'feeds/official-feed',
  //   redirectTo: 'official-feed'
  // },
  {
    path: 'feeds/home',
    redirectTo: 'home'
  },
  // {
  //   path: 'feeds',
  //   // loadChildren: './modules/MainFeeds/feeds.module#FeedsModule',
  //   loadChildren: () => import('./modules/MainFeeds/feeds.module').then(m => m.FeedsModule),
  // },
  {
    path: 'help',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'help',
  //  loadChildren:'./modules/help-request/help-request.module#HelpRequestModule'
   loadChildren: () => import('./modules/help-request/help-request.module').then(m => m.HelpRequestModule),
  },
  {
    path: 'expanded-view',
  //  loadChildren:'./modules/help-request/help-request.module#HelpRequestModule'
   loadChildren: () => import('./modules/expanded-view/expanded-view.module').then(m => m.ExpandedViewModule),
  },
  // {
  //   path: 'official-feed',
  //   loadChildren: './modules/officialFeeds/feeds.module#OfficialFeedsModule'
  // },
  {
   path:'help-request',
   canActivate:[ExpandedViewGuard],
  //  loadChildren:'./modules/help-request/help-request.module#HelpRequestModule'
   loadChildren: () => import('./modules/help-request/help-request.module').then(m => m.HelpRequestModule),
  },
  {
    path: 'user-profile',
    // loadChildren: './modules/user-profile/user-profile.module#UserProfileModule',
   loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule),
    // canActivateChild: [AuthGuard],
  },
  {
    path: 'notifications',
    // loadChildren: './modules/notifications/notifications.module#NotificationsModule',
   loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
  },
  {
    path: 'aboutus',
    component: AboutFaqComponent,
    data: {
      seo: {
        title: 'Know more about Jaano',
        metaTags: [
          { name: 'title', content: 'Know more about Jaano' },
          { property: 'description', content: 'Jaano Local solves the problem of serving contextualised hyper-local official information about public services while creating a channel for public feedback that helps in identifying specific challenges in each area.' },
          { property: 'og:title', content: 'Know more about Jaano' },
          { property: 'og:description', content: 'Jaano Local solves the problem of serving contextualised hyper-local official information about public services while creating a channel for public feedback that helps in identifying specific challenges in each area.' },
          { property: 'keywords', content: 'government hospitals, CSC, district hospitals, private hospitals, seva kendra, corona centres, quarantine centres, corona testing centres, pds, fps, ration shops, community kitchens, essential services, government schools, private schools, primary schools, secondary schools' }
        ]
      }
    }
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'refund',
    component: RefundPolicyComponent
  },
  {
    path: 'terms',
    component: TermsOfUseComponent
  },
  {
    path: 'helprequest', 
    redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'paid-help-request',
    component: LandingPageComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers : [AuthGuard,HrGuard,ExpandedViewGuard]
})
export class AppRoutingModule { }
