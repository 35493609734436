<div class="container_toasty">
    <!-- <button (click)="closeModal()" type="button" class="close" data-dismiss="modal">&times;</button> -->
    <img class="mb-5" height="140" src="../../assets/images/{{data.image}}" alt="Image">
    <div class="heading mb-4" *ngIf="data.heading">
        {{data.heading}}
    </div>
    <div class="title_toasty mb-5">
        {{data.title}}
    </div>
    <div *ngIf="data.action" class="action_toasty" (click)="onClickOfAction()">{{data.action}}</div>
</div>