import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'snack-bar-component-example-snack',
  templateUrl: './custom-toasty.component.html',
  styleUrls: ['./custom-toasty.component.css']
})
export class CustomToastyComponent {
  data: any;

  constructor(
    public snackBarRef: MatSnackBarRef<CustomToastyComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public snackdata: any) {
    this.data = snackdata;
  }

  onClickOfAction() {
    if (this.data.click_action) {
      window.location.href = this.data.click_action;
    }
  }
}