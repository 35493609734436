import { Injectable } from '@angular/core';
import { AngularFireMessaging } from 'angularfire2/messaging';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { CustomToastyComponent } from '../shared-components/custom-toasty/custom-toasty.component';
import { CustomDialogComponent } from '../shared-components/custom-dialog/custom-dialog.component';
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  payloadData = [];
  getNotificationArr:any = [];
  data: any;
  navigationPath: any;
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        // refresh notification token
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        localStorage.setItem('isTokenCaptured', 'true')
        this.commonService.setWebNotificationToken(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  getToken() {
    this.angularFireMessaging.getToken.subscribe((res) => {
      if (res) {
        localStorage.setItem('isTokenCaptured', 'true')
        this.commonService.setWebNotificationToken(res);
      }
    })
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload:any) => {
        payload['isViewed'] = false;
        const notificationArr:any=localStorage.getItem('notificationArr');
        this.getNotificationArr = JSON.parse(notificationArr);
        if (this.getNotificationArr && this.getNotificationArr.length !=0 && this.getNotificationArr != null) {
          this.payloadData = this.getNotificationArr.concat(payload);
          localStorage.setItem('notificationArr', JSON.stringify(this.payloadData));
        } else {
          this.payloadData = this.payloadData.concat(payload);
          localStorage.setItem('notificationArr', JSON.stringify(this.payloadData));
        }
        this.currentMessage.next(payload);
        this.showCustomNotification(payload);
      });
  }

  /**
   * this method is use to show notification when tab is active
   * @param payload notification data
   */
  showCustomNotification(payload:any) {
    let notify_data = payload['notification'];
    let data_payload = payload['data'];
    if (notify_data) {
      let title = notify_data['title'];
      let options = {
        body: notify_data['body'],
        icon: '/assets/images/Group 54.svg',
        badge: '/assets/images/Group 54.svg',
        image: '/assets/images/Group 54.svg'
      };
      let notify: Notification = new Notification(title, options);
      let url = payload.notification.click_action;
      notify.onclick = event => {
        event.preventDefault();
        window.location.href = url;
      };
    } else if (data_payload) {
      if (data_payload.page == 'approvalStatusUpdateCF'  || data_payload.page == 'approvalStatusUpdateHR' || data_payload.page == 'approvalStatusUpdateRVW') {
        if (data_payload.approvalStatus && data_payload.approvalStatus == "1") {
          this.dialog.open(CustomDialogComponent, {
            data: Object.assign({ title: 'Your post has been approved!', action: 'View', image: 'approved_toasty_icon.svg' }, data_payload),
            maxWidth: '400px'
          });
          // var snackBarRef: any;
          // snackBarRef = this._snackBar.openFromComponent(CustomToastyComponent,
          //   {
          //     data: Object.assign({ title: 'Your post has been approved!', action: 'View', image: 'approved_toasty_icon.svg' }, data_payload), duration: 5000,
          //     panelClass: ['white-snackbar']
          //   });
        } else {
          this.dialog.open(CustomDialogComponent, {
            data: Object.assign({ title: 'Sorry! Your post has been disapproved!', action: 'Know more', image: 'disapproved_toasty_icon.svg' }, data_payload),
            maxWidth: '400px'
          });
          // this._snackBar.openFromComponent(CustomToastyComponent,
          //   {
          //     data: Object.assign({ title: 'Sorry! Your post has been disapproved!', action: 'Know more', image: 'disapproved_toasty_icon.svg' }, data_payload), duration: 5000,
          //     panelClass: ['white-snackbar']
          //   });
        }
      }
    }
  }
}
