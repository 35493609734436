import {Injectable} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ShowToastyService {

    constructor(private toastrService:ToastrService){
        
    }

    success(title:string, message:string) {
      let toastOptions = {
        closeButton: true,
        timeOut: 3000,
        tapToDismiss: true
      };
      this.toastrService.success(title, message, toastOptions);
    }
  
    failure(title:string, message:string) {
      let toastOptions = {
        timeOut: 3000,
        closeButton : true,
        tapToDismiss: true
      };
      this.toastrService.error(title, message,toastOptions);
    }
  

}
