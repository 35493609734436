import { Component, OnInit, Inject, NgZone, ChangeDetectorRef } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";

import { LanguageTranslationService } from "../../services/language-translation-service";
import { ShowToastyService } from '../../services/showToastyService';
import { DataService } from '../../services/data.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { LoclangDialogsService } from "../../services/loclang-dialogs.service";
import { CommonService } from '../../services/common.service';
import { LoginDialogService } from '../../services/loginService';


@Component({
  selector: 'app-helpreqopt',
  templateUrl: './helpreqopt.component.html',
  styleUrls: ['./helpreqopt.component.scss']
})
export class HelpreqoptComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  
  allquestions: any[] = [];
  questions:any = [];
  selectedLang = 'en';
  IsLoader = false;

  userLatLng = {
    lat: '',
    lng: ''
  }
  stcode11: any;
  dtcode11: any;
  stname: any;
  dtname: any;
  name = '';
  mobileScreen = false;
  allowHelpReq = true;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private languageService: LanguageTranslationService, private dataService: DataService,
    private loclatdialog: LoclangDialogsService, private commonService: CommonService, private loginService: LoginDialogService, private cdf: ChangeDetectorRef,
    private cookieService: CookieService, private toastyService: ShowToastyService, private dialog: MatDialog, private ngZone: NgZone,
    private googleAnalyticsService: GoogleAnalyticsService, private router: Router, private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<HelpreqoptComponent>) { 
      // adding cookie for comming from google ad
      this.cookieService.put('fromGoogleAds','fromGoogleAds');
      const landing_A = this.cookieService.get('landing_OLD')
      if(!landing_A){
        // also hit google event only first time.
        this.googleAnalyticsService.eventEmitter(
          "landing_OLD",
          "",
          "landing page for case OLD"
        );
        this.googleAnalyticsService.eventEmitter(
          "landing_exp_view",
          "",
          "landing page for case a/b"
        );
        (window as any).hj('event', 'landing_exp_view');
        this.cookieService.put('landing_OLD','true');
      }
    }


  ngOnInit() {
    this.getStandardQues();

    // if (isPlatformBrowser(this.platformId)) {
    //   if (!(localStorage.getItem('lang') && localStorage.getItem('user_location'))) {
    //     let dialogRef = this.dialog.open(SplashScreenComponent, { width: '100%', height: '100%', maxWidth: '100%', panelClass: 'no-padding-dialog'});
    //     dialogRef.afterClosed().subscribe(result => {
    //       this.loadData();
    //     });
    //   } else {
    //     this.loadData();
    //   }
    // } else {
    // }
    this.loadData();

    // adding google event
    this.googleAnalyticsService.eventEmitter(
      "ja_landing",
      "",
      "Landing on help request list from google Ads"
    );
  }

  loadData() {
    this.languageService.getSelectedLanguage().subscribe((value) => {
      let selectedLanguage = 'en';
      if (value) {
        selectedLanguage = value;
        this.selectedLang = selectedLanguage;

        this.loginService.userLoggedInStatus().subscribe(message => {
          if (message) {
            this.name = this.commonService.getUsername();
          }
        });

        this.name = this.commonService.getUsername();

        this.commonService.selectedLocation.subscribe((value) => {
          if (value && Object.keys(value).length > 0  && (Number(localStorage.getItem('admin_defined_location')) > 0 || Number(localStorage.getItem('admin_defined_location')) < 0)) {
            this.stcode11 = localStorage.getItem('stcode11');
            this.dtcode11 = localStorage.getItem('dtcode11');
            this.stname = localStorage.getItem('stname');
            this.dtname = localStorage.getItem('dtname');
            this.userLatLng.lat = value.lat();
            this.userLatLng.lng = value.lng();
            if(Number(localStorage.getItem('admin_defined_location'))  == 1)
            localStorage.setItem('admin_defined_location', '0');
          } else {
            if (isPlatformBrowser(this.platformId)){
              localStorage.setItem('admin_defined_location', '-1');
              this.loclatdialog.openChangeLocationDialog((res:any) => {  });
            }
          }
        })

      } else {
        // if (isPlatformBrowser(this.platformId))
        //   this.loclatdialog.openChangeLanguageDialog(() => { });
        // else
        //   this.languageService.setSelectedLanguage("en");
      }
    });
  }

  ngAfterViewInit() {
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      this.mobileScreen = true;
    }
    else {
      this.mobileScreen = false;
    }
  }

  getStandardQues(){
    this.IsLoader = true;
    let hrIdArray:any = [];
    this.googleAnalyticsService.eventEmitter('qstn_pu_open',"questions pop-up","open questions pop-up","questions pop-up");
    (window as any).hj('event', 'qstn_pu_open');
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if(params['ctg'] && params['subcat']){
        const ctg = params['ctg'];
        const sub_ctg = params['subcat'];
        const req= {
          ctgid: ctg,
          subctgid: sub_ctg
        }
        this.dataService.getStandardHelpRequestsWithCtg(req).subscribe(res => {
          if (res.success == false) {
            this.toastyService.failure('', this.languageService.getTranslated('something_went_wrong'));
          } else {
            this.allquestions = res.result;
            this.activatedRoute.queryParams.subscribe((params: Params) => {
              this.questions = this.allquestions;
              
              if(this.allquestions){
                this.selectedCatId= this.questions[0].ctg_id;
                this.selectedSubCatId = this.questions[0].sub_ctg_id;
              }
            });
          }
          this.IsLoader = false;
        },
        error=>{
          this.IsLoader = false;
        });
      }
      else{
        let helpsIndexes = params['hr'];
        if(helpsIndexes){
          hrIdArray = helpsIndexes.split('h')
        }
        hrIdArray = hrIdArray.filter((data:any)=>data != '');
        let hrIdFinalArray :number[] = [];
        hrIdArray.forEach((data:any)=> hrIdFinalArray.push(+data))
        const req= {"hr_id":hrIdFinalArray};
        this.dataService.getStandardHelpRequests(req).subscribe(res => {
          if (res.success == false) {
            this.toastyService.failure('', this.languageService.getTranslated('something_went_wrong'));
          } else {
            this.allquestions = res.result;
            this.activatedRoute.queryParams.subscribe((params: Params) => {
              let helpsIndexes = params['hr'];
              if (helpsIndexes) {
                this.questions = this.allquestions;
                
                if(this.allquestions){
                  this.selectedCatId= this.questions[0].ctg_id;
                  this.selectedSubCatId = this.questions[0].sub_ctg_id;
                }
              }
            });
          }
          this.IsLoader = false;
        },
        error=>{
          this.IsLoader = false;
        });
      }
    },error=>{
      this.IsLoader = false;
    });
  }

  onAQuesClick(ques:any) {
    this.IsLoader = true;
    const obj = { autoChangeScreen: true, width: "600px",height: "400px",minWidth:"328px", showModal:true,
    panelClass: 'confirmPaidHelpDialog',fromGoogleAds:true}
    this.googleAnalyticsService.eventEmitter('qstn_sel',"questions pop-up","select question");
    (window as any).hj('event', 'qstn_sel');
    this.loginService.openModal(obj, (status: string) => {
      if (status) {
        this.createAHelpReq(ques, true);
        this.dialogRef.close(true);
      }
      else{
        this.IsLoader = false;
      }
    })
  }

  createAHelpReq(ques:any, is_standard:any) {
    this.IsLoader = true;
    let metaData = {
      "virtual_id": localStorage.getItem('virtualId'),
      "browser_userAgent": this.cookieService.get('browser_userAgent'),
      "LanguageSelected": this.cookieService.get('LanguageSelected'),
      "LocationSelected": this.cookieService.get('LocationSelected'),
      "platform": this.cookieService.get('platform'),
      "is_logged__in": this.cookieService.get('is_logged__in'),
      "Webtoken": localStorage.getItem('Webtoken'),
      "username": this.cookieService.get('username'),
      "mobile_number": this.cookieService.get('mobile_number')
    }

    let standardHrLang = this.cookieService.get("LanguageSelected")?this.cookieService.get("LanguageSelected"): "en";
    let body = {
      "username": this.name,
      "category": ques.ctg_id,
      "subCategory": ques.sub_ctg_id,
      "lat": this.userLatLng.lat,
      "lng": this.userLatLng.lng,
      "metadata": JSON.stringify(metaData),
      "stcode11": this.stcode11,
      "dtcode11": this.dtcode11,
      mandalname: localStorage.getItem('mandalname'),
      mandalcode: localStorage.getItem('mandalcode'),
      clustername: localStorage.getItem('clustername'),
      clustercode: localStorage.getItem('clustercode'),
      "helpType": 4,
      "helpTitle": "",
      "stname": this.stname,
      "dtname": this.dtname,
      "helpVideo": "",
      "helpVoice": "",
      "helpPictures": "{}",
      "helpDescription": ques['hr_' + this.selectedLang],
      standard_hr_lang:standardHrLang
    }

    if(is_standard){
      localStorage.setItem('topic', ques.topic);
      Object.assign(body, {
        "standard_hr_id": ques.hr_id,
        "is_standard": true,
        "standard_hr_lang" : this.selectedLang,
        "topic": ques.topic
      })
    }
    this.dataService.createHelpRequestv2(body).subscribe(req => {
      localStorage.setItem("helpID", req.result.helpid);
      if (req.success == false) {
        if (req.msg == "either of the media type should be supplied to add help request") {
          this.toastyService.failure('', this.languageService.getTranslated('Please select Atleast one media Option'));
          this.IsLoader = false;
          return;
        }
        this.IsLoader = false;
        this.dialogRef.close(true);
      }
      else {
        this.ngZone.run(() => {
          setTimeout(() => {
            localStorage.setItem('hrposted_'+ this.name  , 'posted');
            if(req.is_created){
              this.dialogRef.close(true);
            this.router.navigate(['help-request/getHelp-RequestbyId'], { queryParams: {
              "upload": "help",
              newHRID: req.result.helpid,
              Id : req.result.helpid,
              status: '1',
              source: "home", } });
              this.googleAnalyticsService.eventEmitter(
                "ja_create_free_hr",
                "",
                "unpaid Help created from google ads redirect"
              );
              (window as any).hj('event', 'UnpaidHR_Created_GflowListview');
            } else{
              this.dialogRef.close(true);
              if (req.result.topic) localStorage.setItem('topic', req.result.topic);
              localStorage.setItem('status','0');
              localStorage.setItem('source','home');
              this.router.navigate(['help-request/getHelp-RequestbyId'], {
                queryParams: {
                  Id: req.result.helpid,
                  status: '0',
                  source: "home",
                }
              });
            }
            this.cdf.detectChanges();
            const googleAnalyticKey = `qstn_${req.result.helpid}`;
            this.googleAnalyticsService.eventEmitter2(googleAnalyticKey,{});
            (window as any).hj('event', googleAnalyticKey);
            (window as any).hj('event', 'qstn_');
          }, 0)
          // this.googleAnalyticsService.eventEmitter("web_help-request_created", "", "User creates help-request");
        },
        (error:any)=>{
          this.IsLoader = false;
          this.dialogRef.close(true);
        });
      }
    },
    error=>{
      this.IsLoader = false;
      this.dialogRef.close(true);
    })
  }

  onCreateOwnQues() {
    this.googleAnalyticsService.eventEmitter2('web_e1_hr_Own_hr', { platform: "web" });
    this.dialogRef.close(true);
    this.router.navigate(['help-request/edit-request'], {
      queryParams: {
        cat: 15, sub_cat: 89  //Emitra, Aadhar
      }
    });
  }

  selectedCatId:string="";
  selectedSubCatId:string="";
  navigateToPage(link:any) {
    const obj = { autoChangeScreen: true, width: "600px",height: "400px",minWidth:"328px", showModal:true,
    panelClass: 'confirmPaidHelpDialog',fromGoogleAds:true}
    this.googleAnalyticsService.eventEmitter('qstn_view_more',"questions pop-up","select view more questions");
    (window as any).hj('event', 'qstn_view_more');
    this.loginService.openModal(obj, (status: string) => {
      if (status) {
        this.dialogRef.close(true);
        this.router.navigate([link], {
          relativeTo: this.activatedRoute,
          queryParams: {
            category: this.selectedCatId,
            subcategory: this.selectedSubCatId
          }
        });
        // this.googleAnalyticsService.eventEmitter2('web_e1_hr_selected', { platform: "web" });
      }
    })
  }

}