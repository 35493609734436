import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { CookieService } from "ngx-cookie";
import { timestamp } from "rxjs/operators";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  details = {
    lat: "",
    lng: "",
  };
  public selectedLocation: BehaviorSubject<any>;
  jaano_points_counter: BehaviorSubject<any>;
  subscribedUsername: BehaviorSubject<string>;
  dontShowFooter: BehaviorSubject<boolean>;
  ignoreOffer: BehaviorSubject<boolean>;
  referralCodeValue: BehaviorSubject<any>;
  selectedGrvId = "";
  selectedUserPlace;
  filterData: any;
  selectLocationDetails: any;
  selectedTab: any;
  username:any;
  currentUserToken:any;
  webNotificationToken;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private cookieService: CookieService, private dataService:DataService
  ) {
    if (localStorage.getItem("user_location")) {
      // get stored location from local storage
      const userlocation:any=localStorage.getItem("user_location");
      const storelocation = JSON.parse(userlocation);
      this.selectedLocation = new BehaviorSubject({
        lat() {
          return storelocation.lat;
        },
        lng() {
          return storelocation.lng;
        },
      });
    } else {
      this.selectedLocation = new BehaviorSubject({});
    }
    this.selectLocationDetails = this.selectedLocation.asObservable();
    if (localStorage.getItem("jaano_points")) {
      const points = localStorage.getItem("jaano_points");
      this.jaano_points_counter = new BehaviorSubject(points);
    } else {
      this.jaano_points_counter = new BehaviorSubject(0);
    }
    if (localStorage.getItem("username")) {
      const username:any = localStorage.getItem("username");
      this.subscribedUsername = new BehaviorSubject(username);
    } else {
      this.subscribedUsername = new BehaviorSubject('');
    }

    this.webNotificationToken = new BehaviorSubject("");
    this.selectedUserPlace = new BehaviorSubject([]);
    this.dontShowFooter = new BehaviorSubject(false);
    this.ignoreOffer= new BehaviorSubject(false);
    this.referralCodeValue = new BehaviorSubject(null);
  }

  public getrecommended = new BehaviorSubject<any[]>([]);
  readonly getrecommendeddatas = this.getrecommended.asObservable();

  public reqStatusmap = new BehaviorSubject<any[]>([]);
  readonly reqStatusfetch = this.reqStatusmap.asObservable();


  setRefferalCode(referralCode:any) {
    localStorage.setItem('referralCode', referralCode);
    if(referralCode == null){
      localStorage.removeItem('referralCode');
    }
    this.referralCodeValue.next(referralCode);
  }

  getRefferalCode() {
    return this.referralCodeValue;
  }

setReqstatus(reqstatus:any)
{
  this.reqStatusmap.next(reqstatus)
}


  setRecommended(value:any) {
    console.log("jhkdbsjkhdbs", value);
    this.getrecommended.next(value);
  }
  getselectedGrvId() {
    return this.selectedGrvId;
  }

  setselectedGrvId(selectedGrvId:any) {
    this.selectedGrvId = selectedGrvId;
  }

  getWebNotificationToken() {
    return this.webNotificationToken;
  }

  setWebNotificationToken(webNotificationToken:any) {
    this.cookieService.put("Webtoken", webNotificationToken);
    localStorage.setItem("Webtoken", webNotificationToken);
    this.webNotificationToken.next(webNotificationToken);
  }

  getselectedTab() {
    return this.selectedTab;
  }

  setselectedTab(selectedTab:any) {
    this.selectedTab = selectedTab;
  }

  getUsername() {
    if (this.username) {
      return this.username;
    } else {
      // this.setUsername(this.authSerivce.getUsername());
      // console.log(this.authSerivce.getUsername());
      // return this.authSerivce.getUsername();
      return localStorage.getItem("username");
    }
  }

  async getUserFullName(){
    return new Promise((resolve,reject)=>{
      const fullName = localStorage.getItem('full_name');
      const phoneNo = localStorage.getItem('phone_no');
      if(phoneNo && !fullName){
        const data2 = { phone_no: phoneNo };
        this.dataService.getDataByEntity('getUserProfile/getUserDetails', data2).subscribe(result => {
          if(result && result.result && result.result.length>0){
            const fullName_ = result.result[0].full_name;
            localStorage.setItem('full_name', fullName_);
            resolve(fullName_);
          }
          else{
            resolve(null);
          }
        },error=>{
          resolve(null);
        });
      }
      else{
        resolve(fullName);
      }
    })
  }
  getHelpId() {
    let helpid = localStorage.getItem("create_paid_help_id");
    if (helpid) {
      return helpid;
    } else {
      return;
    }
  }

  setUsername(username:any) {
    this.username = username;
    if (username) {
      localStorage.setItem("username", this.username);
    } else {
      localStorage.removeItem("username");
    }
    this.subscribedUsername.next(this.username);
  }

  subscribeToUsername() {
    return this.subscribedUsername.asObservable();
  }

  setCurrentUserToken(currentUserToken:any) {
    if (currentUserToken) {
      this.currentUserToken = currentUserToken;
    }
  }

  getFilterData() {
    return this.filterData;
  }

  setFilterData(filterData:any) {
    this.filterData = filterData;
  }

  setSelectedLocation(selectedLocationDetail:any) {
    const formattedLocation = {
      lat: selectedLocationDetail.lat(),
      lng: selectedLocationDetail.lng(),
    };
    localStorage.setItem("user_location", JSON.stringify(formattedLocation));
    // this.details.lat = selectedLocationDetail.lat();
    // this.details.lng = selectedLocationDetail.lng();
    // this.details['language'] = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
    // this.details['username'] = localStorage.getItem('username');
    // this.dataService.getUserLocationDetails(this.details).subscribe((res) => {
    //   if (res.success) {
    // localStorage.setItem('stcode11', res.data.stcode11);
    // localStorage.setItem('dtcode11', res.data.dtcode11);
    // localStorage.setItem('stname', res.data.stname);
    // localStorage.setItem('dtname', res.data.dtname);
    // localStorage.setItem('district_ln', res.data.district_ln);
    // localStorage.setItem('mandalname', res.data.mandalname);
    // localStorage.setItem('mandalcode', res.data.mandalcode);
    // localStorage.setItem('clustername', res.data.clustername);
    // localStorage.setItem('clustercode', res.data.clustercode);
    this.selectedLocation.next(selectedLocationDetail);
    // }
    // else {
    //   this.selectedLocation.next(null);
    // }
    // })
  }

  setSelectedPlace(userPlace:any) {
    this.selectedUserPlace.next(userPlace);
  }

  setJaanoPoints(jaano_points: number) {
    this.jaano_points_counter.next(jaano_points);
  }

  incrementJaanoPoints(incrementBy:any) {
    let JP = this.jaano_points_counter.getValue();
    if (typeof JP == "string") JP = parseInt(JP);
    this.jaano_points_counter.next(JP + incrementBy);
  }

  decrementJaanoPoints(decrementBY:any) {
    let JP = this.jaano_points_counter.getValue();
    if (typeof JP == "string") JP = parseInt(JP);
    this.jaano_points_counter.next(JP > 0 ? JP - decrementBY : JP);
  }

  getJaanoPoints() {
    return this.jaano_points_counter.asObservable();
  }

  getWhatsappLink() {
    return "https://web.whatsapp.com/send?text=";
  }

  getTwitterLink() {
    return "http://twitter.com/share?";
  }

  getGooglePlayStoreLink() {
    // this.googleAnalyticsService.eventEmitter("web_navigate_to_playstore", page, "User navigate to play-store to download the app");
    return "https://play.google.com/store/apps/details?id=com.jaano.android";
  }

  getAppStoreLink() {
    // this.googleAnalyticsService.eventEmitter("web_navigate_to_appstore", "", "User navigate to app-store to download the app");
    return "https://apps.apple.com/in/app/jaano/id1517955230";
  }

  getDynamicAppDownloadLink() {
    return "https://jaano.page.link/app";
  }

  getSwanitiLink() {
    return "http://www.swaniti.com";
  }

  getBrowserDetails() {
    var nAgt = navigator.userAgent;
    var browserName = navigator.appName;
    var fullVersion = "" + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // In Opera 15+, the true version is after "OPR/"
    if ((verOffset = nAgt.indexOf("OPR/")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    var browser_details = {
      browser_name: browserName,
      full_version: fullVersion,
      major_version: majorVersion,
      app_name: navigator.appName,
      app_code_name: navigator.appCodeName,
      app_version: navigator.appVersion,
      cookies_enabled: navigator.cookieEnabled,
      language: navigator.language,
      online: navigator.onLine,
      platform: navigator.platform,
      user_agent_header: navigator.userAgent,
    };

    return browser_details;
  } //end of getBrowserDetails()


  timeStampToTime(timeStamp:string):string{
    let date = new Date();
    let startDate = new Date(date.getTime());
    startDate.setDate(date.getDate() - 0);
    let current = new Date(startDate.getFullYear(),startDate.getMonth(),
                                  startDate.getDate()).getTime()/1000;

    // if the date from timestamp is same as todays date: timestamp is after 00:00 of
    // today then use the normal date.
    if(current <= +timeStamp){
      current = new Date().getTime()/1000;
    }
    // otherwise use the time starts at 00:00

    let timeDiff = Math.floor(((current - +timeStamp))/60);
    // if timediff is greater than 30days.
    if(Math.floor(((timeDiff/60)/24/30))>0){
      let date = new Date(+timeStamp*1000);
      let day = date.getDate();
      let month = ''+ (date.getMonth()+1);
      let year = date.getFullYear();
      let newDate = day + '/'+ month + "/" + year;
      return newDate;
    }
    // if timediff is greater than 24 hours.
    if(Math.floor(((timeDiff/60)/24))>0){
      timeDiff = Math.ceil((timeDiff / 60 )/24);
      timeStamp = timeDiff.toString()+ " days ago";
    }
    // otherwise if timediff is greater than 1 hour
    else if(Math.floor(timeDiff/60)>0){
      timeDiff = Math.ceil(timeDiff / 60 );
      timeStamp = timeDiff.toString() + " hours ago";
    }
    // otherwise if timediff is greater than 1 minute
    else if(timeDiff>0){
      timeStamp = timeDiff.toString()+ " minutes ago";
    }
    else{
      timeStamp = Math.floor(((current - +timeStamp)/1000))+ " seconds ago";
    }
    return timeStamp;
  }
}
