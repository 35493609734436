import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router,NavigationEnd } from "@angular/router";
import { CommonService } from "../../../../services/common.service";
import { DataService } from "../../../../services/data.service";
import { GoogleAnalyticsService } from "../../../../services/google-analytics.service";
import { PopupserviceService } from "../../../../services/popupservice.service";
import { GetSpecificHelpRequestService } from "../../../../services/get-specific-help-request.service";
import { VideoPlayer } from "../../../../shared-components/video_player_dialog/video_player_dialog";
import { ConfirmDialogPaidHelpComponent } from "../confirm-dialog-paid-help/confirm-dialog-paid-help.component";

@Component({
  selector: "app-confirm-dialog-one",
  templateUrl: "./confirm-dialog-one.component.html",
  styleUrls: ["./confirm-dialog-one.component.scss"],
})
export class ConfirmDialogOneComponent {
  dialogOne: boolean;
  dialogTwo: boolean;
  gotoCreate: boolean=false;
  skip: boolean=false;
  recommendedId: any;
  dialogThree: boolean = false;
  helpid: any;
  phone_no: any;
  route: any;
  exitConfirm=false;
  assignedEmitra="";
  helpRequestId=-1;
  assignedAmount = 0;
  nextState="";

  constructor(
    private getSpecificHelpRequestService: GetSpecificHelpRequestService,
    private dialog: MatDialog,
    private dataService: DataService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private popup: PopupserviceService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<ConfirmDialogOneComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    this.nextState = data && data.nextState?data.nextState.url:"";
    this.assignedEmitra =data?data.assignedEmitra:null;
    this.assignedAmount = data?data.assignedAmount:0;
    this.helpRequestId = data?data.helpid:-1;
    this.googleAnalyticsService.eventEmitter('survey_pu_open',"survey pop-up","open survey pop-up");
    (window as any).hj('event', 'survey_pu_open');
    this.commonService.getrecommendeddatas.subscribe((res) => {
      if (res) {
        // let response = JSON.stringify(res);
        // response.Id;
        this.recommendedId = res;
      }
      // this.dialogOne = true;
    });
    this.dialogOne = true;
    this.dialogTwo = false;
    this.dialogThree = false;
    // adding event ja_popup_visit
    this.googleAnalyticsService.eventEmitter(
      "ja_popup_visit",
      "",
      "Showing popup page on moving from the expanded view of help request"
    );
    this.setCountDown();
  }

  numberOfClicks(opt: string) {
    console.log(opt, this.recommendedId.length);
    if (opt == "yes" && this.recommendedId.length > 0) {
      console.log("----testt---");
      // this.router.navigate(["help-request/getHelp-RequestbyId"], {
      //   queryParams: { Id: this.recommendedId },
      // });
      // // localStorage.setItem("popuphelpID", this.recommendedId);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 500);
    } else {
      this.getSpecificHelpRequestService.getRequestData().subscribe((res) => {
        if (res) {
          this.helpid = res.helpid;
          this.phone_no = res.username;
          
          let user = {
            username: res.username,
            helpid: res.helpid,
            ctg: res.ctg,
            sub_ctg: res.sub_ctg,
            response: opt,
                       
          };
          this.dataService.recordUsersAction(user).subscribe((res) => {
          });
        }
        if (opt == "no") {
          this.dialogOne = false;
          this.dialogTwo = true;
          this.gotoCreate = true;
        }
      });
    }
  }

  addRequest() {
    setTimeout(() => {
      console.log("++++++++++++++++++++++++++++++++");
      this.getSpecificHelpRequestService.getRequestData().subscribe((res) => {
        this.router.navigate(["./help-request/create-request"], {
          queryParams: { cat_id: res.ctg },
        });
      });
    }, 200);
  }

  requestPaidHelp() {
    const paidHelpDialog = this.dialog.open(ConfirmDialogPaidHelpComponent, {
      disableClose: true,
      panelClass: 'confirmPaidHelpDialog',
      width:'435px',
      maxHeight:'620px'
    });
    let confirmDialogClass = 
    document.getElementsByClassName('confirmDialogBox1') as HTMLCollectionOf<HTMLElement>;
    for(let i=0; i<confirmDialogClass.length; i++){
      let div= confirmDialogClass[i];
      div.setAttribute("style","opacity:0");
    }
    paidHelpDialog.afterClosed().subscribe((result:any) =>{
      for(let i=0; i<confirmDialogClass.length; i++){
        let div= confirmDialogClass[i];
        div.setAttribute("style","opacity:1");
      }
      if(result){
        this.dialogRef.close(false);
      }
    })
    // this.dialogOne = false;
    // this.dialogTwo = false;
    // // this.dialogThree = true;
    let jsonRequest = {
      "helpid": this.helpid,
      "phone_no": this.phone_no,
      "opt": "y"
    }
    this.dataService
      .callv3API("setToOptForPaidHR", jsonRequest)
      .subscribe((res) => {
        console.log("res", res, res.success)
         
        // this.dialogOne = false;
        // this.dialogThree = true;
        // this.gotoCreate = true;

        // setTimeout(() => {
        // }, 3000);
      })
    //   this.dialog.closeAll()
    //   this.dialogRef.close(true);

    // // generating a new route to create help request page.
    // this.router.navigate(["help-request/create-request"], {
    //     queryParams: { paidType: true },
    // });
  }

  close() {
    this.dialog;
    let jsonRequest = {
      "helpid": this.helpid,
      "phone_no": this.phone_no,
      "opt": "n"
    }
    this.dialogRef.close(true);
    this.dataService
      .callv3API("setToOptForPaidHR", jsonRequest)
      .subscribe((res) => {
        console.log("res", res, res.success)
         
        this.dialogOne = false;
        this.dialogThree = true;
        this.gotoCreate = true;

       
      })
  }



  openKnowMoreLink() {
    let mobileScreen = false;
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      mobileScreen = true;
    } else {
      mobileScreen = false;
    }
    const videoLink = "https://youtu.be/2wXBqiZWjn0";
    let dialogRefer;
    if (mobileScreen) {
      dialogRefer = this.dialog.open(VideoPlayer, {
        width: "100%",
        minWidth: "100%",
        height: "100%",
        data: {
          videoLink: videoLink,
        },
        panelClass: "transparent-dialog_for_videPlayer",
      });
    } else {
      dialogRefer = this.dialog.open(VideoPlayer, {
        width: "36rem",
        height: "28rem",
        data: {
          videoLink: videoLink,
        },
        panelClass: "transparent-dialog_for_videPlayer",
      });
    }
    dialogRefer.afterClosed().subscribe(() => {
      
    });
  }

  showExtendedCard = false;
  requestHr(isPaid:boolean){
    const paidHelpDialog = this.dialog.open(ConfirmDialogPaidHelpComponent, {
      disableClose: true,
      panelClass: 'confirmPaidHelpDialog',
      width:'435px',
      maxHeight:'620px',
      data: {
        isPaid: isPaid
      }
    });
    let confirmDialogClass = 
    document.getElementsByClassName('confirmDialogBox1') as HTMLCollectionOf<HTMLElement>;
    for(let i=0; i<confirmDialogClass.length; i++){
      let div= confirmDialogClass[i];
      div.setAttribute("style","opacity:0");
    }
    paidHelpDialog.afterClosed().subscribe((result:any) =>{
      for(let i=0; i<confirmDialogClass.length; i++){
        let div= confirmDialogClass[i];
        div.setAttribute("style","opacity:1");
      }
      if(result){
        this.dialogRef.close(false);
      }
    });
  }

  requestPaidHelpOption(){
    const option = "y";
    this.setOptForPaidHr(option);
    this.dialogRef.close(false);
    localStorage.setItem('fromConfirmation','true');
    this.router.navigate(["./help-request/create-request"]);
    // this.router.navigateByUrl("/help/survey");
    // adding event ja_popup_paid_hr
    // this.googleAnalyticsService.eventEmitter(
    //   "ja_popup_paid_hr",
    //   "",
    //   "User selected 'Request paid help' option"
    // );
    this.googleAnalyticsService.eventEmitter('hr_init_sur_pu',"survey pop-up","HR init");
    (window as any).hj('event', 'hr_init_sur_pu');
  }

  requestFreeHelpOption(){
    const isPaid= false;
    this.requestHr(isPaid);
    const option = "n";
    this.setOptForPaidHr(option);
    // adding event ja_init_unpaid_hr
    // this.googleAnalyticsService.eventEmitter(
    //   "ja_init_unpaid_hr",
    //   "",
    //   "User selected 'click here' option for unpaid help"
    // );
    this.googleAnalyticsService.eventEmitter2('fr_init_sur_pu',{});
    (window as any).hj('event', 'fr_init_sur_pu');
  }


  closeOption(){
    // closing the dialog
    this.dialogRef.close(true);
    // sending api for no option..
    const option = "n";
    this.setOptForPaidHr(option);
    // adding event ja_popup_close
    // this.googleAnalyticsService.eventEmitter(
    //   "ja_popup_close",
    //   "",
    //   "User closed the popup"
    // );
    this.googleAnalyticsService.eventEmitter('exit_sur_pu',"survey pop-up","exit");
    (window as any).hj('event', 'exit_sur_pu');
  }

  setOptForPaidHr(option:'n'|'y'){
    this.getSpecificHelpRequestService.getRequestData().subscribe((res) => {
      if (res) {
        this.helpid = res.helpid;
        this.phone_no = localStorage.getItem("phone_no");
      }
      let jsonRequest = {
        "helpid": this.helpid,
        "phone_no": this.phone_no,
        "opt": option
      }
      this.dataService
        .callv3API("setToOptForPaidHR", jsonRequest)
        .subscribe((res) => {
          console.log("res", res, res.success)
           
          this.dialogOne = false;
          this.dialogThree = true;
          this.gotoCreate = true;
  
         
        })
    });
    
  }

  optedYes(){
    // adding event ja_popup_yes
    // this.googleAnalyticsService.eventEmitter(
    //   "ja_popup_yes",
    //   "",
    //   "User selected 'Yes' option in the popup for 'did our suggestion solved your problem'"
    // );
    this.googleAnalyticsService.eventEmitter('survey_pu_yes',"survey pop-up","yes");
    (window as any).hj('event', 'survey_pu_yes');
    // hitting APi for option yes
    const option = 'y';
    this.setIsSuggestionSolvedProblem(option);
  }

  optedNo(){
    // adding event ja_popup_no
    // this.googleAnalyticsService.eventEmitter(
    //   "ja_popup_no",
    //   "",
    //   "User selected 'No' option in the popup for 'did our suggestion solved your problem'"
    // );
    this.googleAnalyticsService.eventEmitter('survey_pu_no',"survey pop-up","no");
    (window as any).hj('event', 'survey_pu_no');
    // hitting APi for option no
    const option = 'n';
    this.setIsSuggestionSolvedProblem(option);
  }

  setIsSuggestionSolvedProblem(option:"y"|"n"){

    this.getSpecificHelpRequestService.getRequestData().subscribe((res) => {
      if (res) {
        this.helpid = res.helpid;
        this.phone_no = localStorage.getItem("phone_no");
      }
      let jsonRequest = {
        "helpid": this.helpid,
        "phone_no": this.phone_no,
        "is_solved_problem": option
      }
      this.dataService
        .callv3API("isSuggestionSolvedProblem", jsonRequest)
        .subscribe((res) => {
          
        })
    });
  }

  limitTime = 5*60000;
  remainingMinute = 5;
  remainingSeconds = 0;
  remainingTime = '05:00';
  setCountDown=()=>{
    let timer:any = localStorage.getItem('paid_help_creation_time');
    if(timer){
      const timerPlusLimit = new Date(+timer + this.limitTime).getTime();
      const currentTime = new Date().getTime();
      // timer is not exceeded
      if(timerPlusLimit> currentTime ){
        this.remainingMinute = Math.floor((timerPlusLimit - currentTime)/60000);
        this.remainingSeconds =  Math.round((timerPlusLimit - currentTime)/1000) - 60*this.remainingMinute;
        console.log('remaining time',this.remainingMinute,this.remainingSeconds);
        let countDown = setInterval(()=>{
          if(this.remainingMinute == 0 && this.remainingSeconds == 0){
            clearInterval(countDown);
            return;
          }
          if(this.remainingSeconds == 0){
            this.remainingMinute = this.remainingMinute - 1;
            this.remainingSeconds = 59;
          }
          else{
            this.remainingSeconds = this.remainingSeconds - 1;
          }
          const minutes = '0'+this.remainingMinute
          const seconds = (this.remainingSeconds.toString())
                            .length==1?'0'+this.remainingSeconds:this.remainingSeconds;
          this.remainingTime= minutes+':'+seconds;
        },1000)
      }
      else{
        return '00:00'
      }
    }
  }

  profileRoute=()=>{
    this.dialogRef.close(true);
    this.router.navigate(["user-profile"], {
      queryParams: { profile_type: 'pvt' },
    });
  }

  acceptOffer=()=>{
    this.dialogRef.close(false);
    this.router.navigate(["help-request/getHelp-RequestbyId"], {
      queryParams: { Id: this.helpRequestId,acceptOffer:true },
      queryParamsHandling: 'merge'
    });
    this.googleAnalyticsService.eventEmitter('offer_accepted_E',"exit popup","accept offer from exit popup");
    (window as any).hj('event', 'offer_accepted_E');
  }

  setExitConfirm1=()=>{
    // google and hotjar events
    if(this.assignedEmitra){
      this.googleAnalyticsService.eventEmitter('Exit_offer_page',"exit popup","exit expanded view before 5 min with offer");
      (window as any).hj('event', 'Exit_offer_page');
    }
    else{
      this.googleAnalyticsService.eventEmitter('Exit_wait_page',"exit popup","exit expanded view before 5 min with no offer");
      (window as any).hj('event', 'Exit_wait_page');
    }
    this.exitConfirm = true;
    if(this.nextState.includes('home')){
      const hasEmitra=  this.assignedEmitra?'assigned':'notAssigned';
      localStorage.setItem('homePopupFromRouteGuard',hasEmitra);
    }
    else{
      const hasEmitra=  this.assignedEmitra?'assigned':'notAssigned';
      localStorage.setItem('profilePopupFromRouteGuard',hasEmitra);
    }
    this.dialogRef.close(true);
  }
}