
<div class="opacity_overlay" *ngIf="onLoginRequestInProcess">
  <mat-progress-spinner *ngIf="onLoginRequestInProcess" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
  </mat-progress-spinner>
</div>
<div id="errorMsg" class="error"></div>
<div [hidden]="showModal" style="position:relative;height:100%">
  <div [hidden]="selectedscreen != 'signuplogin'">
    <div class="modal-header">
      <button (click)="closeModal()" type="button" class="close" data-dismiss="modal">&times;</button>
    </div>
    <div class="modal-body">
      <div class="top_part">
        <div style="text-align: left;"><img src="../../../assets/images/quotes_start.svg" alt="Image"></div>
        <div *ngIf="importData && importData.quote">{{importData.quote | translate}}</div>
        <div *ngIf="!importData || !importData.quote">{{'leaderboard_quote' | translate}}</div>
        <div style="text-align: right;"><img src="../../../assets/images/quotes_end.svg" alt="Image"></div>
      </div>
      <img class="jaanoLogo" src="../../assets/images/loginlogo.svg" alt="Image" />
      <div class="bottom_part">
        <span *ngIf="importData && importData.text">{{importData.text | translate}}</span>
        <span *ngIf="!importData || !importData.text">{{'please_log_in' | translate}}</span>
        <div style="margin-top: 2rem;">
          <div (click)="pagechange('ifsignup')" style="background: #fff; color: #003DB3;" class="signin">
            {{'Signup' | translate}}</div>
          <div (click)="pagechange('iflogin')" class="signin">{{'login' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
  
  <div [hidden]="selectedscreen != 'ifsignup'" [ngClass] ="{'shake': shakeIt}">
    <div class="modal-header">
      <button (click)="closeModal()" type="button" class="close" data-dismiss="modal">&times;</button>
    </div>
    <div class="signupbody modal-body">
      <img style="height: 4rem" src="../../assets/images/loginlogo.svg" alt="Image" />
      <form #f="ngForm" [formGroup]="phoneForm" style="font-size: 1.4rem; position:relative" class="inputbox">
        <!-- <international-phone-number required formControlName="phone" placeholder="Mobile no." [maxlength]="20"
          [defaultCountry]="'in'" name="phone">
        </international-phone-number> -->
        <input formControlName="phone" class="append-input-box" type="number"
        [maxlength]="20" name="phone" style="border:none; padding-left:20px">
        <div class="append-text">+91</div>
      </form>
      <input class="inputbox"  placeholder="{{'enter_your_name' | translate}}" [(ngModel)]="nameString" style="padding: 1rem;"
      (ngModelChange)="setUsernameOption()">
      <div class="username_suggestion_box">
      <div class="text">{{'select_from_available'| translate}}:</div>
      <div class="col-sm-12 col-xs-12 no-padding patterns">
        <div class="col-sm-4 col-xs-4" *ngFor="let username_suggestion of username_suggestions" (click)="selectPattern(username_suggestion)" [ngClass]="selected_username_pattern == username_suggestion ? 'selected_ptn' : 'unselected_ptn'"> {{username_suggestion}}</div>
      </div>
      </div>
      <div class="text">{{'otp_sent_text' | translate}}</div>
      <div id="recaptcha_container_signup"></div>
      <mat-checkbox #signupCheckbox [checked]="true" class="text">{{'chk_ios' | translate}}<a target="_blank"
          [href]="swanitiUrl">{{'policy' | translate}}</a></mat-checkbox>
      <div style="text-align: -webkit-center;text-align: -moz-center;">
        <div (click)="sendOtpOnSignup()" class="continue" [style.padding]="onSignupRequestInProcess ? '0.5rem' : '0'">
          <input type="button" *ngIf="!onSignupRequestInProcess" [disabled]="checkActivityForContinueButtonOfSignup()" value="{{'continuelogin' | translate }}" />
          <span *ngIf="onSignupRequestInProcess" class="loader"></span>
        </div>
      </div>
    </div>
  </div>
  
  <div [hidden]="selectedscreen != 'iflogin'" [ngClass] ="{'shake': shakeIt}">
    <div class="modal-header">
      <button (click)="closeModal()" type="button" class="close" data-dismiss="modal">&times;</button>
    </div>
    <div class="signupbody modal-body">
      <img style="height: 10rem" src="../../assets/images/jaanoLogo.svg" alt="Image" />
      <div class="heading">{{'Please enter your mobile number'| translate}}</div>
      <form #f="ngForm" [formGroup]="phoneForm" style="font-size: 1.4rem;position:relative" 
      class="inputbox">
        <!-- <international-phone-number type="tel" required formControlName="phone" placeholder="Mobile no." [maxlength]="20" style="letter-spacing: 2px"
          [defaultCountry]="'in'" name="phone" disabled>
        </international-phone-number> -->
  
        <input formControlName="phone" class="append-input-box" type="number"
        [minlength]="10"
        [maxlength]="20" name="phone" style="border:none; padding-left:20px">
        <div class="append-text">+91</div>
      </form>
      <!-- <div class="text"> {{'otp_sent_text' | translate}} </div> -->
      <div id="recaptcha-container"></div>
      <mat-checkbox #loginCheckbox [checked]="true" class="text"
      style="display:none">{{'chk_ios' | translate}}<a target="_blank"
          [href]="swanitiUrl">{{'policy' | translate}}</a></mat-checkbox>
      <div style="text-align: -webkit-center;text-align: -moz-center;">
        <div (click)="sendOtpOnLoginNew()" class="continue" [style.padding]="onLoginRequestInProcess ? '0.5rem' : '0'">
          <input type="button" *ngIf="!onLoginRequestInProcess" [disabled]="checkActivityForContinueButtonOfLogin()" value="{{'Request OTP' | translate }}" />
          <span *ngIf="onLoginRequestInProcess" class="loader"></span>
        </div>
      </div>
      <span class="policy-info-1">
        {{'login_policy'|translate}}
      </span>
    </div>
    <div class="referal-wrapper mt-24" *ngIf="showReferralCode">
      <div class="row" style="width: 280px; margin: auto;">
        <div class="col content-center pr-0">
          <hr style="width:100%">
        </div>
        <div class="col-auto content-center" style="padding-left: 8px; padding-right: 8px;">
          {{'Optional'|translate}}
        </div>
        <div class="col content-center pl-0">
          <hr style="width:100%">
        </div>
      </div>
      <div class="row mt-24" style="width: 280px; margin: auto;">
        <div class="col-12 p-0">
          <input type="text"  class="referal-input" placeholder="{{'Enter referral code here'|translate}}" maxlength="6" [(ngModel)]="referralCode"
          [readonly]="disableEditReferral">
        </div>
      </div>
    </div>
  </div>
  
  <div [hidden]="selectedscreen != 'otpScreen'" [ngClass] ="{'shake': shakeIt}">
    <div class="modal-header">
      <button (click)="closeModal()" type="button" class="close" data-dismiss="modal">&times;</button>
    </div>
    <div class="signupbody modal-body">
      <img style="height: 10rem" src="../../assets/images/loginlogo.svg" alt="Image" />
      <div class="heading" style="margin-bottom: 1rem;">{{'otp_verify' | translate}}</div>
      <div class="row justify-content-center">
        <div class="col-sm-12 sm-pd0">
          <div class="justify-content-center">
            <input class="otp-input" [value]="phoneno" readonly
            style="width:260px">
          </div>
          <img src="../../../assets/images/edit-icon3.svg" width="18px"
          height="18px" class="edit cursor-pointer" (click)="editPhoneNo()" alt="edit" />
        </div>
      </div>
      <!-- <span class="inputbox inputboxdisabled" (click)="resendOtp()">{{phoneno}}</span> -->
      <!-- <span class="codespan">{{'otp_sent_verify' | translate}} {{last4digits}}</span> -->
      <div class="col-sm-12 sm-pd0">
        <div id="otpContainer">
          <input class="otp-input mb-4" placeholder="{{'Enter 6-digit OTP'| translate}}" type="number" pattern="[0-9]{6}"  style="letter-spacing: 1px;"
          [(ngModel)]="verificationCode"
          (input)="changeValue($event)" onKeyUp="if(this.value > 999999) this.value = this.value.slice(0,6)" max="999999">
        </div>
      </div>
      <div class="col-sm-12">
        <button [disabled]="invalidOtp()" 
        class="mb-4 continue addPadding" (click)="verifyCodeNew()"
        style="border:none;">
        {{'verify' | translate}}
      </button>
      </div>
  
      <div class="col-sm-12 col-xs-12 center">
        <button class="resendOTP" [disabled]="!ngIfOtpTimeout" 
        (click)="resendNewOtp()">{{'resendotp' | translate}}</button>
      </div>
      <div *ngIf="ngIfAfterOtpSent">
        <div class="col-sm-12 center ">
          <!-- <span>{{"Didn't receive OTP? Retry in" | translate}}</span>
          <span class="timer">&nbsp; {{timer}}</span> -->
          <span class="timer">{{timer}}</span>
        </div>
      </div>
    </div>
    <div style="margin-top: 2rem;"></div>
  </div>
</div>
<div [hidden]="!showModal">
  <div class="container-fluid" style="position:relative;height:501px" [ngStyle]="{'height':!fromAds?'386px':'501px'}">
    <div class="row" style="margin-top:14px;" *ngIf="!!fromAds">
      <div class="col-auto" *ngIf="!fromSolution">
        <button class="no-border-btn" (click)="closeModal2()" style="text-transform: uppercase;">
          <img src="../../../assets/images/back_arrow_gray.svg" alt="back-btn">
          {{'back'|translate}}
        </button>
      </div>
      <div class="col-auto ml-auto">
        <button class="no-border-btn" (click)="closeModal3()">
          <img src="../../../assets/images/cross-close-icon.svg" alt="back-btn">
        </button>
      </div>
    </div>
    <div class="row" *ngIf="!fromAds">
      <button class="back-btn-2" (click)="closeModal()">
        <img src="../../../assets/images/grey-back-btn.svg" alt="back-icon" width="24px" height="24px">
      </button>
      <div class="col-12 text-center login-header">
        {{'login_'|translate}}
      </div>
    </div>
    <div class="row login-card" style="position:absolute; top:28%; width:100%" 
      [hidden]="selectedscreen != 'iflogin'">
      <div class="col-12 text-center field-1">
        {{'login_to_proceed'|translate}}
      </div>
      <div class="col-12 text-center field-2" *ngIf="!!fromAds">
        {{'please_login_to_create_request'|translate}}
      </div>
      <div class="col-12 text-center field-2" *ngIf="!fromAds">
        {{'login_info_text'|translate}}
      </div>
      <div class="col-12 mt-24">
        <form #f="ngForm" [formGroup]="phoneForm" style="font-size: 1.4rem;position:relative" class="content-center">
    
          <input formControlName="phone" class="append-input-box" type="number"
          [minlength]="10"
          [maxlength]="20" name="phone" style="width: 100%; text-align: center;"
          placeholder="{{'enter_your_phone_no'|translate}}" [ngClass]="{'new-input':!fromAds}">
        </form>
      </div>
      <div class="col-12 text-center mt-24">
        <button class="login-btn" [disabled]="checkActivityForContinueButtonOfLogin()"
        (click)="sendOtpOnLoginNew()" [ngClass]="{'new-request-otp':!fromAds}">
          {{'Request OTP' | translate }}
        </button>
      </div>
      <div class="col-12 text-center field-2 mt-8" *ngIf="!!fromAds">
        {{'login_policy'|translate}}
      </div>
    </div>
    <div class="row  login-card" style="position:absolute; top:28%; width:100%"  
    [hidden]="selectedscreen != 'otpScreen'" [ngClass]="{'content-center':!fromAds}">
      <div class="col-12 text-center field-1">
        {{'enter_otp'|translate}}
      </div>
      <div class="col-12 text-center field-2" *ngIf="!!fromAds">
        {{'please_enter_otp'|translate}}
      </div>
      <div class="col-12 text-center field-2" *ngIf="!fromAds">
        {{'otp_info_text'|translate}}
      </div>
      <div class="mt-24" style="position:relative"  [ngClass]="{'content-center col-auto':!fromAds,'col-12':!!fromAds}">
        <input [value]="phoneno" readonly style="color: rgba(50, 50, 51, 0.3);" [ngClass]="{'new-input':!fromAds}">
        <img src="../../../assets/images/edit-icon3.svg" width="18px"
        height="18px" class="edit-2 cursor-pointer" (click)="editPhoneNo()" alt="edit" />
      </div>
      <div class="col-12 mt-24"  [ngClass]="{'content-center':!fromAds}">
        <input placeholder="{{'Enter 6-digit OTP'| translate}}" type="number" pattern="[0-9]{6}"  
        (input)="changeValue($event)"   [ngClass]="{'new-input':!fromAds}"
        [(ngModel)]="verificationCode"
        onKeyUp="if(this.value > 999999) this.value = this.value.slice(0,6)" max="999999">
      </div>
      <div class="col-12 text-center mt-24">
        <button class="login-btn"[disabled]="invalidOtp()"(click)="verifyCodeNew()"  [ngClass]="{'new-request-otp':!fromAds}" >
          {{'verify' | translate}}
        </button>
      </div>
      <div class="col-12 text-center mt-24">
        <button class="no-border-btn resend-otp-1" [disabled]="!ngIfOtpTimeout" (click)="resendNewOtp()">
          {{'resendotp' | translate}}
        </button>
      </div>
      <div class="col-12 text-center timer-text">
        {{timer}}
      </div>
    </div>
    <div class="row footer-card justify-content-between" *ngIf="fromAds">
      <div class="col-auto p-0">
        <div class="img-circle">
          <img src="../../../assets/images/refund-icon.svg" alt="refund-icon">
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-12 text-center field-1">
            {{'we_offer_complete_refund'|translate}}
          </div>
          <div class="col-12 text-center field-2 p-0">
            {{'if_not_satisfied'|translate}}
          </div>
        </div>
      </div>
      <div class="col-auto p-0">
        <div class="img-circle">
          <img src="../../../assets/images/refund-icon.svg" alt="refund-icon">
        </div>
      </div>
    </div>
    <div class="row" style="position: absolute; bottom: 4px; right: 20px;" *ngIf="fromAds">
      <div class="col-12 field-3">
        {{'term_condition_apply'|translate}}
      </div>
    </div>
  </div>
</div>

<!-- <div class="maincontainer">
  <div class="container-fluid">
    <div class="row" style="position:absolute; top:8px; left:8px;z-index: 99999;">
      <div class="col-auto">
        <button class="back-btn" (click)="closeModal()">
          <img src="../../../assets/images/back_arrow.svg" alt="left-arrow">
        </button>
      </div>
    </div>
    <div class="row select-lang-top">
        <div class="col-12 text-center">
            <img src="../../../assets/images/jaanoLogo.svg" alt="jaanoLogo" width="120px"
            height="120px;">
        </div>
        <div class="col-12 mt-16 text-center">
            <span class="text-1 sh1 w-500" *ngIf="selectedscreen == 'iflogin'">
                {{'enter_phone_no'|translate}}
            </span>
            <span class="text-1 sh1 w-500" *ngIf="selectedscreen == 'otpScreen'">
                {{'enter_phone_no'|translate}}
            </span>
        </div>
    </div>
  </div>
  <div class="container-fluid select-lang-bottom">
    <div class="row select-lang-bottom">
      <div class="col-12">
        <div class="signupbody modal-body">
          <form #f="ngForm" [formGroup]="phoneForm" style="font-size: 1.4rem;position:relative" 
          class="inputbox-3">
      
            <input formControlName="phone" class="append-input-box" type="number"
            [minlength]="10" placeholder="Enter your phone number here"
            [maxlength]="20" name="phone" style="border:none; padding-left:20px">
            <div class="append-text">+91</div>
          </form>
          <div id="recaptcha-container"></div>
          <mat-checkbox #loginCheckbox [checked]="true" class="text"
          style="display:none">{{'chk_ios' | translate}}<a target="_blank"
              [href]="swanitiUrl">{{'policy' | translate}}</a></mat-checkbox>
          <div style="text-align: -webkit-center;text-align: -moz-center;">
            <div (click)="sendOtpOnLogin()" class="continue-2" [style.padding]="onLoginRequestInProcess ? '0.5rem' : '0'">
              <input type="button" *ngIf="!onLoginRequestInProcess" [disabled]="checkActivityForContinueButtonOfLogin()" value="{{'Request OTP' | translate }}" />
              <span *ngIf="onLoginRequestInProcess" class="loader"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-8 content-center">
        <div class="policy-label">
          {{'login_policy'|translate}}
        </div>
      </div>
    </div>
  </div>
</div> -->