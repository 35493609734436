import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { StringcheckerService } from "../../services/StringcheckerService";
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'video_player_dialog',
    templateUrl: 'video_player_dialog.html',
    styleUrls: ["video_player_dialog.css"]
})
export class VideoPlayer {

    video_url ;
    isFacebookVideo = false;
    isTwitterVideo = false;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, public dialogRef: MatDialogRef<VideoPlayer>, 
    private checkString:StringcheckerService,
    private sanitizer: DomSanitizer,
    private googleAnalyticsService: GoogleAnalyticsService,
    private commonService: CommonService) {
      
      const userName = this.commonService.getUsername();
      const eventName = "Youtube Video";
      const tags = {
        url: this.data.videoLink,
        userName: userName,
        timeStamp: new Date().getTime()
      }
        // check for youtube videos and firebase/s3 videos
      if (this.data.videoLink != null && this.data.videoLink !== 'null') {
        if (this.checkString.isYoutubeURL(this.data.videoLink)) {
          if (this.data.videoLink.indexOf('https://youtu.be/') !== -1) {
           this.video_url = this.data.videoLink.replace('https://youtu.be/', 'https://youtube.com/embed/');
           this.video_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
          } else if (this.data.videoLink.indexOf('https://www.youtube.com/watch?v=') !== -1) {
            this.video_url = this.data.videoLink.replace('&feature=youtu.be', '');
            this.video_url = this.video_url.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
            this.video_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
          }

          if(this.data.videoLink){
            this.googleAnalyticsService.eventEmitter2(eventName, tags);
          }
        }
        else if (this.checkString.isFacebookVideoURL(this.data.videoLink)) {
          this.video_url = "https://www.facebook.com/plugins/video.php?href=" + encodeURIComponent(this.data.videoLink); +"&show_text=0&width=560"
          this.isFacebookVideo = true;
        }
        else if (this.checkString.isTwitterVideoURL(this.data.videoLink)) {
          this.isTwitterVideo = true;
          this.video_url  = this.data.videoLink
          // https://video.twimg.com/ext_tw_video/567972074346807296/pu/vid/480x480/eU1s1ig_skHgeRjB.mp4
        }
        else {
         this.video_url = this.data.videoLink;
         this.video_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url);
        }
      }
    }

    discard() {
        this.dialogRef.close(false);
    }
}
