import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
  ElementRef,
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute, Params } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import {
  Subject,
  Observable,
  Observer,
  Subscription,
  of as observableOf,
} from "rxjs";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CookieService } from "ngx-cookie";

import { LanguageTranslationService } from "../../../services/language-translation-service";
import { ShowToastyService } from "../../../services/showToastyService";
import { DataService } from "../../../services/data.service";
import { CommonService } from "../../../services/common.service";
import { StringcheckerService } from "../../../services/StringcheckerService";
import { UploadService } from "../../../services/uploadService";
import { GoogleAnalyticsService } from "../../../services/google-analytics.service";
import { LoginDialogService } from "../../../services/loginService";
import { HelpRequestCategoryServiceService } from "../../../services/help-request-category-service.service";
import { PreviousRouteService } from "../../../services/previous-route.service";
import { MoengageEventsService } from "../../../services/moengage_events_service";
import { LoclangDialogsService } from "../../../services/loclang-dialogs.service";
import { isPlatformBrowser } from "@angular/common";
import { VideoPlayer } from "../../../shared-components/video_player_dialog/video_player_dialog";
import { AuthService } from "../../../services/auth.service";
import RecordRTC from "recordrtc";
import { Options } from "html2canvas";
import { OtpDialog, SelectCategoryDialog } from "../../expanded-view/expanded-view.component";
import { PopularService } from "src/app/models/models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
declare var require: any;
// const MicRecorder = require('mic-recorder').default;
// const MicRecorder = require('mic-recorder-to-mp3');
// declare var hj;

@Component({
  selector: "app-create-help-request",
  templateUrl: "./create-help-request.component.html",
  styleUrls: ["./create-help-request.component.scss"],
})
export class CreateHelpRequestComponent implements OnInit, OnDestroy {
  @ViewChild("closeImagebutton") closeImagebutton:any;
  @ViewChild("closeVideobutton") closeVideobutton:any;
  @ViewChild("openImageModal") openImageModal:any;
  @ViewChild("help_category") helpCategoy : ElementRef|any;
  @ViewChild("help_sub_category") helpSubCategoy : ElementRef|any;
  @ViewChild("help_type") helpType_ : ElementRef|any;
  @ViewChild("help_title") helpTitle : ElementRef|any;
  @ViewChild("help_description") helpDescriptionView : ElementRef|any;
  @ViewChild("closeAudioModal") closeAudioModal:any;
  @ViewChild("confirmationButton") confirmationButton:any;
  @ViewChild("mediaAudioBtn") mediaAudioBtn:any;
  @ViewChild("mediaVideoBtn") mediaVideoBtn:any;
  @ViewChild("mediaImageBtn") mediaImageBtn:any;
  subCategoryValue:any;
  emptyCat: boolean = false;
  emptySubCat: boolean = false;
  emptyTitle: boolean = false;
  emptyDescription: boolean = false;
  emptyHrType: boolean = false;
  disableBtn: boolean = false;
  color = "primary";
  mode = "indeterminate";
  value = 50;
  IsLoader: boolean = false;
  @ViewChild("video1") video: any;
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = true;
  public deviceId: string="";
  public videoOptions: MediaTrackConstraints = {
    aspectRatio: 1,
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  // latest snapshot
  public webcamImage: WebcamImage|any = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  selected_type = 0;
  selectedImageThumbnail:any = [];
  selectedDocumentThumbnail: any;
  selectHelpVoice: any;
  size: number=0;
  selectedFileType = "video/*";
  selectedVideoFiles: any;
  selectAudioFile: any;
  videosThumbnile: any;
  select_radio = 0;
  selectedLanguage:any;
  lat: any;
  lng: any;
  description = "";
  review_news_link = "";
  news_snippet = null;
  selected_exp = 0;
  name:any;
  showScreen = 1; //Default screen was 0
  selectedOption:any;
  selectSubType = 0;
  isRecording = false;
  imageClickOption = false;
  imageSelect = false;
  videoSelected = false;
  openRecordVideo = false;
  videoUpload = false;
  videoRecord = false;
  audioBlobURL:any;
  tempAudioBlob:any;
  tempSelectedAudio:any;
  helpType:any;
  helpDescription:any;
  imageUploaded:any = [];
  private stream: MediaStream=new MediaStream;
  private recordRTC: any;
  userLatLng = {
    lat: "",
    lng: "",
  };
  stcode11: any;
  dtcode11: any;
  stname: any;
  dtname: any;
  storage:any;
  dialogRef:any;
  helpImage:any;
  helpVideo:any;
  helpAudio:any;
  base64TrimmedURL: string="";
  base64DefaultURL: string="";
  generatedImage: string="";
  windowOPen: boolean=false;
  selectVideoUrl: any;
  imageOption = false;
  videoOption = false;
  voiceOption = false;
  imageOptionSelected = false;
  videoOptionSelected = false;
  voiceOptionSelected = false;
  descriptionOptionSelected = false;
  imageSelected = false;
  ImageFiles:any = [];
  VideoFiles:any = [];
  playStoreLink = "";
  subscriptions: Subscription[] = [];
  private VIDEODURATION = "30"; // in seconds
  private AUDIODURATION = 60; //in seconds
  isDisable: boolean=false;
  videoTimeIssue: boolean=false;
  showVideo: boolean = false;
  displayImageModal= "none";
  categoryList:any= [];
  subTypeCat:any;
  helpRequestId: any;
  commentToShow:any;
  requestDetails:any;
  requestImages:any = [];
  requestVideo:any;
  previousUrl: string="";
  isRecordingVideo: boolean = false;
  intervalId:any;
  audioRecorderProgress = 0;
  audioRecorderTimer = 0;
  dialogRefer:any;
  countLoginDialogFre = 0;
  general = true;
  selectCategoryArray:any = [];
  pageFrom: boolean = false;
  paidTypeToggle: boolean = false;
  showPaidTypeToggleNo: boolean = true;
  fromHome= false;
  popularService = false;
  jaInitHr=false;
  referralCode = "";
  newCreateRequestFlow = true;
  username="";
  userPhoneNo="";

  requireDetailForm:FormGroup = new FormGroup({
    serviceName:new FormControl('',[Validators.required]),
    describe:new FormControl('',[Validators.required])
  })
  get serviceName(){
    return this.requireDetailForm.get('serviceName');
  }
  get describe(){
    return this.requireDetailForm.get('describe');
  }
  userDetailForm: FormGroup = new FormGroup({
      userName: new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z ?]{1,100}")]),
      mobileNo: new FormControl('',[Validators.required,Validators.pattern("^[0-9]{10,10}")]),
      location: new FormControl('',[Validators.required])
  });
  get userName(){
    return this.userDetailForm.get('userName');
  }
  get mobileNo(){
    return this.userDetailForm.get('mobileNo');
  }
  get location(){
    return this.userDetailForm.get('location');
  }

  constructor(
    private languageService: LanguageTranslationService,
    private helpRequestService: HelpRequestCategoryServiceService,
    private toastyService: ShowToastyService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private commonService: CommonService,
    private loginService: LoginDialogService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private uploadService: UploadService,
    private checkString: StringcheckerService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private previousRouteService: PreviousRouteService,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private loclatdialog: LoclangDialogsService,
    private cdf: ChangeDetectorRef,
    private moeService: MoengageEventsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private el: ElementRef,
    private authService:AuthService
  ) {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params["cat"]) {
        this.general = false;
        this.selectCategoryOption(params["cat"], params["sub_cat"]);
      }
      if(params["home"]){
        this.fromHome= true;
      }
      if(params["popularService"]){
        this.popularService= true;
      }
      if(params["referralCode"]){
        this.referralCode = params["referralCode"];
      }
    });
    this.subscriptions.push(
      this.languageService.getSelectedLanguage().subscribe((value) => {
        if(!value && isPlatformBrowser(this.platformId)){
          this.languageService.setSelectedLanguage('en');
          this.selectedLanguage = 'en';

          // this.loclatdialog.openChangeLanguageDialog(() => {
            
          // });
        }
        else{
          this.selectedLanguage = value;
        }
        this.helpRequestService.getHelpRequestCategoriesList(
          this.selectedLanguage
        );

        if(value && this.newCreateRequestFlow){

        this.commonService.selectedLocation.subscribe((value) => {
          console.log('value from location',value);
          if (
            value &&
            Object.keys(value).length > 0 &&
            (Number(localStorage.getItem("admin_defined_location")) > 0 ||
              Number(localStorage.getItem("admin_defined_location")) < 0)
          ) {
            this.stcode11 = localStorage.getItem("stcode11");
            this.dtcode11 = localStorage.getItem("dtcode11");
            this.stname = localStorage.getItem("stname");
            this.dtname = localStorage.getItem("dtname");
            this.userDetailForm.patchValue({location:this.dtname});
            this.userDetailForm.controls['location'].markAsTouched();
            this.userLatLng.lat = value.lat();
            this.userLatLng.lng = value.lng();
            let dialogRef = this.dialog.open(SelectCategoryDialog, {
              width: '600px',
              height: '540px',
              panelClass:'custom-panel-class3',
              disableClose: true,
              data:{referralCode:this.referralCode}
            });
            dialogRef.afterClosed().subscribe(response=>{
              if(response && response.popularServices){
                this.popularServices = response.popularServices;
              }
            })
          }
        });
          // getting user details
          this.username = this.commonService.getUsername();
          let mobileNo:any = localStorage.getItem('phone_no');
          // this.getLocation();
          if(this.username){
            // remove +91 for validation purpose...
            mobileNo = mobileNo.length>10 && mobileNo.includes('+91')?mobileNo.split("+91")[1]:mobileNo;
            this.userDetailForm.patchValue({mobileNo:mobileNo});
          }
        }
        let subscriptions = this.helpRequestService.listObservable.subscribe(
          (data) => {
            this.categoryList = data;
            let categorylist1 = this.categoryList.filter((_category:any)=> _category.cat_key!= 'other');
            let categorylist2 = this.categoryList.filter((category:any) => category.cat_key == 'other');
            this.categoryList = [...categorylist1, ...categorylist2];
            this.getSelectedGeneralCatArray();
            subscriptions.unsubscribe();
          }
        );
      })
    );

    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.helpRequestId = params["Id"];
        if (this.helpRequestId != undefined && this.helpRequestId != null) {
          this.general = false;
          this.showScreen = 1;
        } else if (params["cat"] && params["sub_cat"]) {
          this.selected_type = 4;
        }

        const paidType = params["paidType"];
        if(paidType == "true"){
          this.hrTypeSelected = true;
          this.isPaidHelpRequest = true;
          this.paidTypeToggle = true;
          this.showPaidTypeToggleNo = false;
          (window as any).hj('event', 'PaidHR_Init_Home');
        }
        else{
          (window as any).hj('event', 'UnpaidHR_Init_Home');
        }
        // restrict to apply only once per request
        if(!this.jaInitHr){
          this.googleAnalyticsService.eventEmitter(
            "ja_init_hr",
            "",
            "Help Request Initialized"
          );
          this.jaInitHr = true;
        }

        // else {
        //   if (!params.cat) {
        //     this.showScreen = 0;
        //   }
        // }
      })
    );
    this.loginService.userLoggedInStatus().subscribe((message) => {
      if (message) {
        this.name = this.commonService.getUsername();
      }
    });

    this.playStoreLink = this.commonService.getDynamicAppDownloadLink();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.setPageView({
          page_title: "Create Help-request Web",
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
  testingCall() {
    // console.log("Testing calling");
    // console.log("selectCategoryOption si invoking");
    // this.selectedOption = undefined;
    // this.general = false;
    // this.selectCategoryOption(15, 0);
    let cat_id:any = this.activatedRoute.snapshot.queryParamMap.get("cat_id");
    if (cat_id != null) {
      const element:any = document.getElementById("moreSelect");
      element.click();
      setTimeout(() => {
        const catId:any =document.getElementById(cat_id);
        catId.click();
      }, 200);
    }
  }

  // recorder = new MicRecorder({
  //   bitRate: 128,
  // });
  // recorder = new MicRecorder({
  //   bitRate: 128,
  //   encoder: 'mp3', // default is mp3, can be wav as well
  //   sampleRate: 44100, // default is 44100, it can also be set to 16000 and 8000.
  // });

  progress = 0;
  timer = 0;
  interval:any;
  progressBar(style:any) {
    // dyanmic progress bar
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(style));
    this.el.nativeElement.appendChild(styleElement);
  }

  startAudioRecording() {
    if (!this.isRecording) {
      // creating progress bar
      this.timer = 0;
      this.interval = setInterval(() => {
        if(this.progress <= 100) {
          if(this.timer < 60){
            this.timer = this.timer+1;
          }
          this.progress = (this.timer/60)*100;
          let style:string  = `.dyanmicProgress{background: conic-gradient(#304FFE ${this.progress}%, #f2f2f4 ${this.progress}%)}`;
          this.progressBar(style);
        } else {
          clearInterval(this.interval);
        }
      },1000);
      this.isRecording = true;



      // recording using recordrtc

      let mediaConstraints = {
        video: false,
        audio: true
      };
      navigator.mediaDevices.getUserMedia(mediaConstraints).
      then(this.successCallback2.bind(this), this.errorCallback2.bind(this));
      // this.recorder
      //   .start()
      //   .then(() => {
      //     const getDownloadProgress = () => {
      //       if (this.audioRecorderProgress < 100) {
      //         this.audioRecorderTimer += 1;
      //         this.audioRecorderProgress =
      //           this.audioRecorderProgress + (1 / this.AUDIODURATION) * 100;
      //       } else {
      //         this.stopAudioRecording();
      //       }
      //     };
      //     this.intervalId = setInterval(getDownloadProgress, 1000);
      //   })
      //   .catch((e:any) => {
      //     // console.error(e);
      //     this.isRecording = false;
      //   });
    }
  }


  audioRecord:any;
  successCallback2(stream:any) {
    let options:RecordRTC.Options = {
      type: 'audio',
      mimeType: "audio/wav",
      // numberOfAudioChannels: 1,
      // sampleRate: 16000,
      desiredSampRate: 16000,
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.audioRecord = new StereoAudioRecorder(stream, options);
    this.audioRecord.record();
    const getDownloadProgress = () => {
      if (this.audioRecorderProgress < 100) {
        this.audioRecorderTimer += 1;
        this.audioRecorderProgress =
          this.audioRecorderProgress + (1 / this.AUDIODURATION) * 100;
      } else {
        this.stopAudioRecording();
      }
    };
    this.intervalId = setInterval(getDownloadProgress, 1000);
  }
  errorCallback2() {
    this.isRecording = false;
  }

  processRecording(blob:any) {
    const url = URL.createObjectURL(blob);
    this.tempAudioBlob = this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(blob)
    );
    this.tempSelectedAudio = blob;
    this.handleAudioSelect(blob);
  }

  stopAudioRecording() {
    if (this.isRecording) {
      clearInterval(this.intervalId);
      clearInterval(this.interval);
      this.audioRecorderProgress = 0;
      this.audioRecorderTimer = 0;
      this.isRecording = false;
      this.audioRecord.stop(this.processRecording.bind(this));
      // this.recorder
      //   .stop()
      //   .getMp3()
      //   .then(([buffer, blob]:any) => {
      //     this.tempAudioBlob = this.sanitizer.bypassSecurityTrustUrl(
      //       URL.createObjectURL(blob)
      //     );
      //     this.tempSelectedAudio = blob;
      //     this.handleAudioSelect(blob);
      //     // const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.mp3');
      //     // const file = new File(buffer, mp3Name, {
      //     //   type: blob.type,
      //     //   lastModified: Date.now()
      //     // });
      //     // const li = document.createElement('li');
      //     // const player = new Audio(URL.createObjectURL(file));
      //     // player.controls = true;
      //     // li.appendChild(player);
      //     // document.querySelector('#playlist').appendChild(li);
      //     // console.log("stop recording");
      //   })
      //   .catch((e:any) => {
      //     // console.error(e);
      //     this.isRecording = false;
      //   });
    }
  }

  setTitleOfProgressBar() {
    return this.audioRecorderTimer + " Sec";
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
    }
  }

  saveAudio() {
    this.closeAudioModal.nativeElement.click();
    this.audioBlobURL = this.tempAudioBlob;
    this.selectHelpVoice = this.tempSelectedAudio;
    this.voiceOption = true;
    this.voiceOptionSelected = true;
  }

  clearRecordedData() {
    this.voiceOption = false;
    this.voiceOptionSelected = false;
    this.audioBlobURL = null;
    this.tempAudioBlob = null;
    this.tempSelectedAudio = null;
  }

  isHelpRequestCreated = false;
  ngOnDestroy(): void {
    this.abortRecording();
    this.subscriptions.forEach((s) => s.unsubscribe());
    clearTimeout(this.intervalId);
    if(!this.isHelpRequestCreated){
      // console.log('inside hj 3');
      // (window as any).hj('event', 'Exit_CreateHR_page');
    }

    // clearing modal popup => in some cases when user click browser back button or any other way to go to previous without closing modal...
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      document.getElementsByClassName('modal-backdrop')[0].remove();
    }
  }

  ngOnInit() {
    let style:string  = `.dyanmicProgress{background: conic-gradient(#304FFE ${this.progress}%, #f2f2f4 ${this.progress}%)}`;
    this.progressBar(style);
    // replacing code from constructor to ngOnInit---
    // this.subscriptions.push(
      // this.commonService.selectedLocation.subscribe((value) => {
      //   if (
      //     value &&
      //     Object.keys(value).length > 0 &&
      //     (Number(localStorage.getItem("admin_defined_location")) > 0 ||
      //       Number(localStorage.getItem("admin_defined_location")) < 0)
      //   ) {
      //     this.stcode11 = localStorage.getItem("stcode11");
      //     this.dtcode11 = localStorage.getItem("dtcode11");
      //     this.stname = localStorage.getItem("stname");
      //     this.dtname = localStorage.getItem("dtname");

      //     if (this.dialogRefer) {
      //       this.dialogRefer.close("immediate");
      //       this.dialogRefer = null;
      //     }
      //     this.userLatLng.lat = value.lat();
      //     this.userLatLng.lng = value.lng();
      //     if (Number(localStorage.getItem("admin_defined_location")) == 1)
      //       localStorage.setItem("admin_defined_location", "0");
      //   } else {
      //     if (isPlatformBrowser(this.platformId)) {
      //       localStorage.setItem("admin_defined_location", "-1");
      //       this.loclatdialog.openChangeLocationDialog((res:any) => {});
      //     }
      //   }
      // })
    // );

    // end ----
    // if (!localStorage.getItem("foo")) {
    //   console.log("reloading.....")
    //   localStorage.setItem("foo", "no reload");
    //   location.reload();
    // } else {
    //   localStorage.removeItem("foo");
    // }

    this.languageService.startLanguageTranslator();
    this.name = localStorage.getItem("username");

    this.pageFrom =
      this.activatedRoute.snapshot.queryParamMap.get("pageFrom") == "1"
        ? true
        : false;
    this.languageService.startLanguageTranslator();
    this.name = localStorage.getItem("username");

    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
    this.commentToShow = 20;
    if (this.helpRequestId != undefined && this.helpRequestId != null) {
      this.getRequestDetailbyId(this.helpRequestId, this.commentToShow);
    }

    // setting hr_title and hr_description
    const previousUrl = this.previousRouteService.getPreviousUrl();

    // if from google ads survey part 2
    if(previousUrl && previousUrl.includes('home') && previousUrl.includes('ctg') && previousUrl.includes('subcat')){
      this.fromGoogleAds2 = true;
      this.setCatSubcat();
    }

    const fromExpandedView = previousUrl?previousUrl.includes('/help-request/getHelp-RequestbyId'):false;
    // also check from confirmation popup
    const fromConfirmationPopup = localStorage.getItem('fromConfirmation')?true:false;
    if(fromConfirmationPopup){
      localStorage.removeItem('fromConfirmation');
    }
    if(this.isPaidHelpRequest && (fromExpandedView || fromConfirmationPopup)){
      if(!this.fromHome){
        this.helpType = localStorage.getItem("hr_title");
      }else{
        this.helpType = null;
      }
      this.helpDescription = localStorage.getItem("hr_description");
      this.setCatSubcat();
    }

    setTimeout(() => {
      this.presected();
    }, 300);
    
  }

  fromGoogleAds2 = false;

  // isDisabled(){
  //   if( this.helpType && this.helpDescription 
  //     && this.selectedOption && this.subTypeCat){
  //       return false;
  //     }
  //     return true;
  // }

  onChangeLocaton(){
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem("admin_defined_location", "-1");
        this.loclatdialog.openChangeLocationDialog((res:any) => {
          this.commonService.selectedLocation.subscribe(value=>{
            if (
              value &&
              Object.keys(value).length > 0 &&
              (Number(localStorage.getItem("admin_defined_location")) > 0 ||
                Number(localStorage.getItem("admin_defined_location")) < 0)
            ) {
              this.stcode11 = localStorage.getItem("stcode11");
              this.dtcode11 = localStorage.getItem("dtcode11");
              this.stname = localStorage.getItem("stname");
              this.dtname = localStorage.getItem("dtname");
              this.userDetailForm.patchValue({location:this.dtname});
              this.userDetailForm.controls['location'].markAsTouched();
              
              if (this.dialogRefer) {
                this.dialogRefer.close("immediate");
                this.dialogRefer = null;
              }
              this.userLatLng.lat = value.lat();
              this.userLatLng.lng = value.lng();
              if (Number(localStorage.getItem("admin_defined_location")) == 1)
                localStorage.setItem("admin_defined_location", "0");
            } 
          })
        });
      }
    } catch (error) {
      console.log('erro on fetch location',error);
    }
  }

  getLocation=async ()=>{
    return new Promise((resolve,reject)=>{
      try {
        this.commonService.selectedLocation.subscribe((value) => {
          if (
            value &&
            Object.keys(value).length > 0 &&
            (Number(localStorage.getItem("admin_defined_location")) > 0 ||
              Number(localStorage.getItem("admin_defined_location")) < 0)
          ) {
            this.stcode11 = localStorage.getItem("stcode11");
            this.dtcode11 = localStorage.getItem("dtcode11");
            this.stname = localStorage.getItem("stname");
            this.dtname = localStorage.getItem("dtname");
            this.userDetailForm.patchValue({location:this.dtname});
            this.userDetailForm.controls['location'].markAsTouched();
    
            if (this.dialogRefer) {
              this.dialogRefer.close("immediate");
              this.dialogRefer = null;
            }
            this.userLatLng.lat = value.lat();
            this.userLatLng.lng = value.lng();
            if (Number(localStorage.getItem("admin_defined_location")) == 1)
              localStorage.setItem("admin_defined_location", "0");
            resolve(null);
          } else {
            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem("admin_defined_location", "-1");
              this.loclatdialog.openChangeLocationDialog((res:any) => {
                resolve(null);
              });
            }
          }
        })    
      } catch (error) {
        reject(error);
      }
    })
    
  }

  getRequestDetailbyId(helprequestId:any, commentToShow:any) {
    this.IsLoader = true;
    this.requestDetails = {
      username: this.name,
      helpRequestId: helprequestId,
      commentsToShow: commentToShow,
      repliesToShow: 5,
      lat: this.lat,
      lng: this.lng,
    };
    this.subscriptions.push(
      this.dataService
        .callv3API("expand/getHelpRequestDetailsByID", this.requestDetails)
        .subscribe((res) => {
          this.processData(res.data);
          this.showScreen = 1;
          //this.meta.updateTag({ name: 'title', content: this.languageService.getTranslationOf('Help Request for') +this.requestTitle });

          this.IsLoader = false;
        })
    );
    this.IsLoader = false;
  }
  processData(result:any) {
    this.selectedOption = result.category;
    let subcategoryItem: any;
    subcategoryItem = this.categoryList.filter(
      (x:any) => x.cat_id == this.selectedOption
    );
    let subcategoryIds = subcategoryItem[0]?subcategoryItem[0].subcat_id.indexOf(
      result.sub_category
    ):null;
    this.subTypeCat = subcategoryIds;
    this.selectSubType = result.sub_category;
    if(!this.fromHome){
      this.helpType = result.help_title;
    }
    this.helpDescription = result.help_description;
    this.selected_type = result.help_type;
    if (
      this.helpDescription != "" &&
      this.helpDescription != null &&
      this.helpDescription != undefined
    ) {
      this.descriptionOptionSelected = true;
    }
    this.selectedImageThumbnail = [];
    this.requestImages = [];
    if (result.help_pictures != null) {
      if (result.help_pictures.length > 0) {
        result.help_pictures.forEach((element:any) => {
          this.selectedImageThumbnail.push(element);
          this.requestImages.push(element);
        });
        this.imageOption = true;
        this.imageOptionSelected = true;
      }
    }
    if (
      result.help_video != "" &&
      result.help_video != null &&
      result.help_video != undefined
    ) {
      this.requestVideo = result.help_video;
      this.videosThumbnile = result.help_video;
      this.videoOptionSelected = true;
      this.videoOption = true;
      this.showVideo = true;
    }
    // if(result.help_voice_link !="" && result.help_voice_link != null && result.help_voice_link != undefined){
    //   this.blobUrl = result.help_voice_link;
    //   this.voiceOptionSelected = true;
    //   this.voiceOption = true;
    // }

    // if (this.subTypeCat == e) {
    //   this.subTypeCat = -1;
    //   this.selectSubType = "0";
    // }
    // else {
    //   this.subTypeCat = e;
    //   this.selectSubType = subcategoryIds;
    // }
    //}
    this.actualFormData.selectedOption = this.selectedOption;
    this.actualFormData.helpType = this.helpType;
    this.actualFormData.helpDescription = this.helpDescription;
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    const element:any = document.getElementById("imageCaptureModalButton");
    element.click();
  }

  public toggleWebcam(): void {
    const element:any = document.getElementById("imageCaptureModalButton");
    element.click();
    this.showWebcam = !this.showWebcam;
    this.imageClickOption = !this.imageClickOption;
    this.imageOptionSelected = false;
  }
  public takeNewSnapShot(): void {
    this.webcamImage = null;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.showWebcam = !this.showWebcam;
    this.getImage(this.webcamImage.imageAsDataUrl);
  }

  getImage(imageUrl: string) {
    this.imageClickOption = false;
    this.imageSelect = false;
    this.IsLoader = true;
    this.subscriptions.push(
      this.getBase64ImageFromURL(imageUrl).subscribe((base64Data: string) => {
        this.base64TrimmedURL = base64Data;
        this.imageOption = true;
        this.dataURItoBlob(this.base64TrimmedURL).subscribe((blob: Blob) => {
          const imageBlob: Blob = blob;
          const imageName: string = this.generateName();
          const imageFile: File = new File([imageBlob], imageName, {
            type: "image/jpeg",
          });
          this.ImageFiles.push(imageFile);
          this.selectedImageThumbnail.push(this.base64DefaultURL);
          this.imageOption = true;
          this.imageOptionSelected = false;
          this.IsLoader = false;
        });
        this.IsLoader = false;
      })
    );
  }
  getBase64ImageFromURL(url: string): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }
  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    var canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx: CanvasRenderingContext2D |any= canvas.getContext("2d");
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    let dataURL: string = canvas.toDataURL("image/png");
    this.base64DefaultURL = dataURL;
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  /**Method that will create Blob and show in new window */
  createBlobImageFileAndShow(): void {}

  /* Method to convert Base64Data Url as Image Blob */
  dataURItoBlob(dataURI: string): Observable<Blob> {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "image/jpeg" });
      observer.next(blob);
      observer.complete();
    });
  }

  /**Method to Generate a Name for the Image */
  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = "";
    const possibleText: string =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + "." + text + ".jpeg";
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  openVideoModal() {}
  toggleControls() {
    let video: HTMLVideoElement = this.video.nativeElement;
    video.muted = !video.muted;
    video.controls = !video.controls;
    video.autoplay = !video.autoplay;
  }
  startVideoRecording() {
    let mediaConstraints = {
      allowCameraSwitch: true,
      video: {
        mandatory: {
          minWidth: 1280,
          minHeight: 720,
        },
      },
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(<any>mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
    this.isRecordingVideo = true;
  }
  errorCallback() {}
  successCallback(stream: MediaStream) {
    // var options:Options = {
    //   mimeType: "video/webm", // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
    //   audioBitsPerSecond: 128000,
    //   videoBitsPerSecond: 128000,
    //   bitsPerSecond: 128000, // if this line is provided, skip above two
    // };
    this.stream = stream;
    this.recordRTC = new RecordRTC(stream);
    this.recordRTC.startRecording();
    let video: HTMLVideoElement = this.video.nativeElement;
    video.srcObject = stream;
    this.toggleControls();
  }
  recordVideo() {
    this.openRecordVideo = true;
  }

  stopVideoRecording() {
    this.isRecordingVideo = false;
    //$("#videoRecordModal").modal("hide");
    const element:any = document.getElementById("videoRecordModalButton")
    element.click();
    let recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processVideo.bind(this));
    let stream = this.stream;
    stream.getAudioTracks().forEach((track) => track.stop());
    stream.getVideoTracks().forEach((track) => track.stop());
  }
  processVideo(audioVideoWebMURL:any) {
    let video: HTMLVideoElement|any = this.video.nativeElement;
    let recordRTC = this.recordRTC;
    video.src = video.srcObject = null;
    video.src = URL.createObjectURL(recordRTC.getBlob());
    this.toggleControls();
    var recordedBlob = recordRTC.getBlob();
    this.handleVideoSelect(recordedBlob);
    recordRTC.getDataURL(function () {});
  }

  handleFileSelect(evt:any) {
    this.closeImagebutton.nativeElement.click();
    var files = evt.target.files; // FileList object

    // Loop through the FileList and render image files as thumbnails.
    for (var i = 0, f; (f = files[i]); i++) {
      if (!f.type.match("image.*")) {
        continue;
      }
      this.ImageFiles.push(files[i]);

      var reader = new FileReader();

      // Closure to capture the file information.
      reader.onload = ((theFile) => {
        return (e:any) => {
          // Render thumbnail.
          // var strImage = e.target.result.replace(/^data:image\/[a-z]+;base64,/, "" );
          var strImage = e.target.result;
          this.selectedImageThumbnail.push(strImage);
          // this.selectedImages.push(strImage);
        };
      })(f);
      reader.readAsDataURL(f);
      // this.imageSelect = !this.imageSelect;
      this.imageOption = true;
      this.imageOptionSelected = false;
    }
  }

  sendGAEvent() {
    this.googleAnalyticsService.eventEmitter(
      "web_navigate_to_playstore",
      "",
      "User navigate to play-store to download the app"
    );
  }

  removeVideoSelect() {
    this.showVideo = false;
    this.video = [];
    this.videoOption = false;
    this.openRecordVideo = false;
    this.requestVideo = "";
    this.videosThumbnile = "";
    this.videoUpload = false;
    this.videoOptionSelected = false;
  }
  handleVideoSelection(evt:any) {
    var files = evt.target.files; // FileList object
    // Loop through the FileList and render image files as thumbnails.
    for (var i = 0, f; (f = files[i]); i++) {
      if (f.type.match("video.*")) {
        this.handleVideoSelect(files[i]);
        continue;
      }
      this.selectedVideoFiles = files[i];
      var reader = new FileReader();
      // Closure to capture the file information.
      reader.onload = ((theFile) => {
        return (e:any) => {
          var strImage = e.target.result;
          // this.selectedVideoFiles.push(strImage)
        };
      })(f);
      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  }

  handleVideoSelect(videoFile:any) {
    this.closeVideobutton.nativeElement.click();
    this.IsLoader = true;
    this.selectedVideoFiles = videoFile;
    var reader = new FileReader();
    if (videoFile != null) {
      this.isDisable = false;
      this.setFileInfo(videoFile);
    } else {
      //document.getElementById("errorInVideo").innerHTML = "Size should be less than 2MB";
      this.isDisable = true;
    }
    if (this.isDisable) {
      if (this.videoTimeIssue) {
        this.toastyService.failure(
          "",
          "Duration of video should be less than " +
            this.VIDEODURATION +
            " seconds."
        );
        this.IsLoader = false;
        return;
      } else {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "Not a valid Video, Please try again"
          )
        );
        this.IsLoader = false;
        return;
      }
      // this.IsLoader = false;
    } else {
      this.videosThumbnile = videoFile;
      this.videoOption = true;
      this.IsLoader = false;
      this.showVideo = true;
      this.videoOptionSelected = true;
      this.cdf.detectChanges();
      // this.uploadService.uploadVideo(videoFile).then((value) => {
      //   console.log('value', value);
      //   if (value != null || value != "") {
      //     this.IsLoader = false;
      //     this.showVideo = true;
      //     this.videoOptionSelected = true;
      //     this.videoOption = true;
      //     this.helpVideo = value;

      //   }
      //   //this.IsLoader = false;
      // });
      // this.IsLoader = false;
    }
  }
  setFileInfo(file:any) {
    var video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;
      file.duration = duration;
      if (file.duration < this.VIDEODURATION) {
        this.isDisable = false;
        this.videoTimeIssue = false;
      } else {
        // document.getElementById("errorInVideo").innerHTML = "Duration of video should be less than " + this.VIDEODURATION + " seconds.";
        this.videoTimeIssue = true;
        this.isDisable = true;
      }
    };

    //video.src = URL.createObjectURL(file);
  }
  handleAudioSelect(audioFile:any) {
    this.IsLoader = true;
    this.selectAudioFile = audioFile;
    var reader = new FileReader();
    if (audioFile != null) {
      this.isDisable = false;
      this.setFileInfo(audioFile);
    } else {
      //document.getElementById("errorInVideo").innerHTML = "Size should be less than 2MB";
      this.isDisable = true;
    }
    if (this.isDisable) {
      if (this.videoTimeIssue) {
        this.toastyService.failure(
          "",
          "Duration of Audio should be less than " +
            this.AUDIODURATION +
            " seconds."
        );
        this.IsLoader = false;
        return;
      } else {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "Not a valid Video, Please try again"
          )
        );
        this.IsLoader = false;
        return;
      }
    } else {
      // this.voiceOption = true;
      // this.voiceOptionSelected = true;
      this.IsLoader = false;
    }
  }
  selectedSubType(e:any) {
    let subcategoryItem: any;
    subcategoryItem = this.categoryList.filter(
      (x:any) => x.cat_id == this.selectedOption
    );
    let subcategoryIds = subcategoryItem[0].subcat_id[e];
    console.log("subcat_",subcategoryItem,this.subTypeCat,this.selectSubType,
    subcategoryIds);
    if (this.subTypeCat == e) {
      this.subTypeCat = -1;
      this.selectSubType = 0;
    } else {
      this.subTypeCat = e;
      this.selectSubType = subcategoryIds;
    }
    console.log("subcat_",subcategoryItem,this.subTypeCat,this.selectSubType,
    subcategoryIds);
  }
  selectCategoryOption(e:any, sub?:any) {
    this.selectedOption = e;
    console.log(this.categoryList[0].subcat_id.indexOf(sub));
    let subcategoryIds = this.categoryList[0].subcat_id.indexOf(sub);
    this.subTypeCat = subcategoryIds;
    this.selectSubType = sub || 0;
    this.nextPage();
    this.getSubCategory()
  }

  selectCategoryDetails() {
    let subcategoryItem = this.categoryList.filter(
      (x:any) => x.cat_id == this.selectedOption
    );
    return subcategoryItem[0] || {};
  }


  changedForm(){
    if(this.actualFormData.selectedOption != this.selectedOption || 
      this.actualFormData.helpType != this.helpType ||  
      this.actualFormData.helpDescription != this.helpDescription){
        return true;
      }
    return false;
  }
  
  actualFormData: {selectedOption:string,helpType:string,helpDescription:string}=
  {selectedOption : "", helpType : "", helpDescription: ""};

  invalidForm(): boolean{
    this.emptyCat = false;
    this.emptySubCat = false;
    this.emptyTitle = false;
    this.emptyDescription = false;
    this.emptyHrType = false;
    if(!this.selectedOption){
      this.emptyCat = true;
      this.helpCategoy.nativeElement.scrollIntoView({
        behavior:"smooth",
        block: "start",
        inline:"nearest"
      })
      return true;
    }
    if(!this.selectSubType){
      this.emptySubCat = true;
      this.helpSubCategoy.nativeElement.scrollIntoView({
        behavior:"smooth",
        block: "start",
        inline:"nearest"
      })
      return true;
    }
    if(!this.helpType){
      this.emptyTitle = true;
      this.helpTitle.nativeElement.scrollIntoView({
        behavior:"smooth",
        block: "start",
        inline:"nearest"
      })
      return true;
    }
    else if(!this.helpDescription){
      this.emptyDescription = true;
      this.helpDescriptionView.nativeElement.scrollIntoView({
        behavior:"smooth",
        block: "start",
        inline:"nearest"
      })
      return true;
    }
    // if(!this.hrTypeSelected){
    //   this.emptyHrType = true;
    //   this.helpType_.nativeElement.scrollIntoView({
    //     behavior:"smooth",
    //     block: "start",
    //     inline:"nearest"
    //   })
    //   return true;
    // }

    return false;
  }

  async onSubmit() {
    if(this.invalidForm()){
      return false;
    }
    await this.getLocation();
    this.countLoginDialogFre++;
    localStorage.setItem('requestCreated','true');
    this.loginService.openModal(
      { autoChangeScreen: true },
      (status: string) => {
        if (status) {
          this.countLoginDialogFre = 0;
          this.IsLoader = true;
          this.disableBtn = true;
          this.helpImage = "";
          var promises = [];
          if (
            this.helpDescription != "" &&
            this.helpDescription != null &&
            this.helpDescription != undefined
          ) {
            this.descriptionOptionSelected = true;
          }
          if (
            !this.imageOption &&
            !this.videoOption &&
            !this.voiceOption &&
            !this.descriptionOptionSelected
          ) {
            this.toastyService.failure(
              "",
              this.languageService.getTranslated(
                "Please select Atleast one media Option"
              )
            );
            this.disableBtn = false;
            this.imageOption = false;
            this.imageOptionSelected = false;
            this.videoOption = false;
            this.videoOptionSelected = false;
            this.voiceOption = false;
            this.voiceOptionSelected = false;
            this.descriptionOptionSelected = false;
            this.IsLoader = false;
            return;
          }
          if (this.imageOption) {
            promises.push(
              new Promise((resolve, reject) => {
                this.uploadService
                  .uploadImageOnFirebase(this.ImageFiles)
                  .then((imageurl:any) => {
                    if (imageurl.length > 0) {
                      imageurl.forEach((element:any) => {
                        this.imageUploaded.push(element);
                      });
                      let abc = JSON.stringify(this.imageUploaded);
                      this.helpImage = abc.replace("[", "{").replace("]", "}");
                      resolve(imageurl);
                    } else {
                      reject(imageurl);
                    }
                  });
              })
            );
          }
          if (this.voiceOption) {
            promises.push(
              new Promise((resolve, reject) => {
                this.uploadService
                  .uploadAudio(this.selectHelpVoice)
                  .then((value:any) => {
                    if (value != null || value != "") {
                      this.helpAudio = value;
                      resolve(value);
                    } else {
                      reject(value);
                    }
                  });
              })
            );
          }
          if (this.videoOption) {
            promises.push(
              new Promise((resolve, reject) => {
                this.uploadService
                  .uploadVideo(this.videosThumbnile)
                  .then((value:any) => {
                    if (value != null || value != "") {
                      this.helpVideo = value;
                      resolve(value);
                    } else {
                      reject(value);
                    }
                  });
              })
            );
          }
          Promise.all(promises).then(
            (values) => {
              this.helpDescription = this.helpDescription.trim();
              if (this.descriptionOptionSelected) {
                if (
                  this.helpDescription == "" ||
                  this.helpDescription == null ||
                  this.helpDescription == undefined
                ) {
                  this.toastyService.failure(
                    "",
                    this.languageService.getTranslated(
                      "Please Enter Request Details First."
                    )
                  );
                  this.disableBtn = false;
                  this.IsLoader = false;
                  return;
                }
              } else {
                this.helpDescription = "";
              }
              if (
                (this.helpDescription == "" ||
                  this.helpDescription == null ||
                  this.helpDescription == undefined) &&
                (this.helpImage == "{}" ||
                  this.helpImage == "" ||
                  this.helpImage == null ||
                  this.helpImage == undefined) &&
                (this.helpVideo == "" ||
                  this.helpVideo == null ||
                  this.helpVideo == undefined) &&
                (this.helpAudio == "" ||
                  this.helpAudio == null ||
                  this.helpAudio == undefined)
              ) {
                this.toastyService.failure(
                  "",
                  this.languageService.getTranslated(
                    "Please select Atleast one media Option"
                  )
                );
                this.disableBtn = false;
                this.imageOption = false;
                this.imageOptionSelected = false;
                this.videoOption = false;
                this.videoOptionSelected = false;
                this.videoOption = false;
                this.voiceOptionSelected = false;
                this.descriptionOptionSelected = false;
                this.IsLoader = false;
                return;
              }
              if (
                this.helpDescription != "" &&
                this.helpDescription != null &&
                this.helpDescription != undefined
              ) {
                this.descriptionOptionSelected = true;
              }
              if (
                this.imageOption &&
                !this.videoOption &&
                !this.voiceOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 1;
              } else if (
                this.videoOption &&
                !this.imageOption &&
                !this.voiceOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 2;
              } else if (
                this.voiceOption &&
                !this.imageOption &&
                !this.videoOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 3;
              } else if (
                this.descriptionOptionSelected &&
                !this.imageOption &&
                !this.videoOption &&
                !this.voiceOption
              ) {
                this.selected_type = 4;
              } else if (
                this.imageOption &&
                this.videoOption &&
                !this.voiceOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 12;
              } else if (
                this.imageOption &&
                this.voiceOption &&
                !this.videoOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 13;
              } else if (
                this.imageOption &&
                this.descriptionOptionSelected &&
                !this.voiceOption &&
                !this.videoOption
              ) {
                this.selected_type = 14;
              } else if (
                this.videoOption &&
                this.voiceOption &&
                !this.imageOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 23;
              } else if (
                this.videoOption &&
                this.descriptionOptionSelected &&
                !this.voiceOption &&
                !this.imageOption
              ) {
                this.selected_type = 24;
              } else if (
                this.voiceOption &&
                this.descriptionOptionSelected &&
                !this.videoOption &&
                !this.imageOption
              ) {
                this.selected_type = 34;
              } else if (
                this.imageOption &&
                this.videoOption &&
                this.voiceOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 123;
              } else if (
                this.imageOption &&
                this.videoOption &&
                this.descriptionOptionSelected &&
                !this.voiceOption
              ) {
                this.selected_type = 124;
              } else if (
                this.imageOption &&
                this.voiceOption &&
                this.descriptionOptionSelected &&
                !this.videoOption
              ) {
                this.selected_type = 134;
              } else if (
                this.videoOption &&
                this.voiceOption &&
                this.descriptionOptionSelected &&
                !this.imageOption
              ) {
                this.selected_type = 234;
              } else if (
                this.imageOption &&
                this.videoOption &&
                this.voiceOption &&
                this.descriptionOptionSelected
              ) {
                this.selected_type = 1234;
              }
              // let helpVoice = this.selectHelpVoice;
              let helpType = this.selected_type;
              let helpTitle = this.helpType;

              let v1 = this.cookieService.get("localstars") == "true" ? 1 : 0;
              let v2 =
                this.cookieService.get("Help_Video_seen") == "true" ? 1 : 0;
              let v3 =
                this.cookieService.get("publicfacilities") == "true" ? 1 : 0;
              let v4 = this.cookieService.get("local") == "true" ? 1 : 0;
              let v5 = this.cookieService.get("official") == "true" ? 1 : 0;
              let v6 = JSON.stringify(Object.values({ v1, v2, v3, v4, v5 }));

              let metaData = {
                virtual_id: localStorage.getItem("virtualId"),
                browser_userAgent: this.cookieService.get("browser_userAgent"),
                LanguageSelected: this.cookieService.get("LanguageSelected"),
                LocationSelected: this.cookieService.get("LocationSelected"),
                Help_videos_seen: v2,
                Home_videos_seen: v6,
                platform: this.cookieService.get("platform"),
                is_logged__in: this.cookieService.get("is_logged__in"),
                Webtoken: localStorage.getItem("Webtoken"),
                username: this.cookieService.get("username"),
                mobile_number: this.cookieService.get("mobile_number"),
              };

              if (this.selectedOption == 16 && !this.selectSubType) {
                // 16 for mnrega
                this.toastyService.failure(
                  "",
                  this.languageService.getTranslated(
                    "Please select a subcategory."
                  )
                );
                this.disableBtn = false;
                this.IsLoader = false;
                return;
              }
              let body:any;
              // if it is a paid help request
              let standardHrLang = this.cookieService.get("LanguageSelected")?this.cookieService.get("LanguageSelected"): "en";
              if(this.isPaidHelpRequest){
                body = {
                  username: this.name,
                  category: this.selectedOption,
                  subCategory: this.selectSubType,
                  lat: this.userLatLng.lat,
                  lng: this.userLatLng.lng,
                  metadata: JSON.stringify(metaData),
                  stcode11: this.stcode11,
                  dtcode11: this.dtcode11,
                  helpType: helpType,
                  helpTitle: helpTitle,
                  stname: this.stname,
                  dtname: this.dtname,
                  helpVideo:
                    this.helpVideo != "" &&
                    this.helpVideo != undefined &&
                    this.helpVideo != null
                      ? this.helpVideo
                      : "",
                  helpVoice:
                    this.helpAudio != "" &&
                    this.helpAudio != undefined &&
                    this.helpAudio != null
                      ? this.helpAudio
                      : "",
                  helpPictures:
                    this.helpImage != "" &&
                    this.helpImage != null &&
                    this.helpImage != "{}"
                      ? this.helpImage
                      : "{}",
                  helpDescription:
                    this.helpDescription != "" &&
                    this.helpDescription != undefined &&
                    this.helpDescription != null
                      ? this.helpDescription
                      : "",
                  is_paid_help: true,
                  is_standard: false,
                  standard_hr_lang:standardHrLang
                };
                
              }
              else{
                body = {
                  username: this.name,
                  category: this.selectedOption,
                  subCategory: this.selectSubType,
                  lat: this.userLatLng.lat,
                  lng: this.userLatLng.lng,
                  metadata: JSON.stringify(metaData),
                  stcode11: this.stcode11,
                  dtcode11: this.dtcode11,
                  helpType: helpType,
                  helpTitle: helpTitle,
                  stname: this.stname,
                  dtname: this.dtname,
                  helpVideo:
                    this.helpVideo != "" &&
                    this.helpVideo != undefined &&
                    this.helpVideo != null
                      ? this.helpVideo
                      : "",
                  helpVoice:
                    this.helpAudio != "" &&
                    this.helpAudio != undefined &&
                    this.helpAudio != null
                      ? this.helpAudio
                      : "",
                  helpPictures:
                    this.helpImage != "" &&
                    this.helpImage != null &&
                    this.helpImage != "{}"
                      ? this.helpImage
                      : "{}",
                  helpDescription:
                    this.helpDescription != "" &&
                    this.helpDescription != undefined &&
                    this.helpDescription != null
                      ? this.helpDescription
                      : "",
                      standard_hr_lang:standardHrLang
                };
              }

              // from popular service
              if(this.popularService){
                body.is_popular_service=true
              }

              // this.IsLoader = false;
              this.subscriptions.push(
                this.dataService.createHelpRequestv2(body).subscribe((req) => {
                  // a step for causing issue id removing on creation of request from login page
                  localStorage.removeItem('requestCreated');
                  if (req.success == false) {
                    if (
                      req.msg ==
                      "either of the media type should be supplied to add help request"
                    ) {
                      this.toastyService.failure(
                        "",
                        this.languageService.getTranslated(
                          "Please select Atleast one media Option"
                        )
                      );
                      this.disableBtn = false;
                      this.imageOption = false;
                      this.imageOptionSelected = false;
                      this.videoOption = false;
                      this.videoOptionSelected = false;
                      this.videoOption = false;
                      this.voiceOptionSelected = false;
                      this.descriptionOptionSelected = false;
                      this.IsLoader = false;
                      return;
                    }
                  } else {
                    // this.toastyService.success('', this.languageService.getTranslated('Help request successfully posted.'));
                    this.disableBtn = false;
                    this.IsLoader = false;
                    this.isHelpRequestCreated = true;
                    this.googleAnalyticsService.eventEmitter2('ja_create_hr',{});
                    const statusValue ='0';
                    if(this.isPaidHelpRequest){
                      const time = Date.now();
                      localStorage.setItem("paid_help_creation_time",time.toString());
                      this.dataService.setOfferHelpid(req.result.helpid);
                      localStorage.setItem("create_paid_help_id",req.result.helpid);
                      // set status = 0 in ABTesting new case
                      this.router.navigate(["/help-request/getHelp-RequestbyId"], {
                        queryParams: {
                          Id: req.result.helpid,
                          pageNo: "1",
                          status: statusValue,
                          source: "help",
                          isPaidType: "true"
                        },
                      });
                      this.googleAnalyticsService.eventEmitter(
                        "ja_create_paid_hr",
                        "",
                        "Paid Help created from old create help page"
                      );
                      (window as any).hj('event', 'PaidHR_Created_Home');
                      if(this.authService.authenticated){
                        this.dataService.updateNewHridAndTimestamp(this.commonService.getUsername(),
                        req.result.helpid);
                      }
                    }
                    else{
                      this.router.navigate(["/help-request/getHelp-RequestbyId"], {
                        queryParams: {
                          Id: req.result.helpid,
                          pageNo: "1",
                          status: statusValue,
                          source: "help",
                        },
                      });
                      // this.router.navigate(["/feeds/help"], {
                      //   queryParams: {
                      //     upload: "help",
                      //     newHRID: req.result.helpid,
                      //   },
                      // });
                      this.googleAnalyticsService.eventEmitter(
                        "ja_create_free_hr",
                        "",
                        "unpaid Help created from old create help page"
                      );
                      (window as any).hj('event', 'UnpaidHR_Created_Home');
                      this.isHelpRequestCreated = true;
                    }
                    this.googleAnalyticsService.eventEmitter(
                      "web_help-request_created",
                      "",
                      "User creates help-request"
                    );
                  }
                  this.IsLoader = false;
                },error =>{
                  this.IsLoader = false;
                })
              );
            },
            (reason) => {
              // console.log("rejected" + reason);
            }
          );
        } else {
          this.moeService.eventEmitter("web_Login/Signup_Left", {
            platform: "web",
            page: "create_help_req",
            count: this.countLoginDialogFre,
          });
          // this.onSubmit();
        }
      }
    );
  }
  onEdit() {
    this.countLoginDialogFre++;
    this.loginService.openModal(
      { autoChangeScreen: true },
      (status: string) => {
        if (status) {
          this.countLoginDialogFre = 0;
          this.IsLoader = true;
          this.disableBtn = true;
          this.helpImage = "";
          var promises1 = [];
          if (
            !this.imageOption &&
            !this.videoOption &&
            !this.voiceOption &&
            !this.descriptionOptionSelected
          ) {
            this.toastyService.failure(
              "",
              this.languageService.getTranslated(
                "Please select Atleast one media Option"
              )
            );
            this.disableBtn = false;
            this.imageOption = false;
            this.imageOptionSelected = false;
            this.videoOption = false;
            this.videoOptionSelected = false;
            this.videoOption = false;
            this.voiceOptionSelected = false;
            this.descriptionOptionSelected = false;
            this.IsLoader = false;
            return;
          }
          if (this.imageOption) {
            if (this.requestImages.length > 0) {
              this.requestImages.forEach((element:any) => {
                this.imageUploaded.push(element);
              });
            }
            if (this.ImageFiles.length > 0) {
              promises1.push(
                new Promise((resolve, reject) => {
                  this.uploadService
                    .uploadImageOnFirebase(this.ImageFiles)
                    .then((imageurl:any) => {
                      if (imageurl.length > 0) {
                        imageurl.forEach((element:any) => {
                          this.imageUploaded.push(element);
                        });
                        resolve(imageurl);
                      } else {
                        reject(imageurl);
                      }
                    });
                })
              );
            }
          }
          if (this.voiceOption) {
            promises1.push(
              new Promise((resolve, reject) => {
                this.uploadService
                  .uploadAudio(this.selectHelpVoice)
                  .then((value:any) => {
                    if (value != null || value != "") {
                      this.helpAudio = value;
                      resolve(value);
                    } else {
                      reject(value);
                    }
                  });
              })
            );
          }
          if (this.videoOption) {
            if (
              this.requestVideo != "" &&
              this.requestVideo != null &&
              this.requestVideo != undefined
            ) {
              this.helpVideo = this.requestVideo;
            } else {
              promises1.push(
                new Promise((resolve, reject) => {
                  this.uploadService
                    .uploadVideo(this.videosThumbnile)
                    .then((value:any) => {
                      if (value != null || value != "") {
                        this.helpVideo = value;
                        resolve(value);
                      } else {
                        reject(value);
                      }
                    });
                })
              );
            }
          }
          Promise.all(promises1).then(
            (values) => {
              if (this.imageUploaded.length > 0) {
                let abc = JSON.stringify(this.imageUploaded);
                this.helpImage = abc.replace("[", "{").replace("]", "}");
              }
              this.helpDescription = this.helpDescription.trim();
              if (this.descriptionOptionSelected) {
                if (
                  this.helpDescription == "" ||
                  this.helpDescription == null ||
                  this.helpDescription == undefined
                ) {
                  this.toastyService.failure(
                    "",
                    this.languageService.getTranslated(
                      "Please Enter Request Details First."
                    )
                  );
                  this.disableBtn = false;
                  this.IsLoader = false;
                  return;
                }
              } else {
                this.helpDescription = "";
              }
              if (
                (this.helpDescription == "" ||
                  this.helpDescription == null ||
                  this.helpDescription == undefined) &&
                (this.helpImage == "{}" ||
                  this.helpImage == "" ||
                  this.helpImage == null ||
                  this.helpImage == undefined) &&
                (this.helpVideo == "" ||
                  this.helpVideo == null ||
                  this.helpVideo == undefined) &&
                (this.helpAudio == "" ||
                  this.helpAudio == null ||
                  this.helpAudio == undefined)
              ) {
                this.toastyService.failure(
                  "",
                  this.languageService.getTranslated(
                    "Please select Atleast one media Option"
                  )
                );
                this.disableBtn = false;
                this.imageOption = false;
                this.imageOptionSelected = false;
                this.videoOption = false;
                this.videoOptionSelected = false;
                this.videoOption = false;
                this.voiceOptionSelected = false;
                this.descriptionOptionSelected = false;
                this.IsLoader = false;
                return;
              }
              if (
                this.helpDescription != "" &&
                this.helpDescription != null &&
                this.helpDescription != undefined
              ) {
                this.descriptionOptionSelected = true;
              }
              if (
                this.imageOption &&
                !this.videoOption &&
                !this.voiceOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 1;
              } else if (
                this.videoOption &&
                !this.imageOption &&
                !this.voiceOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 2;
              } else if (
                this.voiceOption &&
                !this.imageOption &&
                !this.videoOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 3;
              } else if (
                this.descriptionOptionSelected &&
                !this.imageOption &&
                !this.videoOption &&
                !this.voiceOption
              ) {
                this.selected_type = 4;
              } else if (
                this.imageOption &&
                this.videoOption &&
                !this.voiceOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 12;
              } else if (
                this.imageOption &&
                this.voiceOption &&
                !this.videoOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 13;
              } else if (
                this.imageOption &&
                this.descriptionOptionSelected &&
                !this.voiceOption &&
                !this.videoOption
              ) {
                this.selected_type = 14;
              } else if (
                this.videoOption &&
                this.voiceOption &&
                !this.imageOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 23;
              } else if (
                this.videoOption &&
                this.descriptionOptionSelected &&
                !this.voiceOption &&
                !this.imageOption
              ) {
                this.selected_type = 24;
              } else if (
                this.voiceOption &&
                this.descriptionOptionSelected &&
                !this.videoOption &&
                !this.imageOption
              ) {
                this.selected_type = 34;
              } else if (
                this.imageOption &&
                this.videoOption &&
                this.voiceOption &&
                !this.descriptionOptionSelected
              ) {
                this.selected_type = 123;
              } else if (
                this.imageOption &&
                this.videoOption &&
                this.descriptionOptionSelected &&
                !this.voiceOption
              ) {
                this.selected_type = 124;
              } else if (
                this.imageOption &&
                this.voiceOption &&
                this.descriptionOptionSelected &&
                !this.videoOption
              ) {
                this.selected_type = 134;
              } else if (
                this.videoOption &&
                this.voiceOption &&
                this.descriptionOptionSelected &&
                !this.imageOption
              ) {
                this.selected_type = 234;
              } else if (
                this.imageOption &&
                this.videoOption &&
                this.voiceOption &&
                this.descriptionOptionSelected
              ) {
                this.selected_type = 1234;
              }
              let helpVoice = this.selectHelpVoice;
              let helpType = this.selected_type;
              let helpTitle = this.helpType;
              let body = {
                helpRequestId: this.helpRequestId,
                username: this.name,
                category: this.selectedOption,
                subCategory: this.selectSubType,
                helpType: helpType,
                helpTitle: helpTitle,
                helpVideo:
                  this.helpVideo != "" &&
                  this.helpVideo != undefined &&
                  this.helpVideo != null
                    ? this.helpVideo
                    : "",
                helpVoice:
                  this.helpAudio != "" &&
                  this.helpAudio != undefined &&
                  this.helpAudio != null
                    ? this.helpAudio
                    : "",
                helpPictures:
                  this.helpImage != "" &&
                  this.helpImage != null &&
                  this.helpImage != "{}"
                    ? this.helpImage
                    : "{}",
                helpDescription:
                  this.helpDescription != "" &&
                  this.helpDescription != undefined &&
                  this.helpDescription != null
                    ? this.helpDescription
                    : "",
              };
              this.subscriptions.push(
                this.dataService.editHelpRequest(body).subscribe((req) => {
                  if (req.success == false) {
                    if (
                      req.msg ==
                      "either of the media type should be supplied to add help request"
                    ) {
                      this.toastyService.failure(
                        "",
                        this.languageService.getTranslated(
                          "Please select Atleast one media Option"
                        )
                      );
                      this.disableBtn = false;
                      this.imageOption = false;
                      this.imageOptionSelected = false;
                      this.videoOption = false;
                      this.videoOptionSelected = false;
                      this.voiceOption = false;
                      this.voiceOptionSelected = false;
                      this.descriptionOptionSelected = false;
                      this.IsLoader = false;
                      return;
                    }
                  } else {
                    this.toastyService.success(
                      "",
                      this.languageService.getTranslated(
                        "Help request successfully posted."
                      )
                    );
                    this.disableBtn = false;
                    this.IsLoader = false;
                    this.gotoPrivousPage();
                    this.googleAnalyticsService.eventEmitter(
                      "web_help-request_created",
                      "",
                      "User creates help-request"
                    );
                  }
                  this.IsLoader = false;
                })
              );
            },
            (reason) => {
              // console.log("rejected" + reason);
            }
          );
        } else {
          this.moeService.eventEmitter("web_Login/Signup_Left", {
            platform: "web",
            page: "edit_help_req",
            count: this.countLoginDialogFre,
          });
          this.onEdit();
        }
      }
    );
  }

  nextPage() {
    if (this.showScreen == 0) {
      if (
        this.selectedOption != 0 &&
        this.selectedOption != undefined &&
        this.selectedOption != null
      ) {
        this.showScreen = 1;
        this.descriptionOptionSelected = true;
      } else {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "Please select Atleast one Category from above list."
          )
        );
        return;
      }
    }
    // else {
    //   this.showScreen = 0;
    // }
  }
  imageUploadOption(e:any) {
    console.log('image upload option',e);
    this.showWebcam = false;
    if (e == 1) {
      this.imageClickOption = true;
      this.showWebcam = true;
      // $("#imageModal").modal("hide");
      this.closeImagebutton.nativeElement.click();
    } else {
      // this.closeImagebutton.nativeElement.click();
      this.imageSelect = true;
      // this.imageOption = true;
    }
  }

  videoUploadOption(e:any) {
    this.openRecordVideo = false;
    if (e == 1) {
      this.videoUpload = true;
    } else {
      this.videoRecord = true;
      this.openRecordVideo = true;
      this.closeVideobutton.nativeElement.click();
    }
  }
  selectAttachOption(option:any) {
    if (option == 1) {
      this.showWebcam = false;
      this.imageOptionSelected = !this.imageOptionSelected;
      if (this.videoOption) {
        this.videoOptionSelected = true;
      } else {
        this.videoOptionSelected = false;
      }
      if (this.voiceOption) {
        this.voiceOptionSelected = true;
      } else {
        this.voiceOptionSelected = false;
      }
      if (this.helpDescription != "") {
        this.descriptionOptionSelected = true;
      } else {
        this.descriptionOptionSelected = false;
      }
      if (this.ImageFiles.length > 0) {
        this.imageSelected = true;
      }
    } else if (option == 2) {
      this.openRecordVideo = false;
      // this.videoOption = !this.videoOption;
      if (this.videoOption) {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "You have already added a video. To replace it, please delete that video first."
          )
        );
        return;
      } else {
        this.videoOptionSelected = !this.videoOptionSelected;
      }

      if (this.imageOption) {
        this.imageOptionSelected = true;
      } else {
        this.imageOptionSelected = false;
      }
      if (this.voiceOption) {
        this.voiceOptionSelected = true;
      } else {
        this.voiceOptionSelected = false;
      }
      if (this.helpDescription != "") {
        this.descriptionOptionSelected = true;
      } else {
        this.descriptionOptionSelected = false;
      }
      if (!this.videoOption) {
        this.openRecordVideo = false;
      }
      if (this.videosThumbnile) {
        this.videoSelected = true;
      }
    } else if (option == 3) {
      if (this.voiceOption) {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "You have already added a audio. To replace it, please delete that audio first."
          )
        );
        return;
      } else {
        this.voiceOptionSelected = !this.voiceOptionSelected;
      }
      if (this.imageOption) {
        this.imageOptionSelected = true;
      } else {
        this.imageOptionSelected = false;
      }
      if (this.videoOption) {
        this.videoOptionSelected = true;
      } else {
        this.videoOptionSelected = false;
      }
      if (this.helpDescription != "") {
        this.descriptionOptionSelected = true;
      } else {
        this.descriptionOptionSelected = false;
      }
    } else if (option == 4) {
      // this.descriptionOption = !this.descriptionOption;
      this.descriptionOptionSelected = !this.descriptionOptionSelected;
      if (this.imageOption) {
        this.imageOptionSelected = true;
      } else {
        this.imageOptionSelected = false;
      }
      if (this.videoOption) {
        this.videoOptionSelected = true;
      } else {
        this.videoOptionSelected = false;
      }
      if (this.voiceOption) {
        this.voiceOptionSelected = true;
      } else {
        this.voiceOptionSelected = false;
      }
    }
  }

  remove_picture(image:any) {
    this.requestImages.splice(image, 1);
    this.selectedImageThumbnail.splice(image, 1);
    if (this.selectedImageThumbnail.length == 0) {
      this.imageOption = false;
      this.imageSelected = false;
    }
    this.imageSelected = false;
    this.imageOptionSelected = false;
    this.ImageFiles = [];
    //this.selectedImageThumbnail = [];
    this.helpImage = "";
  }
  remove_video() {
    //$("#videoRecordModal").modal("hide");
    this.showVideo = false;
    const element:any = document.getElementById("videoRecordModalButton")
    element.click();
    let stream = this.stream;
    stream.getAudioTracks().forEach((track) => track.stop());
    stream.getVideoTracks().forEach((track) => track.stop());
    this.video = [];
    this.videoOption = false;
    this.videoOptionSelected = false;
    this.openRecordVideo = false;
    this.videosThumbnile = "";
  }
  // getSelectedCategorArray() {
  //   let selectCategoryArray = (this.categoryList || []).filter(x => x.cat_id == this.selectedOption);
  //   return selectCategoryArray || [];
  // }
  getSelectedGeneralCatArray() {
    this.selectCategoryArray = [];
    for (let category of this.categoryList) {
      category["pref"].forEach((element:any, index:any) => {
        if (element == true) {
          let newdata = {
            ...category,
            ...{
              sub_cat_final: category["subcat_id"][index],
              subcat_key_final: category["subcat_key"][index],
              subcat_lang_final: category["SubCatlangKey"][index],
            },
          };
          this.selectCategoryArray.push(newdata);
        }
      });
    }
  }

  selectCategoryAndSubCatOption(cat:any, sub_cat:any) {
    if(this.popularService){
      return;
    }
    this.selectCategoryOption(cat, sub_cat);
  }

  onSelectMore(){
    if(this.popularService){
      return;
    }
    this.selectedOption = undefined; 
    this.general = false
  }

  getCategoryArray() {
    let categoryArr = this.categoryList || [];
    return categoryArr || [];
  }

  subcategories:{index:number,value:string}[] =[];
  getSubCat(cat:any,subcat:string){
    let subcategory: any;
    subcategory = (this.categoryList || []).filter(
      (x:any) => x.cat_id == cat
    );
    if (subcategory && subcategory.length > 0) {
      let index = 0;
      let found = false;
      for(let subCat of subcategory[0].subcat_id){
        if(subCat == subcat){
          found = true;
          break;
        }
        index = index +1;
      }
      if(found){
        const subCat = found?subcategory[0].SubCatlangKey[index]:null;
        return subCat;
      }
    }
    return null;
  }
  getSubCategory() {
    let subcategory: any;
    this.subcategories=[]
    subcategory = (this.categoryList || []).filter(
      (x:any) => x.cat_id == this.selectedOption
    );
    if (subcategory && subcategory.length > 0) {
      let subcategories:{index:number,value:string}[] =[];
      let index = 0;
      let otherIndex= -1;
      let otherIndexValue = "";
      for(let subCat of subcategory[0].SubCatlangKey){
        if(subCat == "Other"){
          otherIndex = index;
          otherIndexValue = subCat;
        }
        else{
          subcategories.push({index:index,value:subCat})
        }
        index = index +1;
      }
      if(otherIndex != -1){
        subcategories.push({index:otherIndex,value:otherIndexValue})
      }
      this.subcategories = subcategories;
      return subcategories;
    } else {
      return [];
    }
  }

  backButton(){
    if (this.helpRequestId != undefined && this.helpRequestId != null){
      if(this.changedForm()){
        this.confirmationButton.nativeElement.click();
        return;
      }
      else{
        this.gotoPrivousPage();
      }
    }
    else{
      this.general == false ? this.general = true: this.gotoPrivousPage();
      this.gotoPrivousPage();
    }
  }

  gotoPrivousPage() {
    // console.log('inside hj 8');
    // hj('event', 'clicked_back_on_create_hr');
    this.previousUrl = this.previousRouteService.getPreviousUrl();
    if (this.previousUrl && this.previousUrl != "/")
      this.router.navigateByUrl(this.previousUrl);
    else this.router.navigate(["./feeds/help"]);

    // if (this.previousUrl == '/user-profile?profile_type=pvt') {
    //   this.router.navigate(['user-profile'], { queryParams: { profile_type: 'pvt' } });
    // } else {
    //   this.router.navigate(['./feeds/help']);
    // }
  }

  onNext():boolean {
    this.selectedOption = 14;
    this.showScreen = 1;
    return true;
  }

  onPrev() {
    // this.showScreen = 0;
  }

  goToHomeStatus: boolean = false;
  presected() {
    let cat_id:any = this.activatedRoute.snapshot.queryParamMap.get("cat_id");
    if (cat_id != null) {
      this.goToHomeStatus = true;
      const element:any = document.getElementById("moreSelect");
      element.click();
      setTimeout(() => {
        const catId:any =document.getElementById(cat_id);
        catId.click();
      }, 300);
    }
  }
  gotoHome() {
    this.goToHomeStatus = false;
    this.router.navigate(["/home"]);
  }

  togglePaidType(){
    if(!this.showPaidTypeToggleNo){
      return;
    }
    this.paidTypeToggle = !this.paidTypeToggle;
  }

  hrTypeSelected: boolean  = false;
  isPaidHelpRequest: boolean = true;
  helpRequestTypeSelected(type:'YES'|'NO'){
    this.hrTypeSelected = true;
    this.emptyHrType= false;
    if(type == 'YES'){
      this.isPaidHelpRequest = true;
    }
  }

  transformIntoSec(progress:any){
    if(progress!=0){
      return Math.round((progress*60)/100);
    }
    return 0;
  }


  openKnowMoreLink() {
    let mobileScreen = false;
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      mobileScreen = true;
    } else {
      mobileScreen = false;
    }
    const videoLink = "https://youtu.be/2wXBqiZWjn0";
    let dialogRefer;
    if (mobileScreen) {
      dialogRefer = this.dialog.open(VideoPlayer, {
        width: "100%",
        minWidth: "100%",
        height: "100%",
        data: {
          videoLink: videoLink,
        },
        panelClass: "transparent-dialog_for_videPlayer",
      });
    } else {
      dialogRefer = this.dialog.open(VideoPlayer, {
        width: "36rem",
        height: "28rem",
        data: {
          videoLink: videoLink,
        },
        panelClass: "transparent-dialog_for_videPlayer",
      });
    }
    dialogRefer.afterClosed().subscribe(() => {
      
    });
  }

  setCatSubcat = () => {
    const category = localStorage.getItem("hr_cat");
    const subCategory:any = localStorage.getItem("hr_sub_cat");
    this.helpRequestService.listObservable.subscribe(
      (data) => {
        this.categoryList = data;
        this.selectCategoryOption(category, subCategory);
        let found = false;
        for(let cat of this.selectCategoryArray){
          if(cat.cat_id == category && cat.sub_cat_final == this.selectSubType){
            found = true;
            break;
          }
        }
        if(found == false){
           this.general = false;
        }

        // SETTING SUB CATEGORY
        this.subCategoryValue = this.getSubCat(category, subCategory);
      }
    );
  }

  popularServices:PopularService[]=[];
  selectedPopularService:PopularService={
    catId:'-1',
    subCatId:'-1',
    message:'',
    helpTitle:'',
    emitraId:'',
    listingId:-1,
    price:0,
    fromOther:false,
    subCatName:''
  }
  onSelectPopularService=(item:PopularService)=>{
    this.selectedPopularService = {
      catId:item.catId,
      subCatId:item.subCatId,
      message:item.message,
      helpTitle:item.helpTitle,
      emitraId:item.emitraId,
      listingId:item.listingId,
      price:item.price,
      fromOther:false,
      subCatName:item.subCatName
    };
    if(item.listingId == -999){
      this.requireDetailForm.setValue({
        serviceName:item.helpTitle, describe:''
      });
    }else{
      this.requireDetailForm.setValue({
        serviceName:item.helpTitle, describe:item.helpTitle
      });
    }
  }

  onDescribe=($event:any)=>{
    const item = this.popularServices[0];
    const value = $event.target.value;
    this.selectedPopularService = {
      catId:item.catId,
      subCatId:item.subCatId,
      message:item.message,
      helpTitle:value,
      emitraId:item.emitraId,
      listingId:this.selectedPopularService.listingId,
      price:item.price,
      fromOther:false,
      subCatName:item.subCatName
    },
    this.requireDetailForm.patchValue({serviceName:value});
  }

  onChangeCategory=()=>{
    this.selectedPopularService = {
      catId:'-1',
      subCatId:'-1',
      message:'',
      helpTitle:'',
      emitraId:'',
      listingId:-1,
      price:0,
      fromOther:false,
      subCatName:''
    }
    let dialogRef = this.dialog.open(SelectCategoryDialog, {
      width: '600px',
      height: '540px',
      panelClass:'custom-panel-class3',
      disableClose: true,
      data:{referralCode:this.referralCode}
    });
    dialogRef.afterClosed().subscribe(response=>{
      if(response && response.popularServices){
        this.popularServices = response.popularServices;
      }
    })
    this.requireDetailForm.reset();
  }
  onChangeMobileNo(){
    let mobileNo = this.userDetailForm.value.mobileNo;
    if(mobileNo && mobileNo.length>10){
      mobileNo = mobileNo.toString();
      mobileNo = mobileNo.substring(0,10);
      this.userDetailForm.patchValue({mobileNo:mobileNo});
      // this.userDetailForm.controls['mobileNo'].markAsTouched();
    }
  }
  invalidUserDetails(){
    this.requireDetailForm.controls['serviceName'].markAsTouched();
    this.requireDetailForm.controls['describe'].markAsTouched();
    this.userDetailForm.controls['userName'].markAsTouched();
    this.userDetailForm.controls['mobileNo'].markAsTouched();
    this.userDetailForm.controls['location'].markAsTouched();
    return (this.requireDetailForm.invalid || this.userDetailForm.invalid);
  }

  inValidUserDetailsWithoutReferral(){
    this.requireDetailForm.controls['serviceName'].markAsTouched();
    this.requireDetailForm.controls['describe'].markAsTouched();
    return this.requireDetailForm.invalid;
  }

  onCreateRequest=async ()=>{
    // validation before request creation
    this.descriptionOptionSelected = true;
    if(this.inValidUserDetailsWithoutReferral()){
      return;
    }
    if (
      this.imageOption &&
      !this.videoOption &&
      !this.voiceOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 1;
    } else if (
      this.videoOption &&
      !this.imageOption &&
      !this.voiceOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 2;
    } else if (
      this.voiceOption &&
      !this.imageOption &&
      !this.videoOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 3;
    } else if (
      this.descriptionOptionSelected &&
      !this.imageOption &&
      !this.videoOption &&
      !this.voiceOption
    ) {
      this.selected_type = 4;
    } else if (
      this.imageOption &&
      this.videoOption &&
      !this.voiceOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 12;
    } else if (
      this.imageOption &&
      this.voiceOption &&
      !this.videoOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 13;
    } else if (
      this.imageOption &&
      this.descriptionOptionSelected &&
      !this.voiceOption &&
      !this.videoOption
    ) {
      this.selected_type = 14;
    } else if (
      this.videoOption &&
      this.voiceOption &&
      !this.imageOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 23;
    } else if (
      this.videoOption &&
      this.descriptionOptionSelected &&
      !this.voiceOption &&
      !this.imageOption
    ) {
      this.selected_type = 24;
    } else if (
      this.voiceOption &&
      this.descriptionOptionSelected &&
      !this.videoOption &&
      !this.imageOption
    ) {
      this.selected_type = 34;
    } else if (
      this.imageOption &&
      this.videoOption &&
      this.voiceOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 123;
    } else if (
      this.imageOption &&
      this.videoOption &&
      this.descriptionOptionSelected &&
      !this.voiceOption
    ) {
      this.selected_type = 124;
    } else if (
      this.imageOption &&
      this.voiceOption &&
      this.descriptionOptionSelected &&
      !this.videoOption
    ) {
      this.selected_type = 134;
    } else if (
      this.videoOption &&
      this.voiceOption &&
      this.descriptionOptionSelected &&
      !this.imageOption
    ) {
      this.selected_type = 234;
    } else if (
      this.imageOption &&
      this.videoOption &&
      this.voiceOption &&
      this.descriptionOptionSelected
    ) {
      this.selected_type = 1234;
    }
    const username = this.commonService.getUsername();
    const firstname = await this.commonService.getUserFullName();
    console.log('===username',username,firstname,this.userDetailForm.value.userName);
    if(username){
      await this.getLocation();
    }
    let phoneNo:any = this.userDetailForm.value.mobileNo;
    phoneNo = phoneNo?phoneNo.toString():'';
    if(phoneNo && !phoneNo.includes('+91')){
      phoneNo =`+91${phoneNo}`;
    }
    const userDetails:any = {
      phone_no: phoneNo,
      code:"",
      username: username,
      category: this.selectedPopularService.catId,
      subCategory: this.selectedPopularService.subCatId,
      lat:this.userLatLng.lat,
      lng: this.userLatLng.lng,
      stcode11: this.stcode11,
      dtcode11: this.dtcode11,
      helpType: this.selected_type,
      helpTitle: firstname || username || this.userDetailForm.value.userName ,  // passing if first name present, otherwise username..
      stname: this.stname,
      dtname: this.dtname,
      helpVideo: this.helpVideo != "" &&
                  this.helpVideo != undefined &&
                  this.helpVideo != null
                    ? this.helpVideo
                    : "",
      helpVoice: this.helpAudio != "" &&
                  this.helpAudio != undefined &&
                  this.helpAudio != null
                    ? this.helpAudio
                    : "",
      helpPictures:this.helpImage != "" &&
                  this.helpImage != null &&
                  this.helpImage != "{}"
                    ? this.helpImage
                    : "{}",
      helpDescription: this.selectedPopularService.helpTitle,
      is_paid_help: true,
      is_standard: false,
      standard_hr_lang:this.selectedLanguage,
      is_popular_service:this.selectedPopularService.listingId != -999?true:false,
      referred_by:this.selectedPopularService.emitraId
    }
    if(!username){
      let dialogRef = this.dialog.open(OtpDialog, {
        width: '600px',
        height: '540px',
        panelClass:'custom-panel-class3',
        disableClose: true,
        data:{userDetails:userDetails}
      });
      dialogRef.afterClosed().subscribe(response=>{
        if(response && response.helpid){
          const statusValue = '0';
          this.router.navigate(["/help-request/getHelp-RequestbyId"], {
            queryParams: {
              Id: response.helpid,
              pageNo: "1",
              status: statusValue,
              source: "help",
              isPaidType: "true"
            },
          });
          this.googleAnalyticsService.eventEmitter2('ja_create_hr',{});
          (window as any).hj('event', 'ja_create_hr');
          localStorage.setItem('create_paid_help_id',response.helpid);
          if(this.authService.authenticated){
            this.dataService.updateNewHridAndTimestamp(this.commonService.getUsername(),
            response.helpid);
          }
        }
      })
    }
    else{
      this.IsLoader = true;
      delete userDetails.code;
      delete userDetails.phone_no;
      // delete userDetails.referred_by;
      this.dataService.createHelpRequestv2(userDetails).subscribe(response=>{
        const statusValue = '0';
        this.router.navigate(["/help-request/getHelp-RequestbyId"], {
          queryParams: {
            Id: response.result.helpid,
            pageNo: "1",
            status: statusValue,
            source: "help",
            isPaidType: "true"
          },
        });
        this.googleAnalyticsService.eventEmitter2('ja_create_hr',{});
        (window as any).hj('event', 'ja_create_hr');
        const time = Date.now();
        localStorage.setItem("paid_help_creation_time",time.toString());
        localStorage.setItem('create_paid_help_id',response.result.helpid);
        if(this.authService.authenticated){
          this.dataService.updateNewHridAndTimestamp(this.commonService.getUsername(),
          response.result.helpid);
        }
        this.IsLoader = false;
      },error=>{
        this.IsLoader = false;
      })
    }
  }

  onRequestDetailInfo=()=>{
    const header = this.languageService.getTranslated('requestDetailHeader');
    const info = this.languageService.getTranslated('requestDetailInfo');
    const data={
      header:header,
      infoMessage:info
    }
    let dialogRef = this.dialog.open(InfoDialog, {
      width: '600px',
      height: '320px',
      panelClass:'custom-panel-class4',
      disableClose: true,
      data:data
    });
  }

  onYourDetailInfo=()=>{
    const header = this.languageService.getTranslated('yourDetailHeader');
    const info = this.languageService.getTranslated('yourDetailInfo');
    const data={
      header:header,
      infoMessage:info
    }
    let dialogRef = this.dialog.open(InfoDialog, {
      width: '600px',
      height: '320px',
      panelClass:'custom-panel-class4',
      disableClose: true,
      data:data
    });
  }

  onAddAttachment(){
    let dialogRef = this.dialog.open(MediaDialog, {
      width: '600px',
      height: '320px',
      panelClass:'custom-panel-class4',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(response=>{
      if(response && response.audio){
        this.mediaAudioBtn.nativeElement.click();
      }
      if(response && response.video){
        // this.videoOptionSelected = true;
        this.selectAttachOption(2);
        setTimeout(() => {
          this.mediaVideoBtn.nativeElement.click();
        }, 100);
      }
      if(response && response.picture){
        // this.imageOptionSelected = true;
        this.selectAttachOption(1);
        setTimeout(() => {
          this.mediaImageBtn.nativeElement.click();
        }, 100);
      }
    })
  }
}

@Component({
  selector: 'media-dialog',
  templateUrl: 'media-dialog.component.html',
  styleUrls: ['create-help-request.component.scss']
})
export class MediaDialog {
  constructor( public dialogRef: MatDialogRef<MediaDialog>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      
  }

  onClickAudio(){
    this.dialogRef.close({audio:true});
  }
  onClickPicture(){
    this.dialogRef.close({picture:true});
  }
  onClickVieo(){
    this.dialogRef.close({video:true});
  }
  onClose(){
    this.dialogRef.close(false);
  }
}


@Component({
  selector: 'info-dialog',
  templateUrl: 'info-dialog.component.html',
  styleUrls: ['create-help-request.component.scss']
})
export class InfoDialog {
  header:string="";
  infoMessage:string="";
  constructor( public dialogRef: MatDialogRef<InfoDialog>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      if(data){
        this.header = data.header;
        this.infoMessage = data.infoMessage;
      }
  }
  onClose(){
    this.dialogRef.close(false);
  }
}