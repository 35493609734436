<div class="container-fluid" style="height:168px; position:relative;">
    <div class="row">
        <div class="col-12 referral-text text-center" style="position: absolute; top: 41px; width: 100%;">
            {{'referral_issue_1'|translate}}
        </div>
    </div>
    <div class="row justify-content-around" style="width:100%; bottom:0px;position: absolute;">
        <div class="col-auto">
            <button class="close-btn" (click)="onClose()">
                {{'okay'|translate}}
            </button>
        </div>
    </div>
</div>