<div class="container-fluid">
    <div class="scroll-content">
        <div class="pageheading">{{'terms_of_use' | translate}}</div>
        <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A.
            Acceptance of Terms</span></h3>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">PLEASE
            READ THE TERMS OF USE THOROUGHLY AND CAREFULLY. The terms and conditions set forth below (&quot;Terms of
            Use&quot;) constitute a legally-binding agreement between Swaniti Initiative (&quot;Jaano&quot;), and you. These
            Terms of Use contain provisions that define your limits, legal rights and obligations with respect to your use
            of and participation in (i) the Jaano website and mobile application, including the classified advertisements,
            forums, Internet links, and all content and Jaano services available through the domain and sub-domains of Jaano
            located at jaano.swaniti.org (collectively referred to herein as the &quot;Website&quot;), and (ii) the online
            transactions between those users of the Website who are offering services (each, a &quot;E-mitra&quot;) and
            those users of the Website who are obtaining services (each, a &quot;Paid Service Users&quot;) through the
            Website (such services, collectively, the &quot; Paid help requests&quot;). The Terms of Use described below
            incorporate the Privacy Policy and apply to all users of the Website, including users who are also contributors
            of video content, information, private and public messages, advertisements, and other materials or Services on
            the Website.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            Website is currently owned and operated by Swaniti Initiative.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            acknowledge that the Website serves as a venue for the online distribution and publication of user submitted
            information between E-mitra and Paid Service Users, and, by using, visiting, registering for, and/or otherwise
            participating in this Website, including the Services presented, promoted, and displayed on the Website, and by
            clicking on &quot;I have read and agree to the terms of use,&quot; you hereby certify that: (1) you are either
            aE-mitra or a prospective Paid Service Users, (2) you have the authority to enter into these Terms of Use, (3)
            you authorize the transfer of payment for Paid help requests requested through the use of the Website, and (4)
            you agree to be bound by all terms and conditions of these Terms of Use and any other documents incorporated by
            reference herein. If you do not so agree to the foregoing, you should not click to affirm your acceptance
            thereof, in which case you are prohibited from accessing or using the Website. If you do not agree to any of the
            provisions set forth in the Terms of Use, kindly discontinue viewing or participating in this Website
            immediately.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">YOU
            SPECIFICALLY AGREE THAT BY USING THE WEBSITE, YOU ARE AT LEAST 18 YEARS OF AGE AND YOU ARE COMPETENT UNDER LAW
            TO ENTER INTO A LEGALLY BINDING AND ENFORCEABLE CONTRACT.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">All
            references to &quot;you&quot; or &quot;your,&quot; as applicable, mean the person that accesses, uses, and/or
            participates in the Website in any manner. If you use the Website or open an Account (as defined below) on
            behalf of a business, you represent and warrant that you have the authority to bind that business and your
            acceptance of the Terms of Use will be deemed an acceptance by that business and &quot;you&quot; and
            &quot;your&quot; herein shall refer to that business.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            MODIFICATIONS TO TERMS OF USE AND/OR PRIVACY POLICY</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jaano
            reserves the right, in its sole discretion, to change, modify, or otherwise amend the Terms of Use, and any
            other documents incorporated by reference herein for complying with legal and regulatory framework and for other
            legitimate business purposes, at any time, and Jaano will post notice of the changes and the amended Terms of
            Use at the domain of&nbsp;</span><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 122, 183); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">jaano.swaniti.org/terms</span><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">.
            It is your responsibility to review the Terms of Use for any changes and you are encouraged to check the Terms
            of Use frequently. Your use of the Website following any amendment of the Terms of Use will signify your assent
            to and acceptance of any revised Terms of Use. If you do not agree to abide by these or any future Terms of Use,
            please do not use or access the Website</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            PRIVACY POLICY</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jaano
            has established a Privacy Policy that explains to users how their information is collected and used. The Privacy
            Policy is referenced above and hereby incorporated into the Terms of Use set forth herein. Your use of this
            Website is governed by the Privacy Policy.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            Privacy Policy is located at:&nbsp;</span><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 122, 183); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">jaano.swaniti.org/privacy</span><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">.</span>
    </p>
    <p><br></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">B.
            Membership and Accessibility</span></h3>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            LICENSE TO ACCESS</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jaano
            hereby grants you a non-exclusive, revocable license to use the Website as set forth in the Terms of Use;
            provided, however, that (i) you will not copy, distribute, or make derivative works of the Website in any medium
            without Jaano&rsquo;s prior written consent; (ii) you will not alter or modify any part of the Website other
            than as may be reasonably necessary to use the Website for its intended purposes; and (iii) you will otherwise
            act in accordance with the terms and conditions of the Terms of Use and in accordance with all applicable
            laws.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            MEMBERSHIP ELIGIBILITY CRITERIA</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Use
            of the Website is available only to individuals who are at least 18 years old and can form legally binding
            contracts under applicable law. You represent, acknowledge and agree that you are at least 18 years of age, and
            that: (a) all registration information that you submit is truthful and accurate, (b) you will maintain the
            accuracy of such information, and (c) your use of the Website and Services offered through this Website do not
            violate any applicable law or regulation. Your Account (defined below) may be terminated without warning if we
            at our discretion, believe that you are under the age of 18 or that you are not complying with any applicable
            laws, rules or regulations.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            need not register with Jaano to simply visit and view the Website, but to access and participate in certain
            features of the Website, you will need to create a OTP-protected account (&quot;Account&quot;). To create an
            account, you must submit your mobile number through the account registration page on the Website. You will also
            have the ability to provide additional optional information, which is not required to register for an account
            but may be helpful to Jaano in providing you with a more customized experience when using the Website.</span>
    </p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            shall be solely responsible for all activity that occurs on your Account and you shall notify Jaano immediately
            of any breach of security or any unauthorized use of your Account. Similarly, you shall never use another&apos;s
            Account without Jaano&rsquo;s permission. You agree that you will not misrepresent yourself or represent
            yourself as another user of the Website and/or the Services offered through the Website.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            hereby expressly acknowledge and agree that you yourself and not Jaano &nbsp;will be liable for your losses,
            damages etc. (whether direct or indirect) caused by an unauthorized use of your Account. Notwithstanding the
            foregoing, you may be liable for the losses of Jaano or others due to such unauthorized use.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">An
            Account holder is sometimes referred to herein as a &quot;Registered User.&quot;</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            acknowledge and agree that you shall comply with the following policies (the &quot;Account
            Policies&quot;):</span></p>
    <ul style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 8pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
                    will not copy or distribute any part of the Website in any medium without Jaano&apos;s prior written
                    authorization.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
                    will not alter or modify any part of the Website other than as may be reasonably necessary to use the
                    Website for its intended purpose.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
                    will provide true, accurate, current and complete information when creating your Account and you shall
                    maintain and update such information during the term of this Agreement so that it will remain accurate,
                    true, current and complete.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
                    shall not use any automated system, including but not limited to, &quot;robots,&quot;
                    &quot;spiders,&quot; &quot;offline readers,&quot; &quot;scrapers,&quot; etc., to access the Website for
                    any purpose without Jaano&apos;s prior written approval.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
                    shall not in any manual or automated manner collect E-mitras or Paid Service Userss information,
                    including but not limited to, names, addresses, phone numbers, or email addresses, copying copyrighted
                    text, or otherwise misuse or misappropriate Website information or content, including but not limited
                    to, use on a &quot;mirrored&quot;, competitive, or third party site.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
                    shall not in any way that transmits more request messages to the Jaano servers, or any server of a Jaano
                    subsidiary or affiliate, in a given period of time than a human can reasonably produce in the same
                    period by using a conventional online web browser; provided, however, that the operators of public
                    search engines may use spiders or robots to copy materials from the site for the sole purpose of
                    creating publicly available searchable indices of the materials, but not caches or archives of such
                    material. Jaano reserves the right to revoke these exceptions either generally or in specific
                    cases.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
                    shall not recruit, solicit, or contact in any form E-mitras or Paid Service Userss for employment or
                    contracting for a business not affiliated with Jaano without express written permission from
                    Jaano.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
                    shall not take any action that (i) unreasonably encumbers or, in Jaano&apos;s sole discretion, may
                    unreasonably encumber the Website&apos;s infrastructure; (ii) interferes or attempts to interfere with
                    the proper working of the Website or any third-party participation in the Website; or (iii) bypasses
                    Jaano&apos;s measures that are used to prevent or restrict access to the Website.</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 16pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
                    agree not to collect or harvest any personally identifiable data, including without limitation, names or
                    other Account information, from the Website, nor to use the communication systems provided by the
                    Website for any commercial solicitation purposes.</span></p>
        </li>
    </ul>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            ADDITIONAL POLICIES</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Your
            access to, use of, and participation in the Website is subject to the Terms of Use and all applicable Jaano
            regulations, guidelines and additional policies that Jaano may set forth from time to time, including without
            limitation, a copyright policy and any other restrictions or limitations that Jaano publishes on the Website
            (the &quot;Additional Policies&quot;). You hereby agree to comply with the Additional Policies and your
            obligations thereunder at all times. You hereby acknowledge and agree that if you fail to adhere to any of the
            terms and conditions of this Agreement or documents referenced herein, including the Account Policies,
            membership eligibility criteria or Additional Policies, Jaano, in its sole discretion, may terminate your
            Account at any time without prior notice to you as well as initiate appropriate legal proceedings, if
            necessary.</span></p>
    <p><br></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C.
            Member Conduct</span></h3>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            PROHIBITIONS ON SUBMITTED CONTENT</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            shall not upload, post, transmit, transfer, disseminate, distribute, or facilitate distribution of any content,
            including text, images, video, sound, data, information, or software, to any part of the Website, including your
            profile (&quot;Profile&quot;), the posting of your Service (&quot;Offer&quot;), the posting of your desired
            Service (&quot;Want&quot;), or the posting of any opinions or reviews in connection with the Website, the
            Service, the E-mitras, or the Paid Service Users (&quot;Feedback&quot;) (all of the foregoing content is
            sometimes collectively referred to herein as &quot;Submitted Content&quot; and the posting of Submitted Content
            is sometimes referred to as a &quot;Posting&quot; or as &quot;Postings&quot;) that:</span></p>
    <ul style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 8pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">misrepresents
                    the source of anything you post, including impersonation of another individual or entity or any false or
                    inaccurate biographical information for any E-mitras; provides or create links to external sites that
                    violate the Terms of Use; is intended to harm or exploit any individual under the age of 18
                    (&quot;Minor&quot;) in any way; is designed to solicit, or collect personally identifiable information
                    of any Minor, including, but not limited to, name, email address, home address, phone number, or the
                    name of his or her school;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">invades
                    anyone&apos;s privacy by attempting to harvest, collect, store, or publish private or personally
                    identifiable information, such as names, email addresses, phone numbers, passwords, account information,
                    credit card numbers, home addresses, or other contact information without their knowledge and willing
                    consent;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">contains
                    falsehoods or misrepresentations that could damage Jaano or any third party;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">is
                    pornographic, harassing, hateful, illegal, obscene, defamatory, libelous, slanderous, threatening,
                    discriminatory, racially, culturally or ethnically offensive; incites, advocates, or expresses
                    pornography, obscenity, vulgarity, profanity, hatred, bigotry, racism, or gratuitous violence;
                    encourages conduct that would be considered a criminal offense, give rise to civil liability or violate
                    any law; promotes racism, hatred or physical harm of any kind against any group or individual; contains
                    nudity, violence or inappropriate subject matter; or is otherwise inappropriate;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">is
                    copyrighted, protected by trade secret or otherwise subject to third-party proprietary rights, including
                    privacy and publicity rights, unless you are the owner of such rights or have permission from the
                    rightful owner to post the material and to grant Jaano all of the license rights granted herein;</span>
            </p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">contains
                    or promotes an illegal or unauthorized copy of another person&apos;s copyrighted work, such as pirated
                    computer programs or links to them, information to circumvent manufacture installed copy-protection
                    devices, pirated music or links to pirated music files, or lyrics, guitar tabs or sheet music, works of
                    art, teaching tools, or any other item the copy, display, use, performance, or distribution of which
                    infringes on another&apos;s copyright, intellectual property right, or any other proprietary
                    right;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">is
                    intended to threaten, stalk, defame, defraud, degrade, victimize, or intimidate an individual or group
                    of individuals for any reason on the basis of age, gender, disability, ethnicity, sexual orientation,
                    race, or religion; or to incite or encourage anyone else to do so;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">intends
                    to harm or disrupt another user&apos;s computer or would allow others to illegally access software or
                    bypass security on websites or servers, including but not limited, to spamming; impersonates, uses the
                    identity of, or attempts to impersonate a Jaano employee, agent, manager, host, another user, or any
                    other person though any means;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">advertises
                    or solicits a business not related to or appropriate for the Website (as determined by Jaano in its sole
                    discretion);</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">contains
                    or could be considered &quot;junk mail&quot;, &quot;spam&quot;, &quot;chain letters&quot;, &quot;pyramid
                    schemes&quot;, &quot;affiliate marketing&quot;, or unsolicited commercial advertisement;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">contains
                    advertising for ponzi schemes, discount cards, credit counseling, online surveys or online
                    contests;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">distributes
                    or contains viruses or any other technologies that may harm Jaano, or the interests or property of Jaano
                    users</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">contains
                    links to commercial services or websites, except as allowed pursuant to the Terms of Use;is non-local or
                    irrelevant content;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 16pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">contains
                    identical content to other open Postings you have already posted; or uses any form of automated device
                    or computer program that enables the submission of Postings without the express written consent of
                    Jaano</span></p>
        </li>
    </ul>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            PROHIBITIONS ON SENDING MESSAGES</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            will not send messages to other users containing:</span></p>
    <ul style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 8pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">offers
                    to make national or international money transfers for amounts exceeding the asking price of a service,
                    with intent to request a refund of any portion of the payment; or</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 16pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">unsolicited
                    advertising or marketing of a service not offered on the Website or an external website.</span></p>
        </li>
    </ul>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            NO DISCRIMINATION</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            Discriminatory Postings. Indian laws prohibit any preference, limitation or discrimination based on race, color,
            religion, sex, national origin, age, handicap or other protected class. Jaano will not knowingly accept any
            Posting which is in violation of the law. Jaano has the right, in its sole discretion and without prior notice
            to you; to immediately remove any posting that discriminates or is any way in violation of any law.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            PROHIBITIONS WITH RESPECT TO SERVICES</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">While
            using the Website, you shall not:</span></p>
    <ul style="margin-top: 0px; margin-bottom: 0px; padding-inline-start: 48px;">
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 8pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">post
                    content or items in any inappropriate category or areas on the Website;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">violate
                    any laws, third-party rights, Account Policies, or any provision of the Terms of Use, such as the
                    prohibitions described above;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">fail
                    to deliver payment for Services purchased by you, unless the E-mitras has materially changed the
                    description of the Service description after you negotiate an agreement for such Service, a clear
                    typographical error is made, or you cannot authenticate the E-mitras identity;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">fail
                    to perform Services purchased from you, unless the Paid Service Users fails to materially meet the terms
                    of the mutually agreed upon agreement for the Services, refuses to pay, a clear typographical error is
                    made, or you cannot authenticate the Paid Service User &nbsp;identity; manipulate the price of any
                    Service or interfere with other users&apos; Postings;</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">circumvent
                    or manipulate our fee structure, the billing process, or fees owed to Jaano; post false, inaccurate,
                    misleading, defamatory, or libelous content (including personal information about any Website
                    user);</span></p>
        </li>
        <li aria-level="1" dir="ltr"
            style="list-style-type: disc; font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre;">
            <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 0pt; margin-bottom: 16pt;"><span
                    style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">take
                    any action that may undermine the Feedback or ratings systems (such as displaying, importing or
                    exporting Feedback information off of the Website or using it for purposes unrelated to the
                    Website)</span></p>
        </li>
    </ul>
    <p dir="ltr" style="line-height: 1.38; text-align: justify; margin-top: 8pt; margin-bottom: 16pt;"><span
            style="font-size: 10.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><span
                class="Apple-tab-span" style="white-space: pre;">&nbsp; &nbsp;&nbsp;</span></span><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            FEEDBACK</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">As
            a participant in the Website, you agree to use careful, prudent, and good judgment when leaving Feedback for
            another user. The following actions constitute inappropriate uses of Feedback: (a) threatening to leave negative
            or impartial Feedback for another user unless that user provides services not included in the original Posting
            or not agreed to as part of the Service to be provided; (b) leaving Feedback in order to make the E-mitras or
            Paid Service Users appear better than he or she actually is or was; and (c) including conditions in an Offer or
            Want that restrict a E-mitras or a Paid Service Users from leaving Feedback.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            Sanctions for Inappropriate Use of Feedback. If you violate any of the above-referenced rules in connection with
            leaving Feedback, Jaano, in its sole discretion, may take any of the following actions: (i) cancel your Feedback
            or any of your Postings; (ii) limit your Account privileges; (iii) suspend your Account; and/or (iv) decrease
            your status earned via the Feedback page.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            Reporting Inappropriate Use of Feedback. You may contact Jaano regarding any inappropriate use of Feedback
            via-email at help@swaniti.in.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            Resolving Disputes in Connection with Feedback. In the event of any dispute between users of the Website
            concerning Feedback, Jaano shall be the final arbiter of such dispute. Further, IN THE EVENT OF ANY DISPUTE
            BETWEEN USERS OF THE WEBSITE CONCERNING FEEDBACK, Jaano HAS THE RIGHT, IN ITS SOLE AND ABSOLUTE DISCRETION, TO
            REMOVE SUCH FEEDBACK OR TAKE ANY ACTION IT DEEMS REASONABLE WITHOUT INCURRING ANY LIABILITY THEREFROM.</span>
    </p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            foregoing lists of prohibitions provide examples and are not complete or exclusive. Jaano reserves the right to
            (a) terminate your access to your Account, your ability to post to this Website (or the Services) and (b)
            refuse, delete or remove, move or edit the content, in whole or in part, of any Postings; with or without cause
            and with or without notice, for any reason or no reason, or for any action that Jaano determines is
            inappropriate or disruptive to this Website or to any other user of this Website and/or Services. Jaano reserves
            the right to restrict the number of e-mails or other messages that you are allowed to send to other users to a
            number that Jaano deems appropriate in Jaano&apos;s sole discretion. Jaano may report to law enforcement
            authorities any actions that may be illegal, and any reports it receives of such conduct. When legally required
            or at Jaano&apos;s discretion, Jaano will cooperate with law enforcement agencies in any investigation of
            alleged illegal activity on this Website or on the Internet. Jaano does not and cannot review every Posting
            posted to the Website. These prohibitions do not require Jaano to monitor, police or remove any Postings or
            other information submitted by you or any other user.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">D.
            Rules for E-Mitras</span></h3>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            PROFILES AND OFFERS MUST NOT BE FRAUDULENT</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Subject
            to any exceptions set forth in these Terms of Use or Additional Policies, if any, E-mitras shall not: (a) list
            Services or offers relating to any Service in a category that is inappropriate to the Service they are offering;
            (b) misrepresent the location at which they will provide a Service; (c) include brand names or other
            inappropriate keywords in their Profile, Offer, Want, Feedback, or any other title or description relating to a
            Service; (d) use misleading titles that do not accurately describe the Service; or (e) include any information
            in their Profile that is fraudulent.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            PROFILES AND OFFERS CANNOT USE TECHNIQUES TO AVOID OR CIRCUMVENT JAANO FEES</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Subject
            to any exceptions set forth in these Terms of Use or Additional Policies, if any, E-mitras shall not: (a) offer
            a catalog or a link to a third-party website from which Paid Service Users or any Registered User or user of the
            Website may obtain the Service directly; (b) exceed multiple Posting limits; (c) post a single Service but offer
            additional identical services in the Service description; (d) charge fees for traveling further than desired to
            provide the Service; (e) offer the opportunity through Jaano to purchase the Service or any other service
            outside of Jaano; (f) use their Profile page or user name to promote services not offered on or through the
            Website and/or prohibited services.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">In
            case we realize that any E-mitra is involved in any of the above activities, Jaano holds the sole discretion to
            blacklist the E-mitra and withhold any outstanding credits or payments to the E-mitra.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            SANCTIONS FOR VIOLATING ANY OF THE RULES FOR E-MITRA</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If
            an E-mitra violates any of the above-referenced rules in connection with his or her Posting, Jaano, in its sole
            discretion, may take any of the following actions: (a) cancel the Posting; (b) limit the E-mitra&apos;s Account
            privileges; (c) suspend the E-mitra&rsquo;s Account; (d) cause the E-mitra to forfeit any fees earned on a
            cancelled Posting; and/or (e) decrease the E-mitra&apos;s status earned via the Feedback page.</span></p>
    <p><br></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">E.
            Rules for Paid Service Users</span></h3>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            PAID SERVICE USERS SHALL NOT TAKE ANY OF THE FOLLOWING ACTIONS:</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(a)
            commit to purchasing or using a Service without paying; (b) sign up for, negotiate a price for, use, or
            otherwise solicit a Service with no intention of following through with your use of or payment for the Service;
            (c) agree to purchase a Service when you do not meet the E-mitra&apos;s terms as outlined in the Posting, or
            agree to purchase a Service with the intention of disrupting a Posting; or (d) misuse any options made available
            now or in the future by Jaano in connection with the use or purchase of any Service.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            SANCTIONS FOR VIOLATING ANY OF THE RULES FOR PAID SERVICE USERS</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If
            a Paid Service User violates any of the above-referenced rules in connection with his or her Posting, Jaano, in
            its sole discretion, may take any of the following actions: (a) cancel the Posting; (b) limit the Paid Service
            User&apos;s Account privileges; (c) suspend the Paid Service User&apos;s Account; and/or (d) decrease the Paid
            Service User&apos;s status earned via the Feedback page.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">F.
            Use of Submitted Content</span></h3>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            NO CONFIDENTIALITY</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            Website may now or in the future permit the submission of videos or other communications submitted by you and
            other users, including without limitation, your Profile, your Offer, your Wants, any Feedback, and all Submitted
            Content, and the hosting, sharing, and/or publishing of such Submitted Content. You understand that whether or
            not such Submitted Content is published, Jaano does not guarantee any confidentiality with respect to any
            Submitted Content.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            agree that any Submitted Content provided by you for which you authorize to be searchable by Registered Users
            who have access to the Website is provided on a non-proprietary and non confidential basis. You agree that Jaano
            shall be free to use or disseminate such freely searchable Submitted Content on an unrestricted basis for the
            purpose of providing the Services.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            are aware that any information provided by you in the Submitted Content towards locating a professional who
            ordinarily is bound to maintain confidentiality under law with his/her client (i.e. a doctor or a lawyer) is not
            extended to Jaano.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jaano
            may also disclose user information including personal information if Jaano reasonably believes that disclosure
            (i) is necessary in order to comply with a legal process (such as a court order, search warrant, etc.) or other
            legal requirement of any governmental authority, (ii) would potentially mitigate Jaano&rsquo;s liability in an
            actual or potential lawsuit, (iii) is otherwise necessary or appropriate to protect our rights or property, or
            the rights or property of any person or entity, (iv) to enforce this Agreement (including, but not limited to
            ensuring payment of fees by users), or (v) as may be required or necessary to deter illegal behavior (including,
            but not limited to, fraud).</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            YOUR REPRESENTATIONS AND WARRANTIES</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            shall be solely responsible for your own Submitted Content and the consequences of posting or publishing it. In
            connection with Submitted Content, you affirm, represent, and/or warrant that: (a) you own or have the necessary
            licenses, rights, consents, and permissions to use and authorize Jaano to use all patent, trademark, trade
            secret, copyright or other proprietary rights in and to any and all Submitted Content to enable inclusion and
            use of the Submitted Content in the manner contemplated by the Website and these Terms of Use; and (b) you have
            the written consent, release, and/or permission of each and every identifiable individual person in the
            Submitted Content to use the name or likeness of each and every such identifiable individual person to enable
            inclusion and use of the Submitted Content in the manner contemplated by the Website and these Terms of Use. You
            agree to pay for all royalties, fees, and any other monies owing any person by reason of any Submitted Content
            posted by you to or through the Website.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            YOUR OWNERSHIP RIGHTS AND LICENSE TO JAANO</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            retain all of your ownership rights in your Submitted Content. However, by submitting the Submitted Content to
            Jaano for posting on the Website, you hereby grant, and you represent and warrant that you have the right to
            grant, to Jaano a perpetual, worldwide, non-exclusive, royalty-free, sublicense able and transferable license to
            link to, use, reproduce, distribute, reformat, translate, prepare derivative works of, display, and perform the
            Submitted Content in connection with the Website and Jaano&apos;s (and its successor&apos;s) business
            operations, including without limitation, for the promotion and redistribution of any part or all of the
            Website, and any derivative works thereof, in any media formats and through any media channels. You also hereby
            grant each user of the Website a non-exclusive license to access your Submitted Content through the Website, and
            to use, reproduce, distribute, prepare derivative works of, display and perform such Submitted Content as
            permitted through the functionality of the Website and under these Terms of Use. The foregoing license granted
            by you terminates once you remove or delete the Submitted Content from the Website.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            acknowledge and understand that the technical processing and transmission of the Website, including your
            Submitted Content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to
            technical requirements of connecting networks or devices.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            may remove your Submitted Content from the Website at any time. If you choose to remove your Submitted Content,
            the license granted above will automatically expire.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">4.
            JAANO&apos;S DISCLAIMERS AND RIGHT TO REMOVE</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            Jaano does not endorse any Submitted Content or any opinion, recommendation, or advice expressed therein, and
            Jaano expressly disclaims any and all liability in connection with all Submitted Content. Jaano does not permit
            copyright infringing activities and infringement of intellectual property rights on the Website, and Jaano will
            remove any Data (as defined below) or Submitted Content if properly notified, pursuant to the &quot;take
            down&quot; notification procedure described in Section J below, that such Posting or Submitted Content infringes
            on another&apos;s intellectual property rights. Jaano reserves the right to remove any Data or Submitted Content
            without prior notice. Jaano will also terminate a user&apos;s access to the Website, if he or she is determined
            to be a repeat infringer. A repeat infringer is a Website user who has been notified of infringing activity more
            than twice and/ or has had Submitted Content removed from the Website more than twice. Jaano also reserves the
            right, in its sole and absolute discretion, to decide whether any Data or Submitted Content is appropriate and
            complies with these Terms of Use for all violations, in addition to copyright infringement and violations of
            intellectual property law, including, but not limited to, pornography, obscene or defamatory material, or
            excessive length. Jaano may remove such Submitted Content and/or terminate a user&apos;s access for uploading
            such material in violation of these Terms of Use at any time, without prior notice and in its sole
            discretion.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            You acknowledge and understand that when using the Website, you will be exposed to Submitted Content from a
            variety of sources, and that Jaano is not responsible for the accuracy, usefulness, safety, or intellectual
            property rights of or relating to such Submitted Content. You further acknowledge and understand that you may be
            exposed to Submitted Content that is inaccurate, offensive, indecent, or objectionable, and you agree to waive,
            and hereby do waive, any legal or equitable rights or remedies you have or may have against Jaano with respect
            thereto, and agree to indemnify and hold Jaano, its owners, members, managers, operators, directors, officers,
            agents, affiliates, and/or licensors, harmless to the fullest extent allowed by law regarding all matters
            related to your use of the Website.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            You are solely responsible for the photos, profiles and other content, including, without limitation, Submitted
            Content that you publish or display on or through the Website, or transmit to other Website users. You
            understand and agree that Jaano may, in its sole discretion and without incurring any liability, review and
            delete or remove any Submitted Content that violates this Agreement or which might be offensive, illegal, or
            that might violate the rights, harm, or threaten the safety of Website users or others.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">5.
            SUGGESTIONS.</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If
            you send or transmit any communications, comments, questions, suggestions, or related materials to Jaano,
            whether by letter, email, telephone, or otherwise (collectively, &quot;Suggestions&quot;), suggesting or
            recommending changes to the Website, including, without limitation, new features or functionality relating
            thereto, all such Suggestions are, and will be treated as, non-confidential and non-proprietary. You hereby
            assign all right, title, and interest in, and Jaano is free to use, without any attribution or compensation to
            you, any ideas, know-how, concepts, techniques, or other intellectual property and proprietary rights contained
            in the Suggestions, whether or not patentable, for any purpose whatsoever, including but not limited to,
            developing, manufacturing, having manufactured, licensing, marketing, and selling, directly or indirectly,
            products and services using such Suggestions. You understand and agree that Jaano is not obligated to use,
            display, reproduce, or distribute any such ideas, know-how, concepts, or techniques contained in the
            Suggestions, and you have no right to compel such use, display, reproduction, or distribution or seek
            recognition if the Suggestions are in fact implemented.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">J.
            Copyright Infringement Take Down Procedure</span></h3>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jaano
            has high regard for intellectual property and expects the same level of standard to be employed by its users.
            Jaano may, in appropriate circumstances and at its discretion, terminate the Account or prohibit access to the
            Website of users who infringe upon the intellectual property rights of others.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If
            you believe that your work has been copied and posted on the Website in a way that constitutes copyright
            infringement and/or trademark infringement, please send the following information to us at help@Jaano.com</span>
    </p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(i)
            identification of the copyrighted and/or trademarked work claimed to have been infringed, or, if multiple works
            at a single online site are covered by a single notification, a representative list of such works at that
            site;</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(ii)
            identification of the material that is claimed to be infringing or to be the subject of infringing activity and
            that is to be removed or access to which is to be disabled at the Website, and information reasonably sufficient
            to permit Jaano to locate the material.;</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(iii)
            a written statement that you have a good faith belief that the disputed use is not authorized by the copyright
            and/or trademark owner, its agent, or the law;</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(iv)
            information reasonably sufficient to permit Jaano to contact you as the complaining party, such as an address,
            telephone number, and, if available, an electronic mail address at which you may be contacted;</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(v)
            an electronic or physical signature of the person authorized to act on behalf of the owner of an exclusive
            interest that is allegedly infringed; and</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">(vi)
            a statement by you, made under penalty of perjury, that the information in your report is accurate and that you
            are the owner of the exclusive right or authorized to act on the behalf of the owner of the exclusive right. A
            statement by you comprised of the foregoing points is referred to herein as the &quot;Notice.&quot;</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: italic; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Only
            the intellectual property rights owner is permitted to report potentially infringing items through Jaano&apos;s
            reporting system set forth above. If you are not the intellectual property rights owner, you should contact the
            intellectual property rights owner and they can choose whether to use the procedures set forth in these Terms of
            Use.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">K.
            Modifications to or Termination of Website</span></h3>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            MODIFICATION OR CESSATION OF WEBSITE</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jaano
            reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the
            Website (or any part thereof) with or without notice and in its sole discretion. You agree that Jaano shall not
            be liable to you or to any third party for any modification, suspension or discontinuance of Jaano
            services.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            TERMINATION BY Jaano</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            hereby acknowledge and agree that Jaano, in its sole and absolute discretion, has the right (but not the
            obligation) to delete, terminate, or deactivate your Account, block your email or IP address, cancel the Website
            or otherwise terminate your access to or participation in the use of the Website (or any part thereof), or
            remove and discard any Submitted Content on the Website (&quot;Termination of Service&quot;), immediately and
            without notice, for any reason, including without limitation, Account inactivity or if Jaano believes or has
            reason to believe that you have violated any provision of the Terms of Use.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            TERMINATION BY YOU</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            may cancel your use of the Website and/or terminate the Terms of Use with or without cause at any time by
            following the link in your Account under &quot;Account Preferences&quot; to &quot;Deactivate
            Account.&quot;</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">4.
            EFFECT OF TERMINATION</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Upon
            termination of your Account, your right to participate in the Website, including, but not limited to, your right
            to offer or purchase Services and your right to receive any fees or compensation, including, without limitation,
            referral discounts, incentive bonuses, or other special offer rewards, shall automatically terminate. You
            acknowledge and agree that your right to receive any fees or compensation hereunder is conditional upon your
            proper use of the Website, your adherence to the Terms of Use, the continuous activation of your Account, and
            your permitted participation in the Website. In the event of Termination of Service, your Account will be
            disabled and you may not be granted access to your Account or any files or other data contained in your Account.
            Notwithstanding the foregoing, residual data may remain in the Jaano system.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Unless
            Jaano has previously cancelled or terminated your use of the Website (in which case subsequent notice by Jaano
            shall not be required), if you provided a valid email address during registration, Jaano will notify you via
            email of any such termination or cancellation, which shall be effective immediately upon Jaano&apos;s delivery
            of such notice.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Upon
            Termination of Service, the following shall occur: all licenses granted to you hereunder will immediately
            terminate; and you shall promptly destroy all copies of Jaano Data (as defined below), Marks (as defined below)
            and other content in your possession or control. You further acknowledge and agree that Jaano shall not be
            liable to you or any third party for any termination of your access to the Website. Upon Termination of Service,
            Jaano retains the right to use any data collected from your use of the Website for internal analysis and
            archival purposes, and all related licenses you have granted Jaano hereunder shall remain in effect for the
            foregoing purpose. In no event is Jaano obligated to return any Submitted Content to you. Sections K, L, M, N,
            O, Q, R, S, T, U, V, W, and X, shall survive expiration or termination of the Website or your Account.</span>
    </p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            agree to indemnify and hold Jaano, and its officers, managers, members, affiliates, successor, assigns,
            directors, agents, E-mitras, suppliers, and employees harmless from any claim or demand, including reasonable
            attorneys&apos; fees and court costs, made by any third party due to or arising out of the Termination of
            Service.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">L.
            Intellectual Property Rights</span></h3>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            Jaano OWNS OR HOLDS THE LICENSES TO ALL DATA AND MARKS ON THE WEBSITE</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            content on the Website (exclusive of all Submitted Content), including without limitation, the text, software,
            scripts, graphics, photos, sounds, music, videos, interactive features and the like (&quot;Data&quot;) and the
            trademarks, service marks and logos contained therein (&quot;Marks&quot;), are owned by Jaano. Other trademarks,
            names and logos on this Website are the property of their respective owners.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Data
            on the Website is provided to you AS IS for your information and personal use only and may not be used, copied,
            reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other
            purposes whatsoever without the prior written consent of the respective owners. Jaano reserves all rights not
            expressly granted in and to the Website and the Data. You agree not to use, copy, or distribute, any of the Data
            other than as expressly permitted herein, including any use, copying, or distribution of Submitted Content
            obtained through the Website for any commercial purposes. If you download or print a copy of the Data for
            personal use, you must retain all copyright and other proprietary notices contained thereon. You agree not to
            circumvent, disable or otherwise interfere with security features of the Website or features that prevent or
            restrict use or copying of any Data or enforce limitations on use of the Website or the Data therein.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            Jaano&apos;S LICENSE TO YOU FOR THE USE OF DATA AND MARKS</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            Website contains Jaano&apos;s Data and Marks, which are, or may become, protected by copyright, trademark,
            patent, trade secret and other laws, and Jaano owns and retains all rights in the Jaano Data and Marks. Subject
            to these Terms of Use, Jaano hereby grants you a limited, revocable, nontransferable, nonsublicensable license
            to reproduce and display the Jaano Data (excluding any software source code) solely for your personal use in
            connection with accessing and participating in the Website.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            Website may also contain Data of other users or licensors, which you shall not copy, modify, translate, publish,
            broadcast, transmit, distribute, perform, display, or sell.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">M.
            Jaano Fees</span></h3>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            FEES INCURRED BY E-mitraS</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Joining
            Jaano, opening an Account, posting Services and viewing posted Services is free. Jaano reserves the right at its
            sole discretion to charge fees to E-mitras for other services that Jaano may provide in the future.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jaano
            may also in certain categories require E-mitras to make payments for following up on job leads i.e. requests
            posted by Paid Service Users, and permitting the E-mitra to contact the Paid Service User (&ldquo;Lead
            Fee&rdquo;) irrespective of whether the job leads eventually result in a transaction or exchange of
            consideration for the service requested. In order to be eligible to make such Lead Fee payment, E-mitras shall
            be required to make advance payments (&ldquo;Credits&rdquo;) from which the Lead Fee may be deducted as and when
            necessary. Whenever the Credits fall below the prescribed threshold, the E-mitra shall be prompted to refill the
            Credits in order to continue enjoying the ability to follow up on leads and contacting Paid Service
            Users.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            E-mitra is entitled to refund of credits that is credited back in their wallet in the following
            conditions:</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            Refunds are processed only when a customer has cancelled the request and not viewed your profile</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            Refunds are processed if the customer has not viewed quote and not cancelled request 4 days after your
            response</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">In
            the event, (i) the job lead is identified to be non-genuine, or (ii) the Paid Service User within 48 hours of
            posting a request on the Website withdraws the same by way of the Website or the mobile application only, or
            (iii) the E-mitra pays the Lead Fee to respond to the Paid Service User&rsquo;s request for service but the Paid
            Service User does not open or access the E-mitra&rsquo;s response, the Lead Fee shall be reimbursed to the
            E-mitra. The decision of Jaano pertaining to the above shall be final and binding.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Jaano
            offers premium services to E-mitras for a fee and may in the future offer additional services, like tax
            preparation and bookkeeping that E-mitras can also choose to purchase. Jaano reserves the right to charge fees
            for these services at its sole discretion.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            FEES INCURRED BY PAID SERVICE USERS</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Joining
            Jaano, opening an Account, viewing posted Services, and bidding on posted Services is free. Jaano currently does
            not charge Paid Service Users for transactions completed on the Website between Paid Service Users and Services
            Professionals. However, Jaano reserves the right to charge a fee to Paid Service Users in the future on a
            per-transaction basis or in any other manner, and reserves the right to do so in its sole discretion. Changes to
            this Fee Policy are effective after Jaano has provided you with thirty (30) days&apos; notice by posting the
            changes on the Website.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If
            applicable, you agree to pay all fees or charges to your Account based on Jaano&apos;s fees, charges, and
            billing terms then in effect. If you do not pay on time or if Jaano cannot charge your credit card, any payment
            gateway or other payment method for any reason, Jaano reserves the right to either suspend or terminate your
            access to the Website and Account and terminate these Terms of Use. You are expressly agreeing that Jaano is
            permitted to bill you for the applicable fees, any applicable tax and any other charges you may incur in
            connection with your use of this Website and the fees will be billed to your credit card, a payment gateway or
            other payment method designated at the time you make a purchase or register for a fee-based service. If you
            cancel your Account at any time, you will not receive any refund. If you have a balance due on any account, you
            agree that Jaano may charge such unpaid fees to your credit card or otherwise bill you for such unpaid fees.
            Jaano reserves its right to initiate appropriate legal proceedings, if necessary, in its sole discretion, to
            recover such outstanding amounts.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            TAXES</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            understand that we are acting solely as an intermediary for the collection of rents and fees between a Paid
            Service User and a E-mitra who choose to enter into an Agreement for Service. Because state and local tax laws
            vary significantly by locality, you understand and agree that you are solely responsible for determining your
            own tax reporting requirements in consultation with tax advisors, and that we cannot and do not offer tax advice
            to either hosts or guests. Further, you understand that Jaano shall not be responsible or liable in any manner
            in relation to tax liability of a Paid Service User or a E-mitra.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">4.
            REFUND POLICY</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">All
            commissions payable to Emitra by Paid Service users through Jaano on consummated transactions, i.e. exchange of
            services for consideration, are final and non-refundable.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">All
            money paid by Paid Service users to Jaano for availing service by Emitra &nbsp;are non-interest bearing and
            shall be refunded to the Paid Service Users if they remain un-utilised for a period greater than 364 days only.
            Such amounts shall not be refunded prior to that.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If
            poor customer service or inappropriate behaviour with customers results in the E-mitrs being de-listed from the
            Jaano Admin platform, then all credits (purchased or promotional) will have to forfeited, and there will be no
            refund made in such a case.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Once
            the paid service user receives the confirmation documents related to service requested from E-mitra, then the
            money paid by the paid service user will not be refunded in any circumstances</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">N.
            Negotiation of Terms of Service; Disputes Between Registered Users</span></h3>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            NEGOTIATION WORKSHEET AND CONTRACT TEMPLATE</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">As
            a courtesy to Registered Users, to facilitate the negotiation and confirmation of the Agreement for Service,
            Jaano provides a general framework for negotiating the terms of Service (e.g., rate) (&quot;Negotiation
            Worksheet&quot;). Registered Users acknowledge and agree that (i) they are solely responsible for addressing all
            issues that exist now or may arise in the future in connection with the applicable Service; and (ii) it is
            solely up to such Registered Users, if they so desire, to enter into a signed, written contract, that addresses
            all of the relevant issues and memorializes the agreed upon Negotiation Worksheet.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            should not rely on the any information or resources contained on the Website, including, without limitation, the
            Negotiation Worksheet, as a replacement or substitute for any professional, financial, legal or other advice or
            counsel. Jaano makes no representations and warranties, and expressly disclaims any and all liability,
            concerning actions taken by a user following the information or using the resources offered or provided on or
            through the Websites, including, without limitation, the Negotiation Worksheet. In no way will Jaano be
            responsible for any actions taken or not taken based on the information or resources provided on this Website.
            If you have a situation that requires professional advice, you should consult a qualified specialist. Do not
            disregard, avoid or delay obtaining professional advice from a qualified specialist because of information or
            resources that are provided on this Website, however provided.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            Jaano IS NOT A PARTY TO ANY SERVICE CONTRACT</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Each
            Registered User hereby acknowledges and agrees that Jaano is NOT a party to any oral or written Agreement for
            Service, Negotiation Worksheet, or any contract entered into between Registered Users in connection with any
            Service offered, directly or indirectly, through the Website. Each Registered User acknowledges, agrees and
            understands that Jaano only seeks to provide a platform wherein the Paid Service User and E-mitra can be brought
            together and Jaano itself has not role in the execution or provision of Services.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            NO AGENCY OR PARTNERSHIP</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">No
            agency, partnership, joint venture, or employment is created as a result of the Terms of Use or your use of any
            part of the Website, including without limitation, the Negotiation Worksheet or Agreement for Service. You do
            not have any authority whatsoever to bind Jaano in any respect. All E-mitras are independent contractors.
            Neither Jaano nor any users of the Website may direct or control the day-to-day activities of the other, or
            create or assume any obligation on behalf of the other.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">4.
            DISPUTES BETWEEN REGISTERED USERS</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Subject
            to the provisions regarding disputes between Website participants in connection with Feedback, your interactions
            with individuals and/or organizations found on or through the Website, including payment of and performance of
            any Service, and any other terms, conditions, warranties or representations associated with such transactions or
            dealings, are solely between you and such individual or organization. You should take reasonable precautions and
            make whatever investigation or inquiries you deem necessary or appropriate before proceeding with any online or
            offline transaction with any third party, including without limitation, E-mitras and Paid Service Users.</span>
    </p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            understand that deciding whether to use the Services of a E-mitra or provide Services to a Paid Service User or
            use information contained in any Submitted Content, including, without limitation, Postings, Offers, Wants
            and/or Feedback, is your personal decision for which alone are responsible. You understand that Jaano does not
            warrant and cannot make representations as to the suitability of any individual you may decide to interact with
            on or through the Website and/or the accuracy or suitability of any advice, information, or recommendations made
            by any individual. While Jaano may attempt to seek information about the background of a E-mitra, either via a
            telephonic or in-person interview, review of past work/customer feedback and/or check presence online (if
            available) on Facebook, personalised website, LinkedIn, Twitter etc., you understand that E-mitras may register
            themselves suo moto. You also understand that any so called background check undertaken by Jaano is not
            exhaustive to the extent to determine previous criminal antecedents and hence, at the end of the day, the Paid
            Service User should take an informed decision on his/her own accord and keep in mind the fact that Jaano only
            seeks to provide a platform wherein Paid Service Users and E-mitras have an opportunity to meet each
            other.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">NOTWITHSTANDING
            THE FOREGOING, YOU AGREE THAT SINCE Jaano only seeks to provide a platform wherein the Paid Service User and
            E-mitra can be brought together and Jaano itself has not role in the execution or provision of Services ITSELF,
            URBAN CLAP SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT WHATSOEVER INCURRED AS THE
            RESULT OF ANY SUCH TRANSACTION OR DEALINGS. IF THERE IS A DISPUTE BETWEEN PARTICIPANTS ON THE WEBSITE, OR
            BETWEEN REGISTERED USERS OR ANY WEBSITE USER AND ANY THIRD PARTY, YOU ACKNOWLEDGE AND AGREE THAT Jaano IS UNDER
            NO OBLIGATION TO BECOME INVOLVED. IN THE EVENT THAT A DISPUTE ARISES BETWEEN YOU AND ONE OR MORE WEBSITE USERS,
            REGISTERED USERS OR ANY THIRD PARTY, YOU HEREBY RELEASE Jaano, ITS OFFICERS, MANAGERS, MEMBERS, DIRECTORS,
            EMPLOYEES, ATTORNEYS, AGENTS, AND SUCCESSORS IN RIGHTS FROM ANY CLAIMS, DEMANDS, AND DAMAGES (ACTUAL AND
            CONSEQUENTIAL) OF EVERY KIND OR NATURE, KNOWN OR UNKNOWN, SUSPECTED OR UNSUSPECTED, FORESEEABLE OR
            UNFORESEEABLE, DISCLOSED OR UNDISCLOSED, ARISING OUT OF OR IN ANY WAY RELATED TO SUCH DISPUTES AND/OR THE
            WEBSITE OR ANY SERVICE PROVIDED THEREUNDER.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">O.
            Dispute Resolution</span></h3>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">If
            a dispute arises between you and Jaano, our goal is to provide you with a neutral and cost effective means of
            resolving the dispute quickly. Accordingly, you and Jaano hereby agree that we will resolve any claim or
            controversy at law and equity that arises out of the Terms of Use or the Website in accordance with this Section
            O or as we and you otherwise agree in writing. Before resorting to the filing of a formal lawsuit, we strongly
            encourage you to first contact us directly to seek a resolution via e-mail at support@Jaano.com. The dispute
            shall be resolved through alternative dispute resolution procedures, such as mediation or arbitration, as
            alternatives to litigation.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            GOVERNING LAW</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            Terms of Use shall be governed in all respects by the laws of India and any legal proceeding arising out of this
            Agreement will occur exclusively in the courts located in New Delhi, India.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">P.
            Advertisements</span></h3>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Aspects
            of the Website and other Jaano services may be supported by advertising revenue. As such, Jaano may display
            advertisements and promotions on the service. The manner, mode and extent of advertising by Jaano on the Website
            are subject to change and the appearance of advertisements on the Website does not necessarily imply endorsement
            by Jaano of any advertised products or services. You agree that Jaano shall not be responsible or liable for any
            loss or damage of any sort incurred by you as a result of any such dealings or as the result of the presence of
            such advertisers on the Website.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Q.
            Third-party Links, Contact Forms and Phone Numbers</span></h3>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            Website may provide, or third parties may provide, links, contact forms and/or phone numbers to other websites
            or resources, including, without limitation, social networking, blogging and similar websites through which you
            are able to log into the Website using your existing account and log-in credentials for such third-party
            websites. Certain areas of the Website may allow you to interact and/or conduct transactions with such
            third-party sites, and, if applicable, allow you to configure your privacy settings in your third-party site
            account to permit your activities on the Website to be shared with your contacts in your third-party site
            account. Because Jaano has no control over such sites and resources, you acknowledge and agree that Jaano is not
            responsible for the availability of such external sites or resources, and is not responsible or liable for any
            content, advertising, products, goods or services on or available from such websites or resources. Unless
            expressly stated on the Website, links to third-party sites should in no way be considered as or interpreted to
            be Jaano&apos;s endorsement of such third-party sites or any product or service offered through them. You
            further acknowledge and agree that Jaano shall not be responsible or liable, directly or indirectly, for any
            damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content,
            advertising, product, goods or services available on or through any such website or resource. The Third-party
            website may have different privacy policies and terms and conditions and business practices than Jaano. In
            certain situations, you may be transferred to a Third-party website through a link or connected to a Third-party
            resource by a contact form or phone but it may appear that you are still on this Website or transacting with
            Jaano. In these situations, you acknowledge and agree that the Third-party website terms and conditions and
            privacy policy apply in this situation. Your dealings and communications through the Website with any party
            other than Jaano are solely between you and such third party. Any complaints, concerns or questions you have
            relating to materials provided by third parties should be forwarded directly to the applicable third
            party.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">R.
            Disclaimer of Warranties</span></h3>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">YOU
            AGREE THAT YOUR USE OF THE WEBSITE SHALL BE AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, AND Jaano
            AND ITS OFFICERS, MANAGERS, MEMBERS, DIRECTORS, EMPLOYEES, SUCCESSORS, ASSIGNS, SUBSIDIARIES, AFFILIATES,
            E-mitraS, SUPPLIERS, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND MAKE NO
            WARRANTIES, REPRESENTATIONS, OR GUARANTEES IN CONNECTION WITH THIS WEBSITE, THE SERVICES OFFERED ON OR THROUGH
            THIS WEBSITE, ANY DATA, MATERIALS, SUBMITTED CONTENT, RELATING TO THE QUALITY, SUITABILITY, TRUTH, ACCURACY OR
            COMPLETENESS OF ANY INFORMATION OR MATERIAL CONTAINED OR PRESENTED ON THIS WEBSITE, INCLUDING WITHOUT LIMITATION
            THE MATERIALS, DATA AND SUBMITTED CONTENT OF OTHER USERS OF THIS SITE OR OTHER THIRD PARTIES. UNLESS OTHERWISE
            EXPLICITLY STATED, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THIS WEBSITE, THE SERVICES OFFERED ON OR
            THROUGH THIS WEBSITE, DATA, MATERIALS, SUBMITTED CONTENT, AND ANY INFORMATION OR MATERIAL CONTAINED OR PRESENTED
            ON THIS WEBSITE IS PROVIDED TO YOU ON AN &quot;AS IS,&quot; &quot;AS AVAILABLE&quot; AND &quot;WHEREIS&quot;
            BASIS WITH NO WARRANTY OF IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. Jaano DOES NOT PROVIDE ANY WARRANTIES AGAINST ERRORS, MISTAKES, OR
            INACCURACIES OF DATA, CONTENT, INFORMATION, MATERIALS, SUBSTANCE OF THE WEBSITE OR SUBMITTED CONTENT, ANY
            UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
            INFORMATION STORED THEREIN, ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH
            THE WEBSITE BY ANY THIRD PARTY, ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE, ANY
            DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY OR Paid Service User OR SERVICE PROVIDER, OR ANY
            LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY DATA, CONTENT, INFORMATION, MATERIALS,
            SUBSTANCE OF THE WEBSITE OR SUBMITTED CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            WEBSITE. Jaano DOES NOT ENDORSE, WARRANT, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
            ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE WEBSITE OR ANY HYPERLINKED SITE OR FEATURED IN ANY BANNER OR
            OTHER ADVERTISEMENT. Jaano WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
            BETWEEN YOU AND ANY PARTY, INCLUDING THIRD PARTY E-mitraS OF PRODUCTS OR SERVICES. AS WITH THE USE OF ANY
            PRODUCT OR SERVICE, AND THE PUBLISHING OR POSTING OF ANY MATERIAL THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
            SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">S.
            Limitations of Liability</span></h3>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            IN NO EVENT SHALL Jaano, OR ITS RESPECTIVE OFFICERS, MANAGERS, MEMBERS, DIRECTORS, EMPLOYEES, SUCCESSORS,
            ASSIGNS, SUBSIDIARIES, AFFILIATES, E-mitraS, SUPPLIERS, ATTORNEYS OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT,
            INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL OR EXEMPLARY DAMAGES (INCLUDING BUT NOT LIMITED TO LOSS
            OF BUSINESS, REVENUE, PROFITS, USE, DATA OR OTHER ECONOMIC ADVANTAGE) WHATSOEVER RESULTING FROM ANY (I) ACCESS
            TO OR USE OF THE WEBSITE OR ANY SERVICES OFFERED BY ANY E-mitraS VIA THE WEBSITE, INCLUDING SERVICES PROVIDED
            PURSUANT TO AN AGREEMENT FORMED INDEPENDENTLY OF THE WEBSITE, WHETHER OR NOT AN AGREEMENT FOR SERVICE FORMED VIA
            THE WEBSITE IS IN EFFECT; (II) ERRORS, MISTAKES, OR INACCURACIES OF DATA, MARKS, CONTENT, INFORMATION, MATERIALS
            OR SUBSTANCE OF THE WEBSITE OR SUBMITTED CONTENT; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; (IV) ANY BUGS, VIRUSES,
            TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE WEBSITE BY ANY THIRD PARTY; (V) ANY
            INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE; (VI) ANY ERRORS OR OMISSIONS IN ANY DATA,
            CONTENT, INFORMATION, MATERIALS OR SUBSTANCE OF THE WEBSITE OR SUBMITTED CONTENT; (VII) ANY FAILED NEGOTIATIONS
            FOR A SERVICE, ANY DISPUTES THAT ARISE DURING OR AFTER THE NEGOTIATION OF A SERVICE OR THE FORMATION OF A
            CONTRACT FOR A SERVICE, OR ANY OTHER DISPUTE THAT ARISES BETWEEN USERS OF THE WEBSITE; (VIII) ANY DEFAMATORY,
            OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY OR Paid Service User OR E-mitra; OR (IX) ANY USE OF ANY DATA,
            MARKS, CONTENT, INFORMATION, MATERIALS OR SUBSTANCE OF THE WEBSITE OR SUBMITTED CONTENT POSTED, EMAILED,
            TRANSMITTED, OR OTHERWISE MADE AVAILABLE ON OR THROUGH THE WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT
            (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT Jaano IS ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE
            APPLICABLE JURISDICTION.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            IN NO EVENT SHALL THE TOTAL, AGGREGATE LIABILITY OF Jaano, OR ANY OF THE ABOVE-REFERENCED RESPECTIVE PARTIES,
            ARISING FROM OR RELATING TO THE WEBSITE, AND/OR SUBMITTED CONTENT EXCEED THE TOTAL AMOUNT OF FEES ACTUALLY PAID
            TO Jaano BY YOU HEREUNDER.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">YOU
            HEREBY ACKNOWLEDGE AND AGREE THAT Jaano SHALL NOT BE LIABLE FOR SUBMITTED CONTENT OR THE DEFAMATORY, OFFENSIVE,
            OR ILLEGAL CONDUCT OF ANY THIRD PARTY OR Paid Service User OR SERVICE PROVIDER AND THAT THE RISK OF HARM OR
            DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU. YOU FURTHER ACKNOWLEDGE AND AGREE THAT Jaano SHALL NOT BE
            LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL OR EXEMPLARY DAMAGES (INCLUDING
            BUT NOT LIMITED TO LOSS OF BUSINESS, REVENUE, PROFITS, USE, DATA OR OTHER ECONOMIC ADVANTAGE) WHATSOEVER
            RESULTING FROM OR RELATING TO ANY CONTRACT BETWEEN WEBSITE USERS ENTERED INTO INDEPENDENTLY OF THE
            WEBSITE.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">THE
            WEBSITE MAY CONTAIN LINKS TO THIRD-PARTY WEBSITES THAT ARE NOT OWNED OR CONTROLLED BY Jaano. Jaano DOES NOT HAVE
            ANY CONTROL OVER, AND ASSUMES NO RESPONSIBILITY FOR, THE CONTENT, PRIVACY POLICIES, OR PRACTICES OF ANY
            THIRD-PARTY WEBSITES. IN ADDITION, Jaano WILL NOT AND CANNOT CENSOR OR EDIT THE CONTENT OF ANY THIRD-PARTY SITE.
            BY USING THE WEBSITE, YOU EXPRESSLY RELIEVE Jaano FROM ANY AND ALL LIABILITY ARISING FROM YOUR USE OF ANY
            THIRDPARTY WEBSITE. ACCORDINGLY, PLEASE BE ADVISED TO READ THE TERMS AND CONDITIONS AND PRIVACY POLICY OF EACH
            THIRD-PARTY WEBSITE THAT YOU VISIT, INCLUDING THOSE DIRECTED BY THE LINKS CONTAINED ON THE WEBSITE.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            FORCE MAJEURE</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Neither
            Jaano nor you shall be liable to the other for any delay or failure in performance under the Terms of Use, other
            than payment obligations, arising out of a cause beyond its control and without its fault or negligence. Such
            causes may include, but are not limited to fires, floods, earthquakes, strikes, unavailability of necessary
            utilities, blackouts, acts of God, acts of declared or undeclared war, acts of regulatory agencies, or national
            disasters.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">T.
            Indemnification and Release</span></h3>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">YOU
            AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS Jaano, AND ITS OFFICERS, MANAGERS, MEMBERS, DIRECTORS, EMPLOYEES,
            SUCCESSORS, ASSIGNS, SUBSIDIARIES, AFFILIATES, E-mitraS, SUPPLIERS, AND AGENTS, FROM AND AGAINST ANY AND ALL
            CLAIMS, DAMAGES, OBLIGATIONS, LOSSES, LIABILITIES, COSTS OR DEBT, AND EXPENSES (INCLUDING BUT NOT LIMITED TO
            ATTORNEYS&apos; FEES) ARISING FROM YOUR USE OF, ACCESS TO, AND PARTICIPATION IN THE WEBSITE; YOUR VIOLATION OF
            ANY PROVISION OF THE TERMS OF USE, INCLUDING THE PRIVACY POLICY; YOUR VIOLATION OF ANY THIRD-PARTY RIGHT,
            INCLUDING WITHOUT LIMITATION ANY COPYRIGHT, PROPERTY, PROPRIETARY, INTELLECTUAL PROPERTY, OR PRIVACY RIGHT; OR
            ANY CLAIM THAT YOUR SUBMITTED CONTENT CAUSED DAMAGE TO A THIRD PARTY. THIS DEFENSE AND INDEMNIFICATION
            OBLIGATION WILL SURVIVE THESE TERMS OF SERVICE AND YOUR USE OF THE WEBSITE.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">IF
            YOU HAVE A DISPUTE WITH ONE OR MORE WEBSITE USERS, YOU FOREVER RELEASE Jaano (AND ITS OFFICERS, MANAGERS,
            MEMBERS, DIRECTORS, EMPLOYEES, SUCCESSORS, ASSIGNS, SUBSIDIARIES, AFFILIATES, E-mitraS, SUPPLIERS, AGENTS,
            SUBSIDIARIES, AND EMPLOYEES) FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY
            KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF THE WEBSITE AND/OR
            ANY SUBMITTED CONTENT.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">THE
            TERMS OF USE, AND ANY RIGHTS AND LICENSES GRANTED HEREUNDER, MAY NOT BE TRANSFERRED OR ASSIGNED BY YOU, BUT MAY
            BE ASSIGNED BY Jaano WITHOUT RESTRICTION.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">U.
            No Third-party Beneficiaries</span></h3>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            agree that, except as otherwise expressly provided in the Terms of Use, there shall be no third-party
            beneficiaries to the Terms of Use.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">V.
            Notice</span></h3>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            agree that Jaano may provide you with notices, including those regarding changes to the Terms of Use, by email,
            regular mail, or postings on the Website.</span></p>
    <h3 dir="ltr" style="line-height: 1.32; margin-top: 30pt; margin-bottom: 15pt;"><span
            style="font-size: 18pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">W.
            General Information</span></h3>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">1.
            ENTIRE TERMS OF USE</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">The
            Terms of Use, together with the Privacy Policy and any other legal notices or Additional Policies published by
            Jaano on the Website, shall constitute the entire agreement between you and Jaano concerning the Website. If any
            provision of the Terms of Use is deemed invalid by a court of competent jurisdiction, the invalidity of such
            provision shall not affect the validity of the remaining provisions of the Terms of Use, which shall remain in
            full force and effect.</span></p>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">No
            waiver of any provision of this these Terms of Use shall be deemed a further or continuing waiver of such term
            or any other term, and Jaano&apos;s failure to assert any right or provision under these Terms of Use shall not
            constitute a waiver of such right or provision.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">2.
            STATUTE OF LIMITATIONS</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">You
            agree that any cause of action arising out of or related to the Website must commence within one (1) year after
            the cause of action accrues. Otherwise, such cause of action is permanently barred.</span></p>
    <h4 dir="ltr" style="line-height: 1.32; margin-top: 15pt; margin-bottom: 15pt;"><span
            style="font-size: 13.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 700; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">3.
            SECTION HEADINGS</span></h4>
    <p dir="ltr" style="line-height: 1.4727272727272727; text-align: justify; margin-top: 0pt; margin-bottom: 8pt;"><span
            style="font-size: 9.5pt; font-family: Roboto, sans-serif; color: rgb(51, 51, 51); background-color: transparent; font-weight: 400; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-variant-east-asian: normal; font-variant-position: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap; padding-bottom:100px;">The
            section headings in the Terms of Use are for convenience only and have no legal or contractual effect</span></p>
    </div>
</div>