import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { LoginDialogService } from '../services/loginService';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loginService:LoginDialogService) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    console.log('inside handle auth error');
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
        // logout if unauthorized or unauthenticated.
        this.loginService.logOut();
        // console.log('log out from http handler')
    }
    return throwError(err);
}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
        withCredentials: true
    });
    return next.handle(request).pipe(catchError(x=> this.handleAuthError(x)));
  }
}
