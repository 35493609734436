import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

import { LanguageTranslationService } from "../../services/language-translation-service";
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
// import { ChangeLocationDialog } from '../changeLocation/add-location-question.component';
import { ChangeLocationDialog } from "../new-change-location/new-change-location.component";
import { CommonService } from '../../services/common.service';
import { DataService } from "../../services/data.service";
import { MoengageEventsService } from "../../services/moengage_events_service";

@Component({
    selector: 'selectlanguage-dialog',
    templateUrl: 'selectlanguage.dialog.html',
    styleUrls: ["selectlanguage.dialog.css"]
})
export class LanguageSelectionDialog {
    language: any;
    OnBoardUser = false;
    color = 'primary';
    mode = 'indeterminate';
    value = 50;
    IsLoader: boolean = false;
    constructor(private languageService: LanguageTranslationService, public dialogRef: MatDialogRef<LanguageSelectionDialog>,private dialog: MatDialog,
        private googleAnalyticsService: GoogleAnalyticsService,
        private dataService: DataService,
        private commonDataService: CommonService,
        private moeService: MoengageEventsService) {
            let locale = this.languageService.getLanguage();
            //this.language = this.languageService.getLanguage() || 'en';
            if(typeof locale != 'undefined' && locale != '' && locale.length > 0){
                this.language = locale;
            }
            if (!locale || locale == undefined || locale =='undefined') {
                this.OnBoardUser = true;
            }
    }
    onClick(e:any) {
        this.IsLoader= true;
        this.language = e;
        this.languageService.setSelectedLanguage(this.language);
        this.languageService.startLanguageTranslator();
        this.dialogRef.close(true);
        this.moeService.eventEmitter('change_language', {platform : "web", lang : this.language});
        // this.googleAnalyticsService.eventEmitter("web_change_language", "", "User changed Language.", this.language);
        const googleEventLang= `lang_${this.language}`;
        // this.googleAnalyticsService.eventEmitter2(googleEventLang,this.language);
        this.googleAnalyticsService.eventEmitter("lang_sel","language pop-up,","select language","",this.language);
        (window as any).hj('event', 'lang_sel');
        if(!localStorage.getItem('dtname')){
            if(localStorage.getItem('ByDefaultLocationSet') == 'false'){
                this.IsLoader= false;
                this.openChangeLocationDialog();
            }
        }   
        this.IsLoader= false;
        this.changeUserNotificationLanguagecall();
    }
    
    closeDialog() {
        this.dialogRef.close(true);
    }
    changeUserNotificationLanguagecall() {
        let params = {
            "username": localStorage.getItem('username') ? localStorage.getItem('username') : "",
            "notificationLanguage": this.language == "te"  ? "tg" : this.language
        };
        this.dataService.getDataByEntity('changeUserNotificationLanguage', params).subscribe(result => {
        });
    }
    openChangeLocationDialog() {
        const dialogRefer = this.dialog.open(ChangeLocationDialog, {
            width: '600px',
            height: '540px',
            panelClass:'custom-panel-class3',
        }); 
        dialogRefer.afterClosed().subscribe((res) => {
            if (res && res.place && Object.keys(res.place).length > 0) {
              // console.log(res);
              let place = res['place'];
              const unformattedLocation = place.geometry.location;
              this.commonDataService.setSelectedPlace(place.address_components);
              this.commonDataService.setSelectedLocation(unformattedLocation);
            }
          });        
      }

    discard() {
        this.dialogRef.close(false);
    }


    languages = [ 
        {
            key:'A',
            lang:'en',
            language:'English'
        }, 
        {
            key:'अ',
            lang:'hi',
            language:'हिंदी'
        }, 
        {
            key:'ఆ',
            lang:'te',
            language:'తెలుగు'
        }, 
        {
            key:'अ',
            lang:'mr',
            language:'मराठी'
        }, 
        {
            key:'ੳ',
            lang:'pa',
            language:'ਪੰਜਾਬੀ'
        }, 
        {
            key:'অ',
            lang:'bn',
            language:'বাংল'
        }, 
        {
            key:'অ',
            lang:'as',
            language:'অসমীয়া'
        }, 
        {
            key:'अ',
            lang:'ne',
            language:'नेपाली'
        },
    ]
}
