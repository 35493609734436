<div class="col-sm-12 col-xs-12">
  <div class="modal-dialog" role="document">
    <div class="modal-content noBorder">
      <div class="modal-body">
        <div>
          <ul class="levelUp-image">
            <li *ngIf="data.currentLevel == '2'" id="citizen_levelUp" class="imageUp-size"></li>
            <li *ngIf="data.currentLevel == '3'" id="partner_levelUp" class="imageUp-size"></li>
            <li *ngIf="data.currentLevel == '4'" id="leader_levelUp" class="imageUp-size"></li>
            <li *ngIf="data.currentLevel == '5'" id="hero_levelUp" class="imageUp-size"></li>
          </ul>
        </div>
        <div class="text-center">
          <div class="levelUp-head">{{'Congratulations' | translate}}!</div>
          <div class="levelUp-para">{{'You are a' | translate}} 
            <span *ngIf="data.currentLevel == '2'" class="fw-b">{{'CITIZEN' | translate}}</span>
            <span *ngIf="data.currentLevel == '3'" class="fw-b">{{'PARTNER' | translate}}</span>
            <span *ngIf="data.currentLevel == '4'" class="fw-b">{{'LEADER' | translate}}</span>
            <span *ngIf="data.currentLevel == '5'" class="fw-b">{{'HERO' | translate}}</span>
             {{'now' | translate}}.</div>
        </div>
      </div>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
