import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { DataService } from '../../../services/data.service';
import { LanguageTranslationService } from '../../../services/language-translation-service';
import { CommonService } from '../../../services/common.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { LoclangDialogsService } from '../../../services/loclang-dialogs.service';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShowToastyService } from '../../../services/showToastyService';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-gamification',
  templateUrl: './gamification.component.html',
  styleUrls: ['./gamification.component.scss']
})
export class GamificationComponent implements OnInit {
  @ViewChild('cititzen') cititzen:any;
  @ViewChild('partner') partner:any;
  @ViewChild('leader') leader:any;
  @ViewChild('hero') hero:any;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  IsLoader: boolean = false;
  username;
  dtcode;
  profile_type:any;
  userDetail:any;
  aboutMe:any;
  FullName:any;
  selectedLocation:any;
  profile_picture:any;
  stname:any;
  dtname:any;
  userAttributes:any;
  pointsPercentage:any;
  firstLevel:boolean=false;
  secondLevel:boolean=false;
  thirdLevel:boolean= false;
  viewLevel=0;
  openedDiv=0;
  usernameIntial;
  userToScore:any;
  levelPoints = 0;

  subscriptions: Subscription[] = [];


  referalCode:any;
  constructor(
    private router: Router,
    private dataService: DataService,
    private commonService: CommonService,
    private languageService: LanguageTranslationService,
    private activatedRoute: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService,
    private loclatdialog: LoclangDialogsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private dialog: MatDialog,
    private toastservice:ShowToastyService) { 
      let dtcode = localStorage.getItem('dtcode11');
      if(!dtcode || dtcode == undefined || dtcode == 'undefined' || dtcode == 'null' 
      || dtcode == ''){
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("admin_defined_location", "-1");
          this.loclatdialog.openChangeLocationDialog((res:any) => {
            this.dtcode = localStorage.getItem('dtcode11');
          });
        }
      }
    this.dtcode = localStorage.getItem('dtcode11');
    this.username = this.commonService.getUsername();
    this.commonService.subscribeToUsername().subscribe(res=>{
      this.username = res;
      this.usernameIntial = this.username.charAt(0).toUpperCase();
    });

    this.usernameIntial = this.username.charAt(0).toUpperCase();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.setPageView(
          {
            'page_title': 'Gamification Web',
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });

    this.getReferalCode();
  }

  getReferalCode(){
    let referralCode:any = localStorage.getItem('my_referral_code')?localStorage.getItem('my_referral_code'):null;
    if(!referralCode){
      const phoneNo = localStorage.getItem('phone_no');
      const data2 = { phone_no: phoneNo };
      this.dataService.getDataByEntity('getUserProfile/getUserDetails', data2).subscribe(result => {
        if(result && result.result && result.result.length>0){
          referralCode = result.result[0].referral_code;
          localStorage.setItem('my_referral_code',referralCode);
          this.referalCode = referralCode?referralCode:null;
        }
      });
    }
    else{
      this.referalCode = referralCode;
    }
  }

  ngOnInit() {
    this.languageService.startLanguageTranslator();
    this.stname = localStorage.getItem('stname');
    this.dtname = localStorage.getItem('dtname');
    this.subscriptions.push(this.commonService.selectLocationDetails.subscribe((value:any) => {
      if (value) {
        this.selectedLocation = { lat: value.lat(), lng: value.lng() };
      }
    }));
    this.profile_type = 'pvt'
    this.getProfileData();

    // refferal summary
    this.getRefferalSummary();
  }
  getProfileData() {   
      this.IsLoader = true;
      const data = {
        'username': this.username,
        'profile_type': this.profile_type,
        'dtcode': this.dtcode,
      };

      this.subscriptions.push(this.dataService.getDataByEntity('getUserActivity/getUserActivityWithLeadboard/v3', data).subscribe(result => {
        this.IsLoader = false;
        if (result && result.result && result.result[0]) {
          this.userDetail = result.result[0] ? result.result[0].userDetail[0] : '';
          this.aboutMe = this.userDetail ? this.userDetail.about_user : '';     
          this.FullName = this.userDetail? this.userDetail.full_name:'';
          this.profile_picture = this.userDetail? this.userDetail.profile_picture:'';
          if(this.profile_picture !='' && this.profile_picture != null && this.profile_picture != undefined){
            if(this.FullName !='' && this.FullName != null && this.FullName != undefined){
              this.firstLevel = true;
            }
          }
          if(this.aboutMe !='' && this.aboutMe != null && this.aboutMe != undefined){
            this.secondLevel= true;
          }
          this.userAttributes = this.userDetail ? this.userDetail.user_attributes : '';
          if (this.userAttributes == null) {
            this.userAttributes = [];
          }
          if(this.userAttributes != null && this.userAttributes.length > 0 && this.userAttributes != undefined){
            this.thirdLevel= true;
          }
          if(this.userDetail.guide_level == '1'){
            this.pointsPercentage= (this.userDetail.overall_points / 1000) * 100 +'%';
            this.userToScore = (1000 - this.userDetail.overall_points);
            this.levelPoints = 1000;
          }
          else if(this.userDetail.guide_level == '2'){
            this.pointsPercentage= (this.userDetail.overall_points / 5000) * 100 +'%';
            this.userToScore = (5000 - this.userDetail.overall_points);
            this.levelPoints = 5000;
          }
          else if(this.userDetail.guide_level == '3'){
            this.pointsPercentage= (this.userDetail.overall_points / 15000) * 100 +'%';
            this.userToScore = (15000 - this.userDetail.overall_points);
            this.levelPoints = 15000;
          }
          else if(this.userDetail.guide_level == '4'){
            this.pointsPercentage= (this.userDetail.overall_points / 50000) * 100 +'%';
            this.userToScore = (50000 - this.userDetail.overall_points);
            this.levelPoints = 50000;
          }
          else if(this.userDetail.guide_level == '5'){
            this.pointsPercentage= (50000 / 50000) * 100 +'%';
            this.userToScore = 50000;
            this.levelPoints = 50000;
          }
          this.commonService.setJaanoPoints(this.userDetail.overall_points);
        }
      }));
  }

  openDiv(){
    if(this.openedDiv == 0){
      let level = this.userDetail.guide_level;
      if(level == 1){
        this.cititzen.nativeElement.click();
      }
      else if(level == 2){
        this.partner.nativeElement.click();
      }
      else if(level == 3){
        this.leader.nativeElement.click();
      }
      else if(level == 4){
        this.hero.nativeElement.click();
      }      
      this.openedDiv = level;
    }
    else{   
      this.openedDiv = this.openedDiv + 1;
      if(this.openedDiv == 1){
        this.cititzen.nativeElement.click();
      }
      else if(this.openedDiv == 2){
        this.partner.nativeElement.click();
      }
      else if(this.openedDiv == 3){
        this.leader.nativeElement.click();
      }
      else if(this.openedDiv == 4){
        this.hero.nativeElement.click();
      }  
      else{
        this.hero.nativeElement.click();
        this.openedDiv = 0;
        this.openedDiv = this.userDetail.guide_level == 0 ? 0: this.userDetail.guide_level - 1;
        // if(this.openedDiv == 1){
        //   this.cititzen.nativeElement.click();
        // }
        // else if(this.openedDiv == 2){
        //   this.partner.nativeElement.click();
        // }
        // else if(this.openedDiv == 3){
        //   this.leader.nativeElement.click();
        // }
        // else if(this.openedDiv == 4){
        //   this.hero.nativeElement.click();
        // }  
        
      }
    }
   
  }
  navigateToProfile(){
    this.router.navigate(['user-profile'], { queryParams: { profile_type: 'pvt' } });
  }
  navigateToPage(pageName:any){
    if(pageName =='Profile'){
      this.router.navigate(['user-profile'], { queryParams: { profile_type: 'pvt' } });
    }
    else if(pageName =='Help'){
      this.router.navigate(['./feeds/help']);
    }
    else if(pageName =='Help'){
      this.router.navigate(['./feeds/help']);
    }
    else if(pageName =='profileHelp'){
      this.router.navigate(['user-profile'], { queryParams: { profile_type: 'pvt' } });
    }
  }
  // getPoint(){
  //  let myStyles={
  //   'width': this.pointsPercentage
  //  }
  // }


  referalInfo(){
    const element = document.getElementById("referralCard2");
    element?element.scrollIntoView({
      behavior: 'smooth' 
    }):"";
  }

  copyInputMessage(){
    document.addEventListener('copy', (e: ClipboardEvent|any) => {
      e.clipboardData.setData('text/plain', (this.referalCode));
      e.preventDefault();
      // document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.toastservice.success(
      "",
      'Copied referral code'
    );
  }

  shareOnWhatsapp(){
    const url = environment.webUrl;
    this.languageService
    .getTranslationOf('jaano_referal')
    .subscribe((value) => {
      if (value) {
        value= value.replace('$$',this.referalCode)
        const message = `${value} \n ${url}/home?referral_code=${this.referalCode}`;
        const windowWidth = window.innerWidth;
        if (windowWidth < 768) {
          window.open("whatsapp://send?text=" + encodeURIComponent(message));
        } else {
          const whatsappShareLink = this.commonService.getWhatsappLink();
          let url = whatsappShareLink + encodeURIComponent(message);
          window.open(url, "_blank");
        }
      }
    });
    
  }

  shareOnMessage(){
    const url = environment.webUrl;
    this.languageService
    .getTranslationOf('jaano_referal')
    .subscribe((value) => {
      if (value) {
        value= value.replace('$$',this.referalCode)
        const message = `${value} \n ${url}/home?referral_code=${this.referalCode}`;
        
        let userAgent = navigator.userAgent.toLowerCase();
        let messageUrl;     
        if (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1){
          messageUrl = "sms:;body=" + encodeURIComponent(message);
        }
        else{
          messageUrl = "sms:?body=" + encodeURIComponent(message);
        }
        window.open(messageUrl);
      }
    });
  }


  referralList:{referId:number,referredBy:string,referredUser:string,status:string,createdAt:string,updatedAt:string,fullName:string,profilePicture:string,
  phoneno:string}[]=[];
  scoreList:{userId:number,paymentType:string,amount:number,timestamp:string,referralCount:number,id:number}[]=[];
  referralInfo:any;
  getRefferalSummary(){
    const username = this.commonService.getUsername();
    const req={
      username: username
    };
    this.dataService.getReferralSummary(req).subscribe(response=>{
      if(response && response.success){
        this.referralList = [];
        this.scoreList = [];
        if(response.referrals && response.referrals.length>0){
          response.referrals.forEach((data:any)=>{
            this.referralList.push({referId: data.refer_id, referredBy: data.referred_by, referredUser: data.referred_user, status: data.status,
            createdAt: data.created_at, updatedAt:data.updatedAt,fullName:data.full_name, profilePicture:data.profile_picture,phoneno:data.phone_no});
          })
        }
        if(response.score && response.score.length>0){
          response.score.forEach((data:any)=>{
            this.scoreList.push({userId:data.userid, paymentType:data.payment_type,amount:data.amount,timestamp:data.timestamp,referralCount:data.referral_count,
            id:data.id});
          })
          const referralCount = this.scoreList[this.scoreList.length-1].referralCount;
          const referralAmount = this.scoreList[this.scoreList.length-1].amount;
          
          this.languageService
          .getTranslationOf('referal_score_earned')
          .subscribe((value) => {
            if (value) {
              value= value.replace('--',referralAmount);
              value = value.replace('==', referralCount);
              this.referralInfo = value;
            }
          });
        }
      }
      // this.scoreList.push({userId:null, paymentType:null,amount:10,timestamp:null,referralCount:1,
      //   id:null});
      // this.scoreList.push({userId:null, paymentType:null,amount:70,timestamp:null,referralCount:10,
      //   id:null});
      //   const referralCount = this.scoreList[this.scoreList.length-1].referralCount;
      //   const referralAmount = this.scoreList[this.scoreList.length-1].amount;
        
      //   this.languageService
      //   .getTranslationOf('referal_score_earned')
      //   .subscribe((value) => {
      //     if (value) {
      //       value= value.replace('--',referralAmount);
      //       value = value.replace('==', referralCount);
      //       this.referralInfo = value;
      //     }
      //   });
      // this.referralList.push({referId: null, referredBy: null, referredUser: 'parveenJ', status: 'completed',phoneno:'9876543210',
      //   createdAt: null, updatedAt:null,fullName:'parveen', profilePicture:'https://firebasestorage.googleapis.com/v0/b/jaano2.appspot.com/o/files%2F16571688812350_webimage?alt=media&token=41a4e7e3-c646-4016-a648-3ce725e0c19e'});
      //   this.referralList.push({referId: null, referredBy: null, referredUser: 'parkashj', status: 'completed',phoneno:'9876543210',
      //     createdAt: null, updatedAt:null,fullName:'parkash', profilePicture:null})
    })
  }

  shareOnApp(){
    let myNavigator: any = window.navigator;
    const url = environment.webUrl;
    this.languageService
    .getTranslationOf('jaano_referal')
    .subscribe((value) => {
      if (value) {
        value= value.replace('$$',this.referalCode)
        const message = `${value} \n ${url}/home?referral_code=${this.referalCode}`;
        const shareObj = {
          title: 'jaano.swaniti.org',
          text: message,
          url: url,
        };
        if (myNavigator && myNavigator.share) {
          myNavigator.share(shareObj)
            .then(() => {
              console.log('Successful share');
              this.toastservice.success(
                "",
                'Shared successfully'
              );
            })
            .catch((error:any) => console.log('Error sharing', error));
        }
        else{
          document.addEventListener('copy', (e: ClipboardEvent|any) => {
            e.clipboardData.setData('text/plain', (message));
            e.preventDefault();
            // document.removeEventListener('copy', null);
          });
          document.execCommand('copy');
          this.toastservice.success(
            "",
            'Referal link copied.'
          );
        }
      }
    });
  }

  onRedeem = () =>{
    const dialogRef = this.dialog.open(RedeemComponent, {
      width: "328px",
      height:"320px",
      disableClose: true,
      panelClass: 'custom-panel-class',
    });
    dialogRef.afterClosed().subscribe((result) => {
      // after close
    });
  }

}

@Component({
  selector: 'redeem',
  templateUrl: 'redeem.component.html',
  styleUrls: ['gamification.component.scss']
})
export class RedeemComponent {

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading= false;
  redeemForm:any =  new FormGroup({
    upi: new FormControl('',[Validators.required, Validators.pattern('[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}')])
  })
  constructor( public dialogRef: MatDialogRef<RedeemComponent>, private dataService: DataService) {
  }

  viewScreen: 'UPI'|'SUBMITTED' = 'UPI';

  onUpiSubmit= () =>{
    // on upi submit function
    if(this.redeemForm.invalid){
      return;
    }
    this.isLoading = true;
    const username = localStorage.getItem('username');
    const upi = this.redeemForm.value.upi.trim();
    const req={
      username:username,
      upi_id: upi
    }
    this.dataService.requestPayout(req).subscribe(response => {
      if(response && response.success){
        this.viewScreen  = 'SUBMITTED';
      }
      this.isLoading = false;
    },error =>{
      this.isLoading = false;
    });
  }

  onClose =() =>{
    this.dialogRef.close();
  }


}