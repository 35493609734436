import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { MetaServiceService } from "../services/meta-service.service";
import { BehaviorSubject, Observable, of, ReplaySubject } from "rxjs";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import { map } from "rxjs/operators";
import { CheckoutObj, PaymentExitForm, PhoneNo, RazorPayTxn, SessionLogin, VerifyOtp } from "../models/models";
import { CookieService } from "ngx-cookie";
import { PaymentMethod } from "../enums/enum.type";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class DataService {
  selectedentity = "";
  authToken:any;
  baseUrl = environment.baseUrl;
  baseUrl2 = environment.baseUrl2;
  baseUrl3 = environment.baseUrl3;
  surveymanBaseUrl = environment.surveymanBaseUrl;
  adminUrl = environment.adminUrl;

  constructor(
    private httpClient: HttpClient,
    private authSerivce: AuthService,
    private metaDataService: MetaServiceService,
    private db: AngularFireDatabase, private cookieService:CookieService
  ) {
    if (!this.authToken) {
      // get user authentification token to attach in each API body
      this.authSerivce.userAuthToken.subscribe((res) => {
        if (res) {
          this.authToken = res;
          localStorage.setItem('authToken',res);
        } else {
          this.setTokenInService();
        }
      });
    }
  }

  setTokenInService() {
    this.authSerivce.currentUserIdToken().subscribe((res:any) => {
      if (res) {
        this.authToken = res;
        localStorage.setItem('authToken',res);
      }
    });
  }

  getDataByEntity(context: string, data: any) {
    let metaData: any = this.metaDataService.getMetaData();
    let extraMetaData = data.metaData;
    if (extraMetaData) {
      metaData = Object.assign(metaData, extraMetaData);
      delete data.metaData;
    }
    let token = this.authToken? this.authToken: localStorage.getItem('authToken');
    data = {
      ...data,
      ...{ token: token, metadata: JSON.stringify(metaData) },
    };
    const url = `${this.baseUrl2}/${context}`;
    return this.httpClient.post<any>(url, data, httpOptions);
  }

  getData(context: string) {
    const url = `${this.baseUrl}/${context}`;
    return this.httpClient.get<any>(url, httpOptions);
  }

  getAddEntityForm(context: string) {
    const url = `${this.baseUrl2}/${context}`;
    return this.httpClient.get<any>(url, httpOptions);
  }

  syncQnrData(context: string, data: any) {
    const url = `${this.surveymanBaseUrl}/${context}`;
    return this.httpClient.post<any>(url, data, httpOptions);
  }

  getPreviewMetaData(context: string, data: any) {
    const url = `${this.adminUrl}/${context}`;
    return this.httpClient.post<any>(url, data, httpOptions);
  }

  updateUserProfile(context: string, data: any) {
    let token = this.authToken? this.authToken: localStorage.getItem('authToken');
    data = { ...data, ...{ token: token } };
    const url = `${this.baseUrl2}/${context}`;
    return this.httpClient.post<any>(url, data, httpOptions);
  }

  getUserDetails(context: string, data: any) {
    let token = this.authToken? this.authToken: localStorage.getItem('authToken');
    data = { ...data, ...{ token: token } };
    const url = `${this.baseUrl2}/${context}`;
    return this.httpClient.post<any>(url, data, httpOptions);
  }

  getReferralSummary(req:any){
    // here request should have the username object.
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/getReferralSummary",
      req
    );
  }

  messageingApis(context: string, data: any) {
    let token = this.authToken? this.authToken: localStorage.getItem('authToken');
    data = { ...data, token: token };
    const url = `${this.baseUrl2}/messaging/${context}`;
    return this.httpClient.post<any>(url, data, httpOptions);
  }

  WhatsApp(){
    return this.httpClient.get("https://api.whatsapp.com/send?phone=8467882252&text=I'm%20interested%20in%20your%20car%20for%20sale");
  }

  createHelpRequest(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/helpRequest/createNewHelpRequest",
      req
    );
  }

  createNewHelpRequest(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/helpRequest/createNewHelpRequest/v2",
      req
    );
  }

  getReferralServices(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/helpRequest/getHRServices/v2",
      req
    );
  }
  getPopularServiceData(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/helpRequest/getPopularServiceData/v2",
      req
    );
  }

  setPaymentExitReason=(obj:PaymentExitForm)=>{
    const req:any = obj;
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/setPaymentExitReason",
      req
    );
  }
  recordUsersAction(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      `${this.baseUrl2}/homePage/setHRReview`,
      req
    );
  }

  editHelpRequest(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/helpRequest/editHelpRequest",
      req
    );
  }

  updateUserName(req:any){
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/getUserProfile/updateUserFullName",
      req
    );
  }
  deleteHelpRequest(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/helpRequest/deleteHelpRequest",
      req
    );
  }

  getStandardHelpRequests(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/hr/getStandardHelpRequests",
      req
    );
  }
  getStandardHelpRequestsWithCtg(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/hr/getStandardHelpRequestsWithCtg",
      req
    );
  }

  getHomeDataList(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    req.metadata = JSON.stringify(this.metaDataService.getMetaData());
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/getHomeFeed/getHomeData/v2",
      req
    );
  }
  getRequestCategoryList(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl3 + "/helpRequest/getHelpRequestCategories",
      req
    );
  }
  getUserLocationDetails(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    req.metadata = JSON.stringify(this.metaDataService.getMetaData());
    if (req.lat && req.lng)
      return this.httpClient.post<any>(
        environment.baseUrl2 + "/getUserLocationDetails",
        req
      );
    else return of({ success: false });
  }

  getUserLocationDetailsv2(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    req.metadata = JSON.stringify(this.metaDataService.getMetaData());
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/getUserLocationDetails/v2",
      req
    );
  }

  createHelpRequestv2(req:any) {
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/helpRequest/createNewHelpRequest/v2",
      req
    );
  }

  processPaidHelpTransaction(req:any){
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/payments/processPaidHelpTransaction",
      req
    );
  }
  initiatePaidHelpTransaction(req:RazorPayTxn){
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/razorpaypayments/initiatePaidHelpTransaction",
      req
    );
  }

  requestPayout = (req:any) =>{
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl4 + "/requestPayout",
      req
    );
  }

  callv3API(context: string, data: any) {
    let metaData: any = this.metaDataService.getMetaData();
    let extraMetaData = data.metaData;
    if (extraMetaData) {
      metaData = Object.assign(metaData, extraMetaData);
      delete data.metaData;
    }
    let token = this.authToken? this.authToken: localStorage.getItem('authToken');
    data = {
      ...data,
      ...{ token: token, metadata: JSON.stringify(metaData) },
    };
    const url = `${this.baseUrl3}/${context}`;
    return this.httpClient.post<any>(url, data, httpOptions);
  }

  setLogUserTokenInBehaviour(){
    const userLocation:any=localStorage.getItem('user_location');
    let lat =JSON.parse(userLocation).lat;
    let lang = JSON.parse(userLocation).lng;
    let data:any = {
      username: localStorage.getItem('username'),
      lat: lat,
      lng: lang,
    }
    let metaData: any = this.metaDataService.getMetaData();
    let extraMetaData = data.metaData;
    if (extraMetaData) {
      metaData = Object.assign(metaData, extraMetaData);
      delete data.metaData;
    }
    let token = this.authToken? this.authToken: localStorage.getItem('authToken');
    data = {
      ...data,
      ...{ token: token, metadata: JSON.stringify(metaData) },
    };
    const url = `${this.baseUrl3}/${'logUserTokenInBehaviour'}`;
    return this.httpClient.post<any>(url, data, httpOptions);

  }

  getTime(time:any):string{
    if(time<= 0){
      return "00:00";
    }
    // getting time in seconds.
    time= Math.round(time/1000);
    let minuteString = "00";
    let secondString = "00";
    let minute = Math.floor(time/60);   // 600 sec = 10 minutes
    let seconds = (time - minute*60);
    if(minute <10 && minute >0){
      minuteString = "0"+minute;
    }
    if(minute >=10){
      minuteString = ""+minute;
    }
    if(seconds >=10){
      secondString = ""+seconds;
    }
    else if(seconds <10 && seconds >0){
      secondString = "0"+seconds;
    }
    return minuteString+":"+secondString;
  }


  private _offerHelpid = new BehaviorSubject<any>(localStorage.getItem('create_paid_help_id'));
  public offerHelpid = this._offerHelpid.asObservable();
  public setOfferHelpid(value:any){
    this._offerHelpid.next(value);
  }
  private _removeHrSubject = new BehaviorSubject<boolean>(false);
  public removeHrSubject = this._removeHrSubject.asObservable();
  private _growthBookHr = new ReplaySubject<boolean>();
  private _growthBookPayment = new ReplaySubject<boolean>();
  private _growthBookKeys = new ReplaySubject<{variationId:number,gbUserId:string,experiment_id:string}>();
  public checkoutSubject = new BehaviorSubject<CheckoutObj>({
    amount:0,
    service:'',
    category:'',
    emitraName:'',
    emitraHrSolved:0,
    emitraRating:0,
    emitraReviews:0,
    emitraPicture:'',
    selectedPaymentMethod:PaymentMethod.Paytm,
    fromReferral:false,
    grandTotalAmount:0,
    helpid:'',
    phoneNo:'',
    username:'',
    emitraId:'',
    offerlist:[],offer:null});
  
  public setGrowthBookHr(value:boolean){
    this._growthBookHr.next(value);
  }
  public setGrowthBookPayment(value:boolean){
    this._growthBookPayment.next(value);
  }
  public growthBookHr =this._growthBookHr.asObservable()
  public growthBookPayment =this._growthBookPayment.asObservable()

  public setGrowthBookKeys(value:{variationId:number,gbUserId:string,experiment_id:string}){
    this._growthBookKeys.next(value);
  }
  public growthBookKeys =this._growthBookKeys.asObservable()


  offerReference!: AngularFireList<any>;
  latestHrAndTimestamp!: AngularFireList<any>;
  getOffer(username:string, helpid: string): AngularFireList<any>{
    username = username.replace('.','*');
  const PATH = "/offers2/" + username;
    this.offerReference  = this.db.list(PATH, ref=> ref.orderByKey()
    .equalTo(helpid));
    return this.offerReference;
  }

  getPopularServices(data:any){
    let metaData: any = this.metaDataService.getMetaData();
    let extraMetaData = data.metaData;
    if (extraMetaData) {
      metaData = Object.assign(metaData, extraMetaData);
      delete data.metaData;
    }
    let token = this.authToken? this.authToken: localStorage.getItem('authToken');
    data = {
      ...data,
      ...{ token: token, metadata: JSON.stringify(metaData) },
    };
    const url = `${this.baseUrl3}/getPopularServices`;
    return this.httpClient.post<any>(url, data, httpOptions);
  }

  getLatestHrAndTimestamp(username:string){
    username = username.replace('.','*');
    const PATH = "/latest_paid_hr/";
    this.latestHrAndTimestamp  = this.db.list(PATH, ref=> ref.orderByKey()
    .equalTo(username));
    return this.latestHrAndTimestamp;
  }

  updateNewHridAndTimestamp(username:string, helpId:string){
    username = username.replace('.','*');
    const timestamp = new Date().getTime();
    const PATH = "/latest_paid_hr/";
    const latestPaidHrObject = {
      help_id: helpId,
      timestamp : timestamp
    };
    const latestPaidHr = this.db.list(PATH);
    latestPaidHr.update(username,latestPaidHrObject);
  }

  removeHridAndTimestamp(username:string){
    username = username.replace('.','*');
    const PATH = "/latest_paid_hr/";
    let hrRef  = this.db.list(PATH);
    hrRef.remove(username);
  }

  removeHrAndTimestampIfPaid(username_:string,helpid_:string){
    let username= localStorage.getItem('username');
    // username of hr must be same as the logged user 
    if(!username || username == undefined || username == 'undefined' || username != username_){
      return false;
    }
    this.getLatestHrAndTimestamp(username_)
    .snapshotChanges()
          .pipe(
          map(changes =>
            changes.map((c:any) =>
              ({ key: c.payload.key, ...c.payload.val() })
            )
          )
          ).subscribe( response=>{
            if(response && response.length>0){
              let helpid = response[0].help_id;
              if(helpid == helpid_){
                this.removeHridAndTimestamp(username_);
                const helpid:any= null;
                this.setOfferHelpid(helpid);
                this._removeHrSubject.next(true);
              }
            }
          });
  }

  convertToFree(helpid:string){
    const req:any= {helpid:helpid};
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/convertToFree",
      req
    );
  }

  setToken(){
    let req:any;
    return this.httpClient.get<any>(
      environment.baseUrl2 + "/token",
      req
    );
  }

  sendOTP(req:PhoneNo){
    const csrftoken = this.cookieService.get('csrfToken');
    const newReq:any= req;
    newReq.csrfToken= csrftoken;
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/auth/sendOTP",
      newReq
    );
  }

  resendOTP(req:PhoneNo){
    const csrftoken = this.cookieService.get('csrfToken');
    const newReq:any= req;
    newReq.csrfToken= csrftoken;
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/auth/resendOTP",
      newReq
    );
  }
  verifyOTP(req:VerifyOtp){
    const csrftoken = this.cookieService.get('csrfToken');
    const newReq:any= req;
    newReq.csrfToken= csrftoken;
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/auth/verifyOTP",
      newReq
    );
  }

  verifyOTPAndCreateHelpRequest(req:any){
    const csrftoken = this.cookieService.get('csrfToken');
    const newReq:any= req;
    newReq.csrfToken= csrftoken;
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/auth/verifyOTPAndCreateHelpRequest",
      newReq
    );
  }
  sessionLogin(req:SessionLogin){
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials:true
    };
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/sessionLogin",
      req,httpOptions
    );
  }

  askForSupport=(helpid:string)=>{
    const req:any= {helpid:helpid};
    req.token = this.authToken? this.authToken: localStorage.getItem('authToken');
    return this.httpClient.post<any>(
      environment.baseUrl2 + "/helpRequest/askForSupport/v2",
      req
    );
  }

}
