<div (click)="closeDialog()" style="cursor: pointer;" class="d-flex align-center">
  <img height="15" src="../../../assets/images/back_arrow_gray.svg" alt="Next">
  <span class="next-page text-uppercase">{{'back' | translate}}</span>
</div>

<div class="col-12 mainheading">
  {{'Please select your Topic of Interest' | translate}}</div>

<div class="maincontainer">
  <div *ngFor="let categories of categoriesJSON | keyvalue">
    <div class="col-12 header">
      {{$any(categories).value[0].cat_ln}}
    </div>

    <div class="elementscontainer">
      <div class="m-0 item d-inline-block" *ngFor="let data of $any(categories).value;let idx = index">
        <div class="col-8 pr-2 pl-2 d-inline-block">
          <div class="sub_cat" title="{{data['subcat_ln']}}">{{data['subcat_ln']}}</div>
          <div class="count">{{data.total_solved_hr}} {{'Solved Help Requests'| translate}}</div>
        </div>
        <div class="col-4 p-0 pr-2 text-right d-inline-block align-top">
          <button class="btn-primary view_button" (click)="onView(data)">{{'View'| translate}}</button>
        </div>
      </div>
    </div>

  </div>
</div>