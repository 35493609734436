import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Subject } from 'rxjs';

@Injectable()
export class HelpRequestCategoryServiceService {
  selectedLanguage:any;
  categoryList:any;
  public list1 = new Subject<any>();
  public listObservable = this.list1.asObservable();
  constructor(private dataService:DataService) { }


  getHelpRequestCategoriesList(lang:any){ 
    let ttl = 604800;
    const now = new Date();
    if(localStorage.getItem('category_list')){     
      const categoryList:any= localStorage.getItem('category_list'); 
      let catItem = JSON.parse(categoryList);
      //let selectedCat = catItem.filter(x=>x.cat_lng == lang);
      if(lang == catItem.cat_lng){
        if (now.getTime() > catItem.expiry) {
          let param = {
            lang: lang
          }
          this.dataService.getRequestCategoryList(param).subscribe(req => {
            //localStorage.setItem('category_list',req.data);
            let arrayList = JSON.stringify(req.data);
            let cat_array={
              cat_list : arrayList,
              cat_lng: lang,
              expiry: now.getTime() + ttl,
            }
            this.list1.next(req.data);
            localStorage.setItem('category_list',JSON.stringify(cat_array));
           //return this.categoryList = JSON.stringify(req.data);
          });
        }
        else{
          setTimeout(()=> {
            let cat_list = JSON.parse(catItem.cat_list); 
            this.list1.next(cat_list);
          }, 100)
        }    
      }   
      else{       
        let param = {
          lang: lang
        }
        this.dataService.getRequestCategoryList(param).subscribe(req => {
          //localStorage.setItem('category_list',req.data);
          let arrayList = JSON.stringify(req.data);
          let cat_array={
            cat_list : arrayList,
            cat_lng: lang,
            expiry: now.getTime() + ttl,
          }
          let cat_array_lng ={
  
          }
          this.list1.next(req.data);
          localStorage.setItem('category_list',JSON.stringify(cat_array));
         //return this.categoryList = JSON.stringify(req.data);
        });
      }      
    }
    else{
      let param = {
        lang: lang
      }
      this.dataService.getRequestCategoryList(param).subscribe(req => {
        //localStorage.setItem('category_list',req.data);
        let arrayList = JSON.stringify(req.data);
        let cat_array={
          cat_list : arrayList,
          cat_lng: lang,
          expiry: now.getTime() + ttl,
        }
        let cat_array_lng ={

        }
        this.list1.next(req.data);
        localStorage.setItem('category_list',JSON.stringify(cat_array));
       //return this.categoryList = JSON.stringify(req.data);
      });
    }   
  }
}
