<div class="main-dialog col-sm-12 col-xs-12">
    <!-- <div mat-dialog-title class="heading_change_language"> {{'SelectLanguage' | translate}} </div> -->
    <div class="cross-icon" (click)="discard()">
        <img src="../../../assets/images/close_video_player.svg" alt="close-video-player">
        <!-- <i class="material-icons">close</i> -->
    </div>
    <div class="main-dialog">
        <iframe class="match_height_width" title="" *ngIf="video_url && !isFacebookVideo" [src]="video_url" allow='autoplay'
            webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        <iframe class="match_height_width" *ngIf="isFacebookVideo" [src]="video_url | safe" style="border:none;overflow:hidden" scrolling="no"
            frameborder="0" allowTransparency="true" allowFullScreen="true" allow='autoplay'></iframe>
        <iframe title="" class="match_height_width" *ngIf="isTwitterVideo" [src]="video_url" webkitallowfullscreen
            mozallowfullscreen allowfullscreen allow='autoplay'></iframe>
        <!-- <mat-video *ngIf="isTwitterVideo" style="height: 20rem;" [fullscreen]="false" [download]="true"
            color="accent" [keyboard]="false" spinner="hourglass" [src]="video_url"></mat-video> -->
    </div>
</div>