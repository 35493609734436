import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpRequestCategoryServiceService } from '../../services/help-request-category-service.service';
import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { LanguageTranslationService } from '../../services/language-translation-service';
import { LoginDialogService } from '../../services/loginService';
import { MoengageEventsService } from '../../services/moengage_events_service';
import { VideoPlayer } from '../../shared-components/video_player_dialog/video_player_dialog';
import { ConfirmDialogPaidHelpComponent } from '../help-request/get-specific-help-request/confirm-dialog-paid-help/confirm-dialog-paid-help.component';
import { isPlatformBrowser } from '@angular/common';
import { LoclangDialogsService } from '../../services/loclang-dialogs.service';
import { ChangeLocationDialog } from '../../shared-components/new-change-location/new-change-location.component';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  docEl:HTMLElement|any;
  subscriptions: Subscription[] = [];
  sliderList: {label:string, img:string,description:string,width:string,height:string}[] = [
    {label:'create_request',img:'https://jaano2.s3.ap-south-1.amazonaws.com/Landing_page/Sliding_card1.png',
    description:'create_request_desc',
    width:'200px',height:'307px'},
    {label:'wait_for_emitra',img:'https://jaano2.s3.ap-south-1.amazonaws.com/Landing_page/Sliding_card2.png',
    description:'wait_for_emitra_desc',
    width:'200px',height:'85px'},
    {label:'accept_offer',img:'https://jaano2.s3.ap-south-1.amazonaws.com/Landing_page/Sliding_card3.png',
    description:'accept_offer_desc',
    width:'200px',height:'70px'},
    {label:'complete_payment',img:'https://jaano2.s3.ap-south-1.amazonaws.com/Landing_page/Sliding_card4.png',
    description:'complete_payment_desc',
    width:'200px',height:'181px'},
    {label:'connect_with_emitra',img:'https://jaano2.s3.ap-south-1.amazonaws.com/Landing_page/Sliding_card5.png',
    description:'connect_with_emitra_desc',
    width:'200px',height:'280px'},
    {label:'problem_solved',img:'https://jaano2.s3.ap-south-1.amazonaws.com/Landing_page/Sliding_card6.png',
    description:'problem_solved_desc',
    width:'200px',height:'274px'},
  ];

  faqList: {id:string,label:string,desc:string[],hidden:boolean}[] = [
    {id:'collapseOne',label:'faq1',desc:['faq1_ans'],hidden:false},
    {id:'collapseTwo',label:'faq2',desc:['faq2_ans1','faq2_ans2','faq2_ans3'],hidden:false},
    {id:'collapseThree',label:'faq3',desc:['faq3_ans1','faq3_ans2','faq3_ans3','faq3_ans4','faq3_ans5','faq3_ans6',
    'faq3_ans7','faq3_ans8'],hidden:false},
    {id:'collapseFour',label:'faq4',desc:['faq4_ans1','faq4_ans2'],hidden:false},
    {id:'collapseFive',label:'faq5',desc:['faq5_ans1','faq5_ans2'],hidden:false},
    {id:'collapseSix',label:'faq6',desc:['faq6_ans1','faq6_ans2','faq6_ans3'],hidden:false},
    {id:'collapseSeven',label:'faq7',desc:['faq7_ans1','faq7_ans2'],hidden:false},
    {id:'collapseEight',label:'faq8',desc:['faq8_ans'],hidden:false},
    {id:'collapseNine',label:'faq9',desc:['faq9_ans'],hidden:false},
    {id:'collapseTen',label:'faq10',desc:['faq10_ans'],hidden:false},
    {id:'collapseEleven',label:'faq11',desc:['faq11_ans'],hidden:false}
  ];
  showFaq = 5;
  knowMoreVideo: any = "https://youtu.be/2wXBqiZWjn0";


  knowMore = false;
  hrSolved = 450;
  selectedLanguage= 'en';
  categoriesList :any = [];
  catId:any;
  subCatId:any;
  constructor( private languageService: LanguageTranslationService,
    private moeService:MoengageEventsService, private googleAnalyticsService: GoogleAnalyticsService,
    private dataService: DataService,private dialog: MatDialog,
    private commonService: CommonService, private sanitizer: DomSanitizer,
    private loginService: LoginDialogService, private activatedRoute: ActivatedRoute,
    private router: Router, private helpRequestService:HelpRequestCategoryServiceService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private loclatdialog: LoclangDialogsService,) {

      this.activatedRoute.queryParams.subscribe(params =>{
        if(params){
          if(params['subCat'] && params['cat']){
            this.subCatId = params['subCat'];
            this.catId = params['cat'];
            localStorage.setItem('hr_cat',this.catId);
            localStorage.setItem('hr_sub_cat',this.subCatId);
          }
          // const skipLocation = params['skipLocation'];
          // if(!skipLocation){
          //   this.router.navigate([], {
          //    queryParams: {
          //     skipLocation: 'true'
          //    },
          //    queryParamsHandling: 'merge',
          //    // preserve the existing query params in the route
          //    skipLocationChange: true
          //    // do not trigger navigation
          //  });

          // }
        }
      })
     }

  ngOnInit() {

    this.hrSolved = this.increaseByRate(this.hrSolved);
    // checking whether language is previously opted or not?
    this.languageService.getSelectedLanguage().subscribe((value) => {
      if(value){
        this.selectedLanguage= value;
        this.helpRequestService.getHelpRequestCategoriesList(
          this.selectedLanguage
        );
        this.helpRequestService.listObservable.subscribe(
          (data) => {
            // getting categories
            this.categoriesList = data;
          });
        this.layer = 'cbp';
        this.getLocation();
      }
      else{
        this.layer = 'language';
      }
    });
    if(environment.production){
      // hotjar event for landing page.
      (window as any).hj('event', 'PaidHR_landing_page');
    }
  }


  getLocation(){
    this.commonService.selectedLocation.subscribe((value) => {
      if (
        value &&
        Object.keys(value).length > 0 &&
        (Number(localStorage.getItem("admin_defined_location")) > 0 ||
          Number(localStorage.getItem("admin_defined_location")) < 0)
      ) {
        if (Number(localStorage.getItem("admin_defined_location")) == 1)
          localStorage.setItem("admin_defined_location", "0");
      } else {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("admin_defined_location", "-1");
          this.openChangeLocationDialog((res:any) => {});
        }
      }
    });
  }

  dialogRefer:any;
  openChangeLocationDialog(callback:any) {
    const isAlreadyOpenDialog = localStorage.getItem("changeLocationOpen");
    if(isAlreadyOpenDialog){
      localStorage.removeItem("changeLocationOpen");
      return;
    }
    let locationJSon:any = {fromAds: true};
    this.dialogRefer = this.dialog.open(ChangeLocationDialog, {
      width: '600px',
      height: '540px',
      panelClass:'custom-panel-class3',
      data: locationJSon // {lat: this.lat,lng: this.lng}
    });
    localStorage.setItem("changeLocationOpen","true");
    this.dialogRefer.afterClosed().subscribe((res:any) => {
      localStorage.removeItem("changeLocationOpen");
      if (res && res != 'immediate') {
        if (res && res.place && Object.keys(res.place).length > 0) {
          let place = res['place'];
          const unformattedLocation = place.geometry.location;
          this.commonService.setSelectedPlace(place.address_components);
          this.commonService.setSelectedLocation(unformattedLocation);
          // if (!locationJSon || (locationJSon && !locationJSon.manual)) {
          //   this.openVaacineNotificationDialog((res => {
          //   }));
          // }
          localStorage.setItem('ByDefaultLocationSet', 'false');
          callback(unformattedLocation, true);
        } else if (res && res.latitude && res.longitude) {
          this.commonService.setSelectedPlace("");
          const unformattedLocation = {
            lat: () => res.latitude,
            lng: () => res.longitude
          };
          this.commonService.setSelectedLocation(unformattedLocation);
          // if (!locationJSon || (locationJSon && !locationJSon.manual)) {
          //   this.openVaacineNotificationDialog((res => {
          //   }));
          // }
          callback(unformattedLocation, true);
        }
        else if (res == undefined) {
          this.openChangeLocationDialog(callback);
        }
        else {
          if (localStorage.getItem('ByDefaultLocationSet') == 'false') {
            this.openChangeLocationDialog(callback);
          }
        }
      } else if (!res) {
        this.openChangeLocationDialog(callback);
      }
    });
  }


  language = 'en'
  layer :'language'|'cbp'  = 'language';
  customerReviews : {userProfilePicture: string, userid: string, location:string, rating:number,
    timestamp:string, comment:string, subCat:string, solvedById:string,
    solvedByProfilePicture:string ,expand:boolean , link: string}[] =[];
  onClick(lang:'en'|'hi'|'te'|'pn'|'mr'|'bn'|'as'|'ne'){
    this.language = lang;
    this.languageService.setSelectedLanguage(this.language);
    this.languageService.startLanguageTranslator();
    this.moeService.eventEmitter('change_language', {platform : "web", lang : this.language});
    this.googleAnalyticsService.eventEmitter("web_change_language", "", "User changed Language.", this.language);
    if(!localStorage.getItem('dtname')){
        if(localStorage.getItem('ByDefaultLocationSet') == 'false'){
        }
    }
    this.changeUserNotificationLanguagecall();
    this.getLocation();
  }

  changeUserNotificationLanguagecall() {
      let params = {
          "username": localStorage.getItem('username') ? localStorage.getItem('username') : "",
          "notificationLanguage": this.language == "te"  ? "tg" : this.language
      };
      this.dataService.getDataByEntity('changeUserNotificationLanguage', params).subscribe(result => {
        this.layer= 'cbp';
      },error=>{
        this.layer= 'cbp';
      });
  }

  subCatString = "";
  getPaidHelp(){
    this.googleAnalyticsService.eventEmitter2('pr_init_pl',{});
    (window as any).hj('event', 'pr_init_pl');
    if(environment.production){
      // hotjar event on initializing paid help request.
      (window as any).hj('event', 'PaidHR_init_landing_page');
    }

    const isPaid = true;
    let paidHelpDialog = this.dialog.open(ConfirmDialogPaidHelpComponent, {
      disableClose: true,
      panelClass: 'confirmPaidHelpDialog',
      width:'360px',
      height:'515px',
      maxHeight:'515px',
      data: {
        isPaid: isPaid,
        fromAds:true
      }
    });
    paidHelpDialog.afterClosed().subscribe((result) => {
      if(result && result.viewSolutions){
        let data = {
          cat:this.catId,
          subcat:result.subCtg,
          subCatValue:result.subCtgValue
        }
        const subcat = result.subCtg;
        let dialogRef = this.dialog.open(ViewSolutionDialogComponent, {
          disableClose: true,
          panelClass: 'confirmPaidHelpDialog',
          width: '328px',
          height: '240px',
          data: data
        });
        dialogRef.afterClosed().subscribe(result => {
          // redirect it to login page if not logged in.
          if(result){
            let obj:any = { autoChangeScreen: true };
            obj = { autoChangeScreen: true, showModal:true,width: "360px",height: "515px",
            panelClass: 'confirmPaidHelpDialog',fromAds: true, fromSolution:true}
            this.loginService.openModal(
              obj,
              (status: any) => {
                if (status != false) {
                  if(status.allClose){
                    return;
                  }
                  // go to helprequest page
                  this.router.navigate(["./helprequest"], {
                    queryParams: { ctg: this.catId, subcat: subcat},
                  });
                }
              });
          }
        });
      }
    });
  }
  getCustomerReviews(){
    let subCat = localStorage.getItem("hr_sub_cat");
    let subCatValue = localStorage.getItem("subCategoryName")
    const req= {
      subctg: subCat
    }
    this.subscriptions.push(this.dataService.callv3API("expand/getHRReviews",req).subscribe(res => {
    if(res && res.success && res.rows){
      this.customerReviews = [];
      for (let index = 0; index < this.categoriesList.length; index++) {
        if(this.categoriesList[index].cat_id == this.catId){
          let subCats = this.categoriesList[index].subcat_key;
          let subCatIds = this.categoriesList[index].subcat_id;
          let i = 0;
          for (let j = 0; j < subCatIds.length; j++) {
            if(subCatIds[j]==this.subCatId){
              i = j;
            }
            break;
          }
          if(i != -1){
            this.subCatString = subCats[i];
          }
          break;
        }
      }
      let response = res.rows;
      const videosLink = ['https://youtu.be/zOSEQOHOM5Y','https://youtu.be/3cjfSTnKFNw',
                            'https://youtu.be/_ZWQ-jJmzkw'];
      // filtering out timestamp -1 and timestamp in microseconds..
      response = response.filter( (data:any)=> data.timestamp>-1 && data.timestamp< 10000000000)
      response.forEach((data:any) => {
        let timestamp = this.commonService.timeStampToTime(data.timestamp);
        this.customerReviews.push({userProfilePicture:data.profile_picture, userid: data.userid,
          location: data.stname, rating: data.rating, timestamp: timestamp,
          comment: data.msg , subCat: this.subCatString,
          solvedById: data.emitra_username, solvedByProfilePicture:data.emitra_profile_picture
          , expand: false ,link:''});
      });
      this.customerReviews = this.customerReviews.filter((data:any)=>data.comment && data.comment.length>0)
      this.customerReviews = this.customerReviews.splice(0,3);
      this.customerReviews[0]?this.customerReviews[0].link=videosLink[0]:'';
      this.customerReviews[1]?this.customerReviews[1].link=videosLink[1]:'';
      this.customerReviews[2]?this.customerReviews[2].link=videosLink[2]:'';
    }
    }))
  }

  increaseByRate(number:any): number{
    let baseDate = new Date('9/21/2022').getTime();
    let todaysDate = new Date().getTime();
    let dayDiff = Math.abs(todaysDate - baseDate);
    dayDiff = Math.ceil(dayDiff / (1000 * 60 * 60 * 24));
    let finalValue = Math.round(number + (number * 4.7)/100 * dayDiff);
    return finalValue;
  }

  viewMoreFaq(){
    this.showFaq = this.showFaq + 5;
  }

  onKnowMore(){
    this.googleAnalyticsService.eventEmitter2('know_more_pl',{});
    (window as any).hj('event', 'know_more_pl');
    if(!this.knowMore){
      if(environment.production){
        // hotjar event on know more click.
        (window as any).hj('event', 'Know_more_landing_page');
      }

      this.getCustomerReviews();
      this.knowMore = true;
      if (this.knowMoreVideo.indexOf('https://youtu.be/') !== -1) {
       this.knowMoreVideo = this.knowMoreVideo.replace('https://youtu.be/', 'https://youtube.com/embed/');
       this.knowMoreVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.knowMoreVideo);
      } else if (this.knowMoreVideo.indexOf('https://www.youtube.com/watch?v=') !== -1) {
        this.knowMoreVideo = this.knowMoreVideo.replace('&feature=youtu.be', '');
        this.knowMoreVideo = this.knowMoreVideo.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
        this.knowMoreVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.knowMoreVideo);
      }
    }
    //  scroll to know more page..
    setTimeout(() => {
      this.goToConfidenceBuilding();
    }, 100);
  }
  playKnowMoreVideo(){
    const videoLink = "https://youtu.be/2wXBqiZWjn0";
    this.playVideo(videoLink);
  }
  playVideo(videoLink:any){
    let mobileScreen = false;
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      mobileScreen = true;
    } else {
      mobileScreen = false;
    }
    let dialogRefer;
    if (mobileScreen) {
      dialogRefer = this.dialog.open(VideoPlayer, {
        width: "100%",
        minWidth: "100%",
        height: "100%",
        data: {
          videoLink: videoLink,
        },
        panelClass: "transparent-dialog_for_videPlayer",
      });
    } else {
      dialogRefer = this.dialog.open(VideoPlayer, {
        width: "36rem",
        height: "28rem",
        data: {
          videoLink: videoLink,
        },
        panelClass: "transparent-dialog_for_videPlayer",
      });
    }
    dialogRefer.afterClosed().subscribe(() => {

    });
  }

  goToConfidenceBuilding(){
    this.docEl = document.getElementById("confidence_building_scroll");
    if(this.docEl){
      this.docEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }
  goToKnowMore(){
    this.docEl = document.getElementById("knowMoreBox");
    if(this.docEl){
      this.docEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  showHideFaq(faq:any){
    this.faqList.forEach(data=>{
      //  if selected faq then hide/show faq
      if(faq.id == data.id){
        data.hidden = !data.hidden;
      }
      else{
        // close the rest of faq if open..
        data.hidden = false;
      }
    });
  }

  getInitial(string:any){
    if(string){
      return string.charAt(0).toUpperCase();
    }
    return ('E');
  }

  ngOnDestroy(){
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  navigateToProfile(data:any){
    return;
  }
}


@Component({
  selector: 'view-solution-dialog',
  templateUrl: 'view-solution-dialog.html',
  styleUrls: ['view-solution-dialog.css']
})
export class ViewSolutionDialogComponent implements OnInit{

  subCatValue;
  landing_page_free_solution = "landing_page_free_solution";
  constructor(public dialogRef: MatDialogRef<ViewSolutionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private languageService: LanguageTranslationService,
    private googleAnalyticsService: GoogleAnalyticsService) {
      if(data){
        this.subCatValue = data.subCatValue;
      }
  }

  ngOnInit(){
    this.languageService
    .getTranslationOf(this.landing_page_free_solution)
    .subscribe((value) => {
      if (value) {
        const substr = "<span style='font-weight:600'>"+this.subCatValue+"</span>"
        value = value.replace('$1',substr);
        const element = document.getElementById("landingPageFreeSolution");
        element?element.innerHTML = value:"";
      }
    });
  }

  onClose(value:any){
    this.dialogRef.close(value);
    if(value){
      this.googleAnalyticsService.eventEmitter2('view_sol',{});
      (window as any).hj('event', 'view_sol');
    }
    else{
      this.googleAnalyticsService.eventEmitter2('exit_view_sol_pu',{});
      (window as any).hj('event', 'exit_view_sol_pu');
    }
  }
}
