import { Component, NgModule, VERSION, Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'numberdecimal'
})
export class NumberFormatDecimal implements PipeTransform {
    constructor() { }

    transform(value: any, args: any): any {
        if (value !== undefined && value !== null) {
            return Math.floor(value);
        } else {
            return "";
        }
    }
}