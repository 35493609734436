
<div class="container-fluid">
  <div class="row justify-content-end">
    <div class="col-auto">
      <button class="download-btn" (click)="downloadReceipt()">
        {{'download button'|translate}}
        <img alt="Image" class="mr-2 icon" height="16px" width="10px"
        src="../../../assets/images/download_icon.svg">
      </button>
    </div>
  </div>
</div>
<div class="container-fluid" id="paymentReceiptDownload" #paymentReceiptDownload>
  <div class="row justify-content-center">
    <div class="col-auto text-center">
      <span class="heading">
        PAID SERVICE PAYMENT RECIEPT
      </span>
    </div>
  </div>
  <hr class="mt-3">
  <div class="row mt-2">
    <div class="col-auto">
      <table class="table table-responsive">
        <tbody>
          <tr>
            <td>
              Receipt No :
            </td>
            <td>
              {{paymentObj.receipt_no}}
            </td>
            <td>
              Jaano Username :
            </td>
            <td>
              {{paymentObj.username}}
            </td>
          </tr>
          <tr>
            <td>
              Date :
            </td>
            <td>
              {{paymentObj.txn_time|date:'short':'IST'}}
            </td>
            <td>
              Registered Number :
            </td>
            <td>
              {{paymentObj.phone_no}}
            </td>
          </tr>
          <tr>
            <td>
              Amount :
            </td>
            <td>
              ₹ {{paymentObj.txn_amount}}
            </td>
            <td>
              Help Request Id :
            </td>
            <td>
              {{paymentObj.helpid}}
            </td>
          </tr>
          <tr>
            <td>
              Mode :
            </td>
            <td>
              {{paymentObj.payment_mode}}
            </td>
            <td>
              Received From :
            </td>
            <td>
              {{paymentObj.full_name?paymentObj.full_name:paymentObj.username}}
            </td>
          </tr>
          <tr>
            <td>
              E-Mitra :
            </td>
            <td colspan="3">
              {{paymentObj.emitra_name}}
              <span class="light-text">
                (Payment dispatched after successful completion)
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr></tr>
          <tr class="table-header">
            <th>
              FEE HEADS
            </th>
            <th>
              RS.
            </th>
            <th>
              P.
            </th>
          </tr>
          <tr>
            <td>
              E-mitra Fees :
            </td>
            <td>
              {{paymentObj.txn_amount}}
            </td>
            <td>
              00
            </td>
          </tr>
          <tr>
            <td>
              Processing Fees :
            </td>
            <td>
              00
            </td>
            <td>
              00
            </td>
          </tr>
          <tr>
            <td>
              Additional Fees :
            </td>
            <td>
              00
            </td>
            <td>
              00
            </td>
          </tr>
          <tr>
            <th>
              Total Amount to
              Pay :
            </th>
            <th>
              {{paymentObj.txn_amount}}
            </th>
            <th>
              00
            </th>
          </tr>
          <tr>
            <th>
              Paid Amount :
            </th>
            <th>
              {{paymentObj.txn_amount}}
            </th>
            <th>
              00
            </th>
          </tr>
          <tr>
            <th>
              Due Amount :
            </th>
            <th>
              00
            </th>
            <th>
              00
            </th>
          </tr>
          <tr>
            <th>
              Rupees(in words) :
            </th>
            <th colspan="2">
              {{amountInWords|uppercase}} RUPEES ONLY
            </th>
          </tr>
          <tr></tr>
        </tfoot>
        <tfoot>
        </tfoot>
        <tfoot >
        </tfoot>
      </table>
    </div>
  </div>
  <hr>
  <div class="row mt-2 justify-content-between">
    <img class="img-circle" width="44px" height="44px"
    src="../../../assets/images/jaanoLogo.svg" alt="jaano-logo" />
    <div class="col-4 col-sm-auto jaano-text"> 
      JANNO
    </div>
    <div class="ml-auto">
      <address class="address">
        13/14, Street 13, New Delhi
        Delhi 110016, +91 9821959901
      </address>
    </div>
  </div>

</div>