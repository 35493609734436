import { Injectable } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";

import { LanguageSelectionDialog } from '../shared-components/LanguageDialog/selectlanguage.dialog';
import { ChangeLocationDialog } from "../shared-components/new-change-location/new-change-location.component";
import { GoogleAnalyticsService } from "../services/google-analytics.service";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: 'root'
})
export class LoclangDialogsService {

  dialogRefer:any;
  callback: any;

  constructor(private dialog: MatDialog, private commonService: CommonService,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  openChangeLanguageDialog(callback:any) {
    this.googleAnalyticsService.eventEmitter("lang_open","language pop-up","open login pop-up");
    (window as any).hj('event', 'lang_open');
    const dialogRefer = this.dialog.open(LanguageSelectionDialog, {
      width: '600px',
      height: '400px',
      minWidth: '328px',
      panelClass:'custom-panel-class-0'
    });
    dialogRefer.afterClosed().subscribe((res) => {
      if (res) {
        callback(res);
      }
      else {
        this.openChangeLanguageDialog(callback);
      }
    });
  }

  openChangeLocationDialog(callback:any, locationJSon = null) {
    console.log('on change location',locationJSon);
    const isAlreadyOpenDialog = localStorage.getItem("changeLocationOpen");
    const stcode = localStorage.getItem("stcode11")
    const fromHelpReqOpt = localStorage.getItem("fromHelpReqOpt");
    if(isAlreadyOpenDialog && stcode){
      localStorage.removeItem("changeLocationOpen");
      return;
    }

    // fixed issue where location popup is opening twice and asking for location twice when coming from google ads..
    if(isAlreadyOpenDialog && fromHelpReqOpt){
      localStorage.removeItem("fromHelpReqOpt");
      localStorage.removeItem("changeLocationOpen");
      return;
    }
    this.dialogRefer = this.dialog.open(ChangeLocationDialog, {
      width: '600px',
      height: '540px',
      panelClass:'custom-panel-class3',
      data: locationJSon // {lat: this.lat,lng: this.lng}
    });
    localStorage.setItem("changeLocationOpen","true");
    this.dialogRefer.afterClosed().subscribe((res:any) => {
      localStorage.removeItem("changeLocationOpen");
      if (res && res != 'immediate') {
        if (res && res.place && Object.keys(res.place).length > 0) {
          let place = res['place'];
          const unformattedLocation = place.geometry.location;
          this.commonService.setSelectedPlace(place.address_components);
          this.commonService.setSelectedLocation(unformattedLocation);
          // if (!locationJSon || (locationJSon && !locationJSon.manual)) {
          //   this.openVaacineNotificationDialog((res => {
          //   }));
          // }
          localStorage.setItem('ByDefaultLocationSet', 'false');
          callback(unformattedLocation, true);
        } else if (res && res.latitude && res.longitude) {
          this.commonService.setSelectedPlace("");
          const unformattedLocation = {
            lat: () => res.latitude,
            lng: () => res.longitude
          };
          this.commonService.setSelectedLocation(unformattedLocation);
          // if (!locationJSon || (locationJSon && !locationJSon.manual)) {
          //   this.openVaacineNotificationDialog((res => {
          //   }));
          // }
          callback(unformattedLocation, true);
        }
        else if (res == undefined) {
          this.openChangeLocationDialog(callback);
        }
        else {
          if (localStorage.getItem('ByDefaultLocationSet') == 'false') {
            this.openChangeLocationDialog(callback);
          }
        }
      } else if (!res) {
        this.openChangeLocationDialog(callback);
      }
    });
  }

}
