import { Component, Inject, ViewEncapsulation } from '@angular/core';

import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'custom-dialog-component',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.css']
})
export class CustomDialogComponent {
  data: any;

  constructor(
    public dialogref: MatDialogRef<CustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.data = dialogdata;
  }

  onClickOfAction() {
    if (this.data.click_action) {
      window.location.href = this.data.click_action;
    }
    this.dialogref.close();
  }
}