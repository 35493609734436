import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";
import { DataService } from './data.service';

declare let gtag:Function;
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  variationId:number=0;
  gbUserId:string="";
  experimentId:string="";
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private dataService:DataService) { 
    this.dataService.growthBookKeys.subscribe(response=>{
      if(response){
        this.variationId = response.variationId;
        this.gbUserId = response.gbUserId;
        this.experimentId = response.experiment_id;
      }
    })
  }
  public eventEmitter( 
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = '',
    eventValue: number = -1) {
    if (isPlatformBrowser(this.platformId)) {
      gtag('event', eventName, {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue,
        variation_id: this.variationId,
        gb_user_id: this.gbUserId,
        experiment_id:this.experimentId
      });
      console.log('gtag event',eventName,JSON.stringify({
        variation_id: this.variationId,
        gb_user_id: this.gbUserId,
        experiment_id:this.experimentId
      }))
    }
  }
  public eventEmitter2(eventName: string, eventJSON:any) {
    eventJSON.variation_id = this.variationId;
    eventJSON.gb_user_id = this.gbUserId;
    eventJSON.experiment_id = this.experimentId;
    if(isPlatformBrowser(this.platformId))
    {
      gtag('event', eventName, eventJSON);
      console.log('gtag event',eventName,JSON.stringify({
        variation_id: this.variationId,
        gb_user_id: this.gbUserId,
        experiment_id:this.experimentId
      }))
    }   
  }
  public setPageView(eventJSON:any) {
    if(isPlatformBrowser(this.platformId))
    {
      gtag('event', 'page_view', eventJSON);
      // gtag('config', 'G-16VNW7R19S', eventJSON)
    }   
  }


}
