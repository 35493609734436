import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  SimpleChange,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { NguCarouselConfig } from "@ngu/carousel";

import { DataService } from "../../../services/data.service";
import { ShowToastyService } from "../../../services/showToastyService";
import { LanguageTranslationService } from "../../../services/language-translation-service";
import { MoengageEventsService } from "../../../services/moengage_events_service";
import { LoginDialogService } from "../../../services/loginService";
import { GoogleAnalyticsService } from "../../../services/google-analytics.service";
import { CommonService } from "../../../services/common.service";
import { ReportPostDialogComponent } from "../../../shared-components/report-post-dialog/report-post-dialog.component";
import { Gratification } from "../../../shared-components/gratification-dialog/gratification.component";
import { ConfirmDialogOneComponent } from "../get-specific-help-request/confirm-dialog-one/confirm-dialog-one.component";
import { AuthService } from "../../../services/auth.service";

declare var FB: any;
declare var hj:any;

@Component({
  selector: "app-trending",
  templateUrl: "./trending.component.html",
  styleUrls: ["./trending.component.scss"],
})
export class TrendingComponent implements OnInit {
  @Input() categoryList: any;
  @Input() cat_id: any;
  @Input() subcat_id: any;
  @Input() current_helpReq_id: any;
  @Input() pageNo = 1;

  @Output()
  getTrendingPostCount: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  hasMoreTrendingHrEmitter= new EventEmitter<boolean>();

  hasMoreTrending = true;

  trendingPosts:any = [];
  windowWidth = 0;
  url: string="";
  twitterShareLink: string = "";
  whatsappShareLink: string = "";
  mobileScreen: boolean = false;
  IsLoader = false;
  lat:any;
  lng:any;
  subcategory:any;
  username:any;
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 500,
    point: {
      visible: false,
    },
    load: 2,
    velocity: 0,
    touch: true,
    loop: true,
    easing: "cubic-bezier(0, 0, 0.2, 1)",
  };

  constructor(
    private router: Router,
    private dataService: DataService,
    private toastyService: ShowToastyService,
    private moeService: MoengageEventsService,
    private dialog: MatDialog,
    private loginService: LoginDialogService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private commonService: CommonService,
    private languageService: LanguageTranslationService,
    public sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private authService:AuthService
  ) {
    this.twitterShareLink = this.commonService.getTwitterLink();
    this.whatsappShareLink = this.commonService.getWhatsappLink();
    this.username = localStorage.getItem('username');
  }

  // ngOnInit() {
  //   let httpParams = {
  //     "days": 5,
  //     "postType": 1,
  //     "username": this.commonService.getUsername()
  //   }
  //   this.dataService.getDataByEntity('getTrendingPosts', httpParams).subscribe(res => {
  //     if (res.success && res.data) {
  //       this.trendingPosts = res.data;
  //       this.getTrendingPostCount.emit(this.trendingPosts.length)
  //     } else {
  //       this.toastyService.failure(res.msg, '');
  //     }
  //   })
  // }

  ngOnInit() {
    this.commonService.selectedLocation.subscribe((value) => {
      if (value && Object.keys(value).length > 0) {
        this.languageService.getSelectedLanguage().subscribe((lang) => {
          this.lat = value.lat();
          this.lng = value.lng();
          this.callTrendingHRApi(value.lat(), value.lng(), lang);
        });
      }
    });
  }

  morecontent: any = [];

  ngOnChanges(changes: SimpleChanges) {
    const cat_id: SimpleChange = changes["cat_id"];
    // const subcat_id: SimpleChange = changes.subcat_id;
    const helpreqId: SimpleChange = changes["current_helpReq_id"];
    if ((cat_id && !cat_id.firstChange && helpreqId && !helpreqId.firstChange) || 
    this.pageNo != 1) {
      this.commonService.selectedLocation.subscribe((value) => {
        if (value && Object.keys(value).length > 0) {
          this.languageService.getSelectedLanguage().subscribe((lang) => {
            this.callTrendingHRApi(value.lat(), value.lng(), lang);
          });
        }
      });
    }
  }

  ngAfterViewInit() {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth < 768) {
      this.mobileScreen = true;
    } else {
      this.mobileScreen = false;
    }
  }

  // Deprecated
  callApi(lat:any, lng:any, lang:any) {
    if (!this.cat_id || !this.subcat_id) {
      return;
    }
    let httpParams = {
      isSearch: false,
      search: "",
      category: this.cat_id,
      subCategory: "",
      isFilter: true,
      lat: lat,
      lng: lng,
      language: lang,
      withinKm: 0,
      stcode11: localStorage.getItem("stcode11"),
      dtcode11: localStorage.getItem("dtcode11"),
      stname: localStorage.getItem("stname"),
      dtname: localStorage.getItem("dtname"),
      contentType: 0,
      sortBy: "popularity",
      username: this.commonService.getUsername(),
      pageNo: 1,
      pageSize: 5,
      creationDateTime: 0,
      endDateTime: 0,
    };
    this.dataService
      .getDataByEntity("helpRequest/getAllHelpRequestsV2", httpParams)
      .subscribe((res) => {
        if (res.success && res.data) {
          if (res.data) {
            this.trendingPosts = res.data.filter((value:any, index:any, arr:any) => {
              return value.helpid != this.current_helpReq_id;
            });
            this.processData();
          }
          this.getTrendingPostCount.emit(this.trendingPosts.length);
        } else {
          this.toastyService.failure(
            res.msg || this.languageService.getTranslationOf("server_error"),
            "Error"
          );
        }
      });
  }

  callTrendingHRApi(lat:any, lng:any, lang:any) {
    if (!this.cat_id || !this.subcat_id) {
      return;
    }

    let httpParams = {
      isSearch: false,
      search: "",
      category: this.cat_id,
      subCategory: this.subcat_id || "",
      isFilter: true,
      lat: lat,
      lng: lng,
      language: lang,
      withinKm: 0,
      stcode11: localStorage.getItem("stcode11"),
      dtcode11: localStorage.getItem("dtcode11"),
      stname: localStorage.getItem("stname"),
      dtname: localStorage.getItem("dtname"),
      contentType: 0,
      sortBy: "popularity",
      username: this.commonService.getUsername(),
      pageNo: this.pageNo,
      pageSize: 5,
      creationDateTime: 0,
      endDateTime: 0,
      currenthelpid: this.current_helpReq_id,
    };

    this.dataService
      .getDataByEntity("getTrendingHR", httpParams)
      .subscribe((res) => {
        if (res.success && res.data) {
          if (res.data && res.data) {
            if(res.data.length==0){
              this.hasMoreTrendingHrEmitter.emit(false);
              this.hasMoreTrending = false;
            }
            const posts= res.data.filter((value:any, index:any, arr:any) => {
              return value.helpid != this.current_helpReq_id;
            });
            this.trendingPosts = [...this.trendingPosts,...posts ];
            this.processData();
            if(this.hasMoreTrending){
              const element = document.getElementById('trendingHrId');
              element?element.scrollBy(0,-150):"";
            }
          }
          this.getTrendingPostCount.emit(this.trendingPosts.length);
        } else {
          this.toastyService.failure(res.msg, "");
        }
      });
  }

  processData() {
    for (const i in this.trendingPosts) {
      this.trendingPosts[i]["initial"] = this.trendingPosts[i].username
        .charAt(0)
        .toUpperCase();
      // check for youtube videos and firebase/s3 videos
      if (
        this.trendingPosts[i].help_video != null &&
        this.trendingPosts[i].help_video !== "null"
      ) {
        if (
          this.trendingPosts[i].help_video.indexOf("https://youtu.be/") !== -1
        ) {
          this.trendingPosts[i]["video"] = this.trendingPosts[
            i
          ].help_video.replace(
            "https://youtu.be/",
            "https://youtube.com/embed/"
          );
          this.trendingPosts[i]["video"] =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              this.trendingPosts[i]["video"]
            );
        } else if (
          this.trendingPosts[i].help_video.indexOf(
            "https://www.youtube.com/watch?v="
          ) !== -1
        ) {
          this.trendingPosts[i].help_video = this.trendingPosts[
            i
          ].help_video.replace("&feature=youtu.be", "");
          this.trendingPosts[i]["video"] = this.trendingPosts[
            i
          ].help_video.replace(
            "https://www.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          );
          this.trendingPosts[i]["video"] =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              this.trendingPosts[i]["video"]
            );
        } else {
          this.trendingPosts[i]["video"] = this.trendingPosts[i].help_video;
          this.trendingPosts[i]["video"] =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              this.trendingPosts[i]["video"]
            );
        }
      }
      let catgoryDetails;
      if (this.categoryList) {
        if (this.trendingPosts[i].category) {
          catgoryDetails = this.categoryList.filter(
            (x:any) => x.cat_id == this.trendingPosts[i].category
          );
        } else {
          catgoryDetails = this.categoryList.filter((x:any) => x.cat_id == "14");
        }
        this.categoryList.filter(
          (x:any) => x.cat_id == this.trendingPosts[i].category
        );
      }
      this.trendingPosts[i]["requestTitle"] = catgoryDetails[0].CatlangKey;
      let mediaArray = [];
      this.trendingPosts[i]["urlArray"] = [];
      if (
        this.trendingPosts[i].help_video != "" &&
        this.trendingPosts[i].help_video != null
      ) {
        if (this.trendingPosts[i].help_video != "") {
          let linkJson = {
            type: "video",
            link: this.trendingPosts[i]["video"],
          };
          mediaArray.push(linkJson);
        }
      }
      if (
        this.trendingPosts[i].help_voice_link != "" &&
        this.trendingPosts[i].help_voice_link != null
      ) {
        if (this.trendingPosts[i].help_voice_link != "") {
          let linkJson = {
            type: "audio",
            link: this.trendingPosts[i].help_voice_link,
          };
          mediaArray.push(linkJson);
        }
      }
      if (this.trendingPosts[i].help_pictures != null) {
        if (this.trendingPosts[i].help_pictures.length > 0) {
          this.trendingPosts[i].help_pictures.forEach((element:any) => {
            if (element != null) {
              let linkJson = {
                type: "picture",
                link: element,
              };
              mediaArray.push(linkJson);
            }
          });
        }
      } else {
        this.trendingPosts[i].help_pictures = [];
      }
      this.cd.detectChanges();
      if (mediaArray.length > 0) {
        this.trendingPosts[i]["urlArray"] = mediaArray;
      } else {
        this.trendingPosts[i]["urlArray"] = [];
      }

      this.trendingPosts[i].help_video =
        this.trendingPosts[i].help_video == ""
          ? null
          : this.trendingPosts[i].help_video;
      this.trendingPosts[i].help_voice_link =
        this.trendingPosts[i].help_voice_link == ""
          ? null
          : this.trendingPosts[i].help_voice_link;
    }
  }

  getCategoryArrayByCategoryId(id:any, sub_category:any) {
    let subcategory: any;
    subcategory = this.categoryList.filter((x:any) => x.cat_id == id);
    if (subcategory && subcategory.length > 0) {
      let value = subcategory[0].subcat_id.indexOf(sub_category);
      this.subcategory = subcategory[0].SubCatlangKey[value];
      if (!this.subcategory) {
        this.subcategory = "";
      }
    } else {
      this.subcategory = "";
    }
    return subcategory;
  }

  navigateToHelpRequestById(request:any) {
    // when route is same and just query parameter is changes, angular would not destroy and
    // again init the component. there use this strategy.
    // this.router.routeReuseStrategy.shouldReuseRoute = function() {
    //   return false;
    // }
    // this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(["help-request/getHelp-RequestbyId"], {
      queryParams: { Id: request.helpid, pageNo: request.page_index },
    });
    setTimeout(() => {
      // window.location.reload();
    }, 2000);
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_expanded-view_page",
      "",
      "User navigate to help-request expanded-view"
    );
  }
  navigateToHelpRequestById1(request:any) {
    // this.router.navigate(["user-profile/leaderboard"]);
    //return;
    const dialogOne = this.dialog.open(ConfirmDialogOneComponent, {
      disableClose: true,
    });
    this.commonService.setRecommended(
      // type: "recommended",
      request.helpid
    );
    // console.log(request.helpid);
    // this.router.navigate(["help-request/getHelp-RequestbyId"], {
    //   queryParams: { Id: request.helpid },
    // });
    // setTimeout(() => {
    //   this.autoLoad();
    //   //window.location.reload();
    //   //this.router.navigate(["user-profile/leaderboard"]);
    // }, 500);
    // this.googleAnalyticsService.eventEmitter(
    //   "web_help-request_expanded-view_page",
    //   "",
    //   "User navigate to help-request expanded-view"
    // );
  }
  autoLoad() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });
  }
  navigateToEditHelpRequestById(requestId:any,event:any) {
    event.stopPropagation();
    this.loginService.openModal("", (status: string) => {
      if (status) {
        this.router.navigate(["help-request/edit-request"], {
          queryParams: { Id: requestId },
        });
      } else {
        this.moeService.eventEmitter("web_Login/Signup_Left", {
          platform: "web",
        });
      }
    });
    //this.googleAnalyticsService.eventEmitter("web_help-request_expanded-view_page", "", "User navigate to help-request expanded-view");
  }
  navigateToProfile(username_public_profile:any,event:any) {
    event.stopPropagation();
    this.loginService.openModal("", (status: string) => {
      if (status) {
        if (this.commonService.getUsername() == username_public_profile) {
          localStorage.setItem("profile_type", "pvt");
          this.router.navigate(["user-profile"], {
            queryParams: { profile_type: "pvt" },
          });
          this.googleAnalyticsService.eventEmitter(
            "web_navigate_to_private-profile",
            "",
            "User navigate to private-profile"
          );
        } 
        // else {
        //   localStorage.setItem("profile_type", "pub");
        //   localStorage.setItem("public_username", username_public_profile);
        //   this.router.navigate(["user-profile"], {
        //     queryParams: { profile_type: "pub" },
        //   });
        //   this.googleAnalyticsService.eventEmitter(
        //     "web_navigate_to_public-profile",
        //     "",
        //     "User navigate to public-profile"
        //   );
        // }
      } else {
        this.moeService.eventEmitter("web_Login/Signup_Left", {
          platform: "web",
        });
      }
    });
  }

  share(platform:any, request:any,event:any) {
    event.stopPropagation();
    this.url = window.location.href;
    switch (platform) {
      case "facebook":
        this.facebookShare(request);
        break;
      case "twitter":
        this.twitterShare(request);
        break;
      case "whatsapp":
        this.whatsappShare(request);
        break;
    }
  }

  facebookShare(request:any) {
    // Dynamically gather and set the FB share data.
    let FBDesc = request.help_description;
    let FBTitle = request.help_title;
    let FBLink =
      window.location.origin +
      "/#/help-request/getHelp-RequestbyId?Id=" +
      request.helpid;
    FBLink = encodeURI(FBLink);
    FB.ui(
      {
        method: "share_open_graph",
        action_type: "og.shares",
        action_properties: JSON.stringify({
          object: {
            "og:url": FBLink,
            "og:title": FBTitle,
            "og:description": FBDesc,
          },
        }),
      },
      function (response:any) {
        if (response != undefined && response.length == 0) {
        }
      }
    );
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_share_fb",
      "",
      "User shares help-request on fb"
    );
  }

  twitterShare(request:any) {
    let requestURL =
      window.location.origin +
      "/#/help-request/getHelp-RequestbyId?Id=" +
      request.helpid;
    let text =
      (request.help_title && request.help_title != "NaN"
        ? request.help_title + " \n"
        : "") +
      request.help_description +
      " \n" +
      (request.help_video &&
      request.help_video != "NaN" &&
      request.help_video != "null"
        ? "\n" + request.help_video
        : "") +
      "\n" +
      ("Please click for more Info -" + requestURL + "\n");

    // Opens a pop-up with twitter sharing dialog
    var shareURL = this.twitterShareLink; //url base
    //params
    var params:any = {
      url: this.url,
      text: text,
      via: "jaano",
      //hashtags: ""
    };
    for (var prop in params)
      shareURL += "&" + prop + "=" + encodeURIComponent(params[prop]);
    window.open(
      shareURL,
      "",
      "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    );
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_share_twitter",
      "",
      "User shares help-request on twitter"
    );
  }

  whatsappShare(request:any) {
    let requestURL =
      window.location.origin +
      "/help-request/getHelp-RequestbyId?Id=" +
      request.helpid;
    //let requestURL = "https://www.facebook.com";
    let text =
      (request.help_title && request.help_title != "NaN"
        ? request.help_title + " \n"
        : "") +
      (request.help_description && request.help_description != "NaN"
        ? request.help_description + " \n"
        : "") +
      ("Please click for more Info -" + requestURL + "\n");

    if (this.mobileScreen) {
      window.open("whatsapp://send?text=" + encodeURIComponent(text));
    } else {
      let url = this.whatsappShareLink + encodeURIComponent(text);
      window.open(url, "_blank");
    }
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_share_whatsApp",
      "",
      "User shares help-request on whatsApp"
    );
  }
  markSaveUnSaveRequest(request:any, isSaved:any, i:any, event:any) {
    event.stopPropagation();
    this.loginService.openModal(
      { quote: "offer_help_save_1", text: "offer_help_save_2" },
      (status: string) => {
        if (status) {
          let requestDetails = {
            username: this.commonService.getUsername(),
            helpRequestId: request.helpid,
            isSave: !isSaved,
            lat: request.lat,
            lng: request.lng,
            stname: request.stname,
            dtname: request.dtname,
            stcode11: request.stcode11,
            dtcode11: request.dtcode11,
          };
          this.dataService
            .getDataByEntity(
              "helpRequest/saveUnSaveAHelpRequest",
              requestDetails
            )
            .subscribe((res) => {
              if (res.success == true) {
                if (res.data.is_saved) {
                  this.toastyService.success("",
                  this.languageService.getTranslated(
                    "post_saved"
                  ));
                  this.trendingPosts[i].is_saved = true;
                } else {
                  this.toastyService.success("",
                  this.languageService.getTranslated(
                    "post_unsaved"
                  ));
                  this.trendingPosts[i].is_saved = false;
                }
                this.googleAnalyticsService.eventEmitter(
                  "web_save_help-request",
                  "",
                  "User saves help-request."
                );
              }
            });
        } else {
          this.moeService.eventEmitter("web_Login/Signup_Left", {
            platform: "web",
          });
        }
      }
    );
  }

  markUserSupport(request:any, i:any,event:any) {
    event.stopPropagation();
    if (!request.sentReqForSupport) {
      request.sentReqForSupport = true;
      this.loginService.openModal(
        { quote: "offer_help_support_1", text: "offer_help_support_2" },
        (status: string) => {
          if (status) {
            if (request.is_support == 0) {
              let ele: HTMLImageElement;
              if (window.innerWidth < 768) {
                ele = document.getElementById(
                  "post_animator" + i + "_s"
                ) as HTMLImageElement;
              } else {
                ele = document.getElementById(
                  "post_animator" + i
                ) as HTMLImageElement;
              }
              ele.classList.add("animationClassOnSubmit");
              ele.src = "../../../../assets/images/jp2_animation.gif";
              setTimeout(() => {
                ele.classList.remove("animationClassOnSubmit");
                ele.src = "";
              }, 600);
            }

            let requestDetails = {
              username: this.commonService.getUsername(),
              helpRequestId: request.helpid,
              isSupport: request.is_support == 0 ? 1 : 0,
              lat: request.lat,
              lng: request.lng,
              stcode11: request.stcode11,
              dtcode11: request.dtcode11,
              stname: request.stname,
              dtname: request.dtname,
              clustername: request.clustername || "",
              clustercode: request.clustercode || -1,
              mandalname: request.mandalname || "",
              mandalcode: request.mandalcode || -1,
              user_lat: this.lat,
              user_lng: this.lng,
            };
            this.dataService
              .getDataByEntity(
                "helpRequest/markUserSupportForHelpRequest",
                requestDetails
              )
              .subscribe((res) => {
                request.sentReqForSupport = false;
                if (res.success == true) {
                  if (res.data.is_support == 0) {
                    this.trendingPosts[i].tot_support =
                      this.trendingPosts[i].tot_support - 1;
                    this.commonService.decrementJaanoPoints(2);
                    this.moeService.eventEmitter("unsupported_help_req", {
                      platform: "web",
                    });
                  } else {
                    this.trendingPosts[i].tot_support =
                      this.trendingPosts[i].tot_support + 1;
                    this.commonService.incrementJaanoPoints(2);
                    if (this.loginService.showGratificationPopupStatus()) {
                      this.dialog.open(Gratification, {
                        width: "30rem",
                        panelClass: "dialogWithNoPadding",
                        data: {
                          jp_points: 2,
                          text_jp: "for_supporting_help",
                        },
                      });
                    }
                    this.moeService.eventEmitter("supported_help_req", {
                      platform: "web",
                    });
                  }
                  this.trendingPosts[i].is_support = res.data.is_support;
                  this.googleAnalyticsService.eventEmitter(
                    "web_mark_support_on_help-request",
                    "",
                    "User marks support to help-request."
                  );
                }
              });
          } else {
            this.moeService.eventEmitter("web_Login/Signup_Left", {
              platform: "web",
            });
          }
        }
      );
    }
  }

  reportPost(helpid:any,event:any) {
    event.stopPropagation();
    this.loginService.openModal(
      { quote: "offer_help_report_1", text: "offer_help_report_2" },
      (status: string) => {
        if (status) {
          this.dialog.open(ReportPostDialogComponent, {
            data: {
              helpid: helpid,
              type: "help",
            },
            disableClose: true,
            width:'320px'
          });
        } else {
          this.moeService.eventEmitter("web_Login/Signup_Left", {
            platform: "web",
          });
        }
      }
    );
  }

  deleteHelpRequest(requestId:any, i:any,event:any,username_:any) {
    event.stopPropagation();
    this.loginService.openModal("", (status: string) => {
      if (status) {
        this.IsLoader = true;
        const detail = {
          helpId: requestId,
          username: this.commonService.getUsername(),
        };
        this.dataService.deleteHelpRequest(detail).subscribe((result) => {
          this.IsLoader = false;
          if (result.success) {
            if (result.isdeleted) {
              // location.reload();
              this.trendingPosts.splice(i, 1);
              this.toastyService.success(
                "",
                this.languageService.getTranslated(
                  "Help Request Deleted Successfully."
                )
              );
              // on successfull deletion also delete from firebase if exist.
              if(this.authService.authenticated){
                this.dataService.removeHrAndTimestampIfPaid(username_,requestId);
              }
            } else {
              this.toastyService.success("", result.msg);
              // location.reload();
            }
          }
        });
      }
    });
  }

  navigateNewHelpRequestById(request:any){

    this.commonService.setRecommended(
      // type: "recommended",
      request.helpid
    );
    this.googleAnalyticsService.eventEmitter(
          "rec_cont_card",
          "",
          "view recommended content"
    );

    let width = '400px';
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      width= '320px';
    }
    const dialogOne = this.dialog.open(ConfirmDialogOneComponent, {
      disableClose: true,
      panelClass: 'confirmDialogBox1',
      maxHeight:'620px',
      width:width,
      minWidth:'320px'
    });
    dialogOne.afterClosed().subscribe(result=>{
      if(result == true){
        this.router.navigate(["help-request/getHelp-RequestbyId"], {
          queryParams: { 
            Id: request.helpid, 
            pageNo: request.page_index,
            status: request.req_status
           },
        });
      }
    });
    this.googleAnalyticsService.eventEmitter2('rec_cont_card',{});
    (window as any).hj('event', 'rec_cont_card');
  }

  // @Output() linkIdsForPopUp = new EventEmitter<string>();

  // passIdFoPopUp(event: any) {
  //   console.log(event);
  //   this.linkIdsForPopUp.emit(event);
  // }
}
