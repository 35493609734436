<div class="container-fluid" style="height:100%; position:relative;">
    <div class="pull-top-right">
        <button class="no-border-btn" (click)="onClose()">
            <img src="../../../assets/images/cross-close-icon-2.svg" alt="cross-close-icon" 
            width="24px" height="24px">
        </button>
    </div>
    <div class="row">
        <div class="col-12 header-text" *ngIf="!isLoading">
            {{'provide_name'|translate}}
        </div>
        <div class="col-12 header-text" *ngIf="isLoading">
            {{'please_wait_'|translate}}
        </div>
        <div class="col-12 header-text" *ngIf="isLoading">
            {{'we_are_creating_request'|translate}}
        </div>
    </div>
    <div class="row mt-20">
        <div class="col-12 p-0" style="position:relative;">
            <label for="createrName">{{'name'|translate}}</label>
            <input type="text" placeholder="{{'Enter your name here'|translate}}" id="createrName"
            [(ngModel)]="createName">
        </div>
    </div>
    <div class="pull-bottom">
        <div class="col-12 text-center">
            <button class="no-border-btn" *ngIf="isLoading"> 
                <img src="../../../assets/images/progress-bar1.svg" class="move-block-right"
                alt="progress-bar">
                <img src="../../../assets/images/progress-bar2.svg" class="move-block-right-2"
                alt="progress-bar">
            </button>
            <button class="solid-btn" (click)="createHelpRequest()"  *ngIf="!isLoading">
                {{'next'|translate}}
            </button>
        </div>
    </div>
</div>