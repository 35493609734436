import { Component, Inject, ViewEncapsulation } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'img_text_button_customdiaog-component',
  templateUrl: './img_text_button_customdiaog.html',
  styleUrls: ['./img_text_button_customdiaog.component.css']
})
export class ImgTextButtonCustomDialogComponent {
  data: any;

  constructor(
    public dialogref: MatDialogRef<ImgTextButtonCustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.data = dialogdata;
  }

  onClickOfAction() {
    if (this.data.click_action) {
      window.location.href = this.data.click_action;
    }
    this.dialogref.close();
  }

  closeModal() {
    this.dialogref.close();
  }
}