import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LanguageTranslationService } from '../../services/language-translation-service';

@Component({
  selector: 'app-user-level-up-dialog',
  templateUrl: './user-level-up-dialog.component.html',
  styleUrls: ['./user-level-up-dialog.component.scss']
})
export class UserLevelUpDialogComponent implements OnInit{

  constructor(private dialogRef: MatDialogRef<UserLevelUpDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private languageService: LanguageTranslationService) {
  }
  
  ngOnInit() {
    this.languageService.startLanguageTranslator();
  }

  close() {
    this.dialogRef.close();
  }

}
