<div class="container">
    <button class="transparent-btn"  style="position: absolute; right: 10px; z-index: 1; top: 10px;"
    (click)="onClose(false)">
      <img src="../../../assets/images/cross-close-icon.svg" alt="close-icon"
      width="12px" height="12px">
    </button>
    <div class="row justify-content-around">
        <div class="col-auto text-center heading">
            <!-- {{landing_page_free_solution}} -->
            <div id="landingPageFreeSolution"></div>
        </div>
    </div>
    <div class="row" style="margin-top:37px;">
      <div class="col-12 text-center">
        <div class="img-circle">
          <img src="../../../assets/images/solved-icon.svg" alt="solution">
        </div>
      </div>
      <div class="col-12 text-center field-1">
        {{'hand_picked'|translate}}
      </div>
      <div class="col-12 text-center field-2">
        {{'solution_for_problem'|translate}}
      </div>
    </div>
    <div class="row justify-content-around" style="position:absolute;width:100%;bottom:12px">
        <div class="col-auto">
            <button class="view-solutions" (click)="onClose(true)">
                {{'view_solutions'|translate}}
            </button>
        </div>
    </div>
</div>