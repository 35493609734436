<div class="container-fluid">
  <div class="scroll-content">
    <div class="faqheading">{{'jaano_title' | translate}}</div>
    <div class="aboutdata">{{'jaano_info1' | translate}}</div>
    <div class="aboutdata">{{'jaano_info2' | translate}}</div>
    <div class="aboutdata">{{'jaano_info3' | translate}}</div>
    <div class="aboutdata">{{'jaano_info4' | translate}}</div>
    <div class="aboutdata">{{'jaano_info5' | translate}}</div>
    <div class="faqheading">{{'swaniti_initiative' | translate}}</div>
    <div class="aboutdata">{{'about_swaniti' | translate}}</div>
    <div class="faqheading">{{'pricing_title' | translate}}</div>
    <div class="aboutdata">{{'pricing_info1' | translate}}</div>
    <div class="aboutdata">{{'pricing_info2' | translate}}</div>
    <div class="aboutdata">{{'pricing_info3' | translate}}</div>
    <div class="aboutdata">{{'pricing_info4' | translate}}</div>
    <div class="aboutdata">{{'pricing_info5' | translate}}</div>
    <div class="faqheading">{{'contact_us_title' | translate}}</div>
    <div class="aboutdata">
      {{'contact_us_info' | translate}}
      <br />
      <a href="mailto:help@swaniti.in" style="font-size:14px" target="_top">help@swaniti.in</a>
      <div class="title">
        <strong>Address: </strong>
        13/14, Block 13, Sarvapriya Vihar, New Delhi, Delhi 110016
      </div>
      <div class="title">
        <strong>Phone no: </strong>
        <a [href]="'tel:'+swanitiPhoneNo" style="cursor:pointer;color: #14377B;
        font-size:14px">
          {{swanitiPhoneNoView}}
        </a>
      </div>
    </div>
    <div class="faqheading">
      <!-- <img alt="Image" class="faqicon" src="../../../../assets/images/faq-icon.svg"> -->
      <div>{{'faqs' | translate}}</div>
    </div>
    <div style="margin-top: 1rem;" class="w-100 panel-group" id="accordion">
      <div *ngFor="let i of data; index as j" class="collapsible panel panel-default">
        <div class="panel-heading" (click)="openFAQPanel(j)">
          <h4 class="panel-title">
            <i class="fa fa-caret-down" aria-hidden="true" *ngIf="showFAQPanel != 'collapse' + j"></i>
            <i class="fa fa-caret-up" aria-hidden="true" *ngIf="showFAQPanel == 'collapse' + j"></i>
            <span>{{i.title | translate}}</span>
          </h4>
        </div>
        <div id="collapse{{j}}" class="panel-collapse" *ngIf="showFAQPanel == 'collapse' + j">
          <div class="panel-body">{{i.desc | translate}}</div>
        </div>
      </div>
    </div>
    <div class="query">
      {{'for_any_queries' | translate}}
    </div>
    <div class="query" style="padding-bottom: 100px;">
      <span>{{'visit_our_website' | translate}}</span>
      <span><a [href]="swanitiUrl" target="_blank">{{swanitiUrl}}</a></span>
    </div>

  </div>
</div>
