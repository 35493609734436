import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DataService } from '../../services/data.service';
import { CommonService } from '../../services/common.service';
import { LanguageTranslationService } from '../../services/language-translation-service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'app-report-post-dialog',
  templateUrl: './report-post-dialog.component.html',
  styleUrls: ['./report-post-dialog.component.scss']
})
export class ReportPostDialogComponent implements OnInit {
  all_issues:any[] = [];
  selectedIssue = 0;
  feedback = '';
  wasClicked = false;
  onSuceess = false;

  constructor(private languageService: LanguageTranslationService,
    private dialogRef: MatDialogRef<ReportPostDialogComponent>,
    private dataService: DataService,
    private commonService: CommonService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.all_issues = ['issue1', 'issue2', 'issue3', 'issue4', 'issue5'];
    this.languageService.startLanguageTranslator();
  }

  setIssue(index:any) {
    this.selectedIssue = index + 1;
    this.feedback = '';
  }

  submit() {
    if (this.wasClicked == false) {
      this.wasClicked = true;
      if (this.data.type == 'help') {
        let params = {
          'username': this.commonService.getUsername(),
          'typeOfReport': this.selectedIssue,
          'helpRequestId': this.data.helpid,
          'feedback': this.feedback
        };

        this.dataService.getDataByEntity('helpRequest/reportHelpRequest', params).subscribe((result) => {
          if (result && result.success) {
            this.onSuceess = true;
            this.router.navigate(['./feeds/help']);
            this.googleAnalyticsService.eventEmitter("web_report_feed_help", "", "User reported Help feed");
          }
        });
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

}
