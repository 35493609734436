import { Component,Inject, Renderer2, OnInit } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { CookieService } from 'ngx-cookie';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';

import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";

import { filter, map, mergeMap, tap, timestamp } from 'rxjs/operators';

import { CommonService } from './services/common.service';
import { AuthService } from './services/auth.service';
import { LoginDialogService } from './services/loginService';
import { MessagingService } from './services/messaging.service';
import { SeoService } from "./seo.service";
import { DataService } from "./services/data.service";

import { LanguageTranslationService } from "./services/language-translation-service";
import { GoogleAnalyticsService } from './services/google-analytics.service';
import moment from 'moment';
import { environment } from '../environments/environment';
import { Subject, Subscription } from 'rxjs';
import { GrowthBook } from "@growthbook/growthbook";
import { ABTestingType } from './enums/enum.type';
import { LoclangDialogsService } from './services/loclang-dialogs.service';

declare let gtag:Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  username: any;
  shownLoginDialog = 0;
  dateStart;
  tabWasRefreshed = false;
  subscriptions: Subscription[] = [];
  abTestingType:ABTestingType = ABTestingType.Old;
  ABTestingType=ABTestingType;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private languageService: LanguageTranslationService,
    private renderer: Renderer2, @Inject(DOCUMENT) private _document: any,  @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService,
    private messagingService: MessagingService,
    private commonService: CommonService,
    private cookieService: CookieService,
    private loginService: LoginDialogService,
    private route: ActivatedRoute,
    private seoService: SeoService,
    private dataService: DataService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private _auth: AuthService,
    private langaugeService:LanguageTranslationService,
    private loclatdialog: LoclangDialogsService,
  ) {
    this.authService.initUserAuthenticationService();

  //   if (isPlatformBrowser(this.platformId)) {
  //     this.injectScripts(); //gtag script

  //     let script = this._renderer2.createElement('script'); //webengage
  //     script.id = '_webengage_script_tag';
  //     script.type = `text/javascript`;
  //     if (window.location.href.indexOf('dev-jaano.swaniti.org') > -1) {
  //       script.text = `
  //       var webengage; !function(w,e,b,n,g){function o(e,t){e[t[t.length-1]]=function(){r.__queue.push([t.join("."),arguments])}}var i,s,r=w[b],z=" ",l="init options track screen onReady".split(z),a="feedback survey notification".split(z),c="options render clear abort".split(z),p="Open Close Submit Complete View Click".split(z),u="identify login logout setAttribute".split(z);if(!r||!r.__v){for(w[b]=r={__queue:[],__v:"6.0",user:{}},i=0;i<l.length;i++)o(r,[l[i]]);for(i=0;i<a.length;i++){for(r[a[i]]={},s=0;s<c.length;s++)o(r[a[i]],[a[i],c[s]]);for(s=0;s<p.length;s++)o(r[a[i]],[a[i],"on"+p[s]])}for(i=0;i<u.length;i++)o(r.user,["user",u[i]]);setTimeout(function(){var f=e.createElement("script"),d=e.getElementById("_webengage_script_tag");f.type="text/javascript",f.async=!0,f.src=("https:"==e.location.protocol?"https://ssl.widgets.webengage.com":"http://cdn.widgets.webengage.com")+"/js/webengage-min-v-6.0.js",d.parentNode.insertBefore(f,d)})}}(window,document,"webengage");
  //       webengage.init('~71680567');
  //     `;
  //     } else if (window.location.href.indexOf('web.jaano.swaniti.org') > -1) {
  //       script.text = `
  //     var webengage; !function(w,e,b,n,g){function o(e,t){e[t[t.length-1]]=function(){r.__queue.push([t.join("."),arguments])}}var i,s,r=w[b],z=" ",l="init options track screen onReady".split(z),a="feedback survey notification".split(z),c="options render clear abort".split(z),p="Open Close Submit Complete View Click".split(z),u="identify login logout setAttribute".split(z);if(!r||!r.__v){for(w[b]=r={__queue:[],__v:"6.0",user:{}},i=0;i<l.length;i++)o(r,[l[i]]);for(i=0;i<a.length;i++){for(r[a[i]]={},s=0;s<c.length;s++)o(r[a[i]],[a[i],c[s]]);for(s=0;s<p.length;s++)o(r[a[i]],[a[i],"on"+p[s]])}for(i=0;i<u.length;i++)o(r.user,["user",u[i]]);setTimeout(function(){var f=e.createElement("script"),d=e.getElementById("_webengage_script_tag");f.type="text/javascript",f.async=!0,f.src=("https:"==e.location.protocol?"https://ssl.widgets.webengage.com":"http://cdn.widgets.webengage.com")+"/js/webengage-min-v-6.0.js",d.parentNode.insertBefore(f,d)})}}(window,document,"webengage");
  //     webengage.init('~134105a0b');
  //     `;
  //   }

  //   this._renderer2.appendChild(this._document.body, script);
  //   if ((window as any).webengage) {
  //     (window as any).webengage.onReady(() => {
  //       if ((window as any).webengage.webpush.isSubscribed() && !localStorage.getItem('isTokenCaptured')) {
  //         this.getFirebaseToken();
  //       }
  //       (window as any).webengage.webpush.onSubscribe(() => { this.getFirebaseToken() })
  //     })
  //   }
  // }

    // if (localStorage.getItem('user_location')) {
    //   // get stored location from local storage
    //   const storelocation = JSON.parse(localStorage.getItem('user_location'));
    //   const location = {
    //     lat: () => storelocation.lat,
    //     lng: () => storelocation.lng
    //   };
    //   // set stored location
    //   this.commonService.setSelectedLocation(location);
    // } else {
    //   // get user current location
    //   this.getUserLocation();
    // }
    // if (window.location.href.indexOf('localhost') > -1) {
    //   // request for notification and refresh notification token
    //   this.messagingService.requestPermission();
    // }

    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    let now = new Date();
    let existingVirtualId = this.cookieService.get('virtualId') || localStorage.getItem('virtualId');
    if(this.cookieService.get('virtualId') && !localStorage.getItem('virtualId') ){
      let virtualId :any=this.cookieService.get('virtualId');
      localStorage.setItem('virtualId', virtualId);
    }
    if(!existingVirtualId){
      localStorage.setItem('virtualId',now.valueOf().toString() + "_" + this.makeRandom(3) + Math.floor((Math.random() * 998) + 1));
      this.cookieService.put('created_at',now.valueOf().toString());
      this.cookieService.put('updated_at',now.valueOf().toString());
    }
    else{
      this.cookieService.put('updated_at',now.valueOf().toString());
    }
    let existingWebToken = this.cookieService.get('Webtoken');
    if(!existingWebToken){
      this.commonService.getWebNotificationToken().subscribe((res) => {
        this.cookieService.put('Webtoken',res);
        if(res && localStorage.getItem('Webtoken') == undefined){
          localStorage.setItem('Webtoken', res);
        } else if(res){
          localStorage.setItem('Webtoken', res);
        }
      });
      //let webtoken = this.commonService.getWebNotificationToken();

    } else {
      localStorage.setItem('Webtoken', existingWebToken);
      this.commonService.getWebNotificationToken().subscribe((res) => {
        if (res && localStorage.getItem('Webtoken') == undefined) {
          localStorage.setItem('Webtoken', res);
        }
      });
    }
    this.cookieService.put('platform','web');
    if(this.cookieService.get('is_logged__in') == undefined)  {
      if(localStorage.getItem('phone_no') && localStorage.getItem('username')){
        this.cookieService.put('is_logged__in','true');
      }
      else{
        this.cookieService.put('is_logged__in','false');
      }
      // this.cookieService.put('is_logged__in','false');
    }
    this.cookieService.put('browser_userAgent',navigator.userAgent);
    if(this.cookieService.get('Help_Video_seen') == undefined){
      this.cookieService.put('Help_Video_seen','false');
    }
    if(this.cookieService.get('localstars') == undefined){
      this.cookieService.put('localstars','false');
    }
    if(this.cookieService.get('publicfacilities') == undefined){
      this.cookieService.put('publicfacilities','false');
    }
    if(this.cookieService.get('local') == undefined){
      this.cookieService.put('local','false');
    }
    if(this.cookieService.get('official') == undefined){
      this.cookieService.put('official','false');
    }
    this.username = localStorage.getItem('username');
    if (this.username != 'undefined' && this.username != null && this.username != '' && this.username != 'null') {
      if (this.username.includes(" ")) {
        this.loginService.logOut();
        // window.location.reload();
      }
    }

    this.dateStart = moment();
    document.addEventListener(
      "visibilitychange"
      , () => {
        if (document.hidden) {
          if(!this.tabWasRefreshed){
            this.estimateEngmtTime();
          }
          this.tabWasRefreshed = false;
        }
        else{
          this.dateStart = moment();
        }
      }
    );

    router.events
      .subscribe((event: any) => {
        if (event.navigationTrigger === 'popstate') {
          this.onClickMainBody();
        }
      });

  }
  locationDialog= false;
  async getLanguage(){
    return new Promise((resolve,reject)=>{
      this.languageService.getSelectedLanguage().subscribe((language) => {
        if(!language){
          this.languageService.setSelectedLanguage('en');
          resolve('en');
        }
        else{
          resolve(language);
        }
      });
    })
  }
  async getLocation(){
    return new Promise((resolve,reject)=>{

      this.commonService.selectedLocation.subscribe((value) => {
        console.log('value from location',value);
        if (
          value &&
          Object.keys(value).length > 0 &&
          (Number(localStorage.getItem("admin_defined_location")) > 0 ||
            Number(localStorage.getItem("admin_defined_location")) < 0)
        ) {
          if (Number(localStorage.getItem("admin_defined_location")) == 1)
            localStorage.setItem("admin_defined_location", "0");
            
          console.log('inside admin location 1');
          resolve(true);
        } else {
          localStorage.setItem("admin_defined_location", "-1");
            console.log('inside admin location 2');
          if(!this.locationDialog){
            console.log('inside admin location 2');
            this.loclatdialog.openChangeLocationDialog((res:any) => {});
            this.locationDialog = true;
          }
          resolve(true);
            // console.log('inside admin location 2');
            // this.loclatdialog.openChangeLocationDialog((res:any) => {});
            // this.locationDialog = true;
        }
      })
    });
  }
  async settingLanguageAndLocation(){
    console.log('settingLanguageAndLocation');
    await this.getLanguage();
    setTimeout(async () => {
      await this.getLocation();
    }, 1000);
  }

  ngOnInit(){
    console.log('app component');
    this.settingLanguageAndLocation();
    this.setToken();
    this.createGrowthBookInstance();
    this.languageService.startLanguageTranslator();

    this.route.queryParams.subscribe((params) => {
      if(params['lat'] && params['lng']){
        let place = {
          lat: ()=> parseFloat(params['lat']),
          lng: ()=> parseFloat(params['lng'])
        }
        this.commonService.setSelectedPlace(place);
        this.commonService.setSelectedLocation(place);
      } else if(params['loc']){
        let arrOfval = params['loc'].split(',');
        let place = {
          lat: ()=> parseFloat(arrOfval[0]),
          lng: ()=> parseFloat(arrOfval[1])
        }
        this.commonService.setSelectedPlace(place);
        this.commonService.setSelectedLocation(place);
      }

      if(params['skipLocation'] == 'true'){
        localStorage.setItem('admin_defined_location', '1');
        this.subscriptions.push(this.dataService.callv3API('getGeoLocation/getUserLocationViaIP',{}).subscribe(res=>{
          if(res.success){
            let place = {
              lat: ()=> res.data.lat,
              lng: ()=> res.data.lng
            }
            localStorage.setItem('stcode11', res.data.stcode11);
            localStorage.setItem('dtcode11', res.data.dtcode11);
            localStorage.setItem('stname', res.data.stname);
            localStorage.setItem('dtname', res.data.dtname);
            localStorage.setItem('district_ln', res.data.dtname);
            localStorage.setItem('mandalname', res.data.mandalname);
            localStorage.setItem('mandalcode', res.data.mandalcode);
            localStorage.setItem('clustername', res.data.clustername);
            localStorage.setItem('clustercode', res.data.clustercode);
            this.commonService.setSelectedPlace(place);
            this.commonService.setSelectedLocation(place);
          }
        }))
      }
      if(params['referral_code']){
        const referralCode = params['referral_code'];
        this.commonService.setRefferalCode(referralCode);
        this.router.navigate([], {
          queryParams: {
            'referral_code': null
          },
          queryParamsHandling: 'merge'
        })
      }

      if(params['lang']){
        if(params['lang'] =='en' || params['lang'] =='hi' || params['lang'] =='te' || params['lang'] =='pa' || params['lang'] =='mr')
          this.languageService.setSelectedLanguage(params['lang']);
      }

      if (params['showLoginDialog'] && params['showLoginDialog'] == 'show') {
        if (this.shownLoginDialog == 0) {
          this.shownLoginDialog++;
          this.loginService.openModal('', (status: string) => {
            if (status) {
            }
          });
        }
      }
    });

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e=> this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data=> {
      let seoData = data['seo'];
      if(seoData){
      this.seoService.updateTitle(seoData['title']);
      this.seoService.updateMetaTags(seoData['metaTags']);
      }
    })

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (!!event.url && event.url.match(/^\/#/)) {
          this.router.navigate([event.url.replace('/#', '')]);
        }
      }
    });

    this.setNotificationToken();
  }

  doBeforeUnload() { //When page is refreshed or closed
    this.estimateEngmtTime();
    this.dateStart = moment();
    this.tabWasRefreshed = true;
    return true;
  }

  estimateEngmtTime(){
    const currentTime = moment();
    const seconds = currentTime.diff(this.dateStart, 'seconds');
    const durationInSeconds = moment.duration(seconds, 'seconds');
    let time_spent_in_words = `${durationInSeconds.hours()} Hours, ${durationInSeconds.minutes()} Minutes and ${durationInSeconds.seconds()} Seconds`;
    //Call api here to save data in backend
    let SESSION_ID = sessionStorage.getItem("ENGMT_SESSION_ID");
    if(SESSION_ID == 'undefined' || SESSION_ID == null || SESSION_ID == '' || SESSION_ID == 'null'){
      SESSION_ID = this.makeRandom(16);
      sessionStorage.setItem("ENGMT_SESSION_ID", SESSION_ID);
    }

    let event_generated : string = environment.production ? "production" : "debug";
    let username = "";
    let item = localStorage.getItem('username');
    if (item != 'undefined' && item != null && item != '' && item != 'null') {
      username = item;
    }
    let bundle = {
      time_spent_in_seconds : seconds,
      time_spent_in_words : time_spent_in_words,
      username : username,
      userid : this._auth.currentUserId,
      user_session_id : SESSION_ID,
      event_generated : event_generated,
      event_timestamp_in_seconds : Math.round(+new Date()/1000)
    }
    this.googleAnalyticsService.eventEmitter2("time_spent_by_user_web", bundle);
  }

  async getFirebaseToken () {
    const swRegistrations = await navigator.serviceWorker.getRegistrations()
    let isFirebaseSWRegistered = false
    swRegistrations.forEach( (swReg) => {
      if (swReg.scope === `${window.location.origin}/firebase-cloud-messaging-push-scope`) {
        try {
          isFirebaseSWRegistered = true;
          this.messagingService.getToken();
          // const webpushToken = (window as any).messaging.getToken()
          // console.log('Token generated | ', webpushToken);
        } catch (err) {
          // console.log('Unable to generate the token - ', err);
        }
      }
    });
    if (!isFirebaseSWRegistered) {
      try {
        // this will register the firebase SW and prompt won't shown again as user would be already subscribed
        this.messagingService.getToken();
        isFirebaseSWRegistered = true;
      } catch (err) {
        // console.log('Unable to generate the token :  ', err);
      }
    }
  }

  makeRandom(lengthOfCode: number) {
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
      return text;
  }


  injectScripts() {

    const gtmScriptTag = this.renderer.createElement('script');
    gtmScriptTag.type = 'text/javascript';
    gtmScriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=UA-XXXX';
    this.renderer.appendChild(this._document.body, gtmScriptTag);


    const gtagInitScript = this.renderer.createElement('script');
    gtagInitScript.type = 'text/javascript';
    gtagInitScript.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-16VNW7R19S');
    `;

    //The Id = G-16VNW7R19S was for dev-jaano.. This is updated.
    this.renderer.appendChild(this._document.body, gtagInitScript);
  }


  // getUserLocation() {
  //   const self = this;
  //   function geoSuccess(loc) {
  //     const lat = loc.coords.latitude;
  //     const lng = loc.coords.longitude;
  //     const location = {
  //       lat: () => lat,
  //       lng: () => lng
  //     };
  //     self.commonService.setSelectedLocation(location);
  //   }
  //   function geoError() {
  //   }
  //   navigator.geolocation.getCurrentPosition(geoSuccess.bind(this), geoError.bind(this));
  // }

  setNotificationToken(){
    let hasAddedNotificationLog= false;
    if(this.cookieService.get('notificationLog')){
      hasAddedNotificationLog = true;
    }
    if(!hasAddedNotificationLog){
      if (!("Notification" in window)) {
        // Check if the browser supports notifications
      } else if (Notification.permission === "granted") {
        this.dataService.setLogUserTokenInBehaviour().subscribe(response=>{
          this.cookieService.put('notificationLog','true');
        });
      }
    }
  }

  onClickMainBody(){
    const element0=document.getElementById("languageNav");
    element0?element0.style.width = "0":0;
    let element1 = document.getElementById("mySidenav");
    let element2 = document.getElementById("bottomNavbar");
    let element3 =  document.getElementById("mainBody111");
    let element4 =  document.getElementById("topNavbar11");
    let element5 = document.getElementById("bottomNavbar");
    element1?element1.style.width = "0":-1;
    element2?element2.style.opacity= '1':-1;
    element3?element3.style.opacity= '1':-1;
    element4?element4.style.opacity= '1':-1;
    element5?element5.style.pointerEvents = "initial":-1;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async createGrowthBookInstance(){
    // Create a GrowthBook instance
    gtag('get', 'G-16VNW7R19S', 'client_id', async (clientId:any) => {
    });
    const gbuuid = this.getUUID();
    console.log("gbuuid",gbuuid);
    const gb = new GrowthBook({
      apiHost: "https://cdn.growthbook.io",
      clientKey: environment.growthBookToken,
      enableDevMode: true,
      subscribeToChanges: true,
      // Targeting attributes
      trackingCallback: (experiment, result) => {
        // TODO: Use your real analytics tracking system
        console.log("Viewed Experiment", {
          experimentId: experiment.key,
          variationId: result.key
        });
        console.log('result of trackingcall',JSON.stringify(result));
        console.log('result of experiment',JSON.stringify(experiment));
        console.log('gtag',JSON.stringify({
          event_category: "experiment",
          experiment_id: experiment.key,
          variation_id: result.variationId,
          gb_user_id: gbuuid,
        }))
        // track with GA4, for example:
        gtag("event", "experiment_viewed", {
          event_category: "experiment",
          experiment_id: experiment.key,
          variation_id: result.variationId,
          gb_user_id: gbuuid,
        });
        this.dataService.setGrowthBookKeys({variationId:result.variationId,gbUserId:gbuuid,experiment_id:experiment.key});
      }
    });
    const virtualId = localStorage.getItem('virtualId')||Math.floor(Math.random()*100)
    await gb.setAttributes({
      "deviceId": virtualId
    })
    console.log('virtualid',virtualId);

    // Wait for features to be available
    await gb.loadFeatures();
    console.log('loaded gb features');
    // Simple boolean (on/off) feature flag
    if (gb.isOn("google_hr_list_case1")) {
      console.log("google_hr_list_case1 is OLD",true);
      this.dataService.setGrowthBookHr(true);
      // this.dataService.setGrowthBookHr(false);
      this.abTestingType = ABTestingType.Old;
    }
    else{
      console.log("google_hr_list_case1 is NEW",false);
      this.dataService.setGrowthBookHr(false);
      // this.dataService.setGrowthBookHr(true);
      this.abTestingType = ABTestingType.New;
    }

    // growthbook event for payment
    if (gb.isOn("payment_tool")) {
      console.log("payment_tool is OLD",true);
      this.dataService.setGrowthBookPayment(true);
      // this.dataService.setGrowthBookPayment(false);
    }
    else{
      console.log("payment_tool is NEW",false);
      this.dataService.setGrowthBookPayment(false);
      // this.dataService.setGrowthBookPayment(true);
    }
  }


  getUUID = (): string => {
    const uuidKey = "gbuuid";
    const generateUUID = (): string => 
        window.crypto.randomUUID ? window.crypto.randomUUID() : 
        "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c => 
            (parseInt(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> parseInt(c) / 4).toString(16)
        );
    const getCookie = (name: string): string | undefined => {
        const matches = `; ${document.cookie}`.split(`; ${name}=`);
        if (matches.length === 2) return matches.pop()?.split(";").shift();
    };
    const setCookie = (name: string, value: string): void => {
        const date = new Date();
        date.setTime(date.getTime() + 3456e7);
        document.cookie = `${name}=${value};path=/;expires=${date.toUTCString()}`;
    };
    const existingUUID = getCookie(uuidKey);
    if (existingUUID) return existingUUID;
    const newUUID = generateUUID();
    setCookie(uuidKey, newUUID);
    return newUUID;
  };

  setToken=()=>{
    // this.dataService.setToken().subscribe(response=>{
    //   console.log('response from set token',response);
    // })

    console.log('new set token 9.');
    const csrfToken = this.cookieService.get('csrfToken');
    if(!csrfToken){
      fetch(`${environment.baseUrl2 + "/token"}`, { credentials: "include" })
    .then(res => console.log('response from settoken',res));
    }
    // axios.get(environment.baseUrl2 + '/token', { withCredentials: true })
    // .then(res => console.log('response from settoken',res));
  }
}
