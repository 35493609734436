import { Observable } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private _auth: AuthService) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let url: string = state.url;

    return Observable.create((observer:any) => {
      const choiceCallback = (choice: boolean) => {
        if (choice) {
          observer.next(choice);
        }
        observer.complete();
      };

      this._auth.currentUserStatus((auth: boolean) => {
        choiceCallback(auth);
      });
    });
  }
}