import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  gif_image = '';
  constructor(private dialogRef: MatDialogRef<SplashScreenComponent>) { }

  ngOnInit() {
  }


  ngAfterViewInit() {
    let windowWidth = window.innerWidth;
    let duration: number = 3660; //In MilliSeconds
    if (windowWidth < 768) {
      this.gif_image = 'splash_gif.gif';
    }
    else {
      this.gif_image = 'splash_gif_desktop.gif';
    }
    setTimeout(()=>{
      this.dialogRef.close();
    }, duration);
  }
}

