import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import keys from '../../../../assets/i18n/en.json';
import { CommonService } from '../../../services/common.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';

@Component({
  selector: 'app-about-faq',
  templateUrl: './about-faq.component.html',
  styleUrls: ['./about-faq.component.scss']
})
export class AboutFaqComponent implements OnInit {

  data:any = [];
  showFAQPanel:any = null;
  selectedIndex = null;
  swanitiUrl: string = `${this.commonService.getSwanitiLink()}`;

  constructor(private commonService: CommonService, private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.setPageView(
          {
            'page_title': 'About-FAQ Web',
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });
  }

  ngOnInit() {
    for (const i in keys.faqdata) {
      this.data.push({
        title: 'faqdata.' + i + '.faq_title',
        desc: 'faqdata.' + i + '.faq_description'
      });
    }
  }

  openFAQPanel(index:any) {
    if (this.selectedIndex === index) {
      if (!this.showFAQPanel) this.showFAQPanel = `collapse${index}`;
      else this.showFAQPanel = null;
    } else {
      this.showFAQPanel = `collapse${index}`;
    }
    this.selectedIndex = index;
  }
  
  downloadFile(){
    const url= "https://firebasestorage.googleapis.com/v0/b/jaano2.appspot.com/o/Rate%20Card.pdf?alt=media&token=43244247-f9e5-4679-80f3-6a1b19e8059e";
    window.open(url);
  }

  email="help@swaniti.in"
  swanitiPhoneNo = '+918130092262';
  swanitiPhoneNoView = '+91 8130092262'
}
