
<div class="opacity_overlay" *ngIf="IsLoader">
  <mat-progress-spinner *ngIf="IsLoader" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
  </mat-progress-spinner>
</div>
<!-- <ng2-toasty [position]="'top-center'"></ng2-toasty> -->
<div class="container p-20-0" style="display:none;">
  <div class="row">
    <div class="col-sm-12 p-0">
      <div class="level-info">
        <div class="col-sm-12" style="padding: 12px 12px 0;">
           <div class="level-parent">
            <!-- <div style="cursor: pointer;" class="col-12 pd0 d-flex align-center" style="padding: 12px 12px 0;">
              <span (click)="navigateToProfile()"><img style="height: 15px;" src="../../../../assets/images/back_arrow_gray.svg" alt="Next"></span>
              <span (click)="navigateToProfile()" class="next-page text-uppercase">{{'back' | translate}}</span>
            </div> -->
            <div class="profile-info">
              <div class="user-name">
                <div class="user-dp">
                  <img *ngIf="username == 'Swaniti' else elseBlock4"
                  src="../../../../assets/images/swanitilogo.svg" class="user-dp" alt="Image" />
                <ng-template #elseBlock4>
                  <img
                    *ngIf="profile_picture != null && profile_picture != '' && profile_picture != 'undefined'"
                    src="{{profile_picture}}" class="user-dp" alt="Image">
                  <img
                    *ngIf="profile_picture == null || profile_picture == '' || profile_picture == 'undefined'"
                    src="../../../../assets/images/defaultprofilepic.svg" class="user-dp p-relative" alt="Image">
                  <div class="initial pointer" (click)="navigateToProfile()"
                    *ngIf="profile_picture == null || profile_picture == '' || profile_picture == 'undefined'">
                    {{usernameIntial}}</div>
                </ng-template>
                </div>
                <div class="pl-2">
                  <div class="profile-name">{{FullName != "" && FullName != null && FullName != undefined ? FullName: username}}</div>
                  <!-- <div class="profile-place">{{dtname}}</div> -->
                </div>
              </div>
            </div>
            <div class="user-current-level">
              <div class="col-12 pd0">
                <div class="level-star">
                  <!-- <div><img src="../../../../assets/images/gamification/level-star.svg" alt="Image"></div> -->
                  <span class="mt-1">{{'Current Level' | translate}}:</span>
                  <span class="game-level">
                    <span class="level-name" *ngIf="userDetail?.guide_level == '1'">{{'USER' | translate}}</span>
                    <span class="level-name" *ngIf="userDetail?.guide_level == '2'">{{'CITIZEN' | translate}}</span>
                    <span class="level-name" *ngIf="userDetail?.guide_level == '3'">{{'PARTNER' | translate}}</span>
                    <span class="level-name" *ngIf="userDetail?.guide_level == '4'">{{'LEADER' | translate}}</span>
                    <span class="level-name" *ngIf="userDetail?.guide_level == '5'">{{'HERO' | translate}}</span>
                    <span class="level-name float-right p-0">{{pointsPercentage}}</span>
                  </span>
                  <span class="level-image">
                    <img *ngIf="userDetail?.guide_level == '1'" src="../../../../assets/images/gamification/game_level_1.svg" class="image-size" alt="game_level"/>
                    <img *ngIf="userDetail?.guide_level == '2'" src="../../../../assets/images/gamification/game_level_2.svg" class="image-size" alt="game_level"/>
                    <img *ngIf="userDetail?.guide_level == '3'" src="../../../../assets/images/gamification/game_level_3.svg" class="image-size" alt="game_level"/>
                    <img *ngIf="userDetail?.guide_level == '4'" src="../../../../assets/images/gamification/game_level_4.svg" class="image-size" alt="game_level" />
                    <img *ngIf="userDetail?.guide_level == '5'" src="../../../../assets/images/gamification/game_level_5.svg" class="image-size" alt="game_level" />
                  </span>
                </div>

                  <div class="sm-hide w-100">
                    <div class="level-slider wid-100">
                      <div class="level-range">
                        <div class="skills" [ngStyle]="{width: pointsPercentage}"></div>
                      </div>
                      <div class="level-range-info">
                        <div class="flex-center" *ngIf="userDetail?.guide_level != '5'">
                          <div><img src="../../../../assets/images/gamification/level-coins.svg" alt="Coins"></div>
                          <div class="level-no">{{userDetail?.overall_points}} JP</div>
                        </div>
                        <div class="level-no" *ngIf="userDetail?.guide_level != '5'">
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="Coins">
                          {{levelPoints}}&nbsp;JP
                        </div>
                        <div *ngIf="userDetail?.guide_level == '5'">
                          {{userToScore}}&nbsp;{{'You have reached the top level' | translate}}
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="col-12 md-hide p-0">
                <div class="level-slider wid-100">
                  <div class="level-range">
                    <div class="skills" [ngStyle]="{width: pointsPercentage}"></div>
                  </div>
                  <div class="level-range-info">
                    <div class="flex-center" *ngIf="userDetail?.guide_level != '5'">
                      <div><img src="../../../../assets/images/gamification/level-coins.svg" alt="Coins"></div>
                      <div class="level-no">{{userDetail?.overall_points}} JP</div>
                    </div>
                    <div *ngIf="userDetail?.guide_level != '5'">
                      {{userToScore}}&nbsp;{{'JP to level up' | translate}}
                    </div>
                    <div *ngIf="userDetail?.guide_level == '5'">
                      {{userToScore}}&nbsp;{{'You have reached the top level' | translate}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="divider">
              <hr>
            </div>
            <div class="level-steps-wrapper" *ngIf="userDetail?.guide_level == 1">
              <div class="col-sm-12 level-steps-head">{{'3 Quick Steps to Level up'| translate}}</div>
              <div class="col-12">
                <div class="row">
                  <div class="col-4 sm-plr05">
                    <div [ngClass]="firstLevel == true?'level-step level-active':'level-step'">
                      <div class="mb-3 md-ht-60">{{'Update your Profile Photo & Full name' | translate}}</div>
                      <div class="step-points">
                        <div class="mark-tick"><img src="../../../../assets/images/gamification/step-tick.svg" alt="mark"></div>
                        <span>
                           <img class="ht-21" src="../../../../assets/images/gamification/level-coins.svg" alt="Coins">
                        </span>
                        <span  (click)="navigateToPage('Profile')">
                          {{'500 JP' | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 sm-plr05">
                    <div [ngClass]="secondLevel == true?'level-step level-active':'level-step'">
                      <div class="mb-3 md-ht-60">{{"Write about yourself in 'About Me'" | translate}}</div>
                      <div class="step-points">
                        <div class="mark-tick"><img src="../../../../assets/images/gamification/step-tick.svg" alt="mark"></div>
                        <span>
                           <img class="ht-21" src="../../../../assets/images/gamification/level-coins.svg" alt="Coins">
                        </span>
                        <span  (click)="navigateToPage('Profile')">
                             {{'250 JP'|translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 sm-plr05">
                    <div [ngClass]="thirdLevel == true?'level-step level-active':'level-step'">
                      <div class="mb-3 md-ht-60">{{"Add your 'User Attributes'" | translate}}</div>
                      <div class="step-points">
                        <div class="mark-tick"><img src="../../../../assets/images/gamification/step-tick.svg" alt="mark"></div>
                        <span>
                           <img class="ht-21" src="../../../../assets/images/gamification/level-coins.svg" alt="Coins">
                        </span>
                        <span  (click)="navigateToPage('Profile')">
                          {{'250 JP'|translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           </div>
           <div class="level-parent-info">
             <div class="col-12 local-star-head">
              <span>{{'Your Journey to ‘Local Stars’' | translate}}</span>
               <span class="info-icon">
                 <a  data-toggle="modal" data-target="#localStar_info">
                 <img src="../../../../assets/images/gamification/info-icon.svg" alt="info">
               </a>
              </span>
             </div>
             <div class="col-12 accordion pd0" id="accordionEx" role="tablist" aria-multiselectable="true">
              <div class="col-12 flex-wrapper mb-2 overflow-auto" style="padding: 0 5px;">
                <!-- user -->
               <div class="abox">
                <ul class="level-image-info">
                  <li id="user_levelStar" class="image-size"></li>                  
                </ul>
                <div [ngClass]="userDetail?.guide_level >= 1 ?'level-name text-center  name-active':'level-name text-center'">{{'USER' | translate}}</div>
                  <div class="level-points" *ngIf="userDetail?.guide_level <= 1">
                    <span><img src="../../../../assets/images/gamification/level-coins.svg" alt="Coins"> <span>{{'0 JP'| translate}}</span></span>
                  </div>
               </div>
               <!-- user -->
               <!-- citizen -->
               <div role="tab" id="head1" class="abox">
                <a data-toggle="collapse" #cititzen href="#citizenSteps" (click)="openedDiv = 1" role="button" aria-expanded="false" aria-controls="citizenSteps">
                <ul class="level-image-info">
                  <li *ngIf="userDetail?.guide_level >= 2" id="citizen_levelStar-active" class="image-size"></li>
                  <li *ngIf="userDetail?.guide_level < 2" id="citizen_levelStar" class="image-size"></li>
                </ul>
                <div [ngClass]="userDetail?.guide_level >= 2 ?'level-name text-center name-active':'level-name text-center'">{{'CITIZEN' | translate}}</div>
                  <div class="level-points" *ngIf="userDetail?.guide_level <= 2">
                    <span><img src="../../../../assets/images/gamification/level-coins.svg" alt="Coins"> <span>{{'1000 JP' | translate}}</span></span>
                  </div>
                  </a>                 
              </div>
              <!-- citizen -->
              <!-- partner -->
              <div role="tab" id="head2" class="abox">
                <a data-toggle="collapse" #partner href="#partnerSteps" (click)="openedDiv = 2"  role="button" aria-expanded="false" aria-controls="partnerSteps">
                <ul class="level-image-info">
                  <li *ngIf="userDetail?.guide_level < 3" id="partner_levelStar" class="image-size"></li>
                  <li *ngIf="userDetail?.guide_level >= 3" id="partner_levelStar-active" class="image-size"></li>
                </ul>
                <div [ngClass]="userDetail?.guide_level >= 3 ?'level-name text-center name-active':'level-name text-center'">{{'PARTNER' | translate}}</div>
                  <div class="level-points" *ngIf="userDetail?.guide_level <= 3">
                    <span><img src="../../../../assets/images/gamification/level-coins.svg" alt="Coins"> <span>{{'5000 JP' | translate}}</span></span>
                  </div>
                  </a>
              </div>
              <!-- partner -->
              <!-- leader -->
              <div role="tab" id="head3" class="abox">
                <a data-toggle="collapse" #leader href="#leaderSteps" (click)="openedDiv = 3"  role="button" aria-expanded="false" aria-controls="leaderSteps">
                <ul class="level-image-info">
                  <li *ngIf="userDetail?.guide_level < 4" id="leader_levelStar" class="image-size"></li>
                  <li *ngIf="userDetail?.guide_level >= 4" id="leader_levelStar-active" class="image-size"></li>
                </ul>
                <div [ngClass]="userDetail?.guide_level >= 4 ?'level-name text-center  name-active':'level-name text-center'">{{'LEADER' | translate}}</div>
                  <div class="level-points" *ngIf="userDetail?.guide_level <= 4">
                    <span><img src="../../../../assets/images/gamification/level-coins.svg" alt="Coins"> <span>{{'15000 JP' | translate}}</span></span>
                  </div>
                </a>
              </div>
              <!-- leader -->
              <!-- hero -->
              <div role="tab" id="head4" class="abox">
                <a data-toggle="collapse" #hero href="#heroSteps"  (click)="openedDiv = 4"  role="button" aria-expanded="false" aria-controls="heroSteps">
                <ul class="level-image-info">
                  <li *ngIf="userDetail?.guide_level < 5" id="hero_levelStar" class="image-size"></li>
                  <li *ngIf="userDetail?.guide_level >= 5" id="hero_levelStar-active" class="image-size"></li>
                </ul>
                <div [ngClass]="userDetail?.guide_level >= 5 ?'level-name text-center  name-active':'level-name text-center'">{{'HERO' | translate}}</div>
                  <div class="level-points" *ngIf="userDetail?.guide_level <= 5">
                    <span><img src="../../../../assets/images/gamification/level-coins.svg" alt="Coins"> <span> {{'50000 JP' | translate}}</span></span>
                  </div>
                  </a>
              </div>
              <!-- hero -->
               </div>
               <!-- citizen info -->
               <div class="collapse" id="citizenSteps" role="tabpanel" aria-labelledby="head1" data-parent="#accordionEx">
                <span class="arrow-up"></span>
                <div class="card card-body">
                  <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="citizen_step1" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'Your profile gets the special identity of' | translate}} <span class="fw-b">{{'CITIZEN' | translate}}</span></div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="citizen_step2" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'Your post gets priority in Local Feed.' | translate}}</div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="citizen_step3" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'A Star logo is displayed on your profile.' | translate}}</div>
                  </div>
                </div>
                </div>
              </div>
              <!-- citizen info -->
              <!-- partner info -->
              <div class="collapse" id="partnerSteps" role="tabpanel" aria-labelledby="head2" data-parent="#accordionEx">
                <span class="arrow-up"></span>
                <div class="card card-body">
                  <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="partner_step1" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'Your profile gets the special identity of' | translate}} <span class="fw-b">{{'PARTNER' | translate}}</span></div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="partner_step2" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'Your post gets priority in Local Feed.' | translate}}</div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="partner_step3" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'A Medal logo is displayed on your profile.' | translate}}</div>
                  </div>
                </div>
                </div>
              </div>
              <!-- partner info -->
              <!-- leader info -->
              <div class="collapse" id="leaderSteps" role="tabpanel" aria-labelledby="head3" data-parent="#accordionEx">
                <span class="arrow-up"></span>
                <div class="card card-body">
                  <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="leader_step1" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'Your profile gets the special identity of' | translate}} <span class="fw-b">{{'LEADER' | translate}}</span></div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="leader_step2" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'Your post gets priority in Local Feed.' | translate}}</div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="leader_step3" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'You get a chance to feature in national /state public media via Jaano platform.' | translate}}</div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="leader_step4" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'A Trophy logo is displayed on your profile.' | translate}}</div>
                  </div>
                </div>
                </div>
              </div>
              <!-- leader info -->
               <!-- hero info -->
               <div class="collapse" id="heroSteps" role="tabpanel" aria-labelledby="head4" data-parent="#accordionEx">
                <span class="arrow-up"></span>
                <div class="card card-body">
                  <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="hero_step1" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'Your profile gets the special identity of' | translate}} <span class="fw-b">{{'HERO' | translate}}</span></div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="hero_step2" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'Your post gets priority in Local Feed.' | translate}}</div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="hero_step3" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{"You are recognised as 'Jaano certified Social Leader'." | translate}}</div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="hero_step4" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{"You get exclusive feature of 'Status' at District level." | translate}}</div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="hero_step5" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'You get a chance to feature in national /state public media via Jaano platform.' | translate}}</div>
                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="sub-card">
                    <div>
                      <ul class="steps-card">
                      <li id="hero_step6" class="image-size"></li>
                    </ul>
                  </div>
                    <div class="pl-2">{{'A Diamond logo distinguishes your profile from others on Jaano app.' | translate}}</div>
                  </div>
                </div>
                </div>
              </div>
              <!-- hero info -->
             </div>
             <div class="col-12 flex-center mt-4" (click)="openDiv()">
               <div class="btn btn-blue" *ngIf="openedDiv == 0">{{'See what’s in store next!' | translate}}</div>
               <div class="btn btn-blue" *ngIf="openedDiv >0 && openedDiv <4">{{'next' | translate}}</div>
               <div class="btn btn-blue" *ngIf="openedDiv == 4">{{'Close' | translate}}</div>
             </div>
           </div>
           <div class="modal fade" id="localStar_info" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">             
                <div class="modal-body">
                 <div class="col-sm-12">
                     <div class="row">
                       <div class="col-sm-6 b-right">
                         <ul class="head-img">
                          <li id="localStar" class="localStar-size"></li>
                         </ul>
                         <div class="col-12 localStar-heading">{{'What is Local Stars?' | translate}}</div>
                         <div class="col-12 localStar-para"><span class="fw-b">‘{{'Local Stars' | translate}}’</span> {{"is a leaderboard which ranks the profile of 'civically active people' near you based
                          on the contribution to help others on the Jaano platform. Use Jaano and make your place." | translate}}</div>
                       </div>
                       <div class="col-sm-6">
                        <ul class="head-img">
                         <li id="localStarInfo" class="localStar-size"></li>
                        </ul>
                        <div class="col-12 localStar-heading">{{'How can I become Local Star?' | translate}}</div>
                        <div class="col-12 localStar-para">
                          <p>{{'You have to earn' | translate}} <span class="fw-b">‘{{'Jaano Points' | translate}}’</span>{{'to become a' | translate}} ‘{{'Local Stars' | translate}}’.
                            {{'One can earn the' | translate}}‘{{'Jaano Points' | translate}}’ {{'by contributing to the society:' |translate}}</p>
                            <ol class="order-para">
                              <li>{{'Helping people by suggesting solutions to their problems.' | translate}}</li>
                              <!-- <li>{{'Sharing information in the local community.' | translate}}</li> -->
                              <!-- <li>{{'Review public facilities.' | translate}}</li> -->
                            </ol>
                        </div>
                      </div>
                     </div>
                 </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-blue" data-dismiss="modal">{{'Got It!' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="accordion mt-3" id="pointsList">
            <div class="card">
              <div class="card-header bg-img" id="pointsHead">
                  <button class="btn left-dropdown" type="button" data-toggle="collapse" data-target="#earnPoints" aria-expanded="false" aria-controls="earnPoints">
                    <div class="head-wrapper">
                      <div class="head-text">
                        <div>{{'How to earn' | translate}}</div>
                        <div class="main-head">{{'JAANO POINTS' | translate}}</div>
                      </div>
                      <div class="dropdown-arrow"><img src="../../../../assets/images/gamification/according-arrow.svg" alt="arrow"></div>
                    </div>
                  </button>
              </div>
          
              <div id="earnPoints" class="collapse show" aria-labelledby="pointsHead" data-parent="#pointsList">
                <div class="card-body">
                  <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'Joining bonus for signing-up'| translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'100 JP' | translate}}</span>
                      </div>
                      <div class="subCard-received"><span class="blue-tick"><img src="../../../../assets/images/gamification/blue-tick.svg" alt="Image"></span> {{'Received' | translate}}</div>
                    </div>
                  </div>

                  <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'Verify your identity by adding your photo, full name, bio, attributes, gender, DOB., etc.' | translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'250 JP'}} ({{'per detail' | translate}})</span>
                      </div>
                      <div class="subCard-earn btn-blue" *ngIf="!firstLevel || !secondLevel || !thirdLevel" (click)="navigateToPage('Profile')">{{'Earn Points' | translate}}</div>
                      <div class="subCard-received"  *ngIf="firstLevel && secondLevel && thirdLevel"><span class="blue-tick"><img src="../../../../assets/images/gamification/blue-tick.svg" alt="Image"></span> {{'Received' | translate}}</div>                    
                    </div>
                  </div>

                  <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'On receiving a Thank You note for your suggestion which has been accepted as the solution' | translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'100 JP' | translate}}</span>
                      </div>
                      <div class="subCard-know" data-toggle="modal" data-target="#knowModal">{{'Know More' | translate}}</div>
                    </div>
                  </div>
                  <div class="modal fade" id="knowModal" tabindex="-1" role="dialog" aria-labelledby="knowModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content p-0">
                        <div><img src="../../../../assets/images/gamification/thankYouNote.svg" alt="thankYouNote" class="wid-100"></div>
                         <div class="col-sm-12">
                             <div class="row">
                               <div class="col-sm-12 know-text"> 
                                <div class="col-12 col-lg-9 localStar-para">
                                  <p>You can receive a <span class="fw-b">‘{{'Thank you note' | translate}}’</span>from someone who finds your suggestion on a help request as the solution to his/her request.</p>
                                    <p><span class="fw-b">a. </span>Go to Help Request feed on Jaano.</p>
                                    <p><span class="fw-b">b. </span>Suggest a solution to an open Help request.</p>
                                    <p><span class="fw-b">c. </span>If your suggestion works and is marked as the solution, you may receive a Thank you note from the person who had requested help, as a token of gratitude and appreciation.</p>
                                    <p>A <span class="fw-b">Thank you note </span>grants Jaano Points to both the sender and the receiver of the Thank you note. Thank you notes are visible on a user’s public profile.</p>
                                </div>
                              </div>
                             </div>
                         </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-blue" data-dismiss="modal">{{'Got It!' | translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'On adding or verifying information about Public Facilities' | translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'25 JP' | translate}}</span>
                      </div>
                      <div class="subCard-earn btn-blue">{{'Earn Points' | translate}}</div>
                    </div>
                  </div> -->


                  <!-- <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'On sharing feedback by reviewing and rating Public Facilities' | translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'10 JP' | translate}}</span>
                      </div>
                      <div class="subCard-earn btn-blue">{{'Earn Points' | translate}}</div>
                    </div>
                  </div> -->

                  <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'On suggesting solution on help requests' | translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'10 JP' | translate}}</span>
                      </div>
                      <div class="subCard-earn btn-blue"  (click)="navigateToPage('Help')">{{'Earn Points' | translate}}</div>
                    </div>
                  </div>

                  <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'On giving a Thank You note to the best suggestion' |translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'10 JP' | translate}}</span>
                      </div>
                      <div class="subCard-earn btn-blue" (click)="navigateToPage('profileHelp')">{{'Earn Points' | translate}}</div>
                    </div>
                  </div>

                  <!-- <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'On creating a post in Local Feed' | translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'10 JP' | translate}}</span>
                      </div>
                      <div class="subCard-earn btn-blue">{{'Earn Points' | translate}}</div>
                    </div>
                  </div> -->

                  <!-- <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'On commenting on the posts in Local Feed' | translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'02 JP' | translate}}</span>
                      </div>
                      <div class="subCard-earn btn-blue">{{'Earn Points' | translate}}</div>
                    </div>
                  </div> -->

                  <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'On supporting help requests' | translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'02 JP' | translate}}</span>
                      </div>
                      <div class="subCard-earn btn-blue"  (click)="navigateToPage('Help')">{{'Earn Points' | translate}}</div>
                    </div>
                  </div>

                  <div class="earnPoints-subCard">
                    <div class="subCard-head">{{'Every time someone likes your suggestion' | translate}}</div>
                    <div class="subCard-status">
                      <div class="subCard-coins">
                        <span>
                          <img src="../../../../assets/images/gamification/level-coins.svg" alt="coins">
                        </span>
                        <span class="ml-2">{{'01 JP (per like for first 90 likes)' | translate}}</span>
                      </div>
                      <div class="subCard-earn btn-blue" (click)="navigateToPage('Help')">{{'Earn Points' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="display:none;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div>
          <ul class="levelUp-image">
            <li id="citizen_levelUp" class="imageUp-size"></li>
            <!-- <li id="partner_levelUp" class="imageUp-size"></li>
            <li id="leader_levelUp" class="imageUp-size"></li>
            <li id="hero_levelUp" class="imageUp-size"></li> -->
          </ul>
        </div>
        <div class="text-center">
          <div class="levelUp-head">Congratulations!</div>
          <div class="levelUp-para">You are a <span class="fw-b">CITIZEN</span> now.</div>
        </div>
      </div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>


<!-- new changes -->
<div style="max-height: calc(100vh - 48px); overflow-y: auto;">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-16">
        <div class="card card-body custom-card p-0">
          <div class="info-icon" (click)="referalInfo()">
            <img src="../../../../assets/images/about-us-icon-4.svg" alt="info-icon">
          </div>
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12 text-center mt-24">
                <img src="../../../../assets/images/jaanoLogo.svg" alt="jaano-icon-3" width="80px" height="80px">
              </div>
              <div class="col-12 text-center mt-24">
                <div class="card earn-rewards">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col p-0 text-left" style="margin:auto;">
                        <div class="text-bold-1 text-green">
                          {{'Earn cash rewards'|translate}}
                        </div>
                        <div class="text-light">
                          {{'by referring friends to Jaano! '|translate}}
                        </div>
                      </div>
                      <div class="col-auto p-0">
                        <img src="../../../../assets/images/gift-icon.svg" alt="gift-box" class="gift-box">
                      </div>
                    </div>
                    <div class="row mt-16">
                      <div class="col-auto content-center p-0">
                        <img src="../../../../assets/images/feedback2.svg" alt="rating-start" class="star">
                      </div>
                      <div class="col p-0 content-center ml--8 mr--8">
                        <hr style="width:100%; border-color:rgba(0, 175, 49, 0.30);" />
                      </div>
                      <div class="col-auto content-center p-0">
                        <img src="../../../../assets/images/feedback2.svg" alt="rating-start" class="star">
                      </div>
                      <div class="col p-0 content-center ml--8 mr--8">
                        <hr style="width:100%; border-color:rgba(0, 175, 49, 0.30);" />
                      </div>
                      <div class="col-auto content-center p-0">
                        <img src="../../../../assets/images/feedback2.svg" alt="rating-start" class="star">
                      </div>
                    </div>
                    <div class="row mt-16">
                      <div class="col-12 text-bold-2 text-green">
                        {{'referal_info_header'|translate}}
                      </div>
                    </div>
                    <div class="row mt-16">
                      <div class="col rs-block">
                        <div class="img-circle-2 yellow active">
                          1
                        </div>
                        <div class="content-center active">
                          ₹10
                        </div>
                      </div>
                      <div class="col-auto plr-4">
                        <img src="../../../../assets/images/dash-icon-4.svg" alt="dash-icon" class="sm-show">
                        <img src="../../../../assets/images/dash-icon-3.svg" alt="dash-icon" class="lg-show">
                      </div>
                      <div class="col rs-block">
                        <div class="img-circle-2 yellow active">
                          10
                        </div>
                        <div class="content-center active">
                          ₹70
                        </div>
                      </div>
                      <div class="col-auto plr-4">
                        <img src="../../../../assets/images/dash-icon-4.svg" alt="dash-icon" class="sm-show">
                        <img src="../../../../assets/images/dash-icon-3.svg" alt="dash-icon" class="lg-show">
                      </div>
                      <div class="col rs-block">
                        <div class="img-circle-2 yellow active">
                          30
                        </div>
                        <div class="content-center active">
                          ₹125
                        </div>
                      </div>
                      <div class="col-auto plr-4">
                        <img src="../../../../assets/images/dash-icon-4.svg" alt="dash-icon" class="sm-show">
                        <img src="../../../../assets/images/dash-icon-3.svg" alt="dash-icon" class="lg-show">
                      </div>
                      <div class="col rs-block">
                        <div class="img-circle-2 yellow active">
                          70
                        </div>
                        <div class="content-center active">
                          ₹175
                        </div>
                      </div>
                      <div class="col-auto plr-4">
                        <img src="../../../../assets/images/dash-icon-4.svg" alt="dash-icon" class="sm-show">
                        <img src="../../../../assets/images/dash-icon-3.svg" alt="dash-icon" class="lg-show">
                      </div>
                      <div class="col rs-block">
                        <div class="img-circle-2 yellow active">
                          100
                        </div>
                        <div class="content-center active">
                          ₹300
                        </div>
                      </div>
                    </div>
                    <div class="row mt-16">
                      <div class="col-12 text-light p-0">
                        {{'Start earning now with our referral program.'|translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-16">
        <div class="card custom-card p-0">
          <div class="card-header">
            {{'refer_a_friend'|translate}}
          </div>
          <div class="card-body">
            <div class="row" style="width: 100%;">
              <div class="col-12">
                <div class="input-group mb-2">
                  <input type="text" class="custom-input" id="inlineFormInputGroup" 
                  placeholder="Refer code"  [value]="referalCode" readonly>
                  <div class="input-group-append">
                    <div class="input-group-text" (click)="copyInputMessage()">
                      <img src="../../../../assets/images/copy-icon.svg" alt="copy-icon">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-between" style="width: 100%;">
              <div class="col content-center">
                <hr style="width:100%">
              </div>
              <div class="col-auto p-0 content-center light-text">
                {{'invite_via'|translate}}
              </div>
              <div class="col content-center">
                <hr style="width:100%">
              </div>
            </div>
            <div class="row justify-content-around" style="width: 100%;">
              <div class="col content-start lg-hide">
                <div class="img-circle-1" (click)="shareOnWhatsapp()">
                  <img src="../../../../assets/images/whatsapp-icon-1.svg" alt="whatsapp_share_white" width="24px" height="24px">
                </div>
              </div>
              <div class="col content-center lg-hide">
                <div class="img-circle-1" (click)="shareOnMessage()">
                  <img src="../../../../assets/images/message-icon-1.svg" alt="message-icon-1" width="24px" height="24px">
                </div>
              </div>
              <div class="col content-center-right">
                <div class="img-circle-1" (click)="shareOnApp()">
                  <img src="../../../../assets/images/share-icon.svg" alt="share-icon" width="24px" height="24px">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-16" *ngIf="scoreList && scoreList.length>0">
        <div class="card custom-card p-0">
          <div class="card-header">
            <div class="row">
              <div class="col-auto">
                {{'referal_score'|translate}}
              </div>
              <div class="col-auto ml-auto color-blue cursor-pointer" (click)="onRedeem()">
                {{'redeem'|translate}} ₹{{scoreList[scoreList.length-1].amount}}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col rs-block">
                  <div class="img-circle-2" [ngClass]="{'active-img-circle-2':scoreList.length>0}">
                    1
                  </div>
                  <div class="content-center" [ngClass]="{'active':scoreList.length>0}">
                    ₹10
                  </div>
                </div>
                <div class="col-auto plr-8">
                  <img src="../../../../assets/images/dash-icon-2.svg" alt="dash-icon" class="sm-show">
                  <img src="../../../../assets/images/dash-icon.svg" alt="dash-icon" class="lg-show">
                </div>
                <div class="col rs-block">
                  <div class="img-circle-2" [ngClass]="{'active-img-circle-2':scoreList.length>1}">
                    10
                  </div>
                  <div class="content-center" [ngClass]="{'active':scoreList.length>1}">
                    ₹70
                  </div>
                </div>
                <div class="col-auto plr-8">
                  <img src="../../../../assets/images/dash-icon-2.svg" alt="dash-icon" class="sm-show">
                  <img src="../../../../assets/images/dash-icon.svg" alt="dash-icon" class="lg-show">
                </div>
                <div class="col rs-block">
                  <div class="img-circle-2" [ngClass]="{'active-img-circle-2':scoreList.length>2}">
                    30
                  </div>
                  <div class="content-center" [ngClass]="{'active':scoreList.length>2}">
                    ₹125
                  </div>
                </div>
                <div class="col-auto plr-8">
                  <img src="../../../../assets/images/dash-icon-2.svg" alt="dash-icon" class="sm-show">
                  <img src="../../../../assets/images/dash-icon.svg" alt="dash-icon" class="lg-show">
                </div>
                <div class="col rs-block">
                  <div class="img-circle-2" [ngClass]="{'active-img-circle-2':scoreList.length>3}">
                    70
                  </div>
                  <div class="content-center" [ngClass]="{'active':scoreList.length>3}">
                    ₹175
                  </div>
                </div>
                <div class="col-auto plr-8">
                  <img src="../../../../assets/images/dash-icon-2.svg" alt="dash-icon" class="sm-show">
                  <img src="../../../../assets/images/dash-icon.svg" alt="dash-icon" class="lg-show">
                </div>
                <div class="col rs-block">
                  <div class="img-circle-2" [ngClass]="{'active-img-circle-2':scoreList.length>4}">
                    100
                  </div>
                  <div class="content-center" [ngClass]="{'active':scoreList.length>4}">
                    ₹300
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 text-center">
                  <span>
                    <img src="../../../../assets/images/congo-icon.svg" alt="congo-icon" width="16px" height="16px">
                  </span>
                  <span class="text-1">{{'Congratulations'|translate}}!</span>
                  <span>
                    <img src="../../../../assets/images/congo-icon.svg" alt="congo-icon" width="16px" height="16px" class="flip-img">
                  </span>
                </div>
                <div class="col-12 text-center text-2 mt-8">
                  {{referralInfo}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-16" *ngIf="referralList && referralList.length>0">
        <div class="card custom-card p-0">
          <div class="card-header">
            <div class="row">
              <div class="col-auto">
                {{'my_referal'|translate}}
              </div>
              <div class="col-auto ml-auto" style="text-transform: capitalize;">
                {{'completed'|translate}} {{referralList.length}}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="container-fluid">
              <div class="row referal-list" *ngFor="let item of referralList">
                <div class="col-12">
                  <div class="row">
                    <div class="col-auto" style="display:inline-flex;">
                      <div class="user-img">
                        <img [src]="item.profilePicture?item.profilePicture:'../../../../assets/images/user-profile-photo.svg'" alt="user profile" 
                        width="32px" height="32px">
                      </div>
                      <div class="user-info">
                        <div class="row">
                          <div class="col-12 username">
                            {{item.fullName}}
                          </div>
                          <div class="col-12 phone-no">
                            {{item.phoneno}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto ml-auto completed content-center" style="text-transform: capitalize;">
                      {{item.status}}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <hr class="mt-16 mb-16">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-16" id="referralCard2">
        <div class="card p-0 referral-card-2" style="margin-bottom:16px;">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-center">
                {{'Earn cash rewards in 3 simple steps!'|translate}}
              </div>
            </div>
          </div>
          <div class="card-body" style="max-height:inherit !important;background: rgba(48, 79, 254, 0.10);">
            <div class="container-fluid" style="position:relative;">
              <div class="row">
                <div class="col-12 content-center referral-img-block">
                  <img [src]="'https://jaano2.s3.ap-south-1.amazonaws.com/referral-card-bck-img.svg'" alt="referral_bck" >
                </div>
                <div class="steps step-1">
                  <div class="heading">
                    {{'Step'|translate}} 1
                  </div>
                  <div class="body">
                    {{'referral_1'|translate}}
                  </div>
                </div>
                <div class="infos info-1">
                  {{'referral_11'|translate}}
                </div>
                <div class="steps step-2">
                  <div class="heading">
                    {{'Step'|translate}} 2
                  </div>
                  <div class="body">
                    {{'referral_2'|translate}}
                  </div>
                </div>
                <div class="infos info-2">
                  {{"referral_21"|translate}}
                </div>
                <div class="steps step-3">
                  <div class="heading">
                    {{'Step'|translate}} 3
                  </div>
                  <div class="body">
                    {{'referral_3'|translate}}
                  </div>
                </div>
                <div class="infos info-3">
                  {{'referral_31'|translate}} {{'referral_32'|translate}}
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="container-fluid p-0">
              <div class="row justify-content-between">
                <div class="col content-center">
                  <hr style="width:100%">
                </div>
                <div class="col-auto p-0 content-center light-text">
                  {{'invite_now'|translate}}
                </div>
                <div class="col content-center">
                  <hr style="width:100%">
                </div>
              </div>
              <div class="row justify-content-around mt-16">
                <div class="col content-start lg-hide">
                  <div class="img-circle-1" (click)="shareOnWhatsapp()">
                    <img src="../../../../assets/images/whatsapp-icon-1.svg" alt="whatsapp_share_white" width="24px" height="24px">
                  </div>
                </div>
                <div class="col content-center lg-hide">
                  <div class="img-circle-1" (click)="shareOnMessage()">
                    <img src="../../../../assets/images/message-icon-1.svg" alt="message-icon-1" width="24px" height="24px">
                  </div>
                </div>
                <div class="col content-center-right">
                  <div class="img-circle-1" (click)="shareOnApp()">
                    <img src="../../../../assets/images/share-icon.svg" alt="share-icon" width="24px" height="24px">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>