import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie';
import { WebcamImage, WebcamInitError,WebcamUtil } from 'ngx-webcam';
import { Observable, Observer, Subject, Subscription } from 'rxjs';
import { CommonService } from '../../../../../app/services/common.service';
import { HelpRequestCategoryServiceService } from '../../../../../app/services/help-request-category-service.service';
import { LanguageTranslationService } from '../../../../../app/services/language-translation-service';
import { LoclangDialogsService } from '../../../../../app/services/loclang-dialogs.service';
import { ShowToastyService } from '../../../../../app/services/showToastyService';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginDialogService } from '../../../../../app/services/loginService';
import { UploadService } from '../../../../../app/services/uploadService';
import { DataService } from '../../../../../app/services/data.service';
import { Router, RoutesRecognized } from '@angular/router';
import { GoogleAnalyticsService } from '../../../../../app/services/google-analytics.service';
import { filter, pairwise } from 'rxjs/operators';
import RecordRTC from 'recordrtc';

declare var require: any;
// const MicRecorder = require("mic-recorder-to-mp3");

@Component({
  selector: 'app-confirm-dialog-paid-help',
  templateUrl: './confirm-dialog-paid-help.component.html',
  styleUrls: ['./confirm-dialog-paid-help.component.scss']
})
export class ConfirmDialogPaidHelpComponent implements OnInit {

  fromExpandedView:any;
  helpRequestForm:any= new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl('',[Validators.required]),
    category: new FormControl('',[Validators.required]),
    subCategory: new FormControl('',[Validators.required])
  });
  categoriesList = [];
  subCategoriesList:any;
  subCategories :{index:string,value:string}[] = [];
  requestVideo: string="";

  needHelpWith = ""
  isPaid = false;
  fromAds = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogPaidHelpComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private helpRequestService: HelpRequestCategoryServiceService,
    private languageService: LanguageTranslationService,
    private commonService: CommonService,
    private loclatdialog: LoclangDialogsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookieService: CookieService,
    private toastyService: ShowToastyService,
    private cdf: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private loginService: LoginDialogService,
    private uploadService: UploadService,
    private dataService: DataService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private el: ElementRef) {
      this.resetFormData();
      this.isPaid = data.isPaid;
      this.fromAds = data.fromAds;
      if(this.isPaid){
        (window as any).hj('event', 'PaidHR_Init_ExpView');
      }
      else{
        (window as any).hj('event', 'UnpaidHR_Init_ExpView');
      }
      this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        const previousUrl = events[0].urlAfterRedirects;
        if(previousUrl.includes('/help-request/getHelp-RequestbyId')){
          this.fromExpandedView = true;
        }
      });
     }

  ngOnInit() {
    let style:string  = `.dyanmicProgress{background: conic-gradient(#304FFE ${this.progress}%, #f2f2f4 ${this.progress}%)}`;
    this.progressBar(style);
    this.getLocation();
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
  }

  helpVideo:any;
  helpAudio:any
  helpImage:any;
  descriptionOptionSelected=false;
  videoOption=false;
  imageOption=false;
  voiceOption=false;
  
  createHelpRequest(){
    if(this.helpRequestForm.invalid){
      return;
    }
    this.IsLoader = true;
    let obj:any = { autoChangeScreen: true };
    if(this.fromAds){
      this.googleAnalyticsService.eventEmitter2('crt_cpr_pl',{});
      // hotjar event on init creation of paid help request
      (window as any).hj('event', 'crt_cpr_pl');

      obj = { autoChangeScreen: true, showModal:true,width: "360px",height: "515px",
      panelClass: 'confirmPaidHelpDialog',fromAds: true}
    }
    else{
      if(this.isPaid){
        if(this.fromExpandedView){
          this.googleAnalyticsService.eventEmitter2('crt_cpr_cev',{});
          (window as any).hj('event', 'crt_cpr_cev');
        }
        else{
          this.googleAnalyticsService.eventEmitter2('crt_cpr_sur_pu',{});
          (window as any).hj('event', 'crt_cpr_sur_pu');
        }
      }
      else{
        if(this.fromExpandedView){
          this.googleAnalyticsService.eventEmitter2('crt_cfr_cev',{});
          (window as any).hj('event', 'crt_cfr_cev');
        }
        else{
          this.googleAnalyticsService.eventEmitter2('crt_cfr_sur_pu',{});
          (window as any).hj('event', 'crt_cfr_sur_pu');
        }
      }
    }
    this.loginService.openModal(
      obj,
      (status: any) => {
        if (status != false) {
          if(status.allClose){
            this.dialogRef.close(true);
            this.closeIfFromAds();
            return;
          }
          this.postHelpRequest();
        }
        else{
          // hotjar event on failed login or back from login..
          (window as any).hj('event', 'PaidHR_login_failed_landing_page');

          this.IsLoader = false;
        }
      });
  }

  subscriptions: Subscription[] = [];
  selectedLanguage:any;

  setNeedHelpAsLanguage(subCat: string){
    let language = this.languageService.getLanguage() || 'en';
    if(language == 'hi'){
      this.needHelpWith = subCat + ' के लिए मदद चाहिए';
    }
    else if(language == 'te'){
      this.needHelpWith = subCat + ' కోసం సహాయం కావాలి';
    }
    else if(language == 'pa'){
      this.needHelpWith = subCat + ' ਲਈ ਮਦਦ ਦੀ ਲੋੜ ਹੈ';
    }
    else if(language == 'mr'){
      this.needHelpWith = subCat + 'साठी मदत हवी आहे';
    }
    else{
      this.needHelpWith = 'Need help with '+ subCat;
    }
  }

  resetFormData(){
    this.IsLoader = true;
    const hrTitle = localStorage.getItem("hr_title");
    const hrDescription = localStorage.getItem("hr_description");
    const category = localStorage.getItem("hr_cat");
    const subCategory = localStorage.getItem("hr_sub_cat");
    this.subscriptions.push(
      this.languageService.getSelectedLanguage().subscribe((value) => {
        if (value) {
          this.selectedLanguage = value;
          this.helpRequestService.getHelpRequestCategoriesList(
            this.selectedLanguage
          );
          let subscriptions =this.helpRequestService.listObservable.subscribe(
          (data:any) => {
            // getting categories
            this.categoriesList = data;
            subscriptions.unsubscribe();
            this.subCategoriesList = this.categoriesList.filter(
              (x:any) => x.cat_id == category
            );
            if(this.subCategoriesList){
              let index = 0;
              let subCatValues = this.subCategoriesList[0].SubCatlangKey;
              for(let data of this.subCategoriesList[0].subcat_id){
                this.subCategories.push({index:data,value:subCatValues[index]});
                index = index+1;
              }
            }
            let selectedSubCategoryValue:any;
            this.subCategories.forEach(subCat=>{
              if(subCat.index == subCategory){
                selectedSubCategoryValue = subCat.value;
              }
            });
            this.setNeedHelpAsLanguage(selectedSubCategoryValue);
            this.helpRequestForm.setValue({
              title: this.needHelpWith,
              description: hrDescription,
              category: category,
              subCategory: subCategory
            });
            this.onChangeSubCategory(subCategory);
            this.IsLoader = false;
          },
          error=>{
            this.IsLoader = false;
          });
        }
      },error=>{
        this.IsLoader = false;
      }));
  }

  stcode11:any;
  dtcode11:any;
  stname:any;
  dtname:any;
  dialogRefer:any;
  userLatLng = {
    lat: "",
    lng: "",
  };
  selected_type = 0;
  getLocation(){
    this.commonService.selectedLocation.subscribe((value) => {
      if (
        value &&
        Object.keys(value).length > 0 &&
        (Number(localStorage.getItem("admin_defined_location")) > 0 ||
          Number(localStorage.getItem("admin_defined_location")) < 0)
      ) {
        this.stcode11 = localStorage.getItem("stcode11");
        this.dtcode11 = localStorage.getItem("dtcode11");
        this.stname = localStorage.getItem("stname");
        this.dtname = localStorage.getItem("dtname");
        this.userLatLng.lat = value.lat();
        this.userLatLng.lng = value.lng(); 
        if (Number(localStorage.getItem("admin_defined_location")) == 1)
          localStorage.setItem("admin_defined_location", "0");
      } else {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("admin_defined_location", "-1");
          if(!this.fromAds){
            this.loclatdialog.openChangeLocationDialog((res:any) => {});
          }
        }
      }
    })
  }

  setHelpType(){
    if ( 
      this.helpRequestForm.value.description != "" &&
      this.helpRequestForm.value.description != null &&
      this.helpRequestForm.value.description != undefined
    ) {
      this.descriptionOptionSelected = true;
    }
    if (
      this.imageOption &&
      !this.videoOption &&
      !this.voiceOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 1;
    } else if (
      this.videoOption &&
      !this.imageOption &&
      !this.voiceOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 2;
    } else if (
      this.voiceOption &&
      !this.imageOption &&
      !this.videoOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 3;
    } else if (
      this.descriptionOptionSelected &&
      !this.imageOption &&
      !this.videoOption &&
      !this.voiceOption
    ) {
      this.selected_type = 4;
    } else if (
      this.imageOption &&
      this.videoOption &&
      !this.voiceOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 12;
    } else if (
      this.imageOption &&
      this.voiceOption &&
      !this.videoOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 13;
    } else if (
      this.imageOption &&
      this.descriptionOptionSelected &&
      !this.voiceOption &&
      !this.videoOption
    ) {
      this.selected_type = 14;
    } else if (
      this.videoOption &&
      this.voiceOption &&
      !this.imageOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 23;
    } else if (
      this.videoOption &&
      this.descriptionOptionSelected &&
      !this.voiceOption &&
      !this.imageOption
    ) {
      this.selected_type = 24;
    } else if (
      this.voiceOption &&
      this.descriptionOptionSelected &&
      !this.videoOption &&
      !this.imageOption
    ) {
      this.selected_type = 34;
    } else if (
      this.imageOption &&
      this.videoOption &&
      this.voiceOption &&
      !this.descriptionOptionSelected
    ) {
      this.selected_type = 123;
    } else if (
      this.imageOption &&
      this.videoOption &&
      this.descriptionOptionSelected &&
      !this.voiceOption
    ) {
      this.selected_type = 124;
    } else if (
      this.imageOption &&
      this.voiceOption &&
      this.descriptionOptionSelected &&
      !this.videoOption
    ) {
      this.selected_type = 134;
    } else if (
      this.videoOption &&
      this.voiceOption &&
      this.descriptionOptionSelected &&
      !this.imageOption
    ) {
      this.selected_type = 234;
    } else if (
      this.imageOption &&
      this.videoOption &&
      this.voiceOption &&
      this.descriptionOptionSelected
    ) {
      this.selected_type = 1234;
    }
  }

  setMetaData(){
    let v1 = this.cookieService.get("localstars") == "true" ? 1 : 0;
    let v2 =
      this.cookieService.get("Help_Video_seen") == "true" ? 1 : 0;
    let v3 =
      this.cookieService.get("publicfacilities") == "true" ? 1 : 0;
    let v4 = this.cookieService.get("local") == "true" ? 1 : 0;
    let v5 = this.cookieService.get("official") == "true" ? 1 : 0;
    let v6 = JSON.stringify(Object.values({ v1, v2, v3, v4, v5 }));
    let metaData = {
      virtual_id: localStorage.getItem("virtualId"),
      browser_userAgent: this.cookieService.get("browser_userAgent"),
      LanguageSelected: this.cookieService.get("LanguageSelected"),
      LocationSelected: this.cookieService.get("LocationSelected"),
      Help_videos_seen: v2,
      Home_videos_seen: v6,
      platform: this.cookieService.get("platform"),
      is_logged__in: this.cookieService.get("is_logged__in"),
      Webtoken: localStorage.getItem("Webtoken"),
      username: this.cookieService.get("username"),
      mobile_number: this.cookieService.get("mobile_number"),
    };
    return metaData;
  }


  color = "primary";
  mode = "indeterminate";
  value = 50;
  IsLoader: boolean = false;
  @ViewChild("video1") video: any;
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = true;
  public deviceId: string="";
  public videoOptions: MediaTrackConstraints = {
    aspectRatio: 1,
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  // latest snapshot
  public webcamImage!: WebcamImage|null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  private stream!: MediaStream;
  private recordRTC: any;
  selectedImageThumbnail:any[] = [];
  requestImages:any[] = [];
  selectedDocumentThumbnail: any;
  selectHelpVoice: any;
  size!: number;
  selectedFileType = "video/*";
  selectedVideoFiles: any;
  selectAudioFile: any;
  videosThumbnile: any;
  select_radio = 0;
  isRecordingVideo: boolean = false;
  isRecording = false;
  imageClickOption = false;
  imageSelect = false;
  videoSelected = false;
  openRecordVideo = false;
  videoUpload = false;
  videoRecord = false;
  base64TrimmedURL!: string;
  base64DefaultURL!: string;
  generatedImage!: string;
  windowOPen!: boolean;
  selectVideoUrl: any;
  audioBlobURL:any;
  tempAudioBlob:any;
  tempSelectedAudio:any;
  imageOptionSelected = false;
  videoOptionSelected = false;
  voiceOptionSelected = false;
  imageSelected = false;
  ImageFiles:any[] = [];
  VideoFiles = [];
  playStoreLink = "";
  private VIDEODURATION = "30"; // in seconds
  private AUDIODURATION = 60; //in seconds
  isDisable!: boolean;
  videoTimeIssue!: boolean;
  showVideo: boolean = false;
  displayImageModal!: "none";
  @ViewChild("closeImagebutton1") closeImagebutton:any;
  @ViewChild("closeVideobutton1") closeVideobutton:any;
  @ViewChild("openImageModal") openImageModal:any;
  @ViewChild("closeAudioModal1") closeAudioModal:any;
  @ViewChild("confirmationButton") confirmationButton:any;


  imageUploadOption(e:any) {
    this.showWebcam = false;
    if (e == 1) {
      this.imageClickOption = true;
      this.showWebcam = true;
      // $("#imageModal").modal("hide");
      this.closeImagebutton.nativeElement.click();
    } else {
      // this.closeImagebutton.nativeElement.click();
      this.imageSelect = true;
      // this.imageOption = true;
    }
  }

  videoUploadOption(e:any) {
    this.openRecordVideo = false;
    if (e == 1) {
      this.videoUpload = true;
    } else {
      this.videoRecord = true;
      this.openRecordVideo = true;
      this.closeVideobutton.nativeElement.click();
    }
  }
  selectAttachOption(option:any) {
    if (option == 1) {
      if(this.fromAds){
        this.googleAnalyticsService.eventEmitter2('add_photo_cpr_pu_pl',{});
        (window as any).hj('event', 'add_photo_cpr_pu_pl');
      }
      else{
        if(this.isPaid){
          if(this.fromExpandedView){
            this.googleAnalyticsService.eventEmitter2('add_photo_cpr_pu_cev',{});
            (window as any).hj('event', 'add_photo_cpr_pu_cev');
          }
          else{
            this.googleAnalyticsService.eventEmitter2('add_photo_cpr_pu_sur_pu',{});
            (window as any).hj('event', 'add_photo_cpr_pu_sur_pu');
          }
        }
        else{
          if(this.fromExpandedView){
            this.googleAnalyticsService.eventEmitter2('add_video_cfr_pu_cev',{});
            (window as any).hj('event', 'add_video_cfr_pu_cev');
          }
          else{
            this.googleAnalyticsService.eventEmitter2('add_photo_cfr_pu_sur_pu',{});
            (window as any).hj('event', 'add_photo_cfr_pu_sur_pu');
          }
        }
      }
      this.showWebcam = false;
      this.imageOptionSelected = !this.imageOptionSelected;
      if (this.videoOption) {
        this.videoOptionSelected = true;
      } else {
        this.videoOptionSelected = false;
      }
      if (this.voiceOption) {
        this.voiceOptionSelected = true;
      } else {
        this.voiceOptionSelected = false;
      }
      if (this.helpRequestForm.value.description != "") {
        this.descriptionOptionSelected = true;
      } else {
        this.descriptionOptionSelected = false;
      }
      if (this.ImageFiles.length > 0) {
        this.imageSelected = true;
      }
    } else if (option == 2) {
      if(this.fromAds){
        this.googleAnalyticsService.eventEmitter2('add_video_cpr_pu_pl',{});
        (window as any).hj('event', 'add_video_cpr_pu_pl');
      }
      else{
        if(this.isPaid){
          if(this.fromExpandedView){
            this.googleAnalyticsService.eventEmitter2('add_video_cpr_pu_cev',{});
            (window as any).hj('event', 'add_video_cpr_pu_cev');
          }
          else{
            this.googleAnalyticsService.eventEmitter2('add_video_cpr_pu_sur_pu',{});
            (window as any).hj('event', 'add_video_cpr_pu_sur_pu');
          }
        }
        else{
          if(this.fromExpandedView){
            this.googleAnalyticsService.eventEmitter2('add_photo_cfr_pu_cev',{});
            (window as any).hj('event', 'add_photo_cfr_pu_cev');
          }
          else{
            this.googleAnalyticsService.eventEmitter2('add_video_cfr_pu_sur_pu',{});
            (window as any).hj('event', 'add_video_cfr_pu_sur_pu');
          }
        }
      }
      this.openRecordVideo = false;
      // this.videoOption = !this.videoOption;
      if (this.videoOption) {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "You have already added a video. To replace it, please delete that video first."
          )
        );
        return;
      } else {
        this.videoOptionSelected = !this.videoOptionSelected;
      }

      if (this.imageOption) {
        this.imageOptionSelected = true;
      } else {
        this.imageOptionSelected = false;
      }
      if (this.voiceOption) {
        this.voiceOptionSelected = true;
      } else {
        this.voiceOptionSelected = false;
      }
      if (this.helpRequestForm.value.description != "") {
        this.descriptionOptionSelected = true;
      } else {
        this.descriptionOptionSelected = false;
      }
      if (!this.videoOption) {
        this.openRecordVideo = false;
      }
      if (this.videosThumbnile) {
        this.videoSelected = true;
      }
    } else if (option == 3) {
      if(this.fromAds){
        this.googleAnalyticsService.eventEmitter2('add_voice_cpr_pu_pl',{});
        (window as any).hj('event', 'add_voice_cpr_pu_pl');
      }
      else{
        if(this.isPaid){
          if(this.fromExpandedView){
            this.googleAnalyticsService.eventEmitter2('add_voice_cpr_pu_cev',{});
            (window as any).hj('event', 'add_voice_cpr_pu_cev');
          }
          else{
            this.googleAnalyticsService.eventEmitter2('add_voice_cpr_pu_sur_pu',{});
            (window as any).hj('event', 'add_voice_cpr_pu_sur_pu');
          }
        }
        else{
          if(this.fromExpandedView){
            this.googleAnalyticsService.eventEmitter2('add_voice_cfr_pu_cev',{});
            (window as any).hj('event', 'add_voice_cfr_pu_cev');
          }
          else{
            this.googleAnalyticsService.eventEmitter2('add_voice_cfr_pu_sur_pu',{});
            (window as any).hj('event', 'add_voice_cfr_pu_sur_pu');
          }
        }
      }
      if (this.voiceOption) {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "You have already added a audio. To replace it, please delete that audio first."
          )
        );
        return;
      } else {
        this.voiceOptionSelected = !this.voiceOptionSelected;
      }
      if (this.imageOption) {
        this.imageOptionSelected = true;
      } else {
        this.imageOptionSelected = false;
      }
      if (this.videoOption) {
        this.videoOptionSelected = true;
      } else {
        this.videoOptionSelected = false;
      }
      if (this.helpRequestForm.value.description != "") {
        this.descriptionOptionSelected = true;
      } else {
        this.descriptionOptionSelected = false;
      }
    } else if (option == 4) {
      // this.descriptionOption = !this.descriptionOption;
      this.descriptionOptionSelected = !this.descriptionOptionSelected;
      if (this.imageOption) {
        this.imageOptionSelected = true;
      } else {
        this.imageOptionSelected = false;
      }
      if (this.videoOption) {
        this.videoOptionSelected = true;
      } else {
        this.videoOptionSelected = false;
      }
      if (this.voiceOption) {
        this.voiceOptionSelected = true;
      } else {
        this.voiceOptionSelected = false;
      }
    }
  }

  remove_picture(image:any) {
    this.requestImages.splice(image, 1);
    this.selectedImageThumbnail.splice(image, 1);
    if (this.selectedImageThumbnail.length == 0) {
      this.imageOption = false;
      this.imageSelected = false;
    }
    this.imageSelected = false;
    this.imageOptionSelected = false;
    this.ImageFiles = [];
    //this.selectedImageThumbnail = [];
    this.helpImage = "";
  }
  remove_video() {
    //$("#videoRecordModal").modal("hide");
    this.showVideo = false;
    const element = document.getElementById("videoRecordModalButton");
    element?element.click():'';
    let stream = this.stream;
    stream.getAudioTracks().forEach((track) => track.stop());
    stream.getVideoTracks().forEach((track) => track.stop());
    this.video = [];
    this.videoOption = false;
    this.videoOptionSelected = false;
    this.openRecordVideo = false;
    this.videosThumbnile = "";
  }


  public triggerSnapshot(): void {
    this.trigger.next();
    const element = document.getElementById("imageCaptureModalButton1");
    element?element.click():'';
  }

  public toggleWebcam(): void {
    const element = document.getElementById("imageCaptureModalButton1");
    element?element.click():'';
    this.showWebcam = !this.showWebcam;
    this.imageClickOption = !this.imageClickOption;
    this.imageOptionSelected = false;
  }
  public takeNewSnapShot(): void {
    this.webcamImage = null;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.showWebcam = !this.showWebcam;
    this.getImage(this.webcamImage.imageAsDataUrl);
  }

  getImage(imageUrl: string) {
    this.imageClickOption = false;
    this.imageSelect = false;
    this.IsLoader = true;
    this.subscriptions.push(
      this.getBase64ImageFromURL(imageUrl).subscribe((base64Data: string) => {
        this.base64TrimmedURL = base64Data;
        this.imageOption = true;
        this.dataURItoBlob(this.base64TrimmedURL).subscribe((blob: Blob) => {
          const imageBlob: Blob = blob;
          const imageName: string = this.generateName();
          const imageFile: File = new File([imageBlob], imageName, {
            type: "image/jpeg",
          });
          this.ImageFiles.push(imageFile);
          this.selectedImageThumbnail.push(this.base64DefaultURL);
          this.imageOption = true;
          this.imageOptionSelected = false;
          this.IsLoader = false;
        });
        this.IsLoader = false;
      })
    );
  }
  getBase64ImageFromURL(url: string): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }
  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    var canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx: CanvasRenderingContext2D|any = canvas.getContext("2d");
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    let dataURL: string = canvas.toDataURL("image/png");
    this.base64DefaultURL = dataURL;
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  /**Method that will create Blob and show in new window */
  createBlobImageFileAndShow(): void {}

  /* Method to convert Base64Data Url as Image Blob */
  dataURItoBlob(dataURI: string): Observable<Blob> {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "image/jpeg" });
      observer.next(blob);
      observer.complete();
    });
  }

  /**Method to Generate a Name for the Image */
  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = "";
    const possibleText: string =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + "." + text + ".jpeg";
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  openVideoModal() {}
  toggleControls() {
    let video: HTMLVideoElement = this.video.nativeElement;
    video.muted = !video.muted;
    video.controls = !video.controls;
    video.autoplay = !video.autoplay;
  }
  startVideoRecording() {
    let mediaConstraints = {
      allowCameraSwitch: true,
      video: {
        mandatory: {
          minWidth: 1280,
          minHeight: 720,
        },
      },
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(<any>mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
    this.isRecordingVideo = true;
  }
  errorCallback() {}
  successCallback(stream: MediaStream) {
    var options = {
      mimeType: "video/webm", // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 128000,
      bitsPerSecond: 128000, // if this line is provided, skip above two
    };
    this.stream = stream;
    this.recordRTC = new RecordRTC(stream);
    this.recordRTC.startRecording();
    let video: HTMLVideoElement = this.video.nativeElement;
    video.srcObject = stream;
    this.toggleControls();
  }
  recordVideo() {
    this.openRecordVideo = true;
  }

  stopVideoRecording() {
    this.isRecordingVideo = false;
    //$("#videoRecordModal").modal("hide");
    const element = document.getElementById("videoRecordModalButton")
    element?element.click():"";
    let recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processVideo.bind(this));
    let stream = this.stream;
    stream.getAudioTracks().forEach((track) => track.stop());
    stream.getVideoTracks().forEach((track) => track.stop());
  }
  processVideo(audioVideoWebMURL:any) {
    let video: HTMLVideoElement|any = this.video.nativeElement;
    let recordRTC = this.recordRTC;
    video.src = video.srcObject = null;
    video.src = URL.createObjectURL(recordRTC.getBlob());
    this.toggleControls();
    var recordedBlob = recordRTC.getBlob();
    this.handleVideoSelect(recordedBlob);
    recordRTC.getDataURL(function () {});
  }

  handleFileSelect(evt:any) {
    this.closeImagebutton.nativeElement.click();
    var files = evt.target.files; // FileList object

    // Loop through the FileList and render image files as thumbnails.
    for (var i = 0, f; (f = files[i]); i++) {
      if (!f.type.match("image.*")) {
        continue;
      }
      this.ImageFiles.push(files[i]);

      var reader = new FileReader();

      // Closure to capture the file information.
      reader.onload = ((theFile) => {
        return (e:any) => {
          // Render thumbnail.
          // var strImage = e.target.result.replace(/^data:image\/[a-z]+;base64,/, "" );
          var strImage = e.target.result;
          this.selectedImageThumbnail.push(strImage);
          // this.selectedImages.push(strImage);
        };
      })(f);
      reader.readAsDataURL(f);
      // this.imageSelect = !this.imageSelect;
      this.imageOption = true;
      this.imageOptionSelected = false;
    }
  }

  removeVideoSelect() {
    this.showVideo = false;
    this.video = [];
    this.videoOption = false;
    this.openRecordVideo = false;
    this.requestVideo = "";
    this.videosThumbnile = "";
    this.videoUpload = false;
    this.videoOptionSelected = false;
  }
  handleVideoSelection(evt:any) {
    var files = evt.target.files; // FileList object
    // Loop through the FileList and render image files as thumbnails.
    for (var i = 0, f; (f = files[i]); i++) {
      if (f.type.match("video.*")) {
        this.handleVideoSelect(files[i]);
        continue;
      }
      this.selectedVideoFiles = files[i];
      var reader = new FileReader();
      // Closure to capture the file information.
      reader.onload = ((theFile) => {
        return (e:any) => {
          var strImage = e.target.result;
          // this.selectedVideoFiles.push(strImage)
        };
      })(f);
      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  }

  handleVideoSelect(videoFile:any) {
    this.closeVideobutton.nativeElement.click();
    this.IsLoader = true;
    this.selectedVideoFiles = videoFile;
    var reader = new FileReader();
    if (videoFile != null) {
      this.isDisable = false;
      this.setFileInfo(videoFile);
    } else {
      //document.getElementById("errorInVideo").innerHTML = "Size should be less than 2MB";
      this.isDisable = true;
    }
    if (this.isDisable) {
      if (this.videoTimeIssue) {
        this.toastyService.failure(
          "",
          "Duration of video should be less than " +
            this.VIDEODURATION +
            " seconds."
        );
        this.IsLoader = false;
        return;
      } else {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "Not a valid Video, Please try again"
          )
        );
        this.IsLoader = false;
        return;
      }
      // this.IsLoader = false;
    } else {
      this.videosThumbnile = videoFile;
      this.videoOption = true;
      this.IsLoader = false;
      this.showVideo = true;
      this.videoOptionSelected = true;
      this.cdf.detectChanges();
    }
  }
  setFileInfo(file:any) {
    var video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;
      file.duration = duration;
      if (file.duration < this.VIDEODURATION) {
        this.isDisable = false;
        this.videoTimeIssue = false;
      } else {
        // document.getElementById("errorInVideo").innerHTML = "Duration of video should be less than " + this.VIDEODURATION + " seconds.";
        this.videoTimeIssue = true;
        this.isDisable = true;
      }
    };

    //video.src = URL.createObjectURL(file);
  }
  handleAudioSelect(audioFile:any) {
    this.IsLoader = true;
    this.selectAudioFile = audioFile;
    var reader = new FileReader();
    if (audioFile != null) {
      this.isDisable = false;
      this.setFileInfo(audioFile);
    } else {
      //document.getElementById("errorInVideo").innerHTML = "Size should be less than 2MB";
      this.isDisable = true;
    }
    if (this.isDisable) {
      if (this.videoTimeIssue) {
        this.toastyService.failure(
          "",
          "Duration of Audio should be less than " +
            this.AUDIODURATION +
            " seconds."
        );
        this.IsLoader = false;
        return;
      } else {
        this.toastyService.failure(
          "",
          this.languageService.getTranslated(
            "Not a valid Video, Please try again"
          )
        );
        this.IsLoader = false;
        return;
      }
    } else {
      // this.voiceOption = true;
      // this.voiceOptionSelected = true;
      this.IsLoader = false;
    }
  }

  audioRecorderProgress = 0;
  audioRecorderTimer = 0;
  intervalId:any;
  // recorder = new MicRecorder({
  //   bitRate: 128,
  // });

  progress = 0;
  timer = 0;
  interval:any;
  progressBar(style:any) {
    // dyanmic progress bar
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(style));
    this.el.nativeElement.appendChild(styleElement);
  }
  startAudioRecording() {
    if (!this.isRecording) {
      // creating progress bar
      this.timer = 0;
      this.interval = setInterval(() => {
        if(this.progress <= 100) {
          if(this.timer < 60){
            this.timer = this.timer+1;
          }
          this.progress = (this.timer/60)*100;
          let style:string  = `.dyanmicProgress{background: conic-gradient(#304FFE ${this.progress}%, #f2f2f4 ${this.progress}%)}`;
          this.progressBar(style);
        } else {
          clearInterval(this.interval);
        }
      },1000);
      this.isRecording = true;
      // recording using recordrtc

      let mediaConstraints = {
        video: false,
        audio: true
      };
      navigator.mediaDevices.getUserMedia(mediaConstraints).
      then(this.successCallback2.bind(this), this.errorCallback2.bind(this));
      // this.recorder
      //   .start()
      //   .then(() => {
      //     const getDownloadProgress = () => {
      //       if (this.audioRecorderProgress < 100) {
      //         this.audioRecorderTimer += 1;
      //         this.audioRecorderProgress =
      //           this.audioRecorderProgress + (1 / this.AUDIODURATION) * 100;
      //       } else {
      //         this.stopAudioRecording();
      //       }
      //     };
      //     this.intervalId = setInterval(getDownloadProgress, 1000);
      //   })
      //   .catch((e:any) => {
      //     // console.error(e);
      //     this.isRecording = false;
      //   });
    }
  }
  audioRecord:any;
  successCallback2(stream:any) {
    let options:RecordRTC.Options = {
      type: 'audio',
      mimeType: "audio/wav",
      // numberOfAudioChannels: 1,
      // sampleRate: 16000,
      desiredSampRate: 16000,
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.audioRecord = new StereoAudioRecorder(stream, options);
    this.audioRecord.record();
    const getDownloadProgress = () => {
      if (this.audioRecorderProgress < 100) {
        this.audioRecorderTimer += 1;
        this.audioRecorderProgress =
          this.audioRecorderProgress + (1 / this.AUDIODURATION) * 100;
      } else {
        this.stopAudioRecording();
      }
    };
    this.intervalId = setInterval(getDownloadProgress, 1000);
  }
  errorCallback2() {
    this.isRecording = false;
  }

  processRecording(blob:any) {
    const url = URL.createObjectURL(blob);
    console.log("blob", blob);
    console.log("url", url);
    this.tempAudioBlob = this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(blob)
    );
    this.tempSelectedAudio = blob;
    this.handleAudioSelect(blob);
  }

  stopAudioRecording() {
    if (this.isRecording) {
      clearInterval(this.intervalId);
      clearInterval(this.interval);
      this.audioRecorderProgress = 0;
      this.audioRecorderTimer = 0;
      this.isRecording = false;
      this.audioRecord.stop(this.processRecording.bind(this));
      // this.recorder
      //   .stop()
      //   .getMp3()
      //   .then(([buffer, blob]:any) => {
      //     this.tempAudioBlob = this.sanitizer.bypassSecurityTrustUrl(
      //       URL.createObjectURL(blob)
      //     );
      //     this.tempSelectedAudio = blob;
      //     this.handleAudioSelect(blob);
      //   })
      //   .catch((e:any) => {
      //     // console.error(e);
      //     this.isRecording = false;
      //   });
    }
  }

  setTitleOfProgressBar() {
    return this.audioRecorderTimer + " Sec";
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
    }
  }

  saveAudio() {
    this.closeAudioModal.nativeElement.click();
    this.audioBlobURL = this.tempAudioBlob;
    this.selectHelpVoice = this.tempSelectedAudio;
    this.voiceOption = true;
    this.voiceOptionSelected = true;
  }

  clearRecordedData() {
    this.voiceOption = false;
    this.voiceOptionSelected = false;
    this.audioBlobURL = null;
    this.tempAudioBlob = null;
    this.tempSelectedAudio = null;
  }
  
  transformIntoSec(progress:any){
    if(progress!=0){
      return Math.round((progress*60)/100);
    }
    return 0;
  }


  ngOnDestroy(): void {
    this.abortRecording();
    this.subscriptions.forEach((s) => s.unsubscribe());
    clearTimeout(this.intervalId);
  }

  imageUploaded:any[] = [];
  postHelpRequest(){
    this.helpImage = "";
    let promises = [];
    if (
      this.helpRequestForm.value.description != "" &&
      this.helpRequestForm.value.description != null &&
      this.helpRequestForm.value.description != undefined
    ) {
      this.descriptionOptionSelected = true;
    }
    if (
      !this.imageOption &&
      !this.videoOption &&
      !this.voiceOption &&
      !this.descriptionOptionSelected
    ) {
      this.toastyService.failure(
        "",
        this.languageService.getTranslated(
          "Please select Atleast one media Option"
        )
      );
      this.imageOption = false;
      this.imageOptionSelected = false;
      this.videoOption = false;
      this.videoOptionSelected = false;
      this.voiceOption = false;
      this.voiceOptionSelected = false;
      this.descriptionOptionSelected = false;
      this.IsLoader = false;
      return;
    }
    if (this.imageOption) {
      promises.push(
        new Promise((resolve, reject) => {
          this.uploadService
            .uploadImageOnFirebase(this.ImageFiles)
            .then((imageurl:any) => {
              if (imageurl.length > 0) {
                imageurl.forEach((element:any) => {
                  this.imageUploaded.push(element);
                });
                let abc = JSON.stringify(this.imageUploaded);
                this.helpImage = abc.replace("[", "{").replace("]", "}");
                resolve(imageurl);
              } else {
                reject(imageurl);
              }
            });
        })
      );
    }
    if (this.voiceOption) {
      promises.push(
        new Promise((resolve, reject) => {
          this.uploadService
            .uploadAudio(this.selectHelpVoice)
            .then((value:any) => {
              if (value != null || value != "") {
                this.helpAudio = value;
                resolve(value);
              } else {
                reject(value);
              }
            });
        })
      );
    }
    if (this.videoOption) {
      promises.push(
        new Promise((resolve, reject) => {
          this.uploadService
            .uploadVideo(this.videosThumbnile)
            .then((value:any) => {
              if (value != null || value != "") {
                this.helpVideo = value;
                resolve(value);
              } else {
                reject(value);
              }
            });
        })
      );
    }
    Promise.all(promises).then(
      (values) => {
        this.helpRequestForm.patchValue({
          description: this.helpRequestForm.value.description?
          this.helpRequestForm.value.description.trim():""
        });
        if (this.descriptionOptionSelected) {
          if (
            this.helpRequestForm.value.description == "" ||
            this.helpRequestForm.value.description == null ||
            this.helpRequestForm.value.description == undefined
          ) {
            this.toastyService.failure(
              "",
              this.languageService.getTranslated(
                "Please Enter Request Details First."
              )
            );
            this.IsLoader = false;
            return;
          }
        } else {
          this.helpRequestForm.value.description = "";
        }
        if (
          (this.helpRequestForm.value.description == "" ||
            this.helpRequestForm.value.description == null ||
            this.helpRequestForm.value.description == undefined) &&
          (this.helpImage == "{}" ||
            this.helpImage == "" ||
            this.helpImage == null ||
            this.helpImage == undefined) &&
          (this.helpVideo == "" ||
            this.helpVideo == null ||
            this.helpVideo == undefined) &&
          (this.helpAudio == "" ||
            this.helpAudio == null ||
            this.helpAudio == undefined)
        ) {
          this.toastyService.failure(
            "",
            this.languageService.getTranslated(
              "Please select Atleast one media Option"
            )
          );
          this.imageOption = false;
          this.imageOptionSelected = false;
          this.videoOption = false;
          this.videoOptionSelected = false;
          this.videoOption = false;
          this.voiceOptionSelected = false;
          this.descriptionOptionSelected = false;
          this.IsLoader = false;
          return;
        }
        this.setHelpType();
        // let helpVoice = this.selectHelpVoice;
        let helpType = this.selected_type;
        let metadata = this.setMetaData();

        if (this.helpRequestForm.value.category == 16 && 
          !this.helpRequestForm.value.subCategory) {
          // 16 for mnrega
          this.toastyService.failure(
            "",
            this.languageService.getTranslated(
              "Please select a subcategory."
            )
          );
          this.IsLoader = false;
          return;
        }
        let standardHrLang = this.cookieService.get("LanguageSelected")?this.cookieService.get("LanguageSelected"): "en";
        let body:any = {
          username: this.commonService.getUsername(),
          category: this.helpRequestForm.value.category,
          subCategory: this.helpRequestForm.value.subCategory,
          lat: this.userLatLng.lat,
          lng: this.userLatLng.lng,
          metadata: JSON.stringify(metadata),
          stcode11: this.stcode11,
          dtcode11: this.dtcode11,
          helpType: this.selected_type,
          helpTitle: this.helpRequestForm.value.title,
          stname: this.stname,
          dtname: this.dtname,
          helpVideo:
            this.helpVideo != "" &&
            this.helpVideo != undefined &&
            this.helpVideo != null
              ? this.helpVideo
              : "",
          helpVoice:
            this.helpAudio != "" &&
            this.helpAudio != undefined &&
            this.helpAudio != null
              ? this.helpAudio
              : "",
          helpPictures:
            this.helpImage != "" &&
            this.helpImage != null &&
            this.helpImage != "{}"
              ? this.helpImage
              : "{}",
          helpDescription:
            this.helpRequestForm.value.description != "" &&
            this.helpRequestForm.value.description != undefined &&
            this.helpRequestForm.value.description != null
              ? this.helpRequestForm.value.description
              : "",
          standard_hr_lang:standardHrLang
        };
        if(this.isPaid){
          body.is_paid_help= true;
          body.is_standard = false;
        }
        // this.IsLoader = false;
        this.subscriptions.push(
          this.dataService.createHelpRequestv2(body).subscribe((req) => {
            if (req.success == false) {
              if (
                req.msg ==
                "either of the media type should be supplied to add help request"
              ) {
                this.toastyService.failure(
                  "",
                  this.languageService.getTranslated(
                    "Please select Atleast one media Option"
                  )
                );
                this.imageOption = false;
                this.imageOptionSelected = false;
                this.videoOption = false;
                this.videoOptionSelected = false;
                this.videoOption = false;
                this.voiceOptionSelected = false;
                this.descriptionOptionSelected = false;
                this.IsLoader = false;
                return;
              }
            } else {
              this.IsLoader = false;
              this.googleAnalyticsService.eventEmitter2('ja_create_hr',{});
              if(this.isPaid){
                this.dialogRef.close(true);
                const time = Date.now();
                localStorage.setItem("paid_help_creation_time",time.toString());
                this.dataService.setOfferHelpid(req.result.helpid);
                localStorage.setItem("create_paid_help_id",req.result.helpid);
                this.router.navigate(["/help-request/getHelp-RequestbyId"], {
                  queryParams: {
                    Id: req.result.helpid,
                    pageNo: "1",
                    status: "1",
                    source: "help",
                    isPaidType: "true"
                  }
                });
                this.googleAnalyticsService.eventEmitter(
                  "ja_create_paid_hr",
                  "",
                  "Paid Help created from new dialog for create help request"
                );
                (window as any).hj('event', 'PaidHR_Created_ExpView');
                this.dataService.updateNewHridAndTimestamp(this.commonService.getUsername(),
                req.result.helpid);
              }
              else{
                this.dialogRef.close(true);
                this.router.navigate(["/help-request/getHelp-RequestbyId"], {
                  queryParams: {
                    Id: req.result.helpid,
                    pageNo: "1",
                    status: "1",
                    source: "help",
                    unpaid: "true"
                  },
                });
                this.googleAnalyticsService.eventEmitter(
                  "ja_create_free_hr",
                  "",
                  "unpaid Help created from new dialog for create help request"
                );
                (window as any).hj('event', 'UnpaidHR_Created_ExpView');
              }
              // this.router.navigate(["/help-request/getHelp-RequestbyId"], {
              //   queryParams: {
              //     Id: req.result.helpid,
              //     pageNo: "1",
              //     status: "1",
              //     source: "help",
              //     isPaidType: "true"
              //   },
              // });
              this.googleAnalyticsService.eventEmitter(
                "web_help-request_created",
                "",
                "User creates help-request"
              );
            }
            this.IsLoader = false;
          },
          error=>{
            this.IsLoader = false;
          })
        );
      },
      (reason) => {
      }
    );
  }

  subCategory = "";
  onChangeSubCategory(index:any){
    if(this.fromAds){
      this.googleAnalyticsService.eventEmitter2('sc_dd_cpr_pu_pl',{});
      (window as any).hj('event', 'sc_dd_cpr_pu_pl');
    }
    else{
      if(this.isPaid){
        if(this.fromExpandedView){
          this.googleAnalyticsService.eventEmitter2('sc_dd_cpr_pu_cev',{});
          (window as any).hj('event', 'sc_dd_cpr_pu_cev');
        }
        else{
          this.googleAnalyticsService.eventEmitter2('sc_dd_cpr_pu_sur_pu',{});
          (window as any).hj('event', 'sc_dd_cpr_pu_sur_pu');
        }
      }
      else{
        if(this.fromExpandedView){
          this.googleAnalyticsService.eventEmitter2('sc_dd_cfr_pu_cev',{});
          (window as any).hj('event', 'sc_dd_cfr_pu_cev');
        }
        else{
          this.googleAnalyticsService.eventEmitter2('sc_dd_cfr_pu_sur_pu',{});
          (window as any).hj('event', 'sc_dd_cfr_pu_sur_pu');
        }
      }
    }
    this.helpRequestForm.patchValue({
      subCategory: index
    });
    // const subCategory = this.helpRequestForm.value.subCategory;
    let subCat:any = this.subCategories.find(data=> data.index == index);
    if(subCat){
      this.subCategory = subCat.value;
      this.setNeedHelpAsLanguage(subCat.value);
      this.helpRequestForm.patchValue({
        title: this.needHelpWith
      })
    }
  }

  closeIfFromAds(){
    if(this.fromAds){
      this.googleAnalyticsService.eventEmitter2('exit_cpr_pu_pl',{});
      (window as any).hj('event', 'exit_cpr_pu_pl');
      this.dialogRef.close({viewSolutions:true,subCtg:this.helpRequestForm.value.subCategory,
        subCtgValue:this.subCategory});
    }
    else{
      if(this.isPaid){
        if(this.fromExpandedView){
          this.googleAnalyticsService.eventEmitter2('exit_cpr_pu_cev',{});
          (window as any).hj('event', 'exit_cpr_pu_cev');
        }
        else{
          this.googleAnalyticsService.eventEmitter2('exit_cpr_pu_sur_pu',{});
          (window as any).hj('event', 'exit_cpr_pu_sur_pu');
        }
      }
      else{
        if(this.fromExpandedView){
          this.googleAnalyticsService.eventEmitter2('exit_cfr_pu_cev',{});
          (window as any).hj('event', 'exit_cfr_pu_cev');
        }
        else{
          this.googleAnalyticsService.eventEmitter2('exit_cfr_pu_sur_pu',{});
          (window as any).hj('event', 'exit_cfr_pu_sur_pu');
        }
      }
    }
  }
}
