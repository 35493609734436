import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DataService } from "../../services/data.service";
import { LanguageTranslationService } from '../../services/language-translation-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-categorieslist',
  templateUrl: './categorieslist.component.html',
  styleUrls: ['./categorieslist.component.scss']
})
export class CategorieslistComponent implements OnInit {

  selectedLanguage = 'en';
  categoriesJSON :any= {};
  subscriptions: Subscription[] = [];

  constructor(private dataService: DataService, private languageService: LanguageTranslationService,
    private dialogRef: MatDialogRef<CategorieslistComponent>) { }

  ngOnInit() {
    this.languageService.getSelectedLanguage().subscribe((value) => {
      if (value) {
        this.selectedLanguage = value;
      }
    });
    this.getHelpFilters();
  }

  onView(data:any) {
    this.dialogRef.close(data);
  }

  getHelpFilters() {
    let params = {
      "language": this.selectedLanguage
    }
    this.subscriptions.push(this.dataService.callv3API('homePage/getHomePageFilters', params).subscribe(res => {
      if (res.success) {
        this.categoriesJSON = {};
        for (let row of res.data) {
          if (!this.categoriesJSON[row['cat_id']]) {
            this.categoriesJSON[row['cat_id']] = [];
          }
          this.categoriesJSON[row['cat_id']].push(row);
        }
      }
    }));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
