import {
  BrowserModule,
  TransferState,
  BrowserTransferStateModule,
} from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { JoyrideModule } from "ngx-joyride";
// import { ToastyModule } from "ng2-toasty";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ArchwizardModule } from "angular-archwizard";
import { MatTooltipModule } from "@angular/material/tooltip";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModuleModule } from "./shared-module/shared-module.module";
// import { DashboardTopBarComponent } from "./shared-components/dashboard-top-bar/dashboard-top-bar.component";
import { AuthenticationComponent, ReferralDialogComponent } from "./shared-components/authentication/authentication.component";
import { environment } from "../environments/environment";
// import { ChangeLocationDialog } from './shared-components/changeLocation/add-location-question.component';
import { ChangeLocationDialog } from "./shared-components/new-change-location/new-change-location.component";
import { CustomToastyComponent } from "./shared-components/custom-toasty/custom-toasty.component";
import { VideoPlayer } from "./shared-components/video_player_dialog/video_player_dialog";
import { LanguageSelectionDialog } from "./shared-components/LanguageDialog/selectlanguage.dialog";
import { AboutFaqComponent } from "./modules/user-profile/about-faq/about-faq.component";
import { HelpreqoptComponent } from "./modules/helpreqopt/helpreqopt.component";
import { CustomDialogComponent } from "./shared-components/custom-dialog/custom-dialog.component";
import { ImgTextButtonCustomDialogComponent } from "./shared-components/img_text_button_customdiaog/img_text_button_customdiaog";
import { PageNotFoundComponent } from "./shared-components/page-not-found/page-not-found.component";
import { ShareWhatsappLoaderComponent } from "./shared-components/share-whatsapp-loader/share-whatsapp-loader.component";
import { SplashScreenComponent } from "./shared-components/splash-screen/splash-screen.component";

//Services
import { AudioRecordingService } from "./services/audioRecordingService";
import { LoginDialogService } from "./services/loginService";
import { AuthService } from "./services/auth.service";
import { LoclangDialogsService } from "./services/loclang-dialogs.service";

// Language Commponents
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { TransferHttpCacheModule } from "@nguniversal/common";
import { translateBrowserLoaderFactory } from "./translate-browser.loader";
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

//Firebase
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database"
import { AngularFireAuthModule } from "angularfire2/auth";
import { AngularFireMessagingModule } from "angularfire2/messaging";
import { ReportPostDialogComponent } from "./shared-components/report-post-dialog/report-post-dialog.component";
import { MessagingService } from "./services/messaging.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { MoengageEventsService } from "./services/moengage_events_service";
import { HelpRequestCategoryServiceService } from "./services/help-request-category-service.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { UserLevelUpDialogComponent } from "./shared-components/user-level-up-dialog/user-level-up-dialog.component";
import { PreviousRouteService } from "./services/previous-route.service";
import { CookieModule } from "ngx-cookie";
import { UserDetailService } from "./services/user-detail.service";
// import { UpdateUsernameDialogComponent } from "./shared-components/update-username-dialog/update-username-dialog.component";
import { Gratification } from "./shared-components/gratification-dialog/gratification.component";
import { CategorieslistComponent } from "./shared-components/categorieslist/categorieslist.component";
import { ViewSolutionDialogComponent } from './modules/landing-page/landing-page.component';
import { PaymentFailedPopupComponent } from "./modules/help-request/get-specific-help-request/get-specific-help-request.component";
import { RedeemComponent } from "./modules/user-profile/gamification/gamification.component";
import { ShowToastyService } from "./services/showToastyService";
import { ToastrModule } from "ngx-toastr";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { CreateHelpRequestDialog, OtpDialog, SelectCategoryDialog } from "./modules/expanded-view/expanded-view.component";
import { InfoDialog, MediaDialog } from "./modules/help-request/create-help-request/create-help-request.component";

@NgModule({
  declarations: [
    AppComponent,
    CustomToastyComponent,
    // UpdateUsernameDialogComponent,
    Gratification,
    CustomDialogComponent,
    ImgTextButtonCustomDialogComponent,
    PageNotFoundComponent,
    ShareWhatsappLoaderComponent,
    SplashScreenComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModuleModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    MatProgressSpinnerModule,
    BrowserTransferStateModule,
    // TransferHttpCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
      defaultLanguage: "en",
    }),
    // ToastyModule.forRoot(),
    JoyrideModule.forRoot(),
    CookieModule.forRoot(),
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.pwa,
    }),
    ArchwizardModule.forRoot(),
    MatTooltipModule,
    ToastrModule.forRoot(),
  ],
  entryComponents: [
    AuthenticationComponent,
    ChangeLocationDialog,
    LanguageSelectionDialog,
    VideoPlayer,
    // UpdateUsernameDialogComponent,
    ShareWhatsappLoaderComponent,
    CategorieslistComponent,
    SplashScreenComponent,
    ReportPostDialogComponent,
    UserLevelUpDialogComponent,
    CustomToastyComponent,
    Gratification,
    CustomDialogComponent,
    ImgTextButtonCustomDialogComponent,
    ViewSolutionDialogComponent,
    ReferralDialogComponent,
    HelpreqoptComponent,
    RedeemComponent,
    CreateHelpRequestDialog,
    SelectCategoryDialog,
    OtpDialog,
    MediaDialog,
    InfoDialog,
    PaymentFailedPopupComponent
  ],
  providers: [
    MessagingService,
    LoginDialogService,
    AuthService,
    AudioRecordingService,
    GoogleAnalyticsService,
    MoengageEventsService,
    HelpRequestCategoryServiceService,
    UserDetailService,
    PreviousRouteService,
    LoclangDialogsService,
    ShowToastyService,
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
