import { DOCUMENT } from "@angular/common";
import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import firebase from "firebase";
import { CookieService } from "ngx-cookie";
import { BehaviorSubject } from "rxjs";
import { HrList, PhoneNo, PopularService, ReferralService, SessionLogin, TrendingQuestion } from "src/app/models/models";
import { AuthService } from "src/app/services/auth.service";
import { CommonService } from "src/app/services/common.service";
import { DataService } from "src/app/services/data.service";
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";
import { LanguageTranslationService } from "src/app/services/language-translation-service";
import { LoclangDialogsService } from "src/app/services/loclang-dialogs.service";
import { LoginDialogService } from "src/app/services/loginService";
import { MoengageEventsService } from "src/app/services/moengage_events_service";
import { UserDetailService } from "src/app/services/user-detail.service";
import { CategorieslistComponent } from "src/app/shared-components/categorieslist/categorieslist.component";

@Component({
    selector: 'app-expanded-view',
    templateUrl: './expanded-view.component.html',
    styleUrls: ['./expanded-view.component.scss']
})
export class ExpandedViewComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading=false;
  hrList:HrList[]=[];
  language:any;
  hrImage="https://firebasestorage.googleapis.com/v0/b/jaano2.appspot.com/o/hr_process_info.svg?alt=media&token=ee5be16d-f8ce-4dba-a155-2fa9f7551af6";
  helpRequest :any;

  trendingQuestionSubject = new BehaviorSubject<TrendingQuestion|null>(null);
  trendingQuestion:TrendingQuestion|null=null;
  explorePopularService= true;
  selectedSubCat= -1;
  selectedSubCatValue="";
  selectedSubCatSub = new BehaviorSubject<number>(89)

  constructor(private dataService:DataService,private activatedRoute:ActivatedRoute,
    private langaugeService:LanguageTranslationService,private moeService:MoengageEventsService,
    private router:Router,private commonService:CommonService,
    private cookieService:CookieService, private googleAnalyticsService:GoogleAnalyticsService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private _document: Document){
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const displayWidth = window.innerWidth;
      // in small devices there should not be slice, instead show all subcategories for popular services.
      this.endIndex= displayWidth >= 768? this.range:998;

      if(params['hr']){
        const landing_B = this.cookieService.get('landing_NEW')
        if(!landing_B){
          // also hit google event only first time.
          this.googleAnalyticsService.eventEmitter(
            "landing_NEW",
            "",
            "landing page for case NEW"
          );
          this.googleAnalyticsService.eventEmitter(
            "landing_exp_view",
            "",
            "landing page for case a/b"
          );
          (window as any).hj('event', 'landing_exp_view');
          this.cookieService.put('landing_NEW','true');
        }
        const helpsIndexes = params['hr'];
        this.getHrList(helpsIndexes);
      }
      if(params['unpaid'] && params['id']){
        this.getHelpRequestDetailsById(params['id'],0);
      }
      this.commonService.dontShowFooter.next(true);
    });
    this.langaugeService.getSelectedLanguage().subscribe((language:any)=>{
      this.language= language;
      this.getHelpFilters();
      this.getPopularServices();
      if(language && this.hrList && this.hrList.length>0){
        this.setHrDescription(language);
        if(this.trendingQuestion){
          this.trendingQuestion.language = language;
          this.getHelpRequestDetailsById(this.trendingQuestion.currenthelpid,0);
        }
      }
      if(!language){
        // setting language default as english
        this.langaugeService.setSelectedLanguage('en');
        this.langaugeService.startLanguageTranslator();
        this.moeService.eventEmitter('change_language', {platform : "web", lang : 'en'});
      }
    })

    // listening for changes in trending questions like pageno, or the first time execution.
    this.trendingQuestionSubject.subscribe(response=>{
      if(response){
        this.getSimilarQuestions(response);
      }
    })


    // LISTEN FOR SCROLL EVENT FOR SHOWING GET POPULAR SOLUTIONS
    this._document.addEventListener('scroll', this.onScroll);
  }

  tagSubCats:{catId:number,subCatId:number,subCat:string}[] = [];
  startIndex =0;
  endIndex = 10;
  range= 10;
  getHelpFilters() {
    let params = {
      language: this.language,
    };
    this.dataService
      .callv3API("getPopularServicesFilter", params)
      .subscribe((res) => {
        if (res.success) {
          let allTags= res.data.map((item:any)=>{return {
            catId:item.cat_id,subCatId:item.subcat_id,subCat:item.subcat_ln || item.subcat_en
          }})
          // allTags = allTags.slice(0, 10);
          this.tagSubCats = allTags;
          console.log('tag sub cat',this.tagSubCats);
        }

        // on change selected subcat from popular services.
        this.selectedSubCatSub.subscribe(subCatId=>{
          this.popularServices=[];
          const tagIndex = this.tagSubCats.findIndex(tag=>tag.subCatId == subCatId);
          if(tagIndex != -1){
            const subCat = this.tagSubCats[tagIndex].subCat;
            this.getPopularServiceByCatSubcat(subCatId,subCat);
          }
        })
      })
  }

  onSelectSubCat(subCatId:number){
    this.selectedSubCatSub.next(subCatId);
  }

  toggleAllCategories() {
    if(this.endIndex ==this.range){
      this.endIndex = 999;
    }
    else{
      this.endIndex = this.range;
    }
  }

  onScroll=(e:any)=>{
    console.log('on scroll ...',e)
  }

  ngOnInit() {
  }
  ngOnDestroy(){
    this.commonService.dontShowFooter.next(false);
    this._document.removeEventListener('scroll', this.onScroll);
    if(this.selectedSubCatSub){
      this.selectedSubCatSub.unsubscribe();
    }
  }

  latLng:any;
  getHrList=async (helpsIndexes:string)=>{
    const latLng:any = await this.getLocation();
    let hrIdArray:string[]=[];
    if(helpsIndexes){
      hrIdArray = helpsIndexes.split('h')
    }
    hrIdArray = hrIdArray.filter((data:any)=>data != '');
    let hrIdFinalArray :number[] = [];
    hrIdArray.forEach((data:any)=> hrIdFinalArray.push(+data))
    const req= {"hr_id":hrIdFinalArray};
    this.isLoading=true;
    this.dataService.getStandardHelpRequests(req).subscribe(response => {
      if(response && response.success){
        const data=response.result;
        if(data && data.length>0){
          const item = data[0];
          this.hrList.push({
            catId:item.ctg_id,
            helpId:item.hr_id,
            subCatId:item.sub_ctg_id,
            topic:item.topic,
            hrEn:item.hr_en,
            hrHi:item.hr_hi,
            hrMr:item.hr_mr,
            hrPa:item.hr_pa,
            hrTe:item.hr_te,
            hrDesc:item.hr_en,
            suggestion:item.suggestion_en,
            suggestionAs:item.suggestion_as,
            suggestionBn:item.suggestion_bn,
            suggestionEn:item.suggestion_en,
            suggestionHi:item.suggestion_hi,
            suggestionMr:item.suggestion_mr,
            suggestionNe:item.suggestion_ne,
            suggestionPa:item.suggestion_pa,
            suggestionTe:item.suggestion_te,
          })
        }
        if(this.language){
          this.setHrDescription(this.language);
        }
        this.resetTrendingQuestion(data[0].hr_id,1,this.language,data[0].ctg_id,data[0].sub_ctg_id,
          latLng.lat,latLng.lng);
      }
      this.isLoading=false;
    },
    error=>{
      this.isLoading=false;
    });
  }

  initiateHelpRequestFromExistingOne=(item:any)=>{
    this.googleAnalyticsService.eventEmitter( "pop_serv_cev","","");
    (window as any).hj('event', 'pop_serv_cev');
    const request={
      category:item.catId,
      subCat:item.subCatId,
      lat:this.latLng.lat,
      lng:this.latLng.lng,
      helpType:this.hrResponse?.help_type || 4,
      helpTitle:"",
      helpDescription:item.subject,
      selectedLanguage:this.language,
      pictures:{},
      video:null,
      voiceLink:null,
      webLink:null,
      doc:null,
      source:'expandedView'
    }
    let dialogRef = this.dialog.open(CreateHelpRequestDialog, {
      width: '400px',
      height: '220px',
      panelClass:'custom-panel-class',
      disableClose: true,
      data:request
    });
  }


  onSelectCategory=()=>{
    let dialogRef = this.dialog.open(SelectCategoryDialog, {
      width: '600px',
      height: '540px',
      panelClass:'custom-panel-class3',
      disableClose: true
    });
  }


  hrResponse:any;
  getHelpRequestDetailsById=async(helpid:any,index:number)=>{
    this.isLoading = true;
    try {
      const latLng:any = await this.getLocation();
      const requestDetails = {
        username: localStorage.getItem('username'),
        helpRequestId: helpid,
        commentsToShow: 5,
        repliesToShow: 5,
        lat: latLng.lat,
        lng: latLng.lng,
        metaData: { requestID: helpid},
      };
      this.dataService.callv3API('expand/getHelpRequestDetailsByID',requestDetails).subscribe(response=>{
        if(response && response.data){
          this.hrList = [];
          const item= response.data;
          this.hrResponse = response.data;
          let suggestion = "";
          const suggestions = response.data.comments;
          // first show only solutions
          for (const iterator of suggestions) {
            if(iterator.is_thankyou){
              suggestion=iterator.comment;
              break;
            }
          }
          // if has no solution as marked then show message added from admin side.
          if(suggestion == ""){
            for (const iterator of suggestions) {
              if(iterator.is_admin ){
                suggestion=iterator.comment;
                break;
              }
            }
          }
          // if has no solution as marked then show message added from is helpful and is starndard
          if(suggestion == ""){
            for (const iterator of suggestions) {
              if(iterator.is_helpful || iterator.is_standard ){
                suggestion=iterator.comment;
                break;
              }
            }
          }
          // if still has no suggestion use the first one as suggestion..
          if(suggestion == ""){
            for (const iterator of suggestions) {
              suggestion=iterator.comment;
              break;
            }
          }
          const hr: HrList= {
            catId:item.category,
            helpId:item.helpid,
            subCatId:item.sub_category,
            topic:item.help_description,
            hrEn:item.help_description,
            hrHi:item.help_description,
            hrMr:item.help_description,
            hrPa:item.help_description,
            hrTe:item.help_description,
            hrDesc:item.help_description,
            suggestion:suggestion,
            suggestionAs:suggestion,
            suggestionBn:suggestion,
            suggestionEn:suggestion,
            suggestionHi:suggestion,
            suggestionMr:suggestion,
            suggestionNe:suggestion,
            suggestionPa:suggestion,
            suggestionTe:suggestion,
          }
          // also switch the zero index hr with the clicked one.
          // this.onSelectHr(hr,index);
          this.hrList.push(hr);
          this.resetTrendingQuestion(helpid,1,this.language,item.category,item.sub_category,
            latLng.lat,latLng.lng);
          const element = document.getElementById("hrlistWrapper");
          element?element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }):'';

          // select the subcategory of popular help request on the basis of request subcategory.
          this.selectedSubCatSub.next(+item.sub_category);

        }
        console.log('hr list',this.hrList);
        this.isLoading = false;
      })
    } catch (error) {
     this.isLoading = false;
    }
  }

  setHrDescription(lang:any){
    switch(lang){
      case 'en':
        this.hrList.forEach(item=>{
          item.hrDesc = item.hrEn;
          item.suggestion = item.suggestionEn;
        });
        break;
      case 'hi':
        this.hrList.forEach(item=>{
          item.hrDesc = item.hrHi
          item.suggestion = item.suggestionHi;
        });
        break;
      case 'mr':
        this.hrList.forEach(item=>{
          item.hrDesc = item.hrMr
          item.suggestion = item.suggestionMr;
        });
        break;
      case 'pa':
        this.hrList.forEach(item=>{
          item.hrDesc = item.hrPa
          item.suggestion = item.suggestionPa;
        });
        break;
      case 'te':
        this.hrList.forEach(item=>{
          item.hrDesc = item.hrTe
          item.suggestion = item.suggestionTe;
        });
        break;
      case 'as':
        this.hrList.forEach(item=>{
          item.hrDesc = item.hrEn
          item.suggestion = item.suggestionAs;
        });
        break;
      case 'bn':
        this.hrList.forEach(item=>{
          item.hrDesc = item.hrEn
          item.suggestion = item.suggestionBn;
        });
        break;
      case 'ne':
        this.hrList.forEach(item=>{
          item.hrDesc = item.hrEn
          item.suggestion = item.suggestionNe;
        });
        break;
      default:
        this.hrList.forEach(item=>{
          item.hrDesc = item.hrEn
          item.suggestion = item.suggestionEn;
        });
    }
  }

  onLoadMore=()=>{
    if(this.trendingQuestion){
      this.trendingQuestion.pageNo = this.trendingQuestion.pageNo +1;
      this.trendingQuestionSubject.next(this.trendingQuestion);
    }
  }

  // onSelectHr=(hr:HrList,index:number)=>{
  //   let firstHr = this.hrList[0];
  //   this.hrList[0]=hr;
  //   this.hrList[index]=firstHr;
  //   const element = document.getElementById("topNavbar11");
  //   element?element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }):'';
  // }

  onInitiateRequest=()=>{
    if(this.hrList && this.hrList.length>0){
      this.googleAnalyticsService.eventEmitter(
        "hr_init_cev",
        "",
        "landing page for case NEW"
      );
      this.googleAnalyticsService.eventEmitter(
        "Ja_hr_init",
        "",
        "hr initialize from new expanded view."
      );  
      localStorage.setItem("hr_title",this.hrList[0].hrDesc);
      localStorage.setItem("hr_description",this.hrList[0].hrDesc);
      localStorage.setItem("hr_cat",this.hrList[0].catId.toString());
      localStorage.setItem("hr_sub_cat",this.hrList[0].subCatId.toString());
      localStorage.setItem('fromConfirmation','true');
      this.router.navigate(["./help-request/create-request"], {
        queryParams: { home:true },
      });
    }
  }

  getLocation=()=>{
    return new Promise((resolve,reject)=>{
      this.commonService.selectedLocation.subscribe(response=>{
        if(response && Object.keys(response).length > 0){
          const latlng = {lat:response.lat(), lng:response.lng()}
          this.latLng=latlng;
          resolve(latlng);
        }
        else{
          const latlng = {lat:-1, lng:-1}
          this.latLng=latlng;
          resolve(latlng);
        }
      })
    })
  }

  getSimilarQuestions=(trendingQuestion:TrendingQuestion)=>{
    this.dataService
      .getDataByEntity("getTrendingHR", trendingQuestion)
      .subscribe((response) => {
        if(response && response.data){
          const data= response.data;
          data.forEach((item:any)=>{
            this.hrList.push({
              catId:item.category,
              helpId:item.helpid,
              subCatId:item.sub_category,
              topic:item.help_description,
              hrEn:item.help_description,
              hrHi:item.help_description,
              hrMr:item.help_description,
              hrPa:item.help_description,
              hrTe:item.help_description,
              hrDesc:item.help_description,
              suggestion:"",
              suggestionAs:"",
              suggestionBn:"",
              suggestionEn:"",
              suggestionHi:"",
              suggestionMr:"",
              suggestionNe:"",
              suggestionPa:"",
              suggestionTe:"",
            })
          })
        }
      });
  }

  resetTrendingQuestion=(helpid:string,pageNo:number,lang?:string,cat?:string,subcat?:string,
    lat?:number,lng?:number)=>{
    const trendingRequest= {
      isSearch: false,
      search: "",
      category: cat?cat:"89",
      subCategory: subcat?subcat:"",
      isFilter: true,
      lat: lat?lat:-1,
      lng: lng?lng:-1,
      language: lang?lang:'en',
      withinKm: 0,
      stcode11: localStorage.getItem("stcode11"),
      dtcode11: localStorage.getItem("dtcode11"),
      stname: localStorage.getItem("stname"),
      dtname: localStorage.getItem("dtname"),
      contentType: 0,
      sortBy: "popularity",
      username: this.commonService.getUsername(),
      pageNo: pageNo,
      pageSize: 5,
      creationDateTime: 0,
      endDateTime: 0,
      currenthelpid: helpid,
    }
    this.trendingQuestion = trendingRequest;
    this.trendingQuestionSubject.next(trendingRequest);
  }

  popularServicePageNo=1;
  popularServices:{subject:string,subjectHn:string,subjectTe:string,subjectMr:string,subjectPa:string,subjectBn:string,subjectAs:string,
    subjectNe:string,subjectEn:string,catId:number,catValue:string,subCatId:number,subCatValue:string,pricing:string,fullCount:number}[]|any=[];

  getPopularServiceByCatSubcat(subCatId:number,subCat:string){
    this.selectedSubCat= subCatId;
    this.selectedSubCatValue = subCat;
    this.popularServices = [];
    this.popularServicePageNo = 1; 
    this.loadMore = false;
    this.getPopularServices();
  }
  getPopularServices(){
    let req:any={
      pageNo:this.popularServicePageNo,
      pageSize:this.loadMore?8:999
    }
    this.selectedSubCat != -1?req.sub_ctg_id = this.selectedSubCat:'';
    this.isLoading = true;
    this.dataService.getPopularServices(req).subscribe(response=>{
      if(response && response.success){
        const data= response.data;
        const popularService:any = [];
        data.forEach((item:any)=>{
          popularService.push({subject:item.subject,catId:item.ctg_id, catValue:item.ctg_name,subCatId:item.sub_ctg_id,
          subCatValue:item.sub_ctg_name,pricing:item.pricing,fullCount: +item.full_count,subjectHn:item.subject_hn,subjectTe:item.subject_te,
          subjectMr:item.subject_mr,subjectPa:item.subject_pa,subjectBn:item.subject_bn,subjectAs:item.subject_as,subjectNe:item.subject_ne,
          subjectEn:item.subject});
        });
        if(this.loadMore){
          this.popularServices = [...this.popularServices,...popularService];
        }
        else{
          this.popularServices =popularService;
        }
        this.popularServices = this.translateService(this.language,this.popularServices);
      }
      this.isLoading = false;
    },error=>{
      this.isLoading = false;
    })
  }
  loadMore = false;
  onLoadMorePopularService=()=>{
    this.popularServicePageNo = this.popularServicePageNo + 1;
    this.loadMore = true;
    this.getPopularServices();
  }

  translateService=(value:'en'|'hi'|'te'|'mr'|'pa'|'bn'|'as'|'ne',list:any)=>{
    if(list.length ==0){
      return list;
    }
    list.forEach((item:any)=>{
      switch(value){
        case 'en':{
          item.subject = item.subjectEn;
          break;
        }
        case 'hi':{
          item.subject = item.subjectHn;
          break;
        }
        case 'te':{
          item.subject = item.subjectTe;
          break;
        }
        case 'mr':{
          item.subject = item.subjectMr;
          break;
        }
        case 'pa':{
          item.subject = item.subjectPa;
          break;
        }
        case 'bn':{
          item.subject = item.subjectBn;
          break;
        }
        case 'as':{
          item.subject = item.subjectAs;
          break;
        }
        case 'ne':{
          item.subject = item.subjectNe;
          break;
        }
      }
    });
    return list;
  }

  scrollToPopularServices=()=>{
    this.explorePopularService = false;
    const element2= document.getElementById("popularServices112");
    if(element2){
      element2.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
        });
    }
    this.googleAnalyticsService.eventEmitter( "scroll_to_ps_cev","","");
    (window as any).hj('event', 'scroll_to_ps_cev');
  }
}



@Component({
  selector: 'create-request-dialog',
  templateUrl: 'create-request-dialog.component.html',
  styleUrls: ['expanded-view.component.scss']
})
export class CreateHelpRequestDialog {

  createName="";
  request:{
    category:string,
    subCat:string,
    lat:string,
    lng:string,
    helpType:string,
    helpTitle:string,
    helpDescription:string,
    selectedLanguage:string,
    pictures:any,
    video:string,
    voiceLink:string,
    webLink:string,
    doc:string
  }={
    category:"",
    subCat:"",
    lat:"",
    lng:"",
    helpType:"",
    helpTitle:"",
    helpDescription:"",
    selectedLanguage:"",
    pictures:{},
    video:"",
    voiceLink:"",
    webLink:"",
    doc:""
  }
  fromHome = false;
  constructor( public dialogRef: MatDialogRef<CreateHelpRequestDialog>,
    private cookieService:CookieService,private router:Router, private dataService:DataService,
    private googleAnalyticsService:GoogleAnalyticsService,
    private loginService:LoginDialogService,
    private loclatdialog: LoclangDialogsService,
    private authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data:any,private commonService:CommonService) {
      this.request = data;
      if(data && data.source == 'home'){
        this.fromHome = true;
      }
      this.googleAnalyticsService.eventEmitter( "Ja_hr_init","","");
      (window as any).hj('event', 'Ja_hr_init');
      this.setUserNameIfExist();
  }

  isLoading=false;
  setUserNameIfExist=async()=>{
    this.isLoading=  true;
    const fullname:any = await this.commonService.getUserFullName();
    this.createName = fullname;
    this.isLoading = false;
    if(this.createName){
      this.createHelpRequest();
    }
  }

  onClose(){
    this.dialogRef.close(false);
  }

  createHelpRequest=async()=>{
    this.isLoading = true;
    // google event
    if(this.fromHome){
      this.googleAnalyticsService.eventEmitter( "name_ps_home","","");
      (window as any).hj('event', 'name_ps_home');
    }
    else{
      this.googleAnalyticsService.eventEmitter( "name_ps_cev","","");
      (window as any).hj('event', 'name_ps_cev');
    }
    if(!this.createName || (this.createName && this.createName.length ==0)){
      this.isLoading = false;
      return false;
    }
    localStorage.setItem('requestCreated','true');
    await this.getLocation();
    this.loginService.openModal(
      { autoChangeScreen: true },
      (status: string) => {
        if (status) {
          let v1 = this.cookieService.get("localstars") == "true" ? 1 : 0;
          let v2 =
            this.cookieService.get("Help_Video_seen") == "true" ? 1 : 0;
          let v3 =
            this.cookieService.get("publicfacilities") == "true" ? 1 : 0;
          let v4 = this.cookieService.get("local") == "true" ? 1 : 0;
          let v5 = this.cookieService.get("official") == "true" ? 1 : 0;
          let v6 = JSON.stringify(Object.values({ v1, v2, v3, v4, v5 }));
    
          let metaData = {
            virtual_id: localStorage.getItem("virtualId"),
            browser_userAgent: this.cookieService.get("browser_userAgent"),
            LanguageSelected: this.cookieService.get("LanguageSelected"),
            LocationSelected: this.cookieService.get("LocationSelected"),
            Help_videos_seen: v2,
            Home_videos_seen: v6,
            platform: this.cookieService.get("platform"),
            is_logged__in: this.cookieService.get("is_logged__in"),
            Webtoken: localStorage.getItem("Webtoken"),
            username: this.cookieService.get("username"),
            mobile_number: this.cookieService.get("mobile_number"),
          };
          const body = {
            username: this.cookieService.get("username"),
            category: this.request.category,
            subCategory: this.request.subCat,
            lat: this.latLng.lat,
            lng: this.latLng.lng,
            metadata: JSON.stringify(metaData),
            stcode11: localStorage.getItem("stcode11"),
            dtcode11: localStorage.getItem("dtcode11"),
            helpType: this.request.helpType,
            helpTitle: this.createName,
            stname: localStorage.getItem("stname"),
            dtname: localStorage.getItem("dtname"),
            helpVideo: this.request.video,
            helpVoice:this.request.voiceLink,
            helpPictures:this.request.pictures,
            helpDescription:this.request.helpDescription,
            standard_hr_lang:this.request.selectedLanguage,
            is_paid_help: true,
            is_standard: false,
            is_popular_service:true
          };
          this.dataService.createNewHelpRequest(body).subscribe((req) => {
            localStorage.removeItem('requestCreated');
            if(req && req.success){
              const time = Date.now();
              localStorage.setItem("paid_help_creation_time",time.toString());
              localStorage.setItem("create_paid_help_id",req.result.helpid);
              this.router.navigate(["/help-request/getHelp-RequestbyId"], {
                queryParams: {
                  Id: req.result.helpid,
                  pageNo: "1",
                  status: "0",
                  source: "help",
                  isPaidType: "true"
                }
              });
              this.googleAnalyticsService.eventEmitter2('ja_create_hr',{});
              (window as any).hj('event', 'PaidHR_Created_Home');
              if(this.authService.authenticated){
                this.dataService.updateNewHridAndTimestamp(this.commonService.getUsername(),
                req.result.helpid);
              }
            }
            // this.IsLoader = false;
            this.dialogRef.close(false);
            this.isLoading = false;
          }, error=>{
            // this.IsLoader = false;
            this.dialogRef.close(false);
            this.isLoading = false;
          });
        }else{
          this.isLoading = false;
        }
      });
  }


  latLng:any;
  getLocation=async()=>{
    return new Promise((resolve,reject)=>{
      this.commonService.selectedLocation.subscribe(response=>{
        if(response && Object.keys(response).length > 0){
          const latlng = {lat:response.lat(), lng:response.lng()}
          this.latLng=latlng;
          const formattedLocation = {
            lat: response.lat(),
            lng: response.lng(),
          };
          localStorage.setItem("user_location", JSON.stringify(formattedLocation));
          resolve(latlng);
        }
        else{
          localStorage.setItem("admin_defined_location", "-1");
          this.loclatdialog.openChangeLocationDialog((res:any) => {
            resolve(null);
            // const latlng = {lat:-1, lng:-1}
            // this.latLng=latlng;
            // const formattedLocation = {
            //   lat: latlng.lat,
            //   lng: latlng.lng,
            // };
            // resolve(latlng);
          });
        }
      })
    })
  }
  
}



@Component({
  selector: 'select-category-dialog',
  templateUrl: 'select-category-dialog.component.html',
  styleUrls: ['expanded-view.component.scss']
})
export class SelectCategoryDialog {
  referralService:ReferralService = {
    emitraId:'',
    otherService:[],
    popularService:[]
  };

  searchCategory="";

  popularServices:PopularService[]=[]
  selectedLanguage="";
  referralCode = "";
  constructor( public dialogRef: MatDialogRef<SelectCategoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data:any,private dataService:DataService,
    private langaugeService:LanguageTranslationService,private googleAnalyticsService:GoogleAnalyticsService) {
      this.langaugeService.getSelectedLanguage().subscribe(value=>{
        if(value){
          if(value == 'hi') this.selectedLanguage = 'hn';
          else if(value =='te') this.selectedLanguage = 'tg';
          else this.selectedLanguage = value;
          if(data) this.referralCode = data.referralCode;
          this.getReferralServices();
        }
      })


  }

  getReferralServices=()=>{
    // for hindi language set hn and for telgu set tg
    let req:any={
      language:this.selectedLanguage
    }
    this.referralCode?req.referral_code=this.referralCode:'';
    this.dataService.getReferralServices(req).subscribe(response=>{
      if(response && response.success){
        this.referralService.emitraId = response.emitra_id;
        const otherServices = response.other_services;
        const popularServices = response.popular_services;
        this.referralService.otherService = otherServices.map((item:any)=>{
          return {
            subCat:item.subcat_en||item.subcat_hn||item.subcat_te||item.subcat_mr||item.subcat_bn
            ||item.subcat_as||item.subcat_ne||item.subcat_pa,
            subCatId:item.subcat_id,flag:true,catId:item.cat_id
          }
        });
        //  sort other services alphabetically
        this.referralService.otherService.sort((a,b)=>{
          return a.subCat.localeCompare(b.subCat);
        })
        this.referralService.popularService = popularServices.map((item:any)=>{
          return {
            subCat:item.subcat_en||item.subcat_hn||item.subcat_te||item.subcat_mr||item.subcat_bn
            ||item.subcat_as||item.subcat_ne|item.subcat_pa,
            subCatId:item.subcat_id,
            count:item.count,flag:true
          }
        });
      }
    })
  }

  getPopularServiceData=(subCatId:string,subCat:string,fromOther:boolean,catId?:string)=>{
    // google events
    if(fromOther){
      if(this.searchCategory && this.searchCategory.length>0){
        this.googleAnalyticsService.eventEmitter('cat_srh_crp', "normal category selected","");
        this.googleAnalyticsService.eventEmitter('cat_crp', "normal category selected","");
      }else{
        this.googleAnalyticsService.eventEmitter('cat_crp', "normal category selected","");
      }
    }
    else{
      if(this.searchCategory && this.searchCategory.length>0){
        this.googleAnalyticsService.eventEmitter('pop_cat_srh_crp', "popular category selected with search","");
        this.googleAnalyticsService.eventEmitter('pop_cat_crp', "popular category selected with search","");
      }else{
        this.googleAnalyticsService.eventEmitter('pop_cat_crp', "popular category selected","");
      }
    }
    const req={
      emitra_id:this.referralService.emitraId,
      subcat_id:subCatId
    }
    this.popularServices = [];
    if(fromOther){
      const popularServices =  [{
        catId:catId?catId:-1,
        subCatId:subCatId,
        message:null,
        helpTitle:null,
        emitraId:this.referralService.emitraId,
        listingId:null,
        price:0,
        fromOther:fromOther,
        subCatName:subCat
      }]
      this.dialogRef.close({popularServices:popularServices});
      return;
    }
    this.dataService.getPopularServiceData(req).subscribe(response=>{
      if(response && response.success && response.data && response.data.length>0){
        const popularServices = response.data;
        this.popularServices = popularServices.map((item:any)=>{
          return {
            catId:item.ctg_id || item.ctg,
            subCatId:item.sub_ctg_id || item.sub_ctg,
            message:item.msg || '',
            helpTitle:item.subject || item.help_title,
            emitraId:item.emitra_id || this.referralService.emitraId,
            listingId:item.listing_id,
            price:item.pricing || item.price,
            fromOther:fromOther,
            subCatName:item.sub_ctg_name || subCat
          }
        })
        if(this.popularServices.length>0){
          this.popularServices.push({
            catId:this.popularServices[0].catId,
            subCatId:this.popularServices[0].subCatId,
            message:this.popularServices[0].message,
            helpTitle:'Other',
            emitraId:this.popularServices[0].emitraId,
            listingId:-999,
            price:this.popularServices[0].price,
            fromOther:fromOther,
            subCatName:this.popularServices[0].subCatName,
          })
        }
        this.dialogRef.close({popularServices:this.popularServices});
      }
    })
  }

  onSearchCategory=()=>{
    if(this.searchCategory && this.searchCategory.length == 0){
      this.referralService.otherService.forEach(item=>item.flag = true);
    }
    else{
      this.referralService.otherService.forEach(item=>item.flag = false);
      this.referralService.popularService.forEach(item=>item.flag = false);
      this.referralService.otherService.forEach(item=>{
        let subcat = item.subCat?item.subCat.toUpperCase():'';
        let subcatSearch = this.searchCategory?this.searchCategory.toUpperCase():this.searchCategory;
        if(subcat.includes(subcatSearch)){
          item.flag = true;
        }
      })
      this.referralService.popularService.forEach(item=>{
        let subcat = item.subCat?item.subCat.toUpperCase():'';
        let subcatSearch = this.searchCategory?this.searchCategory.toUpperCase():this.searchCategory;
        if(subcat.includes(subcatSearch)){
          item.flag = true;
        }
      })
    }
  }
}



@Component({
  selector: 'otp-dialog',
  templateUrl: 'otp-dialog.component.html',
  styleUrls: ['expanded-view.component.scss']
})
export class OtpDialog {
  otp = '';
  otpTimer = '';
  phoneNo="";
  userDetails:any;
  color = "primary";
  mode = "indeterminate";
  value = 50;
  IsLoader: boolean = false;
  userNameDialog= false;
  userDetailForm: FormGroup = new FormGroup({
      userName: new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z ?]{1,100}")]),
      mobileNo: new FormControl('',[Validators.required,Validators.pattern("^[0-9]{10,10}")]),
  });
  get userName(){
    return this.userDetailForm.get('userName');
  }
  get mobileNo(){
    return this.userDetailForm.get('mobileNo');
  }
  constructor( public dialogRef: MatDialogRef<OtpDialog>,
    @Inject(MAT_DIALOG_DATA) public data:any,private cookieService:CookieService,private googleAnalyticsService:GoogleAnalyticsService,
    private languageService:LanguageTranslationService,private dataService:DataService,private commonService:CommonService,
    private authSerivce:AuthService,private userService:UserDetailService ) {
      this.googleAnalyticsService.eventEmitter('login_open_crp', "login pop-up from create request flow","");
      this.googleAnalyticsService.eventEmitter('login_open', "login pop-up from create request flow","");
      if(data){
        this.phoneNo = data.userDetails.phone_no;
        this.userDetails = data.userDetails;
        if(!this.userDetails.username || !this.userDetails.phone_no){
          this.userNameDialog = true;
        }
        else{
          this.sendOtpOnLoginNew();
        }
      }
  }



  remainingMinute = 1;
  remainingSeconds = 0;
  remainingTime = '01:00';
  countDown:any;
  otp1='';
  otp2='';
  otp3='';
  otp4='';
  otp5='';
  otp6='';
  setCountDown=()=>{
    this.countDown = setInterval(()=>{
      if(this.remainingMinute == 0 && this.remainingSeconds == 0){
        clearInterval(this.countDown);
        return;
      }
      if(this.remainingSeconds == 0){
        this.remainingMinute = this.remainingMinute - 1;
        this.remainingSeconds = 59;
      }
      else{
        this.remainingSeconds = this.remainingSeconds - 1;
      }
      const minutes = '0'+this.remainingMinute
      const seconds = (this.remainingSeconds.toString())
                        .length==1?'0'+this.remainingSeconds:this.remainingSeconds;
      this.remainingTime= minutes+':'+seconds;
    },1000)
  }

  getOtp(){
    if(this.userDetailForm.invalid){
      return;
    }
    this.userNameDialog = false;
    this.userDetails.username = this.userDetailForm.value.userName;
    this.userDetails.phone_no = this.userDetailForm.value.mobileNo;
    this.phoneNo = this.userDetailForm.value.mobileNo.toString();
    console.log('user detail form',this.userDetailForm);
    if(this.phoneNo && !this.phoneNo.includes('+91')) this.phoneNo = '+91'+this.phoneNo;
    this.userDetails.phone_no = this.phoneNo;
    console.log('sending otp on new login');
    this.sendOtpOnLoginNew();
  }
  onChangeMobileNo(){
    let mobileNo = this.userDetailForm.value.mobileNo;
    if(mobileNo && mobileNo.length>10){
      mobileNo = mobileNo.toString();
      mobileNo = mobileNo.substring(0,10);
      this.userDetailForm.patchValue({mobileNo:mobileNo});
      // this.userDetailForm.controls['mobileNo'].markAsTouched();
    }
  }


  resendNewOtp(){
    this.googleAnalyticsService.eventEmitter('resend_otp_crp', "login pop-up new flow","resend OTP");
    this.googleAnalyticsService.eventEmitter('resend_otp', "login pop-up new flow","resend OTP");
    (window as any).hj('event', 'resend_otp_crp');
    const req:PhoneNo={
      phone_no:this.phoneNo
    }
    this.dataService.resendOTP(req).subscribe(response=>{
      this.remainingMinute = 1;
      this.remainingSeconds = 0;
      clearInterval(this.countDown);
      this.setCountDown();
    })
  }


  clearInterval_(){
    this.googleAnalyticsService.eventEmitter('edit_ph_crp', "edit phone no new flow","edit phone no");
    this.googleAnalyticsService.eventEmitter('edit_ph', "edit phone no new flow","edit phone no");
    clearInterval(this.countDown);
    // this.dialogRef.close(false);
    this.userNameDialog = true;
    this.otp1='';
    this.otp2='';
    this.otp3='';
    this.otp4='';
    this.otp5='';
    this.otp6='';
    this.otp='';
  }

  onOtp1(event:any){
    this.invalidOtp = false;
    const otp = event.target.value;
    if(otp.length>1){
      this.otp1 = otp.substring(0,1);
    }
    this.otp = this.otp1?.toString() + this.otp2?.toString()+this.otp3?.toString()
    +this.otp4?.toString()+this.otp5?.toString()+this.otp6?.toString();
    if(this.otp.length == 6){
      this.onVerify();
    }
    if(otp.length==1){
      const element = document.getElementById("otp2");
      if(element){
        element.focus();
      }
    }
  }
  onOtp2(event:any){
    this.invalidOtp = false;
    const otp = event.target.value;
    if(otp.length>1){
      this.otp2 = otp.substring(0,1);
    }
    const previousElement = document.getElementById("otp1");
    // on backspace or delete otp return to previous one..
    const key = event.keyCode || event.charCode;
    if(key == 8 || key == 46 && otp.length ==0){
      previousElement?.focus();
      return;
    }
    this.otp = this.otp1?.toString() + this.otp2?.toString()+this.otp3?.toString()
    +this.otp4?.toString()+this.otp5?.toString()+this.otp6?.toString();
    if(this.otp.length == 6){
      this.onVerify();
    }
    if(otp.length==1){
      const element = document.getElementById("otp3");
      if(element){
        element.focus();
      }
    }
  }
  onOtp3(event:any){
    this.invalidOtp = false;
    const otp = event.target.value;
    if(otp.length>1){
      this.otp3 = otp.substring(0,1);
    }
    const previousElement = document.getElementById("otp2");
    // on backspace or delete otp return to previous one..
    const key = event.keyCode || event.charCode;
    if(key == 8 || key == 46 && otp.length ==0){
      previousElement?.focus();
      return;
    }
    this.otp = this.otp1?.toString() + this.otp2?.toString()+this.otp3?.toString()
    +this.otp4?.toString()+this.otp5?.toString()+this.otp6?.toString();
    if(this.otp.length == 6){
      this.onVerify();
    }
    if(otp.length==1){
      const element = document.getElementById("otp4");
      if(element){
        element.focus();
      }
    }
  }
  onOtp4(event:any){
    this.invalidOtp = false;
    const otp = event.target.value;
    if(otp.length>1){
      this.otp4 = otp.substring(0,1);
    }
    this.otp = this.otp1?.toString() + this.otp2?.toString()+this.otp3?.toString()
    +this.otp4?.toString()+this.otp5?.toString()+this.otp6?.toString();
    if(this.otp.length == 6){
      this.onVerify();
    }
    const previousElement = document.getElementById("otp3");
    // on backspace or delete otp return to previous one..
    const key = event.keyCode || event.charCode;
    if(key == 8 || key == 46 && otp.length ==0){
      previousElement?.focus();
      return;
    }
    if(otp.length==1){
      const element = document.getElementById("otp5");
      if(element){
        element.focus();
      }
    }
  }
  onOtp5(event:any){
    this.invalidOtp = false;
    const otp = event.target.value;
    if(otp.length>1){
      this.otp5 = otp.substring(0,1);
    }
    this.otp = this.otp1?.toString() + this.otp2?.toString()+this.otp3?.toString()
    +this.otp4?.toString()+this.otp5?.toString()+this.otp6?.toString();
    if(this.otp.length == 6){
      this.onVerify();
    }
    const previousElement = document.getElementById("otp4");
    // on backspace or delete otp return to previous one..
    const key = event.keyCode || event.charCode;
    if(key == 8 || key == 46 && otp.length ==0){
      previousElement?.focus();
      return;
    }
    if(otp.length==1){
      const element = document.getElementById("otp6");
      if(element){
        element.focus();
      }
    }
  }
  onOtp6(event:any){
    this.invalidOtp = false;
    const otp = event.target.value;
    if(otp.length>1){
      this.otp6 = otp.substring(0,1);
    }
    this.otp = this.otp1?.toString() + this.otp2?.toString()+this.otp3?.toString()
    +this.otp4?.toString()+this.otp5?.toString()+this.otp6?.toString();
    if(this.otp.length == 6){
      this.onVerify();
    }
    const previousElement = document.getElementById("otp5");
    // on backspace or delete otp return to previous one..
    const key = event.keyCode || event.charCode;
    if(key == 8 || key == 46 && otp.length ==0){
      previousElement?.focus();
      return;
    }
  }

  invalidOtp = false;
  onVerify(){
    if(this.otp1 ==null || this.otp2 ==null || this.otp3 ==null || this.otp4 ==null || this.otp5 ==null || this.otp6 ==null){
      this.invalidOtp = true;
      return;
    }
    this.otp = this.otp1?.toString() + this.otp2?.toString()+this.otp3?.toString()
    +this.otp4?.toString()+this.otp5?.toString()+this.otp6?.toString();
    if(this.otp.length != 6){
      this.invalidOtp = true;
      return;
    }
    this.verifyCodeNew();
  }

  onResendOtp=()=>{
    this.invalidOtp = false;
    clearInterval(this.countDown);
    this.setCountDown();
    console.log('inside on resend otp');
    return;
    this.resendNewOtp();
  }

  loginDetails:any;
  sendOtpOnLoginNew(){
    this.phoneNo = this.phoneNo;
    const userLocation:any = localStorage.getItem('user_location');
    const storelocation = JSON.parse(userLocation);
    const httpPostParameters = {
      "phone_no": this.phoneNo,
      webNotificationToken: null,
      "language":(this.userDetails.standard_hr_lang),
      "lat": storelocation.lat,
      "lng": storelocation.lng,
      "stcode11": localStorage.getItem('stcode11'),
      "dtcode11": localStorage.getItem('dtcode11'),
      "stname": localStorage.getItem('stname'),
      "dtname": localStorage.getItem('dtname'),
      "referred_by_code":''    
    }
    console.log('request ==**',httpPostParameters,this.userDetails);
    this.dataService.getDataByEntity('userAuthentication', httpPostParameters)
      .subscribe(
        (res:any) => {
          // if provided username is already exists.
          if(res.success== true && res.data && res.data == 'username_exist'){
            // this.onLoginRequestInProcess = false;
            return false;
          }
          else if (res.success == true) {
            const req:PhoneNo={
              phone_no:this.phoneNo
            }
            this.dataService.sendOTP(req).subscribe(response=>{
              // if(res && res.userCreated == true){
              //   this.wasUserSignedUp = true;
              // }
              // this.pagechange('otpScreen');
              this.googleAnalyticsService.eventEmitter2("req_otp_crp", {platform: "web"});
              this.googleAnalyticsService.eventEmitter2("req_otp", {platform: "web"});
              this.loginDetails = res;
              localStorage.removeItem('notificationArr');
              // this.commonService.setUsername(this.loginDetails.username);
              // localStorage.setItem('phone_no', this.phoneNo);
              this.cookieService.put('username', this.loginDetails.username);
              this.cookieService.put('mobile_number', this.phoneNo);
              this.setCountDown();
            },error=>{
              console.log('error on send otp',error)
            })
          }
        },
        err => {
          console.log('error on user authentication',err)
        }
      )
  }



  helpid = "";
  verifingOtp=false;

  updateUsername(){
    const localUserFirstname = localStorage.getItem('full_name');
    const username = this.userDetailForm.value.userName;
    if(localUserFirstname == username || !username){
      return;
    }
    const req={
      full_name: username,
      phone_no:this.phoneNo
    }
    this.dataService.updateUserName(req).subscribe(response=>{
      console.log('username updated successfully',response);
      if(username && response){
        username? localStorage.setItem('full_name',username.toString()):'';
      }
    },error=>{
      console.log('error while updating username');
    })
  }
  verifyCodeNew(){
    // here we removed old approach of firebase login with otp instead use siginInwithCustomToken
    // check for validation of verification code.
    if (Boolean(this.otp) && this.otp.length == 6) {
        const username = this.cookieService.get('username');
        this.userDetails.code = this.otp;
        this.userDetails.username = username;
        this.userDetails.helpTitle = this.userDetailForm.value.userName;
        // verify otp
        this.IsLoader = true;
        this.dataService.verifyOTPAndCreateHelpRequest(this.userDetails).subscribe(otpResponse=>{
          if(otpResponse && otpResponse.result && otpResponse.result.helpid){
            this.helpid = otpResponse.result.helpid;
          }
          if(otpResponse && otpResponse.success){
            // got login details from the previous api while sending otp.
            this.commonService.setUsername(this.loginDetails['username']);
            const _token = otpResponse.customToken;
            // perform sign in with custom token.
            this.verifingOtp = true;
            firebase.auth().signInWithCustomToken(_token)
              .then((userCredential) => {
                // getting token from firebase which need to passed for session login.
                userCredential.user?.getIdToken().then(newToken=>{
                  // setting session
                  const csrftoken = this.cookieService.get('csrfToken');
                  const _csrf = this.cookieService.get('_csrf');
                  const newReq:SessionLogin={
                    idToken:newToken,
                    csrfToken:csrftoken
                  }
                  this.dataService.sessionLogin(newReq).subscribe(response=>{
                    if(response && response.status){
                      // ...

                      this.googleAnalyticsService.eventEmitter('verify_otp_crp', "verify otp new flow","verify otp");
                      this.googleAnalyticsService.eventEmitter('verify_otp', "verify otp new flow","verify otp");
                      localStorage.setItem('phone_no', this.phoneNo);
                      this.getUserLoginDetail();
                      this.getUserDetails();
                      this.updateUsername();
                      this.authSerivce.userAuthToken.subscribe((response1) => {
                        if (response1) {
                          localStorage.setItem('authToken',response1);
                          this.dialogRef.close({helpid:this.helpid});
                        } else {
                          this.authSerivce.currentUserIdToken().subscribe((response2:any) => {
                            if (response2) {
                              localStorage.setItem('authToken',response2);
                            }
                            if(response2){
                              this.dialogRef.close({helpid:this.helpid});
                            }
                          },
                          (error:any)=>{
                            this.dialogRef.close({helpid:this.helpid});
                          });
                        }
        
                      },error=>{
                        this.dialogRef.close({helpid:this.helpid});
                        console.log("token",error);
                        this.verifingOtp = false;
                      });
                      this.verifingOtp = false;
                    }
                  },error=>{
                    console.error(error);
                    this.verifingOtp = false;
                  })
                },error=>{
                  console.error(error);
                  this.verifingOtp = false;
                });
                // Signed in
                const user = userCredential.user;
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorMessage);
                this.verifingOtp = false;
                // ...
              });  
          }
          else{
            this.invalidOtp = true;
            this.googleAnalyticsService.eventEmitter2('web_Login_Failed', {platform : "web"});
            this.IsLoader = false;
            return false;
          }
          this.IsLoader = false;
        },error=>{
          this.IsLoader = false;
          this.googleAnalyticsService.eventEmitter2('web_Login_Failed', {platform : "web"});
          return false;
        })
    }
    else {
      return false;
    }
  }

  details = {
    lat: '',
    lng: '',
    language: ''
  };

  getUserLoginDetail() {
    this.commonService.selectedLocation.subscribe((value) => {
      if (value && Object.keys(value).length > 0) {
        this.details.lat = value.lat();
        this.details.lng = value.lng();
        this.details.language = this.languageService.getLanguage() || 'en';
      }
    });
    this.dataService.getUserLocationDetails(this.details).subscribe((res) => {
      localStorage.setItem('stcode11', res.data.stcode11);
      localStorage.setItem('dtcode11', res.data.dtcode11);
      localStorage.setItem('stname', res.data.state_ln || res.data.stname);
      localStorage.setItem('dtname', res.data.dtname);
      localStorage.setItem('district_ln', res.data.district_ln);
      localStorage.setItem('mandalname', res.data.mandalname_ln || res.data.mandalname);
      localStorage.setItem('mandalcode', res.data.mandalcode);
      localStorage.setItem('clustername', res.data.clustername_ln || res.data.clustername);
      localStorage.setItem('clustercode', res.data.clustercode);

      if ((window as any).webengage) {
        (window as any).webengage.user.setAttribute('region', res.data.stname);
        (window as any).webengage.user.setAttribute('locality', res.data.dtname);
      }
    })
  }
  getUserDetails() {
    // location.reload();
    const data2 = { phone_no: this.phoneNo };
    this.dataService.getDataByEntity('getUserProfile/getUserDetails', data2).subscribe(result => {

      if ((window as any).webengage) {
        (window as any).webengage.user.setAttribute('we_first_name', result.result[0].username);
        (window as any).webengage.user.setAttribute('we_phone', result.result[0].phone_no);


        if (Boolean(result.result[0].email) && result.result[0].email != "null")
          (window as any).webengage.user.setAttribute('we_email', result.result[0].email);

        if (Boolean(result.result[0].dob) && result.result[0].dob != "null")
          (window as any).webengage.user.setAttribute('birth_date', result.result[0].dob);

        if (Boolean(result.result[0].gender) && result.result[0].gender != "null")
          (window as any).webengage.user.setAttribute('we_gender', result.result[0].gender == "f" || result.result[0].gender.toLowerCase() == 'female' ? 'female' : result.result[0].gender == "m" || result.result[0].gender.toLowerCase() == 'male' ? 'male' : 'other');

      }

      localStorage.setItem('profile_picture', result.result[0].profile_picture);
      localStorage.setItem('phone_no', result.result[0].phone_no);
      this.commonService.setUsername(result.result[0].username);
      localStorage.setItem('jaano_points', result.result[0].total_jaano_points);
      localStorage.setItem('my_referral_code',result.result[0].referral_code);
      this.commonService.setJaanoPoints(result.result[0].total_jaano_points);
      this.userService.setUserDetails(result.result[0].username, result.result[0].profile_picture);
    });
  }

}