import { Injectable } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {
  private previousUrl: string="";
  private currentUrl: string="";

  constructor(private router: Router) {
    // this.currentUrl = this.router.url;
    // router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.previousUrl = this.currentUrl;
    //     this.currentUrl = event.url;
    //   };
    // });
    this.router.events
    .pipe(filter((e:any) => e instanceof RoutesRecognized), pairwise())
      .subscribe((e: RoutesRecognized[]) => {
        this.previousUrl = e[0].urlAfterRedirects;
        this.currentUrl = e[1].urlAfterRedirects;
      });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getCurrentUrl () {
    return this.currentUrl;
  }

}
