<div class="opacity_overlay" *ngIf="IsLoader">
  <mat-progress-spinner *ngIf="IsLoader" class="example-margin" [color]="color" [mode]="$any(mode)" [value]="value">
  </mat-progress-spinner>
</div>
<div class="container main-container box-shadow-4px" style="position:relative;"
[hidden]="newCreateRequestFlow">
  <!-- <div class="col-12 mt-3 pd0">

    <div (click)="backButton()" style="cursor: pointer;"
      class="d-flex align-center mb-2">
      <span><img src="../../../../assets/images/back_arrow_gray.svg" alt="Next" height="14"></span>
      <span class="next-page">{{'back' | translate}}</span>
    </div>
  </div> -->
  <div class="post_request mt-3">
    <div class="mt-1">
      <div class="help-request-header">
        <h2 class="post_request_heading mb-4" *ngIf="helpRequestId">{{'edit_help_request' | translate}}</h2>
        <h2 class="post_request_heading mb-4" *ngIf="!helpRequestId">{{'Create a help request' | translate}}</h2>
      </div>
    </div>
    <div *ngIf="showScreen == 0">
      <div class="card no_border" style="cursor: pointer;" joyrideStep="step_7" [stepContent]="customContent"
        [stepContentParams]="{'content': 'select_from_the_bottom_categories'}">
        <div class="mt-2">
          <div class="subheading1 mb-2">
            {{ 'What kind of help do you want?' | translate }}
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-10 pd0">
        <div class="card no_border" style="cursor: pointer;" joyrideStep="step_8" [stepContent]="customContent"
          [stepContentParams]="{'content': 'walkthrough_add_help_request_category'}">
          <div class="row mt-3">
            <div class="col-4 col-lg-2 item-wrapper" *ngFor="let type of categoryList; let i= index">
              <div class="flex-text-center icon_bkg" (click)="selectCategoryOption(type.cat_id)">
                <div [class]="selectedOption == type.cat_id?'icon-background-selected':'icon-background'">
                  <img class="request-icon" alt="Image"
                    [src]="selectedOption == type.cat_id ? type.cat_icon_selected:type.cat_icon">
                </div>
                <div class="icon-name">
                  {{type.CatlangKey | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showScreen == 1">
      <div class="p-3" [ngClass]="{'error-box':emptyCat && (!(selectedOption && selectedOption > 0) || general),'opacity-3':popularService}">
        <div class="row" *ngIf="!(selectedOption && selectedOption > 0) || general">
          <div class="col mr-auto">
            <div class="subheading1 mb-2" #help_category
            [style.color]="emptyCat?'#EC1919':'#131416'">
            {{'Select Category' | translate}}: &#42;
            </div>
          </div>
          <div *ngIf="emptyCat" class="col-auto err-msg">
            {{'Please select a category'|translate}}
          </div>
        </div>
        <ng-container *ngIf="fromGoogleAds2">
          <div class="col-12 option-overflow">
            <div class="icon_bkg icon-background-selected2">
              <span class="icon-font">{{subCategoryValue}}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!fromGoogleAds2">
          <div class="col-12 option-overflow mb-4" *ngIf="general">
            <div class="icon_bkg"
              [ngClass]="selectedOption == type.cat_id && selectSubType  == type.sub_cat_final ?'icon-background-selected2':'icon-background-page2'"
              *ngFor="let type of selectCategoryArray"
              (click)="selectCategoryAndSubCatOption(type.cat_id, type.sub_cat_final)">
              <span class="icon-font">{{type.subcat_lang_final}} </span>
            </div>
            <div class="icon_bkg icon-background-page2" (click)="onSelectMore()"
              id="moreSelect">
              <span class="icon-font"> {{'more'| translate | uppercase}} </span>
            </div>
          </div>
          <div class="col-12 option-overflow mb-4" *ngIf="!general && !(selectedOption && selectedOption > 0)">
            <!-- <div class="flex-text-center" *ngFor="let type of getSelectedCategorArray(); let i= index"
              (click)="selectCategoryOption(type.cat_id)">
              <div [class]="selectedOption == type.cat_id ?'icon-background-selected2':'icon-background-page2'">
                <img class="m1-1" [src]="selectedOption == type.cat_id? type.cat_icon_selected:type.cat_icon_unselected"
                  alt="Image">
              </div>
              <span class="icon-font">
                {{type.CatlangKey | translate}}
              </span>
            </div> -->
            <div class="icon_bkg"
              [ngClass]="selectedOption == type.cat_id ?'icon-background-selected2':'icon-background-page2'"
              *ngFor="let type of getCategoryArray()" (click)="selectCategoryOption(type.cat_id)" id="{{type.cat_id}}">
              <img [src]="selectedOption == type.cat_id? type.cat_icon_selected:type.cat_icon_unselected" alt="Image">
              <span class="icon-font"> {{type.CatlangKey | translate}} </span>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="p-3" [ngClass]="{'error-box':emptySubCat && !general && selectedOption && selectedOption > 0}" *ngIf="!fromGoogleAds2">
        <div class="card no_border" style="cursor: pointer;" joyrideStep="step_9" [stepContent]="customContent"
          *ngIf="!general && selectedOption && selectedOption > 0"
          [stepContentParams]="{'content': 'walkthrough_help_request_add_subcategory'}">
          <div class="row">
            <div class="col mt-2 ml-0 mr-auto" #help_sub_category>
              <div class="subheading1"> 
                {{'Select Sub-Category' | translate}}:
                <div class="selected_cate">
                  <!-- <img [src]="selectCategoryDetails().cat_icon_selected" height="11" /> -->
                  {{selectCategoryDetails().CatlangKey}}
                  <img (click)="selectedOption = 0" src="../../../../assets/images/white-close.svg" height="11" alt="close-icon" />
                </div>
              </div>
            </div>
            <div *ngIf="emptySubCat" class="err-msg"
            style="position: absolute;
            right: 0px;
            top: -9px;">
              {{'Please select a subcategory.'|translate}}
            </div>
          </div>
          <div class="row mt-2">
            <div class="" *ngFor="let sub of subcategories" style="cursor: pointer;"
              (click)="selectedSubType(sub.index)">
              <diV
                [class]="subCategoryValue == sub.value || subTypeCat == sub.index?'icon-background-page2-selected subheading3-selected subheading3':'icon-background-page2 subheading3'">
                {{sub.value}}
              </diV>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="p-3" [ngClass]="{'error-box':emptyTitle}">
        <div class="card no_border" style="cursor: pointer;" joyrideStep="step_10" [stepContent]="customContent"
          [stepContentParams]="{'content': 'walkthrough_help_request_add_title'}">
          <div class="row m-0" #help_title >
            <div class="mr-auto mt-2">
              <div class="subheading1"
              [style.color]="emptyTitle?'#EC1919':'#131416'">
              {{'title' | translate}}: &#42;
              </div>
            </div>
            <div *ngIf="emptyTitle" class="col-auto err-msg">
              {{'Please enter a title'|translate}}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <input class="inputRequest icon-font" [(ngModel)]="helpType"
                placeholder="{{'title_placeholder2' | translate}}" type="text" />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="p-3 mt-2" [ngClass]="{'error-box':emptyDescription,'opacity-3':popularService}">
        <div class="row">
          <div class="col mr-auto mt-2" #help_description>
            <div class="subheading1"
            [style.color]="emptyDescription?'#EC1919':'#131416'">
            {{'txt_details' | translate}}: &#42;
            </div>
            
          </div>
          <div *ngIf="emptyDescription" class="col-auto err-msg">
            {{'Please enter details'|translate}}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <textarea class="inputRequest2 icon-font" [(ngModel)]="helpDescription"
              placeholder="{{'description_placeholder2' | translate}}" type="text" [disabled]="popularService"></textarea>
          </div>
        </div>
      </div>
      <hr />
      <!-- <div class="mt-2" [ngClass]="{'error-box':emptyHrType}" style="position:relative">
        <div *ngIf="emptyHrType" class="err-msg p-3"
        style="position: absolute; right: 0px; top: -5px;">
          {{'Please select the request type'|translate}}
        </div>
        <div class="row mt-2 p-3" *ngIf="showScreen != 1 || !helpRequestId"
        #help_type>
          <div class="col-6 mt-2">
            <div class="subheading1">
              {{'Do you require Paid help?' | translate}}
            </div>
          </div>
          <div class="col-6 mt-2 pr-0 pl-0">
            <button class="paid-help-button mr-2 pr-2" type="button" [ngClass]="{'active-btn' :isPaidHelpRequest}"
              (click)="helpRequestTypeSelected('YES')">
              <img class="mr-1" src="../../../../assets/images/tick_circle.svg" width="21px" height="21px" 
               *ngIf="isPaidHelpRequest" />
              <img class="mr-1" src="../../../../assets/images/grey-circle-tick.svg" width="21px" height="21px"
              *ngIf="!isPaidHelpRequest" />
              {{'Yes' | translate}}
            </button>
            <button class="paid-help-button pr-2" type="button" 
            [ngClass]="{'active-btn' :!isPaidHelpRequest && hrTypeSelected}"
            (click)="helpRequestTypeSelected('NO')" *ngIf="showPaidTypeToggleNo">
              <img class="mr-1" src="../../../../assets/images/inactive-cross.svg"
               width="21px" height="21px" *ngIf="isPaidHelpRequest || !hrTypeSelected">
              <img class="mr-1" 
              src="../../../../assets/images/new_help_icons/cross-circle.svg" 
              width="21px" height="21px" *ngIf="!isPaidHelpRequest && hrTypeSelected"
              style="    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(104deg) brightness(103%) contrast(103%);">
              {{'no_' | translate}}
            </button>
          </div>
        </div>
        <div class="row mt-16 p-3" *ngIf="hrTypeSelected">
          <div class="col">
            <span class="gray-text text-1" *ngIf="isPaidHelpRequest">
              <em>{{'paid_yes_info'|translate}}</em>
            </span>
            <span class="gray-text text-1" *ngIf="!isPaidHelpRequest">
              <em>{{'paid_no_info'|translate}}</em>
            </span>
          </div>
          <div class="col-auto ml-auto" *ngIf="isPaidHelpRequest">
            <span class="blue-text text-1 cursor-pointer"
            (click)="openKnowMoreLink()">
              {{'know_more'|translate}}
            </span>
          </div>
        </div>
      </div> -->
      <hr />
      <div class="row p-3">
        <div class="col-md-12">
          <div class="subheading1">{{ 'add_media_file' | translate }}:
            <!-- <img class="ml-3" src="../../../../assets/images/attach.svg" alt="Image"> -->
          </div>
        </div>
      </div>
      <div class="col-12 p-3 d-flex" *ngIf="selectedImageThumbnail.length > 0">
        <div *ngFor="let image of selectedImageThumbnail; let idx= index"
          class="imagesContainer addImageListItems mt-3 mr-2" style="display: flex;">
          <div><img style="height:100px;max-width:150px;object-fit:cover;" src="{{image}}" alt="Image"></div>
          <i class="fa fa-trash trash_icon" (click)="remove_picture(idx)"></i>
        </div>
      </div>
      <!-- audio record -->
      <div *ngIf="voiceOptionSelected" class="d-flex p-3 sm-flex-column">
        <div class="ml-2">
          <audio *ngIf="!isRecording && audioBlobURL" controls>
            <source [src]="audioBlobURL" type="audio/webm">
          </audio>
        </div>
      </div>

      <div *ngIf="showVideo" class="col-12 p-3 d-flex">
        <div class="videoContainer">
          <!-- <video id=ideoCustom src="{{videosThumbnile}}" style="height: 100px;"></video> -->
          <img id=ideoCustom src="../../../../assets/images/Play 1.svg" style="height: 100px;" alt="Image">
        </div>
        <i class="fa fa-trash cross_icon" (click)="removeVideoSelect()"></i>
      </div>

      <div class="card no_border p-3" style="cursor: pointer;" joyrideStep="step_11" [stepContent]="customContent"
        [stepContentParams]="{'content': 'walkthrough_help_request_add_media'}">
        <div class="topdiv post mb-4 mt-2">
          <div class="icondiv sm-pdr0" (click)="selectAttachOption(1)" data-toggle="modal" data-target="#imageModal">
            <img
              [src]="imageOptionSelected? '../../../assets/images/img-active.svg' :'../../../assets/images/iconmonstr-picture-2.svg'"
              alt="Image">
            <span class="ml-1" [ngClass]="{'activeClass': (imageOptionSelected)}">{{'Photo click / upload' |
              translate}}</span>
          </div>
          <div class="icondiv sm-pdr0" (click)="selectAttachOption(2)" data-toggle="modal" data-target="#exampleModal">
            <img
              [src]="videoOptionSelected? '../../../assets/images/video-active.svg' :'../../../assets/images/iconmonstr-video-camera-2.svg'"
              alt="Image">
            <span class="ml-1" [ngClass]="{'activeClass': (videoOptionSelected)}">{{'Record / upload video' |
              translate}}</span>
          </div>
          <div class="icondiv sm-pdr0" (click)="selectAttachOption(3)" data-toggle="modal"
            data-target="#audioInputModal" #mediaAudioBtn>
            <img
              [src]="voiceOptionSelected ? '../../../assets/images/audio-active.svg' :'../../../assets/images/iconmonstr-microphone-2.svg'"
              alt="Image">
            <span class="ml-1" [ngClass]="{'activeClass': (voiceOptionSelected)}">{{'Voice recording' |
              translate}}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showScreen == 0" class="col-md-12 pd0 p-3">
      <button class="nextButton" (click)="nextPage()">{{'Next' | translate}}</button>
    </div>
    <div class="card no_border p-3">
      <span class="mandatory-field">
        *{{'Mandatory Fields'| translate}}
      </span>
    </div>
    <!-- <div class="card no_border p-3" style="cursor: pointer;" joyrideStep="step_12" [stepContent]="customContent"
      [stepContentParams]="{'content': 'walkthrough_help_request_created'}">
      <div *ngIf="showScreen == 1 && !helpRequestId" class="col-md-12 pd0">
        <span class="manfield">
          *{{'Mandatory Fields'| translate}}
        </span>
        <button class="nextButton float-right" type="button" (click)="onSubmit()">{{'Post
          Request' | translate}}
          <img class="ml-2" src="../../../../assets/images/white_post_arrow.svg" alt="post-icon" />
        </button>
      </div>
    </div> -->
    <div *ngIf="showScreen == 1 && helpRequestId" class="col-md-12 pd0">
      <button class="nextButton" type="button" (click)="onEdit()">
        <span *ngIf="helpRequestId">
          {{'save_changes' | translate}}
        </span>
        <span *ngIf="!helpRequestId">
          {{'Post Request' | translate}}
        </span>
        <img class="ml-2" src="../../../../assets/images/white_post_arrow.svg" alt="post-icon" />
      </button>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModal" *ngIf="videoOptionSelected && !videoOption && !videoSelected" tabindex="-1"
  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content jaano-modal">
      <div class="modal-header text-center">
        <span class="modal-title-1 heading text-center" id="exampleModalLabel">
          {{'upload_video' | translate}}
        </span>
        <!-- <h5 class="modal-title heading d-inline-block" id="exampleModalLabel">{{'upload_a_video' | translate}}</h5>
        <button type="button" #closeVideobutton class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>

      <div class="modal-body">
        <div class="row justify-content-center" style="margin-bottom:10px;">
          <div class="col-12">
            <div class="modal-box justify-content-center" data-dismiss="modal" 
            (click)="videoUploadOption(2)" data-toggle="modal"
              data-target="#videoRecordModal">
              <img src="../../../../assets/images/new_help_icons/capture-video-icon.svg" 
              alt="Take video" width="16px" height="16px"
              class="mr-2 choose-picture-active">
              <img src="../../../../assets/images/new_help_icons/capture-video-icon.svg" 
              alt="Take video" width="16px" height="16px" class="mr-2 choose-picture">
              <span class="upload-image-text">{{'capture_video' | translate}}</span>
              <!-- <div>{{'Record Video' | translate}}</div>
              <div class="camera-wrapper"><img src="../../../../assets/images/home-images/record-inactive.svg"
                  alt="Take Picture" class="choose-picture">
                <img src="../../../../assets/images/home-images/record-active.svg" alt="Take Picture"
                  class="choose-picture-active">
              </div> -->
            </div>
          </div>
        </div>
        <div class="row justify-content-center" (click)="videoUploadOption(1)">
          <div class="col-12">
            <label for="file-input-video" class="modal-box file-upload-media
            justify-content-center">
              <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
              class="mr-2 choose-picture-active" width="20px" height="18px">
              <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
              class="mr-2 choose-picture" width="20px" height="18px">
              <span class="upload-image-text">
                {{'choose_video' | translate}}
              </span>
            </label>
          </div>
          <input id="file-input-video" (change)="handleVideoSelection($event)" type="file"
            accept="{{selectedFileType}}" />
        </div>
        
      </div>

      <div class="modal-footer">
        <button class="float-right modal-close-btn"
        #closeVideobutton data-dismiss="modal" aria-label="Close">
          {{'cancel'|translate}}
        </button>
      </div>
    </div>
  </div>
</div>
<button id="exampleModalButton" [hidden]="true" data-dismiss="modal" data-target="#exampleModal"></button>
<div class="modal fade" id="videoRecordModal" *ngIf="openRecordVideo == true" tabindex="-1" role="dialog"
  aria-labelledby="videoRecordModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div>
          <div class="col-12 col-sm-12 col-xs-12" style="text-align: right;">
            <i class="fa fa-close cross_icon" data-dismiss="modal" data-target="#videoRecordModal"
              (click)="remove_video()"></i>
          </div>
          <div class="col-12 col-xs-12 col-sm-12">
            <video #video1 class="video mb-3 mr-2 mt-2"></video>
          </div>
          <div class="col-12 mb-3 sm-pd0">
            <div class="text-center">
              <button *ngIf="!isRecordingVideo && !showVideo" md-raised-button color="primary"
                (click)="startVideoRecording()" class="file-upload">
                <img src="../../../../assets/images/start_recording_video.svg" alt="Image" />
              </button>
              <button *ngIf="isRecordingVideo && !showVideo" md-raised-button color="primary" data-dismiss="modal"
                data-target="#videoRecordModal" (click)="stopVideoRecording()" class="file-upload">
                <img src="../../../../assets/images/stop_recording_video.svg" alt="Image" />
              </button>
              <button *ngIf="showVideo" md-raised-button color="primary" data-dismiss="modal"
                data-target="#videoRecordModal" (click)="remove_video()" class="file-upload">
                <img src="../../../../assets/images/remove_video.svg" alt="Image" />
              </button>
              <button *ngIf="showVideo" md-raised-button color="primary" data-dismiss="modal"
                data-target="#videoRecordModal" class="file-upload">
                <img src="../../../../assets/images/accept_video.svg" alt="Image" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button id="videoRecordModalButton" [hidden]="true" data-dismiss="modal" data-target="#videoRecordModal"></button>
<div class="modal fade" id="imageCaptureModal" *ngIf="imageClickOption" tabindex="-1" role="dialog"
  aria-labelledby="imageCaptureModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <webcam class="webcamClass" style="display: block; width: 100%;" [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)" *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch"
            [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" [height]="280" [width]="280"
            [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
          </webcam>
          <button class="file-upload actionBtn">
          </button>
          <button (click)="triggerSnapshot();" data-dismiss="modal" data-target="#imageCaptureModal"
            class="file-upload actionBtn">
            <img src="../../../../assets/images/capture_photo.svg" alt="Image" />
          </button>
          <button (click)="toggleWebcam();" data-dismiss="modal" data-target="#imageCaptureModal"
            class="file-upload actionBtn">
            <img src="../../../../assets/images/camera_off.svg" alt="Image" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<button id="imageCaptureModalButton" [hidden]="true" data-dismiss="modal" data-target="#imageCaptureModal"></button>
<div class="modal fade" id="imageModal" *ngIf="imageOptionSelected" tabindex="-1" role="dialog"
  aria-labelledby="imageModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content jaano-modal">
      <div class="modal-header text-center">

        <span class="modal-title-1 heading text-center" id="exampleModalLabel">
          {{'upload_image' | translate}}
        </span>
        <!-- <button type="button" class="close" #closeImagebutton data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>

      <div class="modal-body">
        <div class="row justify-content-center" style="margin-bottom:10px;">
          <div class="col-12">
            <div class="modal-box justify-content-center" data-dismiss="modal" data-target="#imageModal" (click)="imageUploadOption(1)"
              data-toggle="modal" data-target="#imageCaptureModal">
              <img src="../../../../assets/images/new_help_icons/capcture-img-icon.svg" 
              alt="Take Picture" width="16px" height="16px"
              class="mr-2 choose-picture-active">
              <img src="../../../../assets/images/new_help_icons/capcture-img-icon.svg" 
              alt="Take Picture" width="16px" height="16px" class="mr-2 choose-picture">
              <span class="upload-image-text">{{'capture_photo' | translate}}</span>
              <!-- <div class="camera-wrapper">
                <img src="../../../../assets/images/home-images/camera-inactive.svg"
                  alt="Take Picture" class="choose-picture">
                <img src="../../../../assets/images/home-images/camera-active.svg" alt="Take Picture"
                  class="choose-picture-active">
              </div> -->
            </div>
          </div>
        </div>
        <div class="row justify-content-center" (click)="imageUploadOption(2)">
          <div class="col-12">
            <label for="file-input1" class="modal-box file-upload-media
            justify-content-center">
                <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
                class="mr-2 choose-picture-active" width="20px" height="18px">
                <img src="../../../../assets/images/new_help_icons/upload-icon.svg" alt="Picture"
                class="mr-2 choose-picture" width="20px" height="18px">
                <span class="upload-image-text">
                  {{'choose_photo' | translate}}
                </span>
                <!-- <img src="../../../../assets/images/home-images/picture-inactive.svg" alt="Picture"
                  class="choose-picture">
                <img src="../../../../assets/images/home-images/picture-active.svg" alt="Picture"
                  class="choose-picture-active"> -->
            </label>
          </div>
          <input id="file-input1" (change)="handleFileSelect($event)" type="file" accept='image/*' multiple />

        </div>
      </div>
      <div class="modal-footer">
        <button class="float-right modal-close-btn"
        #closeImagebutton data-dismiss="modal" aria-label="Close">
          {{'cancel'|translate}}
        </button>
      </div>
    </div>
  </div>

</div>
<button id="imageModalButton" [hidden]="true" data-dismiss="modal" data-target="#imageModal"></button>
<!-- Audio Input Dialog -->
<div class="modal fade" id="audioInputModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="text-align: center;">
      <div class="modal-body" style="padding-bottom:0px;margin: auto;">
        <div class="audio-text text-center">{{'record_audio'|translate}}</div>
        <div class="col-12" style="position:relative; top:130px;left: -6px;"
        *ngIf="!tempAudioBlob" >
          <img *ngIf="!isRecording && !tempAudioBlob" (click)="startAudioRecording()"
            src="../../../../assets/images/audioButtons/record_audio_start.svg" alt="Image">
          <img *ngIf="isRecording && !tempAudioBlob" (click)="stopAudioRecording()"
            src="../../../../assets/images/audioButtons/record_audio_stop.svg" alt="Image">
        </div>
        <!-- *ngIf="voiceOptionSelected" -->
        <audio *ngIf="!isRecording && tempAudioBlob" controls
        style="margin-top:72px;">
          <source [src]="tempAudioBlob" type="audio/webm">
        </audio>
        <!-- <circle-progress *ngIf="!tempAudioBlob" [percent]="audioRecorderProgress" [outerStrokeWidth]="10"
          [innerStrokeWidth]="10" [titleFontSize]="24" [title]="setTitleOfProgressBar()" [unitsFontSize]="24"
          [animation]="true" [subtitle] [animationDuration]="300" [startFromZero]="false" [maxPercent]="100">
        </circle-progress> -->
        <div id="progress" class="dyanmicProgress" *ngIf="!tempAudioBlob">
          <span id="progress-value"></span>
        </div>
        <div *ngIf="!tempAudioBlob" style="position: absolute; top: 100%; left: 40%;">
          {{timer}} Sec
        </div>
        <!-- <div class="col-12 text-center" *ngIf="!tempAudioBlob">
          <span class="audio-text grey-color">
            {{transformIntoSec(audioRecorderProgress)}}
          </span>
        </div> -->
      </div>

      <div class="modal-footer col-sm-12 col-xs-12" style="padding-top:0px;">

        <div class="col-12 text-center" style="margin-top:24px; margin-bottom:52px;">
          <img *ngIf="!isRecording && tempAudioBlob" style="cursor:pointer"
          (click)="tempAudioBlob= null;startAudioRecording()"
          src="../../../../assets/images/restart-recording.svg" alt="Image"  />
        </div>


        <div class="col-6">
          <button class="submit-btn" (click)="clearRecordedData()"  data-dismiss="modal"
          [disabled]="isRecording && !tempAudioBlob">
            {{'cancel'|translate|titlecase}}
          </button>
          <!-- <img *ngIf="isRecording || !tempAudioBlob"
            src="../../../../assets/images/audioButtons/record_audio_save_disable.svg" alt="Image">
          <img *ngIf="!isRecording && tempAudioBlob && !audioBlobURL" (click)="saveAudio()"
            src="../../../../assets/images/audioButtons/record_audio_save_enable.svg" alt="Image">
          <img *ngIf="!isRecording && audioBlobURL" data-dismiss="modal"
            src="../../../../assets/images/audioButtons/record_audio_save_active.svg" alt="Image"> -->
        </div>
        <div class="col-6 p-0">
          <button class="submit-btn" [disabled]="isRecording || !tempAudioBlob"
          *ngIf="isRecording || !tempAudioBlob">
            {{'submit'|translate|titlecase}}
          </button>
          <button class="submit-btn" *ngIf="!isRecording && tempAudioBlob && !audioBlobURL"
          (click)="saveAudio()">
            {{'submit'|translate|titlecase}}
          </button>
          <button style="display:none" #closeAudioModal data-dismiss="modal"></button>
          <!-- <img *ngIf="isRecording || !tempAudioBlob"
            src="../../../../assets/images/audioButtons/record_audio_delete_disable.svg" alt="Image">
          <img *ngIf="!isRecording && tempAudioBlob && !audioBlobURL" (click)="clearRecordedData()"
            src="../../../../assets/images/audioButtons/record_audio_delete_enable.svg" alt="Image">
          <img *ngIf="!isRecording && audioBlobURL" data-dismiss="modal" (click)="clearRecordedData()"
            src="../../../../assets/images/audioButtons/record_audio_delete_active.svg" alt="Image"> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <ng2-toasty [position]="'top-center'"></ng2-toasty> -->

<ng-template #customContent let-content="content" let-sub_content="sub_content">
  <div>{{content | translate}}</div>
  <hr *ngIf="sub_content" class="joyride_break">
  <div>{{sub_content | translate}}</div>
</ng-template>

<button style="display:none" #confirmationButton
data-toggle="modal" data-target="#confirmationButton" ></button>
<div class="modal fade" id="confirmationButton" tabindex="-1" role="dialog" 
aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-center" style="padding: 60px 45px;">
        <span class="modal-text-1">{{'save_changes_title'|translate}}</span>
      </div>
      <div class="modal-footer" style="display:flex">
        <button type="button" class="nextButton" data-dismiss="modal"
        (click)="gotoPrivousPage()">
          {{'No'|translate}}
        </button>
        <button type="button" class="nextButton ml-auto"
        (click)="onEdit()" data-dismiss="modal">
        {{'Yes'|translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<footer style="bottom: 0px; width: 100%;  position: fixed; z-index: 1000;"
[hidden]="newCreateRequestFlow">
  <div class="container p-20-0">
    <div class="footer-shadow">
      <div class="row m-0 h-48 justify-content-between bootom-navbar">
        <div class="col-12 text-center p-0">
          <button class="post-btn" (click)="onSubmit()">{{'Post Request' | translate}}</button>  
        </div>
      </div>
    </div>
  </div>
</footer>
  

<div class="container" 
[hidden]="!newCreateRequestFlow">
  <div class="row mt-20-16">
    <div class="col-12 content-around">
      <div class="header-111">
        <span *ngIf="popularServices && popularServices.length>0">
          {{popularServices[0].subCatName}}
        </span>
      </div>
      <div class="light-text-111" (click)="onChangeCategory()">
        {{'Change'|translate}}
      </div>
    </div>
  </div>
  <form [formGroup]="requireDetailForm">
    <div class="row mt-20-24">
      <div class="col-12">
        <div class="custom-wrapper-card" style="position:relative;"
        [ngClass]="{'error-border':requireDetailForm.controls['serviceName'].errors && (serviceName?.dirty || serviceName?.touched)}">
          <div class="card-header content-around no-border-bottom">
            <div class="header">
              {{'Request details'|translate}}*
            </div>
            <div>
              <img src="../../../../assets/images/info-icon-3.svg" alt="info-icon"
              width="16px" height="16px" style="cursor:pointer" (click)="onRequestDetailInfo()" >
            </div>
          </div>
          <div class="referral-discount" *ngIf="referralCode">
            {{'referralDiscout'|translate}}
          </div>
          <div class="referral-discount" *ngIf="!referralCode"
          style="position:relative; overflow: hidden;">
            <div class="slider flex-box-between">
              <div class="content-center">
                <img src="../../../../assets/images/eclipse-icon.svg" alt="eclipse-icon" 
                width="6px" height="6px" style="margin-right:4px;">
                {{'Govt. certified helpers'|translate}}
              </div>
              <div class="content-center">
                <img src="../../../../assets/images/eclipse-icon.svg" alt="eclipse-icon" 
                width="6px" height="6px" style="margin-right:4px;">
                {{'Response under 5 mins'|translate}}
              </div>
              <div class="content-center">
                <img src="../../../../assets/images/eclipse-icon.svg" alt="eclipse-icon" 
                width="6px" height="6px" style="margin-right:4px;">
                {{'Solutions in 24 Hrs'|translate}}
              </div>
              <div class="content-center sm-show">
                <img src="../../../../assets/images/eclipse-icon.svg" alt="eclipse-icon" 
                width="6px" height="6px" style="margin-right:4px;">
                {{'Govt. certified helpers'|translate}}
              </div>
              <div class="content-center sm-show">
                <img src="../../../../assets/images/eclipse-icon.svg" alt="eclipse-icon" 
                width="6px" height="6px" style="margin-right:4px;">
                {{'Response under 5 mins'|translate}}
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- <ng-container *ngIf="referralCode">
              <div class="tile" *ngIf="popularServices && popularServices.length>0 
                && !popularServices[0].fromOther"   style="padding:0px;"
                [ngClass]="{'error-border':requireDetailForm.controls['serviceName'].errors && (serviceName?.dirty || serviceName?.touched)}">
                <div class="label">
                  {{'service'|translate}}
                </div>
                <div class="error-label" *ngIf="requireDetailForm.controls['serviceName'].errors && (serviceName?.dirty || serviceName?.touched)">
                  {{'Service is required*'|translate}}
                </div>
                <div class="dropdown">
                  <button class="sub-cat-btn"
                  type="button" id="subCatId" data-toggle="dropdown" 
                  aria-haspopup="true" aria-expanded="false"
                  style="width: 100%; border: none; background: transparent; text-align: left;height: 100%; padding: 8px;height:40px;">
                  <ng-container *ngIf="selectedPopularService.listingId == -999">
                    Other
                  </ng-container>
                  <ng-container *ngIf="selectedPopularService.listingId != -999">
                    {{selectedPopularService.helpTitle || 'Select service'}}
                  </ng-container>
                  <img src="../../../../assets/images/arrow-right-icon.svg" alt="arrow-icon" width="16px" height="16px"
                  style="float: right; rotate: 90deg;" >
                  </button>
                  <div class="dropdown-menu" aria-labelledby="subCatId"
                  style="overflow-y: auto;
                  max-height: 250px;width: 100%;top: 4px;">
                    <div class="dropdown-item" *ngFor="let item of popularServices"
                    (click)="onSelectPopularService(item)">
                      {{item.helpTitle}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container> -->
            <ng-container *ngIf="popularServices && popularServices.length>0 
              && !popularServices[0].fromOther">
              <div class="tile mb-20-16"    style="padding:8px;display: flex;cursor: pointer; justify-content: start;  align-items: center;" 
                *ngFor="let item of popularServices;let i = index;"
                (click)="onSelectPopularService(item)" [ngClass]="{'mb-20-16':i != popularServices.length-1 || 
                (requireDetailForm.controls['serviceName'].errors && (serviceName?.dirty || serviceName?.touched))}">
                <img src="../../../../assets/images/radio-button-off.svg" alt="radio-button-off" width="24px" height="24px" style="margin-right:8px;"
                *ngIf="selectedPopularService.helpTitle == item.helpTitle">
                <img src="../../../../assets/images/radio-button-on.svg" alt="radio-button-on" width="24px" height="24px" style="margin-right:8px;"
                *ngIf="selectedPopularService.helpTitle != item.helpTitle">
                 {{item.helpTitle}} 
              </div>
              <div class="error-label" *ngIf="requireDetailForm.controls['serviceName'].errors && (serviceName?.dirty || serviceName?.touched)"
              style="bottom: 16px; top: inherit; left: 16px;">
                {{'Service is required*'|translate}}
              </div>
            </ng-container>
            <div class="tile" *ngIf="!popularServices || 
            (popularServices && popularServices.length>0 
              && popularServices[0].fromOther) || selectedPopularService.helpTitle == 'Other'
              || selectedPopularService.helpTitle == 'other' || selectedPopularService.listingId == -999"
              [ngClass]="{'mt-24': selectedPopularService.helpTitle == 'Other'
              || selectedPopularService.helpTitle == 'other' || selectedPopularService.listingId == -999,
              'error-border':requireDetailForm.controls['describe'].errors && (describe?.dirty || describe?.touched)}"
              style="height:initial;">
              <div class="label">
                {{'describe'|translate}}
              </div>
              <div class="error-label" *ngIf="requireDetailForm.controls['describe'].errors && (describe?.dirty || describe?.touched)" 
              style="top:102px !important">
                {{'Describe is required*'|translate}}
              </div>
              <textarea name="describe" id="describe" cols="30" rows="10" formControlName="describe"
              placeholder="{{'describePlaceholder'|translate}}" (input)="onDescribe($event)"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- <form [formGroup]="userDetailForm" *ngIf="referralCode">
    <div class="row mt-20-24">
      <div class="col-12">
        <div class="custom-wrapper-card">
          <div class="card-header content-around">
            <div class="header">
              {{'Your details'|translate}}*
            </div>
            <div>
              <img src="../../../../assets/images/info-icon-3.svg" alt="info-icon"
              width="16px" height="16px" style="cursor:pointer" (click)="onYourDetailInfo()" >
            </div>
          </div>
          <div class="card-body">
            <div class="tile" [ngClass]="{'error-border':userDetailForm.controls['userName'].errors && (userName?.dirty || userName?.touched)}">
              <div class="label">
                {{'name'|translate}}
              </div>
              <div class="error-label" *ngIf="userDetailForm.controls['userName'].errors && (userName?.dirty || userName?.touched) && 
              userDetailForm.controls['userName'].errors['required']">
                {{'Name is required*'|translate}}
              </div>
              <div class="error-label" *ngIf="userDetailForm.controls['userName'].errors && (userName?.dirty || userName?.touched) && 
              !userDetailForm.controls['userName'].errors['required']">
                {{'invalidName'|translate}}*
              </div>
              <input type="text" placeholder="{{'Enter your name here'|translate}}" formControlName="userName" style="width: 100%;" >
            </div>
            <div class="tile mt-24" [ngClass]="{'error-border':userDetailForm.controls['mobileNo'].errors && (mobileNo?.touched)}"
            *ngIf="!username">
              <div class="label" >
                {{'mobileNo'|translate}}
              </div>
              <div class="error-label" *ngIf="userDetailForm.controls['mobileNo'].errors && (mobileNo?.touched) && 
              userDetailForm.controls['mobileNo'].errors['required']">
                {{'Mobile no. is required*'|translate}}
              </div>
              <div class="error-label" *ngIf="userDetailForm.controls['mobileNo'].errors && (mobileNo?.touched) && 
              !userDetailForm.controls['mobileNo'].errors['required']">
                {{'invalidMobileNo'|translate}}*
              </div>
              <input type="number" placeholder="{{'mobileNoPlaceholder'|translate}}"  formControlName="mobileNo" style="width: 100%;" 
              (keyup)="onChangeMobileNo()">
            </div>
            <div class="tile mt-24"  [ngClass]="{'error-border':userDetailForm.controls['location'].errors && (location?.dirty || location?.touched)}"
            *ngIf="!username" style="padding:0px;">
              <div class="label">
                {{'location'|translate}}
              </div>
              <div class="error-label" *ngIf="userDetailForm.controls['location'].errors && (location?.dirty || location?.touched)">
                {{'Location is required*'|translate}}
              </div>
              <input type="text" placeholder="{{'chooseDistrict'|translate}}"  (click)="onChangeLocaton()" readonly
              style="width: 100%; border: none; background: transparent; text-align: left;height: 100%; padding: 8px;height:40px;
              cursor: pointer;"
               formControlName="location">
              <img src="../../../../assets/images/location-icon-6.svg" alt="location-icon" width="16px" height="16px" *ngIf="!dtname" 
              style="position: absolute; right: 8px; top: 11px;cursor:pointer"  (click)="onChangeLocaton()">
              <img src="../../../../assets/images/sync.svg" alt="refresh-icon" width="16px" height="16px"  *ngIf="dtname" style="float: right;"
              style="position: absolute; right: 8px; top: 11px;cursor:pointer"  (click)="onChangeLocaton()" >
            </div>
          </div>
        </div>
      </div>
    </div>
  </form> -->
  <div class="row mt-20-24">
    <div class="col-12">
      <div class="add-attachment content-around" (click)="onAddAttachment()">
        <div class="text-11">
          <img src="../../../../assets/images/pin-icon.svg" alt="attach-icon" width="18px" height="18px" >
          {{'Add attachments'|translate}}
        </div>
        <div class="text-22">
          {{'Optional'|translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-20-24" style="padding-bottom: 80px;">
    <ng-container *ngIf="selectedImageThumbnail.length > 0">
      <div class="col-auto pr-16"*ngFor="let image of selectedImageThumbnail; let idx= index">
        <div class="media-wrapper-box">
          <img style="object-fit:cover;" src="{{image}}" alt="Image" class="img">
          <img src="../../../../assets/images/cross-icon-4.svg" alt="cross-icon" width="24px" height="24px" (click)="remove_picture(idx)" class="cross-btn" >
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showVideo">
      <div class="col-auto pr-16">
        <div class="media-wrapper-box">
          <img style="object-fit:cover;" src="../../../../assets/images/play-btn-4.svg" alt="Image" class="img">
          <img src="../../../../assets/images/cross-icon-4.svg" alt="cross-icon" width="24px" height="24px" (click)="removeVideoSelect()" class="cross-btn" >
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="voiceOptionSelected">
      <div class="col-12 pr-16 mt-20-24">
        <audio *ngIf="!isRecording && audioBlobURL" controls>
          <source [src]="audioBlobURL" type="audio/webm">
        </audio>
      </div>
    </ng-container>
  </div>
  <button data-toggle="modal" data-target="#imageModal"
  #mediaImageBtn style="display:none;">image btn</button>
  <button data-toggle="modal" data-target="#exampleModal"
  #mediaVideoBtn style="display:none;">image btn</button>
</div>



<footer style="bottom: 0px; width: 100%;  position: fixed; z-index: 1000;"
[hidden]="!newCreateRequestFlow">
  <div class="container p-20-0">
    <div class="footer-shadow" style="background:none;padding: 20px 0px;">
      <div class="row m-0 h-48 justify-content-between new-bootom-navbar">
        <div class="col-12 text-center sm-p-0">
          <button class="new-post-btn" (click)="onCreateRequest()">{{'Create Request' | translate}}</button>  
        </div>
      </div>
    </div>
  </div>
</footer>