<div [hidden]="fromAds || logoSpinner" style="position:relative;display:none;">
  <button style="position:absolute;z-index:9999;border:none;background:transparent;left:8px;top:12px;" (click)="onBack()" 
  *ngIf="viewType != 'popularDistrict'">
    <img src="../../../assets/images/grey-back-btn.svg" width="24px" height="24px">
  </button>
  
  <div class="card location-card">
    <div class="card-header text-center" style="position:relative">
        <span>{{'Please Select Your District'| translate}}</span>
        <div class="close-btn" *ngIf="locationAvailable">
            <img (click)="close()" src="../../../assets/images/cross-close-icon-2.svg" alt="close-icon" width="24px" height="24px" >
        </div>
    </div>
    <div class="card-body p-0" id="bgImgCard2">
        <div class="container" style="height:352px;overflow-y: auto;">
          <!-- search option.... -->
          <div class="row mt-16" style="position: sticky; top: 10px; z-index: 9999;">
            <div class="col-12" style="position:relative;">
              <input type="text" (input)="onFilterDistricts($event)" placeholder="{{'search_for_your_district_here'|translate}}" [value]="searchValue">
              <!-- <div class="input-search" *ngIf="!searchValue || searchValue.length == 0" >
                <img src="../../../assets/images/search.svg" width="24" height="24" alt="search-icon" style="opacity:0.3" />
                <span>{{'search_for_your_district_here'|translate}}</span>
              </div> -->
            </div>
          </div>
          <ng-container *ngIf="viewType == 'popularDistrict'">
            <!-- popular manual districts... -->
            <div class="row mt-16">
              <div class="col-12">
                <span>{{'Popular districts'|translate}}</span>
              </div>
            </div>
            <div class="row" style="padding: 16px;">
                <div class="col-auto p-0 mb-16" *ngFor="let location of popularDistricts" style="margin:auto;" >
                    <button class="block" (click)="onSelectDistrict(location.id)">
                        <div class="location-key">
                            {{location.key}}
                        </div>
                        <div class="location mt-8">
                            {{location.district}}
                        </div>
                    </button>
                </div>
                <div class="col-auto p-0 mb-16" style="margin:auto;">
                  <button class="block" (click)="onViewMore()">
                    <div class="view-more">{{'view_more'|translate}}</div>
                  </button>
                </div>
            </div>
          </ng-container>
          <ng-container *ngIf="viewType == 'viewMore'">
            <div class="row" style="padding-bottom: 15px;">
              <div class="col-12">
                <ng-container *ngFor="let x of filteredStates | async">
                  <div  (click)="selectedState({'value': x.stcode11})" class="location-row" data-toggle="collapse"
                  [ngStyle]="{'border-radius':selectedStateID && selectedStateID == x.stcode11?'4px 4px 0px 0px':'4px'}">
                    {{x['state_' + selectedLanguage] || x.stname}}
                    <img src="../../../assets/images/arrow-right-icon.svg" alt="right-arrow" width="24px" height="24px"
                    [ngStyle]="{'transform':selectedStateID && selectedStateID == x.stcode11?'rotate(90deg)':'initial'}">
                  </div>
                  <ng-container *ngIf="selectedStateID && selectedStateID == x.stcode11" >
                    <div id="collapseOne" class="collapse collapse-box" aria-labelledby="headingOne" 
                    [ngClass]="{'show':selectedStateID && selectedStateID == x.stcode11}">
                      <ng-container *ngFor="let y of filteredDistricts | async" >
                        <div (click)="selectedDistrict({'value' : y.dtcode11})" class="sub-locations">
                          {{y['district_' + selectedLanguage] || y.dtname}}
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="viewType == 'search'">
            <div class="row" style="padding-bottom: 15px;">
              <ng-container *ngFor="let location of filterDistricts">
                <div class="col-12">
                  <button class="location-row" (click)="onSelectFilteredDistrict(location)">
                    <span>{{location.districtName}} , {{location.stname}}</span>
                  </button>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
    </div>
  </div>
  <!-- <div class="text-center">
    <img height="100" width="100" src="../../../assets/images/new_add_location.svg">
  </div>
  
  <div class="text-center" style="margin-top:16px;">
    <span class="gps-text" (click)="entermanualLocation = false;getLocationData();">
      {{'find your location with GPS'| translate}}
      <img src="../../../assets/images/new_gps_icon2.svg"
      alt="gps-icon" class="gps-icon ml-2" />
    </span>
  </div>
  <div class="text-center"  style="margin-top:8px">
    <span class="or-text">
      {{'OR'|translate}}
    </span>
  </div>
  
  <div [style.display]="!entermanualLocation ? 'none' : 'unset'">
  
    <aw-wizard class="custom-line-css">
      <aw-wizard-step stepTitle=""  *ngFor="let wizard of wizardSteps; let first = first; let last = last">
  
        <div *ngIf="wizard.content == 'state'">
        <div class="select_heading">{{'Please Select Your State'| translate}}</div>
        <div class="sender-detail-search">
          <span><img src="../../../../assets/images/feed-search-icon.svg" alt="Search"></span>
         <div class="search-area" [ngClass]="{'remove_border': (!showFilter), 'w-100': showFilter}">
          <form class="example-form">
            <mat-form-field class="example-full-width" appearance="fill">
              <input type="text" matInput [formControl]="myControlStates" [matAutocomplete]="auto" placeholder="{{'search'| translate}}">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedState({'value': $event.option.value.stcode11})"> 
              </mat-autocomplete>
            </mat-form-field>
          </form>
         </div>
        </div>
        
        <div class="col-12 locationselector">
          <div class="stname" *ngFor="let x of filteredStates | async" awNextStep (click)="selectedState({'value': x.stcode11})">
            {{x['state_' + selectedLanguage] || x.stname}}
          </div>
        </div>
  
        </div>
  
        <div *ngIf="wizard.content == 'district'">
          <div class="text-left back_arrow">
            <img awPreviousStep src="../../../assets/images/backarrow_grey.svg" alt="image">
          </div>
    
          <div class="fs-16 text-center">{{selectedStateName}}</div>
          <div class="select_heading">{{'Please Select Your District'| translate}}</div>
          <div class="sender-detail-search">
            <span><img src="../../../../assets/images/feed-search-icon.svg" alt="Search"></span>
           <div class="search-area" [ngClass]="{'remove_border': (!showFilter), 'w-100': showFilter}">
            <form class="example-form">
              <mat-form-field class="example-full-width" appearance="fill">
                <input type="text" matInput [formControl]="myControlDistricts" placeholder="{{'search'| translate}}">
              </mat-form-field>
            </form>
           </div>
          </div>
          
          <div class="col-12 locationselector">
            <div class="stname" *ngFor="let x of filteredDistricts | async" awNextStep (click)="selectedDistrict({'value' : x.dtcode11})">
              {{x['district_' + selectedLanguage] || x.dtname}}
            </div>
          </div>
        </div>
  
        <div *ngIf="wizard.content == 'cluster'">
          <div class="text-left back_arrow">
            <img awPreviousStep src="../../../assets/images/backarrow_grey.svg" alt="image">
          </div>
          <div class="fs-16 text-center">{{SelectedDistrictName}}, {{selectedStateName}}</div>
          <div class="select_heading">{{'Please Select Your Cluster'| translate}}</div>
          <div class="sender-detail-search">
            <span><img src="../../../../assets/images/feed-search-icon.svg" alt="Search"></span>
           <div class="search-area" [ngClass]="{'remove_border': (!showFilter), 'w-100': showFilter}">
            <form class="example-form">
              <mat-form-field class="example-full-width" appearance="fill">
                <input type="text" matInput [formControl]="myControlClusters" placeholder="{{'search'| translate}}">
              </mat-form-field>
            </form>
           </div>
          </div>
  
          <div class="col-12 locationselector">
            <div class="stname" *ngFor="let x of filteredClusters| async" awNextStep
              (click)="selectCluster({'value' : x.clustercode})">
              {{(x['cluster' + '_' + selectedLanguage]) || x.clustername || 'NA'}}
            </div>
          </div>
        </div>
  
        <div  *ngIf="wizard.content == 'mandal'">
          <div class="text-left back_arrow">
            <img awPreviousStep src="../../../assets/images/backarrow_grey.svg" alt="image">
          </div>
          <div class="fs-16 text-center">{{selectedClusterDetails.clusterName}}, {{SelectedDistrictName}}, {{selectedStateName}}</div>
          <div class="select_heading">{{'Please Select Your Mandal'| translate}}</div>
  
          <div class="sender-detail-search">
            <span><img src="../../../../assets/images/feed-search-icon.svg" alt="Search"></span>
           <div class="search-area" [ngClass]="{'remove_border': (!showFilter), 'w-100': showFilter}">
            <form class="example-form">
              <mat-form-field class="example-full-width" appearance="fill">
                <input type="text" matInput [formControl]="myControlMandals" placeholder="{{'search'| translate}}">
              </mat-form-field>
            </form>
           </div>
          </div>
  
          <div class="col-12 locationselector">
            <div class="stname" *ngFor="let x of filteredMandals | async" awNextStep (click)="selectMandal({'value' : x.mandalcode})">
              {{ (x['mandal' +  '_' + selectedLanguage])  || x.mandalname || 'NA'}}
            </div>
          </div>
        </div>
  
      </aw-wizard-step>
    </aw-wizard>
  
  </div -->
  
  
  <!-- <div class="text-center mt-5 error_container" *ngIf="!entermanualLocation && err.length == 0">
    <div class="select_heading">{{'Finding your Location using GPS'| translate}}</div>
    <div class="almost_there">{{'Almost there'| translate}} ......</div>
    <mat-slider min="1" max="9000" [value]="timer_gps"></mat-slider>
    <div class="mt-3 text-center enter_manually_text fs-12" (click)="entermanualLocation = true">{{'Alternately'| translate}},
      <b>{{'enter your location manually'| translate}}</b>
      <img src="../../../assets/images/new_manual_loc_keypad_icon.svg" alt="new-manual-loc-keypad">
    </div>
  
  </div>
  
  <div *ngIf="!entermanualLocation && err.length > 0" class="mt-5 error_container">
    <div class="select_heading color_red">{{'Failed to detect your location using GPS'| translate}} !!</div>
    <div class="mt-5 text-center fs-20" (click)="entermanualLocation = true">{{'Please select location manually'| translate}}</div>
    <div class="mt-5 text-center fs-20"> <img src="../../../assets/images/redirecting_icon.svg" class="mr-2" />{{'Redirecting'| translate}}</div>
  </div> -->
</div>
<!-- <div *ngIf="logoSpinner" [ngClass]="{'content-center-1':fromAds, 'content-center-2':!fromAds}">
  <img src="../../../assets/images/Rotating jaano logo_C.gif" alt="jaano-logo" 
  width="160px" height="160px">
</div> -->
<div *ngIf="logoSpinner" [ngClass]="{'content-center-1':fromAds, 'content-center-2':!fromAds}" style="display:none;">
  <img src="../../../assets/images/jaano-rotation-1.svg" alt="jaano-rotation" width="160px" height="160px" class="rotating">
  <img src="../../../assets/images/jaano-rotation-2.svg" alt="jaano-rotation-2" class="jaano-rotation-title" >
</div>
<div [hidden]="!fromAds || logoSpinner" style="display:none;">
  <div class="container-fluid" style="height: calc(100vh - 48px); position: relative;">
    <div class="row">
      <div class="col-12 text-center">
        <img src="../../../assets/images/jaanoLogo.svg" alt="jaano-logo" class="jaano-logo-1">
      </div>
    </div>
    <div class="row mt-24">
      <div class="col-12 text-center">
        <span class="text text-1 w-700 color-1">
          {{'please_choose_location'|translate}}
        </span>
      </div>
    </div>
    <div class="row justify-content-center mt-24">
      <div class="col-12 text-center mb-6">
        <img src="../../../assets/images/grey-gps-icon.svg" alt="gps-icon" width="24px"
        height="24px" class="cursor-pointer">
      </div>
      <div class="col-12">
        <aw-wizard class="custom-line-css-1">
          <aw-wizard-step stepTitle=""  *ngFor="let wizard of wizardSteps; let first = first; let last = last">
      
            <div *ngIf="wizard.content == 'state'">
            <div class="text text-2 w-600 color-2 text-center">{{'Please Select Your State'| translate}}</div>
            <div class="sender-detail-search-1">
              <span><img src="../../../../assets/images/search_icon.svg" alt="Search"></span>
             <div class="search-area-1" [ngClass]="{'remove_border': (!showFilter), 'w-100': showFilter}">
              <form class="example-form">
                <input type="text" [formControl]="myControlStates"  
                placeholder="{{'search_for_your_state_here'| translate}}">
                <!-- <mat-form-field class="example-full-width" appearance="fill">
                  <input type="text" matInput [formControl]="myControlStates" [matAutocomplete]="auto" placeholder="{{'search'| translate}}">
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedState({'value': $event.option.value.stcode11})"> 
                  </mat-autocomplete>
                </mat-form-field> -->
              </form>
             </div>
            </div>
            
            <div class="col-12 locationselector-1">
              <div class="stname-1" *ngFor="let x of filteredStates | async" awNextStep (click)="selectedState({'value': x.stcode11})">
                {{x['state_' + selectedLanguage] || x.stname}}
              </div>
            </div>
      
            </div>
      
            <div *ngIf="wizard.content == 'district'">
              <!-- <div class="text-left back_arrow">
                <img awPreviousStep src="../../../assets/images/backarrow_grey.svg">
              </div> -->
        
              <div class="text text-2 w-600 color-3 text-center">
                {{selectedStateName}}
                <img src="../../../assets/images/blue-cross-icon.svg" alt="cross-close" width="24px" height="24px"
                class="cursor-pointer" style="margin-left:4px;" awPreviousStep>
              </div>
              <div class="text text-2 w-600 color-2 text-center mt--4">{{'Please Select Your District'| translate}}</div>
              <div class="sender-detail-search-1">
                <span><img src="../../../../assets/images/search_icon.svg" alt="Search"></span>
               <div class="search-area-1" [ngClass]="{'remove_border': (!showFilter), 'w-100': showFilter}">
                <form class="example-form">
                  <input type="text" [formControl]="myControlDistricts" 
                  placeholder="{{'search_for_your_district_here'| translate}}">
                  <!-- <mat-form-field class="example-full-width" appearance="fill">
                    <input type="text" matInput [formControl]="myControlDistricts" placeholder="{{'search'| translate}}">
                  </mat-form-field> -->
                </form>
               </div>
              </div>
              
              <div class="col-12 locationselector-1">
                <div class="stname-1" *ngFor="let x of filteredDistricts | async" awNextStep (click)="selectedDistrict2({'value' : x.dtcode11})">
                  {{x['district_' + selectedLanguage] || x.dtname}}
                </div>
              </div>
            </div>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
    <div class="row justify-content-center footer-card">
      <div class="col-auto cursor-pointer" (click)="entermanualLocation = false;getLocationData();">
        <img src="../../../assets/images/blue-gps-icon.svg" alt="gps-icon" width="16px" height="16px"
        style="margin-right:4px;">
        <span class="text text-3 w-500 color-3">{{'location_via_gps'|translate}}</span> 
      </div>
    </div>
  </div>
</div>

<!-- <div class="maincontainer"  [hidden]="fromAds">
  <div [style.display]="!entermanualLocation ? 'none' : 'unset'">
    <aw-wizard class="custom-line-css-1">
      <aw-wizard-step stepTitle=""  *ngFor="let wizard of wizardSteps; let first = first; let last = last">
        <div class="container-fluid">
          <div class="row select-lang-top">
              <div class="col-12 text-center">
                  <img src="../../../assets/images/jaanoLogo.svg" alt="jaanoLogo" width="120px"
                  height="120px;">
              </div>
              <div class="col-12 mt-16 text-center">
                <div *ngIf="wizard.content == 'state'">
                  <span class="text-1 sh1 w-500">
                      {{'Please Select Your State'|translate}}
                  </span>
                </div>
                <div *ngIf="wizard.content == 'district'">
                  <span class="text-1 sh1 w-500">
                    {{'Please Select Your District'|translate}}
                  </span>
                </div>
                <div *ngIf="wizard.content == 'cluster'">
                  <span class="text-1 sh1 w-500">
                    {{'Please Select Your Cluster'|translate}}
                  </span>
                </div>
                <div *ngIf="wizard.content == 'mandal'">
                  <span class="text-1 sh1 w-500">
                    {{'Please Select Your Mandal'|translate}}
                  </span>
                </div>
              </div>
          </div>
        </div>
        <div class="container-fluid select-lang-bottom">
          <div class="row" style="position:absolute; top:8px; left:8px;z-index: 99999;"
          *ngIf="wizard.content != 'state'">
            <div class="col-auto">
              <button class="back-btn" awPreviousStep>
                <img src="../../../assets/images/back_arrow.svg" alt="left-arrow">
              </button>
            </div>
          </div>
          <div class="row" style="position:absolute; top:8px; right:8px;z-index: 99999;"
          *ngIf="wizard.content != 'state'">
            <div class="col-auto">
              <button class="back-btn text-5">
                {{'skip'|translate}}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div *ngIf="wizard.content == 'state'" class="row justify-content-around">
                <div class="col-auto">
                  <div class="title-box mt-16" *ngFor="let x of filteredStates | async" awNextStep (click)="selectedState({'value': x.stcode11})">
                    {{x['state_' + selectedLanguage] || x.stname}}
                  </div>
                </div>
              </div>
              <div *ngIf="wizard.content == 'district'"  class="row justify-content-around">
                
                <div class="col-auto">
                  <div class="title-box mt-16" *ngFor="let x of filteredDistricts | async" awNextStep (click)="selectedDistrict({'value' : x.dtcode11})">
                    {{x['district_' + selectedLanguage] || x.dtname}}
                  </div>
                </div>
              </div>
              <div *ngIf="wizard.content == 'cluster'"  class="row justify-content-around">
                <div class="col-auto">
                  <div class="title-box mt-16" *ngFor="let x of filteredClusters| async" awNextStep
                    (click)="selectCluster({'value' : x.clustercode})">
                    {{(x['cluster' + '_' + selectedLanguage]) || x.clustername || 'NA'}}
                  </div>
                </div>
              </div>
        
              <div  *ngIf="wizard.content == 'mandal'"  class="row justify-content-around">
                <div class="col-auto">
                  <div class="title-box mt-16" *ngFor="let x of filteredMandals | async" awNextStep (click)="selectMandal({'value' : x.mandalcode})">
                    {{ (x['mandal' +  '_' + selectedLanguage])  || x.mandalname || 'NA'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row custom-footer-card justify-md-content-around" 
          *ngIf="wizard.content == 'state'">
            <div class="col-12 col-lg-auto">
              <div class="content" (click)="entermanualLocation = false;getLocationData();">
                <span>
                  <img src="../../../assets/images/gps-icon-3.svg" alt="gps-icon"
                  width="16px" height="16px">
                </span>
                <span class="ml--4 text-4">{{'location_via_gps'|translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </aw-wizard-step>
    </aw-wizard>
  
  </div>
</div> -->

<div class="category-card">
  <div class="card-header">
      <header>{{'select_your_district'|translate}}</header>
      <div class="title">{{'select_your_district_label'|translate}}</div>
  </div>
  <div class="card-body max-height-custom">
      <div class="container" style="position:initial;">
          <div class="row" style="position: sticky; top: 1px;z-index: 999;">
              <div class="col-12 p-0">
                  <input type="text" (input)="onFilterDistricts($event)" 
                  placeholder="{{'search_for_your_district_here'|translate}}" [value]="searchValue">
                  <img src="../../../assets/images/search.svg" alt="search-icon" style=" left: 10px; top: 13px;right:initial">
              </div>
          </div>
          <div class="row mt-16-8" *ngIf="!searchValue ||
           (searchValue && searchValue.length == 0)">
              <div class="col-auto pl-0 title">
                  {{'Popular districts'|translate}}
              </div>
              <div class="col ml-auto content-center p-0">
                  <hr style="width: 100%;border-top: 1px solid rgba(50, 50, 51, 0.30);">
              </div>
          </div>
          <div class="row mt-16-8"   *ngIf="searchValue && searchValue.length>0">
              <div class="col-auto pl-0 title">
                  <span>
                      {{'Search results'|translate}}
                  </span>
              </div>
              <div class="col ml-auto content-center p-0">
                  <hr style="width: 100%;border-top: 1px solid rgba(50, 50, 51, 0.30);">
              </div>
          </div>
          <div class="row mt-16-8" *ngIf="!searchValue ||
          (searchValue && searchValue.length == 0)">
              <ng-container  *ngFor="let location of popularDistricts;let i=index">
                  <div class="col-6 pl-0 mb-12-8"
                  (click)="onSelectDistrict(location.id)">
                      <div class="tiles">
                          <div class="label-2">{{location.district}}</div>
                      </div>
                  </div>
              </ng-container>
          </div>
          <div class="row mt-8" *ngIf="!searchValue ||
          (searchValue && searchValue.length == 0)">
              <div class="col-auto pl-0 title">
                  <span >
                      {{'select_state_to_choose'|translate}}
                  </span>
              </div>
              <div class="col ml-auto content-center p-0">
                  <hr style="width: 100%;border-top: 1px solid rgba(50, 50, 51, 0.30);">
              </div>
          </div>
          <div class="row" [ngClass]="{'mt-8':!searchValue ||
          (searchValue && searchValue.length == 0)}" *ngIf="!searchValue || (searchValue && searchValue.length == 0)">
            <ng-container *ngFor="let x of filteredStates | async">
              <div  (click)="selectedState({'value': x.stcode11})" class="location-row" data-toggle="collapse"
              [ngStyle]="{'border-radius':selectedStateID && selectedStateID == x.stcode11?'4px 4px 0px 0px':'4px'}"
              style="    border-radius: 4px; border: 1px solid rgba(50, 50, 51, 0.30);position: relative;">
                {{x['state_' + selectedLanguage] || x.stname}}
                <img src="../../../assets/images/arrow-right-icon.svg" alt="right-arrow" width="24px" height="24px"
                [ngStyle]="{'transform':selectedStateID && selectedStateID == x.stcode11?'rotate(90deg)':'initial'}">
              </div>
              <ng-container *ngIf="selectedStateID && selectedStateID == x.stcode11" >
                <div id="collapseOne" class="collapse collapse-box" aria-labelledby="headingOne" 
                [ngClass]="{'show':selectedStateID && selectedStateID == x.stcode11}" style="border: 1px solid rgba(50, 50, 51, 0.30);width: 100%;">
                  <ng-container *ngFor="let y of filteredDistricts | async" >
                    <div (click)="selectedDistrict({'value' : y.dtcode11})" class="sub-locations">
                      {{y['district_' + selectedLanguage] || y.dtname}}
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div class="row" [ngClass]="{'mt-16-8':!searchValue ||
          (searchValue && searchValue.length != 0)}" *ngIf="searchValue && searchValue.length != 0">
            <ng-container  *ngFor="let location of filterDistricts;let i=index">
                <div class="col-12 tiles space-around mb-12-8"
                (click)="onSelectFilteredDistrict(location)">
                    <div class="label">{{location.districtName}} , {{location.stname}}</div>
                </div>
            </ng-container>
          </div>
      </div>
  </div>
</div>