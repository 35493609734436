import { Component, Inject, OnInit, PLATFORM_ID, } from '@angular/core';
import { JoyrideService } from 'ngx-joyride';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { Observable, Subscription, interval } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie';

import { CommonService } from '../../services/common.service';
import { LanguageTranslationService } from '../../services/language-translation-service';
import { LoginDialogService } from "../../services/loginService";
import { AuthService } from "../../services/auth.service";
import { DataService } from '../../services/data.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { UserLevelUpDialogComponent } from '../user-level-up-dialog/user-level-up-dialog.component';
import { PreviousRouteService } from '../../services/previous-route.service';
import { UserDetailService } from '../../services/user-detail.service';
import { MoengageEventsService } from '../../services/moengage_events_service';
import { LoclangDialogsService } from "../../services/loclang-dialogs.service";
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-dashboard-top-bar',
  templateUrl: './dashboard-top-bar.component.html',
  styleUrls: ['./dashboard-top-bar.component.scss']
})
export class DashboardTopBarComponent implements OnInit {
  title = 'jaano2-webApp';
  mode:any = 'feeds';
  state: any;
  primaryMarker: any;
  isUserLoggedIn: boolean = false;
  isNavBarOpen: boolean = false;
  userEnteredLoaction: any;
  selectedUserLanguage = 'select_preferred_language';
  userCurrentPlace:any;
  previousLocation:any;
  isDropdownOpen: boolean = false;
  index: { isAddUserPageActive: boolean; title: string; }|any;
  lat:any; lng:any;
  username:any;
  subscriptions: Subscription[] = [];
  notificationArr:any[] = [];
  currentUrl: string="";
  webNotificationToken: any;
  initial: any;
  profile_picture: string="";
  // jaano_points: number = 0;
  mobileScreen: boolean = false;
  queryParams:any;
  previousUrl:any;
  gifFile="https://jaano2.s3.ap-south-1.amazonaws.com/v2assets/Add_help_anim_final.gif";

  constructor(
    private router: Router,
    private commonService: CommonService,
    public dialog: MatDialog,
    private languageService: LanguageTranslationService,
    private userService:UserDetailService,
    private loginService: LoginDialogService,
    private _auth: AuthService,
    private dataService: DataService,
    public joyrideService: JoyrideService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private previousRouteService: PreviousRouteService,
    private cookieService: CookieService,
    private moeService: MoengageEventsService,
    private loclatdialog: LoclangDialogsService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    // setting language if available.
    this.languageService.getSelectedLanguage().subscribe(response=>{
      if(response){
        this.language = response;
      }
    })
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd ) {
        if(event.url.includes('/user-profile/addupdate-user') || event.url.includes('/expanded-view')|| (
          event.url.includes('getHelp-RequestbyId')) ||
          event.url.includes('/paid-help-request') || event.url.includes('/helprequest') || event.url.includes('/user-profile/refer-a-friend')){
          this.showFooterException = true;
        }
        else{
          this.showFooterException = false;
        }

      }
      this.commonService.dontShowFooter.subscribe(data=>{
        if(data){
          this.showFooterException = true;
        }
        else{
          this.showFooterException = false;
        }
      })
    });


    this.subscriptions.push(this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let urlParts = event.url ? event.url.split('/') : [];
        if (urlParts.length > 0) {
          this.detectPageChangeHandler(event.url);
        }
      }
    }));
    this.subscriptions.push(this.commonService.selectedLocation.subscribe((value) => {
      if (value && Object.keys(value).length > 0) {
        this.lat = value.lat();
        this.lng = value.lng();
        this.setCurrentLocation();
      }
    }));

    // this.subscriptions.push(this.commonService.getJaanoPoints().subscribe((value) => {
    //   let ele = document.getElementById('game_jp');
    //   if (!isNaN(value)) {
    //     this.jaano_points = value;
    //     if (ele)
    //       ele.classList.add('game_jp_hover_class');
    //     setTimeout(() => {
    //       if (ele)
    //         ele.classList.remove('game_jp_hover_class');
    //     }, 2000);
    //   }
    // }));

    let subscriptions = this.userService.userDt.subscribe(data => {
      this.username = data[0];
      this.initial = this.username.charAt(0).toUpperCase();
      this.profile_picture = data[1];
      subscriptions.unsubscribe();
    })
    this.dataService.offerHelpid.subscribe(response =>{
      this.helpid = response;
      this.getLatestHelpidAndTimestamp();
    });
    this.dataService.removeHrSubject.subscribe(response =>{
      if(response){
        this.reloadTimerOfferSubject.unsubscribe();
        this.remainingTime = 0;
      }
    })
    this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = params;
    });

    // listen for ignore offer
    this.commonService.ignoreOffer.subscribe(response=>{
      if(response){
        this.hasOffer = 0;
        this.remainingTime = 0;
      }
    });
  }

  ngOnInit(): void {
    this.reloadTimerForOffer();
    this.commonService.getWebNotificationToken().subscribe((res) => {
      this.webNotificationToken = res;
    });
    if (!this.username) {
      this.username = localStorage.getItem('username');
      if (this.username != undefined && this.username != null) {
      this.initial = this.username.charAt(0).toUpperCase();
      }
    }
    this.username = this.commonService.getUsername();
    this.commonService.subscribeToUsername().subscribe(res=>{
      this.username = res;
      if (this.username != undefined && this.username != null) {
        this.initial = this.username.charAt(0).toUpperCase();
        const profilePicture:any = localStorage.getItem('profile_picture');
        this.profile_picture = profilePicture;
      }
    });
    if(!this.profile_picture){
      const profilePicture:any = localStorage.getItem('profile_picture');
      this.profile_picture = profilePicture;
    }

    this.languageService.startLanguageTranslator();
    this._auth.getAuthStateSync((authenticated:any) => {
      if (authenticated) {
        this.isUserLoggedIn = true;
      }
    });
    this.subscriptions.push(this.loginService.userLoggedInStatus().subscribe(message => {
      if (message) {
        // this.showToast.success('Successfully logged-in', '');
      }
      this.isUserLoggedIn = message;
    }));
    this.mode = localStorage.getItem('mode');
    // this.notifications();
  }

  ngAfterViewInit() {
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      this.mobileScreen = true;
    }
    else {
      this.mobileScreen = false;
    }
  }

  //Deprecated
  notifications() {
    // this.notificationArr = JSON.parse(localStorage.getItem('notificationArr'));
    // if (this.notificationArr != [] && this.notificationArr != null) {
    //   this.notificationArr.reverse();
    // }

    this.dataService.getDataByEntity('getUserProfile/getUserNotifications', {
      "username": this.commonService.getUsername(),
      "appType": 3, // 3 for web
      "pageNo": 1,
      "pageSize": 4
    }).subscribe(res => {
      if (res.success && res.data) {
        this.notificationArr = [];
        for (let ele of res.data) {
          let newElement = ele.notificationpayload;
          newElement.sentat = ele.sentat
          this.notificationArr.push(newElement);
        }
      }
    });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  goToGamification(){
    this.mode = 'refer-a-friend';
    localStorage.setItem("mode",this.mode);
    this.router.navigate(['./user-profile/refer-a-friend']);
  }

  loadTourService() {
    this.subscriptions.push(this.languageService.getTranslationOf(['Next', 'Previous', 'Next']).subscribe(values => {
      this.joyrideService.startTour(
        {
          steps: ['step_1@home', 'step_2@home', 'step_3@home', 'step_4@home'], // steps order
          themeColor: '#14377B',
          customTexts: {
            next: values['Next'],
            prev: values['Previous'],
            done: values['Next']
          }
        }
      ).subscribe(
        step => {
          if(step.number == 4){
            this.moeService.eventEmitter('web_Walkthrough_HomePage_Completed', {platform : "web"});
          }
        },
        error => {
          // console.log(error);
        },
        () => {
          // console.log("finished tour");
          /*Tour is finished here, do something*/
        }
      );
    }));
    this.googleAnalyticsService.eventEmitter("web_user_guide_tour", "", "User Tap on How to Use Icon");
  }

  openChangeLocationDialog() {
    const currentUrl = this.router.url;
    if(currentUrl.includes('user-profile')){
      this.googleAnalyticsService.eventEmitter('loc_menu_pro',"menu profile","open location");
      (window as any).hj('event', 'loc_menu_pro');
    }
    else{
      this.googleAnalyticsService.eventEmitter('loc_menu_home',"menu home","open location");
      (window as any).hj('event', 'loc_menu_pro');
    }
    let value:any = {
      lat: this.lat,
      lng: this.lng,
      manual: true
    }
    this.loclatdialog.openChangeLocationDialog((res: any) => { },value );
  }

  openChangeLanguageDialog() {
    const currentUrl = this.router.url;
    if(currentUrl.includes('user-profile')){
      this.googleAnalyticsService.eventEmitter('lang_menu_pro',"menu profile","open language");
      (window as any).hj('event', 'lang_menu_pro');
    }
    else{
      this.googleAnalyticsService.eventEmitter('lang_menu_home',"menu home","open language");
      (window as any).hj('event', 'lang_menu_pro');
    }
    this.loclatdialog.openChangeLanguageDialog((res: any) =>{
      if (res) {
        this.subscriptions.push(this.dataService.getUserLocationDetails(Object.assign({ language: this.languageService.getLanguage() || 'en' }, { lat: this.lat, lng: this.lng })).subscribe((res: { data: { district_ln: any; dtname: any; state_ln: any; stname: any; mandalname_ln: any; mandalname: any; clustername_ln: any; clustername: any; }; }) => {
          this.userCurrentPlace = res.data.district_ln ? res.data.district_ln : res.data.dtname;
          localStorage.setItem('district_ln', this.userCurrentPlace);
          localStorage.setItem('state_ln', res.data.state_ln || res.data.stname);
          localStorage.setItem('mandalname', res.data.mandalname_ln || res.data.mandalname);
          localStorage.setItem('clustername', res.data.clustername_ln || res.data.clustername);
        }))
      }
    })
  }

  setCurrentLocation() {
    this.userCurrentPlace = localStorage.getItem('district_ln') ? localStorage.getItem('district_ln') : localStorage.getItem('dtname');
  }

  async openLoginDialog() {
    await this.locationConstraint();
    this.loginService.openModal('', (status: string) => {
      if (status) {
        // Logged in user
      } else {
        this.moeService.eventEmitter('web_Login/Signup_Left', {platform : "web"});
      }
    });
  }

  logOut() {
    const currentUrl = this.router.url;
    if(currentUrl.includes('user-profile')){
      this.googleAnalyticsService.eventEmitter('logout_menu_pro',"menu profile","logout");
      (window as any).hj('event', 'logout_menu_pro');
    }
    else{
      this.googleAnalyticsService.eventEmitter('logout_menu_home',"menu home","logout");
      (window as any).hj('event', 'logout_menu_pro');
    }
    this.loginService.logOut();
    this.isUserLoggedIn = false;
    const data1 = {
      'username' : this.username,
      'webNotificationToken' : this.webNotificationToken
    };
    this.dataService.getDataByEntity('logOutUser', data1).subscribe(result => {
      if (result && result.success) {

        this.cookieService.put('is_logged__in','false');
        this.cookieService.remove('username');
        this.cookieService.remove('mobile_number');
      }
    });
    this.currentUrl = this.previousRouteService.getCurrentUrl();
    if (this.currentUrl == '/user-profile?profile_type=pvt' || this.currentUrl == '/user-profile?profile_type=pub'
      || this.currentUrl.startsWith('/user-profile/addupdate-user')) {
      this.router.navigate(['home']);
    }
    this.googleAnalyticsService.eventEmitter("web_logged_out", "", "User logged-out of the app");
  }


  openNav() {
    if (!this.isNavBarOpen) {
      let element1= document.getElementById("myNav");
      element1?element1.style.width = "100%":0;
      // if (document.getElementById("myNav"))
      //   document.getElementById("myNav").style.width = "100%";
      this.disable();
      this.isNavBarOpen = true;
    } else {
      let element1= document.getElementById("myNav");
      element1?element1.style.width = "0%":0;
      // if (document.getElementById("myNav"))
      //   document.getElementById("myNav").style.width = "0%";
      this.enable();
      this.isNavBarOpen = false;
    }
  }

  closeNav() {
    let element1= document.getElementById("myNav");
    if(element1){
      element1.style.width = "0%";
      this.enable();
      this.isNavBarOpen = false;
    }
    // if (document.getElementById("myNav")) {
    //   document.getElementById("myNav").style.width = "0%";
    //   this.enable();
    //   this.isNavBarOpen = false;
    // }
    this.googleAnalyticsService.eventEmitter("web_top_cancel", "", "User Tap on Cancel Icon on burger menu");
  }

  openfeedsDropdown() {
    this.isDropdownOpen = true;
  }

  openMainNav() {
    let element1= document.getElementById("myNav");
    element1?element1.style.width = "100%":0;
    // document.getElementById("myNav").style.width = "100%";
  }

  navigateToFeed(event: number) {
    if (event == 0) {
      this.router.navigate(['./feeds/help']);
      this.googleAnalyticsService.eventEmitter("web_top_feed_help", "", "User Tap on Feed icon");
    }
    else if (event == 2) {
      this.router.navigate(['./feeds/official-feed']);
      this.googleAnalyticsService.eventEmitter("web_top_feed_official-feed", "", "User Tap on Official icon");
    }
  }

  modeChangeHandler(value: string) {
    this.mode = value;
    switch (value) {
      case 'feeds':
        this.router.navigate(['./feeds/help']);
        this.googleAnalyticsService.eventEmitter("web_top_feed_help", "", "User Tap on Feed icon");
        break;
      case 'official':
        this.router.navigate(['./feeds/official-feed']);
        this.googleAnalyticsService.eventEmitter("web_top_feed_help", "", "User Tap on Official icon");
        break;
      case 'information_center':
        this.router.navigate(['feeds/gethelp']);
        this.googleAnalyticsService.eventEmitter("web_top_covid-information", "", "User Tap on Covid-19 information icon");
        break;
      case 'user-profile':
        const currentUrl = this.router.url;
        if(currentUrl.includes('user-profile')){
          this.googleAnalyticsService.eventEmitter('pro_menu_pro',"menu profile","open profile");
          (window as any).hj('event', 'pro_menu_pro');
        }
        else{
          this.googleAnalyticsService.eventEmitter('pro_menu_home',"menu home","open profile");
          (window as any).hj('event', 'pro_menu_home');
        }
        this.router.navigate(['user-profile']);
        localStorage.setItem('profile_type', 'pvt');
        this.router.navigate(['user-profile'], { queryParams: { profile_type: 'pvt' } });
        this.googleAnalyticsService.eventEmitter("web_top_profille", "", "User Tap on Profile icon");
        break;
      case 'aboutus':
        this.router.navigate(['aboutus']);
        this.googleAnalyticsService.eventEmitter("web_top_about_us", "", "User Tap on About Us icon");
        break;
      case 'privacy_policy':
        this.router.navigate(['privacy'])
        this.googleAnalyticsService.eventEmitter("web_top_privacy_policy", "", "User Tap on Privacy Policy icon");
        break;
      case 'refund_policy':
        this.router.navigate(['refund'])
        this.googleAnalyticsService.eventEmitter("web_top_refund_policy", "", "User Tap on Refund Policy icon");
        break;
      case 'terms_of_use':
        this.router.navigate(['terms'])
        this.googleAnalyticsService.eventEmitter("web_top_terms_of_use", "", "User Tap on Terms of Use icon");
        break;
      case 'home':
        this.router.navigate(['home']);
        this.googleAnalyticsService.eventEmitter("web_top_home", "", "User Tap on Home icon");
        break;
      case 'jaano':
        //this.router.navigate(['home']);
        this.googleAnalyticsService.eventEmitter("web_top_jaano", "", "User Tap on Jaano icon");
        break;
    }
    localStorage.setItem('mode', this.mode);
  }

  detectPageChangeHandler(value: string) {
    if (value.includes("create-request")){
      this.mode = 'createRequest';
    }
    else if (value.includes("notifications")){
      this.mode = 'notifications';
    }
    else if (value.includes("getHelp-RequestbyId")){
      this.mode = 'hr';
    }
    else if (value.includes("survey")){
      this.mode = 'survey';
    }
    else if (value.includes("getOfficial-FeedbyId")){
      this.mode = 'getOfficial-FeedbyId';
    }
    else if(value.includes("help-request/view-all-comments")){
      this.mode = 'view-all-comments';
    }
    else if(value == 'status=0' || value.indexOf('status=0') > -1)
      this.mode = 'home';
    else if(value == 'status=1' || value.indexOf('status=1') > -1)
      this.mode = 'feeds';
    else if (value == 'help' || value.indexOf('help') > -1)
      this.mode = 'feeds';
    else if (value == 'official-feed' || value.toLowerCase().indexOf('official-feed') > -1)
      this.mode = 'official';
    else if (value == 'help-request' || value.indexOf('help-request') > -1)
      this.mode = 'feeds';
    else if (value == 'user-profile' || value.indexOf('user-profile') > -1)
      this.mode = 'user-profile';
    else if (value == 'aboutus' || value.indexOf('aboutus') > -1)
      this.mode = 'aboutus';
    else if (value == 'privacy' || value.indexOf('privacy') > -1)
      this.mode = 'privacy_policy';
    else if (value == 'refund' || value.indexOf('refund') > -1)
      this.mode = 'refund_policy'
    else if (value == 'terms' || value.indexOf('terms') > -1)
      this.mode = 'terms_of_use'
    else if (value == 'home' || value.indexOf('home') > -1)
      this.mode = 'home';
    else
      this.mode = 'home'
    localStorage.setItem('mode', this.mode);
  }

  goToHome() {
    this.router.navigate(['home']);
  }
  disable() {
    document.body.className = "noscroll";
  }

  enable() {
    document.body.classList.remove("noscroll");
  }

  navigateToNotifications() {
    this.loginService.openModal('', (status: any) => {
      if (status) {
        this.router.navigate(['./notifications']);
        localStorage.setItem('mode','notification');
        this.mode = 'notification';
      } else {
        this.moeService.eventEmitter('web_Login/Signup_Left', {platform : "web"});
      }
    });

    // notification button google analytics
    this.customGoogleAnalytics('noti_',"open nofications");
  }

  jaanoAnalyticLogo(){
    this.customGoogleAnalytics('jaano_logo_',"open home page");
  }
  jaanoAnalyticProfile(){
    this.customGoogleAnalytics('pro_',"open profile");
  }

  navigateToPage(value: any, id: { currentLevel: any; currentLevelBadge: any; } , i: number | number) {
    switch (value) {
      case 'helpRequestComment':
        this.router.navigate(['./help-request/getHelp-RequestbyId'], { queryParams: { Id: id } });
        break;
      case 'supportAdded':
        this.router.navigate(['./help-request/getHelp-RequestbyId'], { queryParams: { Id: id } });
        break;
      case 'newreply':
        this.router.navigate(['./help-request/getHelp-RequestbyId'], { queryParams: { Id: id } });
        break;
      case 'official_feed':
        this.router.navigate(['./feeds/getOfficial-FeedbyId'], { queryParams: { Id: id } });
        break;
      case 'markAsThankyou':
        this.router.navigate(['./help-request/getHelp-RequestbyId'], { queryParams: { Id: id } });
        break;
        case 'thankyouPoints':
          this.router.navigate(['user-profile'], { queryParams: { profile_type: 'pvt' } });
        break;
      case 'newMessage':
        // this.router.navigate(['./user-profile/message/'] + id);
        // this.router.navigateByUrl('/user-profile/message/' + id);
        break;
      case 'suggestionLiked':
        this.router.navigate(['./help-request/getHelp-RequestbyId'], { queryParams: { Id: id } });
        break;
      case 'LeaderboardPush':
        this.router.navigate(['./user-profile/leaderboard']);
        break;
      case 'AlmostLevelUp':
        this.router.navigate(['./user-profile/gamification']);
        break;
      case 'L1UserPush':
        this.router.navigate(['./user-profile/gamification']);
        break;
      case 'UserLevelUp':
        this.router.navigate(['./user-profile/gamification']);
        this.dialog.open(UserLevelUpDialogComponent, {
          data: {
            'currentLevel': id.currentLevel,
            'currentLevelBadge': id.currentLevelBadge
          },
         panelClass: 'levelUpModal'
        });
        break;
    }
    this.notificationArr[i]['isViewed'] = true;
    localStorage.setItem('notificationArr', JSON.stringify(this.notificationArr));
  }


  remainingTime = 0;
  timerText = "05:00";   // 5 minutes
  reloadTimerOfferSubject: Subscription = new Subscription;
  reloadTimerOfferInterval = 1000; // 1 second.
  hasOffer = 0;
  offerNotifications: {helpid:string, msg:string}[] = [];
  showInfoIcon = false;
  reloadTimerForOffer(){
    const creationTime = this.timestamp;
    const currentTime = Date.now();
    if(currentTime - +creationTime <= 300000){
      this.remainingTime = 300000 - (currentTime - +creationTime);
    }
    else{
      this.remainingTime = 0;
      if(this.reloadTimerOfferSubject){
        this.reloadTimerOfferSubject.unsubscribe();
      }
      return;
    }
    // if emitra has offered then no need to reload
    if(this.hasOffer){
      if(this.reloadTimerOfferSubject){
        this.reloadTimerOfferSubject.unsubscribe();
        this.remainingTime = 0;
      }
      return;
    }
    // itreate the interval every seconds.
    this.reloadTimerOfferSubject = interval(this.reloadTimerOfferInterval).subscribe((val)=>{
      // if emitra has offered then no need to reload
      if(this.hasOffer){
        if(this.reloadTimerOfferSubject){
          this.reloadTimerOfferSubject.unsubscribe();
        }
      }
      this.remainingTime = this.remainingTime - this.reloadTimerOfferInterval;
      this.timerText = this.dataService.getTime(this.remainingTime);
      if(this.remainingTime <=0){
        this.remainingTime = 0;
        this.reloadTimerOfferSubject.unsubscribe();
      }

    })
  }

  helpid: string="";

  getInitials(username: string){
    if(username && username.length>0){
      return username.charAt(0);
    }
    return 'U';
  }
  getNotifications(){
    // check for offers
    this.username = this.commonService.getUsername();
    if(this.username && this._auth.authenticated){
      if(this.helpid){
        this.dataService.getOffer(this.username,this.helpid).snapshotChanges()
        .pipe(
        map((changes:any) =>
          changes.map((c:any) =>
            ({ key: c.payload.key, ...c.payload.val() })
          )
        )
        )
        .subscribe( res=>{
          if(res && res.length !=0){
            const ignoreOfferHelpId = localStorage.getItem('ignoreOfferHelpId');
            // dont show offer if the offer helpid is ignored for paid help request
            if(res.length != 0 && ignoreOfferHelpId != res[0].data[0].helpid){
              const helpid = res[0].data[0].helpid;
              if(helpid == this.helpid){
                this.hasOffer = res[0].count;
                if(this.hasOffer > 0){
                  this.remainingTime = 0;
                  if(this.reloadTimerOfferSubject){
                    this.reloadTimerOfferSubject.unsubscribe();
                  }
                }
              }
            }
            else{
              this.hasOffer = 0;
              if(ignoreOfferHelpId != res[0].data[0].helpid){
                if(this.reloadTimerOfferSubject){
                  this.reloadTimerOfferSubject.unsubscribe();
                  this.reloadTimerForOffer();
                }
                else{
                  this.reloadTimerForOffer();
                }
              }
              else{
                this.remainingTime = 0;
              }
            }
          }
          else{
            this.hasOffer = 0;
            if(this.reloadTimerOfferSubject){
              this.reloadTimerOfferSubject.unsubscribe();
              this.reloadTimerForOffer();
            }
            else{
              this.reloadTimerForOffer();
            }
          }
        });
      }
    }
  }

  viewRequest(helpid: string){
    this.router.navigate(["help-request/getHelp-RequestbyId"], {
      queryParams: {
        Id: helpid,
        source: "home",
      },
    });
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_expanded-view_page",
      "",
      "User navigate to help-request expanded-view"
    );
  }

  goToOffer(){
    //  move to offer pgae
    this.viewRequest(this.helpid);
  }


  timestamp = 0;
  getLatestHelpidAndTimestamp(){
    const username = this.commonService.getUsername();
    if(username && this._auth.authenticated){
      this.dataService.getLatestHrAndTimestamp(username)
      .snapshotChanges()
            .pipe(
            map((changes:any) =>
              changes.map((c:any) =>
                ({ key: c.payload.key, ...c.payload.val() })
              )
            )
            ).subscribe( res=>{
        if(res && res.length !=0){
          this.helpid = res[0].help_id;
          this.timestamp = res[0].timestamp;
          this.getNotifications();
        }
        else{
          this.hasOffer = 0;
          if(this.reloadTimerOfferSubject){
            this.reloadTimerOfferSubject.unsubscribe();
          }
        }
      });
    }
  }

  closeNavbar(){
    const element0=document.getElementById("languageNav");
    element0?element0.style.width = "0":0;
    const element1=document.getElementById("mySidenav");
    element1?element1.style.width = "0":0;
    const element2=document.getElementById("bottomNavbar");
    element2?element2.style.opacity= '1':0;
    element2?element2.style.pointerEvents = "initial":0;
    const element3=document.getElementById("mainBody111");
    element3?element3.style.opacity= '1':0;
    const element4=document.getElementById("topNavbar11");
    element4?element4.style.opacity= '1':0;
    const element5=document.getElementById("overlayBox11");
    element5?element5.style.display= 'none':0;
    const element6=document.getElementById("languageWrapper");
    element6?element6.style.zIndex= '0':0;
    // document.getElementById("mySidenav").style.width = "0";
    // document.getElementById("bottomNavbar").style.opacity= '1';
    // document.getElementById("bottomNavbar").style.pointerEvents = "initial";
    // document.getElementById("mainBody111").style.opacity= '1';
    // // document.getElementById("mainBody111").style.pointerEvents = "initial";
    // document.getElementById("topNavbar11").style.opacity= '1';
    // document.getElementById("overlayBox11").style.display= 'none';
    // document.getElementById("topNavbar11").style.pointerEvents = "initial";
  }

  openNavbar(){
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      // mobile screen
      const element1=document.getElementById("mySidenav");
      element1?element1.style.width = "280px":0;
      // document.getElementById("mySidenav").style.width = "280px";
    } else {
      // non mobile screen
      const element1=document.getElementById("mySidenav");
      element1?element1.style.width = "360px":0;
      // document.getElementById("mySidenav").style.width = "360px";
    }
    const element2=document.getElementById("bottomNavbar");
    element2?element2.style.opacity = "0.25":0;
    element2?element2.style.pointerEvents = "none":0;
    // document.getElementById("bottomNavbar").style.opacity= '0.25';
    // document.getElementById("bottomNavbar").style.pointerEvents = "none";
    const element3=document.getElementById("mainBody111");
    element3?element3.style.opacity = "0.25":0;
    // document.getElementById("mainBody111").style.opacity= '0.25';
    // document.getElementById("mainBody111").style.pointerEvents = "none"
    const element4=document.getElementById("topNavbar11");
    element4?element4.style.opacity = "0.25":0;
    // document.getElementById("topNavbar11").style.opacity= '0.25';
    const element5=document.getElementById("topNavbar11");
    element5?element5.style.display = 'block':0;
    // document.getElementById("overlayBox11").style.display= 'block';
    // document.getElementById("topNavbar11").style.pointerEvents = "none";
    this.customGoogleAnalytics('menu_',"open menu");
  }

  openLanguageMenu(){
    console.log('inside open language menu');
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      // mobile screen
      const element1=document.getElementById("languageNav");
      element1?element1.style.width = "200px":0;
    } else {
      // non mobile screen
      const element1=document.getElementById("languageNav");
      element1?element1.style.width = "200px":0;
    }
    const element2=document.getElementById("bottomNavbar");
    element2?element2.style.opacity = "0.5":0;
    element2?element2.style.pointerEvents = "none":0;
    const element3=document.getElementById("mainBody111");
    element3?element3.style.opacity = "0.5":0;
    const element4=document.getElementById("topNavbar11");
    element4?element4.style.opacity = "0.5":0;
    const element5=document.getElementById("topNavbar11");
    element5?element5.style.display = 'block':0;
    const element6=document.getElementById("languageWrapper");
    element6?element6.style.zIndex= '9999':0;
    this.customGoogleAnalytics('menu_',"open menu");
  }
  addRequest() {
    this.router.navigate(["./help-request/create-request"]);
    // this.googleAnalyticsService.eventEmitter(
    //   "crt_hr_init_cev",
    //   "",
    //   "HR init"
    // );
    // (window as any).hj('event', 'crt_hr_init_cev');
    this.customGoogleAnalytics('hr_init_',"HR init");
  }

  googleAnalayticHome(){
    this.customGoogleAnalytics('home_',"open home page");
  }

  showFooterException = false;
  showFooter(){
    if(this.mode == 'user-profile' || this.mode == 'refund_policy' || this.mode == 'aboutus' ||
    this.mode == 'terms_of_use' || this.mode == 'privacy_policy' || this.mode == 'hr'
    || this.mode == 'home' || this.mode == 'feeds' || this.mode == 'notifications'){
      return true;
    }
    return false;
  }

  fromExpandedView(){
    const href=this.router.url;
    if((href.includes('getHelp-RequestbyId') || href.includes('expanded-view') || href.includes('create-request?referralCode') )){
      return true;
    }
    return false;
  }



  showMenu(){
    if(this.fromExpandedView()){
      return false;
    }
    if(this.mode == 'home' || this.mode == 'refund_policy' || this.mode == 'aboutus' ||
    this.mode == 'terms_of_use' || this.mode == 'privacy_policy' || this.mode == 'user-profile'){
      if(this.mode == 'user-profile'){
        const href=this.router.url;
        if(href.includes('user-profile?profile_type=pvt')){
          return true;
        }
        else{
          return false;
        }
      }
      return true;
    }
    return false;
  }

  onBack(){
    const href= this.router.url;
    // if on update profile or no jaano points page move to profile page.
    if(href.includes('/user-profile/gamification') || href.includes('/user-profile/addupdate-user')){
      //  go to profile page.
      this.modeChangeHandler('user-profile');
      return;
    }

    // if on confidence building page go to previous page or on notification page or
    //  on create help request page
    if(href.includes('/help/survey') || href.includes('/notifications') ||
    this.mode == 'createRequest'){
      // go to previous page.
      this.gotoPrivousPage();
      return;
    }

    if(href.includes('help-request/getHelp-RequestbyId')){
      const helpType = localStorage.getItem('helpType');
      const helpStatus = localStorage.getItem('helpStatus');
      if(helpType == 'paid'){
        if(helpStatus == 'open'){
          //  go to profile page.
          this.modeChangeHandler('user-profile');

        }
        else{
          //  go to previous page
          this.gotoPrivousPage();
        }
      }
      if(helpType == 'unpaid'){
        if(helpStatus == 'open'){
          // go to profile page.
          this.modeChangeHandler('user-profile');
        }
        else{
          //  go to home page.
          this.modeChangeHandler('home');
          this.goToHome();
        }
      }
      return;
    }

    this.modeChangeHandler('home');
    this.goToHome();

    // back button google analytics
    this.customGoogleAnalytics('back_',"exit");
  }


  gotoPrivousPage() {
    let source = this.queryParams.source;
    this.previousUrl = this.previousRouteService.getPreviousUrl() || "";
    if (
      this.previousUrl &&
      this.previousUrl != "/" &&
      this.previousUrl.indexOf("?") > -1 &&
      this.previousUrl.indexOf("/view-all-comments") == -1
    ) {
      let search = this.previousUrl.substr(
        this.previousUrl.indexOf("?") + 1,
        this.previousUrl.length
      );
      let newUrl = this.previousUrl.substr(0, this.previousUrl.indexOf("?"));
      let paramsJSON = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      if (paramsJSON.pageNo && this.queryParams.pageNo) {
        paramsJSON.pageNo = this.queryParams.pageNo;
        this.router.navigate([newUrl], { queryParams: paramsJSON });
      } else {
        this.router.navigate([newUrl], { queryParams: paramsJSON });
      }
    } else if (this.previousUrl == "/home") {
      this.router.navigate(["./home"], {
        queryParams: { pageNo: this.queryParams.pageNo },
      });
    } else {
      this.router.navigate(["./feeds/help"], {
        queryParams: { pageNo: this.queryParams.pageNo },
      });
    }
    // if (this.previousUrl == '/user-profile?profile_type=pvt') {
    //   this.router.navigate(['user-profile'], { queryParams: { profile_type: 'pvt' } });
    // } else if (this.previousUrl == '/notifications') {
    //   this.router.navigate(['./notifications']);
    // } else {
    //   this.router.navigate(['./feeds/help']);
    // }
    this.googleAnalyticsService.eventEmitter(
      "web_help-request_feed_page",
      "",
      "User navigate back to help-request main page"
    );
  }


  notLeaderboard(){
    const href = this.router.url;
    if(href.includes('/user-profile/leaderboard')){
      return false;
    }
    return true;
  }

  customGoogleAnalytics(initial:string,action:string){
    let googleAnalyticsKey = initial;
    const currentUrl = this.router.url;
    let category ="";
    if(currentUrl.includes('help-request/getHelp-RequestbyId')){
      googleAnalyticsKey = googleAnalyticsKey + 'cev';
    }else if(currentUrl.includes('help/survey')){
      googleAnalyticsKey = googleAnalyticsKey + 'cbp';
    }
    else if(currentUrl.includes('home')){
      googleAnalyticsKey = googleAnalyticsKey + 'home';
    }
    else if(currentUrl.includes('user-profile/addupdate-user')){
      googleAnalyticsKey = googleAnalyticsKey + 'edit_pro';
    }
    else if(currentUrl.includes('user-profile?profile_type')){
      googleAnalyticsKey = googleAnalyticsKey + 'pro';
    }
    this.googleAnalyticsService.eventEmitter(googleAnalyticsKey,category,action);
    // if(googleAnalyticsKey === 'hr_init_pro')
    // this.googleAnalyticsService.eventEmitter2('hr_init_pro',{});
    // if(googleAnalyticsKey === 'hr_init_cev')
    // this.googleAnalyticsService.eventEmitter2('hr_init_cev',{});
    // if(googleAnalyticsKey === 'hr_init_home')
    // this.googleAnalyticsService.eventEmitter2('hr_init_home',{});
    (window as any).hj('event', googleAnalyticsKey);
  }

  isClosedExpandedView(){
    const href = this.router.url;
    if(href.includes('/help-request/getHelp-RequestbyId') && href.includes('status=0')){
      return true;
    }
    return false;
  }

  language = 'en';
  onChangeLanguage(lang:any) {
    this.language = lang;
    this.languageService.setSelectedLanguage(lang);
    this.languageService.startLanguageTranslator();
    this.moeService.eventEmitter('change_language', {platform : "web", lang : lang});
    this.closeNavbar();
  }

  languages = [ 
    {
        key:'A',
        lang:'en',
        language:'English'
    }, 
    {
        key:'अ',
        lang:'hi',
        language:'हिंदी'
    }, 
    {
        key:'ఆ',
        lang:'te',
        language:'తెలుగు'
    }, 
    {
        key:'अ',
        lang:'mr',
        language:'मराठी'
    }, 
    {
        key:'ੳ',
        lang:'pa',
        language:'ਪੰਜਾਬੀ'
    }, 
    {
        key:'অ',
        lang:'bn',
        language:'বাংল'
    }, 
    {
        key:'অ',
        lang:'as',
        language:'অসমীয়া'
    }, 
    {
        key:'अ',
        lang:'ne',
        language:'नेपाली'
    },
  ]


  dialogRefer:any;
  locationConstraint=async()=>{
    return new Promise((resolve,reject)=>{
      try {
        this.commonService.selectedLocation.subscribe((location) => {
          if (
            location &&
            Object.keys(location).length > 0 &&
            (Number(localStorage.getItem("admin_defined_location")) > 0 ||
              Number(localStorage.getItem("admin_defined_location")) < 0)
          ) {
            if (this.dialogRefer) {
              this.dialogRefer.close("immediate");
              this.dialogRefer = null;
            }
            this.lat = location.lat();
            this.lng = location.lng();
            if (Number(localStorage.getItem("admin_defined_location")) == 1)
              localStorage.setItem("admin_defined_location", "0");
            resolve(null);
          } else {
            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem("admin_defined_location", "-1");
              this.loclatdialog.openChangeLocationDialog((res:any) => {
                resolve(null);
              });
            }
          }
        });
      }
      catch(error){
        reject(error);
      }
    });
  }
}
