<div class="col-sm-12 col-xs-12 no-padding" *ngIf="!onSuceess">
  <div class="row">
    <div class="col-sm-12 col-xs-12 no-padding report-issue-title"
    style="margin-bottom:28px;">
      {{'report_issue_title' | translate}}
    </div>
  
    <div class="col-sm-12 col-xs-12 no-padding" *ngFor="let issue of all_issues;let i=index">
      <input type="radio" [id]="issue" name="issue" value="{{issue}}" (change)="setIssue(i)">
      <label class="labelsClass" for="{{issue}}">{{'all_issues.' + issue | translate}}</label><br>
    </div>
  
    <textarea class="col-sm-12 col-xs-12 no-padding" style="margin-top: 1rem;"
      [style.display]="selectedIssue == all_issues.length? 'block' : 'none'" [(ngModel)]="feedback" rows="3" type="text"
      placeholder="{{'feedback' | translate}}"></textarea>
  </div>

  <!-- <div class="col-sm-12 col-xs-12 no-padding text">{{'removed_from_feed' | translate}}</div> -->
  <div class="row" style="margin-top:16px">
    <div class="col">
      <button class="repost-button cancel" 
      [mat-dialog-close]="true" >{{'cancel' | translate}}</button>
    </div>
    <div class="col-auto ml-auto">
      <button class="repost-button" [disabled]="!selectedIssue || (selectedIssue == 5 && 
      feedback == '')"
      (click)="submit()">{{'submit' | translate | titlecase}}</button>
    </div>
  </div>
  
</div>

<div class="col-sm-12 col-xs-12 no-padding confirmClass" *ngIf="onSuceess">
  <img (click)="close()" class="closeButton" src="../../../assets/images/close.svg" alt="close-icon">
  <img src="../../../assets/images/report_success.svg" alt="report-success">
  <div>{{'thank_you_for_feedback' | translate}}</div>
  <div style="font-size: 14px;">{{'removed_from_feed' | translate}}</div>
</div>