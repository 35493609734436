import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class MetaServiceService {

  constructor(private cookieService: CookieService) { }

  getMetaData() {
    let metaData = {
      "virtual_id": localStorage.getItem('virtualId'),
      "browser_userAgent": this.cookieService.get('browser_userAgent'),
      "LanguageSelected": this.cookieService.get('LanguageSelected'),
      "LocationSelected": this.cookieService.get('LocationSelected'),
      "platform": this.cookieService.get('platform'),
      "is_logged__in": this.cookieService.get('is_logged__in'),
      "Webtoken":  localStorage.getItem('Webtoken'),
      "username": this.cookieService.get('username'),
      "mobile_number": this.cookieService.get('mobile_number')
    }

    return metaData;
  }
}
